import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[(_vm.tabs.length > 0)?_c('main',{staticClass:"main"},[_c(VTabs,{staticStyle:{"margin-top":"-10px"},attrs:{"value":_vm.tabActive,"background-color":"black","color":"white"}},[_vm._l((_vm.tabs),function(item,i){return _c(VTab,{key:i,staticClass:"mt-1",staticStyle:{"color":"white","font-size":".6rem"},on:{"click":function($event){return _vm.teste(item.name, i)}}},[_vm._v(" "+_vm._s(item.name)),_c(VBtn,{staticClass:"ml-3",attrs:{"icon":"","color":"white","x-small":""},on:{"click":function($event){return _vm.removeTab(i)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-close")])],1)],1)}),_c(VSpacer),_c('img',{staticStyle:{"max-height":"40px","padding-top":"15px"},attrs:{"src":require("../../assets/logoMini.png")}}),_c('div',{staticClass:"pa-2 mr-2"},[_c(VAvatar,{staticClass:"mt-1",attrs:{"size":"28","dark":""}},[_c('Gravatar',{attrs:{"email":_vm.user.email,"alt":"email"}})],1)],1)],2),_c(VTabsItems,{model:{value:(_vm.tabActive),callback:function ($$v) {_vm.tabActive=$$v},expression:"tabActive"}},_vm._l((_vm.tabs),function(item){return _c(VTabItem,{key:item.name},[_c(item.content,{tag:"component"})],1)}),1)],1):_vm._e(),(_vm.tabs.length <= 0)?_c('PagInicial'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }