<template>
  <div id="app">
    <v-card>
      <div class="ml-2" v-if="boilerplate">
        <v-data-table
          :headers="headersInvest"
          dense
          :items="itemInvest"
          class="elevation-1"
          no-data-text="Sem investimentos no momento..."
          fixed-header
          item-key="ZZQ_ID"
          :height="altura"
          :loading="loading"
          :search="search"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div>
                <v-btn
                  class="mx-5 mt-1"
                  fab
                  x-small
                  dark
                  color="indigo"
                  @click="novo()"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </div>
              <v-spacer></v-spacer>
              <v-btn
                class="mr-3 mt-4"
                small
                dark
                color="cyan darken-4"
                @click="dialogRelat = true"
                >Relatório</v-btn
              >
            </v-toolbar>
            <section dense v-if="painelAplicado" class="ml-3">
              <span
                style="font-size: 18px; color: #424242; font-weight: bolder"
                >{{ TituloPainel }}</span
              >
              <p style="font-size: 12px; color: #757575">
                {{ DetalhesPainel }}
                <v-btn
                  class="ml-2"
                  x-small
                  dark
                  fab
                  color="error"
                  @click="LimpaFiltroPainel()"
                >
                  <v-icon dark small>mdi-eraser</v-icon>
                </v-btn>
              </p>
            </section>
          </template>
          <template v-slot:[`body.prepend`] v-if="itemInvest.length > 0">
            <tr>
              <td v-for="item in headersInvest" :key="item.text">
                <span v-if="item.text === 'Ações'"
                  >Filtros<v-icon x-small class="ml-2" color="blue darken-4"
                    >mdi-filter</v-icon
                  >
                </span>
                <v-text-field
                  dense
                  v-if="item.text === 'Ano'"
                  v-model="ZW1_ANO"
                  type="text"
                  :placeholder="item.text"
                ></v-text-field>
                <v-text-field
                  dense
                  v-if="item.text === 'Mês'"
                  v-model="ZW1_MES"
                  type="text"
                  :placeholder="item.text"
                ></v-text-field>
                <v-text-field
                  dense
                  v-if="item.text === 'Estado'"
                  v-model="ZW1_EST"
                  type="text"
                  :placeholder="item.text"
                ></v-text-field>
                <v-text-field
                  dense
                  v-if="item.text === 'Cidade'"
                  v-model="ZW1_CIDADE"
                  type="text"
                  :placeholder="item.text"
                ></v-text-field>
                <v-text-field
                  dense
                  v-if="item.text === 'Investimento'"
                  v-model="ZW1_INVEST"
                  type="text"
                  :placeholder="item.text"
                ></v-text-field>
                <v-text-field
                  dense
                  v-if="item.text === 'Meta'"
                  v-model="ZW1_METALD"
                  type="text"
                  :placeholder="item.text"
                ></v-text-field>
              </td>
            </tr>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-row class="start">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Alterar cadastro</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="deleteItem(item)"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Remover cadastro</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="vercliente(item)"
                    v-bind="attrs"
                    v-on="on"
                    v-if="item.A1_COD"
                    >mdi-account-check</v-icon
                  >
                </template>
                <span
                  >Cliente: {{ item.A1_COD }}/{{ item.A1_LOJA }} -
                  {{ item.A1_NOME }}, clique para ir em clientes.</span
                >
              </v-tooltip>
            </v-row>
            <!-- <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="FollowUp(item)"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                >mdi-account-tie-voice</v-icon
              >
            </template>
            <span>Follow-Up</span>
          </v-tooltip> -->
          </template>
        </v-data-table>
      </div>
      <div class="ml-2" v-if="!boilerplate">
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="boilerplate"
          :height="altura + 80"
          type="table"
          class="mx-auto"
          v-if="!boilerplate"
        ></v-skeleton-loader>
      </div>
      <div class="dadosCli text-center">
        <v-dialog v-model="DialogDados" width="60%" persistent>
          <v-card v-if="boilerplate" ref="form">
            <v-card-title
              class="headline grey lighten-2 justify-center"
              v-text="titleDialog"
              style="height: 60px"
            ></v-card-title>
            <v-card-text>
              <v-row dense class="mt-5">
                <v-col
                  v-for="campo in camposTela"
                  :key="campo.ZZP_CAMPO"
                  :cols="campo.ZZP_COLS"
                >
                  <div
                    class="d-flex flex-column mr-5"
                    style="margin-top: -14px"
                    v-if="campo.ZZP_TIPO === 'N'"
                  >
                    <label style="font-size: 0.7rem">{{
                      campo.ZZP_TITULO
                    }}</label>
                    <money
                      v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :value="dadosTela[campo.ZZP_CAMPO.trim()]"
                      dense
                      v-bind="money"
                      label="Investimento"
                      style="border-bottom: 1.4px solid rgb(148, 148, 148)"
                    ></money>
                  </div>

                  <v-text-field
                    v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :label="campo.ZZP_TITULO"
                    v-if="campo.ZZP_TIPO === 'T'"
                    :rules="
                      obrigat(
                        dadosTela[campo.ZZP_CAMPO.trim()],
                        campo.ZZP_OBRIGA,
                      )
                    "
                    class="text-uppercase"
                    dense
                    :readonly="isReading"
                    :disabled="campo.ZZP_VISUAL === '2'"
                  ></v-text-field>
                  <v-text-field
                    v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :label="campo.ZZP_TITULO"
                    v-if="campo.ZZP_TIPO === 'D'"
                    :rules="
                      obrigat(
                        dadosTela[campo.ZZP_CAMPO.trim()],
                        campo.ZZP_OBRIGA,
                      )
                    "
                    v-mask="'##/##/####'"
                    class="text-uppercase"
                    dense
                    :readonly="isReading"
                    :disabled="campo.ZZP_VISUAL === '2'"
                  ></v-text-field>
                  <v-select
                    v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :items="campo.ITEMS"
                    :label="campo.ZZP_TITULO"
                    :rules="
                      obrigat(
                        dadosTela[campo.ZZP_CAMPO.trim()],
                        campo.ZZP_OBRIGA,
                      )
                    "
                    v-if="campo.ZZP_TIPO === 'S'"
                    class="text-uppercase"
                    dense
                    :readonly="isReading"
                    :disabled="campo.ZZP_VISUAL === '2'"
                  ></v-select>
                  <v-autocomplete
                    v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                    style="margin-top: -22px"
                    dense
                    :items="itemsTipo"
                    @focus="carregaItems(campo)"
                    @click="carregaItems(campo)"
                    no-data-text="Buscando dados..."
                    :rules="
                      obrigat(
                        dadosTela[campo.ZZP_CAMPO.trim()],
                        campo.ZZP_OBRIGA,
                      )
                    "
                    filled
                    :label="campo.ZZP_TITULO"
                    background-color="white"
                    v-if="campo.ZZP_TIPO === 'A'"
                    class="text-uppercase"
                    :readonly="isReading"
                    :disabled="campo.ZZP_VISUAL === '2'"
                  ></v-autocomplete>
                  <v-textarea
                    style="margin-top: -14px; font-size: 0.7rem"
                    v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :label="campo.ZZP_TITULO"
                    class="mx-2"
                    rows="1"
                    v-if="campo.ZZP_TIPO === 'Y'"
                    :readonly="isReading"
                    :disabled="campo.ZZP_VISUAL === '2'"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn color="error" text @click="limpaVariaveis()">Fechar</v-btn>
              <v-spacer></v-spacer>
              <v-col cols="5">
                <v-progress-linear
                  v-model="progress"
                  v-if="progress > 0"
                  color="blue-grey"
                  height="25"
                >
                  <template v-slot="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                v-if="!isReading && !isEditing && !isDelete"
                :disabled="blqBotao"
                text
                @click="salva()"
                >Salvar</v-btn
              >
              <v-btn
                color="primary"
                v-if="!isReading && !isDelete && isEditing"
                :disabled="blqBotao"
                text
                @click="salva(id)"
                >Salvar Alteração</v-btn
              >
              <v-btn
                color="orange darken-4"
                v-if="!isReading && !isEditing && isDelete"
                :disabled="blqBotao"
                text
                @click="salva(id)"
                >Deletar</v-btn
              >
            </v-card-actions>
          </v-card>

          <v-skeleton-loader
            ref="skeleton"
            :boilerplate="boilerplate"
            type="table"
            class="mx-auto"
            v-if="!boilerplate"
          ></v-skeleton-loader>
        </v-dialog>
      </div>
      <v-bottom-sheet v-model="sheetMessage">
        <v-sheet class="text-center" :color="colorMessage" height="130px">
          <div class="py-3">
            <v-btn class="mx-2" text fab>
              <v-icon
                v-text="iconMessage"
                @click="sheetMessage = false"
              ></v-icon>
            </v-btn>
          </div>
          <div class="py-3" style="font-size: 15px" v-text="message"></div>
        </v-sheet>
      </v-bottom-sheet>
      <v-dialog v-model="dialogSuccessSaveCliente" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">Lead</v-card-title>
          <v-card-text class="mt-5 title">{{ mensagem }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="reloadSave()">fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAwaitSaveCliente" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitSaveCliente" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Lead</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Salvando o Lead...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogTimeLine" width="20%">
        <div style="background: white" class="d-flex">
          <span class="title ml-2">Histórico</span>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialogTimeLine = false">
            Fechar
          </v-btn>
        </div>
        <v-card height="600" class="scroll" v-if="itensTimeLine.length > 0">
          <v-card-text class="py-0">
            <v-timeline align-top dense style="margin-left: -40px">
              <v-timeline-item
                v-for="(item, i) in itensTimeLine"
                :key="i"
                :color="item.color"
                small
                :icon="item.icon"
                fill-dot
              >
                <v-row>
                  <v-col>
                    <strong>{{ item.operacao }}</strong>
                    <div class="caption">
                      {{ item.caption1 }}
                    </div>
                    <div class="caption">
                      {{ item.caption2 }}
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogErrosCliente" width="900">
        <v-card max-width="900" class="mx-auto">
          <v-card-title class="headline red lighten-2" style="color: white"
            >Erros ao salvar</v-card-title
          >

          <v-card-text>
            <v-list disabled>
              <v-subheader>Erros</v-subheader>
              <v-list-item-group v-model="aError" color="primary">
                <v-list-item v-for="(item, i) in aError" :key="i">
                  <v-list-item-icon>
                    <v-icon color="red" v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 0.8rem"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogErrosCliente = false"
              >fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="text-center">
        <v-dialog v-model="dialogPaineis" width="500" persistent>
          <v-card>
            <v-overlay :value="dialogPaineis" absolute>
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-card-title class="headline grey lighten-2">
              Paineis
            </v-card-title>

            <v-card-text>
              {{ messagePainel }}
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div class="text-center">
        <v-dialog v-model="dialogTransfLead" width="500" persistent>
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Transferência de Lead
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="RespTransf"
                    :items="itemsResponsaveis"
                    dense
                    filled
                    label="Escolha o novo responsável"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" text @click="fechaDialogTransf">
                Fechar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="efetivaTransferencia">
                Transferir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <Opss />
    </v-card>
    <!-- <v-row class="pa-3">
      <v-spacer></v-spacer>
      <v-btn
        class="mr-3"
        small
        dark
        color="cyan darken-4"
        @click="dialogRelat = true"
        >Relatório</v-btn
      >
    </v-row> -->
    <div class="text-center">
      <v-dialog v-model="dialogRelat" width="600" persistent>
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Escolha o ano e mês.
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="Ano"
                  :items="itemsAno"
                  outlined
                  dense
                  chips
                  small-chips
                  label="ANO"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="Mes"
                  :items="itemsMes"
                  outlined
                  dense
                  chips
                  small-chips
                  label="MES"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="error" text @click="dialogRelat = false">
              Fechar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="geraVariaveis"> Gerar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import moment from "moment";
import Opss from "@/components/templates/Opss";
import { Money } from "v-money";
export default {
  name: "Investimento",
  data: () => {
    return {
      Ano: null,
      Mes: null,
      itemsAno: [
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
      ],
      itemsMes: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      dialogRelat: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      dialogTimeLine: false,
      dialogTransfLead: false,
      RespTransf: null,
      itemsResponsaveis: [],
      dialogPaineis: false,
      messagePainel: null,
      drawer: null,
      items: [],
      painelAplicado: false,
      TituloPainel: null,
      DetalhesPainel: null,
      mini: true,
      NrRegFiltro: null,
      codCli: null,
      lojaCli: null,
      dialogDetalhes: false,
      progress: 0,
      indFiltro: "C",
      filtroEscolhido: null,
      id: null,
      aError: [],
      itemsFiltro: [],
      stringFiltro: null,
      dialogErrosCliente: false,
      dialogAwaitSaveCliente: false,
      dialogSuccessSaveCliente: false,
      mensagem: null,
      errosTE: false,
      sheetMessage: false,
      colorMessage: "",
      iconMessage: "",
      titleDialog: "",
      selectLeads: [],
      message: "",
      DialogDados: false,
      isReading: false,
      isEditing: false,
      isDelete: false,
      search: null,
      loading: true,
      altura: 0,
      perPage: 0,
      itensTimeLine: [],
      headersInvest: [],
      itemInvest: [],
      itemsTipo: [],
      camposTela: [],
      dadosTela: [],
      cliente: {},
      dadosCep: [],
      DtInicial: new Date().toISOString().substr(0, 10),
      countObrigatorio: 0,
      cloneDadosTela: null,
      interval: 0,
      itensFiltroEsc: [],
      stringCampos: "",
      top500Invest: [],
      isNew: false,
      isAdmin: false,
      dtInicial: "",
      dtFinal: "",
      boilerplate: false,
      ZW1_ANO: "",
      ZW1_MES: "",
      ZW1_EST: "",
      ZW1_CIDADE: "",
      ZW1_INVEST: "",
      ZW1_METALD: "",
      blqBotao: false,
    };
  },
  components: { Opss, Money },
  computed: {
    ...mapState(["metadados", "user", "dialogFollowUp", "isReload", "tabs"]),
    DataFormatadaInicial() {
      return moment(this.DtInicial).format("DD/MM/YYYY");
    },
  },
  methods: {
    downloadItem({ url, label }) {
      axios
        .get(url, { responseType: "blob" })
        .then(response => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    geraVariaveis() {
      if (!this.Ano && !this.Mes) return;

      let mes = "";
      if (this.Mes === "Janeiro") mes = "01";
      if (this.Mes === "Fevereiro") mes = "02";
      if (this.Mes === "Março") mes = "03";
      if (this.Mes === "Abril") mes = "04";
      if (this.Mes === "Maio") mes = "05";
      if (this.Mes === "Junho") mes = "06";
      if (this.Mes === "Julho") mes = "07";
      if (this.Mes === "Agosto") mes = "08";
      if (this.Mes === "Setembro") mes = "09";
      if (this.Mes === "Outubro") mes = "10";
      if (this.Mes === "Novembro") mes = "11";
      if (this.Mes === "Dezembro") mes = "12";

      this.$store.commit("setAnoMesRelatInvest", `${this.Ano}${mes}`);

      this.dialogRelat = false;
      this.Ano = null;
      this.Mes = null;

      this.$store.commit("SetTitle", "Relatório Investimento");
      this.$store.commit("SetTabs", {
        name: `Relatório Investimento`,
        content: `relatorioInvest`,
      });
      // this.$store.commit("SetTabActive", this.tabs.length - 1);
    },
    async updateDataS() {
      let accessToken = null;

      let url = `${process.env.VUE_APP_BASE_API_URL}/Access_token`;

      await axios
        .post(url)
        .then(res => {
          accessToken = res.data.access_token;
        })
        .catch(error => console.log(error));

      const dados = {
        accessToken: accessToken,
        updateDetails: [
          {
            datasourceSelector: {
              datasourceType: "Sql",
              connectionDetails: {
                server: "172.16.14.2",
                database: "P12PRODUCAO",
              },
            },
            connectionDetails: {
              server: "New-Sql-Server",
              database: "New-Sql-Database",
            },
          },
        ],
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/updateDataSource`;

      await axios
        .post(url, dados)
        .then(res => console.log(res.data))
        .catch(error => {
          console.log(error);
          console.log(error.response.data);
        });
    },
    vercliente(item) {
      this.$store.commit("SetCodLojaCliente", `${item.A1_COD}-${item.A1_LOJA}`);
      this.$store.commit("SetTitle", "Clientes");
      this.$store.commit("SetTabs", {
        name: `${"Clientes"}`,
        content: `Clientes`,
      });
      // this.$store.commit("SetTabActive", this.tabs.length - 1);
      this.$store.commit("SetTitle", "Clientes");
    },
    reloadSave() {
      this.montaBrowser();
      this.dialogSuccessSaveCliente = false;

      if (!this.isEditing && !this.isDelete) {
        if (this.dadosTela["ZW1_MES"].trim() === "01")
          this.dadosTela["ZW1_MES"] = "01=JANEIRO";
        if (this.dadosTela["ZW1_MES"].trim() === "02")
          this.dadosTela["ZW1_MES"] = "02=FEVEREIRO";
        if (this.dadosTela["ZW1_MES"].trim() === "03")
          this.dadosTela["ZW1_MES"] = "03=MARCO";
        if (this.dadosTela["ZW1_MES"].trim() === "04")
          this.dadosTela["ZW1_MES"] = "04=ABRIL";
        if (this.dadosTela["ZW1_MES"].trim() === "05")
          this.dadosTela["ZW1_MES"] = "05=MAIO";
        if (this.dadosTela["ZW1_MES"].trim() === "06")
          this.dadosTela["ZW1_MES"] = "06=JUNHO";
        if (this.dadosTela["ZW1_MES"].trim() === "07")
          this.dadosTela["ZW1_MES"] = "07=JULHO";
        if (this.dadosTela["ZW1_MES"].trim() === "08")
          this.dadosTela["ZW1_MES"] = "08=AGOSTO";
        if (this.dadosTela["ZW1_MES"].trim() === "09")
          this.dadosTela["ZW1_MES"] = "09=SETEMBRO";
        if (this.dadosTela["ZW1_MES"].trim() === "10")
          this.dadosTela["ZW1_MES"] = "10=OUTUBRO";
        if (this.dadosTela["ZW1_MES"].trim() === "11")
          this.dadosTela["ZW1_MES"] = "11=NOVEMBRO";
        if (this.dadosTela["ZW1_MES"].trim() === "12")
          this.dadosTela["ZW1_MES"] = "12=DEZEMBRO";

        this.dadosTela["ZW1_EST"] = null;
        this.dadosTela["ZW1_CIDADE"] = null;
        this.dadosTela["ZW1_INVEST"] = 0;
        this.dadosTela["ZW1_METALD"] = null;
      } else {
        this.isDelete = false;
        this.isEditing = false;
        this.isDelete = false;
        this.DialogDados = false;
      }
    },
    fFollowUp() {
      this.$store.commit("SetAlias", "ZZQ");
      this.$refs.follow.montaBrowser();
      this.$store.commit("SetDialogFollowUp", true);
    },
    paineis(chamada) {
      if (chamada === "meusLeads") this.meusLeads();
      if (chamada === "atribuirLeads") this.atribuirLeads();
      if (chamada === "transfereLead") this.transfereLead();
      if (chamada === "histLead") this.histLead();
      if (chamada === "leadsRetorno") this.leadsRetorno();
      if (chamada === "leadsCliAberto") this.leadsCliAberto();
    },
    async leadsCliAberto() {
      const filtro = this.top500Invest.filter(
        e =>
          e.ZZQ_RESP === this.user.id &&
          e.ZZQ_SITUAC.trim() === "Aberto" &&
          e.A1_COD,
      );

      if (filtro.length > 0) {
        this.painelAplicado = true;
        this.TituloPainel = "Meus lead(s) com cliente vinculado em aberto";
        this.DetalhesPainel = `Total de ${filtro.length} registro(s)`;
        this.itemInvest = filtro;
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit("SetMensagemDialogOps", "Sem resultado no momento.");
        this.$store.commit("SetdialogNull", true);
      }
    },
    async leadsRetorno() {
      const filtro = this.top500Invest.filter(
        e =>
          e.ZZQ_RESP === this.user.id &&
          e.ZZQ_STATUS.trim() === "000007-Retornar" &&
          this.verdata(e.ZZQ_DTRET),
      );

      if (filtro.length > 0) {
        this.painelAplicado = true;
        this.TituloPainel = "Meus lead(s) com retorno até hoje";
        this.DetalhesPainel = `Total de ${filtro.length} registro(s)`;
        this.itemInvest = filtro;
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit("SetMensagemDialogOps", "Sem resultado no momento.");
        this.$store.commit("SetdialogNull", true);
      }
    },
    verdata(data) {
      let dataFormat = data.split("/");
      let dataItem = `${dataFormat[2]}-${dataFormat[1]}-${dataFormat[0]}`;
      let dataParse = Date.parse(dataItem);
      let hoje = new Date();
      hoje = Date.parse(hoje);
      if (dataParse <= hoje) {
        return true;
      } else {
        return false;
      }
    },
    async histLead() {
      this.itensTimeLine = [];

      if (this.selectLeads.length <= 0 || this.selectLeads.length > 1) {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit(
          "SetMensagemDialogOps",
          "Selecione apenas um Lead para ver o histórico.",
        );
        this.$store.commit("SetdialogNull", true);
      } else {
        this.dialogPaineis = true;
        this.messagePainel = `Buscando logs, aguarde...`;
        const id = this.selectLeads[0].ZZQ_ID;

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS:
            "ZZS_DATA,ZZS_HORA,ZZS_OPER,ZS1.ZS1_NOME US,ZSS1.ZS1_NOME USER_RESP",
          WHERE: `ZZS_ID='${id}' AND ZZS_ORIGEM='LEAD' ORDER BY ZZS.R_E_C_N_O_ DESC`,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/timeLine`;

        await axios
          .post(url, dados)
          .then(res => {
            res.data.forEach(e => {
              if (e.ZZS_OPER.trim() === "ATRIBUICAO") {
                this.itensTimeLine.push({
                  color: "blue darken-3",
                  icon: "mdi-account-multiple-plus",
                  operacao: e.ZZS_OPER.trim(),
                  caption1: e.US.substr(0, 13),
                  caption2: `${e.ZZS_DATA.substr(6, 2)}/${e.ZZS_DATA.substr(
                    4,
                    2,
                  )}/${e.ZZS_DATA.substr(0, 4)} ${e.ZZS_HORA}`,
                });
              }
              if (e.ZZS_OPER.trim() === "ALTERACAO") {
                this.itensTimeLine.push({
                  color: "green darken-3",
                  icon: "mdi-account-edit",
                  operacao: e.ZZS_OPER.trim(),
                  caption1: e.US.substr(0, 13),
                  caption2: `${e.ZZS_DATA.substr(6, 2)}/${e.ZZS_DATA.substr(
                    4,
                    2,
                  )}/${e.ZZS_DATA.substr(0, 4)} ${e.ZZS_HORA}`,
                });
              }
              if (e.ZZS_OPER.trim() === "TRANSFERENCIA") {
                this.itensTimeLine.push({
                  color: "yellow darken-3",
                  icon: "mdi-transfer",
                  operacao: e.ZZS_OPER.trim(),
                  caption1: ` => ${e.US.substr(0, 13)}`,
                  caption2: `${e.ZZS_DATA.substr(6, 2)}/${e.ZZS_DATA.substr(
                    4,
                    2,
                  )}/${e.ZZS_DATA.substr(0, 4)} ${e.ZZS_HORA}`,
                });
              }
            });
            if (res.data.length <= 0) {
              this.itensTimeLine.push({
                color: "red darken-3",
                icon: "mdi-close",
                operacao: "SEM DADOS",
                caption1: `Sem histórico no momento`,
                caption2: ``,
              });
            }
            this.dialogPaineis = false;
            this.dialogTimeLine = true;
            this.messagePainel = null;
          })
          .catch(err => {
            console.log(err);
            this.dialogPaineis = false;
            this.messagePainel = null;
          });

        //
      }
    },
    fechaDialogTransf() {
      this.RespTransf = null;
      this.dialogTransfLead = false;
    },
    transfereLead() {
      if (this.selectLeads.length > 0) {
        if (!this.isAdmin) {
          const meus = this.selectLeads.filter(
            e => e.ZZQ_RESP === this.user.id,
          );

          if (meus.length !== this.selectLeads.length) {
            this.painelAplicado = false;
            this.TituloPainel = null;
            this.DetalhesPainel = null;
            this.$store.commit(
              "SetMensagemDialogOps",
              "Foi marcado lead(s) que ainda não esta sobre sua responsabilidade, para transferencia marque somente Leads seus.",
            );
            this.$store.commit("SetdialogNull", true);
          } else {
            this.dialogTransfLead = true;
          }
        } else {
          this.dialogTransfLead = true;
        }
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit(
          "SetMensagemDialogOps",
          "Marque seu(s) registro(s) para transferir!",
        );
        this.$store.commit("SetdialogNull", true);
      }
    },
    async efetivaTransferencia() {
      this.dialogPaineis = true;
      this.messagePainel = `Transferindo lead(s), aguarde...`;
      let dados = {
        CABECALHO: {
          EMPRESA: "01",
          FILIAL: "0101",
          OPER: "T",
        },
      };
      dados.ZZQ = [];
      this.selectLeads.forEach(e => {
        dados.ZZQ.push({
          ZZQ_ID: e.ZZQ_ID,
          ZZQ_SITUAC: "A",
          ZZQ_RESP: this.RespTransf.substr(0, 6),
        });
      });

      const url = `${process.env.VUE_APP_BASE_API_URL}/atribuiLead`;

      await axios
        .put(url, dados)
        .then(res => {
          console.log(res.data);
          this.dialogPaineis = false;
          this.messagePainel = null;
          this.selectLeads = [];
          this.RespTransf = null;
          this.dialogTransfLead = false;
          this.montaBrowser();
        })
        .catch(err => {
          console.log(err);
          this.dialogPaineis = false;
          this.messagePainel = null;
        });
    },
    meusLeads() {
      const filtro = this.top500Invest.filter(e => e.ZZQ_RESP === this.user.id);

      if (filtro.length > 0) {
        this.painelAplicado = true;
        this.TituloPainel = "Meus leads";
        this.DetalhesPainel = `Total de ${filtro.length} registro(s)`;
        this.itemInvest = filtro;
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit("SetMensagemDialogOps", "Sem resultado no momento.");
        this.$store.commit("SetdialogNull", true);
      }
    },
    async atribuirLeads() {
      if (this.selectLeads.length > 0) {
        const meus = this.selectLeads.filter(e => e.ZZQ_SITUAC === "Novo");

        if (meus.length !== this.selectLeads.length) {
          this.painelAplicado = false;
          this.TituloPainel = null;
          this.DetalhesPainel = null;
          this.$store.commit(
            "SetMensagemDialogOps",
            "Foi marcado lead(s) que já esta atribuído a um responsável, marque somente aqueles que não possuem responsável.",
          );
          this.$store.commit("SetdialogNull", true);
        } else {
          this.dialogPaineis = true;
          this.messagePainel = `Atribuindo leads, aguarde...`;
          let dados = {
            CABECALHO: {
              EMPRESA: "01",
              FILIAL: "0101",
              OPER: "A",
            },
          };
          dados.ZZQ = [];
          this.selectLeads.forEach(e => {
            dados.ZZQ.push({
              ZZQ_ID: e.ZZQ_ID,
              ZZQ_SITUAC: "A",
              ZZQ_RESP: this.user.id,
            });
          });

          const url = `${process.env.VUE_APP_BASE_API_URL}/atribuiLead`;

          await axios
            .put(url, dados)
            .then(res => {
              console.log(res.data);
              this.dialogPaineis = false;
              this.messagePainel = null;
              this.selectLeads = [];
              this.montaBrowser();
            })
            .catch(err => {
              console.log(err);
              this.dialogPaineis = false;
              this.messagePainel = null;
            });
        }
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit(
          "SetMensagemDialogOps",
          "Sem registro(s) marcado(s).",
        );
        this.$store.commit("SetdialogNull", true);
      }
    },
    abrePainel() {
      this.drawer = !this.drawer;
      this.mini = false;
    },
    verTipoFiltro() {
      this.itensFiltroEsc = [];

      if (this.filtroEscolhido === "Responsável") {
        const nomes = [
          ...new Set(this.top500Invest.map(item => item.ZS1_NOME)),
        ];
        nomes.forEach(e => {
          if (e !== null) {
            this.itensFiltroEsc.push({
              text: e,
              value: e,
            });
          }
        });
      }

      if (this.filtroEscolhido === "Status") {
        this.itensFiltroEsc = [
          { text: "Reagendar", value: "000001" },
          { text: "Atendimento realizado", value: "000002" },
          { text: "Perdido", value: "000003" },
          { text: "Lojas Opimed", value: "000004" },
          { text: "Outras marcas", value: "000005" },
          { text: "Ligou errado", value: "000006" },
          { text: " Outras informações", value: "000007" },
        ];
      }

      if (this.filtroEscolhido === "Qualificado") {
        this.itensFiltroEsc = [
          { text: "Sim", value: "1" },
          { text: "Não", value: "2" },
        ];
      }

      if (this.filtroEscolhido === "Situação") {
        this.itensFiltroEsc = [
          { text: "Novo", value: "N" },
          { text: "Aberto", value: "A" },
          { text: "Encerrado", value: "E" },
          { text: "Sem contato", value: "S" },
        ];
      }
    },
    LimpaFiltroPainel() {
      this.stringFiltro = null;
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.itensFiltroEsc = [];
      this.painelAplicado = false;
      this.TituloPainel = null;
      this.DetalhesPainel = null;
      this.itemInvest = this.top500Invest;
    },
    LimpaFiltro() {
      this.stringFiltro = null;
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.itensFiltroEsc = [];
      this.dtInicial = "";
      this.dtFinal = "";
      if (!this.painelAplicado) {
        this.itemInvest = this.top500Invest;
      } else {
        this.meusLeads();
      }
    },
    async filtraRegistro(campo, stringFiltro) {
      if (!campo || !stringFiltro) return;

      let WHERE = "";

      if (campo === "Nome") {
        WHERE = `ZZQ_NOME LIKE '%${stringFiltro.toUpperCase()}%'`;
      }

      if (campo === "E-mail") {
        WHERE = `ZZQ_EMAIL LIKE '%${stringFiltro.toLowerCase()}%'`;
      }

      if (campo === "Telefone") {
        WHERE = `ZZQ_TEL LIKE '%${stringFiltro}%'`;
      }

      if (campo === "Status") {
        WHERE = `ZZQ_STATUS = '${stringFiltro}'`;
      }

      if (campo === "Qualificado") {
        WHERE = `ZZQ_QUALIF = '${stringFiltro}'`;
      }

      if (campo === "Situação") {
        WHERE = `ZZQ_SITUAC = '${stringFiltro}'`;
      }

      if (campo === "Responsável") {
        const codResponsavel = stringFiltro.split("-");

        WHERE = `ZZQ_RESP = '${codResponsavel[0]}'`;
      }

      if (!this.isAdmin) {
        if (this.painelAplicado) {
          WHERE += ` AND ZZQ_RESP='${this.user.id}'`;
        }
      } else {
        const dtIni = this.dtInicial.split("/");
        const dtFim = this.dtFinal.split("/");

        WHERE += ` AND ZZQ_DTINC BETWEEN '${dtIni[2]}${dtIni[1]}${dtIni[0]}' AND '${dtFim[2]}${dtFim[1]}${dtFim[0]}'`;
      }

      WHERE += " ORDER BY ZZQ_ID DESC";

      this.loading = true;
      this.itemInvest = [];

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: WHERE,
        TABELA: "ZZQ",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/leads`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            if (e.ZZQ_DTINC) {
              e.ZZQ_DTINC = `${e.ZZQ_DTINC.substr(6, 2)}/${e.ZZQ_DTINC.substr(
                4,
                2,
              )}/${e.ZZQ_DTINC.substr(0, 4)}`;
            }
            if (e.ZZR_DESCRI) {
              e.ZZQ_STATUS = `${e.ZZQ_STATUS}-${e.ZZR_DESCRI}`;
            }
            if (e.ZZQ_SITUAC === "N") e.ZZQ_SITUAC = "Novo";
            if (e.ZZQ_SITUAC === "A") e.ZZQ_SITUAC = "Aberto";
            if (e.ZZQ_SITUAC === "E") e.ZZQ_SITUAC = "Encerrado";
            if (e.ZZQ_SITUAC === "S") e.ZZQ_SITUAC = "Sem contato";
            if (e.ZZQ_QUALIF === "1") e.ZZQ_QUALIF = "Sim";
            if (e.ZZQ_QUALIF === "2") e.ZZQ_QUALIF = "Não";

            if (e.ZZQ_PESTRA && e.ZZQ_PESTRA === "M") e.ZZQ_PESTRA = "Min";
            if (e.ZZQ_PESTRA && e.ZZQ_PESTRA === "F") e.ZZQ_PESTRA = "Familiar";
            if (e.ZZQ_PESTRA && e.ZZQ_PESTRA === "A") e.ZZQ_PESTRA = "Amigo";

            if (e.ZZQ_ISCLI === "1") e.ZZQ_ISCLI = "Sim";
            if (e.ZZQ_ISCLI === "2") e.ZZQ_ISCLI = "Não";

            if (e.ZZQ_LAUDOM === "1") e.ZZQ_LAUDOM = "Sim";
            if (e.ZZQ_LAUDOM === "2") e.ZZQ_LAUDOM = "Não";

            if (e.ZZQ_APUSER === "1") e.ZZQ_APUSER = "Sim";
            if (e.ZZQ_APUSER === "2") e.ZZQ_APUSER = "Não";
            e.ZZQ_MARQ = false;
          });
          this.itemInvest = res.data;
          this.NrRegFiltro = this.itemInvest.length;
        })
        .catch(err => console.log(err));

      this.loading = false;
    },
    VerDetalhes(item) {
      this.codCli = item.A1_COD;
      this.lojaCli = item.A1_LOJA;
      const componenteFilho = this.$children.find(
        component => component.$options.name === "DetCli",
      );
      componenteFilho.buscaDados(item.A1_COD, item.A1_LOJA, item.A1_NOME);
    },
    obrigat(value, obrigatorio) {
      if (!value && obrigatorio === "1") {
        return ["Campo obrigatório!"];
      }
    },
    queryAndIndeterminate() {
      this.progress = 0;

      this.interval = setInterval(() => {
        if (this.progress === 100) {
          clearInterval(this.interval);
          this.progress = 0;
          return;
          //return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.progress += 5;
      }, 1000);
    },
    //Função para remover caracteres especiais na Notas
    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");
      return text.toUpperCase();
    },
    async salva(id) {
      const keysObrigat = [];
      this.camposTela.filter(e => {
        if (e.ZZP_OBRIGA === "1") {
          const campo = e.ZZP_CAMPO.trim();
          keysObrigat.push({
            campo,
          });
        }
      });

      let verifica = true;

      this.cloneDadosTela = this.dadosTela;

      const keys = Object.keys(this.cloneDadosTela);

      keysObrigat.forEach(e => {
        if (keys.indexOf(e.campo) < 0) {
          verifica = false;
        }
      });

      if (!verifica) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      let cidade = ``;

      this.camposTela.forEach(e => {
        let value = null;
        if (
          e.ZZP_TIPO.trim() === "A" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("-");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
          cidade = value[1];
        }
        if (
          e.ZZP_TIPO.trim() === "S" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("=");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
        }
        if (
          e.ZZP_TIPO.trim() === "D" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("/");
          this.cloneDadosTela[
            e.ZZP_CAMPO.trim()
          ] = `${value[2]}${value[1]}${value[0]}`;
        }
        // if (
        //   e.ZZP_TIPO.trim() === "T" &&
        //   this.cloneDadosTela[e.ZZP_CAMPO.trim()] &&
        //   this.cloneDadosTela[e.ZZP_CAMPO.trim()] !== "ZZQ_EMAIL"
        // ) {
        //   value = this.removeAcento(this.cloneDadosTela[e.ZZP_CAMPO.trim()]);
        //   this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value.trim();
        // }
        if (
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] &&
          e.ZZP_TIPO.trim() === "Y"
        ) {
          value = this.removeAcento(this.cloneDadosTela[e.ZZP_CAMPO.trim()]);
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value.trim();
        }
      });
      let mes = ``;
      if (this.cloneDadosTela["ZW1_MES"].trim() === "01") mes = "JANEIRO";
      if (this.cloneDadosTela["ZW1_MES"].trim() === "02") mes = "FEVEREIRO";
      if (this.cloneDadosTela["ZW1_MES"].trim() === "03") mes = "MARCO";
      if (this.cloneDadosTela["ZW1_MES"].trim() === "04") mes = "ABRIL";
      if (this.cloneDadosTela["ZW1_MES"].trim() === "05") mes = "MAIO";
      if (this.cloneDadosTela["ZW1_MES"].trim() === "06") mes = "JUNHO";
      if (this.cloneDadosTela["ZW1_MES"].trim() === "07") mes = "JULHO";
      if (this.cloneDadosTela["ZW1_MES"].trim() === "08") mes = "AGOSTO";
      if (this.cloneDadosTela["ZW1_MES"].trim() === "09") mes = "SETEMBRO";
      if (this.cloneDadosTela["ZW1_MES"].trim() === "10") mes = "OUTUBRO";
      if (this.cloneDadosTela["ZW1_MES"].trim() === "11") mes = "NOVEMBRO";
      if (this.cloneDadosTela["ZW1_MES"].trim() === "12") mes = "DEZEMBRO";

      if (!this.isEditing) {
        const verDupli = this.itemInvest.filter(e => {
          if (
            e.ZW1_ANO.trim() === this.cloneDadosTela["ZW1_ANO"].trim() &&
            e.ZW1_MES.trim() === mes.trim() &&
            e.ZW1_CDIBGE.trim() === this.cloneDadosTela["ZW1_CIDADE"].trim()
          ) {
            return true;
          }
        });

        if (verDupli[0]) {
          this.iconMessage = "mdi-close";
          this.colorMessage = "red lighten-4";
          this.message = `Investimento já incluído!`;
          this.sheetMessage = true;
          return;
        }
      }

      this.blqBotao = true;
      this.queryAndIndeterminate();
      if (id) {
        let ZW1 = { ...this.cloneDadosTela };

        ZW1.ZW1_ID = id;
        ZW1.ZW1_EST = ZW1.ZW1_EST.trim();
        ZW1.ZW1_METALD = parseInt(ZW1.ZW1_METALD);
        ZW1.ZW1_CDIBGE = ZW1.ZW1_CIDADE;
        ZW1.ZW1_CIDADE = cidade;

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZW1,
        };

        let url = `${process.env.VUE_APP_BASE_API_URL}/investimento`;
        if (!this.isDelete) {
          await axios
            .put(url, dados)
            .then(res => {
              if (res.data.STATUS === "true") {
                this.idSalvo = res.data.CODIGO;
                this.mensagem = `Investimento alterado com sucesso`;
                this.dialogAwaitSaveCliente = false;
                this.dialogSuccessSaveCliente = true;
                this.progress = 100;
              } else {
                this.aError = [];
                const err = res.data.ERRO.split("\n");
                err.forEach(e => {
                  this.aError.push({ text: `${e}`, icon: "mdi-send" });
                });
                this.dialogAwaitSaveCliente = false;
                this.dialogErrosCliente = true;
                this.progress = 100;
                return;
              }
            })
            .catch(err => {
              this.aError = [];
              this.aError.push({ text: `${err}`, icon: "mdi-send" });

              this.dialogAwaitSaveCliente = false;
              this.dialogErrosCliente = true;
              this.progress = 100;
              return;
            });
        } else {
          url = `${process.env.VUE_APP_BASE_API_URL}/delinvest`;

          await axios
            .post(url, dados)
            .then(res => {
              if (res.data.STATUS === "true") {
                this.idSalvo = res.data.CODIGO;
                this.mensagem = `Investimento deletado com sucesso`;
                this.dialogAwaitSaveCliente = false;
                this.dialogSuccessSaveCliente = true;
                this.progress = 100;
              } else {
                this.aError = [];
                const err = res.data.ERRO.split("\n");
                err.forEach(e => {
                  this.aError.push({ text: `${e}`, icon: "mdi-send" });
                });
                this.dialogAwaitSaveCliente = false;
                this.dialogErrosCliente = true;
                this.progress = 100;
                return;
              }
            })
            .catch(err => {
              this.aError = [];
              this.aError.push({ text: `${err}`, icon: "mdi-send" });

              this.dialogAwaitSaveCliente = false;
              this.dialogErrosCliente = true;
              this.progress = 100;
              return;
            });
        }
      } else {
        let ZW1 = { ...this.cloneDadosTela };

        ZW1.ZW1_EST = ZW1.ZW1_EST.trim();
        ZW1.ZW1_METALD = parseInt(ZW1.ZW1_METALD);
        ZW1.ZW1_CDIBGE = ZW1.ZW1_CIDADE;
        ZW1.ZW1_CIDADE = cidade;
        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZW1,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/investimento`;
        await axios
          .post(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = `Investimento salvo com sucesso`;
              this.$store.commit("SetReload", true);
              this.dialogAwaitSaveCliente = false;
              this.dialogSuccessSaveCliente = true;
              setTimeout(() => {
                this.reloadSave();
              }, 3000);
              this.progress = 100;
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({ text: `${e}`, icon: "mdi-send" });
              });
              this.dialogAwaitSaveCliente = false;
              this.dialogErrosCliente = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => console.log(err));
      }

      this.progress = 100;
      this.blqBotao = false;
    },
    limpaVariaveis() {
      this.DialogDados = false;
      this.dialogSuccessSaveCliente = false;

      this.itemInvest.map(e => {
        e.ZW1_INVEST = e.ZW1_INVEST.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      });

      this.isEditing = false;
      this.isNew = false;
      this.isReading = false;
    },
    async carregaDados() {
      this.boilerplate = false;
      let campos = [];

      this.countObrigatorio = null;

      let ZZP = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === "MD011" && e.ZZP_USADO === "1",
      );

      ZZP.map(e => {
        if (e.ZZP_CBOX.trim().length > 0) {
          const dados = e.ZZP_CBOX.split(";");
          e.ITEMS = dados.map(i => {
            return `${i}`;
          });
        }

        if (e.ZZP_TIPO.trim() === "D") {
          e.MASK = "##/##/####";
        }
        if (e.ZZP_OBRIGA === "1") {
          this.countObrigatorio++;
        }
      });

      campos = ZZP;

      this.camposTela = campos;

      this.boilerplate = true;
    },
    async reload(item) {
      item.ITEMS = await this.chamaConsulta(item);

      this.camposTela.map(e => {
        if (e.ZZP_CAMPO.trim() === item.ZZP_CAMPO.trim()) {
          e.ITEMS = item.ITEMS;
        }
      });

      return this.camposTela;
    },
    chamaConsulta(item) {
      let dados = {};
      let items = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: ``,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      return items;
    },
    async montaBrowser() {
      //this.boilerplate = false;
      this.filtroEscolhido = null;
      this.stringFiltro = null;
      this.NrRegFiltro = null;
      this.altura = window.screen.availHeight - 300;
      this.loading = true;
      let ZZP = [];
      let dados = {};
      let url = ``;
      this.headersInvest = [];
      this.headersInvest.push({
        text: "Ações",
        value: "actions",
        align: "start",
        width: 100,
        caption: "title",
      });

      ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD011");

      ZZP.map(e => {
        let width = 0;
        if (e.ZZP_COLS <= 2) {
          width = 130;
        } else if (e.ZZP_COLS <= 3) {
          width = 200;
        } else {
          width = 250;
        }
        const name = `${e.ZZP_CAMPO.trim()}`;
        if (name === "ZW1_ANO") {
          this.headersInvest.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: width,
            caption: "title",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["ZW1_ANO"].toLowerCase());
            },
          });
        }
        if (name === "ZW1_MES") {
          this.headersInvest.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: width,
            caption: "title",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["ZW1_MES"].toLowerCase());
            },
          });
        }
        if (name === "ZW1_EST") {
          this.headersInvest.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: width,
            caption: "title",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["ZW1_EST"].toLowerCase());
            },
          });
        }
        if (name === "ZW1_CIDADE") {
          this.headersInvest.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: width,
            caption: "title",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["ZW1_CIDADE"].toLowerCase());
            },
          });
        }
        if (name === "ZW1_INVEST") {
          this.headersInvest.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "right",
            width: width,
            caption: "title",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["ZW1_INVEST"].toLowerCase());
            },
          });
        }
        if (name === "ZW1_METALD") {
          this.headersInvest.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: width,
            caption: "title",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["ZW1_METALD"].toLowerCase());
            },
          });
        }
      });

      const tabelaAux = [...new Set(ZZP.map(item => item.ZZP_TABELA))];

      this.stringCampos = "";

      let tabela = tabelaAux[0];

      ZZP.forEach(e => {
        if (e.ZZP_TABELA === tabela) {
          if (e.ZZP_TIPO === "Y") {
            this.stringCampos += `CONVERT(VARCHAR(2000), ${e.ZZP_CAMPO.trim()}) ${e.ZZP_CAMPO.trim()},`;
          } else {
            this.stringCampos += `${e.ZZP_CAMPO.trim()},`;
          }
        }
      });

      if (this.stringCampos.substr(-1) === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: ``,
        TABELA: tabela.substr(0, 3),
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/investimentos`;

      this.itemInvest = [];

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.ZW1_INVEST = e.ZW1_INVEST.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if (e.ZW1_MES === "01") e.ZW1_MES = "01=JANEIRO";
            if (e.ZW1_MES === "02") e.ZW1_MES = "02=FEVEREIRO";
            if (e.ZW1_MES === "03") e.ZW1_MES = "03=MARCO";
            if (e.ZW1_MES === "04") e.ZW1_MES = "04=ABRIL";
            if (e.ZW1_MES === "05") e.ZW1_MES = "05=MAIO";
            if (e.ZW1_MES === "06") e.ZW1_MES = "06=JUNHO";
            if (e.ZW1_MES === "07") e.ZW1_MES = "07=JULHO";
            if (e.ZW1_MES === "08") e.ZW1_MES = "08=AGOSTO";
            if (e.ZW1_MES === "09") e.ZW1_MES = "09=SETEMBRO";
            if (e.ZW1_MES === "10") e.ZW1_MES = "10=OUTUBRO";
            if (e.ZW1_MES === "11") e.ZW1_MES = "11=NOVEMBRO";
            if (e.ZW1_MES === "12") e.ZW1_MES = "12=DEZEMBRO";

            e.ZW1_CIDADE = `${e.ZW1_CDIBGE}-${e.ZW1_CIDADE}`;
          });
          this.itemInvest = [];
          this.top500Invest = [];
          this.itemInvest = res.data;
          this.top500Invest = res.data;
        })
        .catch(err => console.log(err));

      this.perPage = this.itemInvest.length;
      this.loading = false;
      this.boilerplate = true;
    },
    verDados(item) {
      this.carregaDados();
      this.titleDialog = `Visualizando o Lead: ${item.ZZQ_ID}-${item.ZZQ_NOME}`;
      this.dadosTela = item;

      if (this.dadosTela.ZZQ_SITUAC === "Novo")
        this.dadosTela.ZZQ_SITUAC = "N=Novo";
      if (this.dadosTela.ZZQ_SITUAC === "Aberto")
        this.dadosTela.ZZQ_SITUAC = "A=Aberto";
      if (this.dadosTela.ZZQ_SITUAC === "Eencerrado")
        this.dadosTela.ZZQ_SITUAC = "E=Encerrado";
      if (this.dadosTela.ZZQ_SITUAC === "Sem contato")
        this.dadosTela.ZZQ_SITUAC = "S=Sem contato";
      if (this.dadosTela.ZZQ_QUALIF === "Sim")
        this.dadosTela.ZZQ_QUALIF = "1=Sim";
      if (this.dadosTela.ZZQ_QUALIF === "Não")
        this.dadosTela.ZZQ_QUALIF = "2=Não";

      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Min")
        this.dadosTela.ZZQ_PESTRA = "M=Min";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Familiar")
        this.dadosTela.ZZQ_PESTRA = "F=Familiar";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Amigo")
        this.dadosTela.ZZQ_PESTRA = "A=Amigo";

      if (this.dadosTela.ZZQ_ISCLI === "Sim")
        this.dadosTela.ZZQ_ISCLI = "1=Sim";
      if (this.dadosTela.ZZQ_ISCLI === "Não")
        this.dadosTela.ZZQ_ISCLI = "2=Não";

      if (this.dadosTela.ZZQ_LAUDOM === "Sim")
        this.dadosTela.ZZQ_LAUDOM = "1=Sim";
      if (this.dadosTela.ZZQ_LAUDOM === "Não")
        this.dadosTela.ZZQ_LAUDOM = "2=Não";

      if (this.dadosTela.ZZQ_APUSER === "Sim")
        this.dadosTela.ZZQ_APUSER = "1=Sim";
      if (this.dadosTela.ZZQ_APUSER === "Não")
        this.dadosTela.ZZQ_APUSER = "2=Não";
      this.isReading = true;
      this.isEditing = false;
      this.isNew = false;
      this.DialogDados = true;
    },
    editItem(item) {
      this.carregaDados();
      this.isNew = false;
      this.titleDialog = `Alterando o investimento: ${item.ZW1_ANO}-${item.ZW1_MES}-${item.ZW1_CIDADE}`;
      this.id = `${item.ZW1_ID}`;

      this.isEditing = true;
      this.dadosTela = item;
      this.isReading = false;
      this.DialogDados = true;
    },
    deleteItem(item) {
      this.carregaDados();
      this.isNew = false;
      this.titleDialog = `Deletar o investimento: ${item.ZW1_ANO}-${item.ZW1_MES}-${item.ZW1_CIDADE}`;
      this.id = `${item.ZW1_ID}`;

      this.isEditing = false;
      this.isDelete = true;
      this.dadosTela = item;
      this.isReading = false;
      this.DialogDados = true;
    },
    async carregaItems(item) {
      let dados = {};
      let items = [];
      this.itemsTipo = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";
      let where = ``;

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        if (
          item.ZZP_CAMPO.trim() === "ZW1_CIDADE" &&
          this.dadosTela["ZW1_EST"].trim() !== ""
        ) {
          const estado = this.dadosTela["ZW1_EST"].split("-");
          where = `CC2_EST = '${estado[0].trim()}' ORDER BY CC2_MUN`;
        }
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: where,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
          this.itemsTipo = items;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    novo() {
      this.dadosTela = [];
      this.isReading = false;
      this.isNew = true;
      this.titleDialog = `Incluindo Investimento`;
      this.carregaDados();
      this.DialogDados = true;
    },
    Custonfiltro(indice) {
      const search = this.search.toString().toLowerCase();

      if (indice === "N") {
        return this.itemInvest.filter(i => {
          i["A1_NOME"] === search;
        });
      }
      if (indice === "C") {
        return this.itemInvest.filter(i => {
          i["A1_COD"] === search;
        });
      }
      if (indice === "F") {
        return this.itemInvest.filter(i => {
          i["A1_CGC"] === search;
        });
      }
    },
  },
  mounted() {
    this.altura = window.screen.availHeight - 100;
    if (
      this.user.isAdmin &&
      this.user.NivelAdmin.map(e => e.trim()).includes("1")
    ) {
      this.isAdmin = true;
      this.items = [
        { title: "Meus lead(s)", icon: "mdi-home-city", function: "meusLeads" },
        {
          title: "Meus lead(s) com retorno até hoje",
          icon: "mdi-account",
          function: "leadsRetorno",
        },
        {
          title: "Meus Lead(s) com cliente vinculado em aberto",
          icon: "mdi-account",
          function: "leadsCliAberto",
        },
        {
          title: "Atribuir lead(s)",
          icon: "mdi-account-group-outline",
          function: "atribuirLeads",
        },
        {
          title: "Transferir Lead(s)",
          icon: "mdi-account-group-outline",
          function: "transfereLead",
        },
        { title: "Histórico do Lead", function: "histLead" },
      ];
    } else {
      this.items = [
        { title: "Meus lead(s)", icon: "mdi-home-city", function: "meusLeads" },
        {
          title: "Meus lead(s) com retorno até hoje",
          icon: "mdi-account",
          function: "leadsRetorno",
        },
        {
          title: "Meus Lead(s) com cliente vinculado em aberto",
          icon: "mdi-account",
          function: "leadsCliAberto",
        },
        {
          title: "Atribuir lead(s)",
          icon: "mdi-account-group-outline",
          function: "atribuirLeads",
        },
        {
          title: "Transferir meu(s) Lead(s)",
          icon: "mdi-account-group-outline",
          function: "transfereLead",
        },
        { title: "Histórico do Lead", function: "histLead" },
      ];
      this.isAdmin = false;
    }

    this.montaBrowser();
  },
};
</script>

<style>
.v-label {
  font-size: 0.7rem;
}
input {
  font-size: 0.8rem;
}
.text-center {
  font-size: 0.7rem !important;
}
.text-start {
  font-size: 0.7rem !important;
}
tbody tr:nth-of-type(odd) {
  background-color: #d8f6fa;
}
.v-select__selection {
  font-size: 0.8rem !important;
}
.scroll {
  overflow-y: scroll;
}
.painel {
  overflow-x: scroll;
}
</style>
