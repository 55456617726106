
export default {
    state: {
      optionsMenu: [],
      trataAlcada: false,
      dadosTratamento: []
    },
    mutations: {
      setOptionsMenu(state, payload) {
       
        state.optionsMenu = payload
      },
      setTrataAlcada(state, payload) {
       
        state.trataAlcada = payload
      },
      setDadosTratamento(state, payload) {
       
        state.dadosTratamento = payload
      }
    }
  };