<template>
  <div class="text-center">
    <v-dialog v-model="dialogNull" width="500" persistent>
      <v-card>
        <v-card-title class="headline red lighten-4">
          <span style="color: #B71C1C;">OPS!!!</span>
        </v-card-title>

        <v-card-text class="mt-3">
          <span style="font-size: 1rem; color:black;">{{
            mensagemDialogOps
          }}</span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="fechaDialog">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "dialogNull",
  computed: mapState(["dialogNull", "mensagemDialogOps"]),
  methods: {
    fechaDialog() {
      this.$store.commit("SetdialogNull", false);
      this.$store.commit("SetMensagemDialogOps", null);
    },
  },
};
</script>

<style></style>
