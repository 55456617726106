<template>
  <div class="my-4 mx-8">
    <v-row class="px-8">
      <v-col cols="2">
        <v-select
          class="rounded-lg ml-5"
          v-model="anoEscolhido"
          :items="yearList"
          label="Escolha o ano"
          outlined
          dense
          @change="buscaDados()"
        ></v-select>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          class="rounded-lg ml-5"
          v-model="trimestre"
          :items="quartersList"
          label="Escolha o Trimestre"
          outlined
          dense
          @change="buscaDados()"
        ></v-autocomplete>
      </v-col>

      <v-col cols="2">
        <v-autocomplete
          v-if="user.isAdmin"
          class="rounded-lg ml-5"
          v-model="filialVendedor"
          :items="filialList"
          label="Loja"
          outlined
          dense
          :disabled="!user.isAdmin"
          @change="buscaDados()"
        ></v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <h3 class="my-4">
        Consulta realizada em {{ new Date().toLocaleString("pt-BR") }}
      </h3>
    </v-row>
    <v-card class="elevation-0">
      <v-card-title>
        <v-row no-gutters>
          <span>
            Metas do {{ quarterName }} de {{ anoEscolhido }} por Categoria de
            Produtos
          </span>
          <v-spacer></v-spacer>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field> -->
        </v-row>
      </v-card-title>

      <!-- <v-data-table
        :headers="headerTable"
        :items="itensTable"
        :search="search"
        :loading="loadingTable"
        dense
        fixed-header
        hide-default-footer
        :items-per-page="itensTable.length"
        no-data-text="Sem metas no momento"
        class="tbCamp elevation-1"
        :height="height"
      >
        <template v-slot:[`item.ATINGIDO`]="{ item }">
          <v-progress-linear
            v-if="item.ATINGIDO <= 0"
            dark
            indeterminate
            color="blue"
            height="22"
            readonly
          >
            <template v-slot:default><strong>Calculando...</strong></template>
          </v-progress-linear>
          <v-progress-linear
            v-else
            :dark="item.ATINGIDO < 30"
            v-model="item.ATINGIDO"
            :color="legenda(item.ATINGIDO)"
            style="pointer-events: none"
            height="22"
            readonly
            stream
          >
            <template v-slot:default="{ value }">
              <strong>{{ value }}%</strong>
            </template>
          </v-progress-linear>
        </template>
      </v-data-table> -->
    </v-card>
    <div class="d-flex flex-wrap justify-space-around alig-center">
      <!-- Dialog para aguardar -->
      <v-dialog v-model="dialogAguarde" persistent width="13vw">
        <div
          class="d-flex justify-center align-center"
          style="height: 60px;background-color: azure;"
        >
          <span>Buscando dados...</span>
          <v-progress-circular
            :width="3"
            color="green"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>
      <v-card v-for="item in itensTable" :key="item.CATEGORIA" class="mb-3">
        <v-card-title class="d-flex justify-center">{{
          item.CATEGORIA
        }}</v-card-title>
        <v-card-text>
          <bar-chart
            :chartData="{
              label: item.CATEGORIA,
              labels: ['META', `VENDIDO ${item.ATINGIDO}%`],
              datasets: [
                {
                  label: [],
                  barPercentage: 0.5,
                  minBarLength: 2,
                  backgroundColor: ['#82B1FF', '#00E676'],
                  data: [100, item.ATINGIDO],
                },
              ],
            }"
            :options="{
              responsive: true,
              scales: {
                xAxes: [
                  {
                    ticks: {
                      maxRotation: 0,
                      minRotation: 0,
                      font: {
                        weight: ['bold', 'bold'],
                      },
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      font: {
                        weight: ['bold', 'bold'],
                      },
                    },
                  },
                ],
              },
            }"
          ></bar-chart>
          <div class="d-flex flex-column align-center">
            <span class="black--text">Meta: {{ item.META }}</span>
            <span class="black--text">Vendido: {{ item.TOTAL }}</span>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { useStore } from "@/config/store";
import { useToast } from "@/main";
import { api } from "@/services/api";
import { getQuarter } from "date-fns";
import { computed, onMounted, ref } from "vue";
import BarChart from "@/components/templates/Charts/Barchart";

export default {
  //composition-api setup
  components: {
    BarChart,
  },
  setup() {
    /* HOOKS */
    const store = useStore();
    const toast = useToast();

    /* DATA */
    const user = ref(store.state.user);
    const height = ref(window.screen.availHeight - 320);
    const search = ref("");
    const loadingTable = ref(false);
    const dialogAguarde = ref(false);
    const headerTable = ref([]);
    const itensTable = ref([]);
    const yearList = ref([]);
    const quartersList = ref([]);
    const filialList = ref([]);
    const anoEscolhido = ref(new Date().getFullYear());
    const trimestre = ref(`0${getQuarter(new Date())}`);
    const filialVendedor = ref("");

    /* COMPUTED */
    const quarterName = computed(() => {
      return quartersList.value.find(item => item.value === trimestre.value)
        ?.text;
    });

    /* METHODS */
    async function buscaDados() {
      if (!filialVendedor.value) {
        toast.error("Usuário sem filial no cadastro", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      loadingTable.value = true;
      dialogAguarde.value = true;
      itensTable.value = [];
      try {
        const response = await api.get(
          `/metas/produtosCategoria/${filialVendedor.value}/${anoEscolhido.value}/${trimestre.value}`,
        );

        console.log(response.data.itens);

        headerTable.value = response.data.header;
        itensTable.value = response.data.itens;
      } catch (error) {
        console.log(error);
      }
      loadingTable.value = false;
      dialogAguarde.value = false;
    }

    function legenda(valor) {
      if (!valor) return "grey accent-4";
      if (valor <= 30) {
        return "red accent-4";
      }
      if (valor > 30 && valor <= 50) {
        return "orange accent-4";
      }
      if (valor > 50 && valor <= 80) {
        return "yellow accent-4";
      }
      if (valor > 80) {
        return "green accent-4";
      }
    }

    /* LIFE-CYCLE */
    onMounted(async () => {
      quartersList.value = [
        { text: "1º trimestre", value: "01" },
        { text: "2º trimestre", value: "02" },
        { text: "3º trimestre", value: "03" },
        { text: "4º trimestre", value: "04" },
      ];

      for (let y = 2020; y <= new Date().getFullYear(); y++) {
        yearList.value.push({ text: y, value: y });
      }

      if (user.value.isAdmin) {
        try {
          const response = await api.get("/filiais");
          filialList.value = response.data;
          filialVendedor.value = "0101";
        } catch (error) {
          console.log(error);
        }
      } else {
        filialVendedor.value = user.value.filialVendedor;
      }

      buscaDados();
    });

    /* THIS */
    return {
      user,
      height,
      search,
      anoEscolhido,
      trimestre,
      filialVendedor,
      loadingTable,
      dialogAguarde,
      headerTable,
      itensTable,
      buscaDados,
      legenda,
      filialList,
      quartersList,
      yearList,
      quarterName,
      onMounted,
    };
  },
};
</script>

<style>
.theme--light.v-data-table.tbCamp tbody tr.v-data-table__selected {
  background: #0fcbe1 !important;
}

.theme--light.v-data-table.tbCamp tbody tr.v-data-table__selected {
  background: #0fcbe1 !important;
}

.v-data-table.tbCamp tbody tr :hover {
  cursor: pointer;
}
</style>
