<template>
  <div>
    <v-dialog v-model="dialogAguarde" persistent width="20%">
      <div
        class="d-flex justify-center align-center"
        style="height: 60px;background-color: azure;"
      >
        <span>Buscando dados...</span>
        <v-progress-circular
          :width="3"
          color="green"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>
    <div
      class="d-flex flex-column justify-center align-center ma-3 mt-5"
      v-if="dadosOK && !titleStatus"
    >
      <span class="title">Atendimento {{ dadosTratamento.Solicitacao }}</span>
      <v-radio-group v-model="tratamento" row>
        <v-radio label="Aprova" value="A"></v-radio>
        <v-radio label="Reprova" value="R"></v-radio>
      </v-radio-group>

      <v-textarea
        v-model="obs"
        name="input-7-1"
        label="Observação"
        hint="Obervação obrigatória somente na reprova!"
        style="width: 250px"
        counter="250"
      ></v-textarea>

      <div class="d-flex justify-center align-center mt-3" style="width: 15vw;">
        <v-btn class="primary" x-small @click="enviaTratamento">Enviar</v-btn>
      </div>
    </div>
    <div
      class="d-flex flex-column justify-center align-center ma-3 mt-5"
      style="height: 95vh;"
      v-if="!dadosOK && !titleStatus"
    >
      <span style="font-size: 32px; color:red;">Link quebrado!</span>
    </div>
    <div
      class="d-flex flex-column justify-center align-center ma-3 mt-5"
      style="height: 95vh;"
      v-if="titleStatus"
    >
      <span style="font-size: 24px;">{{ titleStatus }}</span>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "trataAlcadas",
  computed: {
    ...mapState({
      dadosTratamento: state => state.menu.dadosTratamento,
      user: state => state.user,
    }),
  },
  data: () => {
    return {
      tratamento: "A",
      obs: null,
      dadosOK: true,
      titleStatus: null,
      dialogAguarde: false,
    };
  },
  methods: {
    async enviaTratamento() {
      if (!this.obs && this.tratamento === "R") {
        this.$toast.error("Uma Observação é obrigatória!", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      let dados = {
        FIELDS: `ZM5_STATUS STATUS,ZM5_CATEG,ZM5_USER,ZM5_PDESC,ZM5_UNIBI,R_E_C_N_O_ RECNO`,
        WHERE: `ZM5_FILIAL='${this.dadosTratamento.Filial}' AND ZM5_NDOC='${this.dadosTratamento.Solicitacao}' AND ZM5_STATUS='1'`,
        TABELA: "ZM5",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const dadosTratamentoSolicitacao = await axios.post(url, dados);

      let percentFora = false;
      let linhasAprovadas = 0;

      for (const linha of dadosTratamentoSolicitacao.data) {
        const dados = {
          FIELDS: `ZM6_APROV APROVADOR,ZM2_TIPO TIPO,ZM2_ID CATEGORIA,ZM2_PRDUNI REF_UNI,ZM2_PDUNI PERC_UNI,ZM2_PRDBI REF_BI,ZM2_PDBI PERC_BI,ZM2_PRDCPD REF_PP,ZM2_PDCATP PERC_PP`,
          WHERE: `ZM6_ID=(SELECT ISNULL(ZM4_ID, '') FROM ZM4010 WHERE ZM4_USER='${linha.ZM5_USER}')
                  AND ZM2_ID='${linha.ZM5_CATEG}'
                  AND ZM6_APROV='000002' `,
          INNER: `INNER JOIN ZM2010 ZM2 WITH(NOLOCK) ON
                                        ZM2.D_E_L_E_T_=' '
                                        AND ZM2_FILIAL = ' '
                                        AND ZM2_TIPO=ZM6_TIPO
                                        AND ZM2_IDAREA=ZM6_IDAREA `,
          TABELA: "ZM6",
        };

        const dadosSolicitacao = await axios.post(url, dados);

        if (dadosSolicitacao.data.length <= 0) {
          this.titleStatus = `Não achei item(s) para sua aprovação no momento!`;
        }

        for (const tratamento of dadosSolicitacao.data) {
          let ref = 0;
          let perc = 0;
          if (linha.ZM5_UNIBI === "1") {
            ref = tratamento.REF_UNI;
            perc = tratamento.PERC_UNI;
          } else {
            ref = tratamento.REF_BI;
            perc = tratamento.PERC_BI;
          }
          if (linha.ZM5_PDESC >= ref && linha.ZM5_PDESC <= perc) {
            const url1 = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
            let dadosTrat = ``;
            if (this.tratamento === "R") {
              dadosTrat = {
                query: `UPDATE ZM5010 SET 
                            ZM5_STATUS='3',
                            ZM5_APROV='${this.user.usr_protheus}',
                            ZM5_NMAPRV='${this.user.name.substring(0, 15)}',
                            ZM5_OBSTRA='${this.obs}',
                            ZM5_TRATPT='S' 
                            WHERE R_E_C_N_O_=${linha.RECNO}`,
              };
            } else {
              dadosTrat = {
                query: `UPDATE ZM5010 SET 
                            ZM5_STATUS='2',
                            ZM5_APROV='${this.user.usr_protheus}',
                            ZM5_NMAPRV='${this.user.name.substring(0, 15)}',
                            ZM5_OBSTRA='${this.obs ? this.obs : ""}',
                            ZM5_TRATPT='S' 
                            WHERE R_E_C_N_O_=${linha.RECNO}`,
              };
            }

            await axios.post(url1, dadosTrat);
            linhasAprovadas++;
          } else {
            percentFora = true;
          }
        }
      }
      if (percentFora) {
        this.titleStatus = `Foram tratados ${linhasAprovadas} item(s). Percentual de desconto do aprovador fora do range de aprovação!`;
      }

      if (linhasAprovadas > 0) {
        this.titleStatus = `Foram tratados ${linhasAprovadas} item(s) com sucesso!`;

        dados = {
          FIELDS: `USR_EMAIL EMAIL, USR_NOME NOME`,
          WHERE: `USR_ID = '${dadosTratamentoSolicitacao.data[0].ZM5_USER}'`,
          TABELA: "SYS_USR",
        };

        const email = await axios.post(url, dados);

        dados = {
          FIELDS: `ZM5_STATUS,ZM5_NDOC,ZM5_ITEM,ZM5_APROV,ZM5_NMAPRV,ZM5_CLI,A1_NOME,ZM5_DPROD,ZM5_PROD,ZM5_OBSTRA`,
          WHERE: `ZM5_FILIAL='${this.dadosTratamento.Filial}' AND ZM5_NDOC='${this.dadosTratamento.Solicitacao}'`,
          INNER: `INNER JOIN SA1010 SA1 WITH(NOLOCK) ON SA1.D_E_L_E_T_=' ' AND A1_COD=ZM5_CLI `,
          TABELA: "ZM5",
        };

        const dadosEmail = await axios.post(url, dados);

        if (email.data.length > 0) {
          const urlEmail = `${process.env.VUE_APP_BASE_API_URL}/enviaTratAlcada`;
          const dadosEnvEmail = {
            para: email.data[0].EMAIL.trim(),
            // para: "evandro.souza@sansix.com.br",
            nome: email.data[0].NOME.trim(),
            dadosEmail: dadosEmail.data,
            observacao: this.obs,
          };

          await axios.post(urlEmail, dadosEnvEmail);
        }
      }
    },
    async validaDadosTratamento() {
      this.titleStatus = null;
      this.dialogAguarde = true;
      if (!this.dadosTratamento) {
        this.$toast.error("Sem dados para tratamento!", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.dadosOK = false;
      }
      if (
        !this.dadosTratamento.Filial ||
        !this.dadosTratamento.Aprovador ||
        !this.dadosTratamento.Solicitacao
      ) {
        this.$toast.error("Dados corrompidos ou faltantes!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.dadosOK = false;
      }

      const dados = {
        FIELDS: `DISTINCT ZM5_STATUS STATUS,ZM5_CATEG,ZM5_USER`,
        WHERE: `ZM5_FILIAL='${this.dadosTratamento.Filial}' AND ZM5_NDOC='${this.dadosTratamento.Solicitacao}'`,
        TABELA: "ZM5",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      console.log(dados);

      const status = await axios.post(url, dados);

      console.log(status);

      if (status.data.length <= 0) {
        this.$toast.error("Dados fora da base!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.dadosOK = false;
      } else if (status.data.length === 1) {
        status.data.map(e => {
          this.titleStatus = null;
          if (e.STATUS === "2") {
            this.titleStatus = `O atendimento ${this.dadosTratamento.Solicitacao}, já está Aprovado!`;
          }
          if (e.STATUS === "3") {
            this.titleStatus = `O atendimento ${this.dadosTratamento.Solicitacao}, está Reprovado!`;
          }
          if (e.STATUS === "4") {
            this.titleStatus = `O atendimento ${this.dadosTratamento.Solicitacao}, está não conformidade!`;
          }
        });
      }
      if (
        this.user.usr_protheus !== this.dadosTratamento.Aprovador &&
        this.user.usr_protheus !== "000000"
      ) {
        this.titleStatus = `O Aprovador esta diferente do link!`;
      }

      this.dialogAguarde = false;
    },
  },
  mounted() {
    this.$store.commit("setOptionsMenu", []);
    this.validaDadosTratamento();
  },
};
</script>
<style></style>
