<template>
  <div class="mr-4 pa-1 d-flex justify-center">
      <iframe 
        :width="width" 
        :height="height" 
        src="https://lookerstudio.google.com/embed/reporting/4b6a0a41-ef4a-4ec9-852b-286dd44abd09/page/dDA6C" 
        frameborder="0" 
        style="border:0" 
        allowfullscreen 
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </div>
</template>

<script>
export default {
  name: "ReportCampanhaAnuncioMeta",
  computed: {
    width: () => (window.screen.availWidth - 100),
    height: () => (window.screen.availHeight - 160)
  }
};
</script>
