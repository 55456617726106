<template>
  <div class="d-flex flex-column pa-3">
    <div class="d-flex align-center" style="width: 95vw;height: 10vh;">
      <v-row no-gutters>
        <v-col cols="1" class="ma-2">
          <v-select
            class="rounded-lg"
            v-model="anoEscolhido"
            :loading="loadingAnos"
            :items="anos"
            label="Escolha o ano"
            @change="buscaAreas"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="2" class="ma-2">
          <v-select
            :disabled="areas.length <= 0"
            class="rounded-lg"
            v-model="area"
            :items="areas"
            label="Escolha a área"
            @change="buscaDados"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="2" class="ma-2">
          <v-select
            :disabled="tiposPremio.length <= 0"
            class="rounded-lg"
            v-model="tipoPremio"
            :items="tiposPremio"
            label="Escolha o tipo de prêmio"
            @change="buscaDados"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="4" class="ma-2 mt-3" v-if="nomeArea">
          <span class="title">Resumo de metas da área {{ nomeArea }}</span>
        </v-col>
        <v-col cols="2" class="mt-3">
          <v-btn
            :disabled="!anoEscolhido"
            class="primary"
            x-small
            @click="buscaDados"
            >Buscar</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <div
      class="d-flex justify-space-around flex-wrap"
      style="width: 95vw;height: 83vh;"
    >
      <v-dialog v-model="dialogAguarde" persistent width="13vw">
        <div
          class="d-flex justify-center align-center"
          style="height: 60px;background-color: azure;"
        >
          <span>Buscando dados...</span>
          <v-progress-circular
            :width="3"
            color="green"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>
      <div
        class="mt-3"
        style="width: 18vw;"
        v-for="(area, ind) of resultadosAreas"
        :key="ind"
      >
        <div
          class="d-flex flex-row justify-center align-center pa-1"
          style="border: 1px solid #BDBDBD; width: 325px; background-color: #1976D2; color:white; border-radius: 5px;"
        >
          <span>{{ area[0].filial }}</span>
        </div>
        <div
          class="d-flex flex-row justify-space-around pa-1"
          style="border: 1px solid #BDBDBD; width: 325px; background-color: #1976D2; color:white;"
        >
          <span>Mês</span>
          <span>Meta</span>
        </div>
        <div v-for="(item, index) in area" :key="index">
          <div
            class="d-flex flex-row justify-space-around align-center pa-1"
            v-for="(itens, i) in metas.filter(m => m.trimestre === index + 1)"
            :key="i"
            style="border: 1px solid #BDBDBD; width: 325px;"
          >
            <span class="caption" style="width: 70px;">{{ itens.mes }}</span>
            <span
              class="caption"
              style="display: flex; justify-content: end;align-items: end;width: 100px;"
            >
              {{ area[index].meta }}</span
            >
          </div>
          <div
            class="d-flex flex-row justify-space-between pa-1"
            style="border: 1px solid #BDBDBD; width: 325px; background-color: #BBDEFB;"
          >
            <span class="ml-1">{{ item.title }}</span>
            <span class="mr-1">{{
              (area[index].metaCurr * 3).toLocaleString("pt-BR", {
                currency: "BRL",
                style: "currency",
              })
            }}</span>
          </div>
        </div>
        <div
          class="d-flex flex-row justify-space-between pa-1 mt-1"
          style="border: 1px solid #BDBDBD; width: 325px; background-color: #0D47A1;border-radius: 5px;"
        >
          <span class="title ml-1" style="color: white">TOTAL</span>
          <span class="title mr-1" style="color: white">{{
            area.totalFilial.toLocaleString("pt-BR", {
              currency: "BRL",
              style: "currency",
            })
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { stringToBrMoney } from "@/utils/stringParse";
import axios from "axios";
import { add, sub } from "date-fns";
import { mapState } from "vuex";

export default {
  name: "MetasAreas",
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  data: () => {
    return {
      anoEscolhido: null,
      loadingAnos: true,
      resultadosAreas: [],
      anos: [],
      tiposPremio: [],
      tipoPremio: null,
      area: null,
      nomeArea: null,
      areas: [],
      dialogAguarde: false,
      metas: [
        { mes: "Janeiro", tipo: "m", trimestre: 1 },
        { mes: "Fevereiro", tipo: "m", trimestre: 1 },
        { mes: "Março", tipo: "m", trimestre: 1 },
        { mes: "Abril", tipo: "m", trimestre: 2 },
        { mes: "Maio", tipo: "m", trimestre: 2 },
        { mes: "Junho", tipo: "m", trimestre: 2 },
        { mes: "Julho", tipo: "m", trimestre: 3 },
        { mes: "Agosto", tipo: "m", trimestre: 3 },
        { mes: "Setembro", tipo: "m", trimestre: 3 },
        { mes: "Outubro", tipo: "m", trimestre: 4 },
        { mes: "Novembro", tipo: "m", trimestre: 4 },
        { mes: "Dezembro", tipo: "m", trimestre: 4 },
      ],
    };
  },
  methods: {
    async buscaAreas() {
      this.area = null;
      this.areas = [];
      this.tipoPremio = null;
      this.tiposPremio = [];
      this.resultadosAreas = [];
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZAE_CODARE",
        WHERE: `ZAB_CODVEN='${this.user.cod_vendedor}' `,
        INNER: `INNER JOIN ZAE010 ZAE WITH(NOLOCK) ON ZAE.D_E_L_E_T_=' ' AND ZAE_IDPAI=ZAB_COD `,
        TABELA: "ZAB",
      };

      const areas = await axios.post(url, dados);

      areas.data.map(e => {
        this.areas.push(e.ZAE_CODARE?.trim());
      });

      if (this.areas.length <= 0) {
        this.$toast.error("Não achei areas para seu código!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }
    },
    async buscaAnos() {
      const anosSub = sub(new Date(), { years: 1 });

      for (let cont = 0; cont <= 1; cont++) {
        if (
          add(anosSub, { years: cont }).getFullYear() <=
          new Date().getFullYear()
        ) {
          this.anos.push(add(anosSub, { years: cont }).getFullYear());
        }
      }

      this.anos = this.anos.reverse();
      this.loadingAnos = false;
    },
    async buscaDados() {
      this.resultadosAreas = [];
      this.tiposPremio = [];
      if (!this.anoEscolhido) {
        this.$toast.error("Escolha o ano antes de buscar!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      let tipoMeta = "UN";
      let tipoZxd = "LJ";

      if (this.tipoPremio) {
        tipoMeta = this.tipoPremio?.split("-")[0];
      }

      if (["PPL01", "PPL02"].includes(this.area)) {
        tipoMeta = "PP";
        tipoZxd = "PP";
      }

      if (["PPF01", "PPF02"].includes(this.area)) {
        tipoMeta = "PF";
        tipoZxd = "PP";
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS:
          "DISTINCT ZXD_DIVIDE,ZXD_FILIAL,ZXD_FORMUL,ZXD_LOJA,ZXD_NOME,ZXD_PROSPE,ZXD_TIPO,ZXD_VEND,ZXE_META,ZXE_ANOTRI,ZXC_SIGLA",
        WHERE: `ZXC_SIGLA='${this.area}' AND ZXC_ANO='${this.anoEscolhido}' ORDER BY ZXD_LOJA`,
        INNER: `INNER JOIN ZXD010 ZXD WITH(NOLOCK) ON ZXD.D_E_L_E_T_=' ' AND ZXD_IDXC=ZXC_ID AND ZXD_TIPO='${tipoZxd}'
                INNER JOIN ZXE010 ZXE WITH(NOLOCK) ON ZXE.D_E_L_E_T_=' ' AND ZXE_TPCALC='${tipoMeta}' AND ZXE_LOJA=ZXD_LOJA AND LEFT(ZXE_ANOTRI,4)='${this.anoEscolhido}'`,
        TABELA: "ZXC",
      };

      if (["PPF01", "PPF02"].includes(this.area)) {
        dados.INNER = `INNER JOIN ZXD010 ZXD WITH(NOLOCK) ON ZXD.D_E_L_E_T_=' ' AND ZXD_IDXC=ZXC_ID AND ZXD_TIPO='${tipoZxd}'
                INNER JOIN ZXE010 ZXE WITH(NOLOCK) ON ZXE.D_E_L_E_T_=' ' AND ZXE_TPCALC='${tipoMeta}' AND ZXE_LOJA=ZXD_VEND AND LEFT(ZXE_ANOTRI,4)='${this.anoEscolhido}'`;
      }

      const area = await axios.post(url, dados);

      if (area.data.length <= 0) {
        this.$toast.error("Não achei a meta para este ano!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      if (
        ["A01", "A02", "A03", "A05", "A04", "A06"].includes(
          area.data[0].ZXC_SIGLA?.trim(),
        )
      ) {
        this.tiposPremio = [
          "UN-UNIDADE DE NEGÓCIO",
          "AS-ASSISTENTES",
          "FN-FONO",
          "OP-OPGRADE",
        ];
      }

      this.nomeArea = null;

      if (!this.tipoPremio) {
        this.nomeArea = `${area.data[0].ZXC_SIGLA} - UN-UNIDADE DE NEGÓCIO`;
        this.tipoPremio = "UN-UNIDADE DE NEGÓCIO";
      } else {
        this.nomeArea = `${area.data[0].ZXC_SIGLA} - ${this.tipoPremio}`;
      }

      this.dialogAguarde = true;

      let areas = [...new Set(area.data.map(e => e.ZXD_LOJA))];

      areas.map(e => {
        let resultTrimestre = [];

        for (let tri = 1; tri <= 4; tri++) {
          const existeMeta = area.data.filter(
            a => Number(a.ZXE_ANOTRI.substr(4, 2)) === tri && a.ZXD_LOJA === e,
          );

          if (existeMeta.length > 0) {
            resultTrimestre.push(
              ...area.data
                .filter(
                  a =>
                    Number(a.ZXE_ANOTRI.substr(4, 2)) === tri &&
                    a.ZXD_LOJA === e,
                )
                .map(a => {
                  return {
                    meta: stringToBrMoney(a.ZXE_META),
                    metaCurr: Number(a.ZXE_META.toFixed(2)),
                    trimestre: tri,
                    title: `${tri} Trimestre`,
                    filial: `${a.ZXD_LOJA} - ${a.ZXD_NOME.trim()}`,
                  };
                }),
            );
          } else {
            resultTrimestre.push({
              meta: "-",
              metaCurr: 0,
              trimestre: tri,
              title: `${tri} Trimestre`,
              filial: "",
            });
          }
        }

        resultTrimestre.totalFilial = resultTrimestre.reduce(
          (total, item) => total + item.metaCurr * 3,
          0,
        );

        this.resultadosAreas.push(resultTrimestre);
      });

      if (["PPF01", "PPF02"].includes(this.area)) {
        this.resultadosAreas = [];
        areas = [...new Set(area.data.map(e => e.ZXD_NOME))];

        areas.map(e => {
          let resultTrimestre = [];

          for (let tri = 1; tri <= 4; tri++) {
            const existeMeta = area.data.filter(
              a =>
                Number(a.ZXE_ANOTRI.substr(4, 2)) === tri && a.ZXD_NOME === e,
            );

            if (existeMeta.length > 0) {
              resultTrimestre.push(
                ...area.data
                  .filter(
                    a =>
                      Number(a.ZXE_ANOTRI.substr(4, 2)) === tri &&
                      a.ZXD_NOME === e,
                  )
                  .map(a => {
                    return {
                      meta: stringToBrMoney(a.ZXE_META),
                      metaCurr: Number(a.ZXE_META.toFixed(2)),
                      trimestre: tri,
                      title: `${tri} Trimestre`,
                      filial: `${a.ZXD_VEND} - ${a.ZXD_NOME.trim()}`,
                    };
                  }),
              );
            } else {
              resultTrimestre.push({
                meta: "-",
                metaCurr: 0,
                trimestre: tri,
                title: `${tri} Trimestre`,
                filial: "",
              });
            }
          }

          resultTrimestre.totalFilial = resultTrimestre.reduce(
            (total, item) => total + item.metaCurr * 3,
            0,
          );

          this.resultadosAreas.push(resultTrimestre);
        });
      }

      console.log(this.resultadosAreas);

      this.dialogAguarde = false;

      console.log(area.data);
    },
  },
  mounted() {
    this.buscaAnos();
  },
};
</script>
<style></style>
