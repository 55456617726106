import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (true)?_c(VNavigationDrawer,{attrs:{"app":"","mini-variant":_vm.mini,"color":_vm.menuColor,"permanent":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"px-2"},[(!_vm.mini)?_c('span',{staticClass:"sx-version"},[_vm._v(" Sansix © "+_vm._s(new Date().getFullYear())+" v."+_vm._s(_vm.versao)+" ")]):_vm._e(),_c('br'),_c(VBtn,{staticClass:"mb-3",attrs:{"fab":"","x-small":"","color":"black"},on:{"click":_vm.logout}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-logout")])],1)],1)]},proxy:true}],null,false,2010805133),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VListItem,{staticClass:"px-2"},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.user.name))]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},_vm._l((_vm.optionsMenu),function(item){return _c(VListItem,{key:item.title,attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.validaVersao(item)}}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItemIcon,_vm._g(_vm._b({},'v-list-item-icon',attrs,false),on),[_c(VIcon,{attrs:{"color":"black"}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.title)}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }