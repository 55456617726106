import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","width":"20%"},model:{value:(_vm.dialogAguarde),callback:function ($$v) {_vm.dialogAguarde=$$v},expression:"dialogAguarde"}},[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"60px","background-color":"azure"}},[_c('span',[_vm._v("Buscando dados...")]),_c(VProgressCircular,{attrs:{"width":3,"color":"green","indeterminate":""}})],1)]),(_vm.dadosOK && !_vm.titleStatus)?_c('div',{staticClass:"d-flex flex-column justify-center align-center ma-3 mt-5"},[_c('span',{staticClass:"title"},[_vm._v("Atendimento "+_vm._s(_vm.dadosTratamento.Solicitacao))]),_c(VRadioGroup,{attrs:{"row":""},model:{value:(_vm.tratamento),callback:function ($$v) {_vm.tratamento=$$v},expression:"tratamento"}},[_c(VRadio,{attrs:{"label":"Aprova","value":"A"}}),_c(VRadio,{attrs:{"label":"Reprova","value":"R"}})],1),_c(VTextarea,{staticStyle:{"width":"250px"},attrs:{"name":"input-7-1","label":"Observação","hint":"Obervação obrigatória somente na reprova!","counter":"250"},model:{value:(_vm.obs),callback:function ($$v) {_vm.obs=$$v},expression:"obs"}}),_c('div',{staticClass:"d-flex justify-center align-center mt-3",staticStyle:{"width":"15vw"}},[_c(VBtn,{staticClass:"primary",attrs:{"x-small":""},on:{"click":_vm.enviaTratamento}},[_vm._v("Enviar")])],1)],1):_vm._e(),(!_vm.dadosOK && !_vm.titleStatus)?_c('div',{staticClass:"d-flex flex-column justify-center align-center ma-3 mt-5",staticStyle:{"height":"95vh"}},[_c('span',{staticStyle:{"font-size":"32px","color":"red"}},[_vm._v("Link quebrado!")])]):_vm._e(),(_vm.titleStatus)?_c('div',{staticClass:"d-flex flex-column justify-center align-center ma-3 mt-5",staticStyle:{"height":"95vh"}},[_c('span',{staticStyle:{"font-size":"24px"}},[_vm._v(_vm._s(_vm.titleStatus))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }