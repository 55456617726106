<template>
  <div class="ml-5 mr-5 mt-3">
    <v-data-table
      dense
      :headers="headersRelat"
      :items="itemsRelat"
      no-data-text="Sem dados no momento"
      hide-default-footer
      :loading="loadData"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div>
            <span>{{ NOME_RELATORIO }}</span>
          </div>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <vue-excel-xlsx
                :data="itemsRelatExcel"
                :columns="headersRelatExcel"
                :filename="NOME_RELATORIO"
                :sheetname="`dados de investimento ${anoMesRelatInvest.substr(
                  4,
                  2,
                )}-${anoMesRelatInvest.substr(0, 4)}`"
                class="ml-5 tbExcel"
              >
                <v-btn
                  icon
                  color="blue darken-2"
                  class="mr-5 mt-5"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-microsoft-excel</v-icon>
                </v-btn>
              </vue-excel-xlsx>
            </template>
            <span>Exportar para excel</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:[`item.ZW1_CIDADE`]="{ item }">
        <span class="td_cidade">{{ item.ZW1_CIDADE }}</span>
      </template>
      <template v-slot:[`item.ZW1_INVEST`]="{ item }">
        <span class="td_invest">{{ item.ZW1_INVEST }}</span>
      </template>
      <template v-slot:[`item.CPL`]="{ item }">
        <span class="td_cpl">{{ item.CPL }}</span>
      </template>
      <template v-slot:[`item.TOTAL_GERAL`]="{ item }">
        <span class="td_cpl">{{ item.TOTAL_GERAL }}</span>
      </template>
      <template v-slot:[`item.TOTAL_QUALIF`]="{ item }">
        <span class="td_cpl">{{ item.TOTAL_QUALIF }}</span>
      </template>
      <template v-slot:[`item.CPL_QUALIF`]="{ item }">
        <span class="td_cplqualif">{{ item.CPL_QUALIF }}</span>
      </template>
      <template v-slot:[`item.ZW1_METALD`]="{ item }">
        <v-text-field
          v-model="item.ZW1_METALD"
          dense
          class="cpTbEdita"
          ref="qtdprod"
          autofocus
          @input="calculaItem(item)"
        ></v-text-field>
      </template>
      <template v-slot:[`item.CPL_MAX`]="{ item }">
        <span class="td_maxcpl">{{ item.CPL_MAX }}</span>
      </template>
      <template v-slot:[`item.NOVO_INVEST`]="{ item }">
        <span class="td_cplqualif">{{ item.NOVO_INVEST }}</span>
      </template>
      <template v-slot:[`body.append`]="{}" v-if="totais[0]">
        <tr>
          <td style="padding: 10px !important"></td>
        </tr>
        <tr>
          <th></th>
          <th class="td_cidade align-center">TOTAL</th>
          <th class="td_cidade align-center">TOTAL</th>
          <th class="td_cidade align-center">MÉDIA</th>
          <th class="td_cidade align-center">TOTAL</th>
          <th class="td_cidade align-center">MÉDIA</th>
          <th class="td_cidade align-center">TOTAL</th>
          <th class="td_cidade align-center">TOTAL</th>
          <th class="td_cidade align-center">TOTAL</th>
        </tr>
        <tr dense>
          <td></td>
          <td class="align-center">{{ totais[0].TOTAL_INVEST }}</td>
          <td class="align-center">{{ totais[0].TOTAL_GERAL }}</td>
          <td class="align-center">{{ totais[0].MEDIA_CPL }}</td>
          <td class="align-center">{{ totais[0].TOTAL_QUALIFICADO }}</td>
          <td class="align-center">{{ totais[0].MEDIA_QUALIFICADO }}</td>
          <td class="align-center">{{ totais[0].TOTAL_NOVA_META }}</td>
          <td class="align-center">{{ totais[0].TOTAL_CPL_MAX }}</td>
          <td class="align-center">{{ totais[0].TOTAL_NOVO_INVEST }}</td>
        </tr>
        <tr>
          <th></th>
          <th class="td_cidade align-center">MÍNIMO</th>
          <th class="td_cidade align-center">MÍNIMO</th>
          <th class="td_cidade align-center">MÍNIMO</th>
          <th class="td_cidade align-center">MÍNIMO</th>
          <th class="td_cidade align-center">MÍNIMO</th>
          <th class="td_cidade align-center">MÍNIMO</th>
          <th class="td_cidade align-center">MÍNIMO</th>
          <th class="td_cidade align-center">MÍNIMO</th>
        </tr>
        <tr>
          <th class="th_label"></th>

          <th class="th_label" v-if="!minimos[0].minimoInvestimento[0]"></th>
          <th
            class="th_label align-center"
            v-if="minimos[0].minimoInvestimento.length === 1"
          >
            {{ minimos[0].minimoInvestimento[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="minimos[0].minimoInvestimento.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ minimos[0].minimoInvestimento.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!minimos[0].minimoLeadGeral[0]"></th>
          <th
            class="th_label align-center"
            v-if="minimos[0].minimoLeadGeral.length === 1"
          >
            {{ minimos[0].minimoLeadGeral[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="minimos[0].minimoLeadGeral.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ minimos[0].minimoLeadGeral.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!minimos[0].minimoCPL[0]"></th>
          <th
            class="th_label align-center"
            v-if="minimos[0].minimoCPL.length === 1"
          >
            {{ minimos[0].minimoCPL[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="minimos[0].minimoCPL.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ minimos[0].minimoCPL.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!minimos[0].minimoLeadQualificado[0]"></th>
          <th
            class="th_label align-center"
            v-if="minimos[0].minimoLeadQualificado.length === 1"
          >
            {{ minimos[0].minimoLeadQualificado[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="minimos[0].minimoLeadQualificado.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ minimos[0].minimoLeadQualificado.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!minimos[0].minimoCPL_QUALIF[0]"></th>
          <th
            class="th_label align-center"
            v-if="minimos[0].minimoCPL_QUALIF.length === 1"
          >
            {{ minimos[0].minimoCPL_QUALIF[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="minimos[0].minimoCPL_QUALIF.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ minimos[0].minimoCPL_QUALIF.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!minimos[0].minimoNovaMeta[0]"></th>
          <th
            class="th_label align-center"
            v-if="minimos[0].minimoNovaMeta.length === 1"
          >
            {{ minimos[0].minimoNovaMeta[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="minimos[0].minimoNovaMeta.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ minimos[0].minimoNovaMeta.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!minimos[0].minimoCPL_MAX[0]"></th>
          <th
            class="th_label align-center"
            v-if="minimos[0].minimoCPL_MAX.length === 1"
          >
            {{ minimos[0].minimoCPL_MAX[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="minimos[0].minimoCPL_MAX.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ minimos[0].minimoCPL_MAX.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!minimos[0].minimoNOVO_INVEST[0]"></th>
          <th
            class="th_label align-center"
            v-if="minimos[0].minimoNOVO_INVEST.length === 1"
          >
            {{ minimos[0].minimoNOVO_INVEST[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="minimos[0].minimoNOVO_INVEST.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ minimos[0].minimoNOVO_INVEST.TOOLTIP }}</span>
          </v-tooltip>
        </tr>
        <tr dense>
          <td class="td_white"></td>

          <td class="td_white" v-if="!minimos[0].minimoInvestimento[0]"></td>
          <td
            class="align-center td_white"
            v-if="minimos[0].minimoInvestimento[0]"
          >
            {{ minimos[0].minimoInvestimento[0].ZW1_INVEST }}
          </td>

          <td class="td_white" v-if="!minimos[0].minimoLeadGeral[0]"></td>
          <td
            class="align-center td_white"
            v-if="minimos[0].minimoLeadGeral[0]"
          >
            {{ minimos[0].minimoLeadGeral[0].TOTAL_GERAL }}
          </td>

          <td class="td_white" v-if="!minimos[0].minimoCPL[0]"></td>
          <td class="align-center td_white" v-if="minimos[0].minimoCPL[0]">
            {{ minimos[0].minimoCPL[0].CPL }}
          </td>

          <td class="td_white" v-if="!minimos[0].minimoLeadQualificado[0]"></td>
          <td
            class="align-center td_white"
            v-if="minimos[0].minimoLeadQualificado[0]"
          >
            {{ minimos[0].minimoLeadQualificado[0].TOTAL_QUALIF }}
          </td>

          <td class="td_white" v-if="!minimos[0].minimoCPL_QUALIF[0]"></td>
          <td
            class="align-center td_white"
            v-if="minimos[0].minimoCPL_QUALIF[0]"
          >
            {{ minimos[0].minimoCPL_QUALIF[0].CPL_QUALIF }}
          </td>

          <td class="td_white" v-if="!minimos[0].minimoNovaMeta[0]"></td>
          <td class="align-center td_white" v-if="minimos[0].minimoNovaMeta[0]">
            {{ minimos[0].minimoNovaMeta[0].ZW1_METALD }}
          </td>

          <td class="td_white" v-if="!minimos[0].minimoCPL_MAX[0]"></td>
          <td class="align-center td_white" v-if="minimos[0].minimoCPL_MAX[0]">
            {{ minimos[0].minimoCPL_MAX[0].CPL_MAX }}
          </td>

          <td class="td_white" v-if="!minimos[0].minimoNOVO_INVEST[0]"></td>
          <td
            class="align-center td_white"
            v-if="minimos[0].minimoNOVO_INVEST[0]"
          >
            {{ minimos[0].minimoNOVO_INVEST[0].NOVO_INVEST }}
          </td>
        </tr>
        <tr>
          <th></th>
          <th class="td_cidade align-center">MÁXIMO</th>
          <th class="td_cidade align-center">MÁXIMO</th>
          <th class="td_cidade align-center">MÁXIMO</th>
          <th class="td_cidade align-center">MÁXIMO</th>
          <th class="td_cidade align-center">MÁXIMO</th>
          <th class="td_cidade align-center">MÁXIMO</th>
          <th class="td_cidade align-center">MÁXIMO</th>
          <th class="td_cidade align-center">MÁXIMO</th>
        </tr>
        <tr>
          <th class="th_label"></th>

          <th class="th_label" v-if="!maximos[0].maximoInvestimento[0]"></th>
          <th
            class="th_label align-center"
            v-if="maximos[0].maximoInvestimento.length === 1"
          >
            {{ maximos[0].maximoInvestimento[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="maximos[0].maximoInvestimento.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ maximos[0].maximoInvestimento.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!maximos[0].maximoLeadGeral[0]"></th>
          <th
            class="th_label align-center"
            v-if="maximos[0].maximoLeadGeral.length === 1"
          >
            {{ maximos[0].maximoLeadGeral[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="maximos[0].maximoLeadGeral.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ maximos[0].maximoLeadGeral.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!maximos[0].maximoCPL[0]"></th>
          <th
            class="th_label align-center"
            v-if="maximos[0].maximoCPL.length === 1"
          >
            {{ maximos[0].maximoCPL[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="maximos[0].maximoCPL.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ maximos[0].maximoCPL.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!maximos[0].maximoLeadQualificado[0]"></th>
          <th
            class="th_label align-center"
            v-if="maximos[0].maximoLeadQualificado.length === 1"
          >
            {{ maximos[0].maximoLeadQualificado[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="maximos[0].maximoLeadQualificado.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ maximos[0].maximoLeadQualificado.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!maximos[0].maximoCPL_QUALIF[0]"></th>
          <th
            class="th_label align-center"
            v-if="maximos[0].maximoCPL_QUALIF.length === 1"
          >
            {{ maximos[0].maximoCPL_QUALIF[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="maximos[0].maximoCPL_QUALIF.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ maximos[0].maximoCPL_QUALIF.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!maximos[0].maximoNovaMeta[0]"></th>
          <th
            class="th_label align-center"
            v-if="maximos[0].maximoNovaMeta.length === 1"
          >
            {{ maximos[0].maximoNovaMeta[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="maximos[0].maximoNovaMeta.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ maximos[0].maximoNovaMeta.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!maximos[0].maximoCPL_MAX[0]"></th>
          <th
            class="th_label align-center"
            v-if="maximos[0].maximoCPL_MAX.length === 1"
          >
            {{ maximos[0].maximoCPL_MAX[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="maximos[0].maximoCPL_MAX.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ maximos[0].maximoCPL_MAX.TOOLTIP }}</span>
          </v-tooltip>

          <th class="th_label" v-if="!maximos[0].maximoNOVO_INVEST[0]"></th>
          <th
            class="th_label align-center"
            v-if="maximos[0].maximoNOVO_INVEST.length === 1"
          >
            {{ maximos[0].maximoNOVO_INVEST[0].ZW1_CIDADE }}
          </th>
          <v-tooltip top color="blue darken-1">
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_label align-center"
                v-bind="attrs"
                v-on="on"
                v-if="maximos[0].maximoNOVO_INVEST.length > 1"
              >
                VÁRIOS
              </th>
            </template>
            <span>{{ maximos[0].maximoNOVO_INVEST.TOOLTIP }}</span>
          </v-tooltip>
        </tr>
        <tr dense>
          <td class="td_white"></td>

          <td class="td_white" v-if="!maximos[0].maximoInvestimento[0]"></td>
          <td
            class="align-center td_white"
            v-if="maximos[0].maximoInvestimento[0]"
          >
            {{ maximos[0].maximoInvestimento[0].ZW1_INVEST }}
          </td>
          <td class="td_white" v-if="!maximos[0].maximoLeadGeral[0]"></td>
          <td
            class="align-center td_white"
            v-if="maximos[0].maximoLeadGeral[0]"
          >
            {{ maximos[0].maximoLeadGeral[0].TOTAL_GERAL }}
          </td>
          <td class="td_white" v-if="!maximos[0].maximoCPL[0]"></td>
          <td class="align-center td_white" v-if="maximos[0].maximoCPL[0]">
            {{ maximos[0].maximoCPL[0].CPL }}
          </td>
          <td class="td_white" v-if="!maximos[0].maximoLeadQualificado[0]"></td>
          <td
            class="align-center td_white"
            v-if="maximos[0].maximoLeadQualificado[0]"
          >
            {{ maximos[0].maximoLeadQualificado[0].TOTAL_QUALIF }}
          </td>
          <td class="td_white" v-if="!maximos[0].maximoCPL_QUALIF[0]"></td>
          <td
            class="align-center td_white"
            v-if="maximos[0].maximoCPL_QUALIF[0]"
          >
            {{ maximos[0].maximoCPL_QUALIF[0].CPL_QUALIF }}
          </td>
          <td class="td_white" v-if="!maximos[0].maximoNovaMeta[0]"></td>
          <td class="align-center td_white" v-if="maximos[0].maximoNovaMeta[0]">
            {{ maximos[0].maximoNovaMeta[0].ZW1_METALD }}
          </td>
          <td class="td_white" v-if="!maximos[0].maximoCPL_MAX[0]"></td>
          <td class="align-center td_white" v-if="maximos[0].maximoCPL_MAX[0]">
            {{ maximos[0].maximoCPL_MAX[0].CPL_MAX }}
          </td>
          <td class="td_white" v-if="!maximos[0].maximoNOVO_INVEST[0]"></td>
          <td
            class="align-center td_white"
            v-if="maximos[0].maximoNOVO_INVEST[0]"
          >
            {{ maximos[0].maximoNOVO_INVEST[0].NOVO_INVEST }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <DialogErros :error="erro" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

import DialogErros from "@/components/_Erros/DialogErros.vue";

String.prototype.replaceAll =
  String.prototype.replaceAll ||
  function (needle, replacement) {
    return this.split(needle).join(replacement);
  };

export default {
  name: "relatInvestimento",
  computed: { ...mapState(["anoMesRelatInvest"]) },
  components: { DialogErros },
  data: () => {
    return {
      loadData: true,
      NOME_RELATORIO: null,
      campo: [],
      itemsRelat: [],
      headersRelat: [],
      itemsRelatExcel: [],
      headersRelatExcel: [],
      headersTotal: [],
      totais: [],
      minimos: [],
      maximos: [],
      erro: null,
    };
  },
  methods: {
    async montaBrowser() {
      (this.erro = null), (this.itemsRelat = []);
      this.headersRelat = [];
      this.headersRelatExcel = [];
      this.minimos = [];
      this.maximos = [];
      this.loadData = true;
      this.NOME_RELATORIO = `Relatório de investimento referência ${this.anoMesRelatInvest.substr(
        4,
        2,
      )}-${this.anoMesRelatInvest.substr(0, 4)}`;
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "",
        WHERE: `ZW1_ANO='${this.anoMesRelatInvest.substr(
          0,
          4,
        )}' AND ZW1_MES='${this.anoMesRelatInvest.substr(4, 2)}'`,
        TABELA: "ZW1",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.itemsRelat = res.data;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
          this.erro = `Linha: 634 =====>`;
          this.erro += `Dados: ${JSON.stringify(dados)} =====>`;
          this.erro += `Chamada: ${url} =====>`;
          this.erro += `${error}`;
          this.$store.commit("setDialogError", true);
        });

      let data = `${this.anoMesRelatInvest.substr(
        0,
        4,
      )}/${this.anoMesRelatInvest.substr(4, 2)}/01`;

      const dataCorrente = new Date(data);

      let ultimoDia = new Date(
        dataCorrente.getFullYear(),
        dataCorrente.getMonth() + 1,
        0,
      );

      ultimoDia = `${this.anoMesRelatInvest.substr(
        0,
        4,
      )}${this.anoMesRelatInvest.substr(4, 2)}${ultimoDia
        .getDate()
        .toString()}`;

      dados.WHERE = `ZZQ_DTINC BETWEEN '${data.replaceAll(
        "/",
        "",
      )}' AND '${ultimoDia}'`;
      dados.TABELA = "ZZQ";

      let leads = [];
      await axios
        .post(url, dados)
        .then(res => {
          leads = res.data;
        })
        .catch(error => {
          this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
          this.erro += `Chamada: ${url} =====>`;
          this.erro += `${error}`;
          this.$store.commit("setDialogError", true);
        });

      if (leads.length > 0) {
        let cidades = [...new Set(leads.map(item => item.ZZQ_CIDADE))];

        //console.log(cidades)

        await cidades.forEach(c => {
          console.log(c.substr(0, 5));
          let cidExist = this.itemsRelat.filter(
            e => e.ZW1_CDIBGE === c.substr(0, 5),
          );

          if (cidExist[0]) {
            this.itemsRelat.map(r => {
              if (c.trim() !== "") {
                if (r.ZW1_CDIBGE === c.substr(0, 5)) {
                  r.CPL = (
                    r.ZW1_INVEST / leads.filter(l => l.ZZQ_CIDADE === c).length
                  ).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  });
                  r.TOTAL_QUALIF = leads.filter(
                    l => l.ZZQ_CIDADE === c && l.ZZQ_QUALIF === "1",
                  ).length;

                  r.TOTAL_GERAL = leads.filter(l => l.ZZQ_CIDADE === c).length;

                  if (r.TOTAL_QUALIF > 0) {
                    r.CPL_QUALIF = (
                      r.ZW1_INVEST /
                      leads.filter(
                        l => l.ZZQ_CIDADE === c && l.ZZQ_QUALIF === "1",
                      ).length
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    });
                    r.CPL_MAX = (
                      r.ZW1_METALD *
                      (r.TOTAL_GERAL / r.TOTAL_QUALIF)
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    });
                    let CPL_QUALIF =
                      r.ZW1_INVEST /
                      leads.filter(
                        l => l.ZZQ_CIDADE === c && l.ZZQ_QUALIF === "1",
                      ).length;
                    r.NOVO_INVEST = (CPL_QUALIF * r.ZW1_METALD).toLocaleString(
                      "pt-BR",
                      {
                        style: "currency",
                        currency: "BRL",
                      },
                    );
                  }
                }
              }
            });
          } else {
            let cidade = ``;
            let codIbge = ``;
            if (c.trim() === "") {
              cidade = `NÃO ESPECIFICADA`;
            } else {
              if (c.includes("-")) {
                cidade = c;
                codIbge = c.substr(0, 5);
              } else {
                cidade = c;
                codIbge = null;
              }
            }

            this.itemsRelat.push({
              ZW1_CIDADE: cidade,
              ZW1_ANO: this.anoMesRelatInvest.substr(0, 4),
              ZW1_CDIBGE: codIbge,
              ZW1_EST: "",
              ZW1_FILIAL: "",
              ZW1_ID: "",
              ZW1_INVEST: 0,
              ZW1_MES: this.anoMesRelatInvest.substr(4, 2),
              ZW1_METALD: 0,
              TOTAL_GERAL: leads.filter(l => l.ZZQ_CIDADE === c).length,
              TOTAL_QUALIF: leads.filter(
                l => l.ZZQ_CIDADE === c && l.ZZQ_QUALIF === "1",
              ).length,
            });
          }
        });
      } else {
        this.itemsRelat.map(e => {
          e.CPL = "-";
        });
      }

      console.log(this.itemsRelat);

      this.itemsRelat.map(e => {
        e.ZW1_INVEST99 = e.ZW1_INVEST;
        e.CPL99 = e.CPL
          ? parseFloat(
              e.CPL.replace("R$", "").replaceAll(".", "").replace(",", "."),
            )
          : null;
        e.CPL_QUALIF99 = e.CPL_QUALIF
          ? parseFloat(
              e.CPL_QUALIF.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            )
          : null;
        e.CPL_MAX99 = e.CPL_MAX
          ? parseFloat(
              e.CPL_MAX.replace("R$", "").replaceAll(".", "").replace(",", "."),
            )
          : null;
        e.NOVO_INVEST99 = e.NOVO_INVEST
          ? parseFloat(
              e.NOVO_INVEST.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            )
          : null;
      });

      this.headersRelat = [
        {
          text: "Cidade",
          value: "ZW1_CIDADE",
          color: "blue",
          align: "center",
          class: "hd_cidade",
        },
        {
          text: "Investimento mês referência",
          value: "ZW1_INVEST",
          class: "hd_cidade",
          align: "right",
        },
        {
          text: "Quantidade lead geral",
          value: "TOTAL_GERAL",
          class: "hd_cidade",
          align: "center",
        },
        { text: "CPL", value: "CPL", class: "hd_cidade", align: "right" },
        {
          text: "Quantidade lead qualificados",
          value: "TOTAL_QUALIF",
          class: "hd_cidade",
          align: "center",
        },
        {
          text: "CPL qualificado",
          value: "CPL_QUALIF",
          class: "hd_cidade",
          align: "right",
        },
        {
          text: "Nova meta lead qualificado",
          value: "ZW1_METALD",
          class: "hd_novaMeta",
        },
        {
          text: "CPL máximo aproximado",
          value: "CPL_MAX",
          class: "hd_cplMax",
          align: "right",
        },
        {
          text: "Novo investimento",
          value: "NOVO_INVEST",
          class: "hd_novoInvest",
          align: "right",
        },
      ];

      this.headersRelatExcel = [
        {
          label: "Cidade",
          field: "ZW1_CIDADE",
        },
        {
          label: "Investimento mês referência",
          field: "ZW1_INVEST99",
        },
        {
          label: "Quantidade lead geral",
          field: "TOTAL_GERAL",
        },
        { label: "CPL", field: "CPL99" },
        {
          label: "Quantidade lead qualificados",
          field: "TOTAL_QUALIF",
        },
        {
          label: "CPL qualificado",
          field: "CPL_QUALIF99",
        },
        {
          label: "Nova meta lead qualificado",
          field: "ZW1_METALD",
        },
        {
          label: "CPL máximo aproximado",
          field: "CPL_MAX99",
        },
        {
          label: "Novo investimento",
          field: "NOVO_INVEST99",
        },
      ];

      setTimeout(() => {
        let campo = document.getElementsByClassName("td_cidade");
        for (var i = 0; i < campo.length; i++) {
          campo[i].parentNode.style.background = "black";
        }
      }, 100);

      setTimeout(() => {
        let campo = document.getElementsByClassName("td_invest");
        for (var i = 0; i < campo.length; i++) {
          campo[i].parentNode.style.background = "#80CBC4";
          campo[i].parentNode.style.borderRight = ".5px solid black";
        }
      }, 100);

      setTimeout(() => {
        let campo = document.getElementsByClassName("td_cpl");
        for (var i = 0; i < campo.length; i++) {
          campo[i].parentNode.style.background = "#FFE0B2";
          campo[i].parentNode.style.borderRight = ".5px solid black";
        }
      }, 100);

      setTimeout(() => {
        let campo = document.getElementsByClassName("td_cplqualif");
        for (var i = 0; i < campo.length; i++) {
          campo[i].parentNode.style.background = "#B3E5FC";
          campo[i].parentNode.style.borderRight = ".5px solid black";
        }
      }, 100);

      setTimeout(() => {
        let campo = document.getElementsByClassName("cpTbEdita");
        for (var i = 0; i < campo.length; i++) {
          campo[i].parentNode.style.background = "#F3E5F5";
          campo[i].parentNode.style.borderRight = ".5px solid black";
        }
      }, 100);

      setTimeout(() => {
        let campo = document.getElementsByClassName("td_maxcpl");
        for (var i = 0; i < campo.length; i++) {
          campo[i].parentNode.style.background = "#E0E0E0";
          campo[i].parentNode.style.borderRight = ".5px solid black";
        }
      }, 100);

      this.calculaTotais();
      this.calculaMinimos();

      this.loadData = false;
    },
    calculaMinimos() {
      Array.min = function (array) {
        return Math.min.apply(Math, array);
      };
      Array.max = function (array) {
        return Math.max.apply(Math, array);
      };

      const arrayInvestimento = this.itemsRelat.map(e =>
        e.ZW1_INVEST ? e.ZW1_INVEST : 100000000,
      );
      const minimoInvestimento = Array.min(arrayInvestimento);

      const arrayLeadGeral = this.itemsRelat.map(e =>
        e.TOTAL_GERAL ? e.TOTAL_GERAL : 100000000,
      );
      const minimoLeadGeral = Array.min(arrayLeadGeral);

      const arrayCPL = this.itemsRelat.map(e =>
        e.CPL
          ? parseFloat(
              e.CPL.replace("R$", "").replaceAll(".", "").replace(",", "."),
            )
          : 100000000,
      );
      const minimoCPL = Array.min(arrayCPL);

      const arrayLeadQualificado = this.itemsRelat.map(e =>
        e.TOTAL_QUALIF ? e.TOTAL_QUALIF : 100000000,
      );
      const minimoLeadQualificado = Array.min(arrayLeadQualificado);

      const arrayCPL_QUALIF = this.itemsRelat.map(e =>
        e.CPL_QUALIF
          ? parseFloat(
              e.CPL_QUALIF.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            )
          : 100000000,
      );
      const minimoCPL_QUALIF = Array.min(arrayCPL_QUALIF);

      const arrayNovaMeta = this.itemsRelat.map(e =>
        e.ZW1_METALD ? e.ZW1_METALD : 100000000,
      );
      const minimoNovaMeta = Array.min(arrayNovaMeta);

      const arrayCPL_MAX = this.itemsRelat.map(e =>
        e.CPL_MAX
          ? parseFloat(
              e.CPL_MAX.replace("R$", "").replaceAll(".", "").replace(",", "."),
            )
          : 100000000,
      );
      const minimoCPL_MAX = Array.min(arrayCPL_MAX);

      const arrayNOVO_INVEST = this.itemsRelat.map(e =>
        e.NOVO_INVEST
          ? parseFloat(
              e.NOVO_INVEST.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            )
          : 100000000,
      );
      const minimoNOVO_INVEST = Array.min(arrayNOVO_INVEST);

      let arrMinimoInvest = this.itemsRelat.filter(
        e => e.ZW1_INVEST === minimoInvestimento,
      );

      arrMinimoInvest.map(e => {
        e.ZW1_INVEST = e.ZW1_INVEST.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      });

      if (arrMinimoInvest.length > 1) {
        let cidades = ``;
        arrMinimoInvest.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrMinimoInvest.TOOLTIP = cidades;
      }

      let arrMinimoLeadGeral = this.itemsRelat.filter(
        e => e.TOTAL_GERAL === minimoLeadGeral,
      );

      if (arrMinimoLeadGeral.length > 1) {
        let cidades = ``;
        arrMinimoLeadGeral.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrMinimoLeadGeral.TOOLTIP = cidades;
      }

      let arrMinimoCPL = this.itemsRelat.filter(e =>
        e.CPL
          ? parseFloat(
              e.CPL.replace("R$", "").replaceAll(".", "").replace(",", "."),
            ) === minimoCPL
          : 0,
      );

      arrMinimoCPL.map(e => {
        e.CPL = e.CPL.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      });

      if (arrMinimoCPL.length > 1) {
        let cidades = ``;
        arrMinimoCPL.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrMinimoCPL.TOOLTIP = cidades;
      }

      let arrMinimoLeadQualificado = this.itemsRelat.filter(
        e => e.TOTAL_QUALIF === minimoLeadQualificado,
      );

      if (arrMinimoLeadQualificado.length > 1) {
        let cidades = ``;
        arrMinimoLeadQualificado.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrMinimoLeadQualificado.TOOLTIP = cidades;
      }

      let arrMinimoCPL_QUALIF = this.itemsRelat.filter(e =>
        e.CPL_QUALIF
          ? parseFloat(
              e.CPL_QUALIF.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            ) === minimoCPL_QUALIF
          : 0,
      );

      arrMinimoCPL_QUALIF.map(e => {
        e.CPL_QUALIF = e.CPL_QUALIF.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      });

      if (arrMinimoCPL_QUALIF.length > 1) {
        let cidades = ``;
        arrMinimoCPL_QUALIF.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrMinimoCPL_QUALIF.TOOLTIP = cidades;
      }

      let arrMinimoNovaMeta = this.itemsRelat.filter(
        e => e.ZW1_METALD === minimoNovaMeta,
      );

      if (arrMinimoNovaMeta.length > 1) {
        let cidades = ``;
        arrMinimoNovaMeta.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrMinimoNovaMeta.TOOLTIP = cidades;
      }

      let arrMinimoCPL_MAX = this.itemsRelat.filter(e =>
        e.CPL_MAX
          ? parseFloat(
              e.CPL_MAX.replace("R$", "").replaceAll(".", "").replace(",", "."),
            ) === minimoCPL_MAX
          : 0,
      );

      if (arrMinimoCPL_MAX.length > 1) {
        let cidades = ``;
        arrMinimoCPL_MAX.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrMinimoCPL_MAX.TOOLTIP = cidades;
      }

      let arrMinimoNOVO_INVEST = this.itemsRelat.filter(e =>
        e.NOVO_INVEST
          ? parseFloat(
              e.NOVO_INVEST.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            ) === minimoNOVO_INVEST
          : 0,
      );

      if (arrMinimoNOVO_INVEST.length > 1) {
        let cidades = ``;
        arrMinimoNOVO_INVEST.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrMinimoNOVO_INVEST.TOOLTIP = cidades;
      }

      this.minimos.push({
        minimoInvestimento: arrMinimoInvest,
        minimoLeadGeral: arrMinimoLeadGeral,
        minimoCPL: arrMinimoCPL,
        minimoLeadQualificado: arrMinimoLeadQualificado,
        minimoCPL_QUALIF: arrMinimoCPL_QUALIF,
        minimoNovaMeta: arrMinimoNovaMeta,
        minimoCPL_MAX: arrMinimoCPL_MAX,
        minimoNOVO_INVEST: arrMinimoNOVO_INVEST,
      });

      //calculando os máximos
      this.itemsRelat.map(e => {
        if (isNaN(parseFloat(e.ZW1_INVEST))) {
          e.ZW1_INVEST = parseFloat(
            e.ZW1_INVEST.replace("R$", "")
              .replaceAll(".", "")
              .replace(",", "."),
          );
        }
      });

      const arrayInvestimentoM = this.itemsRelat.map(e =>
        e.ZW1_INVEST ? e.ZW1_INVEST : 0,
      );
      const maximoInvestimento = Array.max(arrayInvestimentoM);

      const arrayLeadGeralM = this.itemsRelat.map(e =>
        e.TOTAL_GERAL ? e.TOTAL_GERAL : 0,
      );

      const maximoLeadGeral = Array.max(arrayLeadGeralM);

      const arrayCPLM = this.itemsRelat.map(e =>
        e.CPL
          ? parseFloat(
              e.CPL.replace("R$", "").replaceAll(".", "").replace(",", "."),
            )
          : 0,
      );
      const maximoCPL = Array.max(arrayCPLM);

      const arrayLeadQualificadoM = this.itemsRelat.map(e =>
        e.TOTAL_QUALIF ? e.TOTAL_QUALIF : 0,
      );
      const maximoLeadQualificado = Array.max(arrayLeadQualificadoM);

      const arrayCPL_QUALIFM = this.itemsRelat.map(e =>
        e.CPL_QUALIF
          ? parseFloat(
              e.CPL_QUALIF.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            )
          : 0,
      );
      const maximoCPL_QUALIF = Array.max(arrayCPL_QUALIFM);

      const arrayNovaMetaM = this.itemsRelat.map(e =>
        e.ZW1_METALD ? e.ZW1_METALD : 0,
      );
      const maximoNovaMeta = Array.max(arrayNovaMetaM);

      const arrayCPL_MAXM = this.itemsRelat.map(e =>
        e.CPL_MAX
          ? parseFloat(
              e.CPL_MAX.replace("R$", "").replaceAll(".", "").replace(",", "."),
            )
          : 0,
      );
      const maximoCPL_MAX = Array.max(arrayCPL_MAXM);

      const arrayNOVO_INVESTM = this.itemsRelat.map(e =>
        e.NOVO_INVEST
          ? parseFloat(
              e.NOVO_INVEST.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            )
          : 0,
      );
      const maximoNOVO_INVEST = Array.max(arrayNOVO_INVESTM);

      let arrmaximoInvest = this.itemsRelat.filter(
        e => e.ZW1_INVEST === maximoInvestimento,
      );

      arrmaximoInvest.map(e => {
        e.ZW1_INVEST = e.ZW1_INVEST.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      });

      if (arrmaximoInvest.length > 1) {
        let cidades = ``;
        arrmaximoInvest.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrmaximoInvest.TOOLTIP = cidades;
      }

      let arrmaximoLeadGeral = this.itemsRelat.filter(
        e => e.TOTAL_GERAL === maximoLeadGeral,
      );

      if (arrmaximoLeadGeral.length > 1) {
        let cidades = ``;
        arrmaximoLeadGeral.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrmaximoLeadGeral.TOOLTIP = cidades;
      }

      let arrmaximoCPL = this.itemsRelat.filter(e =>
        e.CPL
          ? parseFloat(
              e.CPL.replace("R$", "").replaceAll(".", "").replace(",", "."),
            ) === maximoCPL
          : 0,
      );

      arrmaximoCPL.map(e => {
        e.CPL = e.CPL.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      });

      if (arrmaximoCPL.length > 1) {
        let cidades = ``;
        arrmaximoCPL.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrmaximoCPL.TOOLTIP = cidades;
      }

      let arrmaximoLeadQualificado = this.itemsRelat.filter(
        e => e.TOTAL_QUALIF === maximoLeadQualificado,
      );

      if (arrmaximoLeadQualificado.length > 1) {
        let cidades = ``;
        arrmaximoLeadQualificado.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrmaximoLeadQualificado.TOOLTIP = cidades;
      }

      let arrmaximoCPL_QUALIF = this.itemsRelat.filter(e =>
        e.CPL_QUALIF
          ? parseFloat(
              e.CPL_QUALIF.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            ) === maximoCPL_QUALIF
          : 0,
      );

      arrmaximoCPL_QUALIF.map(e => {
        e.CPL_QUALIF = e.CPL_QUALIF.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      });

      if (arrmaximoCPL_QUALIF.length > 1) {
        let cidades = ``;
        arrmaximoCPL_QUALIF.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrmaximoCPL_QUALIF.TOOLTIP = cidades;
      }

      let arrmaximoNovaMeta = this.itemsRelat.filter(
        e => e.ZW1_METALD === maximoNovaMeta,
      );

      if (arrmaximoNovaMeta.length > 1) {
        let cidades = ``;
        arrmaximoNovaMeta.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrmaximoNovaMeta.TOOLTIP = cidades;
      }

      let arrmaximoCPL_MAX = this.itemsRelat.filter(e =>
        e.CPL_MAX
          ? parseFloat(
              e.CPL_MAX.replace("R$", "").replaceAll(".", "").replace(",", "."),
            ) === maximoCPL_MAX
          : 0,
      );

      if (arrmaximoCPL_MAX.length > 1) {
        let cidades = ``;
        arrmaximoCPL_MAX.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrmaximoCPL_MAX.TOOLTIP = cidades;
      }

      let arrmaximoNOVO_INVEST = this.itemsRelat.filter(e =>
        e.NOVO_INVEST
          ? parseFloat(
              e.NOVO_INVEST.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            ) === maximoNOVO_INVEST
          : 0,
      );

      if (arrmaximoNOVO_INVEST.length > 1) {
        let cidades = ``;
        arrmaximoNOVO_INVEST.forEach(e => {
          cidades += `${e.ZW1_CIDADE.trim()}, `;
        });

        arrmaximoNOVO_INVEST.TOOLTIP = cidades;
      }

      this.maximos.push({
        maximoInvestimento: arrmaximoInvest,
        maximoLeadGeral: arrmaximoLeadGeral,
        maximoCPL: arrmaximoCPL,
        maximoLeadQualificado: arrmaximoLeadQualificado,
        maximoCPL_QUALIF: arrmaximoCPL_QUALIF,
        maximoNovaMeta: arrmaximoNovaMeta,
        maximoCPL_MAX: arrmaximoCPL_MAX,
        maximoNOVO_INVEST: arrmaximoNOVO_INVEST,
      });

      this.itemsRelat.map(e => {
        if (!isNaN(parseFloat(e.ZW1_INVEST))) {
          e.ZW1_INVEST = e.ZW1_INVEST.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }
      });
    },
    calculaTotais() {
      let contMedia = 0;

      this.itemsRelat.forEach(e => {
        if (e.TOTAL_GERAL > 0) {
          contMedia++;
        }
      });

      let TOTAL_INVEST = 0;
      let MEDIA_CPL = 0;
      let MEDIA_QUALIFICADO = 0;
      let TOTAL_QUALIFICADO = 0;
      let TOTAL_GERAL = 0;
      let TOTAL_NOVA_META = 0;
      let TOTAL_CPL_MAX = 0;
      let TOTAL_NOVO_INVEST = 0;
      this.itemsRelat.forEach(e => {
        TOTAL_INVEST += e.ZW1_INVEST;
        MEDIA_CPL += e.CPL
          ? parseFloat(
              e.CPL.replace("R$", "").replaceAll(".", "").replace(",", "."),
            )
          : 0;
        MEDIA_QUALIFICADO += e.CPL_QUALIF
          ? parseFloat(
              e.CPL_QUALIF.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            )
          : 0;
        TOTAL_QUALIFICADO += e.TOTAL_QUALIF ? e.TOTAL_QUALIF : 0;
        TOTAL_GERAL += e.TOTAL_GERAL ? e.TOTAL_GERAL : 0;
        TOTAL_NOVA_META += e.ZW1_METALD;
        TOTAL_CPL_MAX += e.CPL_MAX
          ? parseFloat(
              e.CPL_MAX.replace("R$", "").replaceAll(".", "").replace(",", "."),
            )
          : 0;
        TOTAL_NOVO_INVEST += e.NOVO_INVEST
          ? parseFloat(
              e.NOVO_INVEST.replace("R$", "")
                .replaceAll(".", "")
                .replace(",", "."),
            )
          : 0;
      });

      this.totais.push({
        NDA: "",
        TOTAL_INVEST: TOTAL_INVEST.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        MEDIA_CPL: MEDIA_CPL
          ? (MEDIA_CPL / contMedia).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : "R$ 0,00",
        MEDIA_QUALIFICADO: MEDIA_QUALIFICADO
          ? (MEDIA_QUALIFICADO / contMedia).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : "R$ 0,00",
        TOTAL_QUALIFICADO,
        TOTAL_GERAL,
        TOTAL_NOVA_META,
        TOTAL_CPL_MAX: TOTAL_CPL_MAX.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        TOTAL_NOVO_INVEST: TOTAL_NOVO_INVEST.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
      });

      this.headersTotal.push(
        { text: null, value: "NDA" },
        {
          text: "TOTAL",
          value: "TOTAL_INVEST",
          class: "hd_cidade",
          align: "right",
        },
        {
          text: "TOTAL",
          value: "TOTAL_GERAL",
          class: "hd_cidade",
          align: "center",
        },
        {
          text: "MÉDIA",
          value: "MEDIA_CPL",
          class: "hd_cidade",
          align: "right",
        },
        {
          text: "TOTAL",
          value: "TOTAL_QUALIFICADO",
          class: "hd_cidade",
          align: "center",
        },
        {
          text: "MÉDIA",
          value: "MEDIA_QUALIFICADO",
          class: "hd_cidade",
          align: "right",
        },
        {
          text: "TOTAL",
          value: "TOTAL_NOVA_META",
          class: "hd_cidade",
          align: "center",
        },
        {
          text: "TOTAL",
          value: "TOTAL_CPL_MAX",
          class: "hd_cidade",
          align: "right",
        },
        {
          text: "TOTAL",
          value: "TOTAL_NOVO_INVEST",
          class: "hd_cidade",
          align: "right",
        },
      );
      this.itemsRelatExcel = this.itemsRelat;
    },
    calculaItem(item) {
      let valorMax = 0;
      let valorNovo = 0;
      if (item.TOTAL_GERAL > 0) {
        valorMax = item.ZW1_METALD * (item.TOTAL_GERAL / item.TOTAL_QUALIF);
      }

      if (item.CPL_QUALIF.trim() !== "") {
        const cplQualif = parseFloat(
          item.CPL_QUALIF.replace("R$", "")
            .replaceAll(".", "")
            .replace(",", "."),
        );
        valorNovo = cplQualif * item.ZW1_METALD;
      }

      this.itemsRelat.map(e => {
        if (e.ZW1_CIDADE === item.ZW1_CIDADE) {
          e.CPL_MAX = valorMax.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          e.NOVO_INVEST = valorNovo.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }
      });
    },
  },
  mounted() {
    this.montaBrowser();
  },
};
</script>

<style>
.hd_cidade {
  color: white !important;
  background-color: black;
  border-bottom: 1px solid white;
  font-size: 0.7rem;
}
.hd_novaMeta {
  color: white !important;
  background-color: #ab47bc;
}
.hd_cplMax {
  background-color: #757575;
  color: white !important;
}
.hd_novoInvest {
  background-color: #0288d1;
  color: white !important;
}
.td_cidade {
  color: white !important;
  background-color: black;
}
.td_invest {
  background-color: #80cbc4;
  font-size: 0.7rem;
}
.td_cpl {
  background-color: #ffe0b2;
  font-size: 0.7rem;
}
.td_cplqualif {
  background-color: #b3e5fc;
  font-size: 0.7rem;
}
.td_novameta {
  background-color: #f3e5f5;
}
.td_maxcpl {
  background-color: #e0e0e0;
  font-size: 0.7rem;
}
.align-center {
  text-align: right !important;
}
.align-center {
  text-align: center !important;
}
.th_label {
  background-color: yellow;
}
.td_white {
  background-color: white;
}
.cpTbEdita {
  background-color: #f3e5f5;
  text-align: center !important;
  padding: 0px;
  width: 20%;
  max-height: 30px;
  font-size: 0.7rem;
}
</style>
