import { VBanner } from 'vuetify/lib/components/VBanner';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c(VBanner,{attrs:{"single-line":"","color":"indigo lighten-4"},scopedSlots:_vm._u([{key:"actions",fn:function(){return undefined},proxy:true}])},[_c(VIcon,{attrs:{"slot":"icon","color":"warning","size":"36"},slot:"icon"},[_vm._v("mdi-monitor")]),_vm._v("Telas disponíveis para edição ")],1),_c(VItemGroup,{staticClass:"linkto",attrs:{"active-class":"primary"}},[_c(VRow,{attrs:{"align":"center","justify":"start"}},_vm._l((_vm.itensTela),function(item){return _c(VCol,{key:item.Tela,attrs:{"cols":item.flex}},[_c(VItem,{staticClass:"linkto"},[_c(VCard,{attrs:{"height":"100","to":item.link,"color":"grey lighten-4"}},[_c('div',{attrs:{"align":"center","justify":"center"}},[_c(VIcon,{staticClass:"mt-2",attrs:{"size":"60","color":item.color}},[_vm._v(_vm._s(item.icon))]),_c('p',[_vm._v(_vm._s(item.Tela))])],1)])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }