<template>
  <div class="ma-4">
    <!-- <v-card class="ma-4" height="50">
      <h1 align="center">Barra Superior</h1>
    </v-card> -->
    <v-col
      v-if="true"
      class="ma-4 mr-auto ml-auto"
      height="100"
      md="12"
      lg="10"
      xl="8"
    >
      <v-carousel
        cycle
        continuous
        height="300"
        hide-delimiters
        show-arrows-on-hover
      >
        <v-carousel-item v-for="(foto, i) in banners" :key="i" :src="foto">
        </v-carousel-item>
      </v-carousel>
    </v-col>
    <v-row class="d-flex ma-4">
      <v-spacer></v-spacer>
      <v-col cols="1"></v-col>
      <Plano />
      <v-col cols="1"></v-col>
      <v-spacer></v-spacer>
    </v-row>

    <!-- <v-card class="ma-4" height="50" elevation="4">
      <h1 align="center">Barra Inferior</h1>
    </v-card> -->
  </div>
</template>

<script>
//
// import { mapState, mapMutations } from 'vuex';
// import axios from "axios";
import Plano from "@/components/Clube/PlanCard";
// import banner1 from "../../assets/clube01.jpg";
// import banner2 from "../../assets/clube02.jpg";
// import banner3 from "../../assets/clube03.png";
import banner4 from "../../assets/rojao.jpg";

export default {
  name: "Clube",

  components: {
    Plano,
  },

  computed: {},

  data: () => ({
    banners: [],
  }),

  mounted() {
    this.banners = [
      "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
      "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
      "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
      banner4,
    ];
  },

  methods: {},
};
</script>

<style></style>
