import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"inspire"}},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100vw"}},[_c('Menu'),_c('ContentTabs1')],1),_c('DialogVersao'),_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialogMetadados),callback:function ($$v) {_vm.dialogMetadados=$$v},expression:"dialogMetadados"}},[_c(VCard,[_c(VOverlay,{attrs:{"value":_vm.dialogMetadados,"absolute":""}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VCardTitle,[_vm._v("Sales Move")]),_c(VCardSubtitle,[_c('p',{staticClass:"body-1"},[_vm._v("Carregando metadados, aguarde...")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }