import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","width":"35vw"},model:{value:(_vm.dialogVersion),callback:function ($$v) {_vm.dialogVersion=$$v},expression:"dialogVersion"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Nova Versão do Portal "),_c('span',{staticClass:"red--text ml-3"},[_vm._v(" "+_vm._s(_vm.newVersion))])]),_c(VCardText,[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('p',{staticClass:"text-h4 cyan--text"},[_vm._v("Existe uma nova versão do portal!")]),_c('p',{staticClass:"text-h6"},[_vm._v("Clique no botão recarregar para atualizar.")])])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"primary",attrs:{"x-small":""},on:{"click":_vm.recarregaVersao}},[_vm._v("Recarregar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }