import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 d-flex"},[_c(VCard,[(_vm.stDrawer)?_c(VNavigationDrawer,{attrs:{"mini-variant":_vm.stMini,"permanent":""},on:{"update:miniVariant":function($event){_vm.stMini=$event},"update:mini-variant":function($event){_vm.stMini=$event}},model:{value:(_vm.stDrawer),callback:function ($$v) {_vm.stDrawer=$$v},expression:"stDrawer"}},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,attrs:{"link":""},on:{"click":function($event){return _vm.paineis(item.function)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1):_vm._e()],1),(_vm.telaOS)?_c('OrdemServico1'):_vm._e(),(_vm.telaBaseInst)?_c('BaseInstalada'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }