<template>
  <div class="template pa-5">
    <v-card class="mt-3">
      <v-toolbar color="teal" dark dense>
        <v-toolbar-title>Modelos</v-toolbar-title>

        <v-divider class="mx-4" inset vertical></v-divider>

        <span class="caption" v-text="`Tabela: SA1`"></span>
        <v-divider class="mx-4" inset vertical></v-divider>
        <span class="caption" v-text="`Modelo atual: ${MODELOATUAL}`"></span>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn text @click="DialogVerCampos = true">
            <v-icon small class="pa-1">mdi-eye</v-icon>
            <span
              class="caption"
              v-text="`Campos selecionados no modelo`"
            ></span>
          </v-btn>
          <!-- <v-divider vertical></v-divider>

            <v-btn text @click="carregaSX3">
              <v-icon small class="pa-1">mdi-reload</v-icon>
              <span class="caption" v-text="`Renovar dados`"></span>
            </v-btn>

            <v-divider vertical></v-divider>

            <v-btn text @click="DialogModelo = true">
              <v-icon small class="pa-1">mdi-content-save</v-icon>
              <span class="caption" v-text="`Salvar`"></span>
            </v-btn> -->
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :headers="HEADERSMODELO"
        :items="MODELOS"
        item-key="ZZP_MODELO"
        fixed-header
        :height="100"
        class="elevation-1 mt-3"
        dense
        hide-default-footer
        no-data-text="Sem modelo no momento..."
        :items-per-page="MODELOS.length"
      ></v-data-table>
    </v-card>
    <div class="sx3 mt-3">
      <div class="banner">
        <v-toolbar color="teal" dark dense>
          <v-toolbar-title>Dados</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>

          <span class="caption" v-text="`Tabela: SA1`"></span>
          <v-divider class="mx-4" inset vertical></v-divider>
          <span
            class="caption"
            v-text="`Campos encontrados: ${COUNTCAMPOS}`"
          ></span>
          <v-divider class="mx-4" inset vertical></v-divider>
          <span
            class="caption"
            v-text="`Contagem seleção: ${SomaCamposSelecionados}`"
          ></span>
          <!-- <v-divider class="mx-4" inset vertical></v-divider>
          <span class="caption" v-text="`Campos obrigatórios: ${CAMPOSOBRIGATORIOS}`"></span> -->

          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-btn text @click="DialogVerCampos = true">
              <v-icon small class="pa-1">mdi-eye</v-icon>
              <span class="caption" v-text="`Campos selecionados`"></span>
            </v-btn>
            <v-divider vertical></v-divider>

            <v-btn text @click="carregaSX3">
              <v-icon small class="pa-1">mdi-reload</v-icon>
              <span class="caption" v-text="`Renovar dados`"></span>
            </v-btn>

            <v-divider vertical></v-divider>

            <v-btn text @click="salvaModelo">
              <v-icon small class="pa-1">mdi-content-save</v-icon>
              <span class="caption" v-text="`Salvar`"></span>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </div>
      <v-card>
        <v-card-title>
          SA1010
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Filtro"
            single-line
            dense
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          v-model="SX3SELECIONADA"
          :headers="HEADERS_SX3"
          :items="SX3"
          item-key="X3_CAMPO"
          show-select
          fixed-header
          :height="400"
          class="elevation-1 mt-3"
          dense
          :search="search"
          hide-default-footer
          no-data-text="Sem dados..."
          :items-per-page="SX3.length"
          @click:row="marcaLinha"
          :loading="loading"
        >
          <template v-slot:[`header.data-table-select`]="{}"></template>
          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div class="text-center">
      <v-dialog v-model="DialogVerCampos" width="350">
        <v-card>
          <v-card-title class="headline grey lighten-2"
            >Campos Selecionados</v-card-title
          >

          <v-card-text>
            <v-list dense disabled>
              <v-list-item-group v-model="SX3SELECIONADA" color="primary">
                <v-list-item v-for="(item, i) in SX3SELECIONADA" :key="i">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.X3_CAMPO"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="DialogVerCampos = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="DialogModelo" width="400" persistent>
        <v-card>
          <v-card-title class="headline grey lighten-2"
            >Escolha o nome do modelo</v-card-title
          >

          <v-card-text>
            <v-row>
              <v-text-field
                v-model="MODELO"
                label="Nome do modelo"
              ></v-text-field>
            </v-row>
            <v-row style="margin-top: -30px;">
              <v-spacer></v-spacer>
              <v-checkbox
                v-show="MODELOATUAL.length > 0"
                dense
                v-model="checkAtual"
                :label="`Salvar como modelo atual?`"
              ></v-checkbox>
            </v-row>
            <v-switch
              dense
              v-show="MODELOATUAL.length > 0"
              v-model="ModAtual"
              :label="`Salvar no modelo atual: ${MODELOATUAL}`"
            ></v-switch>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="error" text @click="DialogModelo = false"
              >Fechar</v-btn
            >
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="salvaModelo">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-bottom-sheet v-model="sheetMessage">
      <v-sheet class="text-center" :color="colorMessage" height="130px">
        <div class="py-3">
          <v-btn class="mx-2" fab>
            <v-icon v-text="iconMessage"></v-icon>
          </v-btn>
        </div>
        <div class="py-3" v-text="message"></div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TelaClientes",
  data: () => ({
    checkAtual: false,
    iconMessage: "",
    colorMessage: "",
    sheetMessage: false,
    message: null,
    ModAtual: false,
    DialogModelo: false,
    DialogVerCampos: false,
    SX3: [],
    SX3SELECIONADA: [],
    COUNTCAMPOS: 0,
    HEADERS_SX3: [],
    MODELOS: [],
    HEADERSMODELO: [],
    singleSelect: false,
    loading: true,
    search: null,
    MODELOATUAL: "",
    CAMPOSOBRIGATORIOS: 0,
    MODELO: null,
    CAMPOSMODELOATUAL: [],
  }),
  computed: {
    SomaCamposSelecionados() {
      let count = 0;
      count = this.SX3SELECIONADA.length;
      return count;
    },
  },
  methods: {
    marcaLinha(item) {
      const campo = item.X3_CAMPO;
      const resultado = this.SX3SELECIONADA.filter(e => {
        return e.X3_CAMPO === campo;
      });

      if (resultado.length > 0) {
        const marca = this.SX3SELECIONADA.filter(a => {
          return a.X3_CAMPO !== campo;
        });

        this.SX3SELECIONADA = [];
        this.SX3SELECIONADA = marca;
        this.COUNTSELECAO = this.SX3SELECIONADA.length;
      } else
        this.SX3SELECIONADA.push({
          ...item,
        });
      this.COUNTSELECAO = this.SX3SELECIONADA.length;
      this.search = "";
    },
    async salvaModelo() {
      if (this.ModAtual) {
        let camposNovos = [];

        for (
          let i = this.CAMPOSOBRIGATORIOS;
          i < this.SX3SELECIONADA.length;
          i++
        ) {
          const dados = this.SX3SELECIONADA[i];
          camposNovos.push({
            ...dados,
          });
        }

        let ZZP = [];

        camposNovos.forEach(e => {
          const ZZP_MODELO = this.MODELOATUAL;
          const ZZP_ATUAL = "1";
          const ZZP_TABELA = "SA1010";
          const ZZP_CAMPO = e.X3_CAMPO;
          ZZP.push({
            ZZP_MODELO,
            ZZP_ATUAL,
            ZZP_TABELA,
            ZZP_CAMPO,
          });
        });

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZZP,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/svModelo`;

        await axios
          .post(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.iconMessage = "mdi-check";
              this.colorMessage = "green lighten-4";
              this.message = "Modelo salvo com sucesso!";
              this.sheetMessage = true;
            }
          })
          .catch(err => {
            this.iconMessage = "mdi-close";
            this.colorMessage = "red lighten-4";
            this.message = `Aconteceu algum erro na hora de salvar seu modelo! ${err}`;
            this.sheetMessage = true;
          });
      } else {
        if (!this.MODELO || this.SX3SELECIONADA.length <= 0) {
          this.iconMessage = "mdi-close";
          this.colorMessage = "red lighten-4";
          this.message = "Escolha o nome do modelo para salvar!";
          this.sheetMessage = true;
          return;
        }

        if (this.MODELO.trim() === this.MODELOATUAL.trim()) {
          this.iconMessage = "mdi-close";
          this.colorMessage = "red lighten-4";
          this.message = "O nome do modelo não pode ser igual ao modelo atual!";
          this.sheetMessage = true;
          return;
        }

        if (this.checkAtual) {
          let dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            WHERE: `ZZP_TABELA='SA1010' AND ZZP_MODELO='${this.MODELOATUAL}'`,
            FIELDS: `ZZP_CAMPO`,
          };

          let url = `${process.env.VUE_APP_BASE_API_URL}/modelos`;

          let ZZPAUTAL = [];

          await axios.post(url, dados).then(res => {
            ZZPAUTAL = res.data;
          });

          let ZZP = [];

          ZZPAUTAL.forEach(e => {
            const ZZP_CAMPO = e.ZZP_CAMPO;
            ZZP.push({
              ZZP_CAMPO,
              ZZP_MODELO: this.MODELOATUAL,
              ZZP_TABELA: "SA1010",
              ZZP_ATUAL: "2",
            });
          });

          dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            ZZP,
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/svModelo`;

          console.log(dados);

          await axios.put(url, dados).then(res => {
            console.log(res.data);
          });
        }

        let ZZP = [];

        this.SX3SELECIONADA.forEach(e => {
          const ZZP_MODELO = this.MODELO;
          const ZZP_ATUAL = "1";
          const ZZP_TABELA = "SA1010";
          const ZZP_CAMPO = e.X3_CAMPO;
          ZZP.push({
            ZZP_MODELO,
            ZZP_ATUAL,
            ZZP_TABELA,
            ZZP_CAMPO,
          });
        });

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZZP,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/svModelo`;

        await axios
          .post(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.iconMessage = "mdi-check";
              this.colorMessage = "green lighten-4";
              this.message = "Modelo salvo com sucesso!";
              this.sheetMessage = true;
            }
          })
          .catch(err => {
            this.iconMessage = "mdi-close";
            this.colorMessage = "red lighten-4";
            this.message = `Aconteceu algum erro na hora de salvar seu modelo! ${err}`;
            this.sheetMessage = true;
          });
      }
    },
    async carregaModelos() {
      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        WHERE: `ZZP_TABELA='SA1010'`,
        FIELDS: `DISTINCT ZZP_MODELO,ZZP_ATUAL`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/modelos`;

      await axios.post(url, dados).then(res => {
        this.MODELOS = res.data;

        this.MODELOS.map(e => {
          if (e.ZZP_ATUAL.trim() === "1") {
            e.ZZP_ATUAL = "SIM";
            this.MODELOATUAL = e.ZZP_MODELO;
          } else {
            e.ZZP_ATUAL = "NÃO";
          }
        });
      });

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        WHERE: `ZZP_TABELA='SA1010'`,
        FIELDS: `ZZP_CAMPO`,
      };

      await axios.post(url, dados).then(res => {
        this.CAMPOSMODELOATUAL = res.data;
      });
      const keys = this.MODELOS.length > 0 ? Object.keys(this.MODELOS[0]) : [];

      keys.forEach(e => {
        const campo = e.split("_");
        this.HEADERSMODELO.push({
          text: campo[1],
          value: e,
          caption: "title",
          width: 130,
        });
      });
    },
    async carregaSX3() {
      this.SX3 = [];
      this.SX3SELECIONADA = [];
      this.loading = true;
      this.COUNTCAMPOS = 0;
      this.COUNTSELECAO = 0;
      this.CAMPOSOBRIGATORIOS = 0;
      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        WHERE: `X3_ARQUIVO='SA1' ORDER BY X3_ORDEM`,
        FIELDS: "",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/dicionario`;

      await axios
        .post(url, dados)
        .then(res => {
          this.SX3 = res.data;
          this.SX3.forEach(e => {
            e.BANCO = true;
          });
          this.COUNTCAMPOS = this.SX3.length;
        })
        .catch(err => {
          this.iconMessage = "mdi-close";
          this.colorMessage = "red lighten-4";
          this.message = `Aconteceu algum erro! ${err}`;
          this.sheetMessage = true;
        });

      let keys = Object.keys(this.SX3[0]);

      keys.forEach(e => {
        const campo = e.split("_");
        if (
          e !== "BANCO" &&
          e !== "R_E_C_N_O_" &&
          e !== "R_E_C_D_E_L_" &&
          e !== "D_E_L_E_T_"
        ) {
          this.HEADERS_SX3.push({
            text: campo[1],
            value: e,
            caption: "title",
            width: 130,
          });
        }
      });

      this.CAMPOSMODELOATUAL.forEach(e => {
        this.SX3.forEach(c => {
          if (c.X3_CAMPO === e.ZZP_CAMPO) {
            this.SX3SELECIONADA.push({
              ...c,
            });
          }
        });
      });

      // this.SX3.map((e) => {
      //   if (e.X3_OBRIGAT.trim() === "x") {
      //     e.disabled = true;
      //     this.SX3SELECIONADA.push({
      //       ...e,
      //     });
      //   }
      // });

      // this.COUNTSELECAO = this.SX3SELECIONADA.length;
      // this.CAMPOSOBRIGATORIOS = this.SX3SELECIONADA.length;
      this.loading = false;
    },
  },
  async mounted() {
    await this.carregaModelos();
    this.carregaSX3();
  },
};
</script>

<style></style>
