<template>
  <v-card class="d-flex">
    <div class="tableCli" style="max-width: 100%">
      <v-data-table
        :headers="headersBase"
        dense
        :items="ItensBase"
        class="elevation-1"
        no-data-text="Sem dados..."
        fixed-header
        :height="altura"
        :loading="loading"
        :search="search"
        :items-per-page="21"
        :footer-props="{
          disableItemsPerPage: true,
        }"
      >
        <template v-slot:top>
          <v-row dense class="pa-4">
            <h3>Base Instalada</h3>
          </v-row>
          <v-toolbar flat color="white">
            <v-row dense>
              <v-btn color="indigo" dark @click.stop="abrePainel" class="mt-3">
                <v-icon v-if="!stDrawer">mdi-chevron-right</v-icon
                ><v-icon v-if="stDrawer">mdi-chevron-left</v-icon>Painéis
              </v-btn>
              <v-col cols="2">
                <v-btn
                  class="mx-5 mt-2"
                  fab
                  x-small
                  dark
                  color="indigo"
                  @click="novo()"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" v-if="NrRegFiltro !== null">
                <span style="font-size: 0.7rem"
                  >Filtro aplicado no <strong>{{ filtroEscolhido }}</strong
                  >, {{ NrRegFiltro }} registro(s) encontrado(s).</span
                >
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  fab
                  color="error"
                  @click="LimpaFiltro()"
                >
                  <v-icon dark class="ml-1">mdi-eraser</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2">
                <v-select
                  v-model="filtroEscolhido"
                  :items="itemsFiltro"
                  label="Escolha o campo"
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="digite aqui..."
                  v-model="stringFiltro"
                  :disabled="!filtroEscolhido"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  color="indigo"
                  @click="filtraRegistro(filtroEscolhido, stringFiltro)"
                >
                  <v-icon dark class="ml-1">mdi-account-search</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar cadastro</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="verDados(item)" v-bind="attrs" v-on="on"
                >mdi-eye</v-icon
              >
            </template>
            <span>Visualizar cadastro</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="dadosCli text-center">
      <v-dialog v-model="DialogDados" width="90%" persistent>
        <v-card v-if="boilerplate" ref="form">
          <v-card-title
            class="headline grey lighten-2"
            v-text="titleDialog"
          ></v-card-title>

          <v-card-text>
            <v-row dense class="mt-5">
              <v-col
                v-for="campo in camposTela"
                :key="campo.ZZP_CAMPO"
                :cols="campo.ZZP_COLS"
              >
                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :value="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :label="campo.ZZP_TITULO"
                  v-if="campo.ZZP_TIPO === 'T'"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  class="text-uppercase"
                  dense
                  :readonly="isReading || campo.ZZP_VISUAL == 2"
                ></v-text-field>
                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :value="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :label="campo.ZZP_TITULO"
                  v-if="campo.ZZP_TIPO === 'D'"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  v-mask="'##/##/####'"
                  class="text-uppercase"
                  dense
                  :readonly="isReading || campo.ZZP_VISUAL == 2"
                ></v-text-field>
                <v-select
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :items="campo.ITEMS"
                  :label="campo.ZZP_TITULO"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  v-if="campo.ZZP_TIPO === 'S'"
                  class="text-uppercase"
                  dense
                  :readonly="isReading || campo.ZZP_VISUAL == 2"
                ></v-select>
                <v-autocomplete
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                  style="margin-top: -22px"
                  dense
                  :items="itemsTipo"
                  @focus="carregaItems(campo)"
                  @click="carregaItems(campo)"
                  @blur="gatilhos(campo)"
                  no-data-text="Buscando dados..."
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  filled
                  :label="campo.ZZP_TITULO"
                  background-color="white"
                  v-if="campo.ZZP_TIPO === 'A' || campo.ZZP_TIPO === 'F'"
                  class="text-uppercase"
                  :readonly="isReading || campo.ZZP_VISUAL == 2"
                ></v-autocomplete>
                <v-textarea
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  auto-grow
                  background-color="white"
                  clearable
                  counter
                  rows="1"
                  row-height="20"
                  :items="campo.ITEMS"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  :label="campo.ZZP_TITULO"
                  :readonly="isReading || campo.ZZP_VISUAL == 2"
                  v-if="campo.ZZP_TIPO === 'Y'"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="error" text @click="DialogDados = false"
              >Fechar</v-btn
            >
            <v-spacer></v-spacer>
            <v-col cols="5">
              <v-progress-linear
                v-model="progress"
                v-if="progress > 0"
                color="blue-grey"
                height="25"
              >
                <template v-slot="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              v-if="!isReading && !isEditing"
              text
              @click="salva()"
              >Salvar</v-btn
            >
            <v-btn
              color="primary"
              v-if="!isReading && isEditing"
              text
              @click="salva(id)"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="boilerplate"
          type="table"
          class="mx-auto"
          v-if="!boilerplate"
        ></v-skeleton-loader>
      </v-dialog>
    </div>
    <v-bottom-sheet v-model="sheetMessage">
      <v-sheet class="text-center" :color="colorMessage" height="130px">
        <div class="py-3">
          <v-btn class="mx-2" text fab>
            <v-icon v-text="iconMessage" @click="sheetMessage = false"></v-icon>
          </v-btn>
        </div>
        <div class="py-3" style="font-size: 15px" v-text="message"></div>
      </v-sheet>
    </v-bottom-sheet>

    <v-dialog v-model="dialogSuccessSavebaseInstalada" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">Cliente</v-card-title>
        <v-card-text class="mt-5 title">{{ mensagem }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="limpaVariaveis">fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAwaitSavebaseInstalada" width="500">
      <v-card>
        <v-overlay :value="dialogAwaitSavebaseInstalada" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title class="headline grey lighten-2"
          >Base Instalada</v-card-title
        >
        <v-card-subtitle>
          <p class="body-1">Salvando Base Instalada...</p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogErrosbaseInstalada" width="900">
      <v-card max-width="900" class="mx-auto">
        <v-card-title class="headline red lighten-2" style="color: white"
          >Erros ao salvar</v-card-title
        >

        <v-card-text>
          <v-list disabled>
            <v-subheader>Erros</v-subheader>
            <v-list-item-group v-model="aError" color="primary">
              <v-list-item v-for="(item, i) in aError" :key="i">
                <v-list-item-icon>
                  <v-icon color="red" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 0.8rem"
                    v-text="item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogErrosbaseInstalada = false"
            >fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  name: "baseInstalada",
  data: () => {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      progress: 0,
      drawer: true,
      aError: [],
      dialogErrosbaseInstalada: false,
      dialogAwaitSavebaseInstalada: false,
      dialogAwaitBuscaBaseInstalada: false,
      dialogBaseInstalada: false,
      dialogSuccessSavebaseInstalada: false,
      baseInstaladaAnt: [],
      errosTE: false,
      mensagem: null,
      sheetMessage: false,
      colorMessage: "",
      iconMessage: "",
      titleDialog: "",
      message: "",
      DialogDados: false,
      isReading: false,
      search: null,
      loading: true,
      altura: 0,
      perPage: 0,
      headersBase: [],
      ItensBase: [],
      headersApontamentos: [],
      itemsApontamentos: [],
      headersTrackerPrincipal: [],
      itemsTrackerPrincipal: [],
      headersTracker: [],
      itemsTracker: [],
      itemsTipo: [],
      headersAA3: [],
      itemsAA3: [],
      isEditing: false,
      camposTela: {},
      dadosTela: [],
      cliente: {},
      boilerplate: false,
      dadosCep: [],
      DtInicial: new Date().toISOString().substr(0, 10),
      countObrigatorio: 0,
      cloneDadosTela: [],
      indFiltro: "C",
      filtroEscolhido: null,
      itemsFiltro: ["Cliente", "Produto", "Núm. de Série"],
      stringFiltro: null,
      NrRegFiltro: null,
      top500AA3: [],
      stringCampos: "",
    };
  },
  computed: {
    ...mapState(["user", "metadados", "stDrawer", "stMini"]),
    DataFormatadaInicial() {
      return moment(this.DtInicial).format("DD/MM/YYYY");
    },
  },
  methods: {
    queryAndIndeterminate() {
      this.progress = 0;

      this.interval = setInterval(() => {
        if (this.progress === 100) {
          clearInterval(this.interval);
          this.progress = 0;
          return;
          //return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.progress += 5;
      }, 1000);
    },
    gatilhos(item) {
      if (item.ZZP_CAMPO.trim() === "AA3_CODCLI") {
        this.dadosTela.AA3_LOJA = "01";
      }
    },
    LimpaFiltro() {
      this.stringFiltro = null;
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.ItensBase = this.top500AA3;
    },
    async filtraRegistro(campo, stringFiltro) {
      if (!campo || !stringFiltro) return;

      let WHERE = "";

      if (campo === "Cliente") {
        WHERE += `(AA3_CODCLI LIKE '^${stringFiltro.toUpperCase()}^' OR A1_NOME LIKE '^${stringFiltro.toUpperCase()}^')`;
      }

      if (campo === "Produto") {
        WHERE += `(AA3_CODPRO LIKE '^${stringFiltro.toUpperCase()}^' OR B1_DESC LIKE '^${stringFiltro.toUpperCase()}^')`;
      }

      if (campo === "Núm. de Série") {
        WHERE += `AA3_NUMSER LIKE '^${stringFiltro}^'`;
      }

      this.loading = true;
      this.top500AA3 = this.ItensBase;
      this.ItensBase = [];

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: this.stringCampos,
        WHERE: WHERE,
        ORDER: " AA3_CODCLI, AA3_CODPRO, AA3_NUMSER",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/getBaseInstalada`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.AA3_CODCLI = `${e.AA3_CODCLI} - ${e.A1_NOME}`;
            e.AA3_CODPRO = `${e.AA3_CODPRO} - ${e.B1_DESC}`;

            e.AA3_DTVEND = `${e.AA3_DTVEND.substr(6, 2)}/${e.AA3_DTVEND.substr(
              4,
              2,
            )}/${e.AA3_DTVEND.substr(0, 4)}`;
            e.AA3_DTGAR = `${e.AA3_DTGAR.substr(6, 2)}/${e.AA3_DTGAR.substr(
              4,
              2,
            )}/${e.AA3_DTGAR.substr(0, 4)}`;
          });
          this.ItensBase = res.data;
          this.NrRegFiltro = this.ItensBase.length;
        })
        .catch(err => console.log(err));

      this.loading = false;
    },
    obrigat(value, obrigatorio) {
      if (!value && obrigatorio === "1") {
        return ["Campo obrigatório!"];
      }
    },
    abrePainel() {
      this.drawer = !this.drawer;
      this.$store.commit("SetStDrawer", this.drawer);
      this.$store.commit("SetStMini", false);
    },
    //Função para remover caracteres especiais na Notas
    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");
      return text;
    },
    async carregaItems(item) {
      let dados = {};
      let items = [];
      let stringCampos = "";
      const campos = item.ZZP_F3CAMP.split(";");
      this.itemsTipo = [];

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          stringCampos += `${campos[i]}`;
        } else {
          stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${stringCampos.trim()}`,
          WHERE: ``,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;

            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }

            return dados;
          });
          this.itemsTipo = items;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    async salva(id) {
      const keysObrigat = [];
      this.camposTela.filter(e => {
        if (e.ZZP_OBRIGA === "1") {
          const campo = e.ZZP_CAMPO.trim();
          keysObrigat.push({
            campo,
          });
        }
      });

      let verifica = true;
      this.cloneDadosTela = this.dadosTela;

      const keys = Object.keys(this.cloneDadosTela);

      keysObrigat.forEach(e => {
        if (keys.indexOf(e.campo) < 0) {
          verifica = false;
        }
      });

      if (!verifica) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      this.queryAndIndeterminate();
      keys.forEach(e => {
        this.cloneDadosTela[e] = this.cloneDadosTela[e].toUpperCase();
      });

      this.camposTela.forEach(e => {
        let value = null;
        if (e.ZZP_TIPO.trim() === "A") {
          if (typeof this.cloneDadosTela[e.ZZP_CAMPO.trim()] !== "undefined") {
            value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("-");
          }

          if (value !== null) {
            this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
          }
        }
        if (e.ZZP_TIPO.trim() === "S") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("=");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
        }
        if (e.ZZP_TIPO.trim() === "D") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("/");
          this.cloneDadosTela[
            e.ZZP_CAMPO.trim()
          ] = `${value[2]}${value[1]}${value[0]}`;
        }
        if (
          e.ZZP_TIPO.trim() === "T" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          if (e.ZZP_CAMPO.trim() !== "AA3_NUMSER") {
            value = this.removeAcento(this.cloneDadosTela[e.ZZP_CAMPO.trim()]);
          } else {
            value = this.cloneDadosTela[e.ZZP_CAMPO.trim()];
          }
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value;
        }
        if (e.ZZP_TIPO.trim() === "F") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].substring(0, 4);
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value;
        }
      });

      let baseInstalada = { ...this.cloneDadosTela };
      let isNumeroSerie = false;
      let novoNumeroSerie = "";

      if (id) {
        baseInstalada.AA3_ZZID = id;
        baseInstalada.AA3_CODCLI = baseInstalada.AA3_CODCLI.trim();

        if (
          baseInstalada.AA3_NUMSER.trim() !==
          this.baseInstaladaAnt[0].AA3_NUMSER.trim()
        ) {
          isNumeroSerie = true;
          novoNumeroSerie = baseInstalada.AA3_NUMSER;
          baseInstalada.AA3_NUMSER = this.baseInstaladaAnt.AA3_NUMSER;
        }

        baseInstalada.AA3_NUMSER = this.baseInstaladaAnt[0].AA3_NUMSER;

        delete baseInstalada.A1_NOME;
        delete baseInstalada.B1_DESC;

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          AA3: baseInstalada,
          isNumeroSerie,
          novoNumeroSerie,
        };

        this.dialogAwaitSavebaseInstalada = true;

        const url = `${process.env.VUE_APP_BASE_API_URL}/saveBaseInstalada`;
        await axios
          .put(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = /*`baseInstalada salva com o ID: ${res.data.CODIGO}`*/ `Base Instalada salva com sucesso!`;
              this.dialogAwaitSavebaseInstalada = false;
              this.dialogSuccessSavebaseInstalada = true;
              this.progress = 100;
              // this.ItensBase = this.top500AA3;
              this.montaBrowser();
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({ text: `${e}`, icon: "mdi-send" });
              });
              this.dialogAwaitSavebaseInstalada = false;
              this.dialogErrosbaseInstalada = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => console.log(err));
      } else {
        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          AA3: baseInstalada,
        };

        this.dialogAwaitSavebaseInstalada = true;

        const url = `${process.env.VUE_APP_BASE_API_URL}/saveBaseInstalada`;
        await axios
          .post(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = /*`baseInstalada salva com o ID: ${res.data.CODIGO}`*/ `Base Instalada salva com sucesso!`;
              this.dialogAwaitSavebaseInstalada = false;
              this.dialogSuccessSavebaseInstalada = true;
              this.progress = 100;
              // this.ItensBase = this.top500AA3;
              this.montaBrowser();
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({ text: `${e}`, icon: "mdi-send" });
              });
              this.dialogAwaitSavebaseInstalada = false;
              this.dialogErrosbaseInstalada = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => console.log(err));
      }

      this.camposTela.forEach(e => {
        let value = null;
        if (e.ZZP_TIPO.trim() === "D") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()];
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = `${value.substr(
            6,
            2,
          )}/${value.substr(4, 2)}/${value.substr(0, 4)}`;
        }
      });
      this.progress = 100;
    },
    limpaVariaveis() {
      this.DialogDados = false;
      this.dialogSuccessSavebaseInstalada = false;
      this.montaBrowser();
    },
    async carregaDados() {
      this.boilerplate = false;
      let AA3 = [];

      AA3 = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === "MD9" && e.ZZP_GRAVA.trim() === "S",
      );

      AA3.map(e => {
        if (e.ZZP_CBOX.trim().length > 0) {
          const dados = e.ZZP_CBOX.split(";");
          e.ITEMS = dados.map(i => {
            return `${i}`;
          });
        }
      });

      await AA3.forEach(e => {
        if (e.ZZP_F3TAB.trim().length > 0) {
          this.chamaConsulta(e).then(resp => {
            e.ITEMS = resp;
          });
        }
        if (e.ZZP_TIPO.trim() === "D") {
          e.MASK = "##/##/####";
        }
        if (e.ZZP_OBRIGA === "1") {
          this.countObrigatorio++;
        }
      });

      this.camposTela = AA3;
      this.boilerplate = true;
    },
    async reload(item) {
      item.ITEMS = await this.chamaConsulta(item);

      this.camposTela.map(e => {
        if (e.ZZP_CAMPO.trim() === item.ZZP_CAMPO.trim()) {
          e.ITEMS = item.ITEMS;
        }
      });

      return this.camposTela;
    },
    async chamaConsulta(item) {
      let dados = {};
      let items = [];
      const campos = item.ZZP_F3CAMP.split(";");
      let stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          stringCampos += `${campos[i]}`;
        } else {
          stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${stringCampos.trim()}`,
          WHERE: ``,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            return `${i[campos[0].trim()].trim()}-${i[
              campos[1].trim()
            ].trim()}`;
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
      return items;
    },
    async montaBrowser() {
      this.altura = window.screen.availHeight - 300;
      this.loading = true;
      this.stringCampos = "";
      this.headersBase = [];

      let AA3 = [];
      let dados = {};
      let url = ``;
      this.headersBase.push({
        text: "Actions",
        value: "actions",
        align: "left",
        width: 75,
        caption: "title",
      });

      AA3 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD9");

      AA3.map(e => {
        if (e.ZZP_BROWSE !== "0") {
          let width = 0;

          this.stringCampos += `${e.ZZP_CAMPO.trim()},`;

          if (e.ZZP_BROWSE === "1") {
            if (e.ZZP_COLS <= 2) {
              width = 100;
            } else if (e.ZZP_COLS <= 3) {
              width = 250;
            } else {
              width = 300;
            }

            this.headersBase.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "left",
              width: width,
              caption: "title",
            });
          }
        }
      });

      if (this.stringCampos.substr(-1) === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: ` TOP 500 ${this.stringCampos}`,
        WHERE: "",
        ORDER: " AA3_CODCLI, AA3_CODPRO, AA3_NUMSER",
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/getBaseInstalada`;

      this.ItensBase = [];

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.AA3_CODCLI = `${e.AA3_CODCLI} - ${e.A1_NOME}`;
            e.AA3_CODPRO = `${e.AA3_CODPRO} - ${e.B1_DESC}`;

            e.AA3_DTVEND = `${e.AA3_DTVEND.substr(6, 2)}/${e.AA3_DTVEND.substr(
              4,
              2,
            )}/${e.AA3_DTVEND.substr(0, 4)}`;
            e.AA3_DTGAR = `${e.AA3_DTGAR.substr(6, 2)}/${e.AA3_DTGAR.substr(
              4,
              2,
            )}/${e.AA3_DTGAR.substr(0, 4)}`;
          });
          this.ItensBase = res.data;
        })
        .catch(err => console.log(err));

      this.perPage = this.ItensBase.length;
      this.loading = false;
    },
    verDados(item) {
      this.carregaDados();
      this.titleDialog = "Visualizando Base Instalada";
      this.dadosTela = item;
      this.isReading = true;
      this.DialogDados = true;
    },
    editItem(item) {
      this.carregaDados();
      this.titleDialog = "Editando Base Instalada";
      this.id = item.AA3_ZZID;

      this.isEditing = true;
      this.dadosTela = item;
      this.baseInstaladaAnt = [];
      this.baseInstaladaAnt.push({ ...item });
      this.isReading = false;
      this.DialogDados = true;
    },
    novo() {
      this.dadosTela = [];
      this.isReading = false;
      this.titleDialog = `Nova Base Instalada`;
      this.carregaDados();
      this.DialogDados = true;
    },
  },
  mounted() {
    this.montaBrowser();
    //this.carregaDados();
    // console.log(this.user)
  },
};
</script>

<style>
.v-label {
  font-size: 0.7rem;
}
input {
  font-size: 0.8rem;
}
.text-center,
.text-left {
  font-size: 0.7rem !important;
}
tbody tr:nth-of-type(odd) {
  background-color: #d8f6fa;
}
.v-select__selection {
  font-size: 0.8rem !important;
}
</style>
