<template>
  <v-app id="inspire">
    <!-- <Auth v-if="!user"/> -->
    <NewAuth v-if="!user" />
    <Home v-if="user" />
  </v-app>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Home from "./components/Home/Home";
// import Auth from "./components/auth/Auth";
import NewAuth from "./components/auth/newAuth";
export default {
  name: "App",
  components: {
    // Auth,
    NewAuth,
    Home,
  },
  computed: mapState(["user"]),
  data: () => ({
    validatetoken: true,
  }),
  methods: {
    async validationtoken() {
      this.validatetoken = true;
      const json = localStorage.getItem(process.env.VUE_APP_USER_KEY);
      const userData = json && JSON.parse(json);
      const jsonMeta = localStorage.getItem(process.env.VUE_APP_METADADOS_KEY);
      const MetadadosData = JSON.parse(jsonMeta);
      //this.$store.commit("SetUser", null)

      if (this.$router.currentRoute.path === "/trataAlcada") {
        console.log("entrei no trata alçada");
        this.$store.commit("setTrataAlcada", true);
        this.$store.commit(
          "setDadosTratamento",
          this.$router.currentRoute.query,
        );
        this.$store.commit("SetLimpaTabs", []);
        this.$store.commit("SetTitle", "Tratamento de alçada");
        this.$store.commit("SetTabs", {
          name: `Alcadas`,
          content: `TrataAlcada`,
        });
        this.$store.commit("SetTitle", "Tratamento de Alçada");
      } else {
        this.$store.commit("setDadosTratamento", []);
        this.$store.commit("setTrataAlcada", false);
      }

      if (!userData) {
        this.validatetoken = false;
        return;
      }

      const res = await axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/validateToken`,
        userData,
      );

      if (res.data) {
        this.$store.commit("SetUser", userData);
        this.$store.commit("SetMetadados", MetadadosData);
      } else {
        localStorage.removeItem(process.env.VUE_APP_USER_KEY);
        localStorage.removeItem(process.env.VUE_APP_METADADOS_KEY);
        this.$store.commit("SetUser", null);
      }

      this.validatetoken = false;
    },
  },
  created() {
    this.validationtoken();
  },
};
</script>

<style>
.text-money input {
  text-align: end;
}
</style>
