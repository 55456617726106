<template>
  <div class="content">
    <v-dialog v-model="esperaBuscaDados" persistent width="30vw">
      <v-card>
        <v-card-title>Buscando dados do cliente aguarde</v-card-title>
        <v-card-text>
          <div class="d-flex flex-column justify-center align-center">
            <p>Buscando dados do cliente aguarde...</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="error"
            x-small
            @click="esperaBuscaDados = !esperaBuscaDados"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="obrigatorioInformativo"
      style="z-index: 1000;"
      persistent
      width="60vw"
    >
      <v-card>
        <v-card-title v-if="!incluiAgenda"
          >Atualização Obrigatória de Telefone, WhatsApp e E-mail!</v-card-title
        >
        <v-card-title v-else
          >Atualização de Telefone, WhatsApp e E-mail!</v-card-title
        >
        <v-card-subtitle v-if="!incluiAgenda"
          >É obrigatório atualizar os campos faltantes!</v-card-subtitle
        >
        <v-card-text>
          <v-row dense class="d-flex justify-center align-center">
            <v-col cols="1">
              <v-text-field
                v-model="dddTel"
                label="DDD"
                maxlength="2"
                @change="atualizei = true"
                :append-outer-icon="dddTel ? 'mdi-check' : 'mdi-cancel'"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="telefone"
                label="Telefone"
                maxlength="13"
                @change="atualizei = true"
                :append-outer-icon="telefone ? 'mdi-check' : 'mdi-cancel'"
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-text-field
                v-model="whatsAppTel"
                label="WhatsApp"
                maxlength="13"
                @change="atualizei = true"
                :append-outer-icon="whatsAppTel ? 'mdi-check' : 'mdi-cancel'"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="emailAtu"
                label="email"
                @change="atualizei = true"
                :append-outer-icon="emailAtu ? 'mdi-check' : 'mdi-cancel'"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="d-flex justify-center align-center">
            <span class="text-h6 red--text" v-if="!incluiAgenda"
              >Obrigatório atualizar.Após a operação a tela de detalhes da
              agenda será exibida!</span
            >
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="error"
            x-small
            @click="
              obrigatorioInformativo = !obrigatorioInformativo;
              atualizei = false;
            "
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="primary"
            x-small
            v-if="!incluiAgenda"
            :disabled="!emailAtu || !whatsAppTel || !dddTel || !telefone"
            @click="atualizaTelEnviaWhats"
            >Atualizar</v-btn
          >
          <v-btn class="primary" x-small v-else @click="atualizaTelEnviaWhats"
            >Continuar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVerHorarios" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Horários Vagos - {{ dataTela }}</v-card-title
        >

        <v-card-text>
          <v-data-table
            :headers="headersTbHorasVagas"
            :items="aHorasDia"
            dense
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                color="green darken-2"
                @click="agendar(item.horaIni)"
                v-if="item.vago"
              >
                <v-icon>mdi-calendar-account-outline</v-icon>
              </v-btn>
              <v-chip
                class="ma-2"
                color="red"
                text-color="white"
                x-small
                v-if="!item.vago"
                >Agendado</v-chip
              >
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogVerHorarios = false"
            >fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetalhes" width="50vw" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Detalhes do agendamento</v-card-title
        >
        <v-card-text>
          <v-row align="center">
            <v-col cols="5">
              <v-text-field
                dense
                label="Título"
                v-model="DetTitulo"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                label="Local Atendimento"
                v-model="DetLocalAtendimento"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                label="Número OS"
                v-model="DetNumOs"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -10px">
            <v-col cols="3">
              <v-autocomplete
                v-model="Lead"
                background-color="white"
                no-data-text="Buscando Leads..."
                label="Lead"
                class="mb-3"
                filled
                dense
                :loading="loadingLeads"
                :placeholder="Lead"
                :items="itensLeads"
                @change="pegaNome"
                @focus="montaLeads"
              ></v-autocomplete>
              <!-- <v-text-field
                dense
                label="Lead"
                v-model="Lead"
                readonly
              ></v-text-field> -->
            </v-col>
            <v-col cols="5">
              <v-text-field
                dense
                label="Cliente"
                v-model="DetCliente"
                readonly
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="2">
              <v-text-field
                dense
                label="Hora Inicial"
                v-model="DetHoraIni"
                readonly
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="2">
              <v-text-field
                dense
                label="Hora Final"
                v-model="DetHoraFim"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -10px">
            <v-col cols="1">
              <v-text-field
                dense
                label="DDD"
                v-model="DetDdd"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                label="Telefone"
                v-model="DetTel"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                label="Telefone 2"
                v-model="DetTel2"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <!-- Vitor 08/04/2021 -->
              <v-text-field
                dense
                label="Canal"
                v-model="DetCanal"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                label="Médico/Representante"
                v-model="DetAssessor"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -10px">
            <v-col cols="12">
              <v-textarea dense v-model="DetobsAgenda" color="teal" rows="1">
                <template v-slot:label>
                  <div>Notas</div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-bottom: -15px; margin-top: -10px">
            <v-col cols="4" style="margin-top: -14px">
              <v-autocomplete
                dense
                v-model="status"
                :items="itensStatus"
                filled
                label="Status"
                background-color="white"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                label="Data"
                v-model="DetDtAgenda"
                readonly
              ></v-text-field>
            </v-col>
            <!-- <v-spacer></v-spacer>
            <v-divider></v-divider> -->
            <v-col cols="2">
              <v-text-field
                dense
                label="Data Alteração"
                v-model="DetDtAlt"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                label="Usuário Alteração"
                v-model="DetUserAlt"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="transfer">
            <v-divider></v-divider>
            <span>Transferir Agenda</span>
            <v-row align="center" style="margin-top: -10px">
              <v-col cols="4">
                <v-text-field
                  v-model="dataTransfere"
                  label="Digite a nova data"
                  v-mask="'##/##/####'"
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="2">
                <v-text-field
                  dense
                  label="Hora Inicial"
                  v-model="transfereHini"
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="2">
                <v-text-field
                  dense
                  label="Hora Final"
                  v-model="transfereHfim"
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="4">
                <v-autocomplete
                  style="margin-top: -14px"
                  dense
                  v-model="newProf"
                  :items="nomes"
                  filled
                  label="Novo profissional"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" style="margin-top: -20px">
              <v-col cols="12">
                <v-textarea
                  dense
                  v-model="notaTransfere"
                  color="teal"
                  rows="1"
                  style="margin-top: -2px"
                >
                  <template v-slot:label>
                    <div>Nota</div>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" x-small dark @click="limpaVariaveis">fechar</v-btn>
          <!-- <v-spacer></v-spacer>
          <v-btn
            color="indigo darken-2"
            small
            dark
            @click="dialogTrocaProf = true"
            :disabled="almoco"
            >Trocar Profissional</v-btn
          > -->
          <v-spacer></v-spacer>
          <v-btn color="red darken-3" x-small dark @click="deleteAgenda"
            >Excluir agendamento</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="purple"
            x-small
            dark
            @click="transfereAgenda"
            :disabled="transferida || almoco"
            >Transferir Agenda</v-btn
          >
          <v-chip class="ma-2" color="red darken-3" dark v-if="transferida"
            >Esta agenda foi transferida!</v-chip
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            x-small
            dark
            @click="salvaAlteracao"
            :disabled="transferida || almoco"
            >Salvar Alteração</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-overlay :value="awaitBanco">
        <v-progress-circular
          indeterminate
          size="64"
          class="center"
        ></v-progress-circular>
        <p class="center" style="margin-left: -18px">{{ chamada }}</p>
      </v-overlay>
    </v-dialog>

    <v-dialog v-model="dialogAltStatus" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Alterar Status</v-card-title
        >
        <v-card-text>
          <v-row align="center">
            <v-row align="center">
              <v-col cols="8">
                <v-autocomplete
                  dense
                  v-model="status"
                  :items="itensStatus"
                  filled
                  label="Status"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="green" x-small dark @click="salvaAlteracao"
            >Salvar alteração</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="red" small dark @click="dialogAltStatus = false"
            >fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAgendar" width="60vw" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Agendar Horário - {{ dataTela }}</v-card-title
        >
        <v-card-text>
          <v-row align="center">
            <v-col cols="5">
              <!--<v-text-field dense label="Título" v-model="titulo" autofocus></v-text-field>-->
              <v-autocomplete
                v-model="titulo"
                :items="aItensTitulo"
                :loading="loadingItensTitulo"
                dense
                filled
                label="Título"
                background-color="white"
                autofocus
                style="margin-top: -12px"
                :rules="[rules.obrigatorio]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <!--<v-text-field dense label="Título" v-model="titulo" autofocus></v-text-field>-->
              <v-autocomplete
                v-model="localAtendimento"
                :items="itensAtendimento"
                item-text="ZZ2_LOCAL"
                item-value="ZZ2_CODIGO"
                dense
                filled
                label="Local de Atendimento"
                background-color="white"
                autofocus
                style="margin-top: -12px"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                label="Número OS"
                v-model="NumOs"
                @blur="carregaDadosOS(NumOs)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -20px">
            <v-col cols="4">
              <v-autocomplete
                dense
                v-model="status"
                :items="itensStatusInclusao"
                filled
                label="Status"
                background-color="white"
              ></v-autocomplete>
            </v-col>

            <v-col cols="4">
              <!-- Vitor 08/04/2021 -->
              <v-autocomplete
                dense
                :loading="loadingCanal"
                v-model="canal"
                :items="itensCanal"
                filled
                label="Canal"
                background-color="white"
              ></v-autocomplete>
            </v-col>
            <!-- <v-spacer></v-spacer> -->
            <v-radio-group v-model="tipoCliente" row>
              <v-radio label="Agenda p/ Cliente" value="C"></v-radio>
              <v-radio label="Agenda p/ Lead" value="L"></v-radio>
            </v-radio-group>
            <!-- <v-spacer></v-spacer> -->
          </v-row>
          <div v-if="tipoCliente === 'L'">
            <v-row align="center" style="margin-top: -20px">
              <v-col cols="5">
                <v-autocomplete
                  v-model="Lead"
                  label="Lead"
                  no-data-text="Buscando Leads..."
                  background-color="white"
                  dense
                  filled
                  :loading="loadingLeads"
                  :items="itensLeads"
                  @focus="montaLeads"
                  @change="pegaNome"
                ></v-autocomplete>
              </v-col>
              <v-col cols="7" style="margin-top: -5px">
                <v-text-field
                  dense
                  label="Digite o nome para a agenda"
                  v-model="nomeLead"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div v-if="tipoCliente === 'C'">
            <v-row align="center" style="margin-top: -20px">
              <v-col cols="3" style="margin-top: -20px; min-height: 90px">
                <v-switch
                  dense
                  v-model="clienteNbase"
                  label="Cliente fora da base"
                  style="font-size: 0.7rem"
                ></v-switch>
              </v-col>
              <v-col cols="9" style="margin-top: -20px; height: 90px;">
                <div class="d-flex flex-row align-center">
                  <v-text-field
                    v-if="clienteNbase"
                    dense
                    label="Digite o nome do cliente"
                    v-model="clienteForaBase"
                    height="48px"
                  ></v-text-field>
                  <v-autocomplete
                    v-else
                    v-model="cliente"
                    :loading="loadingCliBase"
                    :items="clienteBase"
                    label="Cliente"
                    background-color="white"
                    dense
                    filled
                    @change="dadosCli"
                  ></v-autocomplete>
                  <v-chip
                    v-show="!clienteNbase && clienteApac"
                    x-small
                    dark
                    color="red lighten-2"
                    class="ml-2"
                    >APAC</v-chip
                  >
                </div>
              </v-col>
            </v-row>
          </div>
          <v-row align="center" style="margin-top: -20px">
            <!--<v-switch v-model="minutoOne" dense label="10 minutos"></v-switch>
            <v-col cols="2">
              <v-btn class="mx-2" fab x-small dark color="indigo" @click="somaMinutos">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </v-col>-->

            <v-col class="d-flex" cols="12" sm="2">
              <v-select
                v-model="horaAgendaIni"
                :items="itemsHini"
                dense
                label="Hora Inicial"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="2">
              <v-select
                v-model="minutoAgendaIni"
                :items="itemsMini"
                dense
                label="Minutos"
                :placeholder="firstMinute"
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="12" sm="2">
              <v-select
                v-model="horaAgendaFim"
                :items="itemsHFim"
                dense
                label="Hora Final"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="2">
              <v-select
                v-model="minutoAgendaFim"
                :items="itemsMFim"
                dense
                label="Minutos"
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -20px">
            <v-col cols="1">
              <v-text-field
                dense
                label="DDD"
                v-model="dddCliente"
                :counter="2"
                :maxlength="4"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                label="Telefone"
                v-model="telCliente"
                :counter="9"
                :maxlength="9"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                label="Telefone 2"
                v-model="telCliente2"
                :counter="9"
                :maxlength="9"
              ></v-text-field>
            </v-col>
            <v-col cols="5" style="margin-top: -14px">
              <v-autocomplete
                dense
                :loading="loadingAssessor"
                v-model="agendaAssessor"
                :items="dbAssesor"
                filled
                label="Médico/Representante"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" style="margin-top: -15px">
              <v-textarea dense v-model="obsAgenda" color="teal" rows="1">
                <template v-slot:label>
                  <div>Notas</div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" x-small dark @click="limpaVariaveis">fechar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" x-small dark @click="salvaAgenda">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAwaitJob" width="500" persistent>
      <v-card>
        <v-overlay :value="dialogAwaitJob" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
        <v-card-subtitle>
          <p class="body-1">Aguarde terminando o preenchimento automático...</p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogErrosAgenda" width="900">
      <v-card max-width="900" class="mx-auto">
        <v-card-title class="headline red lighten-2" style="color: white"
          >Erros ao salvar</v-card-title
        >

        <v-card-text>
          <v-list disabled>
            <v-subheader>Erros</v-subheader>
            <v-list-item-group v-model="aError" color="primary">
              <v-list-item v-for="(item, i) in aError" :key="i">
                <v-list-item-icon>
                  <v-icon color="red" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 0.8rem"
                    v-text="item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogErrosAgenda = false"
            >fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTrocaProf" width="500">
      <v-card>
        <v-card-title>Troca de profissional</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="novoProfissional"
            :items="nomes"
            dense
            filled
            label="Escolha o novo profissional"
            background-color="white"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn class="error" dark small @click="dialogTrocaProf = false"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="primary" dark small @click="trocaProfissional"
            >Trocar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- coluna esquerda -->
    <div class="agenda_mes">
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="nameofdoctor"
            :items="nomes"
            :loading="loadTecnico"
            @change="buscaAgenda"
            dense
            filled
            label="Escolha o profissional"
            background-color="white"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="d-flex flex-row-reverse"
        style="margin-top: -30px"
      >
        <v-btn
          class="mx-2"
          x-small
          dark
          color="blue"
          @click="buscaAgenda"
          v-if="nameofdoctor && horarioDia.length > 0"
          >Renovar agenda</v-btn
        >
      </v-row>
      <p class="my-2">Tipo de visualização</p>
      <v-radio-group v-model="type" row @change="buscaAgenda" dense>
        <v-radio label="Categoria" value="category"></v-radio>
        <v-radio label="Semana" value="week"></v-radio>
        <v-radio label="Diário" value="day"></v-radio>
      </v-radio-group>
      <v-row class="d-flex ml-5 pb-4" align="center">
        <v-btn
          outlined
          x-small
          class="mr-4"
          color="grey darken-2"
          @click="setToday"
          >Hoje</v-btn
        >
        <v-toolbar-title
          v-if="$refs.calendar"
          style="text-transform: capitalize"
          class="ml-5"
          >{{ $refs.calendar.title }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn fab text small color="grey darken-2" @click="prevM">
          <v-icon small>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="nextM">
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
      <v-calendar
        ref="calendarM"
        type="month"
        :weekdays="weekday"
        :events="eventsMonth"
        :now="dataMes"
        color="primary"
        v-model="dataMes"
        locale="pt-BR"
        @change="buscaHoraAgenda"
      ></v-calendar>
    </div>
    <!-- fim coluna esquerda -->

    <!-- coluna direita -->
    <div class="d-flex flex-column justify-space-between" style="height: 90vh;">
      <v-toolbar flat color="white">
        <div class="chiphoras" v-if="type === 'category' || type === 'day'">
          <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            x-small
            v-if="horarioDia.length > 0"
            >Início: {{ horarioDia[0].horaIni }}</v-chip
          >
          <v-chip
            class="ma-2"
            color="orange"
            text-color="white"
            x-small
            v-if="horarioDia.length > 0"
            >Fim: {{ horarioDia[0].horaFim }}</v-chip
          >
          <v-chip
            class="ma-2"
            color="red"
            text-color="white"
            x-small
            v-if="horarioDia.length <= 0 && nameofdoctor"
            >Não trabalha</v-chip
          >
        </div>
        <v-spacer></v-spacer>
        <span>Legendas</span>

        <v-tooltip top color="cyan darken-1">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="cyan darken-1"
              width="20px"
              height="50%"
              class="mr-5 ml-3 filtrinho"
              tile
              @click="filtraLegenda('A')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegAgendado"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="filtroLegAgendado"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Agendamento</span>
        </v-tooltip>

        <v-tooltip top color="grey darken-1">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="grey darken-1"
              width="20px"
              height="50%"
              class="mr-5 filtrinho"
              tile
              @click="filtraLegenda('S')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegSemAgenda"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="filtroLegSemAgenda"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Bloqueado</span>
        </v-tooltip>

        <v-tooltip top color="green darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="green darken-2"
              width="20px"
              height="50%"
              class="mr-5 filtrinho"
              tile
              @click="filtraLegenda('C')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegCompareceu"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="filtroLegCompareceu"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Compareceu</span>
        </v-tooltip>
        <v-tooltip top color="red darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="red darken-2"
              width="20px"
              height="50%"
              class="mr-5 filtrinho"
              tile
              @click="filtraLegenda('N')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegNCompareceu"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="filtroLegNCompareceu"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Não compareceu</span>
        </v-tooltip>

        <v-spacer></v-spacer>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
        <v-toolbar-title
          v-if="$refs.calendar"
          style="text-transform: capitalize"
          >{{ $refs.calendar.title }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn
          class="mx-5"
          fab
          x-small
          dark
          color="indigo"
          @click="agendaPlus"
          v-if="nameofdoctor && horarioDia.length > 0"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
        <!--
        <v-btn
          class="mx-2"
          x-small
          dark
          color="blue"
          @click="verHoraVaga"
          v-if="
            nameofdoctor && horarioDia.length > 0 && this.type === 'category'
          "
        >
          Ver horário vago / incluir
        </v-btn>
        -->
      </v-toolbar>

      <v-calendar
        ref="calendar"
        v-model="dataMes"
        locale="pt-BR"
        color="primary"
        category-show-all
        :weekdays="weekday"
        :type="type"
        :first-time="first_time"
        interval-minutes="15"
        interval-height="40"
        @click:interval="novaAgenda"
        :interval-count="interval_count"
        :event-color="getEventColor"
        :events="events"
        :categories="categories"
        :now="dataMes"
        @change="buscaHoraAgenda"
      >
        <template v-slot:category="{ category }">
          <span class="ml-3">{{ category }}</span>
          <v-btn
            class="mx-2"
            fab
            x-small
            dark
            color="error"
            @click="limpaFiltro"
          >
            <v-icon dark>mdi-eraser</v-icon>
          </v-btn>
          <!--<v-btn x-small color="error" text class="ml-6" @click="limpaFiltro" dark>limpar</v-btn>-->
          <v-row class="pa-2">
            <v-col cols="12" v-if="category === 'Titulo'">
              <v-autocomplete
                v-model="tipo"
                :items="itemsTipo"
                dense
                filled
                @change="filtroTipo('Titulo')"
                @click="carregaTipos('Titulo')"
                label="Filtrar"
                background-color="white"
                :loading="loadfiltro"
                no-data-text="Sem agendamento"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="category === 'Paciente'">
              <v-autocomplete
                v-model="paciente"
                :items="itemsTipo"
                @change="filtroTipo('Paciente')"
                @click="carregaTipos('Paciente')"
                dense
                filled
                label="Filtrar"
                background-color="white"
                no-data-text="Sem agendamento"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="category === 'Médico/Representante'">
              <v-autocomplete
                v-model="assessor"
                :items="itemsTipo"
                @change="filtroTipo('Médico/Representante')"
                @click="carregaTipos('Médico/Representante')"
                dense
                filled
                label="Filtrar"
                background-color="white"
                no-data-text="Sem agendamento"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="category === 'Telefone'">
              <v-autocomplete
                v-model="telefone"
                :items="itemsTipo"
                @change="filtroTipo('Telefone')"
                @click="carregaTipos('Telefone')"
                dense
                filled
                label="Filtrar"
                background-color="white"
                no-data-text="Sem agendamento"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="category === 'Notas'">
              <v-autocomplete
                v-model="obs"
                :items="itemsTipo"
                dense
                filled
                label="Filtrar"
                background-color="white"
                no-data-text="Sem agendamento"
                @change="filtroTipo('Notas')"
                @click="carregaTipos('Notas')"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>
        <template v-slot:event="{ event }">
          <!-- <p style="color: black">{{event.respostaBot === '2'}}</p> -->
          <v-card
            :color="event.color"
            @click="verDados(event)"
            class="rounded d-flex flex-shrink-0 flex-column justify-space-around align-center"
            style="height: 100%;"
          >
            <v-card-title class="caption"
              >{{ event.name }}
              <div v-if="event.clienteApac">
                /
                <v-chip x-small dark color="red lighten-2" class="ml-2"
                  >APAC</v-chip
                >
              </div>
              <div v-if="event.respostaBot">
                /
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-whatsapp</v-icon>
                  </template>
                  <span>Resposta bot</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                      color="green darken-4"
                      dark
                      v-if="event.respostaBot === '1'"
                      >mdi-thumb-up</v-icon
                    >
                  </template>
                  <span>Confirmou a presença</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                      color="black"
                      dark
                      v-if="
                        event.respostaBot === '2' ||
                          event.respostaBot === 'false'
                      "
                      >mdi-calendar-arrow-right</v-icon
                    >
                  </template>
                  <span>Reagendar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                      color="error"
                      dark
                      v-if="event.respostaBot === '3'"
                      >mdi-thumb-down</v-icon
                    >
                  </template>
                  <span>Cancelou</span>
                </v-tooltip>
              </div>
            </v-card-title>
          </v-card>
          <!-- <div class="d-flex flex-shrink-0 flex-column justify-space-around align-center" style="height: 100%; width: 90%;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;">
            <p style="font-size: .6rem;">{{ event.name }}</p>
            <div>{{ event.respostaBot }}</div>

          </div> -->
          <!-- <v-card
            outlined
            dense
            height="100%"
            :color="event.color"
            @click="verDados(event)"
          > -->

          <!-- <v-card-text
              style="
                font-size: 0.7rem;
                color: white;
                padding: 0;
                margin-left: 5px;
                margin-right: 3px;
              "
              v-if="!event.icon"
              class="d-flex align-content-center flex-wrap"
              >{{ event.name }}</v-card-text
            >
            <v-card-actions>
              <v-chip>{{ event.respostaBot }}</v-chip>
            </v-card-actions>
            <v-row justify="center" class="mt-5">
              <v-icon v-if="event.icon" color="white" size="64">{{
                event.icon
              }}</v-icon>
            </v-row>
          </v-card> -->
        </template>
      </v-calendar>

      <v-dialog v-model="dialogAwaitAgenda" persistent width="500">
        <v-card>
          <v-overlay :value="dialogAwaitAgenda" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Aguarde montando a agenda...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAwaitDadosCliente" persistent width="500">
        <v-card>
          <v-overlay :value="dialogAwaitDadosCliente" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Cliente</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Aguarde salvando dados atualizados...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAwaitSaveAgenda" persistent width="500">
        <v-card>
          <v-overlay :value="dialogAwaitSaveAgenda" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Salvando a agenda...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAwaitDadosOS" persistent width="500">
        <v-card>
          <v-overlay :value="dialogAwaitDadosOS" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Aguarde</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Buscando dados da OS...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAwaitDetalhes" persistent width="500">
        <v-card>
          <v-overlay :value="dialogAwaitDetalhes" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Aguarde buscando detalhes do agendamento...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogSuccessSaveAgenda" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-text>{{ mensagem }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="limpaVariaveis">fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogSuccessalterAgenda" width="300" persistent>
        <v-card>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-text>Agenda alterada com sucesso!</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="limpaVariaveis">fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDesenvolve" width="300">
        <v-card>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-text>Em desenvolvimento</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogDesenvolve = false"
              >fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- fim coluna direita -->

    <v-snackbar
      v-model="snackbar_success"
      color="success"
      multi-line="multi-line"
      :timeout="timeSnackbarError"
      bottom="bottom"
    >
      <v-icon small color="#FFF" class="mr-3">mdi-check</v-icon>
      {{ sucesso }}
    </v-snackbar>
  </div>
</template>

<script>
import { calendarDateToDate, fusoInMili, hourParse } from "@/utils/dateParse";
import axios from "axios";
import { addMilliseconds, lightFormat, minutesToMilliseconds } from "date-fns";
import { mapState } from "vuex";

export default {
  data: () => ({
    rules: {
      obrigatorio: value => !!value || "Obrigatório",
      counter: value => value.length === 9 || `OS inválida`,
    },
    regra: false,
    dddCliente: null,
    DetLocalAtendimento: null,
    DetDdd: null,
    localAtendimento: null,
    itensAtendimento: [],
    noInDadosCompusorios: null,
    agendaAlterar: null,
    dialogAwaitDadosCliente: false,
    esperaBuscaDados: false,
    incluiAgenda: false,
    obrigatorioInformativo: false,
    dddTel: null,

    whatsAppTel: null,
    emailAtu: null,
    nomeClienteAutalizaTel: null,
    atualizei: false,
    idEventoAutlizaTel: null,
    doneAtualizaTel: false,
    loadingAtualizaTel: false,
    ddd: null,
    telefoneAtualizado: null,
    atualizaTel: false,
    codCliente: null,
    lojaCliente: null,
    newProf: null,
    awaitBanco: false,
    chamada: null,
    snackbar_success: false,
    sucesso: null,
    timeSnackbarError: 8000,
    firstMinute: "",
    loadingLeads: false,
    Lead: null,
    itensLeads: [],
    nomeLead: null,
    almoco: false,
    transferida: false,
    transfereHini: null,
    transfereHfim: null,
    dataTransfere: null,
    notaTransfere: null,
    dialogDesenvolve: false,
    idSalvo: null,
    dialogSuccessalterAgenda: false,
    idALteracao: null,
    dialogAltStatus: false,
    DetStatus: null,
    tipoCliente: "C",
    dialogTrocaProf: false,
    novoProfissional: null,
    status: "A - Agendamento",
    itensStatus: [
      "A - Agendamento",
      "S - Bloqueado",
      "C - Compareceu",
      "N - Não Compareceu",
    ],
    itensStatusInclusao: ["A - Agendamento", "S - Bloqueado", "C - Compareceu"],
    itensCanal: [],
    NumOs: null,
    dialogAwaitDetalhes: false,
    DetNumOs: null,
    dialogDetalhes: false,
    DetTitulo: null,
    DetTransfere: null,
    DetCliente: null,
    DetCanal: null,
    DetHoraIni: null,
    DetHoraFim: null,
    DetTel: null,
    DetTel2: null,
    DetDtAgenda: null,
    DetAssessor: null,
    DetobsAgenda: null,
    DetDtAlt: null,
    DetUserAlt: null,
    ZZLBANCO: [],
    cloneAgenda: [],
    dialogSuccessSaveAgenda: false,
    mensagem: null,
    dialogAwaitSaveAgenda: false,
    dialogAwaitDadosOS: false,
    dialogErrosAgenda: false,
    aError: [],
    obsAgenda: "",
    titulo: null,
    dadosCliente: [],
    aItensTitulo: [],
    telCliente: null,
    telCliente2: null,
    clienteForaBase: null,
    minutoOne: false,
    minutoAgendaFim: null,
    horaAgendaFim: null,
    loadingAssessor: true,
    dbAssesor: [],
    agendaAssessor: null,
    minutoAgendaIni: null,
    horaAgendaIni: null,
    horaInicioAlmoco: null,
    horaFimAlmoco: null,
    itemsHini: [],
    itemsHFim: [],
    itemsMini: [
      "00",
      "05",
      "10",
      "15",
      "20",
      "25",
      "30",
      "35",
      "40",
      "45",
      "50",
      "55",
    ],
    itemsMFim: [
      "00",
      "05",
      "10",
      "15",
      "20",
      "25",
      "30",
      "35",
      "40",
      "45",
      "50",
      "55",
    ],
    canal: null,
    cliente: null,
    loadingCliBase: true,
    loadingCanal: true,
    loadingItensTitulo: true,
    clienteBase: [],
    clienteNbase: false,
    clienteApac: false,
    dialogAgendar: false,
    headersTbHorasVagas: [
      { text: "Horário vago", value: "horaIni" },
      { text: "Agendar", value: "actions" },
    ],
    dialogVerHorarios: false,
    interval_count: null,
    first_time: "",
    interval_minutes: "",
    loadfiltro: false,
    dialogAwaitAgenda: false,
    dialogAwaitJob: false,
    eventsMonth: [],
    hoje: "",
    type: "category",
    filtrado: false,
    types: ["category", "week", "day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    loadTecnico: true,
    dataMes: "",
    dataTela: null,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    category: ["Development", "Meetings", "Slacking"],
    nomes: [],
    nameofdoctor: "",
    categories: [
      "Titulo",
      "Paciente",
      "Médico/Representante",
      "Telefone",
      "Notas",
    ],
    tipo: null,
    itemsTipo: [],
    paciente: null,
    itemsPaciente: [],
    assessor: null,
    itemsAssessor: [],
    telefone: null,
    itemsTelefone: [],
    obs: null,
    itemsObs: [],
    horarios: [],
    horarioDia: [],
    aHorasDia: [],
    filtroLegAgendado: false,
    filtroLegSemAgenda: false,
    filtroLegCompareceu: false,
    filtroLegNCompareceu: false,
    numberfilter: 0,
  }),
  computed: mapState(["user", "metadados", "vincularOsAgenda"]),
  methods: {
    fechaAtualizaTelWhats() {
      this.atualizaTel = false;
      this.ddd = null;
      this.nomeClienteAutalizaTel = null;
      this.telefoneAtualizado = null;
    },
    pegaNome(item) {
      if (!item) return;
      this.nomeLead = this.itensLeads.filter(e => e.value === item)[0].NOME;
      if (this.idALteracao) {
        this.DetCliente = this.nomeLead;
      }
    },
    async deleteAgenda() {
      const dataAtual = new Date();
      const anoAtual = dataAtual.getFullYear().toString();
      let mesAtual = (dataAtual.getMonth() + 1).toString();
      let diaAtual = dataAtual.getDate().toString();

      if (mesAtual.length < 2) mesAtual = "0" + mesAtual;

      if (diaAtual.length < 2) diaAtual = "0" + diaAtual;

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZZL_ID: this.idALteracao,
        ZZL_STATUS: "N",
        ZZL_DTALT: `${anoAtual}${mesAtual}${diaAtual}`,
        ZZL_USALT: this.user.name.substr(0, 20),
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/delAgenda`;

      this.awaitBanco = true;
      this.chamada = `Executando a exclusão, aguarde...`;
      await axios
        .put(url, dados)
        .then(res => {
          if (res.data.STATUS === "true") {
            this.snackbar_success = true;
            this.sucesso = `Agendamento ${this.idALteracao} excluído com sucesso!`;
            this.events = this.events.filter(e => e.id !== this.idALteracao);
          }
        })
        .catch(err => {
          console.log(err.response);
        });

      this.awaitBanco = false;
      this.dialogDetalhes = false;
      this.itensLeads = [];
    },
    novaAgenda(item) {
      console.log(this.horarioDia.length);
      if (this.horarioDia.length <= 0) {
        return;
      }

      const [hora, minuto] = item.time.split(":");
      const [ano, mes, dia] = item.date.split("-");

      this.horaAgendaIni = hora;
      this.firstMinute = minuto;
      this.dataTela = `${dia}/${mes}/${ano}`;
      this.status = "A - Agendamento";

      this.dialogAgendar = true;
      this.agendar(item.time);
    },
    async trocaProfissional() {
      console.log(this.idALteracao);
      console.log(this.novoProfissional);
    },
    async carregaDadosOS(numero) {
      if (!numero) return;
      let META = [];
      let dados = {};
      let campos = "";
      let splitCampo;
      let url = "";
      let result;

      this.dialogAwaitDadosOS = true;

      META = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD012");
      META.map(e => {
        if (e.ZZP_BROWSE.trim() !== "0" || e.ZZP_TIPO.trim() === "Y") {
          splitCampo = e.ZZP_CAMPO.trim().split(" ");

          if (e.ZZP_TIPO.trim() === "Y") {
            campos += `ISNULL(CONVERT(VARCHAR(2000), ${splitCampo[0]}), ' ') ${splitCampo[1]},`;
          } else {
            campos += `ISNULL(${splitCampo[0]}, ' ') ${splitCampo[1]},`;
          }
        }
      });

      if (campos.substr(-1) === `,`) {
        campos = campos.slice(0, -1);
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: campos,
        WHERE: ` UPPER(ZF1_ID) = '${numero.toUpperCase()}' `,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/getDadosOS`;

      await axios
        .post(url, dados)
        .then(res => {
          if (res.data.length > 0) {
            result = res.data[0];

            this.canal = `${result.COD_CANAL}-${result.DESCRI_CANAL}`.trim();
            this.cliente = `${result.CLIENTE}-${result.LOJA} - ${result.NOME}`.trim();
            this.telCliente = result.TELEFONE;
            this.telCliente2 = result.TELEFONE_2;
            this.agendaAssessor = `${result.INDICADOR} - ${result.NOME_INDICADOR}`;
          } else {
            this.cliente = "";
            this.telCliente = "";
            this.telCliente2 = "";
            this.agendaAssessor = "";
          }

          this.dialogAwaitDadosOS = false;
        })
        .catch(err => console.log("Erro: " + err));
    },
    filtraLegenda(tipo) {
      if (tipo === "A") {
        this.filtroLegAgendado = !this.filtroLegAgendado;
        this.filtroLegSemAgenda = false;
        this.filtroLegCompareceu = false;
        this.filtroLegNCompareceu = false;

        this.limpaFiltro();
      }

      if (tipo === "S") {
        this.filtroLegAgendado = false;
        this.filtroLegSemAgenda = !this.filtroLegSemAgenda;
        this.filtroLegCompareceu = false;
        this.filtroLegNCompareceu = false;

        this.limpaFiltro();
      }

      if (tipo === "C") {
        this.filtroLegAgendado = false;
        this.filtroLegSemAgenda = false;
        this.filtroLegCompareceu = !this.filtroLegCompareceu;
        this.filtroLegNCompareceu = false;

        this.limpaFiltro();
      }

      if (tipo === "N") {
        this.filtroLegAgendado = false;
        this.filtroLegSemAgenda = false;
        this.filtroLegCompareceu = false;
        this.filtroLegNCompareceu = !this.filtroLegNCompareceu;

        this.limpaFiltro();
      }

      if (
        this.filtroLegAgendado ||
        this.filtroLegSemAgenda ||
        this.filtroLegCompareceu ||
        this.filtroLegNCompareceu
      ) {
        this.cloneAgenda = this.events;
        let eventos = this.events;

        let data = this.dataMes.split("-");
        data = `${data[0]}${data[1]}${data[2]}`;

        eventos = eventos.filter(e => {
          if (this.type === "category") {
            if (
              e.status === tipo &&
              e.data === data &&
              e.category === "Titulo"
            ) {
              return e.id;
            }
          } else {
            if (e.status === tipo && e.data === data) {
              return e.id;
            }
          }
        });

        let filtro = [];

        eventos.forEach(e => {
          this.events.map(f => {
            if (f.id === e.id) {
              filtro.push(f);
            }
          });
        });

        this.numberfilter = eventos.length;

        this.events = filtro;
        this.filtrado = true;
      }
    },
    async transfereAgenda() {
      this.aError = [];
      let dataEscolhida = null;
      let hIni = null;
      let hFim = null;
      if (!this.dataTransfere) {
        this.aError.push({
          text: "Preencha a nova data para transferir.",
          icon: "mdi-send",
        });
      } else {
        dataEscolhida = this.dataTransfere.split("/");

        if (
          dataEscolhida.length <= 0 ||
          dataEscolhida.length < 3 ||
          dataEscolhida[2].length < 4
        ) {
          this.aError.push({
            text: "Preencha a data corretamente. Ex: dd/mm/AAAA",
            icon: "mdi-send",
          });
        }
      }

      if (!this.transfereHini) {
        this.aError.push({
          text: "Preencha a hora inicial para transferir",
          icon: "mdi-send",
        });
      } else {
        hIni = this.transfereHini.split(":");

        if (
          hIni.length <= 0 ||
          hIni.length < 2 ||
          hIni[0].length < 2 ||
          hIni[1].length < 2
        ) {
          this.aError.push({
            text: "Preencha a hora inicial corretamente. Ex: 08:00",
            icon: "mdi-send",
          });
        }
      }

      if (!this.transfereHfim) {
        this.aError.push({
          text: "Preencha a hora final para transferir",
          icon: "mdi-send",
        });
      } else {
        hFim = this.transfereHfim.split(":");

        if (
          hFim.length <= 0 ||
          hFim.length < 2 ||
          hFim[0].length < 2 ||
          hFim[1].length < 2
        ) {
          this.aError.push({
            text: "Preencha a hora final corretamente. Ex: 08:00",
            icon: "mdi-send",
          });
        }
      }

      if (!this.notaTransfere) {
        this.aError.push({
          text: "Preencha a nota para transferir.",
          icon: "mdi-send",
        });
      }

      if (this.aError.length > 0) {
        this.dialogErrosAgenda = true;
        return;
      }

      const cliente = this.DetCliente ? this.DetCliente.split("-") : "";

      let cli = null;
      cli = parseInt(cliente[0]);

      let codCli = null;
      let lojaCli = null;

      if (isNaN(cli)) {
        cli = cliente[0];
      } else {
        codCli = cliente[0];
        lojaCli = cliente[1];
        this.cliente = this.DetCliente ? this.DetCliente : "";
        cli = null;
      }

      let assessor = [];
      if (this.DetCanal === "000001") {
        assessor = this.DetAssessor.split("-");
      } else {
        assessor[0] = "";
      }

      this.agendaAssessor = this.DetAssessor;

      this.notaTransfere = this.notaTransfere.replaceAll("\n", " ");
      this.notaTransfere = this.removeAcento(this.notaTransfere);

      const dataAtual = new Date();
      const anoAtual = dataAtual.getFullYear().toString();
      let mesAtual = (dataAtual.getMonth() + 1).toString();
      let diaAtual = dataAtual.getDate().toString();

      if (mesAtual.length < 2) mesAtual = "0" + mesAtual;

      if (diaAtual.length < 2) diaAtual = "0" + diaAtual;

      console.log(this.DetCanal.indexOf("-"));
      let canal = "";

      if (this.DetCanal.indexOf("-") >= 1) {
        canal = this.DetCanal.split("-")[0];
      } else {
        canal = this.DetCanal.substr(0, 6);
      }

      //console.log(canal)

      let profissional = ``;

      if (this.newProf) {
        profissional = this.newProf.substring(0, 6);
      } else {
        profissional = this.nameofdoctor.substring(0, 6);
      }

      let ZZL = {
        ZZL_TITULO: this.DetTitulo,
        ZZL_DTINI: `${dataEscolhida[2]}${dataEscolhida[1]}${dataEscolhida[0]}`,
        ZZL_DTFIN: `${dataEscolhida[2]}${dataEscolhida[1]}${dataEscolhida[0]}`,
        ZZL_HRINI: `${hIni[0]}:${hIni[1]}`,
        ZZL_HRFIN: `${hFim[0]}:${hFim[1]}`,
        ZZL_NOTAS: this.notaTransfere,
        ZZL_PESSOA: profissional,
        ZZL_CLIENT: codCli,
        ZZL_LOJA: lojaCli,
        ZZL_STATUS: "A",
        ZZL_NOMECL: cli,
        ZZL_NUMOS: this.DetNumOs,
        ZZL_VEND1: assessor[0],
        ZZL_DTALT: `${anoAtual}${mesAtual}${diaAtual}`,
        ZZL_TEL1: this.DetTel ? this.DetTel.trim() : "",
        ZZL_TEL2: this.DetTel2 ? this.DetTel2.trim() : "",
        ZZL_USALT: this.user.name.substr(0, 20),
        ZZL_IDPAI: this.idALteracao,
        ZZL_CANAL: canal,
        ZZL_IDLEAD: this.Lead,
        ZZL_LOCAL: this.DetLocalAtendimento
          ? this.DetLocalAtendimento.substring(0, 6)
          : "",
        ZZL_DDD: this.ddd,
      };

      if (this.user.HrEspecial) {
        ZZL.ZZL_HRESP = "S";
      } else {
        ZZL.ZZL_HRESP = "N";
      }

      // console.log(ZZL);

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZZL,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/saveAgenda`;

      let OK = false;

      this.dialogAwaitSaveAgenda = true;
      await axios
        .post(url, dados)
        .then(res => {
          if (res.data.STATUS === "true") {
            OK = true;
            this.ZZLBANCO = ZZL;
            this.ZZLBANCO.titulo = ZZL.ZZL_TITULO;
            this.ZZLBANCO.cliente = this.DetCliente;
            this.ZZLBANCO.canal = this.DetCanal;
            this.ZZLBANCO.telefone = `${ZZL.ZZL_TEL1} / ${ZZL.ZZL_TEL2}`;
            this.ZZLBANCO.assessor = this.DetAssessor;
            this.ZZLBANCO.dtAlt = ZZL.ZZL_DTALT;
            this.ZZLBANCO.usrAlt = ZZL.ZZL_USALT;
            this.ZZLBANCO.nomecl = ZZL.ZZL_NOMECL;
            this.ZZLBANCO.numOs = ZZL.ZZL_NUMOS;
            this.ZZLBANCO.horaIni = `${hIni[0]}:${hIni[1]}`;
            this.ZZLBANCO.horaFim = `${hFim[0]}:${hFim[1]}`;
            this.ZZLBANCO.data = ZZL.ZZL_DTINI;
            this.ZZLBANCO.notas = ZZL.ZZL_NOTAS;
            this.ZZLBANCO.transferida = false;
            this.ZZLBANCO.canal = this.DetCanal;
            this.ZZLBANCO.idLead = this.Lead;
            this.ZZLBANCO.lead = this.Lead;
            this.ZZLBANCO.localAtendimento = this.DetLocalAtendimento
              ? this.DetLocalAtendimento.substring(0, 6)
              : "";
            this.idSalvo = res.data.CODIGO;
            this.mensagem = `Agenda transferida com o id ${res.data.CODIGO} na data: ${dataEscolhida[0]}/${dataEscolhida[1]}/${dataEscolhida[2]}`;
          } else {
            const err = res.data.ERRO.split("\n");
            this.aError.push({ text: `${err[5]}`, icon: "mdi-send" });
            this.dialogAwaitSaveAgenda = false;
            this.dialogErrosAgenda = true;
            return;
          }
        })
        .catch(err => console.log(err));

      if (OK) {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZZL_ID: this.idALteracao,
          ZZL_STATUS: "N",
          ZZL_DTALT: `${anoAtual}${mesAtual}${diaAtual}`,
          ZZL_USALT: this.user.name.substr(0, 20),
          ZZL_TRANSF: "S",
        };

        url = `${process.env.VUE_APP_BASE_API_URL}/delAgenda`;

        let color = ``;

        await axios
          .put(url, dados)
          // .then((res) => {
          //   if (res.data.STATUS === "true") {
          //     this.DetStatus = "N - Não compareceu";
          //     color = "red darken-2";
          //     this.status = "N - Não Compareceu";
          //     this.DetDtAlt = `${diaAtual}/${mesAtual}/${anoAtual}`;
          //     this.DetUserAlt = this.user.name.substr(0, 20);
          //     this.dialogAltStatus = false;
          //   }
          // })
          .catch(err => console.log(err));

        this.events.map(e => {
          if (e.id === this.idALteracao) {
            e.status = this.status.substr(0, 1);
            e.transferida = true;
            e.color = color;
          }
        });

        this.events = this.events.filter(e => e.id !== this.idALteracao);

        this.eventsMonth = [];

        url = `${process.env.VUE_APP_BASE_API_URL}/agenda`;

        dados = {
          EMPRESA: `01`,
          FILIAL: `0101`,
          FIELDS: `DISTINCT(ZZL_DTINI)`,
          WHERE: `ZZL_PESSOA='${this.nameofdoctor.substr(0, 6)}'`,
        };

        let marcacao = [];

        await axios
          .post(url, dados)
          .then(res => {
            marcacao = res.data;
          })
          .catch(err => {
            console.log(err);
          });

        marcacao.map(e => {
          if (e.ZZL_DTINI.trim().length > 0) {
            let start = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
              4,
              2,
            )}-${e.ZZL_DTINI.substr(6, 2)}`;
            let end = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
              4,
              2,
            )}-${e.ZZL_DTINI.substr(6, 2)}`;
            this.eventsMonth.push({
              name: "Agenda",
              start: start,
              end: end,
            });
          }
        });

        this.dialogAwaitSaveAgenda = false;
        this.dialogSuccessSaveAgenda = true;
        this.newProf = null;
      }
    },
    async salvaAlteracao() {
      const dataAtual = new Date();
      const anoAtual = dataAtual.getFullYear().toString();
      let mesAtual = (dataAtual.getMonth() + 1).toString();
      let diaAtual = dataAtual.getDate().toString();

      if (mesAtual.length < 2) mesAtual = "0" + mesAtual;

      if (diaAtual.length < 2) diaAtual = "0" + diaAtual;

      if (this.DetobsAgenda) {
        this.DetobsAgenda = this.DetobsAgenda.replaceAll("\n", " ");
        this.DetobsAgenda = this.removeAcento(this.DetobsAgenda);
      }

      console.log(this.Lead);
      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZZL_ID: this.idALteracao,
        ZZL_STATUS: this.status.substr(0, 1),
        ZZL_DTALT: `${anoAtual}${mesAtual}${diaAtual}`,
        ZZL_USALT: this.user.name.substr(0, 20),
        ZZL_NOTAS: this.notaTransfere,
        ZZL_IDLEAD: this.Lead,
      };
      if (this.Lead && this.Lead.trim() !== "") {
        console.log("entrei");
        dados.ZZL_NOMECL = this.DetCliente.trim();
      }

      console.log(dados);

      if (this.user.HrEspecial) {
        dados.ZZL_HRESP = "S";
      } else {
        dados.ZZL_HRESP = "N";
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/agenda`;

      let color = ``;

      this.dialogAwaitSaveAgenda = true;
      await axios
        .put(url, dados)
        .then(res => {
          if (res.data.STATUS === "true") {
            if (this.status.substr(0, 1) === "A") {
              this.DetStatus = "A - Agendamento";
              color = "cyan darken-1";
            }
            if (this.status.substr(0, 1) === "S") {
              this.DetStatus = "S - Bloqueado";
              color = "grey darken-3";
            }
            if (this.status.substr(0, 1) === "C") {
              this.DetStatus = "C - Compareceu";
              color = "green darken-2";
            }
            if (this.status.substr(0, 1) === "N") {
              this.DetStatus = "N - Não compareceu";
              color = "red darken-2";
            }
            this.DetDtAlt = `${anoAtual}${mesAtual}${diaAtual}`;
            this.DetUserAlt = this.user.name.substr(0, 20);
            this.dialogAwaitSaveAgenda = false;
            this.dialogSuccessalterAgenda = true;
            this.dialogAltStatus = false;
          } else {
            const err = res.data.ERRO.split("\n");
            this.aError.push({ text: `${err[5]}`, icon: "mdi-send" });
            this.dialogAwaitSaveAgenda = false;
            this.dialogErrosAgenda = true;
            return;
          }
        })
        .catch(err => console.log(err));

      this.events.map(e => {
        if (this.type === "category") {
          if (e.category === "Notas" && e.id === this.idALteracao) {
            e.status = this.status.substr(0, 1);
            e.color = color;
            e.name = this.notaTransfere;
            e.cliente = this.DetCliente;
            e.idLead = this.Lead;
          } else {
            if (e.id === this.idALteracao) {
              e.status = this.status.substr(0, 1);
              e.color = color;
              e.notas = this.notaTransfere;
              e.cliente = this.DetCliente;
              e.idLead = this.Lead;
            }
          }
        } else {
          if (e.id === this.idALteracao) {
            e.status = this.status.substr(0, 1);
            e.color = color;
            e.notas = this.notaTransfere;
            e.cliente = this.DetCliente;
            e.idLead = this.Lead;
          }
        }
      });
      this.itensLeads = [];
    },
    async verdados1(event) {
      console.log("Evento aqui: ", event);
      this.dialogAwaitDetalhes = true;
      this.dialogDetalhes = false;

      this.transferida = event.transferida;

      if (event.almoco) {
        this.almoco = event.almoco;
      }

      console.log("evento da agenda", event);

      const telefone = event.telefone.split("/");
      this.DetTitulo = event.titulo;
      this.DetCliente = event.cliente ? event.cliente : event.nomecl;
      this.DetCanal = event.canal;
      this.DetTel = telefone[0].trim();
      this.DetTel2 = telefone[1] ? telefone[1].trim() : null;
      this.DetHoraIni = event.horaIni;
      this.DetHoraFim = event.horaFim;
      this.DetAssessor = event.assessor;
      this.agendaAssessor = event.assessor;
      this.DetobsAgenda = event.notas;
      this.DetNumOs = event.numOs;
      this.DetDtAlt = `${event.dtAlt.substr(6, 2)}/${event.dtAlt.substr(
        4,
        2,
      )}/${event.dtAlt.substr(0, 4)}`;
      this.DetUserAlt = event.usrAlt;
      this.DetDtAgenda = `${event.data.substr(6, 2)}/${event.data.substr(
        4,
        2,
      )}/${event.data.substr(0, 4)}`;

      await this.buscaLocaisAtendimento();

      console.log(this.itensAtendimento);

      const localAtende = this.itensAtendimento.filter(
        e => e.ZZ2_CODIGO === event.localAtendimento,
      );

      if (localAtende.length > 0) {
        this.DetLocalAtendimento = `${event.localAtendimento}-${localAtende[0].ZZ2_LOCAL}`;
      } else {
        this.DetLocalAtendimento = "";
      }

      this.DetDdd = event.ddd;

      if (event.status === "A") {
        this.status = "A - Agendamento";
      }
      if (event.status === "S") {
        this.status = "S - Bloqueado";
      }
      if (event.status === "C") {
        this.status = "C - Compareceu";
      }
      if (event.status === "N") {
        this.status = "N - Não Compareceu";
      }
      this.idALteracao = event.id;

      this.Lead = event.idLead;

      this.dialogDetalhes = true;

      this.transfereHini = event.horaIni;
      this.transfereHfim = event.horaFim;
      this.notaTransfere = event.notas;

      this.dialogAwaitDetalhes = false;
    },
    async verDados(event) {
      const clienteBase = event.cliente.split("-");

      if (
        clienteBase[0].trim().length === 6 &&
        !this.noInDadosCompusorios.data[0].X6_CONTEUD.includes(
          this.nameofdoctor.substring(0, 6),
        )
      ) {
        if (!isNaN(parseFloat(clienteBase[0].substr(0, 4)))) {
          await this.verificaDadosCliente(clienteBase[0].trim(), "A", event);
        } else {
          this.verdados1(event);
        }
      } else {
        this.verdados1(event);
      }
    },
    async atualizaTelEnviaWhats() {
      let dadosCliente = [];
      if (this.incluiAgenda) {
        dadosCliente = this.cliente.split("-");
      } else {
        dadosCliente = this.agendaAlterar.cliente.split("-");
      }

      if (this.atualizei) {
        const dados = {
          A1_COD: dadosCliente[0],
          A1_LOJA: dadosCliente[1],
          A1_DDD: this.dddTel,
          A1_TEL: this.telefone,
          A1_ZWHATSA: this.whatsAppTel,
          A1_EMAIL: this.emailAtu,
        };

        this.dialogAwaitDadosCliente = true;

        await axios
          .post(
            `${process.env.VUE_APP_BASE_API_URL}/atulizaDadosClienteBase`,
            dados,
          )
          .catch(err => console.log(err));

        const data = new Date();
        const ano = data.getFullYear().toString();
        let mes = (data.getMonth() + 1).toString();
        let dia = data.getDate().toString();

        if (mes.length < 2) mes = "0" + mes;

        if (dia.length < 2) dia = "0" + dia;

        const hora = data.getHours();
        let minuto = data.getMinutes();
        if (minuto < 10) {
          minuto = `0${minuto}`;
        }

        const SZ4 = {
          Z4_DATA: `${ano}${mes}${dia}`,
          Z4_HORA: `${hora}:${minuto}`,
          Z4_RESP1: this.user.id,
          Z4_NOME: this.user.name,
          Z4_ALIAS: "SA1",
          Z4_CLIENTE: dadosCliente[0].trim(),
          Z4_LOJA: dadosCliente[1].trim(),
          Z4_NOMECLI: dadosCliente[2].trim(),
          Z4_ID: "",
          Z4_TEXTO:
            "Atualizacao de telefone,WhatsApp e E-mail na inclusao da agenda.",
          Z4_STATUS: "03",
          Z4_TIPO: "20",
        };

        const dadosUrl = {
          EMPRESA: "01",
          FILIAL: "0101",
          SZ4,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/saveFollow`;

        await axios.post(url, dadosUrl);

        this.dialogAwaitDadosCliente = false;

        this.snackbar_success = true;
        this.sucesso = `Dados do cliente ${dadosCliente[2]}, atualizados com sucesso!`;
      }

      this.obrigatorioInformativo = false;
      if (!this.incluiAgenda) {
        this.verdados1(this.agendaAlterar);
      }
      this.incluiAgenda = false;
      this.atualizei = false;
    },
    limpaFiltro() {
      if (this.cloneAgenda.length > 0) {
        this.events = this.cloneAgenda;
      }
      this.itemsTipo = [];
      this.tipo = ``;
      this.paciente = ``;
      this.assessor = ``;
      this.telefone = ``;
      this.obs = ``;
      this.cloneAgenda = [];
    },
    async agendaPlus() {
      this.dialogAgendar = true;

      const [ano, mes, dia] = this.dataMes.split("-");
      this.dataTela = `${dia}/${mes}/${ano}`;
      this.status = "A - Agendamento";
      await this.agendar();

      if (this.vincularOsAgenda.job) {
        this.NumOs = this.vincularOsAgenda.os.ZF1_ID;
        this.cliente = this.clienteBase[0];
        this.dadosCli();
      }
      this.$store.commit("SetVincOsAgenda", { job: false, os: null });
      this.dialogAwaitJob = false;
    },
    limpaVariaveis() {
      this.dialogSuccessSaveAgenda = false;
      this.dialogAgendar = false;
      this.dialogVerHorarios = false;
      this.dialogDetalhes = false;
      this.dialogDetalhes = false;
      this.dialogSuccessalterAgenda = false;
      this.itensLeads = [];

      this.tipoCliente = "C";
      this.nomeLead = null;
      this.Lead = null;

      if (this.idSalvo) {
        const allDay = this.rnd(0, 3) === 0;

        let color = ``;

        if (this.type === "day" || this.type === "week") {
          if (
            this.ZZLBANCO.ZZL_DTINI.trim().length > 0 &&
            this.ZZLBANCO.ZZL_DTFIN.trim().length > 0 &&
            this.ZZLBANCO.ZZL_HRINI.trim().length === 5 &&
            this.ZZLBANCO.ZZL_HRFIN.trim().length === 5
          ) {
            let start = `${this.ZZLBANCO.ZZL_DTINI.substr(
              0,
              4,
            )}-${this.ZZLBANCO.ZZL_DTINI.substr(
              4,
              2,
            )}-${this.ZZLBANCO.ZZL_DTINI.substr(6, 2)} ${
              this.ZZLBANCO.ZZL_HRINI
            }`;
            let end = `${this.ZZLBANCO.ZZL_DTFIN.substr(
              0,
              4,
            )}-${this.ZZLBANCO.ZZL_DTFIN.substr(
              4,
              2,
            )}-${this.ZZLBANCO.ZZL_DTFIN.substr(6, 2)} ${
              this.ZZLBANCO.ZZL_HRFIN
            }`;
            let name = ``;
            if (this.cliente) {
              name = this.cliente;
            } else {
              name = this.ZZLBANCO.ZZL_NOMECL;
            }
            let tel = ``;
            if (this.ZZLBANCO.ZZL_TEL1.trim().length > 0) {
              tel = `${this.ZZLBANCO.ZZL_TEL1} / ${this.ZZLBANCO.ZZL_TEL2}`;
            }
            if (this.ZZLBANCO.ZZL_STATUS === "A") {
              color = "cyan darken-1";
            }
            if (this.ZZLBANCO.ZZL_STATUS === "S") {
              color = "grey darken-1";
            }
            if (this.ZZLBANCO.ZZL_STATUS === "C") {
              color = "green darken-2";
            }
            if (this.ZZLBANCO.ZZL_STATUS === "N") {
              color = "red darken-2";
            }
            const dadosAssessor = this.agendaAssessor.split("-");

            this.events.push({
              id: this.idSalvo,
              name: `Título:${this.ZZLBANCO.ZZL_TITULO} | Paciente:${name} | Médico/Representante:${dadosAssessor[1]} | Telefone: ${tel}`,
              start: start,
              end: end,
              timed: !allDay,
              color: color,
              status: this.ZZLBANCO.ZZL_STATUS,
              titulo: this.ZZLBANCO.titulo,
              cliente: this.ZZLBANCO.cliente,
              canal: this.ZZLBANCO.canal,
              telefone: this.ZZLBANCO.telefone,
              assessor: this.ZZLBANCO.assessor,
              dtAlt: this.ZZLBANCO.dtAlt,
              usrAlt: this.ZZLBANCO.usrAlt,
              // nomecl: this.ZZLBANCO.nomecl,
              numOs: this.ZZLBANCO.ZZL_NUMOS,
              horaIni: this.ZZLBANCO.horaIni,
              horaFim: this.ZZLBANCO.horaFim,
              data: this.ZZLBANCO.data,
              notas: this.ZZLBANCO.notas,
              nomecl: this.ZZLBANCO.nomecl,
              transferida: this.ZZLBANCO.transferida ? true : false,
            });
          }
        }

        if (this.type === "category") {
          this.itemsTipo = [];
          this.tipo = "";
          if (
            this.ZZLBANCO.ZZL_DTINI.trim().length > 0 &&
            this.ZZLBANCO.ZZL_DTFIN.trim().length > 0 &&
            this.ZZLBANCO.ZZL_HRINI.trim().length === 5 &&
            this.ZZLBANCO.ZZL_HRFIN.trim().length === 5
          ) {
            let start = `${this.ZZLBANCO.ZZL_DTINI.substr(
              0,
              4,
            )}-${this.ZZLBANCO.ZZL_DTINI.substr(
              4,
              2,
            )}-${this.ZZLBANCO.ZZL_DTINI.substr(6, 2)} ${
              this.ZZLBANCO.ZZL_HRINI
            }`;
            let end = `${this.ZZLBANCO.ZZL_DTFIN.substr(
              0,
              4,
            )}-${this.ZZLBANCO.ZZL_DTFIN.substr(
              4,
              2,
            )}-${this.ZZLBANCO.ZZL_DTFIN.substr(6, 2)} ${
              this.ZZLBANCO.ZZL_HRFIN
            }`;

            this.categories.forEach(c => {
              let name = ``;
              if (c === "Titulo") {
                name = this.ZZLBANCO.ZZL_TITULO;
              }
              if (c === "Paciente") {
                if (this.cliente) {
                  name = this.cliente;
                } else {
                  name = this.ZZLBANCO.ZZL_NOMECL;
                }
              }
              const dadosAssessor = this.agendaAssesor
                ? this.agendaAssessor.split("-")
                : null;
              if (c === "Médico/Representante") {
                if (dadosAssessor !== null) {
                  name = dadosAssessor[1];
                }
              }
              if (c === "Telefone") {
                if (this.ZZLBANCO.ZZL_TEL1.trim().length > 0) {
                  name = `${this.ZZLBANCO.ZZL_TEL1} / ${this.ZZLBANCO.ZZL_TEL2}`;
                }
              }
              if (c === "Notas") {
                name = this.ZZLBANCO.ZZL_NOTAS;
              }
              if (this.ZZLBANCO.ZZL_STATUS === "A") {
                color = "cyan darken-1";
              }
              if (this.ZZLBANCO.ZZL_STATUS === "S") {
                color = "grey darken-1";
              }
              if (this.ZZLBANCO.ZZL_STATUS === "C") {
                color = "green darken-2";
              }
              if (this.ZZLBANCO.ZZL_STATUS === "N") {
                color = "red darken-2";
              }

              this.events.push({
                id: this.idSalvo,
                name: name,
                start: start,
                end: end,
                timed: !allDay,
                category: c,
                color: color,
                status: this.ZZLBANCO.ZZL_STATUS,
                titulo: this.ZZLBANCO.titulo,
                cliente: this.ZZLBANCO.cliente,
                canal: this.ZZLBANCO.canal,
                telefone: this.ZZLBANCO.telefone,
                assessor: this.ZZLBANCO.assessor,
                dtAlt: this.ZZLBANCO.dtAlt,
                usrAlt: this.ZZLBANCO.usrAlt,
                nomecl: this.ZZLBANCO.nomecl,
                numOs: this.ZZLBANCO.ZZL_NUMOS,
                horaIni: this.ZZLBANCO.horaIni,
                horaFim: this.ZZLBANCO.horaFim,
                data: this.ZZLBANCO.data,
                notas: this.ZZLBANCO.notas,
                idLead: this.ZZLBANCO.idLead,
              });
            });
          }
        }
      }

      this.ZZLBANCO = [];
      this.titulo = null;
      this.cliente = null;
      this.canal = null;
      this.clienteForaBase = null;
      this.clienteNbase = false;
      this.horaAgendaIni = null;
      this.horaAgendaFim = null;
      this.minutoAgendaFim = null;
      this.minutoAgendaIni = null;
      this.telCliente = null;
      this.telCliente2 = null;
      this.agendaAssessor = null;
      this.obsAgenda = null;
      this.NumOs = null;
      this.transfereHini = null;
      this.dataTransfere = null;
      this.transfereHfim = null;
      this.notaTransfere = null;
      this.transferida = false;
      this.idSalvo = null;
      this.transferida = false;
      this.almoco = false;
    },
    async salvaAgenda() {
      this.ZZLBANCO = [];
      this.regra = true;
      this.dialogErrosAgenda = false;
      this.aError = [];
      if (!this.titulo || this.titulo.trim().length <= 0) {
        this.aError.push({
          text: "Preencha o título para o agendamento!",
          icon: "mdi-send",
        });
      }

      if (
        !["000001", "000029", "000131", "000135", "N0A001"].includes(
          this.nameofdoctor.substring(0, 6),
        )
      ) {
        if (
          !this.localAtendimento ||
          this.localAtendimento.trim().length <= 0
        ) {
          this.aError.push({
            text: "Preencha o local de atendimento!",
            icon: "mdi-send",
          });
        }
      }

      if (this.tipoCliente === "C") {
        if (this.clienteNbase) {
          if (!this.clienteForaBase || this.clienteForaBase.trim() == "") {
            this.aError.push({
              text: "Preencha o nome do paciente/cliente.",
              icon: "mdi-send",
            });
          }
        } else {
          if (!this.cliente) {
            this.aError.push({
              text: "Escolha o cliente para o agendamento!",
              icon: "mdi-send",
            });
          }
        }
      } else {
        console.log(this.Lead);
        if (this.nomeLead.trim() === "") {
          this.aError.push({
            text: "Digite o nome do Lead para o agendamento!",
            icon: "mdi-send",
          });
        }
      }
      if (
        !this.horaAgendaIni ||
        !this.minutoAgendaIni ||
        !this.horaAgendaFim ||
        !this.minutoAgendaFim
      ) {
        this.aError.push({
          text: "Digite o horário inicial e final do agendamento!",
          icon: "mdi-send",
        });
      }
      if (!this.telCliente || this.telCliente.trim() === "") {
        this.aError.push({
          text: "Preencha o telefone!",
          icon: "mdi-send",
        });
      }

      if (this.titulo && this.titulo.substr(0, 2) == "01") {
        if (!this.canal || this.canal.trim() == "") {
          this.aError.push({
            text: `O canal é obrigatório quando o tipo de agendamento for ${this.titulo.substr(
              3,
              30,
            )}.`,
            icon: "mdi-send",
          });
        }
      }

      if (!this.agendaAssessor) {
        if (this.canal) {
          if (this.canal.substr(0, 6) == "000001") {
            this.aError.push({
              text: "Escolha o médico/Representante para o agendamento!",
              icon: "mdi-send",
            });
          }
        }
      }

      if (!this.obsAgenda) {
        this.aError.push({
          text: "Digite a nota para o agendamento.",
          icon: "mdi-send",
        });
      }

      if (this.aError.length > 0) {
        this.dialogErrosAgenda = true;
        return;
      }

      const data = this.dataTela.split("/");

      if (this.obsAgenda) {
        this.obsAgenda = this.obsAgenda.replaceAll("\n", " ");
        this.obsAgenda = this.removeAcento(this.obsAgenda);
      }

      let cliente = null;
      let loja = null;
      let clienteBaseS = null;
      let clienteBase = null;
      let cliZZLbanco = null;

      if (this.tipoCliente === "C") {
        if (this.clienteNbase) {
          cliente = this.clienteForaBase;
          cliZZLbanco = cliente;
        } else {
          clienteBaseS = this.cliente.split("-");
          clienteBase = clienteBaseS[0];
          loja = clienteBaseS[1].substr(0, 2);
          cliZZLbanco = this.cliente;
        }
      } else {
        cliente = this.nomeLead;
      }

      // console.log(clienteBase)
      // console.log(loja)

      // console.log(data)
      // console.log(cliZZLbanco)
      // console.log(Id_Lead)
      // console.log(this.nomeLead)

      const dataAtual = new Date();
      const anoAtual = dataAtual.getFullYear().toString();
      let mesAtual = (dataAtual.getMonth() + 1).toString();
      let diaAtual = dataAtual.getDate().toString();

      if (mesAtual.length < 2) mesAtual = "0" + mesAtual;

      if (diaAtual.length < 2) diaAtual = "0" + diaAtual;

      let ZZL = {
        ZZL_TITULO: this.removeAcento(this.titulo.substr(3, 80)),
        ZZL_DTINI: `${data[2]}${data[1]}${data[0]}`,
        ZZL_DTFIN: `${data[2]}${data[1]}${data[0]}`,
        ZZL_HRINI: `${this.horaAgendaIni}:${this.minutoAgendaIni}`,
        ZZL_HRFIN: `${this.horaAgendaFim}:${this.minutoAgendaFim}`,
        ZZL_NOTAS: this.obsAgenda ? this.obsAgenda : null,
        ZZL_PESSOA: this.nameofdoctor.substring(0, 6),
        ZZL_CLIENT: clienteBase,
        ZZL_LOJA: loja,
        ZZL_STATUS: this.status.substr(0, 1),
        ZZL_NOMECL: this.nomeLead ? this.nomeLead : cliente,
        ZZL_NUMOS: this.NumOs,
        ZZL_CANAL: this.canal ? this.canal.substr(0, 6) : "",
        ZZL_VEND1: this.agendaAssessor ? this.agendaAssessor.substr(0, 6) : "",
        ZZL_DTALT: `${anoAtual}${mesAtual}${diaAtual}`,
        ZZL_TEL1: this.telCliente ? this.telCliente.trim() : this.DetTel,
        ZZL_TEL2: this.telCliente2 ? this.telCliente2.trim() : this.DetTel2,
        ZZL_USALT: this.user.name.substr(0, 20),
        ZZL_IDLEAD: this.Lead,
        ZZL_HRESP: this.user.HrEspecial ? "S" : "N",
        ZZL_LOCAL: this.localAtendimento,
        ZZL_DDD: this.dddCliente ? this.dddCliente : "",
      };

      if (this.user.HrEspecial) {
        ZZL.ZZL_HRESP = "S";
      } else {
        ZZL.ZZL_HRESP = "N";
      }

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZZL,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/saveAgenda`;

      this.dialogAwaitSaveAgenda = true;

      await axios
        .post(url, dados)
        .then(res => {
          if (res.data.STATUS === "true") {
            this.ZZLBANCO = ZZL;
            this.ZZLBANCO.titulo = ZZL.ZZL_TITULO;
            this.ZZLBANCO.cliente = cliZZLbanco;
            this.ZZLBANCO.canal = ZZL.ZZL_CANAL;
            this.ZZLBANCO.telefone = ZZL.ZZL_TEL1;
            this.ZZLBANCO.telefone2 = ZZL.ZZL_TEL2;
            this.ZZLBANCO.assessor = this.agendaAssessor;
            this.ZZLBANCO.dtAlt = ZZL.ZZL_DTALT;
            this.ZZLBANCO.usrAlt = ZZL.ZZL_USALT;
            this.ZZLBANCO.numOs = ZZL.ZZL_NUMOS;
            this.ZZLBANCO.idLead = ZZL.ZZL_IDLEAD;
            this.ZZLBANCO.horaIni = `${this.horaAgendaIni}:${this.minutoAgendaIni}`;
            this.ZZLBANCO.horaFim = `${this.horaAgendaFim}:${this.minutoAgendaFim}`;
            this.ZZLBANCO.data = ZZL.ZZL_DTINI;
            this.ZZLBANCO.notas = ZZL.ZZL_NOTAS;
            this.ZZLBANCO.nomecl = ZZL.ZZL_NOMECL;
            this.ZZLBANCO.transferida = false;
            (this.ZZLBANCO.localAtendimento = ZZL.ZZL_LOCAL),
              (this.ZZLBANCO.ddd = ZZL.ZZL_DDD);

            this.idSalvo = res.data.CODIGO;
            this.mensagem = `Agenda salva com o id ${res.data.CODIGO}`;
            this.dialogAwaitSaveAgenda = false;
            this.dialogSuccessSaveAgenda = true;
          } else {
            const err = res.data.ERRO.split("\n");
            this.aError.push({ text: `${err[5]}`, icon: "mdi-send" });
            this.dialogAwaitSaveAgenda = false;
            this.dialogErrosAgenda = true;
            return;
          }
        })
        .catch(err => console.log(err));
      this.itensLeads = [];
    },
    //Função para remover caracteres especiais na Notas
    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");
      return text;
    },
    async buscaLocaisAtendimento() {
      this.itensAtendimento = [];
      const dados = {
        EMPRESA: "0101",
        FILIAL: "01",
        FIELDS: "ZZ2_CODIGO,ZZ2_LOCAL",
        WHERE: `ZZ2_ATIVO='S'`,
        TABELA: "ZZ2",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.itensAtendimento = res.data;
        })
        .catch(err => console.log(err));
    },
    async agendar(horaEscolhida, horaLimite) {
      this.buscaAssessor();
      this.carregarHoraVaga();
      this.buscaLocaisAtendimento();

      if (horaEscolhida) {
        const dataEscolhida = calendarDateToDate(this.dataMes);
        const cincoMin = minutesToMilliseconds(5);
        const horarioInicial = addMilliseconds(
          dataEscolhida,
          hourParse(horaEscolhida) - (hourParse(horaEscolhida) % cincoMin),
        );

        const horarioFinal = horaLimite
          ? addMilliseconds(dataEscolhida, hourParse(horaLimite) + cincoMin)
          : addMilliseconds(horarioInicial, cincoMin * 3);

        this.horaAgendaIni = lightFormat(horarioInicial, "HH");
        this.minutoAgendaIni = lightFormat(horarioInicial, "mm");
        this.horaAgendaFim = lightFormat(horarioFinal, "HH");
        this.minutoAgendaFim = lightFormat(horarioFinal, "mm");

        this.itemsHini = [
          ...new Set(
            this.aHorasDia.map(e => {
              return e.horaIni.substring(0, 2);
            }),
          ),
        ];
        this.itemsHFim = [
          ...new Set(
            this.aHorasDia.map(e => {
              return e.horaIni.substring(0, 2);
            }),
          ),
        ];
      } else {
        const horaini = parseInt(this.horarioDia[0].horaIni.substring(0, 2));
        const horafim = parseInt(this.horarioDia[0].horaFim.substring(0, 2));

        let horas = [];

        for (let i = horaini; i <= horafim; i++) {
          horas.push(`${i}`.padStart(2, "0"));
        }

        this.itemsHini = horas;
        this.itemsHFim = horas;
      }

      let dados = {};
      let url = "";

      //////////////////////////////////////////
      this.loadingItensTitulo = true;
      this.dialogAgendar = true;
      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZZT_COD,ZZT_DESCRI",
        WHERE: `ZZT_FILIAL = '    ' ORDER BY ZZT_COD`,
        TABELA: `ZZT`,
      };

      this.cliente = null;

      url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.aItensTitulo = res.data.map(e => {
            return `${e.ZZT_COD}-${e.ZZT_DESCRI}`;
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      this.loadingItensTitulo = false;
      //////////////////////////////////////////

      this.loadingCliBase = true;
      dados = {
        EMPRESA: "0101",
        FILIAL: "01",
        FIELDS: "A1_COD,A1_LOJA,A1_NOME,A1_DDD,A1_TEL,A1_CONTATO,A1_ZAPAC",
        WHERE: `A1_MSBLQL='2' ORDER BY A1_NOME`,
        TABELA: "SA1",
      };

      if (this.vincularOsAgenda.job) {
        const [codigo] = this.vincularOsAgenda.os.ZF1_CODCLI.split(" - ");
        dados.WHERE = `A1_MSBLQL='2' AND A1_COD='${codigo}'`;
      }

      this.dialogAgendar = true;
      this.cliente = null;

      url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.clienteBase = res.data.map(e => {
            return `${e.A1_COD}-${e.A1_LOJA} - ${e.A1_NOME}`.trim();
          });
          this.dadosCliente = res.data;
        })
        .catch(err => console.log(err));

      this.loadingCliBase = false;
      ///////////////////////////////////////////

      //////////////////////////////////////////
      this.loadingCanal = true;
      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZMC_CODIGO,ZMC_DESCRI",
        WHERE: `ZMC_MSBLQL='2' AND ZMC_LINHA IN ('1') ORDER BY ZMC_CODIGO`,
        TABELA: `ZMC`,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.itensCanal = res.data.map(e => {
            return `${e.ZMC_CODIGO}-${e.ZMC_DESCRI}`.trim();
          });
          // this. = res.data;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      this.loadingCanal = false;
      ///////////////////////////////////////////
    },
    async buscaAssessor() {
      this.loadingAssessor = true;
      // const dados = {
      //   EMPRESA: "0101",
      //   FILIAL: "01",
      //   FIELDS: "A3_COD,A3_NOME",
      //   WHERE: `A3_MSBLQL='2' ORDER BY A3_NOME`,
      // };

      // const url = `${process.env.VUE_APP_BASE_API_URL}/assessor`;

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "A3_COD,A3_NOME",
        LEFT: `LEFT JOIN SZ0010 SZ0 WITH(NOLOCK) ON
                                    SZ0.D_E_L_E_T_=' '
                                    AND Z0_COD=A3_ZTIPO
               LEFT JOIN ZZU010 ZZU WITH(NOLOCK) ON
                                    ZZU.D_E_L_E_T_=' '
                                    AND ZZU.ZZU_COD=A3_ZSEGMEN
                                    AND ZZU.ZZU_TIPO='1'
               LEFT JOIN ZZU010 ZZU_SUB WITH(NOLOCK) ON
                                    ZZU_SUB.D_E_L_E_T_=' '
                                    AND ZZU_SUB.ZZU_COD=A3_ZSUBSEG
                                    AND ZZU_SUB.ZZU_TIPO='2'
               LEFT JOIN ZMA010 ZMA WITH(NOLOCK) ON
                                    ZMA.D_E_L_E_T_=' '
                                    AND ZMA_IDAREA=A3_ZZAREA `,
        WHERE: `A3_MSBLQL='2' ORDER BY A3_NOME`,
        TABELA: "SA3",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.dbAssesor = res.data.map(e => {
            return `${e.A3_COD} - ${e.A3_NOME}`;
          });
        })
        .catch(err => console.log(err));
      this.loadingAssessor = false;
    },
    dadosCli() {
      this.dadosCliente.forEach(e => {
        if (e.A1_COD.trim() === this.cliente.substr(0, 6)) {
          this.telCliente = e.A1_TEL ? `${e.A1_TEL.trim()}` : "";
          this.dddCliente = e.A1_DDD ? `${e.A1_DDD.trim()}` : "";
          this.clienteApac = e.A1_ZAPAC === "S";
        }
      });
      if (
        !this.noInDadosCompusorios.data[0].X6_CONTEUD.includes(
          this.nameofdoctor.substring(0, 6),
        )
      ) {
        this.verificaDadosCliente(this.cliente.substr(0, 6), "I", null);
      }
    },
    async verificaDadosCliente(cliente, origem, evento) {
      console.log("cliente", cliente);
      console.log("origem", origem);
      console.log("evento", evento);
      this.esperaBuscaDados = true;

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "A1_DDD,A1_TEL,A1_ZWHATSA,A1_EMAIL",
        WHERE: `A1_COD = '${cliente.substr(0, 6)}'`,
        TABELA: "SA1",
      };
      const dadosCliente = await axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/consultas`,
        dados,
      );

      if (dadosCliente.data.length > 0) {
        this.agendaAlterar = null;
        this.dddTel = dadosCliente.data[0].A1_DDD.trim();
        this.telefone = dadosCliente.data[0].A1_TEL.trim();

        this.whatsAppTel = dadosCliente.data[0].A1_ZWHATSA.trim();
        this.emailAtu = dadosCliente.data[0].A1_EMAIL.trim();
        if (origem === "I") {
          this.incluiAgenda = true;
        } else {
          this.incluiAgenda = false;
          this.agendaAlterar = evento;
        }
        this.obrigatorioInformativo = true;
      }

      this.esperaBuscaDados = false;
    },
    somaMinutos() {
      let hora = parseFloat(this.horaAgendaFim);
      let minutos = parseFloat(this.minutoAgendaFim);

      minutos += 10;

      if (minutos === 60) {
        hora++;
        if (hora < 10) {
          hora = `0${hora}`;
        }
        this.minutoAgendaFim = `00`;
        this.horaAgendaFim = `${hora}`;
      } else {
        this.minutoAgendaFim = `${minutos}`;
      }
    },
    async verHoraVaga() {
      this.carregarHoraVaga();
      this.dialogVerHorarios = true;
    },
    setToday() {
      this.focus = "";
      const data = new Date();
      const ano = data.getFullYear().toString();
      let mes = (data.getMonth() + 1).toString();
      let dia = data.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      this.dataMes = `${ano}-${mes}-${dia}`;

      this.dataTela = dia + `/` + mes + `/` + ano;

      this.date = this.dataTela;
    },
    async prev() {
      this.$refs.calendar.prev();
      await this.buscaHoraAgenda();
    },
    async atualizaAgendaDia() {
      if (!this.nameofdoctor) {
        return;
      }
      const data = this.dataMes.split("-");
      const dataBanco = `${data[0]}${data[1]}${data[2]}`;
      let agendaDiaTela = [];

      if (this.cloneAgenda.length > 0) {
        this.events = this.cloneAgenda;
        this.cloneAgenda = [];
      }

      agendaDiaTela = this.events.filter(e => {
        if (this.type === "category") {
          if (e.data === dataBanco && e.category === "Titulo") {
            return { ...e.recno };
          }
        } else {
          if (e.data === dataBanco) {
            return { ...e.recno };
          }
        }
      });

      let recnos = ``;

      for (let i = 0; i < agendaDiaTela.length; i++) {
        if (i === agendaDiaTela.length - 1) {
          recnos += `'${agendaDiaTela[i].recno}'`;
        } else {
          recnos += `'${agendaDiaTela[i].recno}',`;
        }
      }

      if (recnos) {
        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `ZZL_ID,ZZL_CANAL,ZZL_TITULO,A1_COD,A1_LOJA,A1_NOME,A1_ZAPAC,
          A3_COD,A3_NOME,ZZL_TEL1,CONVERT(VARCHAR(1000),ZZL_NOTAS) AS NOTAS,
          ZZL_DTINI,ZZL_DTFIN,ZZL_HRINI,ZZL_HRFIN,ZZL_CLIENT,ZZL_LOJA,ZZL_STATUS,ZZL_NOMECL,ZZL_TEL2,
          ZZL_DTALT,ZZL_USALT,ZZL_NUMOS,ZZL_TRANSF,ZZL_IDLEAD,ZZL_WHATS,ZZL_LOCAL,ZZL_DDD,ISNULL(ZMC_DESCRI,' ') ZMC_DESCRI,
          A1_DDD,A1_TEL,A1_CONTATO,A1_WHATS,ZZL.R_E_C_N_O_ AS RECNO`,
          WHERE: `ZZL_PESSOA='${this.nameofdoctor.substring(
            0,
            6,
          )}' AND ZZL_DTINI='${dataBanco}' AND ZZL.R_E_C_N_O_ NOT IN (${recnos})`,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/agenda`;

        let agendaDiaBanco = [];
        await axios
          .post(url, dados)
          .then(res => {
            agendaDiaBanco = res.data;
          })
          .catch(err => {
            console.log(err);
          });

        if (agendaDiaBanco.length > 0) {
          const allDay = this.rnd(0, 3) === 0;

          let assessor = ``;
          let color = ``;

          if (this.type === "day" || this.type === "week") {
            agendaDiaBanco.map(e => {
              if (
                e.ZZL_DTINI.trim().length > 0 &&
                e.ZZL_DTFIN.trim().length > 0 &&
                e.ZZL_HRINI.trim().length === 5 &&
                e.ZZL_HRFIN.trim().length === 5
              ) {
                let start = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
                  4,
                  2,
                )}-${e.ZZL_DTINI.substr(6, 2)} ${e.ZZL_HRINI}`;
                let end = `${e.ZZL_DTFIN.substr(0, 4)}-${e.ZZL_DTFIN.substr(
                  4,
                  2,
                )}-${e.ZZL_DTFIN.substr(6, 2)} ${e.ZZL_HRFIN}`;
                let name = ``;
                if (e.A1_NOME) {
                  name = `${e.A1_COD}-${e.A1_NOME}`;
                } else {
                  name = e.ZZL_NOMECL;
                }
                let tel = ``;
                let tel2 = ``;
                if (e.ZZL_TEL1) {
                  tel = `${e.ZZL_TEL1}`;
                  tel2 = `${e.ZZL_TEL2}`;
                } else {
                  if (e.A1_NOME) {
                    tel = `${e.A1_DDD} - ${e.A1_TEL} / CONTATO: ${e.A1_CONTATO}`;
                  }
                }
                if (e.ZZL_STATUS === "A") {
                  color = "cyan lighten-4";
                }
                if (e.ZZL_STATUS === "S") {
                  color = "grey lighten-4";
                }
                if (e.ZZL_STATUS === "C") {
                  color = "green lighten-4";
                }
                if (e.ZZL_STATUS === "N") {
                  color = "red lighten-4";
                }
                assessor = e.A3_NOME;

                this.events.push({
                  id: e.ZZL_ID,
                  name: `Título:${e.ZZL_TITULO} | Paciente:${name} | Médico/Representante:${assessor} | Telefone: ${tel} / ${tel2} `,
                  start: start,
                  end: end,
                  timed: !allDay,
                  status: e.ZZL_STATUS,
                  color: color,
                  titulo: e.ZZL_TITULO,
                  cliente: e.A1_NOME
                    ? `${e.A1_COD}-${e.A1_LOJA}-${e.A1_NOME}`
                    : e.ZZL_NOMECL,
                  canal: `${e.ZZL_CANAL}-${e.ZMC_DESCRI}`,
                  telefone: e.A1_TEL ? `(${e.A1_DDD})-${e.A1_TEL}` : e.ZZL_TEL1,
                  assessor: `${e.A3_COD}-${e.A3_NOME}`,
                  dtAlt: e.ZZL_DTALT,
                  usrAlt: e.ZZL_USALT,
                  nomecl: e.ZZL_NOMECL,
                  numOs: e.ZZL_NUMOS,
                  horaIni: e.ZZL_HRINI,
                  horaFim: e.ZZL_HRFIN,
                  data: e.ZZL_DTINI,
                  notas: e.NOTAS,
                  transferida: e.ZZL_TRANSF ? true : false,
                });
              }
            });
          }

          if (this.type === "category") {
            this.itemsTipo = [];
            this.tipo = "";
            agendaDiaBanco.map(e => {
              if (e.ZZL_TITULO.trim().length > 0) {
                if (
                  e.ZZL_DTINI.trim().length > 0 &&
                  e.ZZL_DTFIN.trim().length > 0 &&
                  e.ZZL_HRINI.trim().length === 5 &&
                  e.ZZL_HRFIN.trim().length === 5
                ) {
                  let start = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
                    4,
                    2,
                  )}-${e.ZZL_DTINI.substr(6, 2)} ${e.ZZL_HRINI}`;
                  let end = `${e.ZZL_DTFIN.substr(0, 4)}-${e.ZZL_DTFIN.substr(
                    4,
                    2,
                  )}-${e.ZZL_DTFIN.substr(6, 2)} ${e.ZZL_HRFIN}`;

                  this.categories.forEach(c => {
                    let name = ``;
                    if (c === "Titulo") {
                      name = e.ZZL_TITULO;
                    }
                    if (c === "Paciente") {
                      if (e.A1_NOME) {
                        name = `${e.A1_COD}-${e.A1_NOME}`;
                      } else {
                        name = e.ZZL_NOMECL;
                      }
                    }
                    if (c === "Médico/Representante") {
                      name = e.A3_NOME;
                    }
                    if (c === "Telefone") {
                      if (e.A1_NOME) {
                        name = `${e.A1_DDD} - ${e.A1_TEL} / CONTATO: ${e.A1_CONTATO}`;
                      } else {
                        if (e.ZZL_TEL1) {
                          name = `${e.ZZL_TEL1} / ${e.ZZL_TEL2}`;
                        }
                      }
                    }
                    if (c === "Notas") {
                      name = e.NOTAS;
                    }
                    let color = ``;
                    if (e.ZZL_STATUS === "A") {
                      color = "cyan lighten-4";
                    }
                    if (e.ZZL_STATUS === "S") {
                      color = "grey lighten-4";
                    }
                    if (e.ZZL_STATUS === "C") {
                      color = "green lighten-4";
                    }
                    if (e.ZZL_STATUS === "N") {
                      color = "red lighten-4";
                    }

                    this.events.push({
                      id: e.ZZL_ID,
                      name: name,
                      start: start,
                      end: end,
                      timed: !allDay,
                      category: c,
                      status: e.ZZL_STATUS,
                      color: color,
                      titulo: e.ZZL_TITULO,
                      cliente: e.A1_NOME
                        ? `${e.A1_COD}-${e.A1_LOJA}-${e.A1_NOME}`
                        : e.ZZL_NOMECL,
                      telefone: e.A1_TEL
                        ? `(${e.A1_DDD})-${e.A1_TEL}`
                        : e.ZZL_TEL1,
                      canal: `${e.ZZL_CANAL}-${e.ZMC_DESCRI}`,
                      assessor: `${e.A3_COD}-${e.A3_NOME}`,
                      dtAlt: e.ZZL_DTALT,
                      usrAlt: e.ZZL_USALT,
                      nomecl: e.ZZL_NOMECL,
                      numOs: e.ZZL_NUMOS,
                      horaIni: e.ZZL_HRINI,
                      horaFim: e.ZZL_HRFIN,
                      data: e.ZZL_DTINI,
                      notas: e.NOTAS,
                      transferida: e.ZZL_TRANSF ? true : false,
                    });
                  });
                }
              }
            });
          }
        }
      }
    },
    carregarHoraVaga() {
      const data = calendarDateToDate(this.dataMes);
      const anomesdia = lightFormat(data, "yyyyMMdd");

      const agendaDia =
        this.type === "category"
          ? this.events.filter(
              e => e.category === "Titulo" && e.data === anomesdia,
            )
          : this.events.filter(e => e.data === anomesdia);

      const horaIniDia = hourParse(this.horarioDia[0].horaIni);
      const horaFimDia = hourParse(this.horarioDia[0].horaFim);
      const intervalo = minutesToMilliseconds(5);
      const horasDisponiveis = [];

      for (let h = horaIniDia; h < horaFimDia; h += intervalo) {
        const vago = !agendaDia.some(
          a => hourParse(a.horaIni) <= h && h < hourParse(a.horaFim),
        );

        horasDisponiveis.push({
          horaIni: lightFormat(h + fusoInMili, "HH:mm"),
          vago,
        });
      }

      this.aHorasDia = [...horasDisponiveis];
    },
    buscaHoraAgenda() {
      this.filtroLegAgendado = false;
      this.filtroLegSemAgenda = false;
      this.filtroLegCompareceu = false;
      this.filtroLegNCompareceu = false;
      this.numberfilter = 0;
      this.atualizaAgendaDia();
      const data = this.dataMes.split("-");
      const dataAtual = new Date(data[0], data[1] - 1, data[2]);
      const dia = dataAtual.getDay();
      const semana = new Array(6);

      semana[0] = "DOMINGO";
      semana[1] = "SEGUNDA";
      semana[2] = "TERÇA";
      semana[3] = "QUARTA";
      semana[4] = "QUINTA";
      semana[5] = "SEXTA";
      semana[6] = "SABADO";
      this.horarioDia = [];

      this.horarioDia = this.horarios.filter(e => {
        if (e.dia === semana[dia]) {
          return { ...e };
        }
      });

      if (this.horarioDia.length > 0) {
        const horaIni = `${data[0]}${data[1]}${data[2]} ${this.horarioDia[0].horaIni}`;
        const horaFim = `${data[0]}${data[1]}${data[2]} ${this.horarioDia[0].horaFim}`;

        var date1 = new Date(
            horaIni.slice(0, 4),
            horaIni.slice(4, 6),
            horaIni.slice(6, 8),
            horaIni.slice(9, 11),
            horaIni.slice(12, 14),
          ),
          date2 = new Date(
            horaFim.slice(0, 4),
            horaFim.slice(4, 6),
            horaFim.slice(6, 8),
            horaFim.slice(9, 11),
            horaFim.slice(12, 14),
          );

        var diffMs = date2 - date1;
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000);
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
        var diffHora = parseFloat(diffHrs);
        const diffMinutos = parseFloat(diffMins);

        let number_interval = 0;
        let number_interval_minut = 0;

        if (diffHora > 0) {
          number_interval = diffHora * 4;
        }
        if (diffMinutos > 0) {
          number_interval_minut = diffMinutos / 15;
        }

        const total = number_interval + number_interval_minut;

        this.interval_count = total;

        this.first_time = "";

        if (this.horarioDia.length > 0) {
          this.first_time = this.horarioDia[0].horaIni;
        }
      } else {
        this.interval_count = 40;

        this.first_time = "08:00";
      }
    },
    async next() {
      this.$refs.calendar.next();
      await this.buscaHoraAgenda();
    },
    async prevM() {
      this.$refs.calendarM.prev();
      await this.buscaHoraAgenda();
    },
    async nextM() {
      this.$refs.calendarM.next();
      await this.buscaHoraAgenda();
    },
    filtroTipo(tipo) {
      this.filtroLegAgendado = false;
      this.filtroLegSemAgenda = false;
      this.filtroLegCompareceu = false;
      this.filtroLegNCompareceu = false;
      this.numberfilter = 0;

      if (this.cloneAgenda.length > 0) {
        this.events = this.cloneAgenda;
        this.cloneAgenda = [];
      }

      this.cloneAgenda = this.events;
      let eventos = this.events;

      let consulta = null;

      if (tipo === "Titulo") {
        consulta = this.tipo;
      }

      if (tipo === "Paciente") {
        consulta = this.paciente;
      }

      if (tipo === "Médico/Representante") {
        consulta = this.assessor;
      }

      if (tipo === "Telefone") {
        consulta = this.telefone;
      }

      if (tipo === "Notas") {
        consulta = this.obs;
      }

      eventos = eventos.filter(e => {
        if (e.name === consulta) {
          return e.id;
        }
      });

      let filtro = [];

      eventos.forEach(e => {
        this.events.map(f => {
          if (f.id === e.id) {
            filtro.push(f);
          }
        });
      });

      this.events = filtro;
      this.filtrado = true;
    },
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      const allDay = this.rnd(0, 3) === 0;
      for (let i = 0; i < eventCount; i++) {
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
          category: this.categories[this.rnd(0, this.categories.length - 1)],
        });
      }

      let itemsTipo = [];

      this.categories.forEach(e => {
        let conteudo;
        if (e === "Tipo") {
          conteudo = "Retorno";
          itemsTipo.push("Retorno");
        }
        if (e === "Paciente") {
          conteudo = "Evandro Alves de Souza";
        }
        if (e === "Médico/Representante") {
          conteudo = "Dr. Pica Pau";
        }
        if (e === "Telefone") {
          conteudo = "(16) 99334-6003";
        }
        if (e === "Notas") {
          conteudo = "teste de Notas da consulta...";
        }
        events.push({
          id: "34568",
          name: conteudo,
          start: "2020-07-29 08:00:00",
          end: "2020-07-29 09:00:00",
          color: "blue",
          timed: !allDay,
          category: e,
        });
      });

      this.categories.forEach(e => {
        let conteudo;
        if (e === "Tipo") {
          conteudo = "Atendimento";
          itemsTipo.push("Atendimento");
        }
        if (e === "Paciente") {
          conteudo = "Evandro Alves de Souza";
        }
        if (e === "Médico/Representante") {
          conteudo = "Dr. Pica Pau";
        }
        if (e === "Telefone") {
          conteudo = "(16) 99334-6003";
        }
        if (e === "Notas") {
          conteudo = "teste de Notas da consulta...";
        }
        events.push({
          id: "34569",
          name: conteudo,
          start: "2020-07-29 09:15:00",
          end: "2020-07-29 09:30:00",
          color: "blue",
          timed: !allDay,
          category: e,
        });
      });

      this.itemsTipo = itemsTipo;

      this.events = events;
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    async buscaTecnico() {
      if (this.vincularOsAgenda.job) {
        this.dialogAwaitAgenda = true;
      }

      this.loadTecnico = true;
      const dados = {
        EMPRESA: `01`,
        FILIAL: `0101`,
        FIELDS: `ZZK_COD,ZZK_NOME`,
        WHERE: ` ZZK_MSBLQL <> '1' AND ZZK_TIPO IN ('A','F') `,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/tecnicos`;

      await axios
        .post(url, dados)
        .then(res => {
          this.nomes = res.data.map(e => {
            return e.ZZK_COD + " - " + e.ZZK_NOME.trim();
          });
        })
        .catch(err => {
          console.log(err);
        });

      if (this.vincularOsAgenda.job) {
        this.nameofdoctor = this.vincularOsAgenda.os.ZF1_CODTEC;
        this.buscaAgenda();
      }
      this.loadTecnico = false;
    },
    async buscaAgenda() {
      if (this.nameofdoctor) {
        this.setToday();
        this.filtroLegAgendado = false;
        this.filtroLegSemAgenda = false;
        this.filtroLegCompareceu = false;
        this.filtroLegNCompareceu = false;
        this.numberfilter = 0;
        this.first_time = "";
        this.interval_count = null;
        this.dialogAwaitAgenda = true;
        this.events = [];
        let agenda = [];
        this.cloneAgenda = [];
        const allDay = this.rnd(0, 3) === 0;
        const pessoa = this.nameofdoctor.substring(0, 6);

        const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;
        let dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `ZZL_ID, ZZL_CANAL, ZZL_TITULO,
                    A1_COD, A1_LOJA, A1_NOME, A1_ZAPAC,
                    A3_COD, A3_NOME, ZZL_TEL1,
                    CONVERT(VARCHAR(1000), ZZL_NOTAS) AS NOTAS,
                    ZZL_DTINI, ZZL_DTFIN, ZZL_HRINI, ZZL_HRFIN,
                    ZZL_CLIENT, ZZL_LOJA, ZZL_STATUS, ZZL_NOMECL, ZZL_TEL2,
                    ZZL_DTALT, ZZL_USALT, ZZL_NUMOS, ZZL_TRANSF, ZZL_IDLEAD,
                    ZZL_WHATS, ZZL_LOCAL, ZZL_DDD,
                    ISNULL(ZMC_DESCRI,' ') ZMC_DESCRI, ZZ2_LOCAL,
                    A1_DDD, A1_TEL, A1_CONTATO, A1_WHATS, ZZZ_RESPO,
                    ZZL.R_E_C_N_O_ AS RECNO`,
          LEFT: ` LEFT JOIN SA1010 SA1 WITH(NOLOCK) ON
                                                    SA1.D_E_L_E_T_=' '
                                                    AND A1_COD=ZZL_CLIENT
                                                    AND A1_LOJA = ZZL_LOJA
                  LEFT JOIN SA3010 SA3 WITH(NOLOCK) ON
                                                    SA3.D_E_L_E_T_=' '
                                                    AND A3_COD = ZZL_VEND1
                  LEFT JOIN ZMC010 ZMC WITH(NOLOCK) ON
                                                    ZMC.D_E_L_E_T_=' '
                                                  AND ZMC_CODIGO = ZZL_CANAL
                                                  LEFT JOIN ZZ2010 ZZ2 WITH(NOLOCK) ON
                                                    ZZ2.D_E_L_E_T_=' '
                                                    AND ZZ2_CODIGO=ZZL_LOCAL
                  LEFT JOIN ZZZ010 ZZZ WITH(NOLOCK) ON
                                                    ZZZ.D_E_L_E_T_=' '
                                                    AND SUBSTRING(ZZZ_ID,0,7) = ZZL.R_E_C_N_O_
                                                    AND SUBSTRING(ZZZ_ID,8,3) = 'ZZL'
                                                    AND ZZZ_CTXTO='agendamento' `,
          WHERE: `ZZL_PESSOA='${pessoa}'`,
          TABELA: "ZZL",
        };

        await axios
          .post(url, dados)
          .then(res => {
            agenda = res.data;
          })
          .catch(err => {
            console.log(err);
          });

        let assessor = ``;
        let color = ``;

        if (this.type === "day" || this.type === "week") {
          this.events = [];
          agenda.map(e => {
            if (
              e.ZZL_DTINI.trim().length > 0 &&
              e.ZZL_DTFIN.trim().length > 0 &&
              e.ZZL_HRINI.trim().length === 5 &&
              e.ZZL_HRFIN.trim().length === 5
            ) {
              let start = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
                4,
                2,
              )}-${e.ZZL_DTINI.substr(6, 2)} ${e.ZZL_HRINI}`;
              let end = `${e.ZZL_DTFIN.substr(0, 4)}-${e.ZZL_DTFIN.substr(
                4,
                2,
              )}-${e.ZZL_DTFIN.substr(6, 2)} ${e.ZZL_HRFIN}`;
              let name = ``;
              if (e.A1_NOME) {
                name = `${e.A1_COD}-${e.A1_NOME}`;
              } else {
                name = e.ZZL_NOMECL;
              }
              let tel = ``;
              let tel2 = ``;
              if (e.ZZL_TEL1) {
                tel = `${e.ZZL_TEL1}`;
                tel2 = `${e.ZZL_TEL2}`;
              } else {
                if (e.A1_NOME) {
                  tel = `${e.A1_TEL} / CONTATO: ${e.A1_CONTATO}`;
                }
              }
              if (e.ZZL_STATUS === "A") {
                color = "cyan lighten-4";
              }
              if (e.ZZL_STATUS === "S") {
                color = "grey darken-3";
              }
              if (e.ZZL_STATUS === "C") {
                color = "green lighten-4";
              }
              if (e.ZZL_STATUS === "N") {
                color = "red lighten-4";
              }
              assessor = e.A3_NOME;

              this.events.push({
                id: e.ZZL_ID,
                name: `Título:${e.ZZL_TITULO} | Paciente:${name} | Médico/Representante:${assessor} | Telefone: ${tel} / ${tel2}`,
                start: start,
                end: end,
                timed: !allDay,
                status: e.ZZL_STATUS,
                color: color,
                titulo: e.ZZL_TITULO,
                cliente: e.A1_NOME
                  ? `${e.A1_COD}-${e.A1_LOJA}-${e.A1_NOME}`
                  : e.ZZL_NOMECL,
                canal: `${e.ZZL_CANAL}-${e.ZMC_DESCRI}`,
                telefone: `${tel} / ${tel2}`,
                assessor: `${e.A3_COD}-${e.A3_NOME}`,
                dtAlt: e.ZZL_DTALT,
                usrAlt: e.ZZL_USALT,
                nomecl: e.ZZL_NOMECL,
                numOs: e.ZZL_NUMOS,
                horaIni: e.ZZL_HRINI,
                horaFim: e.ZZL_HRFIN,
                data: e.ZZL_DTINI,
                notas: e.NOTAS,
                transferida: e.ZZL_TRANSF === "S" ? true : false,
                recno: e.RECNO,
                clienteApac: e.A1_ZAPAC === "S",
                idLead: e.ZZL_IDLEAD,
                whatsZZL: e.ZZL_WHATS,
                whatsSA1: e.A1_WHATS,
                localAtendimento: e.ZZL_LOCAL,
                ddd: e.ZZL_DDD,
                respostaBot: e.ZZZ_RESPO,
              });
            }
          });
        }

        if (this.type === "category") {
          this.events = [];
          this.itemsTipo = [];
          this.tipo = "";

          agenda.map(e => {
            if (e.ZZL_TITULO.trim().length > 0) {
              if (
                e.ZZL_DTINI.trim().length > 0 &&
                e.ZZL_DTFIN.trim().length > 0 &&
                e.ZZL_HRINI.trim().length === 5 &&
                e.ZZL_HRFIN.trim().length === 5
              ) {
                let start = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
                  4,
                  2,
                )}-${e.ZZL_DTINI.substr(6, 2)} ${e.ZZL_HRINI}`;
                let end = `${e.ZZL_DTFIN.substr(0, 4)}-${e.ZZL_DTFIN.substr(
                  4,
                  2,
                )}-${e.ZZL_DTFIN.substr(6, 2)} ${e.ZZL_HRFIN}`;

                this.categories.forEach(c => {
                  let name = ``;
                  if (c === "Titulo") {
                    name = e.ZZL_TITULO;
                  }
                  if (c === "Paciente") {
                    if (e.A1_NOME) {
                      name = `${e.A1_COD}-${e.A1_NOME}`;
                    } else {
                      name = e.ZZL_NOMECL;
                    }
                  }
                  if (c === "Médico/Representante") {
                    name = e.A3_NOME;
                  }
                  if (c === "Telefone") {
                    if (e.ZZL_TEL1) {
                      name = `${e.ZZL_TEL1} / ${e.ZZL_TEL2}`;
                    }
                  }
                  if (c === "Notas") {
                    name = e.NOTAS;
                  }
                  let color = ``;
                  if (e.ZZL_STATUS === "A") {
                    color = "cyan lighten-4";
                  }
                  if (e.ZZL_STATUS === "S") {
                    color = "grey lighten-4";
                  }
                  if (e.ZZL_STATUS === "C") {
                    color = "green lighten-4";
                  }
                  if (e.ZZL_STATUS === "N") {
                    color = "red lighten-4";
                  }

                  this.events.push({
                    id: e.ZZL_ID,
                    name: name,
                    start: start,
                    end: end,
                    timed: !allDay,
                    category: c,
                    status: e.ZZL_STATUS,
                    color: color,
                    titulo: e.ZZL_TITULO,
                    cliente: e.A1_NOME
                      ? `${e.A1_COD}-${e.A1_LOJA}-${e.A1_NOME}`
                      : e.ZZL_NOMECL,
                    canal: `${e.ZZL_CANAL}-${e.ZMC_DESCRI}`,
                    telefone: `${e.ZZL_TEL1} / ${e.ZZL_TEL2}`,
                    assessor: `${e.A3_COD}-${e.A3_NOME}`,
                    dtAlt: e.ZZL_DTALT,
                    usrAlt: e.ZZL_USALT,
                    nomecl: e.ZZL_NOMECL,
                    numOs: e.ZZL_NUMOS,
                    horaIni: e.ZZL_HRINI,
                    horaFim: e.ZZL_HRFIN,
                    data: e.ZZL_DTINI,
                    notas: e.NOTAS,
                    transferida: e.ZZL_TRANSF === "S" ? true : false,
                    recno: e.RECNO,
                    clienteApac: e.A1_ZAPAC === "S",
                    idLead: e.ZZL_IDLEAD,
                    whatsZZL: e.ZZL_WHATS,
                    whatsSA1: e.A1_WHATS,
                    localAtendimento: e.ZZL_LOCAL,
                    ddd: e.ZZL_DDD,
                    respostaBot: e.ZZZ_RESPO,
                  });
                });
              }
            }
          });
        }

        await this.buscaHorarios();

        this.eventsMonth = [];

        dados = {
          EMPRESA: `01`,
          FILIAL: `0101`,
          FIELDS: `DISTINCT(ZZL_DTINI)`,
          WHERE: `ZZL_PESSOA='${pessoa}'`,
          TABELA: "ZZL",
        };

        let marcacao = [];

        await axios
          .post(url, dados)
          .then(res => {
            marcacao = res.data;
          })
          .catch(err => {
            console.log(err);
          });

        marcacao.map(e => {
          if (e.ZZL_DTINI.trim().length > 0) {
            let start = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
              4,
              2,
            )}-${e.ZZL_DTINI.substr(6, 2)}`;
            let end = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
              4,
              2,
            )}-${e.ZZL_DTINI.substr(6, 2)}`;
            this.eventsMonth.push({
              name: "Agenda",
              start: start,
              end: end,
            });

            const data = new Date(
              parseInt(e.ZZL_DTINI.substr(0, 4)),
              parseInt(e.ZZL_DTINI.substr(4, 2) - 1),
              parseInt(e.ZZL_DTINI.substr(6, 2)),
            );

            if (this.type === "day" && data.getDay() !== 6) {
              start = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
                4,
                2,
              )}-${e.ZZL_DTINI.substr(6, 2)} ${this.horaInicioAlmoco}`;
              end = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
                4,
                2,
              )}-${e.ZZL_DTINI.substr(6, 2)} ${this.horaFimAlmoco}`;

              this.events.push({
                id: "ALM",
                name: `ALMOÇO `,
                start: start,
                end: end,
                timed: !allDay,
                status: "",
                icon: "mdi-food",
                color: "orange",
                titulo: "Almoço",
                cliente: "",
                canal: "",
                telefone: "",
                assessor: "",
                dtAlt: "",
                usrAlt: "",
                nomecl: "",
                numOs: "",
                horaIni: this.horaInicioAlmoco,
                horaFim: this.horaFimAlmoco,
                data: "",
                notas: "",
                transferida: false,
                almoco: true,
                recno: null,
                idLead: null,
                whatsZZL: "S",
                whatsSA1: "S",
                localAtendimento: "",
                ddd: "",
                respostaBot: "",
              });
            }

            if (this.type === "category" && data.getDay() !== 6) {
              start = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
                4,
                2,
              )}-${e.ZZL_DTINI.substr(6, 2)} ${this.horaInicioAlmoco}`;
              end = `${e.ZZL_DTINI.substr(0, 4)}-${e.ZZL_DTINI.substr(
                4,
                2,
              )}-${e.ZZL_DTINI.substr(6, 2)} ${this.horaFimAlmoco}`;
              this.categories.forEach(c => {
                this.events.push({
                  id: "ALM",
                  name: `ALMOÇO `,
                  start: start,
                  end: end,
                  timed: !allDay,
                  category: c,
                  status: "",
                  icon: "mdi-food",
                  color: "orange",
                  titulo: "Almoço",
                  cliente: "",
                  canal: "",
                  telefone: "",
                  assessor: "",
                  dtAlt: "",
                  usrAlt: "",
                  nomecl: "",
                  numOs: "",
                  horaIni: this.horaInicioAlmoco,
                  horaFim: this.horaFimAlmoco,
                  data: "",
                  notas: "",
                  transferida: false,
                  almoco: true,
                  recno: null,
                  idLead: null,
                  whatsZZL: "S",
                  whatsSA1: "S",
                  localAtendimento: "",
                  ddd: "",
                  respostaBot: "",
                });
              });
            }
          }
        });

        this.buscaHoraAgenda();
        this.dialogAwaitAgenda = false;

        if (
          this.vincularOsAgenda.job &&
          this.nameofdoctor &&
          this.horarioDia.length > 0
        ) {
          this.dialogAwaitJob = true;
          await this.agendaPlus();
        }
      }
    },
    async buscaHorarios() {
      const pessoa = this.nameofdoctor.substring(0, 6);
      const dados = {
        EMPRESA: `01`,
        FILIAL: `0101`,
        FIELDS: ``,
        WHERE: `ZZK_COD='${pessoa}'`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/tecnicos`;

      this.horarios = [];

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            if (e.ZZK_HDOM === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRDOMI,
                horaFim: e.ZZK_HRDOMF,
                dia: "DOMINGO",
              });
            }
            if (e.ZZK_HSEG === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRSEGI,
                horaFim: e.ZZK_HRSEGF,
                dia: "SEGUNDA",
              });
            }
            if (e.ZZK_HTER === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRTERI,
                horaFim: e.ZZK_HRTERF,
                dia: "TERÇA",
              });
            }
            if (e.ZZK_HQUA === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRQUAI,
                horaFim: e.ZZK_HRQUAF,
                dia: "QUARTA",
              });
            }
            if (e.ZZK_HQUI === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRQUII,
                horaFim: e.ZZK_HRQUIF,
                dia: "QUINTA",
              });
            }
            if (e.ZZK_HSEX === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRSEXI,
                horaFim: e.ZZK_HRSEXF,
                dia: "SEXTA",
              });
            }
            if (e.ZZK_HSAB === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRSABI,
                horaFim: e.ZZK_HRSABF,
                dia: "SABADO",
              });
            }

            this.horaInicioAlmoco = e.ZZK_HRALMI;
            this.horaFimAlmoco = e.ZZK_HRALMF;
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    async carregaTipos(tipo) {
      this.filtroLegAgendado = false;
      this.filtroLegSemAgenda = false;
      this.filtroLegCompareceu = false;
      this.filtroLegNCompareceu = false;
      this.numberfilter = 0;

      if (this.cloneAgenda.length > 0) {
        this.events = this.cloneAgenda;
        this.cloneAgenda = [];
      }

      if (tipo.trim().length === 0) {
        this.buscaAgenda;
        return;
      }
      const data = this.dataMes;

      this.itemsTipo = [];

      this.events.forEach(e => {
        const dtIni = e.start.substr(0, 10);
        if (e.category === tipo && dtIni === data) {
          this.itemsTipo.push(e.name);
        }
      });
    },
    async montaLeads() {
      this.loadingLeads = true;
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZZQ_ID,ZZQ_NOME,ZZQ_TEL",
        WHERE: "",
        TABELA: "ZZQ",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            if (e.ZZQ_NOME.trim() === "") {
              e.ID = `${e.ZZQ_ID.trim()} - Sem Nome - ${e.ZZQ_TEL.trim()}`;
            } else {
              e.ID = `${e.ZZQ_ID.trim()} - ${e.ZZQ_NOME.trim()} - ${e.ZZQ_TEL.trim()}`;
            }
          });
          this.itensLeads = [
            {
              text: "",
              value: null,
              NOME: "",
            },
          ];
          res.data.forEach(e => {
            this.itensLeads.push({
              text: e.ID,
              value: e.ZZQ_ID,
              NOME: e.ZZQ_NOME.trim(),
            });
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
      this.loadingLeads = false;
    },
    setNomeLead(item) {
      console.log(item);
      const dados = this.Lead.split("-");

      this.nomeLead = dados[1];
    },
    async buscaParametros() {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "X6_CONTEUD",
        WHERE: `X6_VAR='MV_RETCOMC'`,
        TABELA: "SX6010",
      };

      this.noInDadosCompusorios = await axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/consultas`,
        dados,
      );
    },
  },
  created() {
    this.setToday();
  },
  mounted() {
    this.buscaTecnico();
    this.buscaParametros();
  },
  destroyed() {
    this.$store.commit("SetVincOsAgenda", { job: false, os: null });
  },
};
</script>

<style>
.content {
  display: grid;
  grid-template-columns: 400px 1fr;
  align-content: center;
  align-items: top;
  padding-bottom: 40px;
}

.agenda_mes {
  padding: 10px;
  margin: 0px;
  height: 80vh;
}

.agenda_dia {
  height: 300px;
}

.filtrinho:hover {
  cursor: pointer;
}
</style>
