<template>
  <div class="pa-3">
    <v-dialog v-model="dialogVerObs" width="45vw">
      <v-card>
        <v-card-title
          >Observações da visita de {{ itemDaObs.A3_NOME }}</v-card-title
        >
        <v-card-subtitle
          >Em {{ itemDaObs.ZZY_DATA }}, checada às
          {{ itemDaObs.ZZY_HRCHK }}</v-card-subtitle
        >
        <v-card-text>
          <v-skeleton-loader
            ref="skeleton"
            :boilerplate="items.length > 0"
            type="table"
            class="mx-auto"
            v-if="items.length <= 0"
          ></v-skeleton-loader>
          <v-timeline v-if="items.length > 0" align="start">
            <v-timeline-item v-for="(item, i) in items" :key="i">
              <template v-slot:opposite>{{ item.USR_NOME }}</template>
              <div>
                <div class="text-indigo text-h6">
                  Criada em {{ item.ZZV_DATA }} às {{ item.ZZV_HORA }}
                </div>
                <p>{{ item.ZZV_OBS }}</p>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="vObs" width="30vw">
      <v-card>
        <v-card-title>Observação sobre a visita.</v-card-title>
        <v-card-subtitle>{{ nomeVisita }}</v-card-subtitle>
        <v-card-text>
          <v-row dense class="d-flex justify-center align-center">
            <v-col cols="12">
              <v-textarea v-model="obsVisita"></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-text-field 
              v-model="emailAdicional"
              label="Enviar p/ email's adicionais, separar por ;"
              dense
              outlined></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn small class="error" @click="vObs = false">Fechar</v-btn>
            <v-spacer></v-spacer>
            <v-btn small class="primary" @click="incluiObsVisita">Salvar</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
      <v-snackbar
        v-model="snackbar_success"
        color="success"
        multi-line="multi-line"
        :timeout="timeSnackbarError"
        bottom="bottom"
      >
        <v-icon small color="#FFF" class="mr-3">mdi-check</v-icon>
        {{ sucesso }}
      </v-snackbar>
      <div height="10vh">
        <v-overlay
          class="d-flex flex-column justify-center align-center"
          :value="awaitBanco"
        >
          <v-progress-circular
            indeterminate
            size="64"
            class="center"
          ></v-progress-circular>
          <p>{{ chamada }}</p>
        </v-overlay>
      </div>
    </v-dialog>
    <div>
      <v-row dense>
        <v-col cols="1">
          <v-text-field
            class="mt-3 text-uppercase"
            label="Data Inicial"
            v-model="dtInicial"
            v-mask="'##/##/####'"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field
            class="mt-3 text-uppercase"
            label="Data Final"
            v-model="dtFinal"
            v-mask="'##/##/####'"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            dense
            :loading="loadingPessoas"
            v-model="pessoa"
            item-text="text"
            item-value="value"
            :items="itensPessoas"
            filled
            label="Visitador"
            background-color="white"
          ></v-autocomplete>
        </v-col>
        <v-btn
          class="mx-5 mt-2"
          fab
          dark
          small
          color="primary"
          @click="buscaVisitas"
        >
          <v-icon dark> mdi-magnify </v-icon>
        </v-btn>
        <template v-if="itensMedicos.length > 0">
          <v-col cols="3">
            <v-autocomplete
              dense
              :loading="loadingMedicos"
              v-model="medico"
              item-text="text"
              item-value="value"
              :items="itensMedicos"
              filled
              label="Filtrar visitado"
              background-color="white"
            ></v-autocomplete>
          </v-col>
          <v-btn
            class="mx-5 mt-2"
            fab
            dark
            small
            color="primary"
            @click="filtraMedico"
          >
            <v-icon dark> mdi-filter </v-icon>
          </v-btn>
        </template>
      </v-row>
      <v-data-table
        :headers="headersVisitas"
        fixed-header
        dense
        :items="visitas"
        class="elevation-1"
        no-data-text="Sem visitas no momento..."
        :items-per-page="visitas ? visitas.length : 0"
        hide-default-footer
        height="80vh"
        :loading="loadVisitas"
      >
        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex justify-space-around align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="black"
                  size="22"
                  @click="lancaObs(item)"
                  >mdi-notebook-edit-outline</v-icon
                >
              </template>
              <span>Incluir Observação</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="!item.OBS"
                  v-bind="attrs"
                  v-on="on"
                  class="ml-3"
                  color="grey ligthen-2"
                  size="22"
                  >mdi-file-cabinet</v-icon
                >
                <v-icon
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  class="ml-3"
                  color="grey darken-4"
                  size="22"
                  @click="verObservacoes(item)"
                  >mdi-file-cabinet</v-icon
                >
              </template>
              <span>Ver observações</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.ZZY_VISITA`]="{ item }">
          <v-chip
            class="ma-2"
            color="blue"
            text-color="white"
            x-small
            v-if="item.ZZY_VISITA === 'P'"
            >Presencial</v-chip
          >
          <v-chip
            class="ma-2"
            color="red"
            text-color="white"
            x-small
            v-if="item.ZZY_VISITA === 'N'"
            >Não aconsteceu</v-chip
          >
          <v-chip
            class="ma-2"
            color="cyan"
            text-color="white"
            x-small
            v-if="item.ZZY_VISITA === 'R'"
            >Remota</v-chip
          >
          <v-chip
            class="ma-2"
            color="purple"
            text-color="white"
            x-small
            v-if="item.ZZY_VISITA === 'C'"
            >Centro cirúrgico</v-chip
          >
        </template>
        <template v-slot:[`item.ZZY_CHECK`]="{ item }">
          <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            x-small
            v-if="item.ZZY_CHECK === 'S'"
            >Aconteceu</v-chip
          >
          <v-chip
            class="ma-2"
            color="red"
            text-color="white"
            x-small
            v-if="item.ZZY_CHECK === 'N'"
            >Não aconteceu</v-chip
          >
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-row dense>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <vue-excel-xlsx
                    :data="visitas"
                    :columns="headersRelatExcel"
                    :filename="`Visitas de ${pessoa.substring(0, 20)}`"
                    :sheetname="`Visitas de ${pessoa.substring(0, 20)}`"
                    class="ml-5 tbExcel"
                  >
                    <v-btn
                      icon
                      color="blue darken-2"
                      class="mr-5 mt-5"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </vue-excel-xlsx>
                </template>
                <span>Exportar para excel</span>
              </v-tooltip>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </div>
    <v-snackbar
      v-model="snackbar_error"
      color="error"
      multi-line="multi-line"
      :timeout="timeSnackbarError"
      bottom="bottom"
    >
      <v-icon small color="#FFF" class="mr-3">mdi-check</v-icon>
      {{ error }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "VisitasNew",
  computed: {
    ...mapState(["user"]),
  },
  data: () => ({
    emailAdicional: "",
    loadingMedicos: true,
    medico:null,
    itensMedicos: [],
    snackbar_success: false,
    timeSnackbarError: 7000,
    sucesso: null,
    awaitBanco: false,
    items: [],
    itemDaObs: [],
    dialogVerObs: false,
    chamada: "Salvando sua observação, aguarde...",
    vObs: false,
    obsVisita: "",
    idObsVisita: null,
    nomeVisita: "",
    snackbar_error: false,
    error: null,
    altura: 0,
    dtInicial: "",
    dtFinal: "",
    itensPessoas: [],
    loadVisitas: false,
    pessoa: "",
    loadingPessoas: true,
    visitas: [],
    headersVisitas: [
      { text: "Ações", value: "action" },
      { text: "Data", value: "ZZY_DATA" },
      { text: "Visitado", value: "A3_NOME" },
      { text: "Tipo", value: "ZZY_VISITA" },
      { text: "Status", value: "ZZY_CHECK" },
      { text: "Data Status", value: "ZZY_DTCHK" },
      { text: "Hora Status", value: "ZZY_HRCHK" },
      { text: "Visita", value: "ZZY_CONVER" },
      { text: "Prox. visita", value: "ZZY_PROXCO" },
    ],
    headersRelatExcel: [
      { label: "Data", field: "ZZY_DATA" },
      { label: "Visitado", field: "A3_NOME" },
      { label: "Tipo", field: "ZZY_VISITA" },
      { label: "Status", field: "ZZY_CHECK" },
      { label: "Data Status", field: "ZZY_DTCHK" },
      { label: "Hora Status", field: "ZZY_HRCHK" },
      { label: "Visita", field: "ZZY_CONVER" },
      { label: "Prox. visita", field: "ZZY_PROXCO" },
    ],
  }),
  methods: {
    filtraMedico(){
      console.log(this.medico)
      if(!this.medico){
        this.buscaVisitas()
        return
      }

      this.visitas = this.visitas.filter(e=> e.A3_NOME === this.medico)
    },
    async verObservacoes(item) {
      this.dialogVerObs = true;
      this.items = [];

      this.itemDaObs = item;

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS:
          "ZZV_ADMIN,ZZV_DATA,ZZV_HORA,CONVERT(VARCHAR(8000), ZZV_OBS) ZZV_OBS,USR_NOME",
        WHERE: `ZZV_ZZYID='${item.ZZY_ID}' ORDER BY ZZV.R_E_C_N_O_ DESC`,
        INNER: `INNER JOIN SYS_USR USR WITH(NOLOCK) ON USR_ID=ZZV_ADMIN AND USR.D_E_L_E_T_=' '`,
        TABELA: "ZZV",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      const observacoes = await axios.post(url, dados);

      if (observacoes.data.length > 0) {
        observacoes.data.map(e => {
          e.ZZV_DATA = `${e.ZZV_DATA.substr(6, 2)}/${e.ZZV_DATA.substr(
            4,
            2,
          )}/${e.ZZV_DATA.substr(0, 4)}`;
        });
        this.items = observacoes.data;
      } else {
        this.items = [
          {
            USR_NOME: "Sem Obsevações até o momento",
          },
        ];
      }
    },
    async incluiObsVisita() {
      if (this.obsVisita.trim() === "") return;
      this.awaitBanco = true;
      const dados = {
        ZZV_ZZYID: this.idObsVisita,
        ZZV_OBS: this.obsVisita,
        ZZV_ADMIN: this.user.usr_protheus,
        EMAILADC: this.emailAdicional
      };

      await axios
        .post(`${process.env.VUE_APP_BASE_API_URL}/incObsVisita`, dados)
        .then(res => {
          this.sucesso = res.data;
          this.snackbar_success = true;
        })
        .catch(err => {
          console.log(err);
        });
      this.awaitBanco = false;
      this.vObs = false;
      this.obsVisita = null;
      this.emailAdicional = ""
    },
    lancaObs(item) {
      this.idObsVisita = item.ZZY_ID;
      this.nomeVisita = item.A3_NOME;
      this.vObs = true;
      this.obsVisita = "";
    },
    async carregaPessoas() {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "A3_COD,A3_NOME",
        WHERE: `A3_ZTIPO IN ('000017','000021') AND A3_MSBLQL='2' ORDER BY A3_NOME`,
        TABELA: "SA3",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.itensPessoas = [
            {
              text: "",
              value: null,
              NOME: "",
            },
          ];
          res.data.forEach(e => {
            this.itensPessoas.push({
              text: e.A3_NOME,
              value: e.A3_COD,
            });
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
      this.loadingPessoas = false;
    },
    async buscaVisitas() {
      if (!this.dtInicial || !this.dtFinal) {
        this.snackbar_error = true;
        this.error = "Preencha uma data inicial e final!";
        return;
      }
      if (!this.pessoa) {
        this.snackbar_error = true;
        this.error = "Escolha uma pessoa!";
        return;
      }
      this.loadVisitas = true;
      this.visitas = [];
      const dtini = this.dtInicial.split("/");
      const dtfim = this.dtFinal.split("/");
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS:
          "(SELECT TOP 1 'X' FROM ZZV010 WHERE ZZV_ZZYID=ZZY_ID AND D_E_L_E_T_=' ') OBS,A3_NOME,ZZY_ID,ZZY_MEDICO,ZZY_VISITA,ZZY_DATA,CONVERT(VARCHAR(8000), ZZY_CONVER) ZZY_CONVER,CONVERT(VARCHAR(8000), ZZY_PROXCO) ZZY_PROXCO,ZZY_CHECK,ZZY_DTCHK,ZZY_HRCHK,ZZY_GESTOR",
        INNER: ` INNER JOIN SA3010 SA3 WITH(NOLOCK) ON 1=1 AND A3_COD=ZZY_MEDICO AND SA3.D_E_L_E_T_=' ' `,
        WHERE: `ZZY_PROSPE='${this.pessoa}' AND ZZY_DATA BETWEEN '${dtini[2]}${dtini[1]}${dtini[0]}' AND '${dtfim[2]}${dtfim[1]}${dtfim[0]}' ORDER BY ZZY_DATA`,
        TABELA: "ZZY",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.ZZY_DATA = `${e.ZZY_DATA.substr(6, 2)}/${e.ZZY_DATA.substr(
              4,
              2,
            )}/${e.ZZY_DATA.substr(0, 4)}`;
            e.ZZY_DTCHK = `${e.ZZY_DTCHK.substr(6, 2)}/${e.ZZY_DTCHK.substr(
              4,
              2,
            )}/${e.ZZY_DTCHK.substr(0, 4)}`;
          });
          this.visitas = res.data;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      this.loadVisitas = false;

      this.loadingMedicos = true 

      const medicos = [...new Set(this.visitas.map(e=> e.A3_NOME))]

      this.itensMedicos = [
            {
              text: "",
              value: null,
              NOME: "",
            },
          ];
      medicos.forEach(e=>{
        this.itensMedicos.push({
          text: e,
          value: e
        })
      })

      this.loadingMedicos = false
    },
  },
  mounted() {
    this.carregaPessoas();

    this.altura = window.screen.availHeight - 320;
  },
};
</script>

<style></style>
