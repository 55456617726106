import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import leadsKanban from "./modulos/leadskanban";
import menu from "./modulos/menu";
import metasOpimed from "./modulos/metasOpimed";
import premiacao from "./modulos/premiacao";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    leadsKanban,
    metasOpimed,
    premiacao,
    menu,
  },

  state: {
    dialogVersion: false,
    newVersion: null,
    user: null,
    titlePag: null,
    metadados: [],
    tabs: [],
    tabActive: "",
    menuColor: "#0FCBE1",
    dialogNull: false,
    mensagemDialogOps: null,
    dialogFollowUp: false,
    ID: null,
    Alias: null,
    stDrawer: false,
    stMini: true,
    lead_id: null,
    lead_nome: null,
    origem: null,
    isReaload: false,
    codLojaCliente: null,
    drawer: false,
    filtroTela: [],
    totalGeralLeads: null,
    anoMesRelatInvest: null,
    dialogError: false,
    FormsFacebook: [],
    cliente: null,
    ordem_servico: [],
    painelMedico: [],
    vincularOsAgenda: {
      job: false,
      os: null,
    },
  },

  mutations: {
    setOrdemServico(state, payload) {
      !payload === true
        ? (state.ordem_servico = [])
        : (state.ordem_servico[payload.TABELA] = payload.DADOS);
    },
    setCliente: (state, payload) => (state.cliente = payload),
    setFormsFace: (state, payload) => (state.FormsFacebook = payload),
    setPushFormFace: (state, payload) => state.FormsFacebook.push(payload),
    setPainelMedico: (state, payload) => (state.painelMedico = payload),
    setDialogError: (state, payload) => (state.dialogError = payload),
    setOrigem: (state, payload) => (state.origem = payload),
    setDrawer: (state, payload) => (state.drawer = payload),
    setTotalLeads(state, payload) {
      state.totalGeralLeads = payload;
    },
    setAnoMesRelatInvest(state, payload) {
      state.anoMesRelatInvest = payload;
    },
    SetCodLojaCliente(state, value) {
      state.codLojaCliente = value;
    },
    setFiltroTela(state, payload) {
      state.filtroTela = payload;
    },
    SetStDrawer(state, value) {
      state.stDrawer = value;
    },
    SetStMini(state, value) {
      state.stMini = value;
    },
    SetUser(state, user) {
      state.user = user;
      if (user) {
        axios.defaults.headers.common["Authorization"] = `bearer ${user.token}`;
      } else {
        delete axios.defaults.headers.common["Authorization"];
      }
    },
    setMenuColor(state, color) {
      state.menuColor = color;
    },
    SetId(state, value) {
      state.ID = value;
    },
    SetReload(state, value) {
      state.isReaload = value;
    },
    SetLead_id(state, value) {
      state.lead_id = value;
    },
    SetLead_nome(state, value) {
      state.lead_nome = value;
    },
    SetAlias(state, value) {
      state.Alias = value;
    },
    SetTitle(state, title) {
      state.titlePag = title;
    },
    SetMetadados(state, items) {
      state.metadados = items;
    },
    SetDialogFollowUp(state, value) {
      state.dialogFollowUp = value;
    },
    SetTabs(state, tab) {
      if (state.tabs.length <= 0) {
        //tabs vazia
        state.tabs.push(tab);
        state.tabActive = 0;
        return;
      } else {
        const verifica = state.tabs.filter(e => e.name === tab.name);
        if (verifica.length <= 0) {
          //nao encontrou
          state.tabs.push(tab);
          state.tabActive = state.tabs.length - 1;
          return;
        } else {
          //navega ate a aba
          state.tabActive = state.tabs.indexOf(verifica[0]);
        }
      }
    },
    SetLimpaTabs(state, value) {
      state.tabs = value;
    },
    removeTab(state, index) {
      if (index >= 0) state.tabs.splice(index, 1);
    },
    removeTabName(state, payload) {
      state.tabs = state.tabs.filter(e => e.name.trim() !== payload);
    },
    SetTabActive(state, value) {
      state.tabActive = value;
    },
    SetdialogNull(state, value) {
      state.dialogNull = value;
    },
    SetMensagemDialogOps(state, value) {
      state.mensagemDialogOps = value;
    },
    SetVincOsAgenda(state, payload) {
      state.vincularOsAgenda = payload;
    },
    SetVersion(state, payload) {
      state.dialogVersion = payload;
    },
    SetNewVersion(state, payload) {
      state.newVersion = payload;
    },
  },
});

export default store;
export const useStore = () => store;
