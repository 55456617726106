<template>
  <div class="content">
    <v-dialog v-model="dialogVerHorarios" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Horários Vagos - {{ dataTela }}</v-card-title
        >

        <v-card-text>
          <v-data-table
            :headers="headersTbHorasVagas"
            :items="aHorasDia"
            dense
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                color="green darken-2"
                @click="agendar(item.horaIni)"
                v-if="item.vago"
              >
                <v-icon>mdi-calendar-account-outline</v-icon>
              </v-btn>
              <v-chip
                class="ma-2"
                color="red"
                text-color="white"
                x-small
                v-if="!item.vago"
                >Agendado</v-chip
              >
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogVerHorarios = false"
            >fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetalhes" width="1100" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Detalhes do agendamento</v-card-title
        >
        <v-card-text>
          <v-row align="center">
            <v-col cols="4">
              <v-text-field
                dense
                label="Título"
                v-model="DetTitulo"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                label="Un. Negócio"
                v-model="DetUneg"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                label="Nr. Doc"
                v-model="DetNrDoc"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                label="Especialista"
                v-model="DetProf"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -10px">
            <v-col cols="4">
              <v-text-field
                dense
                label="Médico"
                v-model="DetMedico"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                label="Cliente"
                v-model="DetCliente"
                readonly
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="2">
              <v-text-field
                dense
                label="Hora Inicial"
                v-model="DetHoraIni"
                readonly
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="2">
              <v-text-field
                dense
                label="Hora Final"
                v-model="DetHoraFim"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -10px">
            <v-col cols="4">
              <v-text-field
                dense
                label="Telefone"
                v-model="DetTel"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                label="Telefone 2"
                v-model="DetTel2"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                label="Hospital"
                v-model="DetAssessor"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -10px">
            <v-col cols="12">
              <v-textarea dense v-model="DetobsAgenda" color="teal" rows="1">
                <template v-slot:label>
                  <div>Notas</div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-bottom: -15px; margin-top: -10px">
            <v-col cols="4" style="margin-top: -14px">
              <v-autocomplete
                dense
                v-model="status"
                :items="itensStatus"
                filled
                label="Status"
                background-color="white"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                label="Data"
                v-model="DetDtAgenda"
                readonly
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-col cols="2">
              <v-text-field
                dense
                label="Data Alteração"
                v-model="DetDtAlt"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                label="Usuário Alteração"
                v-model="DetUserAlt"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="transfer">
            <v-divider></v-divider>
            <span>Transferir Agenda</span>
            <v-row align="center" style="margin-top: -10px">
              <v-col cols="4">
                <v-text-field
                  v-model="dataTransfere"
                  label="Digite a nova data"
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="2">
                <v-text-field
                  dense
                  label="Hora Inicial"
                  v-model="transfereHini"
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="2">
                <v-text-field
                  dense
                  label="Hora Final"
                  v-model="transfereHfim"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" style="margin-top: -20px">
              <v-col cols="12">
                <v-textarea
                  dense
                  v-model="notaTransfere"
                  color="teal"
                  rows="1"
                  style="margin-top: -2px"
                >
                  <template v-slot:label>
                    <div>Nota</div>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" small dark @click="limpaVariaveis">fechar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="purple"
            small
            dark
            @click="transfereAgenda"
            :disabled="transferida || almoco"
            >Transferir Agenda</v-btn
          >
          <v-chip class="ma-2" color="red darken-3" dark v-if="transferida"
            >Esta agenda foi transferida!</v-chip
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            small
            dark
            @click="salvaAlteracao"
            :disabled="transferida || almoco"
            >Salvar Alteração</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAltStatus" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Alterar Status</v-card-title
        >
        <v-card-text>
          <v-row align="center">
            <v-row align="center">
              <v-col cols="8">
                <v-autocomplete
                  dense
                  v-model="status"
                  :items="itensStatus"
                  filled
                  label="Status"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="green" small dark @click="salvaAlteracao"
            >Salvar alteração</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="red" small dark @click="dialogAltStatus = false"
            >fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAgendar" width="1100" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Agendar Horário - {{ dataTela }}</v-card-title
        >

        <v-card-text>
          <v-row align="center">
            <v-col cols="4">
              <!--<v-text-field dense label="Título" v-model="titulo" autofocus></v-text-field>-->
              <v-autocomplete
                v-model="titulo"
                :items="aItensTitulo"
                dense
                filled
                label="Título"
                background-color="white"
                autofocus
                style="margin-top: -12px"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" style="margin-top: -11px">
              <v-autocomplete
                dense
                v-model="status"
                :items="itensStatus"
                filled
                label="Status"
                background-color="white"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                label="Nr. Doc"
                v-model="NrDoc"
              ></v-text-field>
            </v-col>
            <v-col cols="3" style="margin-top: -11px">
              <v-autocomplete
                dense
                v-model="profissional"
                :items="itensProf"
                :loading="loadingProf"
                filled
                label="Especialista"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -12px">
            <v-col cols="3" style="margin-top: -20px">
              <v-autocomplete
                dense
                :loading="loadingUnNeg"
                v-model="UnNeg"
                :items="itemsUnNeg"
                filled
                label="Unidade de Negócio"
                background-color="white"
              ></v-autocomplete>
            </v-col>
            <v-col cols="5" style="margin-top: -20px">
              <v-autocomplete
                dense
                :loading="loadingMed"
                v-model="Medico"
                :items="itemsMed"
                filled
                label="Médico"
                background-color="white"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" style="margin-top: -20px">
              <v-autocomplete
                dense
                :loading="loadingCliBase"
                v-model="cliente"
                :items="clienteBase"
                filled
                label="Cliente"
                @change="dadosCli"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -20px">
            <v-col class="d-flex" cols="12" sm="2">
              <v-select
                dense
                :items="itemsHini"
                label="Hora Inicial"
                v-model="horaAgendaIni"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="2">
              <v-select
                dense
                :items="itemsMini"
                label="Minutos"
                v-model="minutoAgendaIni"
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="12" sm="2">
              <v-select
                dense
                :items="itemsHFim"
                label="Hora Final"
                v-model="horaAgendaFim"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="2">
              <v-select
                dense
                :items="itemsMFim"
                label="Minutos"
                v-model="minutoAgendaFim"
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -20px">
            <v-col cols="4" sm="4" md="4">
              <v-text-field
                dense
                label="Telefone"
                v-model="telCliente"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-text-field
                dense
                label="Telefone 2"
                v-model="telCliente2"
              ></v-text-field>
            </v-col>
            <v-col cols="4" style="margin-top: -14px">
              <v-autocomplete
                dense
                :loading="loadingAssessor"
                v-model="agendaAssessor"
                :items="dbAssesor"
                filled
                label="Hospital"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" style="margin-top: -11px">
              <v-textarea dense v-model="obsAgenda" color="teal" rows="1">
                <template v-slot:label>
                  <div>Notas</div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" small dark @click="limpaVariaveis">fechar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" small dark @click="salvaAgenda">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogErrosAgenda" width="900">
      <v-card max-width="900" class="mx-auto">
        <v-card-title class="headline red lighten-2" style="color: white"
          >Erros ao salvar</v-card-title
        >

        <v-card-text>
          <v-list disabled>
            <v-subheader>Erros</v-subheader>
            <v-list-item-group v-model="aError" color="primary">
              <v-list-item v-for="(item, i) in aError" :key="i">
                <v-list-item-icon>
                  <v-icon color="red" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 0.8rem"
                    v-text="item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogErrosAgenda = false"
            >fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- coluna esquerda -->
    <div class="agenda_mes">
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="nameofdoctor"
            :items="nomes"
            :loading="loadTecnico"
            @change="buscaAgenda"
            dense
            filled
            label="Escolha a agenda"
            background-color="white"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="d-flex flex-row-reverse"
        style="margin-top: -30px"
      >
        <v-btn
          class="mx-2"
          x-small
          dark
          color="blue"
          @click="buscaAgenda"
          v-if="nameofdoctor && horarioDia.length > 0"
          >Renovar agenda</v-btn
        >
      </v-row>
      <p class="my-2">Tipo de visualização</p>
      <v-radio-group v-model="type" row @change="buscaAgenda" dense>
        <v-radio label="Categoria" value="category"></v-radio>
        <v-radio label="Semana" value="week"></v-radio>
        <v-radio label="Diário" value="day"></v-radio>
      </v-radio-group>
      <v-row class="d-flex ml-5 pb-4" align="center">
        <v-btn
          outlined
          x-small
          class="mr-4"
          color="grey darken-2"
          @click="setToday"
          >Hoje</v-btn
        >
        <v-toolbar-title
          v-if="$refs.calendar"
          style="text-transform: capitalize"
          class="ml-5"
          >{{ $refs.calendar.title }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn fab text small color="grey darken-2" @click="prevM">
          <v-icon small>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="nextM">
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
      <v-calendar
        ref="calendarM"
        type="month"
        :weekdays="weekday"
        :events="eventsMonth"
        :now="dataMes"
        color="primary"
        v-model="dataMes"
        locale="pt-BR"
        @change="buscaHoraAgenda"
      ></v-calendar>
    </div>
    <!-- fim coluna esquerda -->

    <!-- coluna direita -->
    <div class="agenda_dia">
      <v-toolbar flat color="white">
        <div class="chiphoras" v-if="type === 'category' || type === 'day'">
          <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            x-small
            v-if="horarioDia.length > 0"
            >Início: {{ horarioDia[0].horaIni }}</v-chip
          >
          <v-chip
            class="ma-2"
            color="orange"
            text-color="white"
            x-small
            v-if="horarioDia.length > 0"
            >Fim: {{ horarioDia[0].horaFim }}</v-chip
          >
          <v-chip
            class="ma-2"
            color="red"
            text-color="white"
            x-small
            v-if="horarioDia.length <= 0 && nameofdoctor"
            >Não trabalha</v-chip
          >
        </div>
        <v-spacer></v-spacer>
        <span>Legendas </span>

        <v-tooltip
          v-for="item in itensStatFiltro"
          :key="item.CODIGO"
          top
          :color="item.COLOR"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              :color="item.COLOR"
              width="20px"
              height="50%"
              class="mr-5 ml-3 filtrinho"
              tile
              @click="filtraLegenda(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegAgendado === item.CODIGO"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="filtroLegAgendado === item.CODIGO"
              ></v-badge>
            </v-sheet>
          </template>
          <span>{{ item.STATUS }}</span>
        </v-tooltip>

        <!-- <v-tooltip top color="cyan darken-1">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="cyan darken-1"
              width="20px"
              height="50%"
              class="mr-5 ml-3 filtrinho"
              tile
              @click="filtraLegenda('000005')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegAgendado"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="filtroLegAgendado"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Autorizado</span>
        </v-tooltip>

        <v-tooltip top color="grey darken-1">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="grey darken-1"
              width="20px"
              height="50%"
              class="mr-5 filtrinho"
              tile
              @click="filtraLegenda('000006')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegSemAgenda"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="filtroLegSemAgenda"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Aguardando Autorização</span>
        </v-tooltip>

        <v-tooltip top color="green darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="green darken-2"
              width="20px"
              height="50%"
              class="mr-5 filtrinho"
              tile
              @click="filtraLegenda('000007')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegCompareceu"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="filtroLegCompareceu"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Bonificação</span>
        </v-tooltip>
        <v-tooltip top color="red darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="red darken-2"
              width="20px"
              height="50%"
              class="mr-5 filtrinho"
              tile
              @click="filtraLegenda('000008')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegNCompareceu"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="filtroLegNCompareceu"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Demonstração</span>
        </v-tooltip>

        <v-tooltip top color="blue darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="blue darken-2"
              width="20px"
              height="50%"
              class="mr-5 filtrinho"
              tile
              @click="filtraLegenda('000009')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegComp"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="filtroLegComp"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Compareceu</span>
        </v-tooltip>

        <v-tooltip top color="purple darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="purple darken-2"
              width="20px"
              height="50%"
              class="mr-5 filtrinho"
              tile
              @click="filtraLegenda('000010')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegNComp"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="filtroLegNComp"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Não Compareceu</span>
        </v-tooltip>

        <v-tooltip top color="indigo darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="indigo darken-2"
              width="20px"
              height="50%"
              class="mr-5 filtrinho"
              tile
              @click="filtraLegenda('000011')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="filtroLegCanc"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="amber"
                :content="numberfilter"
                bottom
                v-if="filtroLegCanc"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Cancelado</span>
        </v-tooltip>

        <v-tooltip top color="amber darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              color="amber darken-2"
              width="20px"
              height="50%"
              class="mr-5 filtrinho"
              tile
              @click="filtraLegenda('000012')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                small
                color="white"
                style="margin-left: 3px"
                v-if="FiltroLegReag"
                >mdi-filter-plus</v-icon
              >
              <v-badge
                color="indigo"
                :content="numberfilter"
                bottom
                v-if="FiltroLegReag"
              ></v-badge>
            </v-sheet>
          </template>
          <span>Reagendado</span>
        </v-tooltip> -->

        <v-spacer></v-spacer>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
        <v-toolbar-title
          v-if="$refs.calendar"
          style="text-transform: capitalize"
          >{{ $refs.calendar.title }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn
          class="mx-5"
          fab
          x-small
          dark
          color="indigo"
          @click="agendaPlus"
          v-if="nameofdoctor && horarioDia.length > 0"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-calendar
        ref="calendar"
        v-model="dataMes"
        locale="pt-BR"
        color="primary"
        category-show-all
        :weekdays="weekday"
        :type="type"
        :first-time="first_time"
        interval-minutes="15"
        interval-height="40"
        :interval-count="interval_count"
        :event-color="getEventColor"
        :events="events"
        :categories="categories"
        :now="dataMes"
        @change="buscaHoraAgenda"
      >
        <template v-slot:category="{ category }">
          <span class="ml-3">{{ category }}</span>
          <v-btn
            class="mx-2"
            fab
            x-small
            dark
            color="error"
            @click="limpaFiltro"
          >
            <v-icon dark>mdi-eraser</v-icon>
          </v-btn>
          <!--<v-btn x-small color="error" text class="ml-6" @click="limpaFiltro" dark>limpar</v-btn>-->
          <v-row class="pa-2">
            <v-col cols="12" v-if="category === 'Un. de Neg.'">
              <v-autocomplete
                v-model="tipo"
                :items="itemsTipo"
                dense
                filled
                @change="filtroTipo('Un. de Neg.')"
                @click="carregaTipos('Un. de Neg.')"
                label="Filtrar"
                background-color="white"
                :loading="loadfiltro"
                no-data-text="Sem agendamento"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="category === 'Título'">
              <v-autocomplete
                v-model="paciente"
                :items="itemsTipo"
                @change="filtroTipo('Título')"
                @click="carregaTipos('Título')"
                dense
                filled
                label="Filtrar"
                background-color="white"
                no-data-text="Sem agendamento"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="category === 'Médico'">
              <v-autocomplete
                v-model="assessor"
                :items="itemsTipo"
                @change="filtroTipo('Médico')"
                @click="carregaTipos('Médico')"
                dense
                filled
                label="Filtrar"
                background-color="white"
                no-data-text="Sem agendamento"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="category === 'Hospital'">
              <v-autocomplete
                v-model="telefone"
                :items="itemsTipo"
                @change="filtroTipo('Hospital')"
                @click="carregaTipos('Hospital')"
                dense
                filled
                label="Filtrar"
                background-color="white"
                no-data-text="Sem agendamento"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="category === 'Especialista'">
              <v-autocomplete
                v-model="obs"
                :items="itemsTipo"
                dense
                filled
                label="Filtrar"
                background-color="white"
                no-data-text="Sem agendamento"
                @change="filtroTipo('Especialista')"
                @click="carregaTipos('Especialista')"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>
        <template v-slot:event="{ event }">
          <v-card
            outlined
            dense
            height="100%"
            :color="event.color"
            @click="verDados(event)"
          >
            <v-tooltip v-if="type === 'week'" right :color="event.color">
              <template v-slot:activator="{ on, attrs }">
                <v-card-text
                  style="
                    font-size: 0.7rem;
                    color: white;
                    padding: 0;
                    margin-left: 5px;
                    margin-right: 3px;
                  "
                  v-if="!event.icon"
                  class="d-flex align-content-center flex-wrap"
                  v-bind="attrs"
                  v-on="on"
                  >{{ event.name }}</v-card-text
                >
              </template>
              <span style="font-size: 0.7rem">{{ event.name }}</span>
            </v-tooltip>
            <v-card-text
              style="
                font-size: 0.7rem;
                color: white;
                padding: 0;
                margin-left: 5px;
                margin-right: 3px;
              "
              v-if="!event.icon || type === 'category' || type === 'day'"
              class="d-flex align-content-center flex-wrap"
              >{{ event.name }}</v-card-text
            >
            <v-row justify="center" class="mt-5">
              <v-icon v-if="event.icon" color="white" size="64">{{
                event.icon
              }}</v-icon>
            </v-row>
          </v-card>
        </template>
      </v-calendar>
      <v-dialog v-model="dialogAwaitAgenda" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitAgenda" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Aguarde montando a agenda...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAwaitDados" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitDados" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Aguarde atualizando dados da agenda...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAwaitSaveAgenda" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitSaveAgenda" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Salvando a agenda...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAwaitDetalhes" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitDetalhes" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Aguarde buscando detalhes do agendamento...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogSuccessSaveAgenda" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-text>{{ mensagem }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="limpaVariaveis">fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogSuccessalterAgenda" width="300" persistent>
        <v-card>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-text>Agenda alterada com sucesso!</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="limpaVariaveis">fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDesenvolve" width="300">
        <v-card>
          <v-card-title class="headline grey lighten-2">Agenda</v-card-title>
          <v-card-text>Em desenvolvimento</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogDesenvolve = false"
              >fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- fim coluna direita -->

    <snackBar :snack="snackbar_error" :error="error" />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
import snackBar from "../templates/snakBar_error";

export default {
  data: () => ({
    DetNrDoc: null,
    DetProf: null,
    loadingProf: true,
    itensProf: [],
    profissional: null,
    NrDoc: null,
    dialogAwaitDados: false,
    snackbar_error: false,
    error: null,
    loadingMed: true,
    Medico: null,
    itemsMed: [],
    loadingUnNeg: true,
    UnNeg: null,
    itemsUnNeg: [],
    almoco: false,
    transferida: false,
    transfereHini: null,
    transfereHfim: null,
    dataTransfere: null,
    notaTransfere: null,
    dialogDesenvolve: false,
    idSalvo: null,
    dialogSuccessalterAgenda: false,
    idALteracao: null,
    dialogAltStatus: false,
    DetStatus: null,
    status: "A - Agendamento",
    itensStatus: [],
    itensStatFiltro: [],
    NumOs: null,
    dialogAwaitDetalhes: false,
    DetNumOs: null,
    dialogDetalhes: false,
    DetTitulo: null,
    DetTransfere: null,
    DetCliente: null,
    DetMedico: null,
    DetUneg: null,
    DetHoraIni: null,
    DetHoraFim: null,
    DetTel: null,
    DetTel2: null,
    DetDtAgenda: null,
    DetAssessor: null,
    DetobsAgenda: null,
    DetDtAlt: null,
    DetUserAlt: null,
    ZZNBANCO: [],
    cloneAgenda: [],
    dialogSuccessSaveAgenda: false,
    mensagem: null,
    dialogAwaitSaveAgenda: false,
    dialogErrosAgenda: false,
    aError: [],
    obsAgenda: "",
    titulo: null,
    dadosCliente: [],
    aItensTitulo: ["OPME MAT/MED", "OPME PRESTAÇÃO DE SERVIÇO"],
    telCliente: null,
    telCliente2: null,
    clienteForaBase: null,
    minutoOne: false,
    minutoAgendaFim: null,
    horaAgendaFim: null,
    itemsHFim: null,
    loadingAssessor: true,
    dbAssesor: [],
    agendaAssessor: null,
    minutoAgendaIni: null,
    horaAgendaIni: null,
    hrIniAlm: null,
    hrFimAlm: null,
    itemsHini: [],
    itemsMini: [
      "00",
      "05",
      "10",
      "15",
      "20",
      "25",
      "30",
      "35",
      "40",
      "45",
      "50",
    ],
    itemsMFim: [
      "00",
      "05",
      "10",
      "15",
      "20",
      "25",
      "30",
      "35",
      "40",
      "45",
      "50",
    ],
    cliente: null,
    loadingCliBase: true,
    clienteBase: [],
    clienteNbase: false,
    dialogAgendar: false,
    headersTbHorasVagas: [
      { text: "Horário vago", value: "horaIni" },
      { text: "Agendar", value: "actions" },
    ],
    dialogVerHorarios: false,
    interval_count: null,
    first_time: "",
    interval_minutes: "",
    loadfiltro: false,
    dialogAwaitAgenda: false,
    eventsMonth: [],
    hoje: "",
    type: "category",
    filtrado: false,
    types: ["category", "week", "day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    loadTecnico: true,
    dataMes: "",
    dataTela: null,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    category: ["Development", "Meetings", "Slacking"],
    nomes: [],
    nameofdoctor: "",
    categories: ["Un. de Neg.", "Título", "Médico", "Hospital", "Especialista"],
    tipo: null,
    itemsTipo: [],
    paciente: null,
    itemsPaciente: [],
    assessor: null,
    itemsAssessor: [],
    telefone: null,
    itemsTelefone: [],
    obs: null,
    itemsObs: [],
    horarios: [],
    horarioDia: [],
    aHorasDia: [],
    filtroLegAgendado: false,
    filtroLegSemAgenda: false,
    filtroLegCompareceu: false,
    filtroLegNCompareceu: false,

    filtroLegDemo: false,
    filtroLegComp: false,
    filtroLegNComp: false,
    filtroLegCanc: false,
    FiltroLegReag: false,
    numberfilter: 0,
  }),
  components: { snackBar },
  computed: mapState(["user"]),
  methods: {
    filtraLegenda(tipo) {
      if (tipo.CODIGO === this.filtroLegAgendado) {
        this.filtroLegAgendado = null;
        // this.limpaFiltro()
      } else {
        this.filtroLegAgendado = tipo.CODIGO;
      }

      // if (tipo === "000005") {
      //   this.filtroLegAgendado = !this.filtroLegAgendado;
      //   this.filtroLegSemAgenda = false;
      //   this.filtroLegCompareceu = false;
      //   this.filtroLegNCompareceu = false;
      //   this.filtroLegComp= false
      //   this.filtroLegNComp= false
      //   this.filtroLegCanc= false
      //   this.FiltroLegReag= false

      //   this.limpaFiltro();
      // }

      // if (tipo === "000006") {
      //   this.filtroLegAgendado = false;
      //   this.filtroLegSemAgenda = !this.filtroLegSemAgenda;
      //   this.filtroLegCompareceu = false;
      //   this.filtroLegNCompareceu = false;
      //   this.filtroLegComp= false
      //   this.filtroLegNComp= false
      //   this.filtroLegCanc= false
      //   this.FiltroLegReag= false

      //   this.limpaFiltro();
      // }

      // if (tipo === "000007") {
      //   this.filtroLegAgendado = false;
      //   this.filtroLegSemAgenda = false;
      //   this.filtroLegCompareceu = !this.filtroLegCompareceu;
      //   this.filtroLegNCompareceu = false;
      //   this.filtroLegComp= false
      //   this.filtroLegNComp= false
      //   this.filtroLegCanc= false
      //   this.FiltroLegReag= false

      //   this.limpaFiltro();
      // }

      // if (tipo === "000008") {
      //   this.filtroLegAgendado = false;
      //   this.filtroLegSemAgenda = false;
      //   this.filtroLegCompareceu = false;
      //   this.filtroLegNCompareceu = !this.filtroLegNCompareceu;
      //   this.filtroLegComp= false
      //   this.filtroLegNComp= false
      //   this.filtroLegCanc= false
      //   this.FiltroLegReag= false

      //   this.limpaFiltro();
      // }
      // if (tipo === "000009") {
      //   this.filtroLegAgendado = false;
      //   this.filtroLegSemAgenda = false;
      //   this.filtroLegCompareceu = false;
      //   this.filtroLegNCompareceu = false
      //   this.filtroLegComp= !this.filtroLegComp
      //   this.filtroLegNComp= false
      //   this.filtroLegCanc= false
      //   this.FiltroLegReag= false

      //   this.limpaFiltro();
      // }
      // if (tipo === "000010") {
      //   this.filtroLegAgendado = false;
      //   this.filtroLegSemAgenda = false;
      //   this.filtroLegCompareceu = false;
      //   this.filtroLegNCompareceu = false
      //   this.filtroLegComp= false
      //   this.filtroLegNComp= !this.filtroLegNComp
      //   this.filtroLegCanc= false
      //   this.FiltroLegReag= false

      //   this.limpaFiltro();
      // }

      // if (tipo === "000011") {
      //   this.filtroLegAgendado = false;
      //   this.filtroLegSemAgenda = false;
      //   this.filtroLegCompareceu = false;
      //   this.filtroLegNCompareceu = false
      //   this.filtroLegComp= false
      //   this.filtroLegNComp= false
      //   this.filtroLegCanc= !this.filtroLegCanc
      //   this.FiltroLegReag= false

      //   this.limpaFiltro();
      // }
      // if (tipo === "000012") {
      //   this.filtroLegAgendado = false;
      //   this.filtroLegSemAgenda = false;
      //   this.filtroLegCompareceu = false;
      //   this.filtroLegNCompareceu = false
      //   this.filtroLegComp= false
      //   this.filtroLegNComp= false
      //   this.filtroLegCanc= false
      //   this.FiltroLegReag= !this.FiltroLegReag

      //   this.limpaFiltro();
      // }

      console.log(this.cloneAgenda);

      if (this.filtroLegAgendado) {
        this.cloneAgenda = this.events;

        let eventos = this.events;

        let data = this.dataMes.split("-");
        data = `${data[0]}${data[1]}${data[2]}`;

        eventos = eventos.filter(e => {
          if (this.type === "category") {
            // console.log(e.status)
            if (
              e.status === tipo.CODIGO &&
              e.data === data &&
              e.category === "Título"
            ) {
              return e.id;
            }
          } else {
            // console.log(e.status)
            if (e.status === tipo.CODIGO && e.data === data) {
              return e.id;
            }
          }
        });

        let filtro = [];

        eventos.forEach(e => {
          this.events.map(f => {
            if (f.id === e.id) {
              filtro.push(f);
            }
          });
        });

        this.numberfilter = eventos.length;

        this.events = filtro;
        this.filtrado = true;
      } else {
        this.events = [];
        this.events = this.cloneAgenda;

        // console.log(this.events)
        // this.cloneAgenda = this.events;
        //  let eventos = this.events;

        // let filtro = [];

        // eventos.forEach((e) => {
        //   this.events.map((f) => {
        //     if (f.id === e.id) {
        //       filtro.push(f);
        //     }
        //   });
        // });
        // this.numberfilter = eventos.length;

        // this.events = filtro;
        this.filtrado = true;
      }
    },
    async transfereAgenda() {
      this.aError = [];
      let dataEscolhida = null;
      let hIni = null;
      let hFim = null;
      if (!this.dataTransfere) {
        this.aError.push({
          text: "Preencha a nova data para transferir.",
          icon: "mdi-send",
        });
      } else {
        dataEscolhida = this.dataTransfere.split("/");

        if (
          dataEscolhida.length <= 0 ||
          dataEscolhida.length < 3 ||
          dataEscolhida[2].length < 4
        ) {
          this.aError.push({
            text: "Preencha a data corretamente. Ex: dd/mm/AAAA",
            icon: "mdi-send",
          });
        }
      }

      if (!this.transfereHini) {
        this.aError.push({
          text: "Preencha a hora inicial para transferir",
          icon: "mdi-send",
        });
      } else {
        hIni = this.transfereHini.split(":");

        if (
          hIni.length <= 0 ||
          hIni.length < 2 ||
          hIni[0].length < 2 ||
          hIni[1].length < 2
        ) {
          this.aError.push({
            text: "Preencha a hora inicial corretamente. Ex: 08:00",
            icon: "mdi-send",
          });
        }
      }

      if (!this.transfereHfim) {
        this.aError.push({
          text: "Preencha a hora final para transferir",
          icon: "mdi-send",
        });
      } else {
        hFim = this.transfereHfim.split(":");

        if (
          hFim.length <= 0 ||
          hFim.length < 2 ||
          hFim[0].length < 2 ||
          hFim[1].length < 2
        ) {
          this.aError.push({
            text: "Preencha a hora final corretamente. Ex: 08:00",
            icon: "mdi-send",
          });
        }
      }

      if (!this.notaTransfere) {
        this.aError.push({
          text: "Preencha a nota para transferir.",
          icon: "mdi-send",
        });
      }

      if (this.aError.length > 0) {
        this.dialogErrosAgenda = true;
        return;
      }

      let codCli = null;
      let lojaCli = null;

      let cli = null;

      if (this.DetCliente) {
        const cliente = this.DetCliente.split("-");

        cli = parseInt(cliente[0]);

        if (isNaN(cli)) {
          cli = cliente[0];
        } else {
          codCli = cliente[0];
          lojaCli = cliente[1];
          this.cliente = this.DetCliente;
          cli = null;
        }
      }

      const assessor = this.DetAssessor.split("-");

      const medico = this.DetMedico.split("-");

      this.agendaAssessor = this.DetAssessor;

      this.notaTransfere = this.notaTransfere.replaceAll("\n", " ");
      this.notaTransfere = this.removeAcento(this.notaTransfere);

      const dataAtual = new Date();
      const anoAtual = dataAtual.getFullYear().toString();
      let mesAtual = (dataAtual.getMonth() + 1).toString();
      let diaAtual = dataAtual.getDate().toString();

      if (mesAtual.length < 2) mesAtual = "0" + mesAtual;

      if (diaAtual.length < 2) diaAtual = "0" + diaAtual;

      let stat = this.status.split("-");

      let especialista = this.DetProf.split("-");

      const ZZN = {
        ZZN_TITULO: this.DetTitulo,
        ZZN_DTINI: `${dataEscolhida[2]}${dataEscolhida[1]}${dataEscolhida[0]}`,
        ZZN_DTFIN: `${dataEscolhida[2]}${dataEscolhida[1]}${dataEscolhida[0]}`,
        ZZN_HRINI: `${hIni[0]}:${hIni[1]}`,
        ZZN_HRFIN: `${hFim[0]}:${hFim[1]}`,
        ZZN_NOTAS: this.notaTransfere,
        ZZN_PESSOA: this.nameofdoctor.substring(0, 6),
        ZZN_CLIENT: codCli,
        ZZN_LOJA: lojaCli?.trim(),
        ZZN_STATUS: stat[0],
        ZZN_NOMECL: cli,
        ZZN_ESPECI: especialista[0],
        ZZN_UNEG: this.DetUneg,
        ZZN_VEND2: medico[0]?.trim(),
        ZZN_VEND1: assessor[0]?.trim(),
        ZZN_DTALT: `${anoAtual}${mesAtual}${diaAtual}`,
        ZZN_TEL1: this.DetTel ? this.DetTel?.trim() : null,
        ZZN_TEL2: this.DetTel2 ? this.DetTel2?.trim() : null,
        //ZZN_NUMOS: this.DetNumOs,
        ZZN_USALT: this.user.name.substr(0, 20),
        ZZN_IDPAI: this.idALteracao,
      };

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZZN,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/saveAgendaC`;

      let OK = false;

      this.dialogAwaitSaveAgenda = true;
      await axios
        .post(url, dados)
        .then(res => {
          if (res.data.STATUS === "true") {
            OK = true;
            this.ZZNBANCO = ZZN;
            this.ZZNBANCO.titulo = ZZN.ZZN_TITULO;
            this.ZZNBANCO.cliente = this.DetCliente;
            this.ZZNBANCO.telefone = `${ZZN.ZZN_TEL1} / ${ZZN.ZZN_TEL2}`;
            this.ZZNBANCO.assessor = this.DetAssessor;
            this.ZZNBANCO.dtAlt = ZZN.ZZN_DTALT;
            this.ZZNBANCO.usrAlt = ZZN.ZZN_USALT;
            this.ZZNBANCO.numOs = ZZN.ZZN_NUMOS;
            this.ZZNBANCO.horaIni = `${hIni[0]}:${hIni[1]}`;
            this.ZZNBANCO.horaFim = `${hFim[0]}:${hFim[1]}`;
            this.ZZNBANCO.medico = this.DetMedico;
            this.ZZNBANCO.uneg = this.DetUneg;
            this.ZZNBANCO.especialista = this.DetProf;
            this.ZZNBANCO.data = ZZN.ZZN_DTINI;
            this.ZZNBANCO.notas = ZZN.ZZN_NOTAS;
            this.ZZNBANCO.transferida = false;
            this.idSalvo = res.data.CODIGO;

            this.dialogDetalhes = false;
            this.mensagem = `Agenda transferida com o id ${res.data.CODIGO} na data: ${dataEscolhida[0]}/${dataEscolhida[1]}/${dataEscolhida[2]}`;
          } else {
            const err = res.data.ERRO.split("\n");
            console.log(err[5]);
            this.aError.push({ text: `${err[5]}`, icon: "mdi-send" });
            this.dialogAwaitSaveAgenda = false;
            this.dialogErrosAgenda = true;
            return;
          }
        })
        .catch(err => console.log(err));

      if (OK) {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZZN_ID: this.idALteracao,
          ZZN_STATUS: "000012",
          ZZN_DTALT: `${anoAtual}${mesAtual}${diaAtual}`,
          ZZN_USALT: this.user.name.substr(0, 20),
          ZZN_TRANSF: "S",
        };

        url = `${process.env.VUE_APP_BASE_API_URL}/delAgendaC`;

        let color = ``;

        await axios
          .put(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.DetStatus = "000012-Reagendado";
              color = "amber darken-2";
              this.status = "000012-Reagendado";
              this.DetDtAlt = `${diaAtual}/${mesAtual}/${anoAtual}`;
              this.DetUserAlt = this.user.name.substr(0, 20);
              this.dialogAltStatus = false;
            }
          })
          .catch(err => console.log(err));

        this.events.map(e => {
          if (e.id === this.idALteracao) {
            e.status = "000012";
            e.transferida = true;
            e.color = color;
          }
        });

        this.events = this.events.filter(e => e.id !== this.idALteracao);

        this.eventsMonth = [];

        url = `${process.env.VUE_APP_BASE_API_URL}/agendaC`;

        dados = {
          EMPRESA: `01`,
          FILIAL: `0101`,
          FIELDS: `DISTINCT(ZZN_DTINI)`,
          WHERE: `ZZN_PESSOA='${this.nameofdoctor.substr(0, 6)}'`,
        };

        let marcacao = [];

        await axios
          .post(url, dados)
          .then(res => {
            marcacao = res.data;
          })
          .catch(err => {
            console.log(err);
          });

        marcacao.map(e => {
          if (e.ZZN_DTINI.trim().length > 0) {
            let start = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
              4,
              2,
            )}-${e.ZZN_DTINI.substr(6, 2)}`;
            let end = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
              4,
              2,
            )}-${e.ZZN_DTINI.substr(6, 2)}`;
            this.eventsMonth.push({
              name: "Agenda",
              start: start,
              end: end,
            });
          }
        });

        this.dialogAwaitSaveAgenda = false;
        this.dialogSuccessSaveAgenda = true;
      }
    },
    async salvaAlteracao() {
      const dataAtual = new Date();
      const anoAtual = dataAtual.getFullYear().toString();
      let mesAtual = (dataAtual.getMonth() + 1).toString();
      let diaAtual = dataAtual.getDate().toString();

      if (mesAtual.length < 2) mesAtual = "0" + mesAtual;

      if (diaAtual.length < 2) diaAtual = "0" + diaAtual;

      if (this.DetobsAgenda) {
        this.DetobsAgenda = this.DetobsAgenda.replaceAll("\n", " ");
        this.DetobsAgenda = this.removeAcento(this.DetobsAgenda);
      }

      let status = this.status.split("-");

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZZN_ID: this.idALteracao,
        ZZN_STATUS: status[0],
        ZZN_DTALT: `${anoAtual}${mesAtual}${diaAtual}`,
        ZZN_USALT: this.user.name.substr(0, 20),
        ZZN_NOTAS: this.DetobsAgenda,
        ZZN_DOC: this.DetNrDoc.trim(),
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/agendaC`;

      let color = ``;

      if (status[0] === "000005") {
        color = "cyan darken-1";
      }
      if (status[0] === "000006") {
        color = "grey darken-1";
      }
      if (status[0] === "000007") {
        color = "green darken-2";
      }
      if (status[0] === "000008") {
        color = "red darken-2";
      }
      if (status[0] === "000009") {
        color = "blue darken-2";
      }
      if (status[0] === "000010") {
        color = "purple darken-2";
      }
      if (status[0] === "000011") {
        color = "indigo darken-2";
      }
      if (status[0] === "000012") {
        color = "amber darken-2";
      }

      this.dialogAwaitSaveAgenda = true;
      await axios
        .put(url, dados)
        .then(res => {
          if (res.data.STATUS === "true") {
            this.DetDtAlt = `${anoAtual}${mesAtual}${diaAtual}`;
            this.DetUserAlt = this.user.name.substr(0, 20);
            this.dialogAwaitSaveAgenda = false;
            this.dialogDetalhes = false;
            this.dialogSuccessalterAgenda = true;
            this.dialogAltStatus = false;
          }
        })
        .catch(err => console.log(err));

      this.events.map(e => {
        if (this.type === "category") {
          if (e.id === this.idALteracao) {
            //console.log(`${status[0]}-${color}-${this.DetobsAgenda}`);
            e.status = status[0];
            e.color = color;
            e.notas = this.DetobsAgenda;
            e.NrDoc = this.DetNrDoc;
          }
        } else {
          if (e.id === this.idALteracao) {
            e.status = status[0];
            e.color = color;
            e.notas = this.DetobsAgenda;
            e.NrDoc = this.DetNrDoc;
          }
        }
      });
    },
    async verDados(event) {
      this.dialogAwaitDetalhes = true;
      this.dialogDetalhes = false;

      this.transferida = event.transferida;

      if (event.almoco) {
        this.almoco = event.almoco;
      }
      let telefone = event.telefone
        ? event.telefone.split("/")
        : (telefone = ["", ""]);
      this.DetTitulo = event.titulo;
      this.DetCliente = event.cliente;
      this.DetTel = telefone[0] ? telefone[0].trim() : null;
      this.DetTel2 = telefone[1] ? telefone[1].trim() : null;
      this.DetHoraIni = event.horaIni;
      this.DetHoraFim = event.horaFim;
      this.DetAssessor = event.hospital ? event.hospital : event.assessor;
      this.DetMedico = event.medico;
      this.DetUneg = event.uneg;
      this.DetNrDoc = event.NrDoc;
      this.DetProf = event.especialista
        ? event.especialista
        : event.profissional;
      this.agendaAssessor = event.assessor;
      this.DetobsAgenda = event.notas;
      this.DetNumOs = event.numOs;
      this.DetDtAlt = `${event.dtAlt.substr(6, 2)}/${event.dtAlt.substr(
        4,
        2,
      )}/${event.dtAlt.substr(0, 4)}`;
      this.DetUserAlt = event.usrAlt;
      this.DetDtAgenda = `${event.data.substr(6, 2)}/${event.data.substr(
        4,
        2,
      )}/${event.data.substr(0, 4)}`;

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZZO_COD,ZZO_NOME",
        WHERE: `ZZO_AGENDA='C'`,
        TABELA: "ZZO",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.itensStatus = res.data.map(e => {
            return `${e.ZZO_COD}-${e.ZZO_NOME.trim()}`;
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
      if (event.status === "000005") {
        this.status = "000005-Autorizado";
      }
      if (event.status === "000006") {
        this.status = "000006-Aguardando Autorização";
      }
      if (event.status === "000007") {
        this.status = "000007-Bonificação";
      }
      if (event.status === "000008") {
        this.status = "000008-Demonstração";
      }
      if (event.status === "000009") {
        this.status = "000009-Compareceu";
      }
      if (event.status === "000010") {
        this.status = "000010-Não Compareceu";
      }
      if (event.status === "000011") {
        this.status = "000011-Cancelado";
      }
      if (event.status === "000012") {
        this.status = "000012-Reagendado";
      }

      this.idALteracao = event.id;

      this.dialogDetalhes = true;

      this.transfereHini = event.horaIni;
      this.transfereHfim = event.horaFim;
      this.notaTransfere = event.notas;

      this.dialogAwaitDetalhes = false;
    },
    limpaFiltro() {
      if (this.cloneAgenda.length > 0) {
        this.events = this.cloneAgenda;
      }
      this.itemsTipo = [];
      this.tipo = ``;
      this.paciente = ``;
      this.assessor = ``;
      this.telefone = ``;
      this.obs = ``;
      this.cloneAgenda = [];
    },
    async agendaPlus() {
      this.dialogAgendar = true;
      this.loadingUnNeg = true;
      this.loadingMed = true;
      this.loadingProf = true;

      const dataAtual = this.dataMes.split("-");

      this.dataTela = dataAtual[2] + "/" + dataAtual[1] + "/" + dataAtual[0];

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZZO_COD,ZZO_NOME",
        WHERE: `ZZO_AGENDA='C'`,
        TABELA: "ZZO",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.itensStatus = res.data.map(e => {
            return `${e.ZZO_COD}-${e.ZZO_NOME.trim()}`;
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      this.status = "000005-Autorizado";

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZZK_COD,ZZK_NOME",
        WHERE: `ZZK_MSBLQL <> '1' AND ZZK_TIPO = 'E'`,
        TABELA: "ZZK",
      };

      await axios
        .post(url, dados)
        .then(res => {
          this.itensProf = res.data.map(e => {
            return `${e.ZZK_COD.trim()}-${e.ZZK_NOME.trim()}`;
          });
        })
        .catch(err => console.log(err));

      this.loadingProf = false;

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZGA_UNIDAD,ZGA_DESC",
        WHERE: `ZGA_MSBLQL='2'`,
        TABELA: "ZGA",
      };

      await axios
        .post(url, dados)
        .then(res => {
          this.itemsUnNeg = res.data.map(e => {
            return `${e.ZGA_UNIDAD.trim()}-${e.ZGA_DESC.trim()}`;
          });
        })
        .catch(err => console.log(err));

      this.loadingUnNeg = false;

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "A3_COD,A3_NOME",
        WHERE: `A3_MSBLQL='2'`,
        TABELA: "SA3",
      };

      await axios
        .post(url, dados)
        .then(res => {
          this.itemsMed = res.data.map(e => {
            return `${e.A3_COD.trim()}-${e.A3_NOME.trim()}`;
          });
        })
        .catch(err => console.log(err));

      this.loadingMed = false;
      this.agendar();
    },
    async limpaVariaveis() {
      this.dialogSuccessSaveAgenda = false;
      this.dialogAgendar = false;
      this.dialogVerHorarios = false;
      this.dialogDetalhes = false;
      this.dialogDetalhes = false;
      this.dialogSuccessalterAgenda = false;
      this.Medico = null;
      this.UnNeg = null;

      if (this.idSalvo) {
        const allDay = this.rnd(0, 3) === 0;

        let color = ``;

        if (this.type === "day" || this.type === "week") {
          if (
            this.ZZNBANCO.ZZN_DTINI.trim().length > 0 &&
            this.ZZNBANCO.ZZN_DTFIN.trim().length > 0 &&
            this.ZZNBANCO.ZZN_HRINI.trim().length === 5 &&
            this.ZZNBANCO.ZZN_HRFIN.trim().length === 5
          ) {
            let start = `${this.ZZNBANCO.ZZN_DTINI.substr(
              0,
              4,
            )}-${this.ZZNBANCO.ZZN_DTINI.substr(
              4,
              2,
            )}-${this.ZZNBANCO.ZZN_DTINI.substr(6, 2)} ${
              this.ZZNBANCO.ZZN_HRINI
            }`;
            let end = `${this.ZZNBANCO.ZZN_DTFIN.substr(
              0,
              4,
            )}-${this.ZZNBANCO.ZZN_DTFIN.substr(
              4,
              2,
            )}-${this.ZZNBANCO.ZZN_DTFIN.substr(6, 2)} ${
              this.ZZNBANCO.ZZN_HRFIN
            }`;

            color = this.itensStatFiltro.filter(
              e => e.CODIGO === this.ZZNBANCO.ZZN_STATUS,
            )[0].COLOR;

            console.log(color);
            // if (this.ZZNBANCO.ZZN_STATUS === "000005") {
            //   color = "cyan darken-1";
            // }
            // if (this.ZZNBANCO.ZZN_STATUS === "000006") {
            //   color = "grey darken-1";
            // }
            // if (this.ZZNBANCO.ZZN_STATUS === "000007") {
            //   color = "green darken-2";
            // }
            // if (this.ZZNBANCO.ZZN_STATUS === "000008") {
            //   color = "red darken-2";
            // }
            // if (this.ZZNBANCO.ZZN_STATUS === "000009") {
            //   color = "blue darken-2";
            // }
            // if (this.ZZNBANCO.ZZN_STATUS === "000010") {
            //   color = "purple darken-2";
            // }
            // if (this.ZZNBANCO.ZZN_STATUS === "000011") {
            //   color = "indigo darken-2";
            // }
            // if (this.ZZNBANCO.ZZN_STATUS === "000012") {
            //   color = "amber darken-2";
            // }
            let hospital = this.ZZNBANCO.assessor.split("-");
            let medico = this.ZZNBANCO.medico.split("-");

            let especialista = this.ZZNBANCO.especialista.split("-");

            this.events.push({
              id: this.idSalvo,
              name: `Un. de Neg.: ${this.ZZNBANCO.uneg} | Título:${this.ZZNBANCO.titulo} | Médico:${medico[1]} | Hospital:${hospital[1]} | Especialista: ${especialista[1]} `,
              start: start,
              end: end,
              timed: !allDay,
              color: color,
              status: this.ZZNBANCO.ZZN_STATUS,
              titulo: this.ZZNBANCO.titulo,
              cliente: this.ZZNBANCO.cliente,
              telefone: this.ZZNBANCO.telefone,
              assessor: this.ZZNBANCO.assessor,
              hospital: this.ZZNBANCO.hospital,
              NrDoc: this.ZZNBANCO.NrDoc,
              especialista: this.profissional
                ? this.profissional
                : this.ZZNBANCO.especialista,
              uneg: this.ZZNBANCO.uneg,
              medico: this.ZZNBANCO.medico,
              dtAlt: this.ZZNBANCO.dtAlt,
              usrAlt: this.ZZNBANCO.usrAlt,
              numOs: this.ZZNBANCO.ZZN_NUMOS,
              horaIni: this.ZZNBANCO.horaIni,
              horaFim: this.ZZNBANCO.horaFim,
              data: this.ZZNBANCO.data,
              notas: this.ZZNBANCO.notas,
              transferida: this.ZZNBANCO.transferida ? true : false,
            });
          }
        }

        if (this.type === "category") {
          this.itemsTipo = [];
          this.tipo = "";
          if (
            this.ZZNBANCO.ZZN_DTINI.trim().length > 0 &&
            this.ZZNBANCO.ZZN_DTFIN.trim().length > 0 &&
            this.ZZNBANCO.ZZN_HRINI.trim().length === 5 &&
            this.ZZNBANCO.ZZN_HRFIN.trim().length === 5
          ) {
            let start = `${this.ZZNBANCO.ZZN_DTINI.substr(
              0,
              4,
            )}-${this.ZZNBANCO.ZZN_DTINI.substr(
              4,
              2,
            )}-${this.ZZNBANCO.ZZN_DTINI.substr(6, 2)} ${
              this.ZZNBANCO.ZZN_HRINI
            }`;
            let end = `${this.ZZNBANCO.ZZN_DTFIN.substr(
              0,
              4,
            )}-${this.ZZNBANCO.ZZN_DTFIN.substr(
              4,
              2,
            )}-${this.ZZNBANCO.ZZN_DTFIN.substr(6, 2)} ${
              this.ZZNBANCO.ZZN_HRFIN
            }`;

            let hospital = this.ZZNBANCO.assessor.split("-");
            let medico = this.ZZNBANCO.medico.split("-");
            let especialista = this.profissional
              ? this.profissional.split("-")
              : this.ZZNBANCO.especialista.split("-");

            this.categories.forEach(c => {
              let name = ``;
              if (c === "Un. de Neg.") {
                name = this.ZZNBANCO.uneg;
              }
              if (c === "Título") {
                name = this.ZZNBANCO.titulo;
              }
              if (c === "Médico") {
                name = medico[1];
              }
              if (c === "Hospital") {
                name = hospital[1];
              }
              if (c === "Especialista") {
                name = especialista[1];
              }
              if (this.ZZNBANCO.ZZN_STATUS === "000005") {
                color = "cyan darken-1";
              }
              if (this.ZZNBANCO.ZZN_STATUS === "000006") {
                color = "grey darken-1";
              }
              if (this.ZZNBANCO.ZZN_STATUS === "000007") {
                color = "green darken-2";
              }
              if (this.ZZNBANCO.ZZN_STATUS === "000008") {
                color = "red darken-2";
              }
              if (this.ZZNBANCO.ZZN_STATUS === "000009") {
                color = "blue darken-2";
              }
              if (this.ZZNBANCO.ZZN_STATUS === "000010") {
                color = "purple darken-2";
              }
              if (this.ZZNBANCO.ZZN_STATUS === "000011") {
                color = "indigo darken-2";
              }
              if (this.ZZNBANCO.ZZN_STATUS === "000012") {
                color = "amber darken-2";
              }
              this.events.push({
                id: this.idSalvo,
                name: name,
                start: start,
                end: end,
                timed: !allDay,
                category: c,
                color: color,
                status: this.ZZNBANCO.ZZN_STATUS,
                titulo: this.ZZNBANCO.titulo,
                cliente: this.ZZNBANCO.cliente,
                telefone: this.ZZNBANCO.telefone,
                assessor: this.ZZNBANCO.assessor,
                dtAlt: this.ZZNBANCO.dtAlt,
                usrAlt: this.ZZNBANCO.usrAlt,
                numOs: this.ZZNBANCO.ZZN_NUMOS,
                NrDoc: this.ZZNBANCO.NrDoc,
                especialista: this.profissional
                  ? this.profissional
                  : this.ZZNBANCO.especialista,
                uneg: this.ZZNBANCO.uneg,
                hospital: this.ZZNBANCO.hospital,
                medico: this.ZZNBANCO.medico,
                horaIni: this.ZZNBANCO.horaIni,
                horaFim: this.ZZNBANCO.horaFim,
                data: this.ZZNBANCO.data,
                notas: this.ZZNBANCO.notas,
              });
            });
          }
        }
      }

      this.ZZNBANCO = [];
      this.titulo = null;
      this.cliente = null;
      this.clienteForaBase = null;
      this.clienteNbase = false;
      this.horaAgendaIni = null;
      this.horaAgendaFim = null;
      this.minutoAgendaFim = null;
      this.minutoAgendaIni = null;
      this.telCliente = null;
      this.telCliente2 = null;
      this.agendaAssessor = null;
      this.obsAgenda = null;
      this.NumOs = null;
      this.transfereHini = null;
      this.dataTransfere = null;
      this.transfereHfim = null;
      this.notaTransfere = null;
      this.transferida = false;
      this.idSalvo = null;
      this.transferida = false;
      this.almoco = false;
    },
    async salvaAgenda() {
      this.ZZN_BANCO = [];
      this.dialogErrosAgenda = false;
      this.aError = [];
      if (!this.titulo || this.titulo.trim().length <= 0) {
        this.aError.push({
          text: "Preencha o título para o agendamento!",
          icon: "mdi-send",
        });
      }

      if (!this.status || this.titulo.trim().length <= 0) {
        this.aError.push({
          text: "Preencha o Status para o agendamento",
          icon: "mdi-send",
        });
      }

      if (!this.profissional) {
        this.aError.push({
          text: "Digite o Profissional para o agendamento",
          icon: "mdi-send",
        });
      }

      if (!this.UnNeg || this.UnNeg.trim().length <= 0) {
        this.aError.push({
          text: "Preencha a Unidade de Negócio para o agendamento",
          icon: "mdi-send",
        });
      }

      if (
        !this.horaAgendaIni ||
        !this.minutoAgendaIni ||
        !this.horaAgendaFim ||
        !this.minutoAgendaFim
      ) {
        this.aError.push({
          text: "Digite o horário inicial e final do agendamento!",
          icon: "mdi-send",
        });
      }
      if (!this.agendaAssessor) {
        this.aError.push({
          text: "Escolha o indicador para o agendamento!",
          icon: "mdi-send",
        });
      }
      if (!this.obsAgenda) {
        this.aError.push({
          text: "Digite a nota para o agendamento",
          icon: "mdi-send",
        });
      }

      if (this.aError.length > 0) {
        this.dialogErrosAgenda = true;
        return;
      }

      const data = this.dataTela.split("/");

      if (this.obsAgenda) {
        this.obsAgenda = this.obsAgenda.replaceAll("\n", " ");
        this.obsAgenda = this.removeAcento(this.obsAgenda);
      }

      let cliente = null;
      let loja = null;
      let clienteBaseS = null;
      let clienteBase = null;
      let cliZZNbanco = null;
      if (this.cliente) {
        if (this.clienteNbase) {
          cliente = this.clienteForaBase;
          cliZZNbanco = cliente;
        } else {
          clienteBaseS = this.cliente.split("-");
          clienteBase = clienteBaseS[0];
          loja = clienteBaseS[1];
          cliZZNbanco = this.cliente;
        }
      }

      const dataAtual = new Date();
      const anoAtual = dataAtual.getFullYear().toString();
      let mesAtual = (dataAtual.getMonth() + 1).toString();
      let diaAtual = dataAtual.getDate().toString();

      if (mesAtual.length < 2) mesAtual = "0" + mesAtual;

      if (diaAtual.length < 2) diaAtual = "0" + diaAtual;

      let status = this.status.split("-");

      //let unidade = this.UnNeg.split("-");

      let especialista = this.profissional.split("-");

      const ZZN = {
        ZZN_TITULO: this.removeAcento(this.titulo.substr(0, 30)),
        ZZN_DTINI: `${data[2]}${data[1]}${data[0]}`,
        ZZN_DTFIN: `${data[2]}${data[1]}${data[0]}`,
        ZZN_HRINI: `${this.horaAgendaIni}:${this.minutoAgendaIni}`,
        ZZN_HRFIN: `${this.horaAgendaFim}:${this.minutoAgendaFim}`,
        ZZN_NOTAS: this.obsAgenda ? this.obsAgenda : null,
        ZZN_PESSOA: this.nameofdoctor.substring(0, 6),
        ZZN_CLIENT: clienteBase,
        ZZN_LOJA: loja ? loja.trim() : null,
        ZZN_STATUS: status[0],
        ZZN_NOMECL: cliente,
        ZZN_VEND1: this.agendaAssessor.substr(0, 6),
        ZZN_VEND2: this.Medico.substr(0, 6),
        ZZN_DOC: this.NrDoc,
        ZZN_ESPECI: especialista[0],
        ZZN_DTALT: `${anoAtual}${mesAtual}${diaAtual}`,
        ZZN_TEL1: this.telCliente ? this.telCliente : this.DetTel,
        ZZN_TEL2: this.telCliente2 ? this.telCliente2 : this.DetTel2,
        ZZN_UNEG: this.UnNeg.trim(),
        ZZN_USALT: this.user.name.substr(0, 20),
      };

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZZN,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/saveAgendaC`;

      this.dialogAwaitSaveAgenda = true;

      await axios
        .post(url, dados)
        .then(res => {
          if (res.data.STATUS === "true") {
            this.ZZNBANCO = ZZN;
            this.ZZNBANCO.titulo = ZZN.ZZN_TITULO;
            this.ZZNBANCO.cliente = cliZZNbanco;
            this.ZZNBANCO.telefone = ZZN.ZZN_TEL1;
            this.ZZNBANCO.telefone2 = ZZN.ZZN_TEL2;
            this.ZZNBANCO.assessor = this.agendaAssessor;
            this.ZZNBANCO.dtAlt = ZZN.ZZN_DTALT;
            this.ZZNBANCO.usrAlt = ZZN.ZZN_USALT;
            //this.ZZNBANCO.numOs = ZZN.ZZN_NUMOS;
            this.ZZNBANCO.uneg = this.UnNeg.trim();
            this.ZZNBANCO.medico = this.Medico;
            this.ZZNBANCO.especialista = this.profissional;
            this.ZZNBANCO.NrDoc = this.NrDoc;
            this.ZZNBANCO.hospital = this.agendaAssessor;
            this.ZZNBANCO.horaIni = `${this.horaAgendaIni}:${this.minutoAgendaIni}`;
            this.ZZNBANCO.horaFim = `${this.horaAgendaFim}:${this.minutoAgendaFim}`;
            this.ZZNBANCO.data = ZZN.ZZN_DTINI;
            this.ZZNBANCO.notas = ZZN.ZZN_NOTAS;
            this.ZZNBANCO.transferida = false;

            this.idSalvo = res.data.CODIGO;
            this.mensagem = `Agenda salva com o id ${res.data.CODIGO}`;
            this.dialogAgendar = false;
            this.dialogAwaitSaveAgenda = false;
            this.dialogSuccessSaveAgenda = true;
          } else {
            const err = res.data.ERRO.split("\n");
            this.aError.push({ text: `${err[5]}`, icon: "mdi-send" });
            this.dialogAwaitSaveAgenda = false;
            this.dialogErrosAgenda = true;
            return;
          }
        })
        .catch(err => console.log(err));
    },
    //Função para remover caracteres especiais na Notas
    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");
      return text.toUpperCase();
    },
    async agendar(horaEscolhida) {
      this.buscaAssessor();
      this.minutoOne = true;

      if (horaEscolhida) {
        this.itemsHini = this.aHorasDia.map(e => {
          return e.horaIni.substr(0, 2);
        });
        this.horaAgendaIni = horaEscolhida.substr(0, 2);
        this.minutoAgendaIni = horaEscolhida.substr(3, 2);

        this.itemsHFim = this.aHorasDia.map(e => {
          return e.horaIni.substr(0, 2);
        });
        this.horaAgendaFim = horaEscolhida.substr(0, 2);

        let minuto = parseFloat(horaEscolhida.substr(3, 2));
        let hora = parseFloat(horaEscolhida.substr(0, 2));

        minuto += 10;
        if (minuto === 60) {
          hora++;
          if (hora < 10) {
            hora = `0${hora}`;
          }
          this.horaAgendaFim = `${hora}`;
          this.minutoAgendaFim = `00`;
        } else {
          this.minutoAgendaFim = `${minuto}`;
        }
      } else {
        const horaini = parseInt(this.horarioDia[0].horaIni.substr(0, 2));
        const horafim = parseInt(this.horarioDia[0].horaFim.substr(0, 2));

        let horas = [];

        for (let i = horaini; i <= horafim; i++) {
          if (i < 10) {
            horas.push(`0${i}`);
          } else {
            horas.push(`${i}`);
          }
        }

        this.itemsHini = horas;
        this.itemsHFim = horas;
      }

      this.loadingCliBase = true;
      this.dialogAgendar = true;
      const dados = {
        EMPRESA: "0101",
        FILIAL: "01",
        FIELDS: "A1_COD,A1_LOJA,A1_NOME,A1_DDD,A1_TEL,A1_CONTATO",
        WHERE: `A1_MSBLQL='2' ORDER BY A1_NOME`,
      };

      this.cliente = null;

      const url = `${process.env.VUE_APP_BASE_API_URL}/clientes`;

      await axios
        .post(url, dados)
        .then(res => {
          this.clienteBase = res.data.map(e => {
            return `${e.A1_COD}-${e.A1_LOJA} - ${e.A1_NOME}`;
          });
          this.dadosCliente = res.data;
        })
        .catch(err => console.log(err));

      this.loadingCliBase = false;
    },
    async buscaAssessor() {
      this.loadingAssessor = true;
      const dados = {
        EMPRESA: "0101",
        FILIAL: "01",
        FIELDS: "A3_COD,A3_NOME",
        WHERE: `A3_MSBLQL='2' ORDER BY A3_NOME`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/assessor`;

      await axios
        .post(url, dados)
        .then(res => {
          this.dbAssesor = res.data.map(e => {
            return `${e.A3_COD} - ${e.A3_NOME}`;
          });
        })
        .catch(err => console.log(err));
      this.loadingAssessor = false;
    },
    dadosCli() {
      this.dadosCliente.forEach(e => {
        if (e.A1_COD.trim() === this.cliente.substr(0, 6)) {
          this.telCliente = `(${e.A1_DDD}) ${e.A1_TEL}`;
        }
      });
    },
    somaMinutos() {
      let hora = parseFloat(this.horaAgendaFim);
      let minutos = parseFloat(this.minutoAgendaFim);

      minutos += 10;

      if (minutos === 60) {
        hora++;
        if (hora < 10) {
          hora = `0${hora}`;
        }
        this.minutoAgendaFim = `00`;
        this.horaAgendaFim = `${hora}`;
      } else {
        this.minutoAgendaFim = `${minutos}`;
      }
    },
    async verHoraVaga() {
      const data = this.dataMes.replaceAll("-", "");

      const agendaDia = this.events.filter(e => {
        if (this.type === "category") {
          if (e.data === data && e.category === "Titulo") {
            return e;
          }
        } else {
          if (e.data === data) {
            return e;
          }
        }
      });

      const horaIniDia = moment(this.horarioDia[0].horaIni, "HH:mm");
      const horaFimDia = moment(this.horarioDia[0].horaFim, "HH:mm");

      let msDia = horaFimDia - horaIniDia;

      const HoraNvaga = [];

      let somaminutos = 0;

      for (msDia; msDia > 0; ) {
        HoraNvaga.push({
          horaIni: moment(horaIniDia, "HH:mm")
            .add(somaminutos, "minutes")
            .format("HH:mm"),
          vago: true,
        });
        msDia -= 300000;
        somaminutos += 5;
      }

      somaminutos = 0;

      agendaDia.forEach(e => {
        const horaIni = moment(e.horaIni, "HH:mm");
        const horaFim = moment(e.horaFim, "HH:mm");
        let ms = horaFim - horaIni;

        for (ms; ms > 0; ) {
          const horaini = moment(e.horaIni, "HH:mm")
            .add(somaminutos, "minutes")
            .format("HH:mm");
          HoraNvaga.map(f => {
            if (f.horaIni === horaini) {
              f.vago = false;
            }
          });
          ms -= 300000;
          somaminutos += 5;
        }
      });

      this.aHorasDia = HoraNvaga;
      this.aHorasDia = HoraNvaga;

      this.dialogVerHorarios = true;
    },
    setToday() {
      this.focus = "";
      const data = new Date();
      const ano = data.getFullYear().toString();
      let mes = (data.getMonth() + 1).toString();
      let dia = data.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      this.dataMes = `${ano}-${mes}-${dia}`;

      this.dataTela = dia + `/` + mes + `/` + ano;

      this.date = this.dataTela;
    },
    async prev() {
      this.$refs.calendar.prev();
      await this.buscaHoraAgenda();
    },
    async atualizaAgendaDia() {
      if (!this.nameofdoctor) {
        return;
      }

      const data = this.dataMes.split("-");

      const dataBanco = `${data[0]}${data[1]}${data[2]}`;

      let agendaDiaTela = [];

      if (this.cloneAgenda.length > 0) {
        this.events = this.cloneAgenda;
        this.cloneAgenda = [];
      }

      agendaDiaTela = this.events.filter(e => {
        if (this.type === "category") {
          if (e.data === dataBanco && e.category === "Título") {
            return { ...e.recno };
          }
        } else {
          if (e.data === dataBanco) {
            return { ...e.recno };
          }
        }
      });

      let recnos = ``;

      for (let i = 0; i < agendaDiaTela.length; i++) {
        if (i === agendaDiaTela.length - 1) {
          recnos += `'${agendaDiaTela[i].recno}'`;
        } else {
          recnos += `'${agendaDiaTela[i].recno}',`;
        }
      }

      if (recnos) {
        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `ZZN_ID,ZZN_UNEG,ZZN_VEND2,ZZN_TITULO,ZZN_DTINI,ZZN_DTFIN,ZZN_HRINI,ZZN_HRFIN,CONVERT(VARCHAR(1000), ZZN_NOTAS) AS NOTAS,ZZN_CLIENT,ZZN_LOJA,A1_COD,A1_LOJA,A1_NOME,A1_DDD,A1_TEL,A1_CONTATO,ZZN_STATUS,ZZN_NOMECL,ZZN_TEL1,ZZN_TEL2,SA3M.A3_COD COD_MED,SA3M.A3_NOME MEDICO,SA3.A3_NOME HOSPITAL,SA3.A3_COD COD_HOSP,ZZN_DTALT,ZZN_USALT,ZZN_NUMOS,ZZN_TRANSF,ZZN.R_E_C_N_O_ AS RECNO, ZZK.ZZK_NOME`,
          WHERE: `ZZN_PESSOA='${this.nameofdoctor.substr(
            0,
            6,
          )}' AND ZZN_DTINI='${dataBanco}' AND ZZN.R_E_C_N_O_ NOT IN (${recnos})`,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/agendaC`;

        let agendaDiaBanco = [];
        await axios
          .post(url, dados)
          .then(res => {
            agendaDiaBanco = res.data;
          })
          .catch(err => {
            console.log(err);
          });

        if (agendaDiaBanco.length > 0) {
          const allDay = this.rnd(0, 3) === 0;

          //let assessor = ``;
          let color = ``;

          if (this.type === "day" || this.type === "week") {
            agendaDiaBanco.map(e => {
              if (
                e.ZZN_DTINI.trim().length > 0 &&
                e.ZZN_DTFIN.trim().length > 0 &&
                e.ZZN_HRINI.trim().length === 5 &&
                e.ZZN_HRFIN.trim().length === 5
              ) {
                let start = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
                  4,
                  2,
                )}-${e.ZZN_DTINI.substr(6, 2)} ${e.ZZN_HRINI}`;
                let end = `${e.ZZN_DTFIN.substr(0, 4)}-${e.ZZN_DTFIN.substr(
                  4,
                  2,
                )}-${e.ZZN_DTFIN.substr(6, 2)} ${e.ZZN_HRFIN}`;

                if (e.ZZN_STATUS === "000005") {
                  color = "cyan darken-1";
                }
                if (e.ZZN_STATUS === "000006") {
                  color = "grey darken-1";
                }
                if (e.ZZN_STATUS === "000007") {
                  color = "green darken-2";
                }
                if (e.ZZN_STATUS === "000008") {
                  color = "red darken-2";
                }
                if (e.ZZN_STATUS === "000009") {
                  color = "blue darken-2";
                }
                if (e.ZZN_STATUS === "000010") {
                  color = "red darken-2";
                }
                if (e.ZZN_STATUS === "000011") {
                  color = "red darken-2";
                }
                if (e.ZZN_STATUS === "000012") {
                  color = "red darken-2";
                }

                this.events.push({
                  id: e.ZZN_ID,
                  name: `Un. de Neg.: ${e.ZZN_UNEG} | Título:${e.ZZN_TITULO} | Médico:${e.MEDICO} | Hospital:${e.HOSPITAL} | Especialista: ${e.ZZK_NOME} `,
                  start: start,
                  end: end,
                  timed: !allDay,
                  status: e.ZZN_STATUS,
                  color: color,
                  titulo: e.ZZN_TITULO,
                  cliente: e.A1_NOME
                    ? `${e.A1_COD}-${e.A1_LOJA}-${e.A1_NOME}`
                    : e.ZZN_NOMECL,
                  telefone: e.A1_TEL ? `(${e.A1_DDD})-${e.A1_TEL}` : e.ZZN_TEL1,
                  assessor: `${e.A3_COD}-${e.A3_NOME}`,
                  dtAlt: e.ZZN_DTALT,
                  usrAlt: e.ZZN_USALT,
                  numOs: e.ZZN_NUMOS,
                  horaIni: e.ZZN_HRINI,
                  medico: e.MEDICO,
                  uneg: e.ZZN_UNEG,
                  hospital: e.HOSPITAL,
                  horaFim: e.ZZN_HRFIN,
                  data: e.ZZN_DTINI,
                  notas: e.NOTAS,
                  transferida: e.ZZN_TRANSF ? true : false,
                });
              }
            });
          }

          if (this.type === "category") {
            this.itemsTipo = [];
            this.tipo = "";
            agendaDiaBanco.map(e => {
              if (e.ZZN_TITULO.trim().length > 0) {
                if (
                  e.ZZN_DTINI.trim().length > 0 &&
                  e.ZZN_DTFIN.trim().length > 0 &&
                  e.ZZN_HRINI.trim().length === 5 &&
                  e.ZZN_HRFIN.trim().length === 5
                ) {
                  let start = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
                    4,
                    2,
                  )}-${e.ZZN_DTINI.substr(6, 2)} ${e.ZZN_HRINI}`;
                  let end = `${e.ZZN_DTFIN.substr(0, 4)}-${e.ZZN_DTFIN.substr(
                    4,
                    2,
                  )}-${e.ZZN_DTFIN.substr(6, 2)} ${e.ZZN_HRFIN}`;

                  this.categories.forEach(c => {
                    let name = ``;
                    if (c === "Un. de Neg.") {
                      name = e.ZZN_UNEG;
                    }
                    if (c === "Título") {
                      name = e.ZZN_TITULO;
                    }
                    if (c === "Médico") {
                      name = e.MEDICO;
                    }
                    if (c === "Hospital") {
                      name = e.HOSPITAL;
                    }
                    if (c === "Especialista") {
                      name = e.ZZK_NOME;
                    }
                    let color = ``;
                    if (e.ZZN_STATUS === "000005") {
                      color = "cyan darken-1";
                    }
                    if (e.ZZN_STATUS === "000006") {
                      color = "grey darken-1";
                    }
                    if (e.ZZN_STATUS === "000007") {
                      color = "green darken-2";
                    }
                    if (e.ZZN_STATUS === "000008") {
                      color = "red darken-2";
                    }
                    if (e.ZZN_STATUS === "000009") {
                      color = "blue darken-2";
                    }
                    if (e.ZZN_STATUS === "000010") {
                      color = "purple darken-2";
                    }
                    if (e.ZZN_STATUS === "000011") {
                      color = "indigo darken-2";
                    }
                    if (e.ZZN_STATUS === "000012") {
                      color = "amber darken-2";
                    }
                    this.events.push({
                      id: e.ZZN_ID,
                      name: name,
                      start: start,
                      end: end,
                      timed: !allDay,
                      category: c,
                      status: e.ZZN_STATUS,
                      color: color,
                      titulo: e.ZZN_TITULO,
                      cliente: e.A1_NOME
                        ? `${e.A1_COD}-${e.A1_LOJA}-${e.A1_NOME}`
                        : e.ZZN_NOMECL,
                      telefone: `${e.ZZN_TEL1} / ${e.ZZN_TEL2}`,
                      assessor: `${e.A3_COD}-${e.A3_NOME}`,
                      dtAlt: e.ZZN_DTALT,
                      usrAlt: e.ZZN_USALT,
                      numOs: e.ZZN_NUMOS,
                      hospital: `${e.COD_HOSP}-${e.HOSPITAL}`,
                      medico: `${e.COD_MED}-${e.MEDICO}`,
                      uneg: `${e.ZZN_UNEG}`,
                      horaIni: e.ZZN_HRINI,
                      horaFim: e.ZZN_HRFIN,
                      data: e.ZZN_DTINI,
                      notas: e.NOTAS,
                      transferida: e.ZZN_TRANSF === "S" ? true : false,
                      recno: e.RECNO,
                    });
                  });
                }
              }
            });
          }
        }
      }
    },
    buscaHoraAgenda() {
      this.filtroLegAgendado = false;
      this.filtroLegSemAgenda = false;
      this.filtroLegCompareceu = false;
      this.filtroLegNCompareceu = false;
      this.numberfilter = 0;
      this.atualizaAgendaDia();
      const data = this.dataMes.split("-");
      const dataAtual = new Date(data[0], data[1] - 1, data[2]);
      const dia = dataAtual.getDay();
      const semana = new Array(6);

      semana[0] = "DOMINGO";
      semana[1] = "SEGUNDA";
      semana[2] = "TERÇA";
      semana[3] = "QUARTA";
      semana[4] = "QUINTA";
      semana[5] = "SEXTA";
      semana[6] = "SABADO";
      this.horarioDia = [];

      this.horarioDia = this.horarios.filter(e => {
        if (e.dia === semana[dia]) {
          return { ...e };
        }
      });

      if (this.horarioDia.length > 0) {
        const horaIni = `${data[0]}${data[1]}${data[2]} ${this.horarioDia[0].horaIni}`;
        const horaFim = `${data[0]}${data[1]}${data[2]} ${this.horarioDia[0].horaFim}`;

        var date1 = new Date(
            horaIni.slice(0, 4),
            horaIni.slice(4, 6),
            horaIni.slice(6, 8),
            horaIni.slice(9, 11),
            horaIni.slice(12, 14),
          ),
          date2 = new Date(
            horaFim.slice(0, 4),
            horaFim.slice(4, 6),
            horaFim.slice(6, 8),
            horaFim.slice(9, 11),
            horaFim.slice(12, 14),
          );

        var diffMs = date2 - date1;
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000);
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
        var diffHora = parseFloat(diffHrs);
        const diffMinutos = parseFloat(diffMins);

        let number_interval = 0;
        let number_interval_minut = 0;

        if (diffHora > 0) {
          number_interval = diffHora * 4;
        }
        if (diffMinutos > 0) {
          number_interval_minut = diffMinutos / 15;
        }

        const total = number_interval + number_interval_minut;

        this.interval_count = total;

        this.first_time = "";

        if (this.horarioDia.length > 0) {
          this.first_time = this.horarioDia[0].horaIni;
        }
      } else {
        this.interval_count = 40;

        this.first_time = "08:00";
      }
    },
    async next() {
      this.$refs.calendar.next();
      await this.buscaHoraAgenda();
    },
    async prevM() {
      this.$refs.calendarM.prev();
      await this.buscaHoraAgenda();
    },
    async nextM() {
      this.$refs.calendarM.next();
      await this.buscaHoraAgenda();
    },
    filtroTipo(tipo) {
      this.filtroLegAgendado = false;
      this.filtroLegSemAgenda = false;
      this.filtroLegCompareceu = false;
      this.filtroLegNCompareceu = false;
      this.numberfilter = 0;

      if (this.cloneAgenda.length > 0) {
        this.events = this.cloneAgenda;
        this.cloneAgenda = [];
      }

      this.cloneAgenda = this.events;
      let eventos = this.events;

      let consulta = null;

      if (tipo === "Un. de Neg.") {
        consulta = this.tipo;
      }

      if (tipo === "Título") {
        consulta = this.paciente;
      }

      if (tipo === "Médico") {
        consulta = this.assessor;
      }

      if (tipo === "Hospital") {
        consulta = this.telefone;
      }

      if (tipo === "Especialista") {
        consulta = this.obs;
      }

      eventos = eventos.filter(e => {
        if (e.name === consulta) {
          return e.id;
        }
      });

      let filtro = [];

      eventos.forEach(e => {
        this.events.map(f => {
          if (f.id === e.id) {
            filtro.push(f);
          }
        });
      });

      this.events = filtro;
      this.filtrado = true;
    },
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      const allDay = this.rnd(0, 3) === 0;
      for (let i = 0; i < eventCount; i++) {
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
          category: this.categories[this.rnd(0, this.categories.length - 1)],
        });
      }

      let itemsTipo = [];

      this.categories.forEach(e => {
        let conteudo;
        if (e === "Tipo") {
          conteudo = "Retorno";
          itemsTipo.push("Retorno");
        }
        if (e === "Paciente") {
          conteudo = "Evandro Alves de Souza";
        }
        if (e === "Indicador") {
          conteudo = "Dr. Pica Pau";
        }
        if (e === "Telefone") {
          conteudo = "(16) 99334-6003";
        }
        if (e === "Notas") {
          conteudo = "teste de Notas da consulta...";
        }
        events.push({
          id: "34568",
          name: conteudo,
          start: "2020-07-29 08:00:00",
          end: "2020-07-29 09:00:00",
          color: "blue",
          timed: !allDay,
          category: e,
        });
      });

      this.categories.forEach(e => {
        let conteudo;
        if (e === "Tipo") {
          conteudo = "Atendimento";
          itemsTipo.push("Atendimento");
        }
        if (e === "Paciente") {
          conteudo = "Evandro Alves de Souza";
        }
        if (e === "Indicador") {
          conteudo = "Dr. Pica Pau";
        }
        if (e === "Telefone") {
          conteudo = "(16) 99334-6003";
        }
        if (e === "Notas") {
          conteudo = "teste de Notas da consulta...";
        }
        events.push({
          id: "34569",
          name: conteudo,
          start: "2020-07-29 09:15:00",
          end: "2020-07-29 09:30:00",
          color: "blue",
          timed: !allDay,
          category: e,
        });
      });

      this.itemsTipo = itemsTipo;

      this.events = events;
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    async buscaTecnico() {
      this.loadTecnico = true;
      const dados = {
        EMPRESA: `01`,
        FILIAL: `0101`,
        FIELDS: `ZZK_COD,ZZK_NOME`,
        WHERE: `ZZK_TIPO='P'`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/tecnicos`;

      await axios
        .post(url, dados)
        .then(res => {
          if (res.data.code === "ECONNABORTED") {
            this.snackbar_error = true;
            this.error = `A consulta ultrapassou o tempo permitido, abra a tela novamente para repetir a consulta. Se problema persistir entre em contato com a Administração.`;
            return;
          }
          if (res.data.code === "ECONNREFUSED") {
            this.snackbar_error = true;
            this.error = `Servidor off-line, entre em contato com o administrador!`;
            return;
          }
          this.nomes = res.data.map(e => {
            return e.ZZK_COD + "- " + e.ZZK_NOME;
          });
        })
        .catch(err => {
          console.log(err);
        });

      this.loadTecnico = false;
    },
    async buscaAgenda() {
      if (this.nameofdoctor) {
        await this.setToday();

        this.filtroLegAgendado = false;
        this.filtroLegSemAgenda = false;
        this.filtroLegCompareceu = false;
        this.filtroLegNCompareceu = false;
        this.numberfilter = 0;
        this.first_time = "";
        this.interval_count = null;
        this.dialogAwaitAgenda = true;
        await this.buscaStatus();
        this.events = [];
        this.cloneAgenda = [];
        const allDay = this.rnd(0, 3) === 0;
        const pessoa = this.nameofdoctor.substring(0, 6);
        let dados = {
          EMPRESA: `01`,
          FILIAL: `0101`,
          FIELDS: `ZZN_ID,ZZN_UNEG,ZZN_VEND2,ZZN_TITULO,ZZN_DTINI,ZZN_DTFIN,ZZN_HRINI,ZZN_HRFIN,CONVERT(VARCHAR(1000), ZZN_NOTAS) AS NOTAS,ZZN_CLIENT,ZZN_LOJA,A1_COD,A1_LOJA,A1_NOME,A1_DDD,A1_TEL,A1_CONTATO,ZZN_STATUS,ZZN_NOMECL,ZZN_TEL1,ZZN_TEL2,SA3M.A3_COD COD_MED,SA3M.A3_NOME MEDICO,SA3.A3_NOME HOSPITAL,SA3.A3_COD COD_HOSP,ZZN_DTALT,ZZN_USALT,ZZN_NUMOS,ZZN_TRANSF,ZZN.R_E_C_N_O_ AS RECNO, ZZK.ZZK_NOME, ZZKM.ZZK_COD COD_ESPEC, ZZKM.ZZK_NOME ESPECIALISTA,ZZN_DOC`,
          WHERE: `ZZN_PESSOA='${pessoa}'`,
        };

        let url = `${process.env.VUE_APP_BASE_API_URL}/agendaC`;

        let agenda = [];

        this.events = [];

        await axios
          .post(url, dados)
          .then(res => {
            agenda = res.data;
          })
          .catch(err => {
            console.log(err);
          });

        // let color = ``;

        if (this.type === "day" || this.type === "week") {
          agenda.map(e => {
            if (
              e.ZZN_DTINI.trim().length > 0 &&
              e.ZZN_DTFIN.trim().length > 0 &&
              e.ZZN_HRINI.trim().length === 5 &&
              e.ZZN_HRFIN.trim().length === 5
            ) {
              let start = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
                4,
                2,
              )}-${e.ZZN_DTINI.substr(6, 2)} ${e.ZZN_HRINI}`;
              let end = `${e.ZZN_DTFIN.substr(0, 4)}-${e.ZZN_DTFIN.substr(
                4,
                2,
              )}-${e.ZZN_DTFIN.substr(6, 2)} ${e.ZZN_HRFIN}`;

              let color = ``;
              let colorBanco;

              colorBanco = this.itensStatFiltro.filter(
                s => s.CODIGO === e.ZZN_STATUS,
              );

              if (colorBanco.length <= 0) {
                color = "cyan darken-1";
              } else {
                color = colorBanco[0].COLOR;
              }

              // if (e.ZZN_STATUS === "000005") {
              //   color = "cyan darken-1";
              // }
              // if (e.ZZN_STATUS === "000006") {
              //   color = "grey darken-1";
              // }
              // if (e.ZZN_STATUS === "000007") {
              //   color = "green darken-2";
              // }
              // if (e.ZZN_STATUS === "000008") {
              //   color = "red darken-2";
              // }
              // if (e.ZZN_STATUS === "000009") {
              //   color = "blue darken-2";
              // }
              // if (e.ZZN_STATUS === "000010") {
              //   color = "purple darken-2";
              // }
              // if (e.ZZN_STATUS === "000011") {
              //   color = "indigo darken-2";
              // }
              // if (e.ZZN_STATUS === "000012") {
              //   color = "amber darken-2";
              // }
              let tel = ``;
              let tel2 = ``;
              if (e.ZZL_TEL1) {
                tel = `${e.ZZL_TEL1}`;
                tel2 = `${e.ZZL_TEL2}`;
              } else {
                if (e.A1_NOME) {
                  tel = `${e.A1_DDD} - ${e.A1_TEL} / CONTATO: ${e.A1_CONTATO}`;
                }
              }
              this.events.push({
                id: e.ZZN_ID,
                name: `Un. de Neg.: ${e.ZZN_UNEG} | Título:${e.ZZN_TITULO} | Médico:${e.MEDICO} | Hospital:${e.HOSPITAL} | Especialista: ${e.ESPECIALISTA} `,
                start: start,
                end: end,
                timed: !allDay,
                status: e.ZZN_STATUS,
                color: color,
                titulo: e.ZZN_TITULO ? e.ZZN_TITULO : "",
                cliente: e.A1_NOME
                  ? `${e.A1_COD}-${e.A1_LOJA}-${e.A1_NOME}`
                  : e.ZZN_NOMECL,
                telefone: `${tel} / ${tel2}`,
                hospital: `${e.COD_HOSP}-${e.HOSPITAL}`,
                medico: `${e.COD_MED}-${e.MEDICO}`,
                uneg: `${e.ZZN_UNEG}`,
                NrDoc: `${e.ZZN_DOC}`,
                profissional: `${e.COD_ESPEC}-${e.ESPECIALISTA}`,
                dtAlt: e.ZZN_DTALT,
                usrAlt: e.ZZN_USALT,
                numOs: e.ZZN_NUMOS,
                horaIni: e.ZZN_HRINI,
                horaFim: e.ZZN_HRFIN,
                data: e.ZZN_DTINI,
                notas: e.NOTAS,
                transferida: e.ZZN_TRANSF === "S" ? true : false,
                recno: e.RECNO,
              });
            }
          });
        }

        if (this.type === "category") {
          this.itemsTipo = [];
          this.tipo = "";

          agenda.map(e => {
            if (e.ZZN_TITULO.trim().length > 0) {
              if (
                e.ZZN_DTINI.trim().length > 0 &&
                e.ZZN_DTFIN.trim().length > 0 &&
                e.ZZN_HRINI.trim().length === 5 &&
                e.ZZN_HRFIN.trim().length === 5
              ) {
                let start = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
                  4,
                  2,
                )}-${e.ZZN_DTINI.substr(6, 2)} ${e.ZZN_HRINI}`;
                let end = `${e.ZZN_DTFIN.substr(0, 4)}-${e.ZZN_DTFIN.substr(
                  4,
                  2,
                )}-${e.ZZN_DTFIN.substr(6, 2)} ${e.ZZN_HRFIN}`;

                this.categories.forEach(c => {
                  let name = ``;
                  if (c === "Un. de Neg.") {
                    name = e.ZZN_UNEG;
                  }
                  if (c === "Título") {
                    name = e.ZZN_TITULO;
                  }
                  if (c === "Médico") {
                    name = e.MEDICO;
                  }
                  if (c === "Hospital") {
                    name = e.HOSPITAL;
                  }
                  if (c === "Especialista") {
                    name = e.ESPECIALISTA;
                  }
                  let color = ``;
                  let colorBanco;

                  colorBanco = this.itensStatFiltro.filter(
                    s => s.CODIGO === e.ZZN_STATUS,
                  );

                  if (colorBanco.length <= 0) {
                    color = "cyan darken-1";
                  } else {
                    color = colorBanco[0].COLOR;
                  }

                  // if (e.ZZN_STATUS === "000005") {
                  //   color = "cyan darken-1";
                  // }
                  // if (e.ZZN_STATUS === "000006") {
                  //   color = "grey darken-1";
                  // }
                  // if (e.ZZN_STATUS === "000007") {
                  //   color = "green darken-2";
                  // }
                  // if (e.ZZN_STATUS === "000008") {
                  //   color = "red darken-2";
                  // }
                  // if (e.ZZN_STATUS === "000009") {
                  //   color = "blue darken-2";
                  // }
                  // if (e.ZZN_STATUS === "000010") {
                  //   color = "purple darken-2";
                  // }
                  // if (e.ZZN_STATUS === "000011") {
                  //   color = "indigo darken-2";
                  // }
                  // if (e.ZZN_STATUS === "000012") {
                  //   color = "amber darken-2";
                  // }
                  this.events.push({
                    id: e.ZZN_ID,
                    name: name,
                    start: start,
                    end: end,
                    timed: !allDay,
                    category: c,
                    status: e.ZZN_STATUS,
                    color: color,
                    titulo: e.ZZN_TITULO,
                    cliente: e.A1_NOME
                      ? `${e.A1_COD}-${e.A1_LOJA}-${e.A1_NOME}`
                      : e.ZZN_NOMECL,
                    telefone: `${e.ZZN_TEL1} / ${e.ZZN_TEL2}`,
                    assessor: `${e.A3_COD}-${e.A3_NOME}`,
                    dtAlt: e.ZZN_DTALT,
                    usrAlt: e.ZZN_USALT,
                    numOs: e.ZZN_NUMOS,
                    hospital: `${e.COD_HOSP}-${e.HOSPITAL}`,
                    medico: `${e.COD_MED}-${e.MEDICO}`,
                    NrDoc: `${e.ZZN_DOC}`,
                    profissional: `${e.COD_ESPEC}-${e.ESPECIALISTA}`,
                    uneg: `${e.ZZN_UNEG}`,
                    horaIni: e.ZZN_HRINI,
                    horaFim: e.ZZN_HRFIN,
                    data: e.ZZN_DTINI,
                    notas: e.NOTAS,
                    transferida: e.ZZN_TRANSF === "S" ? true : false,
                    recno: e.RECNO,
                  });
                });
              }
            }
          });
        }

        await this.buscaHorarios();

        this.eventsMonth = [];

        dados = {
          EMPRESA: `01`,
          FILIAL: `0101`,
          FIELDS: `DISTINCT(ZZN_DTINI)`,
          WHERE: `ZZN_PESSOA='${pessoa}'`,
        };

        let marcacao = [];

        await axios
          .post(url, dados)
          .then(res => {
            marcacao = res.data;
          })
          .catch(err => {
            console.log(err);
          });

        marcacao.map(e => {
          if (e.ZZN_DTINI.trim().length > 0) {
            let start = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
              4,
              2,
            )}-${e.ZZN_DTINI.substr(6, 2)}`;
            let end = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
              4,
              2,
            )}-${e.ZZN_DTINI.substr(6, 2)}`;
            this.eventsMonth.push({
              name: "Agenda",
              start: start,
              end: end,
            });

            if (this.type === "day") {
              start = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
                4,
                2,
              )}-${e.ZZN_DTINI.substr(6, 2)} ${this.hrIniAlm}`;
              end = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
                4,
                2,
              )}-${e.ZZN_DTINI.substr(6, 2)} ${this.hrFimAlm}`;
              this.events.push({
                id: "ALM",
                name: `ALMOÇO `,
                start: start,
                end: end,
                timed: !allDay,
                status: "",
                icon: "mdi-food",
                color: "orange",
                titulo: "Almoço",
                cliente: "",
                telefone: "",
                assessor: "",
                dtAlt: "",
                usrAlt: "",
                numOs: "",
                horaIni: this.hrIniAlm,
                horaFim: this.hrFimAlm,
                data: "",
                notas: "",
                transferida: false,
                almoco: true,
                recno: null,
              });
            }

            if (this.type === "category") {
              start = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
                4,
                2,
              )}-${e.ZZN_DTINI.substr(6, 2)} ${this.hrIniAlm}`;
              end = `${e.ZZN_DTINI.substr(0, 4)}-${e.ZZN_DTINI.substr(
                4,
                2,
              )}-${e.ZZN_DTINI.substr(6, 2)} ${this.hrFimAlm}`;
              this.categories.forEach(c => {
                this.events.push({
                  id: "ALM",
                  name: `ALMOÇO `,
                  start: start,
                  end: end,
                  timed: !allDay,
                  category: c,
                  status: "",
                  icon: "mdi-food",
                  color: "orange",
                  titulo: "Almoço",
                  cliente: "",
                  telefone: "",
                  assessor: "",
                  dtAlt: "",
                  usrAlt: "",
                  numOs: "",
                  horaIni: this.hrIniAlm,
                  horaFim: this.hrFimAlm,
                  data: "",
                  notas: "",
                  transferida: false,
                  almoco: true,
                  recno: null,
                });
              });
            }
          }
        });

        this.buscaHoraAgenda();

        this.dialogAwaitAgenda = false;
      }
    },
    async buscaStatus() {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZZO_COD,ZZO_NOME,ZZO_COLOR",
        WHERE: `ZZO_AGENDA='C'`,
        TABELA: "ZZO",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.itensStatFiltro = [];
          res.data.map(e => {
            // let color = ``;
            // if (e.ZZO_COD === "000005") {
            //   color = "cyan darken-1";
            // }
            // if (e.ZZO_COD === "000006") {
            //   color = "grey darken-1";
            // }
            // if (e.ZZO_COD === "000007") {
            //   color = "green darken-2";
            // }
            // if (e.ZZO_COD === "000008") {
            //   color = "red darken-2";
            // }
            // if (e.ZZO_COD === "000009") {
            //   color = "blue darken-2";
            // }
            // if (e.ZZO_COD === "000010") {
            //   color = "purple darken-2";
            // }
            // if (e.ZZO_COD === "000011") {
            //   color = "indigo darken-2";
            // }
            // if (e.ZZO_COD === "000012") {
            //   color = "amber darken-2";
            // }
            this.itensStatFiltro.push({
              CODIGO: e.ZZO_COD,
              STATUS: e.ZZO_NOME.trim(),
              COLOR: e.ZZO_COLOR.trim(),
            });
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    async buscaHorarios() {
      const pessoa = this.nameofdoctor.substring(0, 6);
      const dados = {
        EMPRESA: `01`,
        FILIAL: `0101`,
        FIELDS: ``,
        WHERE: `ZZK_COD='${pessoa}'`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/tecnicos`;

      this.horarios = [];

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            if (e.ZZK_HDOM === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRDOMI,
                horaFim: e.ZZK_HRDOMF,
                dia: "DOMINGO",
              });
            }
            if (e.ZZK_HSEG === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRSEGI,
                horaFim: e.ZZK_HRSEGF,
                dia: "SEGUNDA",
              });
            }
            if (e.ZZK_HTER === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRTERI,
                horaFim: e.ZZK_HRTERF,
                dia: "TERÇA",
              });
            }
            if (e.ZZK_HQUA === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRQUAI,
                horaFim: e.ZZK_HRQUAF,
                dia: "QUARTA",
              });
            }
            if (e.ZZK_HQUI === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRQUII,
                horaFim: e.ZZK_HRQUIF,
                dia: "QUINTA",
              });
            }
            if (e.ZZK_HSEX === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRSEXI,
                horaFim: e.ZZK_HRSEXF,
                dia: "SEXTA",
              });
            }
            if (e.ZZK_HSAB === "S") {
              this.horarios.push({
                id: e.ZZK_COD,
                horaIni: e.ZZK_HRSABI,
                horaFim: e.ZZK_HRSABF,
                dia: "SABADO",
              });
            }

            this.hrIniAlm = e.ZZK_HRALMI;
            this.hrFimAlm = e.ZZK_HRALMF;
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    async carregaTipos(tipo) {
      this.filtroLegAgendado = false;
      this.filtroLegSemAgenda = false;
      this.filtroLegCompareceu = false;
      this.filtroLegNCompareceu = false;
      this.numberfilter = 0;

      if (this.cloneAgenda.length > 0) {
        this.events = this.cloneAgenda;
        this.cloneAgenda = [];
      }

      if (tipo.trim().length === 0) {
        this.buscaAgenda;
        return;
      }
      const data = this.dataMes;

      this.itemsTipo = [];

      await this.events.forEach(e => {
        const dtIni = e.start.substr(0, 10);
        if (
          e.category === tipo &&
          dtIni === data &&
          e.name.trim() !== "ALMOÇO"
        ) {
          this.itemsTipo.push(e.name);
        }
      });
    },
  },
  created() {
    this.setToday();
  },
  mounted() {
    this.buscaTecnico();
  },
};
</script>

<style>
.content {
  display: grid;
  grid-template-columns: 400px 1fr;
  align-content: center;
  align-items: top;
  padding-bottom: 40px;
}

.agenda_mes {
  padding: 10px;
  margin: 0px;
  height: 450px;
}

.agenda_dia {
  height: 650px;
}
.filtrinho:hover {
  cursor: pointer;
}
</style>
