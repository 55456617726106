import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"700","persistent":""},model:{value:(_vm.dialogError),callback:function ($$v) {_vm.dialogError=$$v},expression:"dialogError"}},[_c(VCard,[_c(VCardTitle,{staticClass:"title red lighten-2",domProps:{"textContent":_vm._s("Aconteceu algum erro, entre em contato com o administrador!")}}),_c(VCardText,{staticClass:"body-1 mt-3",domProps:{"textContent":_vm._s(_vm.error)}}),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.fechaDialog}},[_vm._v(" Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }