<template id="infoDados">
  <div class="detalhes">
    <v-row justify="center">
      <v-dialog
        v-model="dialogDetalhes"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary" dense>
            <v-btn small class="ml-1" icon dark @click="dialogDetalhes = false">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Detalhes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text>
                {{ `${codigo} - ${nomeCliente}` }}
              </v-btn>
            </v-toolbar-items>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-container fluid>
            <v-row dense justify="center">
              <v-col
                v-for="card in cards"
                :key="card.title"
                cols="12"
                :class="`d-flex align-center justify-${card.justify} mb-6`"
              >
                <v-expansion-panels>
                  <v-expansion-panel width="95%" :class="card.color">
                    <v-expansion-panel-header>
                      <v-spacer></v-spacer>
                      <v-row>
                        <v-badge
                          class="mt-1"
                          color="primary"
                          :content="card.items.length"
                          v-if="card.items.length > 0"
                          bordered
                        >
                          {{ card.title }}
                        </v-badge>
                        <span v-if="card.items.length <= 0">{{
                          card.title
                        }}</span>
                        <v-spacer></v-spacer>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content transition="scale-transition">
                      <div>
                        <v-data-table
                          :headers="card.headers"
                          fixed-header
                          dense
                          :items="card.items"
                          class="elevation-1"
                          no-data-text="Sem dados..."
                          :items-per-page="card.items.length"
                          hide-default-footer
                          :loading="loadingAgendas"
                          :height="
                            card.items.length <= 20
                              ? 250
                              : card.items.length * 10
                          "
                        >
                          <template v-slot:top>
                            <v-toolbar flat color="white">
                              <v-row dense v-if="card.title === 'Agendas'">
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-select
                                    v-model="filtroEscAgenda"
                                    :items="itemsFiltroAgenda"
                                    label="Escolha o campo"
                                  ></v-select>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    placeholder="digite aqui.."
                                    v-model="stringFiltroAgenda"
                                    :disabled="!filtroEscAgenda"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    color="indigo"
                                    @click="
                                      filtraRegistro(
                                        'Agenda',
                                        filtroEscAgenda,
                                        stringFiltroAgenda,
                                        card.headers,
                                      )
                                    "
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-account-search</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    v-if="NrRegFiltroAgenda"
                                    color="error"
                                    @click="LimpaFiltro('Agenda')"
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-eraser</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row dense v-if="card.title === 'Financeiro'">
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-select
                                    v-model="filtroEscFinan"
                                    :items="itemsFiltroFinan"
                                    label="Escolha o campo"
                                  ></v-select>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    placeholder="digite aqui.."
                                    v-model="stringFiltroFinan"
                                    :disabled="!filtroEscFinan"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    color="indigo"
                                    @click="
                                      filtraRegistro(
                                        'Financeiro',
                                        filtroEscFinan,
                                        stringFiltroFinan,
                                        card.headers,
                                      )
                                    "
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-account-search</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    v-if="NrRegFiltroFinan"
                                    color="error"
                                    @click="LimpaFiltro('Financeiro')"
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-eraser</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row dense v-if="card.title === 'Faturamento'">
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-select
                                    v-model="filtroEscFat"
                                    :items="itemsFiltroFat"
                                    label="Escolha o campo"
                                  ></v-select>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    placeholder="digite aqui.."
                                    v-model="stringFiltroFat"
                                    :disabled="!filtroEscFat"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    color="indigo"
                                    @click="
                                      filtraRegistro(
                                        'Faturamento',
                                        filtroEscFat,
                                        stringFiltroFat,
                                        card.headers,
                                      )
                                    "
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-account-search</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    v-if="NrRegFiltroFat"
                                    color="error"
                                    @click="LimpaFiltro('Faturamento')"
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-eraser</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row
                                dense
                                v-if="card.title === 'Ordem(s) de Serviço'"
                              >
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-select
                                    v-model="filtroEscOs"
                                    :items="itemsFiltroOs"
                                    label="Escolha o campo"
                                  ></v-select>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    placeholder="digite aqui.."
                                    v-model="stringFiltroOs"
                                    :disabled="!filtroEscOs"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    color="indigo"
                                    @click="
                                      filtraRegistro(
                                        'Os',
                                        filtroEscOs,
                                        stringFiltroOs,
                                        card.headers,
                                      )
                                    "
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-account-search</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    v-if="NrRegFiltroOs"
                                    color="error"
                                    @click="LimpaFiltro('Os')"
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-eraser</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row dense v-if="card.title === 'Follow Up'">
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-select
                                    v-model="filtroEscFollow"
                                    :items="itemsFiltroFollow"
                                    label="Escolha o campo"
                                  ></v-select>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    placeholder="digite aqui.."
                                    v-model="stringFiltroFollow"
                                    :disabled="!filtroEscFollow"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    color="indigo"
                                    @click="
                                      filtraRegistro(
                                        'Follow',
                                        filtroEscFollow,
                                        stringFiltroFollow,
                                        card.headers,
                                      )
                                    "
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-account-search</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    v-if="NrRegFiltroFollow"
                                    color="error"
                                    @click="LimpaFiltro('Follow')"
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-eraser</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-toolbar>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  small
                                  @click="verDados(item)"
                                  class="mr-2"
                                  v-bind="attrs"
                                  v-on="on"
                                  >mdi-eye</v-icon
                                >
                              </template>
                              <span>Ver Agenda</span>
                            </v-tooltip>
                          </template>
                          <template v-slot:[`item.ZZL_STATUS`]="{ item }">
                            <v-chip
                              class="ma-2"
                              v-if="item.ZZL_STATUS === 'Agendamento'"
                              x-small
                              dark
                              color="cyan darken-1"
                            >
                              {{ item.ZZL_STATUS }}
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.ZZL_STATUS === 'Bloqueado'"
                              x-small
                              dark
                              color="grey darken-1"
                            >
                              {{ item.ZZL_STATUS }}
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.ZZL_STATUS === 'Compareceu'"
                              x-small
                              dark
                              color="green darken-2"
                            >
                              {{ item.ZZL_STATUS }}
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.ZZL_STATUS === 'Não Compareceu'"
                              x-small
                              dark
                              color="red darken-2"
                            >
                              {{ item.ZZL_STATUS }}
                            </v-chip>
                          </template>
                          <template v-slot:[`item.status`]="{ item }">
                            <v-chip
                              class="ma-2"
                              v-if="item.status === 'Vencido'"
                              x-small
                              dark
                              color="red darken-1"
                            >
                              {{ item.status }}
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.status === 'Aberto'"
                              x-small
                              dark
                              color="blue darken-1"
                            >
                              {{ item.status }}
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.status === 'Liquidado'"
                              x-small
                              dark
                              color="green darken-1"
                            >
                              {{ item.status }}
                            </v-chip>
                          </template>
                        </v-data-table>
                      </div>
                    </v-expansion-panel-content>

                    <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      x-small
                      color="primary"
                      dark
                      @click="verTodos(card.title)"
                    >
                      Ver todos
                    </v-btn>
                  </v-card-actions> -->
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
          <!-- <v-skeleton-loader
            ref="skeleton"
            v-for="n in 2 - cards.length"
            :Key="n"
            :boilerplate="skeltCards"
            type="list-item"
            height="60"
            class="pa-2"
          ></v-skeleton-loader> -->
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="dialogDetAgenda" v-if="ZZL[0]" width="900" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Detalhes do agendamento</v-card-title
        >
        <v-card-text>
          <v-row align="center">
            <v-col cols="9">
              <v-text-field
                dense
                label="Título"
                v-model="ZZL[0].ZZL_TITULO"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                label="Número OS"
                v-model="ZZL[0].ZZL_NUMOS"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -10px">
            <v-col cols="8">
              <v-text-field
                dense
                label="Cliente"
                v-model="ZZL[0].ZZL_NOMECLI"
                readonly
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="2">
              <v-text-field
                dense
                label="Hora Inicial"
                v-model="ZZL[0].ZZL_HRINI"
                readonly
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="2">
              <v-text-field
                dense
                label="Hora Final"
                v-model="ZZL[0].ZZL_HRFIN"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -10px">
            <v-col cols="4">
              <v-text-field
                dense
                label="Telefone"
                v-model="ZZL[0].ZZL_TEL1"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                label="Telefone 2"
                v-model="ZZL[0].ZZL_TEL2"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                label="Indicador"
                v-model="ZZL[0].ZZL_INDICADOR"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -5px">
            <v-col cols="12">
              <v-textarea dense v-model="ZZL[0].NOTAS" color="teal" rows="1">
                <template v-slot:label>
                  <div>Notas</div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-bottom: -15px; margin-top: -10px">
            <v-col cols="4">
              <v-text-field
                dense
                label="Status"
                v-model="ZZL[0].ZZL_STATUS"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" small dark @click="dialogDetAgenda = false"
            >fechar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAwaitProcessa" width="500">
      <v-card>
        <v-overlay :value="dialogAwaitProcessa" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title>Dados</v-card-title>
        <v-card-subtitle>
          <p class="body-1">Processando sua solicitação, aguarde...</p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>

    <v-dialog v-model="DialogTodos" v-if="todos[0]" width="90%" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2">{{
          titleTotdos
        }}</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="todos[0].headers"
            dense
            :items="todos[0].items"
            class="elevation-1 mt-2"
            no-data-text="Sem dados..."
            hide-default-footer
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="verDados(item)"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-eye</v-icon
                  >
                </template>
                <span>Ver Agenda</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.ZZL_STATUS`]="{ item }">
              <v-chip
                class="ma-2"
                v-if="item.ZZL_STATUS === 'Agendamento'"
                x-small
                dark
                color="cyan darken-1"
              >
                {{ item.ZZL_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.ZZL_STATUS === 'Bloqueado'"
                x-small
                dark
                color="grey darken-1"
              >
                {{ item.ZZL_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.ZZL_STATUS === 'Compareceu'"
                x-small
                dark
                color="green darken-2"
              >
                {{ item.ZZL_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.ZZL_STATUS === 'Não Compareceu'"
                x-small
                dark
                color="red darken-2"
              >
                {{ item.ZZL_STATUS }}
              </v-chip>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                class="ma-2"
                v-if="item.status === 'Vencido'"
                x-small
                dark
                color="red darken-1"
              >
                {{ item.status }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.status === 'Aberto'"
                x-small
                dark
                color="blue darken-1"
              >
                {{ item.status }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.status === 'Liquidado'"
                x-small
                dark
                color="green darken-1"
              >
                {{ item.status }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" small dark @click="DialogTodos = false"
            >fechar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "DetLeads",
  computed: mapState(["metadados"]),
  data: () => ({
    dialogAwaitProcessa: false,
    skeltCards: false,
    cards: [
      { title: "Agendas", items: [], color: "grey lighten-1" },
      { title: "Follow-Up", items: [], color: "grey lighten-1" },
    ],
    todos: [],
    dialogDetalhes: false,
    codigo: null,
    loja: null,
    nomeCliente: null,
    loadingAgendas: true,
    dialogDetAgenda: false,
    DialogTodos: false,
    titleTotdos: null,
    ZZL: [],
    dadosOrigemAgenda: [],
    dadosOrigemFinan: [],
    dadosOrigemOs: [],
    dadosOrigemFat: [],
    dadosOrigemFollow: [],

    //FILTRO DA AGENDA
    filtroEscAgenda: null,
    NrRegFiltroAgenda: null,
    itemsFiltroAgenda: [],
    stringFiltroAgenda: null,

    NrRegFiltroFat: null,
    filtroEscFat: null,
    itemsFiltroFat: [],
    stringFiltroFat: null,

    NrRegFiltroFinan: null,
    itemsFiltroFinan: [],
    filtroEscFinan: null,
    stringFiltroFinan: null,

    NrRegFiltroOs: null,
    itemsFiltroOs: [],
    filtroEscOs: null,
    stringFiltroOs: null,

    NrRegFiltroFollow: null,
    itemsFiltroFollow: [],
    filtroEscFollow: null,
    stringFiltroFollow: null,
  }),
  methods: {
    LimpaFiltro(card) {
      if (card === "Agenda") {
        this.filtroEscAgenda = null;
        this.NrRegFiltroAgenda = null;
        this.stringFiltroAgenda = null;
        this.cards[0].items = this.dadosOrigemAgenda;
      }
      if (card === "Financeiro") {
        this.filtroEscFinan = null;
        this.NrRegFiltroFinan = null;
        this.stringFiltroFinan = null;
        this.cards[1].items = this.dadosOrigemFinan;
      }

      if (card === "Faturamento") {
        this.filtroEscFat = null;
        this.NrRegFiltroFat = null;
        this.stringFiltroFat = null;
        this.cards[2].items = this.dadosOrigemFat;
      }

      if (card === "Os") {
        this.filtroEscOs = null;
        this.NrRegFiltroOs = null;
        this.stringFiltroOs = null;
        this.cards[3].items = this.dadosOrigemOs;
      }

      if (card === "Follow") {
        this.filtroEscFollow = null;
        this.NrRegFiltroFollow = null;
        this.stringFiltroFollow = null;
        this.cards[4].items = this.dadosOrigemFollow;
      }
    },
    async filtraRegistro(card, campo, stringFiltro) {
      if (!campo || !stringFiltro) return;

      if (card === "Agenda") {
        this.cards[0].items = this.dadosOrigemAgenda;

        this.cards[0].items = this.cards[0].items.filter(e => {
          return e[`${campo}`]
            .toLowerCase()
            .includes(stringFiltro.toLowerCase());
        });

        this.NrRegFiltroAgenda = 1;
      }

      if (card === "Financeiro") {
        this.cards[1].items = this.dadosOrigemFinan;
        this.cards[1].items = this.cards[1].items.filter(e => {
          return e[`${campo}`]
            .toLowerCase()
            .includes(stringFiltro.toLowerCase());
        });

        this.NrRegFiltroFinan = 1;
      }

      if (card === "Faturamento") {
        this.cards[2].items = this.dadosOrigemFat;
        this.cards[2].items = this.cards[2].items.filter(e => {
          return e[`${campo}`]
            .toLowerCase()
            .includes(stringFiltro.toLowerCase());
        });

        this.NrRegFiltroFat = 1;
      }

      if (card === "Os") {
        this.cards[3].items = this.dadosOrigemOs;
        this.cards[3].items = this.cards[3].items.filter(e => {
          return e[`${campo}`]
            .toLowerCase()
            .includes(stringFiltro.toLowerCase());
        });

        this.NrRegFiltroOs = 1;
      }

      if (card === "Follow") {
        this.cards[4].items = this.dadosOrigemFollow;
        this.cards[4].items = this.cards[4].items.filter(e => {
          return e[`${campo}`]
            .toLowerCase()
            .includes(stringFiltro.toLowerCase());
        });

        this.NrRegFiltroFollow = 1;
      }
    },
    async verDados(item) {
      this.dialogDetAgenda = false;
      this.dialogAwaitProcessa = true;
      this.ZZL = [];

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ZZL_TITULO,ZZL_DTINI,ZZL_DTFIN,ZZL_HRINI,ZZL_HRFIN,CONVERT(VARCHAR(1000), ZZL_NOTAS) AS NOTAS,ZZL_TEL1,ZZL_TEL2,ZZL_NUMOS`,
        WHERE: `ZZL.R_E_C_N_O_='${item.R_E_C_N_O_}'`,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/infoCli/agenda`;

      await axios
        .post(url, dados)
        .then(res => {
          this.ZZL = { ...res.data };
        })
        .catch(err => console.log(err));

      this.ZZL[0].ZZL_NOMECLI = item.ZZL_NOMECL;
      this.ZZL[0].ZZL_STATUS = item.ZZL_STATUS;
      this.ZZL[0].ZZL_INDICADOR = item.A3_NOME;
      this.dialogAwaitProcessa = false;
      this.dialogDetAgenda = true;
    },
    async carregaAgendas(id, nome) {
      this.codigo = id;
      this.nomeCliente = nome;

      //this.cards = [];
      let ZZP = [];
      let headers = [];

      headers.push({
        text: "Ações",
        value: "actions",
        align: "center",
        width: 90,
        caption: "Caption",
      });

      ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD4");

      ZZP.map(e => {
        if (e.ZZP_BROWSE === "1") {
          headers.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "start",
            caption: "Caption",
          });
          if (e.ZZP_TITULO.trim() !== "Ações") {
            //let campo = `${e.ZZP_TITULO.trim()}`;
            this.itemsFiltroAgenda.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
            });
          }
        }
      });

      let dados = {};
      let url = ``;

      ZZP;

      const tabelaAux = [...new Set(ZZP.map(item => item.ZZP_TABELA))];

      let stringCampos = "";

      let tabela = tabelaAux[0];

      let cont = 0;
      ZZP.forEach(e => {
        if (e.ZZP_TABELA === tabela) {
          cont++;
        }
      });

      for (let i = 0; i < cont; i++) {
        if (ZZP[i].ZZP_TABELA === tabela) {
          if (i + 1 >= cont) {
            stringCampos += `${ZZP[i].ZZP_CAMPO.trim()}`;
          } else {
            stringCampos += `${ZZP[i].ZZP_CAMPO.trim()},`;
          }
        }
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${stringCampos},ZZL_NOMECL`,
        WHERE: `ZZL_IDLEAD='${id}' ORDER BY ZZL_DTINI DESC`,
        TABELA: tabela.substr(0, 3),
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/infoCli/agenda`;

      ZZP = [];
      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.ZZL_DTINI = `${e.ZZL_DTINI.substr(6, 2)}/${e.ZZL_DTINI.substr(
              4,
              2,
            )}/${e.ZZL_DTINI.substr(0, 4)}`;
            if (e.ZZL_STATUS === "A") e.ZZL_STATUS = "Agendamento";
            if (e.ZZL_STATUS === "S") e.ZZL_STATUS = "Bloqueado";
            if (e.ZZL_STATUS === "C") e.ZZL_STATUS = "Compareceu";
            if (e.ZZL_STATUS === "N") e.ZZL_STATUS = "Não Compareceu";
          });
          ZZP = res.data;
        })
        .catch(err => console.log(err));

      this.loadingAgendas = false;
      this.show = true;
      this.stop = true;

      if (ZZP.length > 0) {
        this.cards[0].items = ZZP;
        this.cards[0].title = "Agendas";
        this.cards[0].color = "blue lighten-5";
      } else {
        this.cards[0].title = "Agendas sem dados no momento";
        this.cards[0].color = "red lighten-4";
      }
      this.cards[0].headers = headers;
      this.dadosOrigemAgenda = this.cards[0].items;
    },
    async carregaOrdens(codigo, loja, nome) {
      console.log(codigo);
      console.log(loja);
      console.log(nome);
    },

    async carregaFollow(id, nome) {
      console.log(nome);
      let SZ4 = [];
      let dados = {};
      let url = ``;
      let headers = [];

      let ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD013");

      ZZP.map(e => {
        let width = 90;
        if (e.ZZP_BROWSE === "1") {
          headers.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: width,
            caption: "title",
          });
          this.itemsFiltroFollow.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
          });
        }
      });

      const tabelaAux = [...new Set(ZZP.map(item => item.ZZP_TABELA))];

      this.stringCampos = "";

      let tabela = tabelaAux[0];

      ZZP.forEach(e => {
        if (e.ZZP_TABELA === tabela) {
          if (e.ZZP_TIPO === "Y") {
            this.stringCampos += `CONVERT(VARCHAR(2000), ${e.ZZP_CAMPO.trim()}) ${e.ZZP_CAMPO.trim()},`;
          } else {
            this.stringCampos += `${e.ZZP_CAMPO.trim()},`;
          }
        }
      });

      if (this.stringCampos.substr(-1) === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      this.stringCampos += " ,ZS1_NOME";

      let WHERE = ``;

      WHERE += ` Z4_ALIAS='ZZQ' AND Z4_ID='${id}' ORDER BY SZ4.R_E_C_N_O_ DESC`;

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: WHERE,
        TABELA: tabela.substr(0, 3),
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/followUp`;

      this.itemLeads = [];

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.Z4_DATA = `${e.Z4_DATA.substr(6, 2)}/${e.Z4_DATA.substr(
              4,
              2,
            )}/${e.Z4_DATA.substr(0, 4)}`;
            if (e.Z4_STATUS) e.Z4_STATUS = `${e.Z4_STATUS}-${e.Z5_DESC}`;
            if (!e.Z4_STATUS) e.Z4_STATUS = `03-FECHADA`;
            if (e.Z4_TEXTO && e.Z4_TEXTO.length > 100) {
              e.Z4_TEXTO_ORI = e.Z4_TEXTO.substr(0, 100) + "...";
            } else {
              e.Z4_TEXTO_ORI = e.Z4_TEXTO;
            }
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "P")
              e.Z4_SATISFA = "P=PESSIMO";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "R")
              e.Z4_SATISFA = "R=RUIM";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "B")
              e.Z4_SATISFA = "B=BOM";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "E")
              e.Z4_SATISFA = "E=EXCELENTE";
          });
          SZ4 = res.data;
        })
        .catch(err => console.log(err));

      if (SZ4.length > 0) {
        this.cards[1].items = SZ4;
        this.cards[1].title = "Follow Up";
        this.cards[1].color = "indigo lighten-5";
      } else {
        this.cards[1].title = "Follow Up sem dados no momento";
        this.cards[1].color = "red lighten-4";
      }
      this.cards[1].headers = headers;
      this.dadosOrigemFollow = this.cards[1].items;
    },
    async buscaDados(id, nome) {
      this.dialogDetalhes = true;
      this.nomeCliente = `${id}-${nome}`;
      this.cards = [
        { title: "Agendas carregando...", items: [], color: "grey lighten-1" },
        {
          title: "Follow Up carregando...",
          items: [],
          color: "grey lighten-1",
        },
      ];

      this.carregaAgendas(id, nome);

      this.carregaFollow(id, nome);
    },
  },
};
</script>

<style></style>
