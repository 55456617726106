<template id="planCard">
  <div class="plan">
    <v-snackbar
      v-model="snackbar_error"
      color="error"
      multi-line="multi-line"
      :timeout="6000"
      bottom="bottom"
    >
      <v-icon small color="#FFF" class="mr-3">mdi-alert-circle</v-icon>
      {{ error }}
    </v-snackbar>
    <v-card
      class="rounded-t-xl pa-4 ma-8"
      elevation="4"
      width="450"
      height="600"
      justify="space-between"
    >
      <v-autocomplete
        class="text-uppercase font-weight-bold"
        background-color="white"
        dense
        filled
        placeholder="Escolha o Clube"
        no-data-text="Buscando dados..."
        v-model="clube"
        :items="planos"
        item-text="nome"
        item-value="id"
        :loading="loadingPlanos"
        @change="filtraPlano"
        @focus="loadPlanos"
      ></v-autocomplete>
      <!-- <v-autocomplete
        class="text-uppercase font-weight-bold"
        style="margin-top: -20px"
        background-color="white"
        dense
        filled
        placeholder="Escolha a quantidade de envios"
        no-data-text="Buscando dados..."
        v-if="clube"
        v-model="envio"
        :items="envios"
        @change="filtraPlano"
      ></v-autocomplete> -->

      <div v-if="planoMessage" class="message">
        <v-row class="d-flex" align="center" justify="center">
          <v-icon class="mr-4" color="grey" size="128">
            mdi-ticket-percent
          </v-icon>
          <span class="text-h6">Este clube não existe</span>
        </v-row>
      </div>

      <div v-if="planoSelecionado">
        <span class="font-weight-medium text-h2">R${{ real }}</span>
        <span class="font-weight-medium text-h4">,{{ centavos }}</span>
      </div>

      <p align="center" v-if="planoSelecionado">
        Plano com {{ planoSelecionado.envios }} envios
      </p>

      <p class="caption" v-if="planoSelecionado">*Envio até o 10º dia do mês</p>

      <v-list v-if="planoSelecionado" class="overflow-y-auto mb-5" height="300">
        <v-list-item v-for="(item, i) in planoSelecionado.produtos" :key="i">
          <v-list-item-icon>
            <v-icon :color="item.iconColor" v-if="true">
              {{ item.icon }}
            </v-icon>
            <!-- <v-icon v-if="false" color="red" >
              mdi-close-circle
            </v-icon> -->
          </v-list-item-icon>
          <v-list-item-content>
            <span>{{ item.text }}</span>
          </v-list-item-content>
          <v-list-item-action>
            {{ "×" }} {{ item.quantidade }}
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          v-for="(item, j) in itensAdesao"
          :key="planoSelecionado.produtos.length + j"
        >
          <v-list-item-icon>
            <v-icon
              v-if="item.icon === 'mdi-plus-circle'"
              :color="item.iconColor"
              @click="adicionaItemAdesao()"
            >
              {{ item.icon }}
            </v-icon>
            <v-icon
              v-if="item.icon === 'mdi-delete'"
              :color="item.iconColor"
              @click="removeItemAdesao(item)"
            >
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <span>{{ item.text }}</span>
          </v-list-item-content>
          <v-list-item-action>
            <button
              v-if="item.icon === 'mdi-delete'"
              @click="editaItemAdesao(item)"
            >
              {{ "×" }} {{ item.quantidade }}
            </button>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-dialog v-model="dialogAdesao" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Adicionar item</span>
          </v-card-title>

          <v-card-text style="padding-bottom: 0">
            <v-container>
              <v-row style="margin-top: -20px">
                <v-autocomplete
                  class="text-uppercase"
                  v-model="editedItem.produto"
                  filled
                  background-color="white"
                  label="Produto"
                  placeholder="Produto"
                  :autofocus="dialogAdesao"
                  :items="produtosAdesao"
                  :readonly="isReading"
                  :loading="loadingProdutos"
                  :rules="obrigat(editedItem.produto, '1')"
                  @change="getPrecoItem"
                ></v-autocomplete>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col>
                  <label style="font-variant: all-small-caps; font-size: 85%"
                    >Valor unitário</label
                  >
                  <money
                    class="
                      v-input
                      text-uppercase
                      v-input--dense
                      theme--light
                      v-text-field v-text-field--is-booted
                    "
                    v-model="editedItem.valor"
                    v-bind="money"
                    dense
                    :readonly="isReading"
                  ></money>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-text-field
                    v-model="editedItem.quantidade"
                    label="Quantidade"
                    :readonly="isReading"
                  >
                  </v-text-field>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn
              v-if="isReading"
              text
              color="blue darken-1"
              @click="fechaDialogAdesao"
            >
              Fechar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isReading"
              text
              color="blue darken-1"
              @click="fechaDialogAdesao"
            >
              Cancelar
            </v-btn>
            <v-btn
              v-if="!isReading"
              text
              color="blue darken-1"
              @click="salvarItemAdesao"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row v-if="planoSelecionado" justify="center">
        <v-btn color="orange" dark @click="modal = true">
          Escolher este plano
        </v-btn>
      </v-row>

      <v-overlay :value="isLoading" absolute align="center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <p class="mt-8 text-h5">Finalizando assinatura<br />Aguarde...</p>
      </v-overlay>
      <Swipemodal v-model="modal" radius="10px" notip>
        <div class="mt-1">
          <v-text-field
            v-if="!agradecimento && modal"
            v-model="cpfAssinatura"
            v-mask="'###.###.###-##'"
            label="CPF"
            placeholder="___.___.___-__"
            outlined
            dense
            :autofocus="modal"
            :loading="loadingCliente"
            @input="preencheCliente"
          ></v-text-field>
        </div>

        <div v-if="clienteMessage && !agradecimento" class="message">
          <v-row class="d-flex" align="center" justify="center">
            <v-icon class="mr-4" color="grey" size="128">
              mdi-account-question
            </v-icon>
            <span class="text-h6">Cliente sem <br />cadastro no portal</span>
          </v-row>
        </div>

        <v-skeleton-loader
          v-if="loadingCliente && !agradecimento"
          v-bind="attrs"
          type="table-tbody"
        ></v-skeleton-loader>
        <div v-if="!loadingCliente && !agradecimento && isVisible">
          <v-text-field
            v-model="a1Nome"
            label="Nome"
            dense
            readonly
          ></v-text-field>
          <v-text-field
            v-model="a1Rua"
            class="mt-1"
            label="Rua"
            dense
            readonly
          ></v-text-field>
          <v-row dense>
            <v-col col="1">
              <v-text-field
                v-model="a1Numero"
                label="Número"
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col col="1">
              <v-text-field
                v-model="a1Complemento"
                label="Complemento"
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            v-model="a1Bairo"
            label="Bairro"
            dense
            readonly
          ></v-text-field>
          <v-text-field
            v-model="a1Municipio"
            label="Município"
            dense
            readonly
          ></v-text-field>
          <v-row>
            <v-col>
              <v-text-field
                v-model="a1Estado"
                label="Estado"
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="a1Cep"
                label="CEP"
                v-mask="'#####-###'"
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="planoSelecionado" justify="space-between">
            <v-col cols="4">
              <v-autocomplete
                class="text-uppercase"
                style="margin-top: -20px"
                background-color="white"
                filled
                label="nº de Parcelas"
                placeholder="Quantidade de parcelas"
                v-model="parcelaSelecionada"
                :items="parcelas"
                @change="calculaParcela"
              ></v-autocomplete>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="7">
              <div v-if="planoSelecionado">
                <span class="font-weight-medium text-h6">
                  {{ parcelaSelecionada }}{{ "×" }} de
                </span>
                <span class="font-weight-medium text-h4"
                  >R${{ realParcela }}</span
                >
                <span class="font-weight-medium text-h6"
                  >,{{ centavosParcela }}</span
                >
              </div>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="8">
              <v-autocomplete
                class="text-uppercase"
                style="margin-top: -20px"
                background-color="white"
                filled
                dense
                label="Indicador"
                placeholder="Nome do(a) indocador(a)"
                v-model="indicadorSelecionado"
                :items="indicadores"
                item-text="A3_NOME"
                item-value="A3_COD"
                :loading="loadingIndicadores"
                :rules="obrigat(indicadorSelecionado, '1')"
                @focus="loadIndicador"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-btn
                class="mt-2 mb-8"
                style="margin-botton: 20px"
                color="purple"
                dark
                @click="assinarClube"
              >
                Assinar
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div
          v-if="agradecimento"
          class="message"
          style="flex-direction: column"
        >
          <v-row align="center" justify="center">
            <v-icon class="mr-4" color="green" size="128">
              mdi-check-decagram
            </v-icon>
            <p class="text-h6" style="color: green">
              Parabens por assinar nosso clube!!!
            </p>
            <p style="color: black">
              Orçamento de venda numero <strong>{{ orcamento }}</strong
              ><br />criado com sucesso!
            </p>
            <p class="text-h6" style="color: red">
              FINALIZE A VENDA NO PROTHEUS.
            </p>
          </v-row>
          <v-btn
            small
            style="margin-botton: 20px"
            color="blue"
            dark
            @click="
              () => {
                agradecimento = false;
                modal = false;
              }
            "
          >
            Fechar
          </v-btn>
        </div>
      </Swipemodal>
    </v-card>
    <v-bottom-sheet v-model="sheetMessage">
      <v-sheet class="text-center" :color="colorMessage" height="130px">
        <div class="py-3">
          <v-btn class="mx-2" text fab>
            <v-icon v-text="iconMessage" @click="sheetMessage = false"></v-icon>
          </v-btn>
        </div>
        <div class="py-3" style="font-size: 15px" v-text="message"></div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
// import cloneDeep from "lodash/cloneDeep";
import Swipemodal from "nekoo_vue_swipemodal";
import "nekoo_vue_swipemodal/dist/swipemodal.css";
import { Money } from "v-money";
import { dateToAnoMesDia } from "@/utils/dateParse";

export default {
  name: "PlanCard",
  props: {
    description: {
      type: String,
      required: false,
    },
  },
  components: {
    Money,
    Swipemodal,
  },
  computed: {
    ...mapState(["user"]),
  },
  data: () => ({
    snackbar_error: false,
    error: null,
    modal: false,
    isReading: false,
    isEditing: false,
    dialogAdesao: false,
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false,
    },
    real: "0",
    centavos: "00",
    envios: [6, 12],
    visivel: false,
    loadingPlanos: false,
    loadingCliente: false,
    loadingIndicadores: false,
    loadingProdutos: false,
    isVisible: false,
    isLoading: false,

    iconMessage: "",
    colorMessage: "",
    message: "",
    sheetMessage: false,

    planos: [],
    planoSelecionado: null,
    planoMessage: false,
    produtosAdesao: [],
    itensAdesao: [],
    editedItem: {},
    cloneEditedItem: {},
    editedIndex: null,
    tailItensAdesao: {
      icon: "mdi-plus-circle",
      iconColor: "green",
      text: "",
      quantidade: 0,
    },
    clube: null,
    envio: null,
    parcelas: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    parcelaSelecionada: 1,
    valorParcela: null,
    realParcela: "0",
    centavosParcela: "00",
    cpfAssinatura: null,
    cliente: {},
    clienteMessage: false,
    agradecimento: false,
    orcamento: "",
    tipoIndicadores: ["000014"],
    indicadores: [],
    indicadorSelecionado: null,

    a1Cod: null,
    a1Loja: null,
    a1Nome: null,
    a1Rua: null,
    a1Numero: null,
    a1Complemento: null,
    a1Bairo: null,
    a1Municipio: null,
    a1Estado: null,
    a1Cep: null,

    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
  }),
  async mounted() {},
  destroyed() {
    // document.querySelector(`#modal`).removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async loadPlanos() {
      this.loadingPlanos = true;
      this.planoMessage = false;
      this.loadProdutos();
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_API_URL}/clube`,
        );
        this.planos = response.data;

        this.planos.forEach(plano => {
          plano.produtos = plano.produtos.map(p => ({
            icon: "mdi-check-circle",
            iconColor: "blue",
            text: `${p.ZCB_DESCRI.trim()}`,
            quantidade: p.ZCB_QUANT,
            ...p,
          }));
        });
      } catch (err) {
        console.log(err);
      }
      this.loadingPlanos = false;
    },

    async preencheCliente(cpf) {
      if (cpf.length == 14) {
        this.clienteMessage = false;
        this.loadingCliente = true;
        const cgc = this.cpfSanitization(cpf);
        if (!this.validaCPF(cgc)) {
          this.error = "CFP inválido!";
          this.snackbar_error = true;
          this.loadingCliente = false;
          return;
        }

        this.calculaParcela();

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "",
          // "A1_COD, A1_LOJA, A1_NOME, A1_END, A1_ZNUM, A1_COMPLEM, A1_MUN, A1_BAIRRO, A1_EST, A1_CEP",
          WHERE: `A1_CGC='${cgc}'`,
          TABELA: "SA1",
        };

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_BASE_API_URL}/consultas`,
            dados,
          );
          this.cliente = response.data[0];
          this.loadingCliente = false;

          if (this.cliente) {
            this.a1Cod = this.cliente.A1_COD;
            this.a1Loja = this.cliente.A1_LOJA;
            this.a1Nome = this.cliente.A1_NOME;
            this.a1Rua = this.cliente.A1_END;
            this.a1Numero = this.cliente.A1_ZNUM;
            this.a1Complemento = this.cliente.A1_COMPLEM;
            this.a1Municipio = this.cliente.A1_MUN;
            this.a1Bairo = this.cliente.A1_BAIRRO;
            this.a1Estado = this.cliente.A1_EST;
            this.a1Cep = this.cliente.A1_CEP;

            this.isVisible = true;
          } else {
            this.clienteMessage = true;
            this.loadingCliente = false;
          }
        } catch (err) {
          if (err.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
          console.log(err);
          this.error = "Erro de conexão com a base";
          this.snackbar_error = true;
          this.loadingCliente = false;
          this.limpaFormulario();
          return;
        }
      }
    },

    async assinarClube() {
      if (
        !this.user.cod_vendedor ||
        this.user.cod_vendedor.trim().length <= 0
      ) {
        (this.snackbar_error = true),
          (this.error = `Você não tem cadastro de vendedor ativo, não pode finalizar a venda!`);
        return;
      }

      if (!this.indicadorSelecionado) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      const ZCC = this.planos
        .filter(e => e.id === this.clube)
        .map(p => {
          return {
            ZCC_CLUBE: p.id,
            ZCC_CLIENT: this.a1Cod,
            ZCC_LOJA: this.a1Loja,
            ZCC_QTDPAR: parseInt(this.parcelaSelecionada),
            ZCC_DTASS: dateToAnoMesDia(new Date()),
            ZZC_STATUS: "01", // ASSINADO
            ZCC_QTDENV: parseInt(this.planoSelecionado.envios),
            ZCC_VEND: this.user.cod_vendedor,
            ZCC_VEND2: this.indicadorSelecionado,
            ZCC_ADESAO: "1",
            ZCC_VLTOT: parseFloat(`${this.real}.${this.centavos}`),
          };
        });

      const produtosPlano = this.planos
        .filter(e => e.id === this.clube)
        .map(p => p.produtos);

      const ZCG = produtosPlano[0].map((e, index) => {
        return {
          ZCG_ITEM: `${("000" + (index + 1)).slice(-3)}`,
          ZCG_CODPRO: e.ZCB_CODPRO,
          ZCG_DESCRI: e.ZCB_DESCRI,
          ZCG_VLITEM: e.ZCB_VLITEM,
          ZCG_VLDESC: e.ZCB_VLDESC,
          ZCG_VLUNIT: e.ZCB_VLUNIT,
          ZCG_QUANT: e.ZCB_QUANT,
          ZCG_VLNOTA: e.ZCB_VLNOTA,
        };
      });

      this.itensAdesao.pop();

      const ZCH = this.itensAdesao.map(e => {
        return {
          ZCH_CODCLI: this.a1Cod,
          ZCH_CODPRO: e.produto,
          ZCH_DESCRI: e.text,
          ZCH_QUANT: e.quantidade,
          ZCH_VALOR: e.valor,
        };
      });

      const dados = {
        // empresa: "01",
        filial: this.user.filialVendedor,
        ZCC,
        ZCG,
        ZCH,
      };

      try {
        this.isLoading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_API_URL}/assinarClube`,
          dados,
        );
        if (response.data.STATUS == "true") {
          console.log(response.data);

          this.limpaFormulario();
          this.orcamento = response.data.CODIGO;
          this.isLoading = false;
          this.agradecimento = true;
        } else {
          this.isLoading = false;
          this.error = response.data.ERRO;
          this.snackbar_error = true;
          return;
        }
      } catch (err) {
        console.log(err);
        this.error = "Erro de conexão com a base";
        this.snackbar_error = true;
        return;
      }
    },

    async loadIndicador() {
      this.loadingIndicadores = true;

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_API_URL}/clube/indicadores`,
          {
            tipos: this.tipoIndicadores,
          },
        );
        this.indicadores = response.data;
      } catch (err) {
        console.log(err);
      }
      this.loadingIndicadores = false;
    },

    async loadProdutos() {
      this.loadingProdutos = true;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_API_URL}/node/consultas`,
          {
            FIELDS: "B1_COD, B1_DESC",
            TABELA: "SB1",
            WHERE: "B1_ZZCLUBE='S' ORDER BY B1_DESC",
          },
        );

        this.produtosAdesao = response.data.map(p => ({
          text: `${p.B1_COD.trim()}-${p.B1_DESC.trim()}`,
          value: `${p.B1_COD.trim()}-${p.B1_DESC.trim()}`,
        }));
      } catch (error) {
        if (error.response.status === 401) {
          this.$store.commit("SetUser", null);
          return;
        }
      }
      this.loadingProdutos = false;
    },

    filtraPlano() {
      if (this.clube) {
        [this.planoSelecionado] = this.planos.filter(p => p.id === this.clube);
        // .filter((p) => p.envios === this.envio);

        if (this.planoSelecionado) {
          this.planoMessage = false;
          [this.real, this.centavos] = this.planoSelecionado.preco
            .toString()
            .split(".");

          if (!this.centavos) {
            this.centavos = "00";
          } else if (this.centavos.length < 2) {
            this.centavos = `${this.centavos}0`;
          }

          this.itensAdesao = [this.tailItensAdesao];

          this.valorParcela = this.planoSelecionado.preco;
          this.calculaParcela();
        } else {
          this.planoMessage = true;
          this.planoSelecionado = null;
        }
      }
    },

    adicionaItemAdesao() {
      this.isReading = false;
      this.isEditing = false;
      this.editedItem = {
        icon: "mdi-delete",
        iconColor: "red",
        produto: null,
        text: null,
        quantidade: 1,
        valor: 0.0,
      };

      this.dialogAdesao = true;
      this.loadingProdutos = true;

      this.loadingProdutos = false;
    },

    fechaDialogAdesao() {
      this.dialogAdesao = false;
      this.editedItem = {};
    },

    salvarItemAdesao() {
      if (!this.editedItem.produto) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      const [code, desc] = this.editedItem.produto.split("-");
      this.editedItem = {
        ...this.editedItem,
        produto: code,
        text: desc,
        quantidade: parseInt(this.editedItem.quantidade),
      };

      if (this.isEditing) {
        this.planoSelecionado.preco -=
          this.cloneEditedItem.quantidade * this.cloneEditedItem.valor;
        this.valorParcela -=
          this.cloneEditedItem.quantidade * this.cloneEditedItem.valor;
      }

      const quantidade = this.editedItem.quantidade;
      if (quantidade !== 0) {
        this.planoSelecionado.preco += quantidade * this.editedItem.valor;
        [this.real, this.centavos] = this.planoSelecionado.preco
          .toString()
          .split(".");
        if (!this.centavos) {
          this.centavos = "00";
        } else if (this.centavos.length < 2) {
          this.centavos = `${this.centavos}0`;
        }

        this.valorParcela = this.planoSelecionado.preco;
        this.calculaParcela();
      }

      if (!this.isReading && !this.isEditing) {
        this.itensAdesao.splice(-1, 1, this.editedItem, this.tailItensAdesao);
      }

      if (this.isEditing && this.editedIndex !== -1) {
        this.itensAdesao.splice(this.editedIndex, 1, this.editedItem);
      }

      this.dialogAdesao = false;
    },

    editaItemAdesao(item) {
      this.isReading = false;
      this.isEditing = true;
      this.dialogAdesao = true;

      this.cloneEditedItem = item;
      this.editedIndex = this.itensAdesao.findIndex(e => e === item);
      this.editedItem = {
        ...item,
        produto: `${item.produto}-${item.text}`,
      };
    },

    removeItemAdesao(item) {
      const index = this.itensAdesao.findIndex(e => e === item);
      const { quantidade, valor } = this.itensAdesao[index];
      this.planoSelecionado.preco -= quantidade * valor;
      [this.real, this.centavos] = this.planoSelecionado.preco
        .toString()
        .split(".");
      if (!this.centavos) {
        this.centavos = "00";
      } else if (this.centavos.length < 2) {
        this.centavos = `${this.centavos}0`;
      }
      this.valorParcela = this.planoSelecionado.preco;
      this.calculaParcela();

      this.itensAdesao.splice(index, 1);
    },

    async getPrecoItem() {
      this.dialogAwaitConsulta = true;
      this.msgDialogConsulta = "Buscando preço do produto...";

      const [code] = this.editedItem.produto.split("-");

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_API_URL}/node/consultas`,
          {
            FIELDS: "B1_PRV1",
            TABELA: "SB1",
            WHERE: `B1_COD = '${code}'`,
          },
        );
        this.editedItem.valor = response.data[0].B1_PRV1;
      } catch (error) {
        if (error.response.status === 401) {
          this.$store.commit("SetUser", null);
          return;
        }
      }
      this.dialogAwaitConsulta = false;
    },

    calculaParcela() {
      this.valorParcela =
        Math.round(
          (100 * this.planoSelecionado.preco) / this.parcelaSelecionada,
        ) / 100;

      [this.realParcela, this.centavosParcela] = this.valorParcela
        .toString()
        .split(".");

      if (!this.centavosParcela) {
        this.centavosParcela = "00";
      } else if (this.centavosParcela.length < 2) {
        this.centavosParcela = `${this.centavosParcela}0`;
      }
    },

    limpaFormulario() {
      this.isVisible = false;
      this.cpfAssinatura = null;
      this.parcelaSelecionada = 1;
      this.planoSelecionado = null;
      this.indicadorSelecionado = null;
      this.itensAdesao = [this.tailItensAdesao];
      this.clube = null;

      this.a1Cod = null;
      this.a1Loja = null;
      this.a1Nome = null;
      this.a1Rua = null;
      this.a1Numero = null;
      this.a1Complemento = null;
      this.a1Municipio = null;
      this.a1Bairo = null;
      this.a1Estado = null;
      this.a1Cep = null;
    },

    obrigat(value, obrigatorio) {
      if (!value && obrigatorio === "1") {
        return ["Campo obrigatório!"];
      }
    },

    cpfSanitization(cpf) {
      const regex = new RegExp(/[^0-9]|[/ /]/g, "");
      let temp = cpf.toString().trim();
      while (temp.match(regex) !== null) {
        temp = temp.replace(regex, "");
      }
      if (temp.length !== 11) throw Error(`invalid CPF: ${cpf}`);
      return temp;
    },

    validaCPF(cpf) {
      var numeros, digitos, soma, i, resultado, digitos_iguais;
      digitos_iguais = 1;
      if (cpf.length < 11) return false;
      for (i = 0; i < cpf.length - 1; i++)
        if (cpf.charAt(i) != cpf.charAt(i + 1)) {
          digitos_iguais = 0;
          break;
        }
      if (!digitos_iguais) {
        numeros = cpf.substring(0, 9);
        digitos = cpf.substring(9);
        soma = 0;
        for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado != digitos.charAt(0)) return false;
        numeros = cpf.substring(0, 10);
        soma = 0;
        for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado != digitos.charAt(1)) return false;
        return true;
      } else return false;
    },
    // formataSXHELP(string) {
    //   const erro = string.split("\r\n");
    //   const index = erro.findIndex((string) =>
    //     string.includes("Mensagem da solucao")
    //   );
    //   const [, message] = erro[index].split("[");

    //   console.log(erro);
    //   return message;
    // },
  },
};
</script>

<style>
#modal_back {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
}

#modal {
  position: absolute;
  bottom: 0;
  height: 65%;
  width: 100%;
  left: 50%;
  box-shadow: 0px -5px 5px 0 rgba(0, 0, 0, 0.2);
}

#modal_contents {
  padding: 24px;
}

@-webkit-keyframes open {
  0% {
    height: 0;
  }
  to {
    height: 65%;
  }
}
@keyframes open {
  0% {
    height: 0;
  }
  to {
    height: 65%;
  }
}
@-webkit-keyframes close {
  0% {
    height: 65%;
  }
  to {
    height: 0;
  }
}
@keyframes close {
  0% {
    height: 65%;
  }
  to {
    height: 0;
  }
}

.message {
  width: 400px;
  height: 300px;
  color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
