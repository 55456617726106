<template>
  <div class="pa-4">
    <div class="tableCli">
      <v-data-table
        :headers="headerOcorrencias"
        dense
        :items="itensOcorrencias"
        class="elevation-1"
        no-data-text="Sem dados..."
        fixed-header
        :height="altura"
        :loading="loading"
        :search="search"
        :items-per-page="20"
        :footer-props="{
          disableItemsPerPage: true,
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-row dense>
              <v-col cols="2">
                <v-btn
                  class="mx-5 mt-2"
                  fab
                  x-small
                  dark
                  color="indigo"
                  @click="novo()"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" v-if="NrRegFiltro !== null">
                <span style="font-size: 0.7rem"
                  >Filtro aplicado no <strong>{{ filtroEscolhido }}</strong
                  >, {{ NrRegFiltro }} registro(s) encontrado(s).</span
                >
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  fab
                  color="error"
                  @click="LimpaFiltro()"
                >
                  <v-icon dark class="ml-1">mdi-eraser</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2">
                <v-select
                  v-model="filtroEscolhido"
                  :items="itemsFiltro"
                  label="Escolha o campo"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="digite aqui.."
                  v-model="stringFiltro"
                  :disabled="!filtroEscolhido"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  color="indigo"
                  @click="filtraRegistro(filtroEscolhido, stringFiltro)"
                >
                  <v-icon dark class="ml-1">mdi-account-search</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar cadastro</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="verDados(item)"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                >mdi-eye</v-icon
              >
            </template>
            <span>Visualizar cadastro</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="dadosCli text-center">
      <v-dialog v-model="DialogDados" width="90%" persistent>
        <v-card v-if="boilerplate" ref="form">
          <v-card-title
            class="headline grey lighten-2"
            v-text="titleDialog"
          ></v-card-title>
          <v-card-text>
            <v-row dense class="mt-5">
              <v-col
                v-for="campo in camposTela"
                :key="campo.ZZP_CAMPO"
                :cols="campo.ZZP_COLS"
              >
                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :label="campo.ZZP_TITULO"
                  v-if="campo.ZZP_TIPO === 'T'"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  class="text-uppercase"
                  dense
                  :readonly="isReading || campo.ZZP_VISUAL.trim() === '2'"
                ></v-text-field>
                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :label="campo.ZZP_TITULO"
                  v-if="campo.ZZP_TIPO === 'D'"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  v-mask="'##/##/####'"
                  class="text-uppercase"
                  dense
                  :readonly="isReading || campo.ZZP_VISUAL.trim() === '2'"
                ></v-text-field>
                <v-select
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :items="campo.ITEMS"
                  :label="campo.ZZP_TITULO"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  v-if="campo.ZZP_TIPO === 'S'"
                  class="text-uppercase"
                  dense
                  :readonly="isReading || campo.ZZP_VISUAL.trim() === '2'"
                ></v-select>
                <v-autocomplete
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                  style="margin-top: -22px"
                  dense
                  :items="itemsTipo"
                  @click="carregaItems(campo)"
                  @focus="carregaItems(campo)"
                  no-data-text="Buscando dados..."
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  filled
                  :label="campo.ZZP_TITULO"
                  background-color="white"
                  v-if="campo.ZZP_TIPO === 'A'"
                  class="text-uppercase"
                  :readonly="isReading || campo.ZZP_VISUAL.trim() === '2'"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-col cols="5">
              <v-progress-linear
                v-model="progress"
                v-if="progress > 0"
                color="blue-grey"
                height="25"
              >
                <template v-slot="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              v-if="!isReading && !isEditing"
              text
              @click="salva()"
              >Salvar</v-btn
            >
            <v-btn
              color="primary"
              v-if="!isReading && isEditing"
              text
              @click="salva(id)"
              >Salvar</v-btn
            >
          </v-card-actions>
          <v-divider></v-divider>
          <div class="px-3">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="limpaVariaveis()">Fechar</v-btn>
          </div>
        </v-card>
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="boilerplate"
          type="table"
          class="mx-auto"
          v-if="!boilerplate"
        ></v-skeleton-loader>
      </v-dialog>
    </div>
    <v-bottom-sheet v-model="sheetMessage">
      <v-sheet class="text-center" :color="colorMessage" height="130px">
        <div class="py-3">
          <v-btn class="mx-2" text fab>
            <v-icon v-text="iconMessage" @click="sheetMessage = false"></v-icon>
          </v-btn>
        </div>
        <div class="py-3" style="font-size: 15px" v-text="message"></div>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-model="dialogSuccessSaveOcorrencia" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">Ocorrencia</v-card-title>
        <v-card-text class="mt-5 title">{{ mensagem }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="limpaVariaveis">fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog exibido enquanto está salvando a ocorrência -->
    <v-dialog v-model="dialogAwaitSaveOcorrencia" width="500">
      <v-card>
        <v-overlay :value="dialogAwaitSaveOcorrencia" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title class="headline grey lighten-2">Ocorrência</v-card-title>
        <v-card-subtitle>
          <p class="body-1">Salvando a ocorrência...</p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
    <!-- dialog exibido enquanto está salvando a ocorrência -->

    <v-dialog v-model="dialogErrosOcorrencia" width="900">
      <v-card max-width="900" class="mx-auto">
        <v-card-title class="headline red lighten-2" style="color: white"
          >Erros ao salvar</v-card-title
        >

        <v-card-text>
          <v-list disabled>
            <v-subheader>Erros</v-subheader>
            <v-list-item-group v-model="aError" color="primary">
              <v-list-item v-for="(item, i) in aError" :key="i">
                <v-list-item-icon>
                  <v-icon color="red" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 0.8rem"
                    v-text="item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogErrosOcorrencia = false"
            >fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import moment from "moment";
//import { component } from 'vue/types/umd';
export default {
  name: "Ocorrencias",
  data: () => {
    return {
      isNew: false,
      NrRegFiltro: null,
      codCli: null,
      lojaCli: null,
      progress: 0,
      indFiltro: "C",
      filtroEscolhido: null,
      id: null,
      aError: [],
      itemsFiltro: ["Código", "Descrição"],
      stringFiltro: null,
      dialogErrosOcorrencia: false,
      dialogAwaitSaveOcorrencia: false,
      dialogSuccessSaveOcorrencia: false,
      mensagem: null,
      errosTE: false,
      sheetMessage: false,
      colorMessage: "",
      iconMessage: "",
      titleDialog: "",
      message: "",
      DialogDados: false,
      isReading: false,
      isEditing: false,
      search: null,
      loading: true,
      altura: 0,
      perPage: 0,
      headerOcorrencias: [],
      itensOcorrencias: [],
      itensOcorrenciasBKP: [],
      itemsTipo: [],
      camposTela: [],
      dadosTela: [],
      cliente: {},
      boilerplate: false,
      dadosCep: [],
      DtInicial: new Date().toISOString().substr(0, 10),
      countObrigatorio: 0,
      cloneDadosTela: null,
      interval: 0,
      ZZW_COD: "",
      ZZW_OCORR: "",
      ZZW_CGC: "",
      stringCampos: "",
      top500ZZW: [],
    };
  },
  computed: {
    ...mapState(["metadados", "codLojaCliente", "origem"]),
    DataFormatadaInicial() {
      return moment(this.DtInicial).format("DD/MM/YYYY");
    },
  },
  methods: {
    ...mapMutations(["setOrigem"]),
    LimpaFiltro() {
      this.stringFiltro = null;
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.itensOcorrencias = this.top500ZZW;
    },
    async filtraRegistro(campo, stringFiltro) {
      if (!campo || !stringFiltro) return;

      let WHERE = "";

      if (campo === "Código") {
        WHERE = `ZZW_COD LIKE '^${stringFiltro}^' `;
      }

      if (campo === "Descrição") {
        WHERE = `ZZW_OCORR LIKE '^${stringFiltro.toUpperCase()}^'`;
      }

      this.loading = true;
      this.itensOcorrencias = [];

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: WHERE,
        TABELA: "ZZW",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/getOcorrencias`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            if (e.ZZW_DTNASC) {
              e.ZZW_DTNASC = `${e.ZZW_DTNASC.substr(
                6,
                2,
              )}/${e.ZZW_DTNASC.substr(4, 2)}/${e.ZZW_DTNASC.substr(0, 4)}`;
            }
          });
          this.itensOcorrencias = res.data;
          this.NrRegFiltro = this.itensOcorrencias.length;
        })
        .catch(err => console.log(err));

      this.loading = false;
    },
    obrigat(value, obrigatorio) {
      if (!value && obrigatorio === "1") {
        return ["Campo obrigatório!"];
      }
    },
    queryAndIndeterminate() {
      this.progress = 0;

      this.interval = setInterval(() => {
        if (this.progress === 100) {
          clearInterval(this.interval);
          this.progress = 0;
          return;
          //return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.progress += 1;
      }, 100);
    },
    //Função para remover caracteres especiais na Notas
    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");
      return text.toUpperCase();
    },
    async salva(id) {
      const keysObrigat = [];
      this.camposTela.filter(e => {
        if (e.ZZP_OBRIGA === "1") {
          const campo = e.ZZP_CAMPO.trim();
          keysObrigat.push({
            campo,
          });
        }
      });

      let verifica = true;

      this.cloneDadosTela = this.dadosTela;

      const keys = Object.keys(this.cloneDadosTela);

      keysObrigat.forEach(e => {
        if (keys.indexOf(e.campo) < 0) {
          verifica = false;
        }
      });

      if (!verifica) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      keys.forEach(e => {
        this.cloneDadosTela[e] = this.cloneDadosTela[e].toUpperCase();
      });

      this.camposTela.forEach(e => {
        let value = null;
        if (e.ZZP_TIPO.trim() === "A") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("-");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
        }
        if (e.ZZP_TIPO.trim() === "S") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("=");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
        }
        if (e.ZZP_TIPO.trim() === "D") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("/");
          this.cloneDadosTela[
            e.ZZP_CAMPO.trim()
          ] = `${value[2]}${value[1]}${value[0]}`;
        }
        if (
          e.ZZP_TIPO.trim() === "T" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          value = this.removeAcento(this.cloneDadosTela[e.ZZP_CAMPO.trim()]);
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value;
        }
      });

      this.queryAndIndeterminate();

      if (id) {
        let ZZW = { ...this.cloneDadosTela };

        ZZW.ZZW_COD = id;

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZZW,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/svOcorrencia`;
        await axios
          .put(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = `Ocorrência salva com sucesso.`;
              this.dialogAwaitSaveOcorrencia = false;
              this.dialogSuccessSaveOcorrencia = true;
              this.progress = 100;
              this.montaBrowser();
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({
                  text: `${e}`,
                  icon: "mdi-send",
                });
              });
              this.dialogAwaitSaveOcorrencia = false;
              this.dialogErrosOcorrencia = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => {
            this.aError = [];
            this.aError.push({ text: `${err}`, icon: "mdi-send" });
            this.dialogAwaitSaveOcorrencia = false;
            this.dialogErrosOcorrencia = true;
            this.progress = 100;
            return;
          });
      } else {
        let ZZW = { ...this.cloneDadosTela };

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZZW,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/svOcorrencia`;
        await axios
          .post(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = `Ocorrência salva com o código: ${res.data.CODIGO}`;
              this.dialogAwaitSaveOcorrencia = false;
              this.dialogSuccessSaveOcorrencia = true;
              this.progress = 100;
              this.montaBrowser();
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({
                  text: `${e}`,
                  icon: "mdi-send",
                });
              });
              this.dialogAwaitSaveOcorrencia = false;
              this.dialogErrosOcorrencia = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => console.log(err));
      }

      this.camposTela.forEach(e => {
        let value = null;
        if (e.ZZP_TIPO.trim() === "D") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()];
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = `${value.substr(
            6,
            2,
          )}/${value.substr(4, 2)}/${value.substr(0, 4)}`;
        }
      });
      this.progress = 100;
    },
    limpaVariaveis() {
      // console.log(this.itensOcorrencias)
      // console.log(this.itensOcorrenciasBKP)
      this.itensOcorrencias = this.itensOcorrenciasBKP;
      this.itensOcorrenciasBKP = [];
      this.DialogDados = false;
      this.dialogSuccessSaveOcorrencia = false;
      this.montaBrowser();
    },
    async carregaDados() {
      this.boilerplate = false;
      let campos = [];

      let ZZW = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === "MD022" && e.ZZP_USADO === "1",
      );

      ZZW.map(e => {
        if (e.ZZP_CBOX.trim().length > 0) {
          const dados = e.ZZP_CBOX.split(";");
          e.ITEMS = dados.map(i => {
            return `${i}`;
          });
        }
        if (e.ZZP_TIPO.trim() === "D") {
          e.MASK = "##/##/####";
        }
        if (e.ZZP_OBRIGA === "1") {
          this.countObrigatorio++;
        }
      });

      campos = ZZW;
      this.camposTela = campos;
      this.boilerplate = true;
    },
    async reload(item) {
      item.ITEMS = await this.chamaConsulta(item);

      this.camposTela.map(e => {
        if (e.ZZP_CAMPO.trim() === item.ZZP_CAMPO.trim()) {
          e.ITEMS = item.ITEMS;
        }
      });

      return this.camposTela;
    },
    chamaConsulta(item) {
      let dados = {};
      let items = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: ``,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      return items;
    },
    async montaBrowser() {
      this.filtroEscolhido = null;
      this.stringFiltro = null;
      this.NrRegFiltro = null;
      this.altura = window.screen.availHeight - 300;
      this.loading = true;
      let ZZP = [];
      let dados = {};
      let url = ``;

      this.headerOcorrencias = [];
      this.headerOcorrencias.push({
        text: "Ações",
        value: "actions",
        align: "center",
        width: 10,
        caption: "title",
      });

      ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD022");

      ZZP.map(e => {
        let width = 0;
        if (e.ZZP_COLS <= 2) {
          width = 90;
        } else if (e.ZZP_COLS <= 3) {
          width = 200;
        } else {
          width = 250;
        }

        this.headerOcorrencias.push({
          text: e.ZZP_TITULO.trim(),
          value: e.ZZP_CAMPO.trim(),
          align: "center",
          width: width,
          caption: "title",
        });
      });

      const where = [...new Set(ZZP.map(e => e.ZZP_WHERE.trim()))];

      let WHERE = "";

      where.forEach(e => {
        if (e.trim().length > 0) {
          WHERE = e.trim();
        }
      });

      const tabelaAux = [...new Set(ZZP.map(item => item.ZZP_TABELA))];

      this.stringCampos = "TOP 500 ";

      let tabela = tabelaAux[0];

      let cont = 0;
      ZZP.forEach(e => {
        if (e.ZZP_TABELA === tabela) {
          cont++;
        }
      });

      for (let i = 0; i < cont; i++) {
        if (ZZP[i].ZZP_TABELA === tabela) {
          if (i + 1 >= cont) {
            this.stringCampos += `${ZZP[i].ZZP_CAMPO.trim()}`;
          } else {
            this.stringCampos += `${ZZP[i].ZZP_CAMPO.trim()},`;
          }
        }
      }

      if (this.codLojaCliente) {
        const dadosCli = this.codLojaCliente.split("-");
        const codigoCliente = dadosCli[0];
        const lojaCliente = dadosCli[1];

        WHERE = ` ZZW_COD = '${codigoCliente}' AND ZZW_LOJA='${lojaCliente}' AND ZZW_MSBLQL='2' ORDER BY ZZW_OCORR`;
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: `${WHERE}`,
        TABELA: tabela.substr(0, 3),
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/getOcorrencias`;

      this.itensOcorrencias = [];

      await axios
        .post(url, dados)
        .then(res => {
          // res.data.map((e) => {
          //     console.log(e)
          // });
          this.itensOcorrencias = res.data;
          this.top500ZZW = res.data;
        })
        .catch(err => console.log(err));

      this.perPage = this.itensOcorrencias.length;
      this.loading = false;
    },
    verDados(item) {
      this.carregaDados();
      this.titleDialog = `Visualizando a ocorrência: ${
        item.ZZW_COD
      }-${item.ZZW_OCORR.trim()}`;
      this.dadosTela = item;
      this.isReading = true;
      this.isEditing = false;
      this.DialogDados = true;
    },
    editItem(item) {
      this.itensOcorrenciasBKP = [...this.itensOcorrencias];
      // console.log(this.top500ZZW)
      // console.log(this.itensOcorrenciasBKP)
      item.ZZW_OCORR = item.ZZW_OCORR.trim();
      this.carregaDados();
      this.titleDialog = `Editando a ocorrência: ${
        item.ZZW_COD
      }-${item.ZZW_OCORR.trim()}`;
      this.id = `${item.ZZW_COD}`;

      this.carregaDados();

      this.isReading = false;
      this.isEditing = true;
      this.dadosTela = item;
      this.DialogDados = true;
    },
    async carregaItems(item) {
      let dados = {};
      let items = [];
      this.itemsTipo = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: ``,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
          this.itemsTipo = items;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    novo() {
      this.dadosTela = [];
      this.isReading = false;
      this.isEditing = false;
      this.titleDialog = `Nova ocorrência`;
      this.isNew = true;
      this.carregaDados();
      this.DialogDados = true;
    },
  },
  mounted() {
    this.montaBrowser();
  },
};
</script>

<style>
.v-label {
  font-size: 0.7rem;
}
input {
  font-size: 0.8rem;
}
.text-center {
  font-size: 0.7rem !important;
}
.text-start {
  font-size: 0.7rem !important;
}
tbody tr:nth-of-type(odd) {
  background-color: #d8f6fa;
}
.v-select__selection {
  font-size: 0.8rem !important;
}
</style>
