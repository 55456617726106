<template>
  <v-app id="inspire">
    <div class="d-flex" style="width: 100vw;">
      <Menu />
      <!-- <Header /> -->
      <ContentTabs1 />
    </div>
    <DialogVersao />
    <!-- <Footer /> -->
    <v-row justify="center">
      <v-dialog v-model="dialogMetadados" width="500" persistent>
        <v-card>
          <v-overlay :value="dialogMetadados" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title>Sales Move</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Carregando metadados, aguarde...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

import Menu from "../templates/Menu";
//import Header from "../templates/Header";
//import Footer from "../templates/Footer";
import ContentTabs1 from "../templates/ContentTabs1";
import DialogVersao from "../versao/versao.vue";
export default {
  name: "home-page",
  components: {
    Menu,
    //Header,
    ContentTabs1,
    DialogVersao,
    //Footer,
  },
  computed: mapState(["metadados"]),
  props: {
    source: String,
  },
  data: () => ({
    dialogMetadados: false,
    drawer: null,
  }),
  methods: {
    async carregaMeta() {
      this.dialogMetadados = true;
      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "*",
        WHERE: `D_E_L_E_T_ = ' ' AND ZZP_MODELO<>' ' ORDER BY ZZP_ORDEM`,
        TABELA: "ZZP",
      };

      /* rota com validacao do token no backend */
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.$store.commit("SetMetadados", null);
          this.$store.commit("SetMetadados", res.data);
          localStorage.setItem(
            process.env.VUE_APP_METADADOS_KEY,
            JSON.stringify(res.data),
          );
          sessionStorage.setItem(
            process.env.VUE_APP_METADADOS_KEY,
            JSON.stringify(res.data),
          );
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
          console.log({ err });
          this.dialogMetadados = false;
          this.posicao = "bottom";
          this.color = "error";
          this.icone = "mdi-alert-circle-outline";
          this.mensagem =
            "Aconteceu algum erro ao criar o metadados, tente novamente!";
          this.snackbar = true;
          localStorage.removeItem(process.env.VUE_APP_USER_KEY);
          localStorage.removeItem(process.env.VUE_APP_METADADOS_KEY);
          this.$store.commit("SetUser");
          this.$store.commit("SetMetadados", null);
          return;
        });

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "",
        WHERE: "",
        TABELA: "ZT1",
      };

      await axios
        .post(url, dados)
        .then(res => {
          this.$store.commit("setFormsFace", res.data);
        })
        .catch(err => {
          console.log(err);
          this.dialogMetadados = false;
          this.posicao = "bottom";
          this.color = "error";
          this.icone = "mdi-alert-circle-outline";
          this.mensagem =
            "Aconteceu algum erro ao criar o metadados, tente novamente!";
          this.snackbar = true;
          localStorage.removeItem(process.env.VUE_APP_USER_KEY);
          localStorage.removeItem(process.env.VUE_APP_METADADOS_KEY);
          this.$store.commit("SetUser");
          this.$store.commit("SetMetadados", null);
          return;
        });

      this.dialogMetadados = false;
    },
  },
  mounted() {
    const jsonMeta = localStorage.getItem(process.env.VUE_APP_METADADOS_KEY);

    if (!jsonMeta) {
      this.carregaMeta();
    }
  },
};
</script>

<style></style>
