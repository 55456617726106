<template>
  <div class="pa-3">
    <v-banner single-line color="indigo lighten-4">
      <v-icon slot="icon" color="warning" size="36">mdi-monitor</v-icon>Telas
      disponíveis para edição
      <template v-slot:actions></template>
    </v-banner>
    <v-item-group active-class="primary" class="linkto">
      <v-row align="center" justify="start">
        <v-col v-for="item in itensTela" :key="item.Tela" :cols="item.flex">
          <v-item class="linkto">
            <v-card class height="100" :to="item.link" color="grey lighten-4">
              <div align="center" justify="center">
                <v-icon size="60" :color="item.color" class="mt-2">{{
                  item.icon
                }}</v-icon>
                <p>{{ item.Tela }}</p>
              </div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
  </div>
</template>

<script>
export default {
  name: "telasSistema",
  data: () => ({
    itensTela: [
      {
        Tela: "Clientes",
        link: "/Tclientes",
        icon: "mdi-account-group",
        flex: 1,
      },
    ],
  }),
};
</script>

<style></style>
