<template id="infoDados">
  <div class="detalhes">
    <v-row justify="center">
      <v-dialog
        v-model="dialogDetalhes"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary" dense>
            <v-btn small class="ml-1" icon dark @click="dialogDetalhes = false">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Detalhes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text>
                {{ `${codigo}/${loja} - ${nomeCliente}` }}
              </v-btn>
            </v-toolbar-items>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-overlay :value="overlay" absolute align="center">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>

          <v-container fluid>
            <v-row dense justify="center">
              <v-col
                v-for="card in cards"
                :key="card.title"
                cols="12"
                :class="`d-flex align-center justify-${card.justify} mb-6`"
              >
                <v-expansion-panels>
                  <v-expansion-panel width="95%" :class="card.color">
                    <v-expansion-panel-header>
                      <v-spacer></v-spacer>
                      <v-row>
                        <v-badge
                          class="mt-1"
                          color="primary"
                          :content="card.items.length"
                          v-if="card.items.length > 0"
                          bordered
                        >
                          {{ card.title }}
                        </v-badge>
                        <span v-if="card.items.length <= 0">{{
                          card.title
                        }}</span>
                        <v-spacer></v-spacer>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content transition="scale-transition">
                      <div>
                        <v-data-table
                          :headers="card.headers"
                          fixed-header
                          dense
                          :items="card.items"
                          class="elevation-1"
                          no-data-text="Sem dados..."
                          :items-per-page="card.items.length"
                          hide-default-footer
                          :loading="loadingAgendas"
                          :height="
                            card.items.length <= 20
                              ? 250
                              : card.items.length * 10
                          "
                        >
                          <template v-slot:top>
                            <v-toolbar flat color="white">
                              <v-row dense v-if="card.title === 'Agendas'">
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-select
                                    v-model="filtroEscAgenda"
                                    :items="itemsFiltroAgenda"
                                    label="Escolha o campo"
                                  ></v-select>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    placeholder="digite aqui.."
                                    v-model="stringFiltroAgenda"
                                    :disabled="!filtroEscAgenda"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    color="indigo"
                                    @click="
                                      filtraRegistro(
                                        'Agenda',
                                        filtroEscAgenda,
                                        stringFiltroAgenda,
                                        card.headers,
                                      )
                                    "
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-account-search</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    v-if="NrRegFiltroAgenda"
                                    color="error"
                                    @click="LimpaFiltro('Agenda')"
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-eraser</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row dense v-if="card.title === 'Financeiro'">
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-select
                                    v-model="filtroEscFinan"
                                    :items="itemsFiltroFinan"
                                    label="Escolha o campo"
                                  ></v-select>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    placeholder="digite aqui.."
                                    v-model="stringFiltroFinan"
                                    :disabled="!filtroEscFinan"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    color="indigo"
                                    @click="
                                      filtraRegistro(
                                        'Financeiro',
                                        filtroEscFinan,
                                        stringFiltroFinan,
                                        card.headers,
                                      )
                                    "
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-account-search</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    v-if="NrRegFiltroFinan"
                                    color="error"
                                    @click="LimpaFiltro('Financeiro')"
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-eraser</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row dense v-if="card.title === 'Faturamento'">
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-select
                                    v-model="filtroEscFat"
                                    :items="itemsFiltroFat"
                                    label="Escolha o campo"
                                  ></v-select>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    placeholder="digite aqui.."
                                    v-model="stringFiltroFat"
                                    :disabled="!filtroEscFat"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    color="indigo"
                                    @click="
                                      filtraRegistro(
                                        'Faturamento',
                                        filtroEscFat,
                                        stringFiltroFat,
                                        card.headers,
                                      )
                                    "
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-account-search</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    v-if="NrRegFiltroFat"
                                    color="error"
                                    @click="LimpaFiltro('Faturamento')"
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-eraser</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row
                                dense
                                v-if="card.title === 'Ordem(s) de Serviço'"
                              >
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-select
                                    v-model="filtroEscOs"
                                    :items="itemsFiltroOs"
                                    label="Escolha o campo"
                                  ></v-select>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    placeholder="digite aqui.."
                                    v-model="stringFiltroOs"
                                    :disabled="!filtroEscOs"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    color="indigo"
                                    @click="
                                      filtraRegistro(
                                        'Os',
                                        filtroEscOs,
                                        stringFiltroOs,
                                        card.headers,
                                      )
                                    "
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-account-search</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    v-if="NrRegFiltroOs"
                                    color="error"
                                    @click="LimpaFiltro('Os')"
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-eraser</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row dense v-if="card.title === 'Follow Up'">
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-select
                                    v-model="filtroEscFollow"
                                    :items="itemsFiltroFollow"
                                    label="Escolha o campo"
                                  ></v-select>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    placeholder="digite aqui.."
                                    v-model="stringFiltroFollow"
                                    :disabled="!filtroEscFollow"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    color="indigo"
                                    @click="
                                      filtraRegistro(
                                        'Follow',
                                        filtroEscFollow,
                                        stringFiltroFollow,
                                        card.headers,
                                      )
                                    "
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-account-search</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    v-if="NrRegFiltroFollow"
                                    color="error"
                                    @click="LimpaFiltro('Follow')"
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-eraser</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row dense v-if="card.title === 'Clube Opimed'">
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-select
                                    v-model="filtroEscClube"
                                    :items="itemsFiltroClube"
                                    label="Escolha o campo"
                                  ></v-select>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    placeholder="digite aqui.."
                                    v-model="stringFiltroClube"
                                    :disabled="!filtroEscClube"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    color="indigo"
                                    @click="
                                      filtraRegistro(
                                        'Clube',
                                        filtroEscClube,
                                        stringFiltroClube,
                                        card.headers,
                                      )
                                    "
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-account-search</v-icon
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="mx-5 mt-2"
                                    x-small
                                    dark
                                    v-if="NrRegFiltroClube"
                                    color="error"
                                    @click="LimpaFiltro('Clube')"
                                  >
                                    <v-icon dark class="ml-1"
                                      >mdi-eraser</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-toolbar>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-if="card.title === 'Agendas'"
                                  small
                                  @click="verDados(item)"
                                  class="mr-2"
                                  v-bind="attrs"
                                  v-on="on"
                                  >mdi-eye</v-icon
                                >
                              </template>
                              <span>Ver Agenda</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-if="card.title === 'Clube Opimed'"
                                  @click="gerarContrato(item)"
                                  class="mr-2"
                                  v-bind="attrs"
                                  v-on="on"
                                  >mdi-file-pdf-box</v-icon
                                >
                              </template>
                              <span>Gerar Contrato</span>
                            </v-tooltip>
                          </template>
                          <template v-slot:[`item.ZZL_STATUS`]="{ item }">
                            <v-chip
                              class="ma-2"
                              v-if="item.ZZL_STATUS === 'Agendamento'"
                              x-small
                              dark
                              color="cyan darken-1"
                            >
                              {{ item.ZZL_STATUS }}
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.ZZL_STATUS === 'Bloqueado'"
                              x-small
                              dark
                              color="grey darken-1"
                            >
                              {{ item.ZZL_STATUS }}
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.ZZL_STATUS === 'Compareceu'"
                              x-small
                              dark
                              color="green darken-2"
                            >
                              {{ item.ZZL_STATUS }}
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.ZZL_STATUS === 'Não Compareceu'"
                              x-small
                              dark
                              color="red darken-2"
                            >
                              {{ item.ZZL_STATUS }}
                            </v-chip>
                          </template>
                          <template v-slot:[`item.status`]="{ item }">
                            <v-chip
                              class="ma-2"
                              v-if="item.status === 'Vencido'"
                              x-small
                              dark
                              color="red darken-1"
                            >
                              {{ item.status }}
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.status === 'Aberto'"
                              x-small
                              dark
                              color="blue darken-1"
                            >
                              {{ item.status }}
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.status === 'Liquidado'"
                              x-small
                              dark
                              color="green darken-1"
                            >
                              {{ item.status }}
                            </v-chip>
                          </template>
                          <template v-slot:[`item.ZCC_STATUS`]="{ item }">
                            <v-chip
                              class="ma-2"
                              v-if="item.ZCC_STATUS === '01'"
                              x-small
                              dark
                              color="red darken-1"
                            >
                              Assinado
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.ZCC_STATUS === '02'"
                              x-small
                              dark
                              color="orange"
                            >
                              Aguardando geração do envio
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.ZCC_STATUS === '03'"
                              x-small
                              dark
                              color="grey darken-1"
                            >
                              Aguardando faturamento
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.ZCC_STATUS === '04'"
                              x-small
                              dark
                              color="green darken-1"
                            >
                              Pedido faturado
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="item.ZCC_STATUS === '05'"
                              x-small
                              dark
                              color="blue darken-1"
                            >
                              Aguardando faturamento da venda
                            </v-chip>
                          </template>
                        </v-data-table>
                      </div>
                    </v-expansion-panel-content>

                    <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      x-small
                      color="primary"
                      dark
                      @click="verTodos(card.title)"
                    >
                      Ver todos
                    </v-btn>
                  </v-card-actions> -->
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
          <v-skeleton-loader
            ref="skeleton"
            v-for="n in 6 - cards.length"
            :key="n"
            :boilerplate="skeltCards"
            type="list-item"
            height="60"
            class="pa-2"
          ></v-skeleton-loader>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="dialogDetAgenda" v-if="ZZL[0]" width="900" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Detalhes do agendamento</v-card-title
        >
        <v-card-text>
          <v-row align="center">
            <v-col cols="9">
              <v-text-field
                dense
                label="Título"
                v-model="ZZL[0].ZZL_TITULO"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                label="Número OS"
                v-model="ZZL[0].ZZL_NUMOS"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -10px">
            <v-col cols="8">
              <v-text-field
                dense
                label="Cliente"
                v-model="ZZL[0].ZZL_NOMECLI"
                readonly
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="2">
              <v-text-field
                dense
                label="Hora Inicial"
                v-model="ZZL[0].ZZL_HRINI"
                readonly
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="2">
              <v-text-field
                dense
                label="Hora Final"
                v-model="ZZL[0].ZZL_HRFIN"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -10px">
            <v-col cols="4">
              <v-text-field
                dense
                label="Telefone"
                v-model="ZZL[0].ZZL_TEL1"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                label="Telefone 2"
                v-model="ZZL[0].ZZL_TEL2"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                label="Indicador"
                v-model="ZZL[0].ZZL_INDICADOR"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-top: -5px">
            <v-col cols="12">
              <v-textarea dense v-model="ZZL[0].NOTAS" color="teal" rows="1">
                <template v-slot:label>
                  <div>Notas</div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row align="center" style="margin-bottom: -15px; margin-top: -10px">
            <v-col cols="4">
              <v-text-field
                dense
                label="Status"
                v-model="ZZL[0].ZZL_STATUS"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" small dark @click="dialogDetAgenda = false"
            >fechar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAwaitProcessa" width="500">
      <v-card>
        <v-overlay :value="dialogAwaitProcessa" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title>Dados</v-card-title>
        <v-card-subtitle>
          <p class="body-1">Processando sua solicitação, aguarde...</p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>

    <v-dialog v-model="DialogTodos" v-if="todos[0]" width="90%" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2">{{
          titleTotdos
        }}</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="todos[0].headers"
            dense
            :items="todos[0].items"
            class="elevation-1 mt-2"
            no-data-text="Sem dados..."
            hide-default-footer
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="verDados(item)"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-eye</v-icon
                  >
                </template>
                <span>Ver Agenda</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.ZZL_STATUS`]="{ item }">
              <v-chip
                class="ma-2"
                v-if="item.ZZL_STATUS === 'Agendamento'"
                x-small
                dark
                color="cyan darken-1"
              >
                {{ item.ZZL_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.ZZL_STATUS === 'Bloqueado'"
                x-small
                dark
                color="grey darken-1"
              >
                {{ item.ZZL_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.ZZL_STATUS === 'Compareceu'"
                x-small
                dark
                color="green darken-2"
              >
                {{ item.ZZL_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.ZZL_STATUS === 'Não Compareceu'"
                x-small
                dark
                color="red darken-2"
              >
                {{ item.ZZL_STATUS }}
              </v-chip>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                class="ma-2"
                v-if="item.status === 'Vencido'"
                x-small
                dark
                color="red darken-1"
              >
                {{ item.status }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.status === 'Aberto'"
                x-small
                dark
                color="blue darken-1"
              >
                {{ item.status }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.status === 'Liquidado'"
                x-small
                dark
                color="green darken-1"
              >
                {{ item.status }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" small dark @click="DialogTodos = false"
            >fechar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackBar :snack="snackbar_error" :error="error" />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import snackBar from "@/components/templates/snakBar_error";

export default {
  name: "DetCli",
  computed: {
    ...mapState(["metadados", "controlValue"]),
    htmlToPdfOptions() {
      return {
        margin: 0.1,

        filename: `Contrato Leonardo.pdf`,

        image: {
          type: "jpeg",
          quality: 0.98,
        },

        enableLinks: true,

        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },

        jsPDF: {
          unit: "in",
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      };
    },
  },
  components: { snackBar },
  data: () => ({
    snackbar_error: false,
    error: "",
    dialogAwaitProcessa: false,
    skeltCards: false,
    cards: [
      { title: "Agendas", items: [], color: "grey lighten-1" },
      { title: "Faturamento", items: [], color: "grey lighten-1" },
      { title: "Ordem(s) de Serviço", items: [], color: "grey lighten-1" },
      { title: "Financeiro", items: [], color: "grey lighten-1" },
      { title: "Follow Up", items: [], color: "grey lighten-1" },
      { title: "Clube Opimed", items: [], color: "grey lighten-1" },
    ],
    todos: [],
    dialogDetalhes: false,
    codigo: null,
    loja: null,
    nomeCliente: null,
    loadingAgendas: true,
    dialogDetAgenda: false,
    DialogTodos: false,
    titleTotdos: null,
    ZZL: [],
    montaPdf: false,
    overlay: false,
    pdfName: "Contrato",

    dadosOrigemAgenda: [],
    dadosOrigemFinan: [],
    dadosOrigemOs: [],
    dadosOrigemFat: [],
    dadosOrigemFollow: [],
    dadosOrigemClube: [],

    //FILTRO DA AGENDA
    filtroEscAgenda: null,
    NrRegFiltroAgenda: null,
    itemsFiltroAgenda: [],
    stringFiltroAgenda: null,

    NrRegFiltroFat: null,
    filtroEscFat: null,
    itemsFiltroFat: [],
    stringFiltroFat: null,

    NrRegFiltroFinan: null,
    itemsFiltroFinan: [],
    filtroEscFinan: null,
    stringFiltroFinan: null,

    NrRegFiltroOs: null,
    itemsFiltroOs: [],
    filtroEscOs: null,
    stringFiltroOs: null,

    NrRegFiltroFollow: null,
    itemsFiltroFollow: [],
    filtroEscFollow: null,
    stringFiltroFollow: null,

    NrRegFiltroClube: null,
    itemsFiltroClube: [],
    filtroEscClube: null,
    stringFiltroClube: null,
  }),
  methods: {
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then(pdf => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " of " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 0.3,
            );
          }
        })
        .save();
    },
    showOverlay(contentRendered) {
      this.overlay = contentRendered;
    },
    onProgress(progress) {
      this.progress = progress;
      // console.log(`PDF generation progress: ${progress}%`)
    },
    startPagination() {
      console.log(`PDF has started pagination`);
    },
    hasPaginated() {
      console.log(`PDF has been paginated`);
    },

    LimpaFiltro(card) {
      if (card === "Agenda") {
        this.filtroEscAgenda = null;
        this.NrRegFiltroAgenda = null;
        this.stringFiltroAgenda = null;
        this.cards[0].items = this.dadosOrigemAgenda;
      }
      if (card === "Financeiro") {
        this.filtroEscFinan = null;
        this.NrRegFiltroFinan = null;
        this.stringFiltroFinan = null;
        this.cards[1].items = this.dadosOrigemFinan;
      }

      if (card === "Faturamento") {
        this.filtroEscFat = null;
        this.NrRegFiltroFat = null;
        this.stringFiltroFat = null;
        this.cards[2].items = this.dadosOrigemFat;
      }

      if (card === "Os") {
        this.filtroEscOs = null;
        this.NrRegFiltroOs = null;
        this.stringFiltroOs = null;
        this.cards[3].items = this.dadosOrigemOs;
      }

      if (card === "Follow") {
        this.filtroEscFollow = null;
        this.NrRegFiltroFollow = null;
        this.stringFiltroFollow = null;
        this.cards[4].items = this.dadosOrigemFollow;
      }

      if (card === "Clube") {
        this.filtroEscClube = null;
        this.NrRegFiltroClube = null;
        this.stringFiltroClube = null;
        this.cards[5].items = this.dadosOrigemClube;
      }
    },
    async filtraRegistro(card, campo, stringFiltro) {
      if (!campo || !stringFiltro) return;

      if (card === "Agenda") {
        this.cards[0].items = this.dadosOrigemAgenda;

        this.cards[0].items = this.cards[0].items.filter(e => {
          return e[`${campo}`]
            .toLowerCase()
            .includes(stringFiltro.toLowerCase());
        });

        this.NrRegFiltroAgenda = 1;
      }

      if (card === "Financeiro") {
        this.cards[1].items = this.dadosOrigemFinan;
        this.cards[1].items = this.cards[1].items.filter(e => {
          return e[`${campo}`]
            .toLowerCase()
            .includes(stringFiltro.toLowerCase());
        });

        this.NrRegFiltroFinan = 1;
      }

      if (card === "Faturamento") {
        this.cards[2].items = this.dadosOrigemFat;
        this.cards[2].items = this.cards[2].items.filter(e => {
          return e[`${campo}`]
            .toLowerCase()
            .includes(stringFiltro.toLowerCase());
        });

        this.NrRegFiltroFat = 1;
      }

      if (card === "Os") {
        this.cards[3].items = this.dadosOrigemOs;
        this.cards[3].items = this.cards[3].items.filter(e => {
          return e[`${campo}`]
            .toLowerCase()
            .includes(stringFiltro.toLowerCase());
        });

        this.NrRegFiltroOs = 1;
      }

      if (card === "Follow") {
        this.cards[4].items = this.dadosOrigemFollow;
        this.cards[4].items = this.cards[4].items.filter(e => {
          return e[`${campo}`]
            .toLowerCase()
            .includes(stringFiltro.toLowerCase());
        });

        this.NrRegFiltroFollow = 1;
      }

      if (card === "Clube") {
        this.cards[5].items = this.dadosOrigemClube;
        this.cards[5].items = this.cards[5].items.filter(e => {
          return e[`${campo}`]
            .toLowerCase()
            .includes(stringFiltro.toLowerCase());
        });

        this.NrRegFiltroClube = 1;
      }
    },
    async verDados(item) {
      this.dialogDetAgenda = false;
      this.dialogAwaitProcessa = true;
      this.ZZL = [];

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ZZL_TITULO,ZZL_DTINI,ZZL_DTFIN,ZZL_HRINI,ZZL_HRFIN,CONVERT(VARCHAR(1000), ZZL_NOTAS) AS NOTAS,ZZL_TEL1,ZZL_TEL2,ZZL_NUMOS`,
        WHERE: `ZZL.R_E_C_N_O_='${item.R_E_C_N_O_}'`,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/infoCli/agenda`;

      await axios
        .post(url, dados)
        .then(res => {
          this.ZZL = { ...res.data };
        })
        .catch(err => console.log(err));

      this.ZZL[0].ZZL_NOMECLI = this.nomeCliente;
      this.ZZL[0].ZZL_STATUS = item.ZZL_STATUS;
      this.ZZL[0].ZZL_INDICADOR = item.A3_NOME;
      this.dialogAwaitProcessa = false;
      this.dialogDetAgenda = true;
    },
    async carregaAgendas(codigo, loja, nome) {
      this.codigo = codigo;
      this.loja = loja;
      this.nomeCliente = nome;

      //this.cards = [];
      let ZZP = [];
      let headers = [];

      headers.push({
        text: "Ações",
        value: "actions",
        align: "center",
        width: 90,
        caption: "Caption",
      });

      ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD4");

      ZZP.map(e => {
        if (e.ZZP_BROWSE === "1") {
          headers.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "start",
            caption: "Caption",
          });
          if (e.ZZP_TITULO.trim() !== "Ações") {
            //let campo = `${e.ZZP_TITULO.trim()}`;
            this.itemsFiltroAgenda.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
            });
          }
        }
      });

      let dados = {};
      let url = ``;

      // let dados = {
      //   EMPRESA: "01",
      //   FILIAL: "0101",
      //   FIELDS: ``,
      //   WHERE: `ZZP_MODELO='MD4' ORDER BY ZZP_ORDEM`,
      //   TABELA: `ZZP`,
      // };

      // let url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      // await axios
      //   .post(url, dados)
      //   .then((res) => {
      //     res.data.forEach((e) => {
      //       if (e.ZZP_BROWSE === "1") {
      //         headers.push({
      //           text: e.ZZP_TITULO.trim(),
      //           value: e.ZZP_CAMPO.trim(),
      //           align: "start",
      //           caption: "Caption",
      //         });
      //         if (e.ZZP_TITULO.trim() !== "Ações") {
      //           //let campo = `${e.ZZP_TITULO.trim()}`;
      //           this.itemsFiltroAgenda.push({
      //             text: e.ZZP_TITULO.trim(),
      //             value: e.ZZP_CAMPO.trim()
      //           });
      //         }
      //       }
      //     });
      //     ZZP = res.data;
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     if (error.response.status === 401) {
      //       this.$store.commit("SetUser", null);
      //       return;
      //     }
      //   });

      ZZP;

      const tabelaAux = [...new Set(ZZP.map(item => item.ZZP_TABELA))];

      let stringCampos = "";

      let tabela = tabelaAux[0];

      let cont = 0;
      ZZP.forEach(e => {
        if (e.ZZP_TABELA === tabela) {
          cont++;
        }
      });

      for (let i = 0; i < cont; i++) {
        if (ZZP[i].ZZP_TABELA === tabela) {
          if (i + 1 >= cont) {
            stringCampos += `${ZZP[i].ZZP_CAMPO.trim()}`;
          } else {
            stringCampos += `${ZZP[i].ZZP_CAMPO.trim()},`;
          }
        }
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${stringCampos}`,
        WHERE: `ZZL_CLIENT='${codigo}' AND ZZL_LOJA='${loja}' ORDER BY ZZL_DTINI DESC`,
        TABELA: tabela.substr(0, 3),
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/infoCli/agenda`;

      ZZP = [];
      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.ZZL_DTINI = `${e.ZZL_DTINI.substr(6, 2)}/${e.ZZL_DTINI.substr(
              4,
              2,
            )}/${e.ZZL_DTINI.substr(0, 4)}`;
            if (e.ZZL_STATUS === "A") e.ZZL_STATUS = "Agendamento";
            if (e.ZZL_STATUS === "S") e.ZZL_STATUS = "Bloqueado";
            if (e.ZZL_STATUS === "C") e.ZZL_STATUS = "Compareceu";
            if (e.ZZL_STATUS === "N") e.ZZL_STATUS = "Não Compareceu";
          });
          ZZP = res.data;
        })
        .catch(err => console.log(err));

      this.loadingAgendas = false;
      this.show = true;
      this.stop = true;

      if (ZZP.length > 0) {
        this.cards[0].items = ZZP;
        this.cards[0].title = "Agendas";
        this.cards[0].color = "blue lighten-5";
      } else {
        this.cards[0].title = "Agendas sem dados no momento";
        this.cards[0].color = "red lighten-4";
      }
      this.cards[0].headers = headers;
      this.dadosOrigemAgenda = this.cards[0].items;
    },
    async carregaOrdens(codigo, loja, nome) {
      console.log(codigo);
      console.log(loja);
      console.log(nome);
    },
    async carregaFinan(codigo, loja) {
      let SE1 = [];
      let dados = {};
      let url = ``;
      let headers = [];

      headers.push({
        text: "Status",
        value: "status",
        align: "center",
        width: 90,
        caption: "Caption",
      });

      this.itemsFiltroFinan.push({ text: "Status", value: "status" });

      SE1 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD5");

      SE1.map(e => {
        if (e.ZZP_BROWSE === "1") {
          headers.push({
            text: e.ZZP_TITULO,
            value: e.ZZP_CAMPO.trim(),
            align: "start",
            caption: "Caption",
          });
          if (e.ZZP_TITULO.trim() !== "Ações") {
            //let campo = `${e.ZZP_TITULO.trim()}`;
            this.itemsFiltroFinan.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
            });
          }
        }
      });

      // let dados = {
      //   EMPRESA: "01",
      //   FILIAL: "0101",
      //   FIELDS: ``,
      //   WHERE: `ZZP_MODELO='MD5' ORDER BY ZZP_ORDEM`,
      //   TABELA: `ZZP`,
      // };

      // let url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      // let headers = [];

      // headers.push({
      //   text: "Status",
      //   value: "status",
      //   align: "center",
      //   width: 90,
      //   caption: "Caption",
      // });
      // this.itemsFiltroFinan.push({text: "Status",
      //   value: "status",})

      // await axios
      //   .post(url, dados)
      //   .then((res) => {
      //     res.data.forEach((e) => {
      //       if (e.ZZP_BROWSE === "1") {
      //         headers.push({
      //           text: e.ZZP_TITULO,
      //           value: e.ZZP_CAMPO.trim(),
      //           align: "start",
      //           caption: "Caption",
      //         });
      //         if (e.ZZP_TITULO.trim() !== "Ações") {
      //           //let campo = `${e.ZZP_TITULO.trim()}`;
      //           this.itemsFiltroFinan.push({
      //             text: e.ZZP_TITULO.trim(),
      //             value: e.ZZP_CAMPO.trim()
      //           });
      //         }
      //       }
      //     });
      //     SE1 = res.data;
      //   })
      //  .catch(error => {
      //   console.log(error);
      //   if (error.response.status === 401) {
      //     this.$store.commit("SetUser", null);
      //     return;
      //   }
      // });

      const tabelaAux = [...new Set(SE1.map(item => item.ZZP_TABELA))];

      let stringCampos = " ";

      let tabela = tabelaAux[0];

      let cont = 0;
      SE1.forEach(e => {
        if (e.ZZP_TABELA === tabela) {
          cont++;
        }
      });

      for (let i = 0; i < cont; i++) {
        if (SE1[i].ZZP_TABELA === tabela) {
          if (i + 1 >= cont) {
            stringCampos += `${SE1[i].ZZP_CAMPO.trim()}`;
          } else {
            stringCampos += `${SE1[i].ZZP_CAMPO.trim()},`;
          }
        }
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${stringCampos}`,
        WHERE: `E1_CLIENTE='${codigo}' AND E1_LOJA='${loja}' ORDER BY E1_VENCREA DESC`,
        TABELA: tabela.substr(0, 3),
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/infoCli/financeiro`;

      SE1 = [];
      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.E1_EMISSAO = `${e.E1_EMISSAO.substr(6, 2)}/${e.E1_EMISSAO.substr(
              4,
              2,
            )}/${e.E1_EMISSAO.substr(0, 4)}`;
            e.E1_VENCREA = `${e.E1_VENCREA.substr(6, 2)}/${e.E1_VENCREA.substr(
              4,
              2,
            )}/${e.E1_VENCREA.substr(0, 4)}`;

            const venc = e.E1_VENCREA.split("/");

            const dataVenc = new Date(venc[2], venc[1] - 1, venc[0]);
            if (dataVenc < new Date() && e.E1_SALDO > 0) {
              e.status = "Vencido";
            }
            if (dataVenc > new Date() && e.E1_SALDO > 0) {
              e.status = "Aberto";
            }
            if (e.E1_SALDO <= 0) {
              e.status = "Liquidado";
            }
            e.E1_NUM = `${e.E1_PREFIXO}${e.E1_NUM}${e.E1_PARCELA}`;
            e.E1_VALOR = e.E1_VALOR.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            e.E1_SALDO = e.E1_SALDO.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          });
          SE1 = res.data;
        })
        .catch(err => console.log(err));

      if (SE1.length > 0) {
        this.cards[1].items = SE1;
        this.cards[1].color = "amber lighten-5";
        this.cards[1].title = "Financeiro";
      } else {
        this.cards[1].title = "Financeiro sem dados no momento";
        this.cards[1].color = "red lighten-4";
      }
      this.cards[1].headers = headers;
      this.dadosOrigemFinan = this.cards[1].items;
    },
    async carregaFollow(codigo, loja) {
      let SZ4 = [];
      let dados = {};
      let url = ``;
      let headers = [];

      let ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD013");

      ZZP.map(e => {
        let width = 90;
        // if (e.ZZP_COLS <= 2) {
        //   width = 130;
        // } else if (e.ZZP_COLS <= 3) {
        //   width = 200;
        // } else {
        //   width = 250;
        // }
        if (e.ZZP_BROWSE === "1") {
          headers.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: width,
            caption: "title",
          });
          this.itemsFiltroFollow.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
          });
        }
      });

      const tabelaAux = [...new Set(ZZP.map(item => item.ZZP_TABELA))];

      this.stringCampos = "";

      let tabela = tabelaAux[0];

      ZZP.forEach(e => {
        if (e.ZZP_TABELA === tabela) {
          if (e.ZZP_TIPO === "Y") {
            this.stringCampos += `CONVERT(VARCHAR(2000), ${e.ZZP_CAMPO.trim()}) ${e.ZZP_CAMPO.trim()},`;
          } else {
            this.stringCampos += `${e.ZZP_CAMPO.trim()},`;
          }
        }
      });

      if (this.stringCampos.substr(-1) === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      this.stringCampos += " ,ZS1_NOME,Z4_USERINC";

      let WHERE = ``;

      WHERE += `Z4_CLIENTE='${codigo}' AND Z4_LOJA='${loja}' ORDER BY SZ4.R_E_C_N_O_ DESC`;

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: WHERE,
        TABELA: tabela.substr(0, 3),
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/followUp`;

      this.itemLeads = [];

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.Z4_DATA = `${e.Z4_DATA.substr(6, 2)}/${e.Z4_DATA.substr(
              4,
              2,
            )}/${e.Z4_DATA.substr(0, 4)}`;
            if (e.Z4_STATUS) e.Z4_STATUS = `${e.Z4_STATUS}-${e.Z5_DESC}`;
            if (!e.Z4_STATUS) e.Z4_STATUS = `03-FECHADA`;
            if (e.Z4_TEXTO && e.Z4_TEXTO.length > 100) {
              e.Z4_TEXTO_ORI = e.Z4_TEXTO.substr(0, 100) + "...";
            } else {
              e.Z4_TEXTO_ORI = e.Z4_TEXTO;
            }
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "P")
              e.Z4_SATISFA = "P=PESSIMO";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "R")
              e.Z4_SATISFA = "R=RUIM";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "B")
              e.Z4_SATISFA = "B=BOM";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "E")
              e.Z4_SATISFA = "E=EXCELENTE";

              if(e.Z4_USERINC.trim() === ""){
              e.Z4_RESP1 = `${e.Z4_RESP1}-${e.Z4_NOME}`
            }else{
              e.Z4_RESP1 = e.Z4_USERINC
            }

            if(e.Z4_DEPTO === "01") e.Z4_DEPTO = `${e.Z4_DEPTO}-DEPTO COMERCIAL`
            if(e.Z4_DEPTO === "02") e.Z4_DEPTO = `${e.Z4_DEPTO}-DEPTO FINANCEIRO`
            if(e.Z4_DEPTO === "03") e.Z4_DEPTO = `${e.Z4_DEPTO}-DEPTO FONOAUDIOLOGIA`
            if(e.Z4_DEPTO === "04") e.Z4_DEPTO = `${e.Z4_DEPTO}-DEPTO LOGÍSTICA`
            if(e.Z4_DEPTO === "05") e.Z4_DEPTO = `${e.Z4_DEPTO}-TELEMARKTING`
          });
          SZ4 = res.data;
        })
        .catch(err => console.log(err));

      if (SZ4.length > 0) {
        this.cards[4].items = SZ4;
        this.cards[4].title = "Follow Up";
        this.cards[4].color = "indigo lighten-5";
      } else {
        this.cards[4].title = "Follow Up sem dados no momento";
        this.cards[4].color = "red lighten-4";
      }
      this.cards[4].headers = headers;
      this.dadosOrigemFollow = this.cards[4].items;
    },
    async carregaFat(codigo, loja) {
      let SD2 = [];
      let dados = {};
      let url = ``;
      let headers = [];

      SD2 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD6");

      SD2.map(e => {
        if (e.ZZP_BROWSE === "1") {
          headers.push({
            text: e.ZZP_TITULO,
            value: e.ZZP_CAMPO.trim(),
            caption: "Caption",
            align: "start",
          });
          if (e.ZZP_TITULO.trim() !== "Ações") {
            //let campo = `${e.ZZP_TITULO.trim()}`;
            this.itemsFiltroFat.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
            });
          }
        }
      });

      // let dados = {
      //   EMPRESA: "01",
      //   FILIAL: "0101",
      //   FIELDS: ``,
      //   WHERE: `ZZP_MODELO='MD6' ORDER BY ZZP_ORDEM`,
      //   TABELA: `ZZP`,
      // };

      // let url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      // await axios
      //   .post(url, dados)
      //   .then((res) => {
      //     res.data.forEach((e) => {
      //       if (e.ZZP_BROWSE === "1") {
      //         headers.push({
      //           text: e.ZZP_TITULO,
      //           value: e.ZZP_CAMPO.trim(),
      //           caption: "Caption",
      //           align: "start",
      //         });
      //         if (e.ZZP_TITULO.trim() !== "Ações") {
      //           //let campo = `${e.ZZP_TITULO.trim()}`;
      //           this.itemsFiltroFat.push({
      //             text: e.ZZP_TITULO.trim(),
      //             value: e.ZZP_CAMPO.trim(),
      //           });
      //         }
      //       }
      //     });
      //     SD2 = res.data;
      //   })
      //   .catch(error => {
      //   console.log(error);
      //   if (error.response.status === 401) {
      //     this.$store.commit("SetUser", null);
      //     return;
      //   }
      // });

      const tabelaAux = [...new Set(SD2.map(item => item.ZZP_TABELA))];

      let stringCampos = " ";

      let tabela = tabelaAux[0];

      let cont = 0;
      SD2.forEach(e => {
        if (e.ZZP_TABELA === tabela) {
          cont++;
        }
      });

      for (let i = 0; i < cont; i++) {
        if (SD2[i].ZZP_TABELA === tabela) {
          if (i + 1 >= cont) {
            stringCampos += `${SD2[i].ZZP_CAMPO.trim()}`;
          } else {
            stringCampos += `${SD2[i].ZZP_CAMPO.trim()},`;
          }
        }
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${stringCampos}`,
        WHERE: `D2_CLIENTE='${codigo}' AND D2_LOJA='${loja}' AND F2_TIPO NOT IN ('B','D') ORDER BY D2_EMISSAO DESC`,
        TABELA: tabela.substr(0, 3),
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/infoCli/faturamento`;

      SD2 = [];
      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.D2_SERIE = `${e.D2_DOC}${e.D2_SERIE}`;
            e.D2_COD = `${e.D2_COD} - ${e.B1_DESC}`;
            if (e.F4_TEXTO) {
              e.D2_CF = `${e.D2_CF} - ${e.F4_TEXTO}`;
            } else {
              e.D2_CF = `${e.D2_CF} - ${e.X5_DESCRI}`;
            }
            e.D2_EMISSAO = `${e.D2_EMISSAO.substr(6, 2)}/${e.D2_EMISSAO.substr(
              4,
              2,
            )}/${e.D2_EMISSAO.substr(0, 4)}`;
            e.D2_TOTAL = e.D2_TOTAL.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          });
          SD2 = res.data;
        })
        .catch(err => console.log(err));

      if (SD2.length > 0) {
        this.cards[2].items = SD2;
        this.cards[2].title = "Faturamento";
        this.cards[2].color = "teal lighten-5";
      } else {
        this.cards[2].title = "Faturamento sem dados no momento";
        this.cards[2].color = "red lighten-4";
      }
      this.cards[2].headers = headers;
      this.dadosOrigemFat = this.cards[2].items;
    },
    async carregaOs(codigo, loja) {
      let ZF1 = [];
      let dados = {};
      let url = ``;
      let headers = [];

      ZF1 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD7");

      ZF1.map(e => {
        if (e.ZZP_BROWSE === "1") {
          headers.push({
            text: e.ZZP_TITULO,
            value: e.ZZP_CAMPO.trim(),
            caption: "Caption",
            align: "start",
          });
          if (e.ZZP_TITULO.trim() !== "Ações") {
            //let campo = `${e.ZZP_TITULO.trim()}`;
            this.itemsFiltroOs.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
            });
          }
        }
      });

      // let dados = {
      //   EMPRESA: "01",
      //   FILIAL: "0101",
      //   FIELDS: ``,
      //   WHERE: `ZZP_MODELO='MD7' ORDER BY ZZP_ORDEM`,
      //   TABELA: `ZZP`,
      // };

      // let url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      // let headers = [];

      // await axios
      //   .post(url, dados)
      //   .then((res) => {
      //     res.data.forEach((e) => {
      //       if (e.ZZP_BROWSE === "1") {
      //         headers.push({
      //           text: e.ZZP_TITULO,
      //           value: e.ZZP_CAMPO.trim(),
      //           caption: "Caption",
      //           align: "start",
      //         });
      //         if (e.ZZP_TITULO.trim() !== "Ações") {
      //           //let campo = `${e.ZZP_TITULO.trim()}`;
      //           this.itemsFiltroOs.push({
      //             text: e.ZZP_TITULO.trim(),
      //             value: e.ZZP_CAMPO.trim(),
      //           });
      //         }
      //       }
      //     });
      //     ZF1 = res.data;
      //   })
      //   .catch(error => {
      //   console.log(error);
      //   if (error.response.status === 401) {
      //     this.$store.commit("SetUser", null);
      //     return;
      //   }
      // });

      const tabelaAux = [...new Set(ZF1.map(item => item.ZZP_TABELA))];

      let stringCampos = " ";

      let tabela = tabelaAux[0];

      let cont = 0;
      ZF1.forEach(e => {
        if (e.ZZP_TABELA === tabela) {
          cont++;
        }
      });

      for (let i = 0; i < cont; i++) {
        if (i + 1 >= cont) {
          stringCampos += `${ZF1[i].ZZP_CAMPO.trim()}`;
        } else {
          stringCampos += `${ZF1[i].ZZP_CAMPO.trim()},`;
        }
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${stringCampos}`,
        WHERE: `ZF1_CODCLI='${codigo}' AND ZF1_LOJA='${loja}' ORDER BY ZF1_EMISSA DESC`,
        TABELA: tabela.substr(0, 3),
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/infoCli/Os`;

      ZF1 = [];
      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.ZF1_TIPO = `${e.ZF1_TIPO}-${e.ZF6_DESCRI}`;
            e.ZF2_CODPRO = `${e.ZF2_CODPRO}-${e.B1_DESC}`;
            e.ZF7_DESCRI = `${e.ZF1_STATUS} - ${e.ZF7_DESCRI}`;
            e.ZF1_CODPRO = `${e.ZF1_CODPRO} - ${e.B1_DESC}`;
            e.ZF1_EMISSA = `${e.ZF1_EMISSA.substr(6, 2)}/${e.ZF1_EMISSA.substr(
              4,
              2,
            )}/${e.ZF1_EMISSA.substr(0, 4)}`;
          });
          ZF1 = res.data;
        })
        .catch(err => console.log(err));

      if (ZF1.length > 0) {
        this.cards[3].items = ZF1;
        this.cards[3].color = "deep-purple lighten-5";
        this.cards[3].title = "Ordem(s) de Serviço";
      } else {
        this.cards[3].title = "Ordem(s) de Serviço sem dados no momento";
        this.cards[3].color = "red lighten-4";
      }
      this.cards[3].headers = headers;
      this.dadosOrigemOs = this.cards[3].items;
    },
    async carregaClube(codigo, loja) {
      const ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD024");

      let headers = [];

      headers.push({
        text: "Ações",
        value: "actions",
        align: "center",
        width: 90,
        caption: "Caption",
      });

      let ZCC = [];

      ZZP.forEach(e => {
        if (e.ZZP_BROWSE === "1") {
          headers.push({
            text: e.ZZP_TITULO,
            value: e.ZZP_CAMPO.trim(),
            caption: "Caption",
            align: "start",
          });
          if (e.ZZP_TITULO.trim() !== "Ações") {
            //let campo = `${e.ZZP_TITULO.trim()}`;
            this.itemsFiltroClube.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
            });
          }
        }
      });

      const url = `${process.env.VUE_APP_BASE_API_URL}/infoCli/Clube`;
      const dados = {
        cliente: codigo,
        loja: loja,
      };

      await axios
        .post(url, dados)
        .then(res => {
          ZCC = res.data.map(e => {
            e.ZCA_NOME = e.ZCA_NOME.trim();
            e.ZCA_PERIOD = `${e.ZCA_PERIOD} dias`;
            e.ZCC_VLTOT = e.ZCC_VLTOT.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });

            return e;
          });
        })
        .catch(err => console.log(err));

      if (ZCC.length > 0) {
        this.pdfName = `Contrato ${this.nomeCliente}.pdf`;

        this.cards[5].items = ZCC;
        this.cards[5].color = "deep-purple lighten-5";
        this.cards[5].title = "Clube Opimed";
      } else {
        this.cards[5].title = "Clube Opimed sem dados no momento";
        this.cards[5].color = "red lighten-4";
      }
      this.cards[5].headers = headers;
      this.dadosOrigemClube = this.cards[5].items;
    },
    async buscaDados(codigo, loja, nome) {
      this.dialogDetalhes = true;
      this.nomeCliente = nome;

      this.cards = [
        {
          title: "Agendas carregando...",
          items: [],
          color: "grey lighten-1",
        },
        {
          title: "Financeiro carregando...",
          items: [],
          color: "grey lighten-1",
        },
        {
          title: "Faturamento carregando...",
          items: [],
          color: "grey lighten-1",
        },

        {
          title: "Ordem(s) de Serviço carregando...",
          items: [],
          color: "grey lighten-1",
        },

        {
          title: "Follow Up carregando...",
          items: [],
          color: "grey lighten-1",
        },

        {
          title: "Clube Opimed carregando...",
          items: [],
          color: "grey lighten-1",
        },
      ];

      this.carregaAgendas(codigo, loja, nome);

      this.carregaFinan(codigo, loja);

      this.carregaFat(codigo, loja, nome);

      this.carregaOs(codigo, loja);

      this.carregaFollow(codigo, loja);

      this.carregaClube(codigo, loja);
    },
    async gerarContrato(item) {
      // console.log(item);

      const resource = `${process.env.VUE_APP_BASE_API_URL}/clube/contrato`;
      this.snackbar_error = false;

      try {
        const token = JSON.parse(
          localStorage.getItem(process.env.VUE_APP_USER_KEY),
        ).token;

        const response = await fetch(resource, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            EMPRESA: "01",
            FILIAL: "0101",
            ZCC_CODIGO: item.ZCC_CODIGO,
          }),
        });
        const blob = await response.blob();
        const file = window.open(URL.createObjectURL(blob), "_blank");

        if (file) {
          file.focus();
        } else {
          this.error = "pop-up do arquivo PDF bloqueada pelo navegador";
          this.snackbar_error = true;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
