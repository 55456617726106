<template>
  <div class="ml-5 mt-3 mr-3">
    <v-dialog v-model="dialogDuplic" width="50%">
      <v-card>
        <v-card-title>Campanhas itens</v-card-title>
        <v-card-text>
          <div class="d-flex flex-column">
            <p>
              O modelo de dados com o filtro, gerou linhas duplicadas para
              cliente. Isto ocorre pois são apresentados todos os produtos da
              nota que compreendem o filtro.
            </p>
            <p>
              Ao criar a campanha vão ser
              <span style="font-weight: bold; color: red"
                >descartadas as linhas duplicadas</span
              >
              de clientes, no total serão criados
              <span style="font-weight: bold; color: green">{{
                distintosReceita.length
              }}</span>
              itens na campanha.
            </p>
            <p>Deseja continuar ?</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="dialogDuplic = false">Não</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="criaCampanha">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar dense rounded>
      <v-icon class="mr-5">mdi-apache-kafka</v-icon>
      <div class="d-flex flex-column justify-center">
        <span class="body">Campanhas</span>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon color="green" @click="montaTabela">
        <v-icon>mdi-cached</v-icon>
      </v-btn>
      <v-btn class="mx-5" fab x-small dark color="indigo" @click="novo()">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <div class="mt-2" rounded>
      <v-data-table
        dense
        :headers="headersCamp"
        :items="itemsCamp"
        item-key="ZW4_ID"
        class="elevation-1 tbCamp"
        height="480"
        :items-per-page="itemsCamp.length"
        fixed-header
        no-data-text="Sem dados no momento"
        :loading="loadingCamp"
        loading-text="Buscando dados aguarde..."
        hide-default-footer
        single-select
        @click:row="mostraDetalhes"
      ></v-data-table>
    </div>

    <v-toolbar dense rounded class="mt-1">
      <v-icon class="mr-5">mdi-axis-arrow</v-icon>
      <div class="d-flex flex-column justify-center">
        <span class="body">Items da campanha {{ nomeCampanhaItems }}</span>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>

    <div class="d-flex justify-space-between">
      <div class="mt-2" rounded style="width: 65%">
        <v-data-table
          dense
          :headers="headersCampItems"
          :items="itemsCampItems"
          item-key="ZW5_CLIENT"
          class="elevation-1 tbCamp"
          height="270"
          :items-per-page="itemsCampItems.length"
          fixed-header
          no-data-text="Clique um uma campanha para mostrar os items"
          :loading="loadingCampItems"
          loading-text="Buscando dados aguarde..."
          hide-default-footer
          single-select
          @click:row="mostraFollow"
        >
          <template v-slot:[`item.ZW5_STATUS`]="{ item }">
            <v-chip
              class="ma-2"
              x-small
              color="blue darken-2"
              dark
              v-if="item.ZW5_STATUS === 'A'"
            >
              Aberto
            </v-chip>
            <v-chip
              class="ma-2"
              x-small
              color="green darken-2"
              dark
              v-if="item.ZW5_STATUS === 'E'"
            >
              Encerrado
            </v-chip>
            <v-chip
              class="ma-2"
              x-small
              color="orange darken-2"
              dark
              v-if="item.ZW5_STATUS === 'P'"
            >
              Perdido
            </v-chip>
            <v-chip
              class="ma-2"
              x-small
              color="yellow darken-2"
              dark
              v-if="item.ZW5_STATUS === 'S'"
            >
              Sem contato
            </v-chip>
          </template>
        </v-data-table>
      </div>
      <div class="mt-2" rounded style="width: 34%">
        <v-card height="300">
          <v-card-title class="headline grey lighten-2">Follow-Up</v-card-title>
          <!-- <v-card-subtitle>{{ nomeClienteFollow }}</v-card-subtitle> -->

          <v-data-table
            dense
            :headers="headersFollow"
            :items="itemsFollow"
            item-key="Z4_HORA"
            class="elevation-1"
            height="210"
            :items-per-page="itemsFollow.length"
            fixed-header
            no-data-text="Clique no item para ver o Follow-up"
            :loading="loadingFollow"
            loading-text="Buscando dados aguarde..."
            hide-default-footer
          >
          </v-data-table>
        </v-card>
      </div>
    </div>
    <div class="text-center">
      <v-dialog v-model="dialogNovo" width="90%" persistent>
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Cadastro de campanha
          </v-card-title>

          <v-card-text>
            <div class="d-flex justify-start">
              <v-card class="mx-auto mt-2" id="opcoesCamp" height="710">
                <v-card-text>
                  <div class="d-flex justify-space-around">
                    <v-text-field
                      v-model="nomeCampanha"
                      dense
                      outlined
                      hide-details
                      label="Nome da Campanha"
                      class="mb-2"
                    ></v-text-field>
                  </div>
                  <div class="d-flex justify-space-around">
                    <v-text-field
                      v-model="finalidade"
                      dense
                      outlined
                      hide-details
                      label="Finalidade"
                      class="mb-2"
                    ></v-text-field>
                  </div>
                  <div class="d-flex justify-space-around">
                    <div>
                      <div>Escolha o tipo de campanha:</div>
                      <v-radio-group v-model="typeCamp">
                        <v-radio label="Estática" value="E"></v-radio>
                        <!-- <v-radio label="Dinamica" value="D" disabled></v-radio> -->
                      </v-radio-group>
                    </div>
                    <div class="ml-5">
                      <div>Validade:</div>
                      <v-text-field
                        v-mask="'##/##/####'"
                        dense
                        label="Início:"
                        v-model="dtInicioCamp"
                      ></v-text-field>

                      <v-text-field
                        v-mask="'##/##/####'"
                        dense
                        label="Fim:"
                        v-model="dtFimCamp"
                      ></v-text-field>
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <div class="mt-3">Modelo de dados:</div>
                  <v-card class="mt-1" height="150">
                    <v-card-text>
                      <v-data-table
                        v-model="selectedModelo"
                        dense
                        show-select
                        :single-select="true"
                        hide-default-footer
                        height="130"
                        :headers="headersMdDados"
                        :items="itemsMdDados"
                        item-key="modelo"
                        no-data-text="Sem dados no momento"
                        loading-text="Buscando dados..."
                        :loading="loadingMdDados"
                        fixed-header
                      ></v-data-table>
                    </v-card-text>
                  </v-card>
                  <div class="mt-3">Filtros:</div>
                  <v-card class="mt-1" height="210">
                    <v-card-text>
                      <v-list nav dense height="180" style="overflow-y: scroll">
                        <v-subheader>
                          <v-btn
                            color="orange"
                            style="margin-top: -10px"
                            text
                            @click="LimpaFiltroCriado"
                          >
                            Limpar
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="success"
                            style="margin-top: -10px"
                            text
                            @click="newFiltro"
                          >
                            Novo
                          </v-btn>
                        </v-subheader>
                        <v-list-item-group color="primary">
                          <v-list-item
                            v-for="(item, i) in itemsFiltro"
                            :key="i"
                            @click="marcar(item)"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item-content v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    class="caption"
                                    v-text="item.text"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </template>
                              <span>{{ item.formula }}</span>
                            </v-tooltip>
                            <v-list-item-icon>
                              <v-icon v-if="!item.select">
                                mdi-star-outline
                              </v-icon>
                              <v-icon color="blue" v-if="item.select">
                                mdi-star
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    style="margin-top: -10px"
                    text
                    @click="aplicarFiltro"
                  >
                    Aplicar
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card class="mx-auto mt-3" id="contentCamp" height="650">
                <v-card-text
                  class="body-1 text-center"
                  v-if="!itemsContent.length > 0"
                >
                  <div>Conteúdo do modelo + filtros escolhidos</div>
                  <v-data-table
                    v-model="selectedContent"
                    dense
                    show-select
                    hide-default-footer
                    height="595"
                    :headers="headersContent"
                    :items="itemsContent"
                    :items-per-page="itemsContent.length"
                    item-key="indice"
                    no-data-text="Sem dados no momento"
                    loading-text="Buscando dados..."
                    :loading="loadingContent"
                    fixed-header
                  ></v-data-table>
                </v-card-text>
                <v-card-text v-if="itemsContent.length > 0">
                  <v-data-table
                    v-model="selectedContent"
                    dense
                    show-select
                    hide-default-footer
                    height="623"
                    :headers="headersContent"
                    :items="itemsContent"
                    :items-per-page="itemsContent.length"
                    item-key="indice"
                    no-data-text="Sem dados no momento"
                    loading-text="Buscando dados..."
                    :loading="loadingContent"
                    fixed-header
                  ></v-data-table>
                </v-card-text>
                <v-toolbar
                  dense
                  rounded
                  class="title grey lighten-2 caption mr-2 ml-2"
                >
                  <span
                    >Total de Registro selecionados:
                    {{ selectedContent.length }}</span
                  >
                  <v-spacer></v-spacer>
                  <span
                    >Total de Registro encontrados:
                    {{ itemsContent.length }}</span
                  >
                </v-toolbar>
              </v-card>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="error" text @click="dialogNovo = false">
              Fechar
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn color="success" text @click="verificaDuplica">
              Criar campanha
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogNewFiltro" width="50%">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Cadastro de filtro
          </v-card-title>
          <v-card-text>
            <v-row dense class="mt-1">
              <v-col cols="4">
                <v-autocomplete
                  dense
                  v-model="campoSelectFiltro"
                  :items="itemsCampoFiltro"
                  item-text="name"
                  item-value="campo"
                  small-chips
                  label="Campo"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  dense
                  v-model="operadorSelect"
                  :items="itemsOperadorFiltro"
                  item-text="name"
                  item-value="value"
                  small-chips
                  label="Operador"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  v-model="expressaoFiltro"
                  label="Expressão"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="AdicionaFiltro">
                Adicionar
              </v-btn>
            </v-row>
            <v-card height="300" class="mt-2">
              <v-toolbar dense rounded class="headline grey lighten-2 caption">
                <span>Incluir</span>
                <div class="ml-3">
                  <v-btn
                    elevation="2"
                    x-small
                    v-text="`e`"
                    @click="adicionaANDFiltro"
                  ></v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                  elevation="2"
                  x-small
                  v-text="`Limpar filtro`"
                  @click="limpafiltro"
                ></v-btn>
              </v-toolbar>
              <v-card-text height="280">
                <v-list nav dense height="200" style="overflow-y: scroll">
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in itemsAdicionaFiltro"
                      :key="i"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="caption"
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
            <v-row dense>
              <v-btn color="error" text @click="dialogNewFiltro = false">
                Fechar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="salvaFiltro()">
                Salvar
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-bottom-sheet v-model="sheetMessage">
      <v-sheet class="text-center" :color="colorMessage" height="130px">
        <div class="py-3">
          <v-btn class="mx-2" text fab>
            <v-icon v-text="iconMessage" @click="sheetMessage = false"></v-icon>
          </v-btn>
        </div>
        <div class="py-3" style="font-size: 15px" v-text="message"></div>
      </v-sheet>
    </v-bottom-sheet>
    <DialogErros :error="erro" />
    <v-dialog v-model="dialogAwaitFiltro" width="500">
      <v-card>
        <v-overlay :value="dialogAwaitFiltro" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title class="headline grey lighten-2">Campanhas</v-card-title>
        <v-card-text>
          <p class="body-1">Aguarde montando os dados...</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAwaitSaveCamp" width="500">
      <v-card>
        <v-overlay :value="dialogAwaitSaveCamp" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title class="headline grey lighten-2">Campanhas</v-card-title>
        <v-card-subtitle>
          <p class="body-1">Criando a campanha...</p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

import DialogErros from "@/components/_Erros/DialogErros.vue";

String.prototype.replaceAll =
  String.prototype.replaceAll ||
  function (needle, replacement) {
    return this.split(needle).join(replacement);
  };

export default {
  name: "Campanhas",
  computed: mapState(["user", "metadados"]),
  components: { DialogErros },
  data: () => {
    return {
      nomeClienteFollow: null,
      loadingFollow: false,
      loadingCampItems: false,
      dialogAwaitSaveCamp: false,
      nomeCampanhaItems: null,
      nomeCampanha: null,
      dialogAwaitFiltro: false,
      headersContent: [],
      selectedContent: [],
      itemsContent: [],
      loadingContent: false,
      filtro: null,
      sheetMessage: false,
      colorMessage: "",
      iconMessage: "",
      message: ``,
      finalidade: null,
      errorSnack: null,
      snackbar_error: false,
      erro: null,
      operadorSelect: null,
      itemsOperadorFiltro: [
        { name: "igual a", value: "=" },
        { name: "Diferente de", value: "<>" },
        { name: "Menor que", value: "<" },
        { name: "Menor ou igual a ", value: "<=" },
        { name: "Maior que ", value: ">" },
        { name: "Maior ou igual a ", value: ">=" },
        { name: "Contém ", value: "LIKE" },
      ],
      expressaoFiltro: null,
      campoSelectFiltro: null,
      itemsCampoFiltro: [],
      distintosReceita: [],
      dialogNewFiltro: false,
      selectedFiltro: [],
      selectedModelo: [],
      itemsAdicionaFiltro: [{ text: ``, filtro: `` }],
      itemsFiltro: [],
      dtInicioCamp: null,
      dtFimCamp: null,
      typeCamp: "E",
      dialogNovo: false,
      loadingMdDados: false,
      itemsMdDados: [],
      itemsCampItems: [],
      itemsFollow: [],
      dialogDuplic: false,
      headersFollow: [
        {
          text: "Follow-up",
          value: "Z4_TEXTO",
          align: "start",
          caption: "overline",
        },
      ],
      headersCampItems: [
        {
          text: "STATUS",
          value: "ZW5_STATUS",
          align: "start",
          width: 50,
          caption: "title",
        },
        {
          text: "Cod. cliente",
          value: "ZW5_CLIENT",
          align: "start",
          width: 50,
          caption: "title",
        },
        {
          text: "Loja. cliente",
          value: "ZW5_LOJA",
          align: "start",
          width: 50,
          caption: "title",
        },
        {
          text: "Nome",
          value: "A1_NOME",
          align: "start",
          width: 250,
          caption: "title",
        },
        {
          text: "Telefone",
          value: "A1_TEL",
          align: "start",
          width: 100,
          caption: "title",
        },
      ],
      headersMdDados: [
        {
          text: "Modelo",
          value: "modelo",
          align: "start",
          caption: "title",
        },
      ],
      headersCamp: [
        {
          text: "Nome",
          value: "ZW4_NOME",
          align: "start",
          width: 250,
          caption: "title",
        },
        {
          text: "Inicio",
          value: "ZW4_DTINI",
          align: "start",
          width: 100,
          caption: "title",
        },
        {
          text: "Fim",
          value: "ZW4_DTFIM",
          align: "start",
          width: 100,
          caption: "title",
        },

        {
          text: "Data inclusão",
          value: "ZW4_DTINC",
          align: "start",
          width: 100,
          caption: "title",
        },
        {
          text: "Hora inclusão",
          value: "ZW4_HORA",
          align: "start",
          width: 100,
          caption: "title",
        },
        {
          text: "Modelo de dados",
          value: "ZW4_MODEL",
          align: "start",
          width: 100,
          caption: "title",
        },
        {
          text: "Reg. encontrados",
          value: "ZW4_NREGTO",
          align: "start",
          width: 100,
          caption: "title",
        },
        {
          text: "Reg. selecionados",
          value: "ZW4_NREGIS",
          align: "start",
          width: 100,
          caption: "title",
        },
        {
          text: "User de criação",
          value: "USER_CRIACAO",
          align: "start",
          width: 250,
          caption: "title",
        },
      ],
      itemsCamp: [],
      loadingCamp: true,
    };
  },
  methods: {
    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");
      return text.toUpperCase();
    },
    async aplicarFiltro() {
      if (this.itemsFiltro.length <= 0) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Crie um filtro para ser aplicado ao modelo de dados.`;
        this.sheetMessage = true;
        return;
      }

      const nrFiltro = this.itemsFiltro.filter(e => e.select === true);

      if (nrFiltro.length === 0) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Escolha pelo menos um filtro para aplicar no modelo.`;
        this.sheetMessage = true;
        return;
      }

      this.headersContent = [];
      this.selectedContent = [];
      this.itemsContent = [];
      this.loadingContent = true;
      this.erro = null;

      this.dialogAwaitFiltro = true;

      let where = ``;

      nrFiltro.forEach(e => {
        where += `${e.formula} AND `;
      });

      where = where.slice(0, -4);

      console.log(this.selectedModelo);

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "",
        WHERE: where.trim(),
        TABELA: `${this.selectedModelo[0].objeto}`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      let ZZP = [];

      ZZP = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === `${this.selectedModelo[0].metadados}`,
      );

      ZZP.forEach(e => {
        if (e.ZZP_VISUAL === "1" || e.ZZP_VISUAL === " ") {
          this.headersContent.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: "250",
          });
        }
      });

      await axios
        .post(url, dados)
        .then(res => {
          let cont = 1;
          if (this.selectedModelo[0].metadados === "MD014") {
            res.data.map(e => {
              cont++;
              e.indice = `${e.NF_SERIE}${e.CODIGO_PRODUTO}${cont}`;
            });
          } else {
            res.data.map(e => {
              cont++;
              e.indice = `${e.A1_COD}${e.A1_LOJA}${cont}`;
            });
          }
          this.itemsContent = res.data;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
          this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
          this.erro += `Chamada: ${url} =====>`;
          this.erro += `${error}`;
          this.$store.commit("setDialogError", true);
        });

      this.loadingContent = false;

      this.dialogAwaitFiltro = false;

      if (this.itemsContent.length <= 0) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "blue lighten-4";
        this.message = `Não foi encontrado nenhum registro no modelo com o filtro aplicado, verifique o filtro.`;
        this.sheetMessage = true;
        return;
      }
    },
    limpafiltro() {
      this.itemsAdicionaFiltro = [{ text: ``, filtro: `` }];
      this.campoSelectFiltro = null;
      this.operadorSelect = null;
      this.expressaoFiltro = null;
      this.filtro = null;
    },
    salvaFiltro() {
      this.itemsFiltro.push({
        text: this.itemsAdicionaFiltro[0].text,
        formula: this.filtro,
        select: false,
      });
      this.itemsAdicionaFiltro = [{ text: ``, filtro: `` }];
      this.dialogNewFiltro = false;
      this.campoSelectFiltro = null;
      this.operadorSelect = null;
      this.expressaoFiltro = null;
      this.filtro = null;
      this.itemsCampoFiltro = [];
    },
    adicionaANDFiltro() {
      if (!this.filtro) return;

      this.itemsAdicionaFiltro[0].text += ` .AND. `;

      this.filtro += ` AND `;
    },
    AdicionaFiltro() {
      if (
        !this.campoSelectFiltro ||
        !this.operadorSelect ||
        !this.expressaoFiltro
      ) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Preencha o campo, operador e expressão para adicionar o filtro.`;
        this.sheetMessage = true;
        return;
      }
      this.itemsAdicionaFiltro[0].text += `${this.campoSelectFiltro} ${this.operadorSelect} ${this.expressaoFiltro}`;

      let expressao = null;

      if (this.expressaoFiltro.includes("/")) {
        expressao = `${this.expressaoFiltro.substr(
          6,
          4,
        )}${this.expressaoFiltro.substr(3, 2)}${this.expressaoFiltro.substr(
          0,
          2,
        )}`;
      } else {
        expressao = this.expressaoFiltro;
      }

      if (this.operadorSelect === "LIKE") {
        expressao = `%${expressao}%`;
      }

      if (!this.filtro) {
        this.filtro = `${this.campoSelectFiltro} ${
          this.operadorSelect
        } '${expressao.toUpperCase()}'`;
      } else {
        this.filtro += `${this.campoSelectFiltro} ${
          this.operadorSelect
        } '${expressao.toUpperCase()}'`;
      }

      this.campoSelectFiltro = null;
      this.operadorSelect = null;
      this.expressaoFiltro = null;
    },
    newFiltro() {
      if (this.selectedModelo.length <= 0) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Escolha um modelo de dados!`;
        this.sheetMessage = true;
        return;
      }
      this.dialogNewFiltro = true;
      this.buscaCampos();
    },

    async buscaCampos() {
      this.itemsCampoFiltro = [];
      this.erro = null;
      let ZZP = [];

      ZZP = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === `${this.selectedModelo[0].metadados}`,
      );

      ZZP.forEach(e => {
        if (e.ZZP_USADO === "1") {
          this.itemsCampoFiltro.push({
            name: e.ZZP_TITULO,
            campo: e.ZZP_CAMPO,
          });
        }
      });
    },
    novo() {
      this.dialogNovo = true;
    },
    marcar(filtro) {
      filtro.select = !filtro.select;
    },
    async buscaModelos() {
      this.erro = null;
      this.loadingMdDados = true;
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: ``,
        WHERE: ``,
        TABELA: "ZW3",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.forEach(e => {
            this.itemsMdDados.push({
              modelo: e.ZW3_NOME.trim(),
              objeto: e.ZW3_OBJETO.trim(),
              metadados: e.ZW3_METADA.trim(),
            });
          });
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
          this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
          this.erro += `Chamada: ${url} =====>`;
          this.erro += `${error}`;
          this.$store.commit("setDialogError", true);
        });

      this.loadingMdDados = false;
    },
    LimpaFiltroCriado() {
      this.itemsFiltro = [];
    },
    verificaDuplica() {
      if (this.selectedModelo[0].objeto === "V_RECEITAS") {
        this.distintosReceita = [
          ...new Set(this.selectedContent.map(item => item.COD_CLIENTE)),
        ];
        this.dialogDuplic = true;
      } else if (this.selectedModelo[0].objeto === "V_PROX_REVISAO") {
        this.distintosReceita = [
          ...new Set(this.selectedContent.map(item => item.COD_CLIENTE)),
        ];
        this.dialogDuplic = true;
      } else {
        this.criaCampanha();
      }
    },
    async criaCampanha() {
      if (!this.nomeCampanha) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Digite o nome da campanha.`;
        this.sheetMessage = true;
        return;
      }

      if (!this.finalidade) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Digite a finalidade da campanha.`;
        this.sheetMessage = true;
        return;
      }

      if (!this.dtInicioCamp || !this.dtFimCamp) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Digite o inicio e fim da campanha.`;
        this.sheetMessage = true;
        return;
      }

      if (this.selectedContent.length <= 0) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Selecione um ou mais registros para criar a campanha.`;
        this.sheetMessage = true;
        return;
      }

      this.dialogAwaitSaveCamp = true;

      let dtini = this.dtInicioCamp.split("/");
      let dtfim = this.dtFimCamp.split("/");

      const nrFiltro = this.itemsFiltro.filter(e => e.select === true);

      let filtro = ``;

      nrFiltro.forEach(e => {
        filtro += `${e.formula} AND `;
      });

      filtro = filtro.slice(0, -4);

      const data = new Date();

      const ano = data.getFullYear().toString();
      let mes = (data.getMonth() + 1).toString();
      let dia = data.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      const hora = data.getHours();
      let minuto = data.getMinutes();
      if (minuto < 10) {
        minuto = `0${minuto}`;
      }

      let registroSelecionados = 0;

      if (
        this.selectedModelo[0].objeto === "V_RECEITAS" ||
        this.selectedModelo[0].objeto === "V_PROX_REVISAO"
      ) {
        registroSelecionados = this.distintosReceita.length;
      } else {
        registroSelecionados = this.selectedContent.length;
      }
      const ZW4 = {
        ZW4_NOME: this.removeAcento(this.nomeCampanha),
        ZW4_DTINI: `${dtini[2]}${dtini[1]}${dtini[0]}`,
        ZW4_DTFIM: `${dtfim[2]}${dtfim[1]}${dtfim[0]}`,
        ZW4_MODEL: this.selectedModelo[0].modelo,
        ZW4_FILTRO: filtro,
        ZW4_NREGIS: registroSelecionados,
        ZW4_DTINC: `${ano}${mes}${dia}`,
        ZW4_HORA: `${hora}:${minuto}`,
        ZW4_USER: this.user.id,
        ZW4_NREGTO: this.selectedContent.length,
        ZW4_TIPO: "C",
        ZW4_FINALI: this.removeAcento(this.finalidade),
      };

      const ZW5 = [];

      let campoCodCli = ``;
      let campoLojaCli = ``;
      if (
        this.selectedModelo[0] &&
        this.selectedModelo[0].objeto === "V_RECEITAS"
      ) {
        campoCodCli = `COD_CLIENTE`;
        campoLojaCli = `LOJA_CLIENTE`;

        this.distintosReceita.forEach(e => {
          ZW5.push({
            ZW5_CLIENT: e,
            ZW5_LOJA: "01",
            ZW5_STATUS: "A",
            ZW5_DTINI: `${dtini[2]}${dtini[1]}${dtini[0]}`,
            ZW5_DTFIM: `${dtfim[2]}${dtfim[1]}${dtfim[0]}`,
          });
        });
      } else if (
        this.selectedModelo[0] &&
        this.selectedModelo[0].objeto === "V_PROX_REVISAO"
      ) {
        campoCodCli = `COD_CLIENTE`;
        campoLojaCli = `LOJA_CLIENTE`;

        this.distintosReceita.forEach(e => {
          ZW5.push({
            ZW5_CLIENT: e,
            ZW5_LOJA: "01",
            ZW5_STATUS: "A",
            ZW5_DTINI: `${dtini[2]}${dtini[1]}${dtini[0]}`,
            ZW5_DTFIM: `${dtfim[2]}${dtfim[1]}${dtfim[0]}`,
          });
        });
      } else {
        campoCodCli = `A1_COD`;
        campoLojaCli = `A1_LOJA`;

        this.selectedContent.forEach(e => {
          ZW5.push({
            ZW5_CLIENT: e[campoCodCli],
            ZW5_LOJA: e[campoLojaCli],
            ZW5_STATUS: "A",
            ZW5_DTINI: `${dtini[2]}${dtini[1]}${dtini[0]}`,
            ZW5_DTFIM: `${dtfim[2]}${dtfim[1]}${dtfim[0]}`,
          });
        });
      }

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZW4,
        ZW5,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/campanhas`;

      await axios
        .post(url, dados)
        .then(res => {
          if (res.data.STATUS === "true") {
            this.dialogAwaitSaveCamp = false;
            this.itemsCampoFiltro = [];
            this.itemsContent = [];
            this.nomeCampanha = null;
            this.dtInicioCamp = null;
            this.dtFimCamp = null;
            this.dialogNovo = false;
            this.dialogDuplic = false;
            this.montaTabela();
          }
        })
        .catch(error => {
          this.dialogAwaitSaveCamp = false;
          this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
          this.erro += `Chamada: ${url} =====>`;
          this.erro += `${error}`;
          this.$store.commit("setDialogError", true);
        });
    },
    async montaTabela() {
      this.loadingCamp = true;
      this.itemsFollow = [];
      this.itemsCamp = [];
      this.itemsCampItems = [];
      this.nomeCampanha = null;
      this.nomeCampanhaItems = null;
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS:
          "DISTINCT ZW4_ID,ZW4_DTINI,ZW4_DTFIM,ZW4_DTINC,ZW4_USER,ZW4_NOME,ZW4_HORA,ZW4_MODEL,ZW4_NREGIS,ZW4_NREGTO,ZS11.ZS1_NOME USER_CRIACAO",
        WHERE: `ZW4_TIPO='C'`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/campanha`;

      await axios
        .post(url, dados)
        .then(res => {
          console.log(res.data);
          res.data.map(e => {
            e.ZW4_DTINC = `${e.ZW4_DTINC.substr(6, 2)}/${e.ZW4_DTINC.substr(
              4,
              2,
            )}/${e.ZW4_DTINC.substr(0, 4)}`;
            e.ZW4_DTINI = `${e.ZW4_DTINI.substr(6, 2)}/${e.ZW4_DTINI.substr(
              4,
              2,
            )}/${e.ZW4_DTINI.substr(0, 4)}`;
            e.ZW4_DTFIM = `${e.ZW4_DTFIM.substr(6, 2)}/${e.ZW4_DTFIM.substr(
              4,
              2,
            )}/${e.ZW4_DTFIM.substr(0, 4)}`;
          });
          this.itemsCamp = res.data;
        })
        .catch(error => {
          this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
          this.erro += `Chamada: ${url} =====>`;
          this.erro += `${error}`;
          this.$store.commit("setDialogError", true);
        });
      this.loadingCamp = false;
    },
    async mostraDetalhes(item, row) {
      row.select(true);
      this.loadingCampItems = true;
      this.itemsCampItems = [];
      this.nomeCampanhaItems = item.ZW4_NOME;
      this.nomeClienteFollow = null;
      this.itemsFollow = [];
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS:
          "ZW5_ID,ZW5_CLIENT,ZW5_LOJA,A1_NOME,A1_DTNASC,A1_TEL,ZW5_STATUS",
        WHERE: `ZW5_ID = '${item.ZW4_ID}' ORDER BY A1_NOME`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/campanha`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.A1_DTNASC = `${e.A1_DTNASC.substr(6, 2)}/${e.A1_DTNASC.substr(
              4,
              2,
            )}/${e.A1_DTNASC.substr(0, 4)}`;
          });
          this.itemsCampItems = res.data;
        })
        .catch(error => {
          this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
          this.erro += `Chamada: ${url} =====>`;
          this.erro += `${error}`;
          this.$store.commit("setDialogError", true);
        });
      this.loadingCampItems = false;
    },
    async mostraFollow(item, row) {
      row.select(true);
      this.loadingFollow = true;
      this.itemsFollow = [];
      this.nomeClienteFollow = item.A1_NOME;
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `CONVERT(VARCHAR(2000), Z4_TEXTO) Z4_TEXTO, Z4_HORA`,
        WHERE: `Z4_ID='${item.ZW5_ID}' AND Z4_ALIAS='ZW5' AND Z4_CLIENTE='${item.ZW5_CLIENT}' AND Z4_LOJA='${item.ZW5_LOJA}' ORDER BY R_E_C_N_O_ DESC`,
        TABELA: "SZ4",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          console.log(res.data);
          this.itemsFollow = res.data;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
          this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
          this.erro += `Chamada: ${url} =====>`;
          this.erro += `${error}`;
          this.$store.commit("setDialogError", true);
        });

      this.loadingFollow = false;
    },
  },
  mounted() {
    this.buscaModelos();
    this.montaTabela();
  },
};
</script>

<style>
#opcoesCamp {
  width: 30%;
}
#contentCamp {
  width: 68%;
}
/* .tbCamp > div > table > tbody > tr.v-data-table__selected {
  background:#0FCBE1 !important;
} */

.theme--light.v-data-table.tbCamp tbody tr.v-data-table__selected {
  background: #0fcbe1 !important;
}

.theme--light.v-data-table.tbCamp tbody tr.v-data-table__selected {
  background: #0fcbe1 !important;
}

.v-data-table.tbCamp tbody tr :hover {
  cursor: pointer;
}
</style>
