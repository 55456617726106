<template>
  <div id="app">
    <div class="dadosCli text-center">
      <!-- <v-dialog v-model="dialogFollowUp" width="90%" persistent> -->
      <v-card id="acoes">
        <v-card-title
          class="headline grey lighten-2 justify-center"
          v-text="titleCentral"
          style="height: 60px"
        ></v-card-title>
        <v-card-text>
          <v-toolbar flat dense color="white">
            <v-row dense class="mt-3">
              <v-col cols="1">
                <v-btn class="mx-5 mt-2"
                  fab
                  x-small
                  dark @click="montaBrowser"
                  color="cyan darken-3"><v-icon>mdi-reload</v-icon> </v-btn>
              </v-col>
              <v-col cols="1">
                
                <v-btn
                  class="mx-5 mt-2"
                  fab
                  x-small
                  dark
                  color="indigo"
                  @click="novo()"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
                
                <!-- <v-btn
                    class="mt-2"
                    fab
                    x-small
                    dark
                    color="green darken-3"
                    @click="editar()"
                  >
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn> -->
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" v-if="NrRegFiltro !== null">
                <span style="font-size: 0.7rem"
                  >Filtro aplicado no <strong>{{ filtroEscolhido }}</strong
                  >, {{ NrRegFiltro }} registro(s) encontrado(s).</span
                >
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  fab
                  color="error"
                  @click="LimpaFiltro()"
                >
                  <v-icon dark class="ml-1">mdi-eraser</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2">
                <v-select
                  v-model="filtroEscolhido"
                  :items="itemsFiltro"
                  @change="verTipoFiltro"
                  label="Escolha o campo"
                ></v-select>
              </v-col>
              <v-col cols="2" v-if="itensFiltroEsc.length <= 0">
                <v-text-field
                  placeholder="digite aqui.."
                  v-model="stringFiltro"
                  :disabled="!filtroEscolhido"
                ></v-text-field>
              </v-col>
              <v-col cols="2" v-if="itensFiltroEsc.length > 0">
                <v-select
                  v-model="stringFiltro"
                  :items="itensFiltroEsc"
                  label="Escolha..."
                ></v-select>
              </v-col>
              <v-col cols="1">
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  color="indigo"
                  @click="filtraRegistro(filtroEscolhido, stringFiltro)"
                >
                  <v-icon dark class="ml-1">mdi-account-search</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </v-card-text>
      </v-card>
      <v-card id="dados">
        <v-card-text>
          <v-data-table
            :headers="headersFollow"
            dense
            :items="itemFollow"
            class="elevation-1"
            no-data-text="Sem dados..."
            fixed-header
            height="200"
            :loading="loading"
            :search="search"
            :items-per-page="20"
            @click:row="mostraDetalhes"
            :footer-props="{
              disableItemsPerPage: true,
            }"
          >
            <template v-slot:[`item.Z4_STATUS`]="{ item }">
              <v-chip
                class="ma-2"
                x-small
                color="blue darken-2"
                dark
                v-if="item.Z4_STATUS === '02-EM ANDAMENTO'"
              >
                {{ item.Z4_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                x-small
                color="green darken-2"
                dark
                v-if="item.Z4_STATUS === '03-FECHADA'"
              >
                {{ item.Z4_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                x-small
                color="orange darken-2"
                dark
                v-if="item.Z4_STATUS === '04-PENDENTE'"
              >
                {{ item.Z4_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                x-small
                color="yellow darken-2"
                dark
                v-if="item.Z4_STATUS === '01-ABERTO'"
              >
                {{ item.Z4_STATUS }}
              </v-chip>
            </template>
            <template v-slot:[`item.Z4_TEXTO`]="{ item }">
              <v-tooltip top max-width="500" v-if="item.Z4_TEXTO.length > 100">
                <template v-slot:activator="{ on, attrs }">
                  <td v-bind="attrs" v-on="on">{{ item.Z4_TEXTO_ORI }}</td>
                </template>
                <span>{{ item.Z4_TEXTO }}</span>
              </v-tooltip>
              <span v-if="item.Z4_TEXTO.length < 100">{{ item.Z4_TEXTO }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <!-- <v-card-actions style="margin-top: -15px">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="fechaDialog">Fechar</v-btn>
          </v-card-actions> -->
      </v-card>
      <!-- </v-dialog> -->
    </div>
    <div class="dadosCli text-center">
      <v-dialog v-model="DialogDados" width="50%" persistent>
        <v-card v-if="boilerplate" ref="form">
          <v-card-title
            class="headline grey lighten-2 justify-center"
            v-text="titleDialog"
          ></v-card-title>
          <v-card-text>
            <v-row dense class="mt-5">
              <v-col
                v-for="campo in camposTela"
                :key="campo.ZZP_CAMPO"
                :cols="campo.ZZP_COLS"
              >
                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :label="campo.ZZP_TITULO"
                  v-if="campo.ZZP_TIPO === 'T'"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  class="text-uppercase"
                  dense
                  :readonly="isReading"
                  :disabled="campo.ZZP_VISUAL === '2'"
                ></v-text-field>
                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :label="campo.ZZP_TITULO"
                  v-if="campo.ZZP_TIPO === 'D'"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  v-mask="'##/##/####'"
                  class="text-uppercase"
                  dense
                  :readonly="isReading"
                  :disabled="campo.ZZP_VISUAL === '2'"
                ></v-text-field>
                <v-select
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :items="campo.ITEMS"
                  :label="campo.ZZP_TITULO"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  v-if="campo.ZZP_TIPO === 'S'"
                  class="text-uppercase"
                  dense
                  :readonly="isReading"
                  :disabled="campo.ZZP_VISUAL === '2'"
                ></v-select>
                <v-autocomplete
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                  style="margin-top: -22px"
                  dense
                  :items="itemsTipo"
                  @click="carregaItems(campo)"
                  @focus="carregaItems(campo)"
                  no-data-text="Buscando dados..."
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  filled
                  :label="campo.ZZP_TITULO"
                  background-color="white"
                  v-if="campo.ZZP_TIPO === 'A'"
                  class="text-uppercase"
                  :readonly="isReading"
                  :disabled="campo.ZZP_VISUAL === '2'"
                ></v-autocomplete>
                <v-textarea
                  style="margin-top: -25px; font-size: 0.7rem"
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :label="campo.ZZP_TITULO"
                  class="mx-2"
                  rows="3"
                  v-if="campo.ZZP_TIPO === 'Y'"
                  :readonly="isReading"
                  :disabled="campo.ZZP_VISUAL === '2'"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="error" text @click="DialogDados = false"
              >Fechar</v-btn
            >
            <v-spacer></v-spacer>
            <v-col cols="5">
              <v-progress-linear
                v-model="progress"
                v-if="progress > 0"
                color="blue-grey"
                height="25"
              >
                <template v-slot="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              v-if="!isReading && !isEditing"
              text
              @click="salva()"
              >Salvar</v-btn
            >
            <v-btn
              color="primary"
              v-if="!isReading && isEditing"
              text
              @click="salva(ID)"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="boilerplate"
          type="table"
          class="mx-auto"
          v-if="!boilerplate"
        ></v-skeleton-loader>
      </v-dialog>
      <v-dialog v-model="dialogSuccessSaveCliente" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">Lead</v-card-title>
          <v-card-text class="mt-5 title">{{ mensagem }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="limpaVariaveis">fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAwaitSaveCliente" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitSaveCliente" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Lead</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Salvando o Lead...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogErrosCliente" width="900">
        <v-card max-width="900" class="mx-auto">
          <v-card-title class="headline red lighten-2" style="color: white"
            >Erros ao salvar</v-card-title
          >

          <v-card-text>
            <v-list disabled>
              <v-subheader>Erros</v-subheader>
              <v-list-item-group v-model="aError" color="primary">
                <v-list-item v-for="(item, i) in aError" :key="i">
                  <v-list-item-icon>
                    <v-icon color="red" v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 0.8rem"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogErrosCliente = false"
              >fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-bottom-sheet v-model="sheetMessage">
      <v-sheet class="text-center" :color="colorMessage" height="130px">
        <div class="py-3">
          <v-btn class="mx-2" text fab>
            <v-icon v-text="iconMessage" @click="sheetMessage = false"></v-icon>
          </v-btn>
        </div>
        <div class="py-3" style="font-size: 15px" v-text="message"></div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapState } from "vuex";

import axios from "axios";
export default {
  name: "FollowUp",
  computed: mapState([
    "dialogFollowUp",
    "metadados",
    "lead_id",
    "lead_nome",
    "Alias",
    "user",
    "origem",
    "cliente",
  ]),
  data: () => {
    return {
      dialogSuccessSaveCliente: false,
      mensagem: "",
      titleDialog: null,
      titleCentral: null,
      camposTela: [],
      progress: 0,
      isReading: false,
      isEditing: false,
      DialogDados: false,
      boilerplate: false,
      NrRegFiltro: null,
      headersFollow: [],
      itemFollow: [],
      cloneItemFollow: [],
      nome: null,
      responsavel: null,
      departamento: null,
      documento: null,
      satisfacao: null,
      ocorrencia: null,
      loading: false,
      search: null,
      itemsFiltro: [],
      Z4_STATUS: "",
      Z4_DATA: "",
      filtroEscolhido: null,
      itensFiltroEsc: [],
      stringFiltro: null,
      itemsTipo: [],
      cloneDadosTela: [],
      dialogAwaitSaveCliente: false,
      dialogErrosCliente: false,
      aError: [],
      sheetMessage: false,
      colorMessage: "",
      iconMessage: "",
      message: "",
    };
  },
  methods: {
    limpaVariaveis() {
      this.DialogDados = false;
      this.dialogSuccessSaveCliente = false;
      this.montaBrowser();
    },
    queryAndIndeterminate() {
      this.progress = 0;

      this.interval = setInterval(() => {
        if (this.progress === 100) {
          clearInterval(this.interval);
          this.progress = 0;
          return;
          //return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.progress += 5;
      }, 1000);
    },
    novo() {
      this.dadosTela = [];
      this.isReading = false;
      this.titleDialog = `Incluindo Follow-Up`;
      this.carregaDados();
      this.DialogDados = true;
    },
    //Função para remover caracteres especiais na Notas
    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");
      return text.toUpperCase();
    },
    async salva(id) {
      console.log(id)
      const keysObrigat = [];
      this.camposTela.filter(e => {
        if (e.ZZP_OBRIGA === "1") {
          const campo = e.ZZP_CAMPO.trim();
          keysObrigat.push({
            campo,
          });
        }
      });

      let verifica = true;

      this.cloneDadosTela = this.dadosTela;

      const keys = Object.keys(this.cloneDadosTela);

      keysObrigat.forEach(e => {
        if (keys.indexOf(e.campo) < 0) {
          verifica = false;
        }
      });

      if (!verifica) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      this.camposTela.forEach(e => {
        let value = null;
        if (
          e.ZZP_TIPO.trim() === "A" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("-");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
        }
        if (
          e.ZZP_TIPO.trim() === "S" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("=");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
        }
        if (
          e.ZZP_TIPO.trim() === "D" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("/");
          this.cloneDadosTela[
            e.ZZP_CAMPO.trim()
          ] = `${value[2]}${value[1]}${value[0]}`;
        }
        if (
          e.ZZP_TIPO.trim() === "T" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] !== "ZZQ_EMAIL"
        ) {
          value = this.removeAcento(this.cloneDadosTela[e.ZZP_CAMPO.trim()]);
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value;
        }
        if (e.ZZP_TIPO.trim() === "Y") {
          value = this.removeAcento(this.cloneDadosTela[e.ZZP_CAMPO.trim()]);
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value;
        }
      });

      const data = new Date();
      const ano = data.getFullYear().toString();
      let mes = (data.getMonth() + 1).toString();
      let dia = data.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      const hora = data.getHours();
      let minuto = data.getMinutes();
      if (minuto < 10) {
        minuto = `0${minuto}`;
      }

      this.queryAndIndeterminate();
      if (id) {
        let SZ4 = { ...this.cloneDadosTela };

        SZ4.Z4_CLIENTE = "";
        SZ4.Z4_LOJA = "";
        SZ4.Z4_ID = id;
        SZ4.Z4_STATUS = "03";
        SZ4.Z4_TIPO = "14";
        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          SZ4,
        };

        console.log("dados: ", dados)

        const url = `${process.env.VUE_APP_BASE_API_URL}/followUp`;
        await axios
          .put(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = `Follow-Up alterado com o código: ${res.data.CODIGO}`;
              this.dialogAwaitSaveCliente = false;
              this.dialogSuccessSaveCliente = true;
              this.progress = 100;
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({ text: `${e}`, icon: "mdi-send" });
              });
              this.dialogAwaitSaveCliente = false;
              this.dialogErrosCliente = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => {
            this.aError = [];
            this.aError.push({ text: `${err}`, icon: "mdi-send" });

            this.dialogAwaitSaveCliente = false;
            this.dialogErrosCliente = true;
            this.progress = 100;
            return;
          });
      } else {
        let SZ4 = { ...this.cloneDadosTela };

        SZ4.Z4_DATA = `${ano}${mes}${dia}`;
        SZ4.Z4_HORA = `${hora}:${minuto}`;
        SZ4.Z4_RESP1 = this.user.id;
        if(this.user.cod_vendedor.trim() !== ''){
          SZ4.Z4_RESPONS = this.user.cod_vendedor
        }
        //SZ4.Z4_RESPONS = '000000'
        SZ4.Z4_NOME = this.user.name;
        SZ4.Z4_ALIAS = this.Alias;

        let dadosCliente;

        if (this.origem === "Cliente") {
          dadosCliente = this.lead_id.split("-");
          SZ4.Z4_CLIENTE = dadosCliente[0];
          SZ4.Z4_LOJA = dadosCliente[1];
          SZ4.Z4_ID = "";
        }

        if (this.origem === "Leads") {
          SZ4.Z4_STATUS = "03";
          SZ4.Z4_TIPO = "14";
        }

        if (this.origem === "Atividade") {
          console.log(this.cliente);
          SZ4.Z4_CLIENTE = this.cliente.CODIGO;
          SZ4.Z4_LOJA = this.cliente.LOJA;
          SZ4.Z4_STATUS = "03";
          SZ4.Z4_TIPO = "14";
        }

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          SZ4,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/saveFollow`;
        await axios
          .post(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              console.log(this.idSalvo);
              this.mensagem = `Follow-Up salvo com sucesso!`;
              this.dialogAwaitSaveCliente = false;
              this.dialogSuccessSaveCliente = true;
              this.progress = 100;
              setTimeout(() => {
                this.dialogSuccessSaveCliente = false;
                this.limpaVariaveis();
              }, 2000);
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({ text: `${e}`, icon: "mdi-send" });
              });
              this.dialogAwaitSaveCliente = false;
              this.dialogErrosCliente = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => console.log(err));
      }

      this.camposTela.forEach(e => {
        let value = null;
        if (e.ZZP_TIPO.trim() === "D") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()];
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = `${value.substr(
            6,
            2,
          )}/${value.substr(4, 2)}/${value.substr(0, 4)}`;
        }
      });
      this.progress = 100;
    },
    obrigat(value, obrigatorio) {
      if (!value && obrigatorio === "1") {
        return ["Campo obrigatório!"];
      }
    },
    async carregaItems(item) {
      console.log(item);
      let dados = {};
      let items = [];
      this.itemsTipo = [];
      let where = item.ZZP_WHERE.trim();
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: where,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
          this.itemsTipo = items;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    editItem(item) {
      this.carregaDados();
      this.titleDialog = `Editando Follow-Up: ${item.Z4_NOME}-${item.Z4_DATA}`;
      this.id = `${item.Z4_ID}`;

      this.isEditing = true;
      this.dadosTela = item;

      this.isReading = false;
      this.DialogDados = true;
    },
    async carregaDados() {
      this.boilerplate = false;
      let campos = [];

      this.countObrigatorio = null;

      let ZZP = [];

      console.log(this.origem);

      if (this.origem === "Leads" || this.origem === "Atividade") {
        ZZP = this.metadados.filter(
          e => e.ZZP_MODELO.trim() === "MD10" && e.ZZP_USADO === "1",
        );
      }

      if (this.origem === "Cliente") {
        ZZP = this.metadados.filter(
          e => e.ZZP_MODELO.trim() === "MD013" && e.ZZP_USADO === "1",
        );
      }

      ZZP.map(e => {
        if (e.ZZP_CBOX.trim().length > 0) {
          const dados = e.ZZP_CBOX.split(";");
          e.ITEMS = dados.map(i => {
            return `${i}`;
          });
        }

        if (e.ZZP_TIPO.trim() === "D") {
          e.MASK = "##/##/####";
        }
        if (e.ZZP_OBRIGA === "1") {
          this.countObrigatorio++;
        }
      });

      campos = ZZP;

      this.camposTela = campos;

      this.dadosTela.Z4_NOME = this.lead_nome.trim();

      this.dadosTela.Z4_ID = this.lead_id;

      this.boilerplate = true;
    },
    verTipoFiltro() {
      this.itensFiltroEsc = [];
      if (this.filtroEscolhido === "Status") {
        this.itensFiltroEsc = [
          // { text: "Aberto", value: "01-ABERTO" },
          // { text: "Em andamento", value: "02-EM ANDAMENTO" },
          { text: "Fechada", value: "03-FECHADA" },
          // { text: "Pendente", value: "04-PENDENTE" },
        ];
      }
    },
    async filtraRegistro(campo, stringFiltro) {
      if (!campo || !stringFiltro) return;

      this.nome = null;
      this.responsavel = null;
      this.departamento = null;
      this.documento = null;
      this.satisfacao = null;
      this.ocorrencia = null;

      let WHERE = "";

      if (campo === "Status") {
        stringFiltro = stringFiltro.split("-");
        WHERE = `Z4_STATUS = '${stringFiltro[0]}'`;
      }

      if (campo === "Data") {
        stringFiltro = stringFiltro.split("/");
        WHERE = `Z4_DATA = '${stringFiltro[2]}${stringFiltro[1]}${stringFiltro[0]}'`;
      }

      if (this.origem === "Leads")
        WHERE += ` AND Z4_ALIAS='${this.Alias}' AND Z4_ID='${this.lead_id}'`;

      let dadosCliente;

      if (this.origem === "Cliente") dadosCliente = this.lead_id.split("-");
      WHERE += ` AND Z4_ALIAS='${this.Alias}' AND Z4_CLIENTE='${dadosCliente[0]}' AND Z4_LOJA='${dadosCliente[1]}'`;

      WHERE += " ORDER BY SZ4.R_E_C_N_O_ DESC";

      

      this.loading = true;
      this.itemLeads = [];

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: WHERE,
        TABELA: "SZ4",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/followUp`;

      await axios
        .post(url, dados)
        .then(res => {
          
          res.data.map(e => {
            e.Z4_DATA = `${e.Z4_DATA.substr(6, 2)}/${e.Z4_DATA.substr(
              4,
              2,
            )}/${e.Z4_DATA.substr(0, 4)}`;
            if (e.Z4_STATUS) e.Z4_STATUS = `${e.Z4_STATUS}-${e.Z5_DESC}`;
            if (!e.Z4_STATUS) e.Z4_STATUS = `03-FECHADA`;
            if (e.Z4_TEXTO && e.Z4_TEXTO.length > 100) {
              e.Z4_TEXTO_ORI = e.Z4_TEXTO.substr(0, 100) + "...";
            } else {
              e.Z4_TEXTO_ORI = e.Z4_TEXTO;
            }
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "P")
              e.Z4_SATISFA = "P=PESSIMO";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "R")
              e.Z4_SATISFA = "R=RUIM";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "B")
              e.Z4_SATISFA = "B=BOM";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "E")
              e.Z4_SATISFA = "E=EXCELENTE";
            if (e.Z4_TIPO) e.Z4_TIPO = `${e.Z4_TIPO}-${e.Z6_DESC}`;
          });
          this.itemFollow = res.data;
          this.NrRegFiltro = this.itemFollow.length;
        })
        .catch(err => console.log(err));

      this.loading = false;
    },
    LimpaFiltro() {
      this.stringFiltro = null;
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.itensFiltroEsc = [];
      this.itemFollow = this.cloneItemFollow;
    },
    mostraDetalhes(item) {
      this.nome = item.Z4_NOME;
      this.responsavel = item.ZS1_NOME;
      this.departamento = item.Z4_DEPTO;
      this.documento = item.Z4_DOC;
      this.satisfacao = item.Z4_SATISFA;
      this.ocorrencia = item.Z4_TEXTO;
    },
    fechaDialog() {
      this.$store.commit("SetDialogFollowUp", false);
    },
    async montaBrowser() {
      this.nome = null;
      this.responsavel = null;
      this.departamento = null;
      this.documento = null;
      this.satisfacao = null;
      this.ocorrencia = null;
      let ZZP = [];
      let dados = {};
      let url = ``;
      this.headersFollow = [];
      this.itemFollow = [];
      (this.headersFollow = []), (this.loading = true);

      if (this.origem === "Leads") {
        ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD10");
        this.titleCentral = `Follow-Up de ${this.lead_id}-${this.lead_nome}`;
      }
      if (this.origem === "Cliente") {
        ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD013");
        this.titleCentral = `Follow-Up de ${this.lead_id}-${this.lead_nome}`;
      }

      if (this.origem === "Atividade") {
        ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD10");
        this.titleCentral = `Follow-UP de ${this.lead_nome}`;
      }

      ZZP.map(e => {
        let width = 90;
        // if (e.ZZP_COLS <= 2) {
        //   width = 130;
        // } else if (e.ZZP_COLS <= 3) {
        //   width = 200;
        // } else {
        //   width = 250;
        // }
        const name = `${e.ZZP_CAMPO.trim()}`;
        if (e.ZZP_BROWSE === "1") {
          if (name === "Z4_STATUS") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.headersFollow.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
              filter: f => {
                return (f + "")
                  .toLowerCase()
                  .includes(this["Z4_STATUS"].toLowerCase());
              },
            });
          }
          if (name === "Z4_DATA") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.headersFollow.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
              filter: f => {
                return (f + "")
                  .toLowerCase()
                  .includes(this["Z4_DATA"].toLowerCase());
              },
            });
          }

          if (name !== "Z4_STATUS" && name !== "Z4_DATA") {
            if (e.ZZP_TIPO === "Y") {
              this.headersFollow.push({
                text: e.ZZP_TITULO.trim(),
                value: e.ZZP_CAMPO.trim(),
                align: "left",
                width: 400,
                caption: "title",
              });
            } else {
              this.headersFollow.push({
                text: e.ZZP_TITULO.trim(),
                value: e.ZZP_CAMPO.trim(),
                align: "center",
                width: width,
                caption: "title",
              });
            }
          }
        }
      });

      const tabelaAux = [...new Set(ZZP.map(item => item.ZZP_TABELA))];

      this.stringCampos = "";

      let tabela = tabelaAux[0];

      ZZP.forEach(e => {
        if (e.ZZP_TABELA === tabela) {
          if (e.ZZP_TIPO === "Y") {
            this.stringCampos += `CONVERT(VARCHAR(2000), ${e.ZZP_CAMPO.trim()}) ${e.ZZP_CAMPO.trim()},`;
          } else {
            this.stringCampos += `${e.ZZP_CAMPO.trim()},`;
          }
        }
      });

      if (this.stringCampos.substr(-1) === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      this.stringCampos += " ,ZS1_NOME";

      let WHERE = ``;

      let dadosCliente;

      if (this.origem === "Leads") {
        WHERE += ` Z4_ALIAS='${this.Alias}' AND Z4_ID='${this.lead_id}' ORDER BY SZ4.R_E_C_N_O_ DESC`;
      } else if (this.origem === "Cliente") {
        dadosCliente = this.lead_id.split("-");
        WHERE += ` Z4_ALIAS='${this.Alias}' AND Z4_CLIENTE='${dadosCliente[0]}' AND Z4_LOJA='${dadosCliente[1]}' ORDER BY SZ4.R_E_C_N_O_ DESC`;
      } else if (this.origem === "Atividade") {
        WHERE += ` Z4_ALIAS='ZW5' AND Z4_ID='${this.lead_id}' AND Z4_CLIENTE='${this.cliente.CODIGO}' AND Z4_LOJA='${this.cliente.LOJA}' ORDER BY SZ4.R_E_C_N_O_ DESC`;
      }

      this.stringCampos += ",Z4_USERINC"

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: WHERE,
        TABELA: tabela.substr(0, 3),
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/followUp`;

      this.itemLeads = [];

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.Z4_DATA = `${e.Z4_DATA.substr(6, 2)}/${e.Z4_DATA.substr(
              4,
              2,
            )}/${e.Z4_DATA.substr(0, 4)}`;
            
            if (e.Z4_STATUS) e.Z4_STATUS = `${e.Z4_STATUS}-${e.Z5_DESC}`;
            
            if (!e.Z4_STATUS) e.Z4_STATUS = `03-FECHADA`;
            
            if (e.Z4_TEXTO && e.Z4_TEXTO.length > 100) {
              e.Z4_TEXTO_ORI = e.Z4_TEXTO.substr(0, 100) + "...";
            } else {
              e.Z4_TEXTO_ORI = e.Z4_TEXTO;
            }
            
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "P")
              e.Z4_SATISFA = "P=PESSIMO";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "R")
              e.Z4_SATISFA = "R=RUIM";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "B")
              e.Z4_SATISFA = "B=BOM";
            if (e.Z4_SATISFA && e.Z4_SATISFA.trim() === "E")
              e.Z4_SATISFA = "E=EXCELENTE";
            if (e.Z4_TIPO) e.Z4_TIPO = `${e.Z4_TIPO}-${e.Z6_DESC}`;


            if(e.Z4_USERINC.trim() === ""){
              e.Z4_RESP1 = `${e.Z4_RESP1}-${e.Z4_NOME}`
            }else{
              e.Z4_RESP1 = e.Z4_USERINC
            }

            if(e.Z4_DEPTO === "01") e.Z4_DEPTO = `${e.Z4_DEPTO}-DEPTO COMERCIAL`
            if(e.Z4_DEPTO === "02") e.Z4_DEPTO = `${e.Z4_DEPTO}-DEPTO FINANCEIRO`
            if(e.Z4_DEPTO === "03") e.Z4_DEPTO = `${e.Z4_DEPTO}-DEPTO FONOAUDIOLOGIA`
            if(e.Z4_DEPTO === "04") e.Z4_DEPTO = `${e.Z4_DEPTO}-DEPTO LOGÍSTICA`
            if(e.Z4_DEPTO === "05") e.Z4_DEPTO = `${e.Z4_DEPTO}-TELEMARKTING`
          });
          this.itemFollow = [];
          this.cloneItemFollow = [];

          this.itemFollow = res.data;
          this.cloneItemFollow = res.data;
        })
        .catch(err => console.log(err));

      this.perPage = this.itemFollow.length;
      this.loading = false;
    },
  },
};
</script>

<style>
.v-label {
  font-size: 0.7rem;
}
input {
  font-size: 0.8rem;
}
.text-center {
  font-size: 0.7rem !important;
}
.text-start {
  font-size: 0.7rem !important;
}
tbody tr:nth-of-type(odd) {
  background-color: #d8f6fa;
}
.v-select__selection {
  font-size: 0.8rem !important;
}
.scroll {
  overflow-y: scroll;
}
</style>
