<template>
  <div>
    <v-dialog v-model="dialogVersion" persistent width="35vw">
      <v-card>
        <v-card-title>Nova Versão do Portal <span class="red--text ml-3"> {{newVersion}}</span></v-card-title>
        <v-card-text>
          <div class="d-flex flex-column justify-center align-center">
            <p class="text-h4 cyan--text">Existe uma nova versão do portal!</p>
            <p class="text-h6">Clique no botão recarregar para atualizar.</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn x-small class="primary" @click="recarregaVersao">Recarregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'dialogVersao',
  computed: mapState(["dialogVersion", "newVersion"]),
  methods:{
    recarregaVersao(){
      this.$store.commit("SetVersion", false)
      document.location.reload()
    }
  }
}
</script>

<style>

</style>