import axios from "axios";

import { stringToBrMoney } from "@/utils/stringParse";

import { add, sub } from "date-fns";

export default {
  state: {
    trimestre: [],
    tabFaturamento: [],
    headersFaturamento: [],
    indiceReajuste: null,
    anoEscolhido: null,
    ajustes: [],
    tabMetas: [],
    mostraCalculo: false,
    dadosCalculo: [],
    dialogAguarde: false,
    assistentes: [],
    fonos: [],
    tabFaturamentoUN: [],

    tabFaturamentoASS: [],
    tabFaturamentoFN: [],
    area: [],
  },
  mutations: {
    async setDadosCalculo(state, payload) {
      state.dadosCalculo = payload;
    },
    setAnoEscolhido(state, payload) {
      state.anoEscolhido = payload;
    },
    setArea(state, payload) {
      state.area = payload;
    },
    setMostraCalculo(state, payload) {
      state.mostraCalculo = payload;
    },
    setTrimestre(state, payload) {
      state.trimestre = payload;
    },
    async setCalcUnidadeNegocio(state) {
      state.tabFaturamentoUN = [];
      state.headersFaturamento = [];
      state.tabFaturamento = [];
      state.mostraCalculo = false;
      state.dialogAguarde = true;
      let url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const anos = sub(new Date(`${state.anoEscolhido}-07-01`), { years: 2 });

      let anosConsulta = [];

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anos, { years: cont }).getFullYear() <= new Date().getFullYear()
        ) {
          anosConsulta.push(add(anos, { years: cont }).getFullYear());
        }
      }

      state.headersFaturamento.push({
        text: "Mês",
        value: "mes",
        align: "start",
      });

      anosConsulta.map(e => {
        state.headersFaturamento.push({
          text: `${e}`,
          value: `A-${e}`,
          align: "end",
        });
      });
      state.dadosCalculo[0].itens[0];
      let dados = {
        FIELDS: "ZXB_ANOMES,ZXB_VALOR",
        INNER: `INNER JOIN ZXB010 ZXB WITH(NOLOCK) ON 
                                            ZXB.D_E_L_E_T_=' ' 
                                            AND ZXB_IDXA=ZXA_ID 
                                            AND ZXB_TIPO='LJ'`,
        WHERE: `ZXA_LOJFIL='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'`,
        TABELA: "ZXA",
      };

      console.log("dados unidade de negócio", dados);

      const faturamento = await axios.post(url, dados);

      const meses = [
        { id: "01", title: "Janeiro", value: "00" },
        { id: "02", title: "Fevereiro", value: "01" },
        { id: "03", title: "Março", value: "02" },
        { id: "04", title: "Abril", value: "03" },
        { id: "05", title: "Maio", value: "04" },
        { id: "06", title: "Junho", value: "05" },
        { id: "07", title: "Julho", value: "06" },
        { id: "08", title: "Agosto", value: "07" },
        { id: "09", title: "Setembro", value: "08" },
        { id: "10", title: "Outubro", value: "09" },
        { id: "11", title: "Novembro", value: "10" },
        { id: "12", title: "Dezembro", value: "11" },
      ];

      meses.map(m => {
        state.tabFaturamento.push({
          mes: m.title,
          valor: m.value,
        });
      });

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Fevereiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Março") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Abril") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Maio") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Junho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Julho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Agosto") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Setembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Outubro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Novembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Dezembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
      });

      //buscando o faturamento depois de 2023 buscar direto da View

      let area = "";

      const dadosArea = state.area[0].area.split(";");

      dadosArea.map(e => {
        area += `'${e.trim()}',`;
      });

      area = area.substring(0, area.length - 1);

      const anoBi = 2023;

      //faturamento area 04

      if (state.dadosCalculo[0].itens[0].ZXD_VEND === "A04   ") {
        url = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
        dados = {
          query: `SELECT SUM(VALOR) VALOR, ANOMES FROM (SELECT FATURAMENTO_LIQUIDO VALOR,ANOMES
            FROM DB_V_RECEITAS_BI  WITH(NOLOCK) WHERE LEFT(AREA,6)='000004'
            AND PLANO_SAUDE<>'1'
                             AND APAC<>'S'
                             AND LEFT(ANOMES,4) >= '${anoBi}'
                             AND RELATORIO_BI<>'2'
                             
                             
                             
                        UNION ALL
                        
                        SELECT FATURAMENTO_LIQUIDO VALOR,ANOMES FROM DB_V_RECEITAS_BI WHERE LEFT(ANOMES,4) >= '${anoBi}' AND LEFT(FILIAL,4)='${state.dadosCalculo[0].itens[0].ZXD_LOJA}') AUX
                        GROUP BY AUX.ANOMES`,
        };
      } else {
        url = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;

        if (state.dadosCalculo[0].itens[0].ZXD_LOJA.substr(0, 1) === "0") {
          dados = {
            query: `

                        SELECT SUM(FATURAMENTO_LIQUIDO) VALOR,ANOMES
                                            FROM DB_V_RECEITAS_BI  WITH(NOLOCK) 

                                            INNER JOIN SA3010 SA3 WITH(NOLOCK) ON A3_COD=CHAVE_VENDEDOR AND A3_FILREF='${state.dadosCalculo[0].itens[0].ZXD_LOJA}' AND A3_ZZLOJRF=''

                                            WHERE 
                                                    LEFT(AREA,6) IN (${area})
                                                    AND LEFT(ANOMES,4) >= '${anoBi}'
                                                    AND APAC<>'S'
                                                  
                                                    AND PLANO_SAUDE<>'1'
                                                    AND RELATORIO_BI<>'2'
                                                    AND CHAVE_VENDEDOR NOT IN ('002491')
                                        
                                                    GROUP BY ANOMES
                       
                         `,
          };
        } else {
          console.log(
            "####################entrei aqui linha 325##########################",
          );
          dados = {
            query: `SELECT SUM(VALOR) VALOR, ANOMES FROM (

                        SELECT FATURAMENTO_LIQUIDO VALOR,ANOMES
                                            FROM DB_V_RECEITAS_BI  WITH(NOLOCK) 

                                            INNER JOIN SA3010 SA3 WITH(NOLOCK) ON A3_COD=CHAVE_VENDEDOR AND A3_ZZLOJRF='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'

                                            WHERE 
                                                    LEFT(AREA,6) IN (${area})
                                                    AND LEFT(ANOMES,4) >= '${anoBi}'
                                                    AND APAC<>'S'
                                                    AND LINHA = 'AUDITIVA'
                                                    AND PLANO_SAUDE<>'1'
                                                    AND RELATORIO_BI<>'2'
                                                    AND CHAVE_VENDEDOR NOT IN ('002491')
                                                   
                        
                        
                        UNION ALL
                        
                        SELECT FATURAMENTO_LIQUIDO VALOR,ANOMES
                                            FROM DB_V_RECEITAS_BI  WITH(NOLOCK) 
                                            
                                            INNER JOIN SA3010 SA3 WITH(NOLOCK) ON A3_COD=CHAVE_VENDEDOR AND A3_ZZLOJRF='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'
                                            
                                            WHERE 
                                                    
                                                    LEFT(FILIAL,4)='0301'
                                                    AND LEFT(AREA,6) IN (${area})
                                                    AND LINHA='SERVICOS'
                                                    AND LEFT(ANOMES,4) >= '${anoBi}'
                                        
                        
                        
                        ) AUX
                        WHERE 1=1
                        GROUP BY ANOMES
                         `,
          };
        }
      }

      const fatReceitaBi = await axios.post(url, dados);

      let anosFatBi = [
        ...new Set(fatReceitaBi.data.map(e => e.ANOMES.substr(0, 4))),
      ];

      if (anosConsulta[anosConsulta.length - 1] === 2023) {
        anosFatBi = ["2023"];
      }

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}01`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}01`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}01`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Fevereiro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}02`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}02`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}02`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Março") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}03`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}03`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}03`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Abril") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}04`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}04`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}04`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Maio") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}05`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}05`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}05`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Junho") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}06`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}06`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}06`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Julho") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}07`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}07`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}07`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Agosto") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}08`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}08`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}08`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Setembro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}09`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}09`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}09`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Outubro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}10`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}10`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}10`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Novembro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}11`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}11`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}11`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Dezembro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}12`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}12`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}12`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
      });

      state.tabFaturamentoUN = state.tabFaturamento;

      state.mostraCalculo = true;
      state.dialogAguarde = false;
    },
    async setCalcOpgrade(state) {
      state.headersFaturamento = [];
      state.tabFaturamento = [];
      state.mostraCalculo = false;
      state.dialogAguarde = true;
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const anos = sub(new Date(`${state.anoEscolhido}-07-01`), { years: 2 });

      let anosConsulta = [];

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anos, { years: cont }).getFullYear() <= new Date().getFullYear()
        ) {
          anosConsulta.push(add(anos, { years: cont }).getFullYear());
        }
      }

      state.headersFaturamento.push({
        text: "Mês",
        value: "mes",
        align: "start",
      });

      anosConsulta.map(e => {
        state.headersFaturamento.push({
          text: `${e}`,
          value: `A-${e}`,
          align: "end",
        });
      });

      let dados = {
        FIELDS: "ZXB_ANOMES,ZXB_VALOR",
        INNER: `INNER JOIN ZXB010 ZXB WITH(NOLOCK) ON 
                                            ZXB.D_E_L_E_T_=' ' 
                                            AND ZXB_IDXA=ZXA_ID 
                                            AND ZXB_TIPO='${state.dadosCalculo[0].itens[0].ZXD_TIPO}'`,
        WHERE: `ZXA_LOJFIL='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'`,
        TABELA: "ZXA",
      };

      const faturamento = await axios.post(url, dados);

      const meses = [
        { id: "01", title: "Janeiro", value: "00" },
        { id: "02", title: "Fevereiro", value: "01" },
        { id: "03", title: "Março", value: "02" },
        { id: "04", title: "Abril", value: "03" },
        { id: "05", title: "Maio", value: "04" },
        { id: "06", title: "Junho", value: "05" },
        { id: "07", title: "Julho", value: "06" },
        { id: "08", title: "Agosto", value: "07" },
        { id: "09", title: "Setembro", value: "08" },
        { id: "10", title: "Outubro", value: "09" },
        { id: "11", title: "Novembro", value: "10" },
        { id: "12", title: "Dezembro", value: "11" },
      ];

      meses.map(m => {
        state.tabFaturamento.push({
          mes: m.title,
          valor: m.value,
        });
      });

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Fevereiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Março") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Abril") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Maio") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Junho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Julho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Agosto") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Setembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Outubro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Novembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Dezembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
      });

      //buscando o faturamento depois de 2023 buscar direto da View

      let area = "";

      const dadosArea = state.area[0].area.split(";");

      dadosArea.map(e => {
        console.log(e);
        area += `'${e.trim()}',`;
      });

      area = area.substring(0, area.length - 1);

      const anoBi = 2023;
      //faturamento area 04
      if (state.dadosCalculo[0].itens[0].ZXD_VEND === "A04   ") {
        dados = {
          FIELDS: `SUM(FATURAMENTO_LIQUIDO) VALOR,ANOMES`,
          WHERE: `LEFT(FILIAL,4)='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'
                            AND PLANO_SAUDE<>'1'
                            AND APAC<>'S'
                            AND LEFT(ANOMES,4) >= '${anoBi}'
                            AND RELATORIO_BI<>'2'
                            AND CHAVE_CANAL='000004'
                            GROUP BY ANOMES `,
          TABELA: "DB_V_RECEITAS_BI",
        };
      } else {
        dados = {
          FIELDS: `SUM(FATURAMENTO_LIQUIDO) VALOR,ANOMES`,
          WHERE: `LEFT(AREA,6) IN (${area})
                            AND LEFT(FILIAL,4)='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'
                            AND PLANO_SAUDE<>'1'
                            AND APAC<>'S'
                            AND LEFT(ANOMES,4) >= '${anoBi}'
                            AND RELATORIO_BI<>'2'
                            AND CHAVE_CANAL='000004'
                            GROUP BY ANOMES `,
          TABELA: "DB_V_RECEITAS_BI",
        };
      }
      const fatReceitaBi = await axios.post(url, dados);

      let anosFatBi = [
        ...new Set(fatReceitaBi.data.map(e => e.ANOMES.substr(0, 4))),
      ];

      if (anosConsulta[anosConsulta.length - 1] === 2023) {
        anosFatBi = ["2023"];
      }

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}01`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}01`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Fevereiro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}02`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}02`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Março") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}03`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}03`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Abril") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}04`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}04`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Maio") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}05`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}05`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Junho") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}06`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}06`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Julho") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}07`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}07`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Agosto") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}08`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}08`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Setembro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}09`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}09`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Outubro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}10`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}10`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Novembro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}11`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}11`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Dezembro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}12`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}12`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
      });

      state.mostraCalculo = true;
      state.dialogAguarde = false;
    },
    async setCalcAssistentes(state) {
      state.tabFaturamentoASS = [];
      state.headersFaturamento = [];
      state.tabFaturamento = [];
      state.assistentes = [];
      state.mostraCalculo = false;
      state.dialogAguarde = true;
      let url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const anos = sub(new Date(`${state.anoEscolhido}-07-01`), { years: 2 });

      let anosConsulta = [];

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anos, { years: cont }).getFullYear() <= new Date().getFullYear()
        ) {
          anosConsulta.push(add(anos, { years: cont }).getFullYear());
        }
      }

      state.headersFaturamento.push({
        text: "Mês",
        value: "mes",
        align: "start",
      });

      anosConsulta.map(e => {
        state.headersFaturamento.push({
          text: `${e}`,
          value: `A-${e}`,
          align: "end",
        });
      });

      let dados = {
        FIELDS: "ZXB_ANOMES,ZXB_VALOR",
        INNER: `INNER JOIN ZXB010 ZXB WITH(NOLOCK) ON 
                                            ZXB.D_E_L_E_T_=' ' 
                                            AND ZXB_IDXA=ZXA_ID 
                                            AND ZXB_TIPO='${state.dadosCalculo[0].itens[0].ZXD_TIPO}'`,
        WHERE: `ZXA_LOJFIL='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'`,
        TABELA: "ZXA",
      };

      const faturamento = await axios.post(url, dados);

      const meses = [
        { id: "01", title: "Janeiro", value: "00" },
        { id: "02", title: "Fevereiro", value: "01" },
        { id: "03", title: "Março", value: "02" },
        { id: "04", title: "Abril", value: "03" },
        { id: "05", title: "Maio", value: "04" },
        { id: "06", title: "Junho", value: "05" },
        { id: "07", title: "Julho", value: "06" },
        { id: "08", title: "Agosto", value: "07" },
        { id: "09", title: "Setembro", value: "08" },
        { id: "10", title: "Outubro", value: "09" },
        { id: "11", title: "Novembro", value: "10" },
        { id: "12", title: "Dezembro", value: "11" },
      ];

      meses.map(m => {
        state.tabFaturamento.push({
          mes: m.title,
          valor: m.value,
        });
      });

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Fevereiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Março") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Abril") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Maio") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Junho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Julho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Agosto") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Setembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Outubro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Novembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Dezembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
      });

      //buscando o faturamento depois de 2023 buscar direto da View
      dados = {
        FIELDS: "ZXF_PESSOA,ZXF_TRIMES,ZXF_PORCFA,ZXF_HRTRAB",
        INNER: `LEFT JOIN ZXF010 ZXF WITH(NOLOCK) ON 
                                                ZXF.D_E_L_E_T_=' '
                                                AND ZXF_IDXD=ZXD_ID
                                                AND ZXF_TIPOPE='A' `,
        WHERE: `ZXD_LOJA='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'`,
        TABELA: "ZXD",
      };

      state.assistentes = await axios.post(url, dados);

      let codAssist = ``;

      state.assistentes.data.map(e => {
        if (e.ZXF_PESSOA) {
          codAssist += `'${e.ZXF_PESSOA}',`;
        }
      });

      if (codAssist.length > 0) {
        codAssist = codAssist.substring(0, codAssist.length - 1);
      } else {
        codAssist = `''`;
      }

      let area = "";

      const dadosArea = state.area[0].area.split(";");

      dadosArea.map(e => {
        console.log(e);
        area += `'${e.trim()}',`;
      });

      area = area.substring(0, area.length - 1);

      const anoBi = 2023;

      //assistente area 04
      if (state.dadosCalculo[0].itens[0].ZXD_VEND === "A04   ") {
        dados = {
          FIELDS: `SUM(FATURAMENTO_LIQUIDO) VALOR,ANOMES`,
          WHERE: `LEFT(AREA,6) IN (${area})
                            AND PLANO_SAUDE<>'1'
                            AND APAC<>'S'
                            AND LEFT(ANOMES,4) >= '${anoBi}'
                            AND RELATORIO_BI<>'2'
                            AND LEFT(VENDEDOR,6) = '${state.dadosCalculo[0].itens[0].ZXD_PROSPE}'
                            GROUP BY ANOMES `,
          TABELA: "DB_V_RECEITAS_BI",
        };
      } else {
        url = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
        dados = {
          query: `SELECT SUM(VALOR) VALOR,ANOMES FROM (
                        SELECT FATURAMENTO_LIQUIDO VALOR,ANOMES
                                            FROM DB_V_RECEITAS_BI  WITH(NOLOCK) WHERE LEFT(AREA,6) IN  (${area})
                                                    AND LEFT(FILIAL,4)='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'
                                                    AND PLANO_SAUDE<>'1'
                                                     AND APAC<>'S'
                                                     AND LEFT(ANOMES,4) >= '${anoBi}'
                                                     AND RELATORIO_BI<>'2'
                                                     AND LEFT(VENDEDOR,6) IN (${codAssist})
                                                   
                     
                     
                     
                                                     UNION ALL
                     
                                                SELECT FATURAMENTO_LIQUIDO VALOR,ANOMES
                                                                     FROM DB_V_RECEITAS_BI  WITH(NOLOCK) 
                                                                                                                     
                                                                     WHERE 
                                                                            
                                                                            LEFT(FILIAL,4)='0301'
                                                                             AND LEFT(AREA,6) IN (${area})
                                                                             AND LINHA='SERVICOS'
                                                                             AND LEFT(ANOMES,4) >= '${anoBi}'
                                                                             AND LEFT(VENDEDOR,6) IN (${codAssist})) aux
                                                                WHERE 1=1 GROUP BY ANOMES`,
        };
        // dados = {
        //     FIELDS: `SUM(FATURAMENTO_LIQUIDO) VALOR,ANOMES`,
        //     WHERE: `LEFT(AREA,6) IN  (${area})
        //             AND LEFT(FILIAL,4)='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'
        //             AND PLANO_SAUDE<>'1'
        //             AND APAC<>'S'
        //             AND LEFT(ANOMES,4) >= '${anoBi}'
        //             AND RELATORIO_BI<>'2'
        //             AND LEFT(VENDEDOR,6) IN (${codAssist})
        //             GROUP BY ANOMES `,
        //     TABELA: 'DB_V_RECEITAS_BI'
        // }
      }

      const fatReceitaBi = await axios.post(url, dados);

      let anosFatBi = [
        ...new Set(fatReceitaBi.data.map(e => e.ANOMES.substr(0, 4))),
      ];

      if (anosConsulta[anosConsulta.length - 1] === 2023) {
        anosFatBi = ["2023"];
      }

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}01`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}01`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Fevereiro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}02`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}02`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Março") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}03`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}03`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Abril") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}04`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}04`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Maio") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}05`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}05`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Junho") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}06`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}06`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Julho") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}07`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}07`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Agosto") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}08`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}08`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Setembro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}09`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}09`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Outubro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}10`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}10`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Novembro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}11`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}11`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
        if (e.mes === "Dezembro") {
          anosFatBi.map(a => {
            if (!e[`A-${a}`][0]) {
              e[`A-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}12`)
                .map(f => stringToBrMoney(f.VALOR));
              e[`C-${a}`] = fatReceitaBi.data
                .filter(f => f.ANOMES === `${a}12`)
                .map(f => f.VALOR);
            }
            e.ano = a;
          });
        }
      });

      state.tabFaturamentoASS = state.tabFaturamento;

      state.mostraCalculo = true;
      state.dialogAguarde = false;
    },
    async setCalcFono(state) {
      state.headersFaturamento = [];
      state.tabFaturamento = [];
      state.fonos = [];
      state.mostraCalculo = false;
      state.dialogAguarde = true;

      // const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`

      const anos = sub(new Date(`${state.anoEscolhido}-07-01`), { years: 2 });

      let anosConsulta = [];

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anos, { years: cont }).getFullYear() <= new Date().getFullYear()
        ) {
          anosConsulta.push(add(anos, { years: cont }).getFullYear());
        }
      }

      state.headersFaturamento.push({
        text: "Mês",
        value: "mes",
        align: "start",
      });

      anosConsulta.map(e => {
        state.headersFaturamento.push({
          text: `${e}`,
          value: `A-${e}`,
          align: "end",
        });
      });

      const meses = [
        { id: "01", title: "Janeiro", value: "00" },
        { id: "02", title: "Fevereiro", value: "01" },
        { id: "03", title: "Março", value: "02" },
        { id: "04", title: "Abril", value: "03" },
        { id: "05", title: "Maio", value: "04" },
        { id: "06", title: "Junho", value: "05" },
        { id: "07", title: "Julho", value: "06" },
        { id: "08", title: "Agosto", value: "07" },
        { id: "09", title: "Setembro", value: "08" },
        { id: "10", title: "Outubro", value: "09" },
        { id: "11", title: "Novembro", value: "10" },
        { id: "12", title: "Dezembro", value: "11" },
      ];

      let faturamento = [];

      meses.map(m => {
        faturamento.push({
          mes: m.title,
          valor: m.value,
        });
      });

      faturamento.map(f => {
        anosConsulta.map(a => {
          let valorUN = 0;
          let valorASS = 0;
          f[`A-${a}`] = state.tabFaturamentoUN
            .filter(e => e.valor === f.valor)
            .map(e => {
              if (e[`A-${a}`][0]) {
                valorUN = e[`C-${a}`][0];
                valorASS = 0;
                state.tabFaturamentoASS
                  .filter(g => g.valor === f.valor)
                  .map(g => {
                    if (g[`A-${a}`][0]) {
                      valorASS = g[`C-${a}`][0];
                    }
                  });
                return stringToBrMoney(valorUN - valorASS);
              }
            });
          f[`C-${a}`] = state.tabFaturamentoUN
            .filter(e => e.valor === f.valor)
            .map(e => {
              if (e[`A-${a}`][0]) {
                valorUN = e[`C-${a}`][0];
                valorASS = 0;
                state.tabFaturamentoASS
                  .filter(g => g.valor === f.valor)
                  .map(g => {
                    if (g[`A-${a}`][0]) {
                      valorASS = g[`C-${a}`][0];
                    }
                  });
                return Number((valorUN - valorASS).toFixed(2));
              }
            });
        });
      });

      state.tabFaturamento = faturamento;

      state.mostraCalculo = true;
      state.dialogAguarde = false;
    },
    async setCalcCirurgico(state) {
      state.tabFaturamentoUN = [];
      state.headersFaturamento = [];
      state.tabFaturamento = [];
      state.mostraCalculo = false;
      state.dialogAguarde = true;
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const anos = sub(new Date(`${state.anoEscolhido}-07-01`), { years: 2 });

      let anosConsulta = [];

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anos, { years: cont }).getFullYear() <= new Date().getFullYear()
        ) {
          anosConsulta.push(add(anos, { years: cont }).getFullYear());
        }
      }

      state.headersFaturamento.push({
        text: "Mês",
        value: "mes",
        align: "start",
      });

      anosConsulta.map(e => {
        state.headersFaturamento.push({
          text: `${e}`,
          value: `A-${e}`,
          align: "end",
        });
      });

      let dados = {
        FIELDS: "ZXB_ANOMES,ZXB_VALOR",
        INNER: `INNER JOIN ZXB010 ZXB WITH(NOLOCK) ON 
                                            ZXB.D_E_L_E_T_=' ' 
                                            AND ZXB_IDXA=ZXA_ID 
                                            AND ZXB_TIPO='${state.dadosCalculo[0].itens[0].ZXD_TIPO}'`,
        WHERE: `ZXA_LOJFIL='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'`,
        TABELA: "ZXA",
      };

      const faturamento = await axios.post(url, dados);

      const meses = [
        { id: "01", title: "Janeiro", value: "00" },
        { id: "02", title: "Fevereiro", value: "01" },
        { id: "03", title: "Março", value: "02" },
        { id: "04", title: "Abril", value: "03" },
        { id: "05", title: "Maio", value: "04" },
        { id: "06", title: "Junho", value: "05" },
        { id: "07", title: "Julho", value: "06" },
        { id: "08", title: "Agosto", value: "07" },
        { id: "09", title: "Setembro", value: "08" },
        { id: "10", title: "Outubro", value: "09" },
        { id: "11", title: "Novembro", value: "10" },
        { id: "12", title: "Dezembro", value: "11" },
      ];

      meses.map(m => {
        state.tabFaturamento.push({
          mes: m.title,
          valor: m.value,
        });
      });

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Fevereiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Março") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Abril") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Maio") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Junho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Julho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Agosto") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Setembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Outubro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Novembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Dezembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
      });

      //buscando o faturamento depois de 2023 buscar direto da View

      const anoBi = 2023;

      let subgrupo =
        state.dadosCalculo[0].itens[0].ZXD_VEND !== "002980" ? "0007" : "0023";
      dados = {
        FIELDS: `SUM(FATURAMENTO_LIQUIDO) VALOR,ANOMES`,
        WHERE: `LINHA='CIRURGICA'
                        AND LEFT(ANOMES,4) >= '${anoBi}'
                        AND LEFT(VENDEDOR,6)='${state.dadosCalculo[0].itens[0].ZXD_VEND}'
                        AND LEFT(DESC_SUBGRUPO_PRODUTO,4)='${subgrupo}'
                        GROUP BY ANOMES `,
        TABELA: "DB_V_RECEITAS_BI",
      };
      const fatReceitaBi = await axios.post(url, dados);

      let anosFatBi = [
        ...new Set(fatReceitaBi.data.map(e => e.ANOMES.substr(0, 4))),
      ];

      if (anosConsulta[anosConsulta.length - 1] === 2023) {
        anosFatBi = ["2023"];
      }

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}01`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}01`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}01`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Fevereiro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}02`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}02`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}02`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Março") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}03`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}03`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}03`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Abril") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}04`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}04`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}04`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Maio") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}05`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}05`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}05`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Junho") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}06`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}06`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}06`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Julho") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}07`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}07`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}07`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Agosto") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}08`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}08`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}08`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Setembro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}09`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}09`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}09`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Outubro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}10`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}10`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}10`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Novembro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}11`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}11`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}11`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Dezembro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}12`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}12`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}12`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
      });

      state.tabFaturamentoUN = state.tabFaturamento;

      state.mostraCalculo = true;
      state.dialogAguarde = false;
    },
    async setCalcCrm(state) {
      state.tabFaturamentoUN = [];
      state.headersFaturamento = [];
      state.tabFaturamento = [];
      state.mostraCalculo = false;
      state.dialogAguarde = true;
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const anos = sub(new Date(`${state.anoEscolhido}-07-01`), { years: 2 });

      let anosConsulta = [];

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anos, { years: cont }).getFullYear() <= new Date().getFullYear()
        ) {
          anosConsulta.push(add(anos, { years: cont }).getFullYear());
        }
      }

      state.headersFaturamento.push({
        text: "Mês",
        value: "mes",
        align: "start",
      });

      anosConsulta.map(e => {
        state.headersFaturamento.push({
          text: `${e}`,
          value: `A-${e}`,
          align: "end",
        });
      });

      let tipoAgenda = `'A','C'`;
      if (
        state.dadosCalculo[0]?.itens[0]?.ZXD_NOME.trim() === "Comparecimento"
      ) {
        tipoAgenda = `'C'`;
      }

      let dados = {
        FIELDS: `COUNT(*) TOTAL,YEAR(ZZL_DTINI) ANO,MONTH(ZZL_DTINI) MES `,
        WHERE: `ZZL_CANAL='000002'
                AND UPPER(ZZL_TITULO) LIKE '%PACIENTES PRIMEIRA%'
                AND ZZL_STATUS IN (${tipoAgenda})
                GROUP BY YEAR(ZZL_DTINI),MONTH(ZZL_DTINI)
                ORDER BY YEAR(ZZL_DTINI)+month(ZZL_DTINI) `,
        TABELA: "ZZL",
      };

      const dadosAgendas = await axios.post(url, dados);

      const meses = [
        { id: "01", title: "Janeiro", value: "1" },
        { id: "02", title: "Fevereiro", value: "2" },
        { id: "03", title: "Março", value: "3" },
        { id: "04", title: "Abril", value: "4" },
        { id: "05", title: "Maio", value: "5" },
        { id: "06", title: "Junho", value: "6" },
        { id: "07", title: "Julho", value: "7" },
        { id: "08", title: "Agosto", value: "8" },
        { id: "09", title: "Setembro", value: "9" },
        { id: "10", title: "Outubro", value: "10" },
        { id: "11", title: "Novembro", value: "11" },
        { id: "12", title: "Dezembro", value: "12" },
      ];

      meses.map(m => {
        state.tabFaturamento.push({
          mes: m.title,
          valor: m.value,
        });
      });

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 1,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }

        if (e.mes === "Fevereiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 2,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }

        if (e.mes === "Março") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 3,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }

        if (e.mes === "Abril") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 4,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }

        if (e.mes === "Maio") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 5,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }

        if (e.mes === "Junho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 6,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }

        if (e.mes === "Julho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 7,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }

        if (e.mes === "Agosto") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 8,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }

        if (e.mes === "Setembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 9,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }

        if (e.mes === "Outubro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 10,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }

        if (e.mes === "Novembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 11,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }

        if (e.mes === "Dezembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = dadosAgendas.data.filter(
              f => f.ANO === a && f.MES === 12,
            )[0]?.TOTAL;

            e.ano = a;
          });
        }
      });

      state.tabFaturamentoUN = state.tabFaturamento;

      state.mostraCalculo = true;
      state.dialogAguarde = false;
    },
    async setCalcAt(state) {
      state.tabFaturamentoUN = [];
      state.headersFaturamento = [];
      state.tabFaturamento = [];
      state.mostraCalculo = false;
      state.dialogAguarde = true;
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const anos = sub(new Date(`${state.anoEscolhido}-07-01`), { years: 2 });

      let anosConsulta = [];

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anos, { years: cont }).getFullYear() <= new Date().getFullYear()
        ) {
          anosConsulta.push(add(anos, { years: cont }).getFullYear());
        }
      }

      state.headersFaturamento.push({
        text: "Mês",
        value: "mes",
        align: "start",
      });

      anosConsulta.map(e => {
        state.headersFaturamento.push({
          text: `${e}`,
          value: `A-${e}`,
          align: "end",
        });
      });

      let dados = {
        FIELDS: "ZXB_ANOMES,ZXB_VALOR",
        INNER: `INNER JOIN ZXB010 ZXB WITH(NOLOCK) ON 
                                            ZXB.D_E_L_E_T_=' ' 
                                            AND ZXB_IDXA=ZXA_ID 
                                            AND ZXB_TIPO='${state.dadosCalculo[0].itens[0].ZXD_TIPO}'`,
        WHERE: `ZXA_LOJFIL='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'`,
        TABELA: "ZXA",
      };

      const faturamento = await axios.post(url, dados);

      const meses = [
        { id: "01", title: "Janeiro", value: "00" },
        { id: "02", title: "Fevereiro", value: "01" },
        { id: "03", title: "Março", value: "02" },
        { id: "04", title: "Abril", value: "03" },
        { id: "05", title: "Maio", value: "04" },
        { id: "06", title: "Junho", value: "05" },
        { id: "07", title: "Julho", value: "06" },
        { id: "08", title: "Agosto", value: "07" },
        { id: "09", title: "Setembro", value: "08" },
        { id: "10", title: "Outubro", value: "09" },
        { id: "11", title: "Novembro", value: "10" },
        { id: "12", title: "Dezembro", value: "11" },
      ];

      meses.map(m => {
        state.tabFaturamento.push({
          mes: m.title,
          valor: m.value,
        });
      });

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Fevereiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Março") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Abril") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Maio") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Junho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Julho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Agosto") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Setembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Outubro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Novembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Dezembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
      });

      //buscando o faturamento depois de 2023 buscar direto da View
      //SERVIÇOS+PEÇAS
      const anoBi = 2023;
      console.log(state.dadosCalculo[0].itens[0]);
      if (state.dadosCalculo[0].itens[0].ZXD_LOJA === "SVP ") {
        dados = {
          FIELDS: `SUM(FATURAMENTO_LIQUIDO) VALOR,ANOMES`,
          WHERE: `GRUPO_PRODUTO IN ('2001','1004')
                            AND LEFT(ANOMES,4) >= '${anoBi}'
                            AND LEFT(DESC_SUBGRUPO_PRODUTO,4) IN ('0004','0005')
                            AND LINHA IN ('AUDITIVA','SERVICOS')
                            GROUP BY ANOMES `,
          TABELA: "DB_V_RECEITAS_BI",
        };
      } else {
        dados = {
          FIELDS: `SUM(FATURAMENTO_LIQUIDO) VALOR,ANOMES`,
          WHERE: `GRUPO_PRODUTO='2001'
                            AND LEFT(ANOMES,4) >= '${anoBi}'
                            AND LEFT(FILIAL,4)='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'
                            AND LEFT(DESC_SUBGRUPO_PRODUTO,4)='${state.dadosCalculo[0].itens[0].ZXD_VEND}'
                            AND LEFT(ARMAZEM,2)='AT' 
                            GROUP BY ANOMES `,
          TABELA: "DB_V_RECEITAS_BI",
        };
      }
      const fatReceitaBi = await axios.post(url, dados);

      let anosFatBi = [
        ...new Set(fatReceitaBi.data.map(e => e.ANOMES.substr(0, 4))),
      ];

      if (anosConsulta[anosConsulta.length - 1] === 2023) {
        anosFatBi = ["2023"];
      }

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}01`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}01`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}01`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Fevereiro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}02`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}02`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}02`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Março") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}03`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}03`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}03`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Abril") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}04`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}04`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}04`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Maio") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}05`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}05`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}05`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Junho") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}06`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}06`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}06`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Julho") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}07`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}07`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}07`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Agosto") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}08`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}08`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}08`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Setembro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}09`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}09`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}09`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Outubro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}10`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}10`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}10`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Novembro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}11`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}11`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}11`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Dezembro") {
          anosFatBi.map(a => {
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}12`,
            )[0];
            if (!existeData) {
              e[`A-${a}`] = [];
              e[`C-${a}`] = [];
            } else {
              e[`A-${a}`] = [
                stringToBrMoney(
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}12`)
                    .map(f => f.VALOR)[0],
                ),
              ];
              e[`C-${a}`] = [
                fatReceitaBi.data
                  .filter(f => f.ANOMES === `${a}12`)
                  .map(f => f.VALOR)[0],
              ];
            }
            e.ano = Number(a);
          });
        }
      });

      state.tabFaturamentoUN = state.tabFaturamento;

      state.mostraCalculo = true;
      state.dialogAguarde = false;
    },
    async setCalcPP(state) {
      state.tabFaturamentoASS = [];
      state.headersFaturamento = [];
      state.tabFaturamento = [];
      state.assistentes = [];
      state.mostraCalculo = false;
      state.dialogAguarde = true;
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const anos = sub(new Date(`${state.anoEscolhido}-07-01`), { years: 2 });

      let anosConsulta = [];

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anos, { years: cont }).getFullYear() <= new Date().getFullYear()
        ) {
          anosConsulta.push(add(anos, { years: cont }).getFullYear());
        }
      }

      state.headersFaturamento.push({
        text: "Mês",
        value: "mes",
        align: "start",
      });

      anosConsulta.map(e => {
        state.headersFaturamento.push({
          text: `${e}`,
          value: `A-${e}`,
          align: "end",
        });
      });

      let dados = {
        FIELDS: "ZXB_ANOMES,ZXB_VALOR",
        INNER: `INNER JOIN ZXB010 ZXB WITH(NOLOCK) ON 
                                            ZXB.D_E_L_E_T_=' ' 
                                            AND ZXB_IDXA=ZXA_ID 
                                            AND ZXB_TIPO='${state.dadosCalculo[0].itens[0].ZXD_TIPO}'`,
        WHERE: `ZXA_LOJFIL='${state.dadosCalculo[0].itens[0].ZXD_VEND}'`,
        TABELA: "ZXA",
      };

      const faturamento = await axios.post(url, dados);

      const meses = [
        { id: "01", title: "Janeiro", value: "00" },
        { id: "02", title: "Fevereiro", value: "01" },
        { id: "03", title: "Março", value: "02" },
        { id: "04", title: "Abril", value: "03" },
        { id: "05", title: "Maio", value: "04" },
        { id: "06", title: "Junho", value: "05" },
        { id: "07", title: "Julho", value: "06" },
        { id: "08", title: "Agosto", value: "07" },
        { id: "09", title: "Setembro", value: "08" },
        { id: "10", title: "Outubro", value: "09" },
        { id: "11", title: "Novembro", value: "10" },
        { id: "12", title: "Dezembro", value: "11" },
      ];

      meses.map(m => {
        state.tabFaturamento.push({
          mes: m.title,
          valor: m.value,
        });
      });

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Fevereiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Março") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Abril") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Maio") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Junho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Julho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Agosto") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Setembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Outubro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Novembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Dezembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
      });

      //buscando o faturamento depois de 2023 buscar direto da View
      dados = {
        FIELDS: "ZXF_PESSOA,ZXF_TRIMES,ZXF_PORCFA,ZXF_HRTRAB",
        INNER: `LEFT JOIN ZXF010 ZXF WITH(NOLOCK) ON 
                                                ZXF.D_E_L_E_T_=' '
                                                AND ZXF_IDXD=ZXD_ID
                                                AND ZXF_TIPOPE='P' `,
        WHERE: `ZXD_VEND='${state.dadosCalculo[0].itens[0].ZXD_VEND}'`,
        TABELA: "ZXD",
      };

      state.assistentes = await axios.post(url, dados);

      let codAssist = ``;

      state.assistentes.data.map(e => {
        if (e.ZXF_PESSOA) {
          codAssist += `'${e.ZXF_PESSOA}',`;
        }
      });

      if (codAssist.length > 0) {
        codAssist = codAssist.substring(0, codAssist.length - 1);
      } else {
        codAssist = `''`;
      }

      if (state.dadosCalculo[0].itens[0].ZXD_VEND.substr(0, 2) === "PF") {
        let area = "";

        const dadosArea = state.area[0].area.split(";");

        dadosArea.map(e => {
          console.log(e);
          area += `'${e.trim()}',`;
        });

        area = area.substring(0, area.length - 1);

        const anoBi = 2023;

        dados = {
          FIELDS: `SUM(FATURAMENTO_LIQUIDO) VALOR,ANOMES`,
          WHERE: `LEFT(ANOMES,4) >= '${anoBi}'
                            AND LINHA='AUDITIVA'
                            AND LEFT(AREA,6) IN (${area})
                            AND CHAVE_CANAL='000001'
                            AND LEFT(PROSPECTOR,6)= '${state.dadosCalculo[0].itens[0].ZXD_PROSPE}'
                            GROUP BY ANOMES `,
          TABELA: "DB_V_RECEITAS_BI",
        };
      } else {
        if (state.dadosCalculo[0].itens[0].ZXD_LOJA.trim() !== "") {
          dados = {
            FIELDS: `SUM(FATURAMENTO_LIQUIDO) VALOR,ANOMES`,
            WHERE: `LEFT(ANOMES,4) >= '2023'
                                AND LINHA='AUDITIVA'
                                AND CHAVE_CANAL='000001'
                                AND LEFT(PROSPECTOR,6)= '${state.dadosCalculo[0].itens[0].ZXD_PROSPE}'
                                AND LEFT(FILIAL,4)='${state.dadosCalculo[0].itens[0].ZXD_LOJA}'
                                AND GRUPO_PRODUTO='1001'
                                AND LEFT(VENDEDOR,6) IN (${codAssist})
                                GROUP BY ANOMES `,
            TABELA: "DB_V_RECEITAS_BI",
          };
        } else {
          dados = {
            FIELDS: `SUM(FATURAMENTO_LIQUIDO) VALOR,ANOMES`,
            WHERE: `LEFT(ANOMES,4) >= '2023'
                                AND LINHA='AUDITIVA'
                                AND CHAVE_CANAL='000001'
                                AND LEFT(PROSPECTOR,6)= '${state.dadosCalculo[0].itens[0].ZXD_PROSPE}'
                                AND GRUPO_PRODUTO='1001'
                                AND LEFT(VENDEDOR,6) IN (${codAssist})
                                GROUP BY ANOMES `,
            TABELA: "DB_V_RECEITAS_BI",
          };
        }
      }

      const fatReceitaBi = await axios.post(url, dados);

      let anosFatBi = [
        ...new Set(fatReceitaBi.data.map(e => e.ANOMES.substr(0, 4))),
      ];

      if (anosConsulta[anosConsulta.length - 1] === 2023) {
        anosFatBi = ["2023"];
      }

      state.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}01`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}01`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Fevereiro") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}02`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}02`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Março") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}03`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}03`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Abril") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}04`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}04`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Maio") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}05`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}05`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Junho") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}06`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}06`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Julho") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}07`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}07`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Agosto") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}08`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}08`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Setembro") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}09`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}09`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Outubro") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}10`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}10`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Novembro") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}11`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}11`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Dezembro") {
          anosFatBi.map(a => {
            e[`A-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}12`)
              .map(f => stringToBrMoney(f.VALOR));
            e[`C-${a}`] = fatReceitaBi.data
              .filter(f => f.ANOMES === `${a}12`)
              .map(f => f.VALOR);
            e.ano = a;
          });
        }
      });

      state.tabFaturamentoASS = state.tabFaturamento;

      state.mostraCalculo = true;
      state.dialogAguarde = false;
    },
    setLimpaCalc(state) {
      state.headersFaturamento = [];
      state.tabFaturamento = [];
      state.fonos = [];
      state.tabFaturamentoUN = [];
      state.tabFaturamentoASS = [];
      state.dadosCalculo = [];
      state.trimestre = [];
      state.mostraCalculo = false;
    },
  },
  actions: {},
};
