<template>
  <div class="pa-4 d-flex">
    <v-card>
      <v-navigation-drawer
        v-if="stDrawer"
        v-model="stDrawer"
        :mini-variant.sync="stMini"
        permanent
      >
        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            @click="paineis(item.function)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>

    <OrdemServico1 v-if="telaOS" />
    <BaseInstalada v-if="telaBaseInst" />
    <!-- <PdfAbertura /> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "OS",
  components: {
    OrdemServico1: () => import("@/components/OrdemServico/OrdemServico1"),
    BaseInstalada: () => import("@/components/BaseInstalada/BaseInstalada"),
    // PdfAbertura: () => import("@/components/OrdemServico/PdfAbertura"),
  },
  data: () => {
    return {
      items: [
        {
          title: "Ordem de Serviço",
          icon: "mdi-hammer-wrench",
          function: "ordemServico",
        },
        {
          title: "Base Instalada",
          icon: "mdi-speaker",
          function: "baseInstalada",
        },
      ],
      drawer: false,
      telaOS: true,
      telaBaseInst: false,
    };
  },
  computed: {
    ...mapState(["user", "metadados", "stDrawer", "stMini"]),
    DataFormatadaInicial() {
      return moment(this.DtInicial).format("DD/MM/YYYY");
    },
  },
  methods: {
    abrePainel() {
      this.drawer = !this.drawer;
      this.$store.commit("SetStDrawer", this.drawer);
      this.$store.commit("SetStMini", false);
    },
    paineis(chamada) {
      if (chamada === "baseInstalada") this.base();
      if (chamada === "ordemServico") this.ordem();
    },
    ordem() {
      this.telaOS = true;
      this.telaBaseInst = false;
    },
    base() {
      this.telaOS = false;
      this.telaBaseInst = true;
    },
  },
  // mounted() {
  //   this.montaBrowser();
  // },
};
</script>

<style>
.v-label {
  font-size: 0.7rem;
}
input {
  font-size: 0.8rem;
}
.text-center,
.text-left {
  font-size: 0.7rem !important;
}
tbody tr:nth-of-type(odd) {
  background-color: #d8f6fa;
}
.v-select__selection {
  font-size: 0.8rem !important;
}
.v-money:focus {
  outline: 0;
}
</style>
