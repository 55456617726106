<template>
  <div class="pa-4">
    <div class="tableCli">
      <!-- browse com todos os cadastros na SA3 que tenham como tipo prospector e ECTC -->
      <v-data-table
        v-if="!mostraDados"
        :headers="headersAssessor"
        dense
        :items="itemsAssessor"
        class="elevation-1"
        no-data-text="Sem dados..."
        fixed-header
        height="70vh"
        :loading="loading"
        :search="search"
        :items-per-page="itemsAssessor.length"
        hide-default-footer
      >
        <template v-slot:top v-if="!user.isCliente">
          <v-row dense class="pa-4">
            <h3>Assessores do prospector {{ user.cod_vendedor }}</h3>
          </v-row>
          <v-toolbar flat color="white">
            <v-row dense>
              <v-col cols="3" v-if="NrRegFiltro !== null">
                <span style="font-size: 0.7rem"
                  >Filtro aplicado no <strong>{{ filtroEscolhido }}</strong
                  >, {{ NrRegFiltro }} registro(s) encontrado(s).</span
                >
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  fab
                  color="error"
                  @click="LimpaFiltro()"
                >
                  <v-icon dark class="ml-1">mdi-eraser</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-select
                  v-model="filtroEscolhido"
                  :items="itemsFiltro"
                  label="Escolha o campo"
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="digite aqui..."
                  v-model="stringFiltro"
                  :disabled="!filtroEscolhido"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  color="indigo"
                  @click="filtraRegistro(filtroEscolhido, stringFiltro)"
                >
                  <v-icon dark class="ml-1">mdi-account-search</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="verDados(item)" v-bind="attrs" v-on="on"
                >mdi-eye</v-icon
              >
            </template>
            <span>Visualizar visitas</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <span>Olá {{ this.user.name }}, seja bem vindo</span>
      <!-- template de visitas do prospector -->
      <div style="height: 60vh;overflow-y: auto">
        <div class="d-flex flex-column">
          <span
            class="ml-6 blue--text text--darken-3"
            style="width: 93vw;text-align: center;"
            >Primeira Semana</span
          >
          <kanban-board
            :stages="semana1"
            :blocks="visitasSemana1"
            @update-block="blockUpdated"
            @click="blockUpdated"
            v-if="mostraDados"
            style="width: 93vw;"
          >
            <div
              v-for="dia in semana1"
              :slot="dia"
              :key="dia"
              class="d-flex justify-space-between"
            >
              <h2>{{ dia }}</h2>

              <v-btn
                icon
                color="blue darken-3"
                style="margin-top: -8px"
                class="ml-3"
                @click="mostra(dia, 1)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>

            <div
              v-for="visita in visitasSemana1"
              :slot="visita.id"
              :key="visita.id"
            >
              <!-- <div>
            <strong>ID: {{ visita.id }}</strong>
          </div> -->

              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column">
                  <v-badge
                    v-if="visita.new"
                    color="green darken-2"
                    content="nova"
                  >
                  </v-badge>
                  <span class="blue--text text--darken-3"
                    ><v-icon color="blue darken-2" class="mr-3"
                      >mdi-face-mask</v-icon
                    ><strong>{{ visita.title }}</strong></span
                  >
                  <span class="red--text text--darken-3"
                    ><v-icon color="red darken-2" class="mr-3"
                      >mdi-calendar</v-icon
                    >{{ visita.detail }}</span
                  >
                </div>
                <div class="d-flex flex-column">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="selecionaTemplate(visita)"
                      >
                        <v-icon dark>
                          mdi-calendar-multiple-check
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Selecionar item</span>
                  </v-tooltip>
                </div>
              </div>

              <!-- <div>
            <div v-if="visita.new">
              <v-badge color="green darken-2" content="nova">
                {{ `${visita.title}=>${visita.detail}` }}
              </v-badge>
            </div>
            <div v-else>
              {{ `${visita.title}=>${visita.detail}` }}
            </div>
           
            <v-btn
              elevation="2"
              color="indigo"
              dark
              class="btn-efetiva-template"
              @click="selecionaTemplate(visita)"
            >
              <v-icon dark class="ml-1">mdi-calendar-multiple-check</v-icon>
            </v-btn>
          </div> -->
            </div>
          </kanban-board>
        </div>

        <!-- template de visitas do prospector -->
        <div class="d-flex flex-column">
          <span
            class="ml-6 blue--text text--darken-3"
            style="width: 93vw;text-align: center;"
            >Segunda Semana</span
          >
          <kanban-board
            :stages="semana2"
            :blocks="visitasSemana2"
            @update-block="blockUpdated"
            @click="blockUpdated"
            v-if="mostraDados"
            style="width: 93vw;"
          >
            <div
              v-for="dia in semana2"
              :slot="dia"
              :key="dia"
              class="d-flex justify-space-between"
            >
              <h2>{{ dia }}</h2>

              <v-btn
                icon
                color="blue darken-3"
                style="margin-top: -8px"
                class="ml-3"
                @click="mostra(dia, 2)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>

            <div
              v-for="visita in visitasSemana2"
              :slot="visita.id"
              :key="visita.id"
            >
              <!-- <div>
            <strong>ID: {{ visita.id }}</strong>
          </div> -->
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column">
                  <v-badge
                    v-if="visita.new"
                    color="green darken-2"
                    content="nova"
                  >
                  </v-badge>
                  <span class="blue--text text--darken-3"
                    ><v-icon color="blue darken-2" class="mr-3"
                      >mdi-face-mask</v-icon
                    ><strong>{{ visita.title }}</strong></span
                  >
                  <span class="red--text text--darken-3"
                    ><v-icon color="red darken-2" class="mr-3"
                      >mdi-calendar</v-icon
                    >{{ visita.detail }}</span
                  >
                </div>
                <div class="d-flex flex-column">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="selecionaTemplate(visita)"
                      >
                        <v-icon dark>
                          mdi-calendar-multiple-check
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Selecionar item</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </kanban-board>
        </div>

        <!-- template de visitas do prospector -->
        <div class="d-flex flex-column">
          <span
            class="ml-6 blue--text text--darken-3"
            style="width: 93vw;text-align: center;"
            >Terceira Semana</span
          >
          <kanban-board
            :stages="semana3"
            :blocks="visitasSemana3"
            @update-block="blockUpdated"
            @click="blockUpdated"
            v-if="mostraDados"
            style="width: 93vw;"
          >
            <div
              v-for="dia in semana3"
              :slot="dia"
              :key="dia"
              class="d-flex justify-space-between"
            >
              <h2>{{ dia }}</h2>

              <v-btn
                icon
                color="blue darken-3"
                style="margin-top: -8px"
                class="ml-3"
                @click="mostra(dia, 3)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>

            <div
              v-for="visita in visitasSemana3"
              :slot="visita.id"
              :key="visita.id"
            >
              <!-- <div>
            <strong>ID: {{ visita.id }}</strong>
          </div> -->

              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column">
                  <v-badge
                    v-if="visita.new"
                    color="green darken-2"
                    content="nova"
                  >
                  </v-badge>
                  <span class="blue--text text--darken-3"
                    ><v-icon color="blue darken-2" class="mr-3"
                      >mdi-face-mask</v-icon
                    ><strong>{{ visita.title }}</strong></span
                  >
                  <span class="red--text text--darken-3"
                    ><v-icon color="red darken-2" class="mr-3"
                      >mdi-calendar</v-icon
                    >{{ visita.detail }}</span
                  >
                </div>
                <div class="d-flex flex-column">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="selecionaTemplate(visita)"
                      >
                        <v-icon dark>
                          mdi-calendar-multiple-check
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Selecionar item</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </kanban-board>
        </div>
        <!-- template de visitas do prospector -->
        <div class="d-flex flex-column">
          <span
            class="ml-6 blue--text text--darken-3"
            style="width: 93vw;text-align: center;"
            >Quarta Semana</span
          >
          <kanban-board
            :stages="semana4"
            :blocks="visitasSemana4"
            @update-block="blockUpdated"
            @click="blockUpdated"
            v-if="mostraDados"
            style="width: 93vw;"
          >
            <div
              v-for="dia in semana4"
              :slot="dia"
              :key="dia"
              class="d-flex justify-space-between"
            >
              <h2>{{ dia }}</h2>

              <v-btn
                icon
                color="blue darken-3"
                style="margin-top: -8px"
                class="ml-3"
                @click="mostra(dia, 4)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>

            <div
              v-for="visita in visitasSemana4"
              :slot="visita.id"
              :key="visita.id"
            >
              <!-- <div>
            <strong>ID: {{ visita.id }}</strong>
          </div> -->

              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column">
                  <v-badge
                    v-if="visita.new"
                    color="green darken-2"
                    content="nova"
                  >
                  </v-badge>
                  <span class="blue--text text--darken-3"
                    ><v-icon color="blue darken-2" class="mr-3"
                      >mdi-face-mask</v-icon
                    ><strong>{{ visita.title }}</strong></span
                  >
                  <span class="red--text text--darken-3"
                    ><v-icon color="red darken-2" class="mr-3"
                      >mdi-calendar</v-icon
                    >{{ visita.detail }}</span
                  >
                </div>
                <div class="d-flex flex-column">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="selecionaTemplate(visita)"
                      >
                        <v-icon dark>
                          mdi-calendar-multiple-check
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Selecionar item</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </kanban-board>
        </div>
      </div>
      <v-spacer></v-spacer>

      <!-- <v-btn
        elevation="2"
        color="indigo"
        dark
        class="mt-3 ml-2 mb-2"
        @click="novaVisitaTemplate()"
        small
        v-if="mostraDados"
      >
        Nova visita</v-btn
      > -->

      <v-spacer></v-spacer>

      <v-btn
        elevation="2"
        color="indigo"
        dark
        class="mt-3 ml-2 mb-2"
        @click="dialogConfirmaPreAgenda = true"
        v-if="mostraDados"
        x-small
      >
        Gerar pré-agenda</v-btn
      >

      <v-spacer></v-spacer>

      <v-btn
        elevation="2"
        color="red"
        dark
        class="mt-3 ml-2 mb-2"
        @click="excluiTemplate(templateSelecionado)"
        v-if="templateSelecionado"
        small
      >
        Excluir visita</v-btn
      >

      <v-row class="fill-height" v-if="mostraDados">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              :weekdays="weekdays"
              locale="pt-BR"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="buscaPreAgenda"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <!-- <v-btn icon>
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn> -->
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- <v-btn icon>
                                        <v-icon>mdi-heart</v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn> -->
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <!-- <v-card-actions>
                                    <v-btn
                                        text
                                        color="secondary"
                                        @click="selectedOpen = false"
                                    >
                                        Cancel
                                    </v-btn>
                                </v-card-actions> -->
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>

      <v-btn
        elevation="2"
        color="indigo"
        dark
        class="mt-3 ml-2 mb-2"
        @click="dialogConfirmaAgenda = true"
        v-if="mostraDados"
        >Efetivar agenda</v-btn
      >

      <v-snackbar v-model="showSnackbar" :multi-line="multiLine">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="showSnackbar = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>

      <!-- Dialog de load enquanto busca o template do usuário logado -->
      <v-dialog v-model="dialogAwaitLoadTemplate" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitLoadTemplate" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Visitas</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Buscando template de visitas...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <!-- Dialog de load enquanto busca o template do usuário logado -->

      <!-- Dialog exibida enquanto está salvando uma nova visita no template -->
      <v-dialog v-model="dialogAwaitSaveVisita" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitSaveVisita" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Visita</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Salvando a visita no template...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <!-- Dialog exibida enquanto está salvando uma nova visita no template -->

      <!-- Dialog exibida após salvar a inclusão/alteração de uma visita no template -->
      <v-dialog v-model="dialogSuccessSaveVisita" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">Visita</v-card-title>
          <v-card-text class="mt-5 title">{{ mensagem }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="limpaVariaveis">fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog exibida após salvar a inclusão/alteração de uma visita no template -->

      <!-- Dialog de inclusão de um novo item no template -->
      <v-dialog v-model="DialogDados" persistent width="50%">
        <v-card v-if="boilerplate" ref="form">
          <v-card-title class="headline grey lighten-2">{{
            titleDialog
          }}</v-card-title>

          <v-card-text>
            <v-row dense class="mt-5">
              <v-col
                v-for="campo in camposTela"
                :key="campo.ZZP_CAMPO"
                :cols="campo.ZZP_COLS"
              >
                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :value="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :label="campo.ZZP_TITULO"
                  v-if="campo.ZZP_TIPO === 'T' && campo.ZZP_GRAVA !== 'N'"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  class="text-uppercase"
                  dense
                  :readonly="isReading || campo.ZZP_VISUAL == 2"
                ></v-text-field>
                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :value="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :label="campo.ZZP_TITULO"
                  v-if="campo.ZZP_TIPO === 'H'"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  v-mask="'##:##'"
                  class="text-uppercase"
                  dense
                  :readonly="isReading || campo.ZZP_VISUAL == 2"
                ></v-text-field>
                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :value="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :label="campo.ZZP_TITULO"
                  v-if="campo.ZZP_TIPO === 'D'"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  v-mask="'##/##/####'"
                  class="text-uppercase"
                  dense
                  :readonly="isReading || campo.ZZP_VISUAL == 2"
                ></v-text-field>
                <v-select
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :items="campo.ITEMS"
                  :label="campo.ZZP_TITULO"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  v-if="campo.ZZP_TIPO === 'S'"
                  class="text-uppercase"
                  dense
                  :readonly="isReading || campo.ZZP_VISUAL == 2"
                ></v-select>
                <v-autocomplete
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                  style="margin-top: -22px"
                  dense
                  :items="itemsTipo"
                  @focus="carregaItems(campo)"
                  @click="carregaItems(campo)"
                  :no-data-text="loadingText"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  filled
                  :label="campo.ZZP_TITULO"
                  background-color="white"
                  v-if="campo.ZZP_TIPO === 'A' || campo.ZZP_TIPO === 'F'"
                  class="text-uppercase"
                  :readonly="isReading || campo.ZZP_VISUAL == 2"
                ></v-autocomplete>
                <v-textarea
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  background-color="white"
                  clearable
                  counter
                  :items="campo.ITEMS"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  :label="campo.ZZP_TITULO"
                  :readonly="isReading || campo.ZZP_VISUAL == 2"
                  v-if="campo.ZZP_TIPO === 'Y'"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-btn
                elevation="2"
                color="red"
                dark
                class="mt-3 ml-2"
                x-small
                @click="DialogDados = false"
                >Fechar</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                elevation="2"
                color="indigo"
                dark
                class="mt-3 ml-2"
                x-small
                @click="salva()"
                >Salvar</v-btn
              >
            </v-row>
          </v-card-text>
        </v-card>
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="boilerplate"
          type="table"
          class="mx-auto"
          v-if="!boilerplate"
        ></v-skeleton-loader>
      </v-dialog>
      <!-- Dialog de inclusão de um novo item no template -->

      <!-- Dialog exibida enquanto está incluindo manualmente um item na pré-agenda -->
      <v-dialog v-model="dialogAwaitPreAgenda" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitPreAgenda" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Visita</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Efetivando visita na pré-agenda...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <!-- Dialog exibida enquanto está incluindo manualmente um item na pré-agenda -->

      <!-- Dialog exibida enquanto está buscando os itens da pré-agenda -->
      <v-dialog v-model="dialogAwaitBuscaPreAgendas" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitBuscaPreAgendas" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Visita</v-card-title>
          <v-card-subtitle>
            <p class="body-1">Buscando itens da pré-agenda...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <!-- Dialog exibida enquanto está buscando os itens da pré-agenda -->

      <!-- Dialog exibida enquanto está gerando a pré-agenda -->
      <v-dialog v-model="dialogAwaitGeraPreAgenda" width="50vw" persistent>
        <v-card>
          <v-card-title class="headline grey lighten-2">Visita</v-card-title>
          <v-card-text>
            <div class="d-flex flex-column justify-center align-center">
              <p class="body-1">{{ txtDialogAwaitGeraPreAgenda }}</p>
              <v-progress-linear v-model="knowledge" height="25" color="green">
                <strong>{{ Math.ceil(knowledge) }}%</strong>
              </v-progress-linear>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Dialog exibida enquanto está gerando a pré-agenda -->

      <!-- Dialog exibida enquanto está gerando a pré-agenda -->
      <v-dialog v-model="dialogAwaitExcluiVisita" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitExcluiVisita" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2">Visita</v-card-title>
          <v-card-subtitle>
            <p class="body-1">{{ txtDialogAwaitExcluiVisita }}</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <!-- Dialog exibida enquanto está gerando a pré-agenda -->

      <!-- Dialog exibida enquanto está excluindo a pré-agenda atual -->
      <v-dialog v-model="dialogAwaitExcluiPreAgenda" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitExcluiPreAgenda" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2"> Visita </v-card-title>
          <v-card-subtitle>
            <p class="body-1">Excluindo pré-agendas anteriores...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <!-- Dialog exibida enquanto está excluindo a pré-agenda atual -->

      <!-- Dialog exibida enquanto está atualizando um bloco do template -->
      <v-dialog v-model="dialogAwaitAtualizaBloco" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitAtualizaBloco" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2"> Visita </v-card-title>
          <v-card-subtitle>
            <p class="body-1">Atualizando...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <!-- Dialog exibida enquanto está atualizando um bloco do template -->

      <!-- Dialog exibida enquanto está efetivando a agenda -->
      <v-dialog v-model="dialogAwaitEfetivaAgenda" width="500">
        <v-card>
          <v-overlay :value="dialogAwaitEfetivaAgenda" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2"> Visita </v-card-title>
          <v-card-subtitle>
            <p class="body-1">{{ txtDialogAwaitEfetivaAgenda }}</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
      <!-- Dialog exibida enquanto está efetivando a agenda -->

      <!-- Dialog exibida para confirmar se deseja efetivar a agenda -->
      <v-row justify="center">
        <v-dialog v-model="dialogConfirmaAgenda" persistent max-width="520">
          <v-card>
            <v-card-title class="headline">
              Tem certeza que deseja efetivar a agenda?
            </v-card-title>
            <v-card-text>
              Ao confirmar a operação, a pré-agenda será efetivada e excluída.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="dialogConfirmaAgenda = false"
              >
                Cancelar
              </v-btn>
              <v-btn color="green darken-1" text @click="efetivaAgenda()">
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Dialog exibida para confirmar se deseja efetivar a agenda -->

      <v-row justify="center">
        <v-dialog v-model="dialogConfirmaPreAgenda" persistent width="50vw">
          <v-card>
            <v-card-title class="headline">
              Tem certeza que deseja gerar a pré-agenda?
            </v-card-title>
            <v-card-text>
              Ao confirmar a operação, todos os agendamentos já gerados serão
              descartados.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="dialogConfirmaPreAgenda = false"
              >
                Cancelar
              </v-btn>
              <v-btn color="green darken-1" text @click="preAgendaAutomatica()">
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <!-- Dialog exibida para confirmar exclusão de uma visita do template -->
      <v-row justify="center">
        <v-dialog
          v-model="dialogConfirmaExclusaoVisita"
          persistent
          max-width="520"
        >
          <v-card>
            <v-card-title class="headline">
              {{ textDialogConfirmaExclusaoVisita }}
            </v-card-title>
            <v-card-text>
              Ao confirmar a operação, não será possível recuperar esta visita e
              caso seja necessário, a mesma terá que ser incluída novamente.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                dialogConfirmaExclusaoVisita
                @click="dialogConfirmaExclusaoVisita = false"
              >
                Cancelar
              </v-btn>
              <v-btn color="red" @click="confirmaExclusao(templateSelecionado)">
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Dialog exibida para confirmar exclusão de uma visita do template -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import vueKanban from "vue-kanban";
import { mapMutations, mapState } from "vuex";
//import { component } from 'vue/types/umd';

Vue.use(vueKanban);

export default {
  name: "Visitas-page",
  data: () => {
    return {
      knowledge: 0,
      semana1: [
        "segunda-feira",
        "terça-feira",
        "quarta-feira",
        "quinta-feira",
        "sexta-feira",
      ],
      semana2: [
        "segunda-feira",
        "terça-feira",
        "quarta-feira",
        "quinta-feira",
        "sexta-feira",
      ],
      semana3: [
        "segunda-feira",
        "terça-feira",
        "quarta-feira",
        "quinta-feira",
        "sexta-feira",
      ],
      semana4: [
        "segunda-feira",
        "terça-feira",
        "quarta-feira",
        "quinta-feira",
        "sexta-feira",
      ],
      visitasSemana1: [],
      visitasSemana2: [],
      visitasSemana3: [],
      visitasSemana4: [],
      dialogAwaitLoadTemplate: false,
      mostraDados: null,
      headersAssessor: [],
      itemsAssessor: [],
      top500SA3: [],
      assessorAtual: "",
      templateSelecionado: null,
      showSnackbar: false,
      multiLine: true,
      snackbarText: "",
      dialogAwaitPreAgenda: false,
      dialogAwaitBuscaPreAgendas: false,
      dialogAwaitGeraPreAgenda: false,
      dialogConfirmaPreAgenda: false,
      confirmaGeracaoPreAgenda: false,
      dialogAwaitExcluiPreAgenda: false,
      txtDialogAwaitGeraPreAgenda: "",
      dialogAwaitAtualizaBloco: false,
      dialogAwaitEfetivaAgenda: false,
      txtDialogAwaitEfetivaAgenda: "",
      dialogConfirmaAgenda: false,
      loadingText: "Buscando dados...",
      dialogConfirmaExclusaoVisita: false,
      textDialogConfirmaExclusaoVisita: "",
      dialogAwaitExcluiVisita: false,
      txtDialogAwaitExcluiVisita: "",

      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      weekdays: [0, 1, 2, 3, 4, 5, 6],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ["blue", "green", "red"],
      names: ["Presencial", "Remota", "Centro Cirurgico"],
      periodo: [
        { id: 0, name: "Manhã" },
        { id: 1, name: "Tarde" },
      ],
      isNew: false,
      NrRegFiltro: null,
      codCli: null,
      lojaCli: null,
      progress: 0,
      indFiltro: "C",
      filtroEscolhido: null,
      id: null,
      aError: [],
      itemsFiltro: ["Código", "Nome"],
      stringFiltro: null,
      dialogErrosVisita: false,
      dialogAwaitSaveVisita: false,
      dialogSuccessSaveVisita: false,
      mensagem: null,
      errosTE: false,
      sheetMessage: false,
      colorMessage: "",
      iconMessage: "",
      titleDialog: "",
      message: "",
      DialogDados: false,
      isReading: false,
      isEditing: false,
      search: null,
      loading: true,
      altura: 0,
      perPage: 0,
      HeadersVisitas: [],
      ItemVisitas: [],
      itemsTipo: [],
      camposTela: [],

      dadosTela: [],
      cliente: {},
      boilerplate: false,
      dadosCep: [],
      DtInicial: new Date().toISOString().substr(0, 10),
      countObrigatorio: 0,
      cloneDadosTela: null,
      interval: 0,
      A1_COD: "",
      A1_NOME: "",
      A1_CGC: "",
      stringCampos: "",
      top500ZTY: [],
    };
  },
  /*components: { Detalhes,FollowUp },*/
  computed: {
    ...mapState(["user", "metadados"]),
    DataFormatadaInicial() {
      return moment(this.DtInicial).format("DD/MM/YYYY");
    },
  },
  methods: {
    mostra(dia, semana) {
      this.dadosTela = [];
      this.isReading = false;
      this.titleDialog = `Nova visita`;
      this.isNew = true;
      this.carregaDados();
      this.DialogDados = true;

      if (semana == 1) {
        this.dadosTela["ZTY_SEMANA"] = `${semana}=Primeira`;
      }
      if (semana == 2) {
        this.dadosTela["ZTY_SEMANA"] = `${semana}=Segunda`;
      }
      if (semana == 3) {
        this.dadosTela["ZTY_SEMANA"] = `${semana}=Terceira`;
      }
      if (semana == 4) {
        this.dadosTela["ZTY_SEMANA"] = `${semana}=Quarta`;
      }

      const NomeDia = dia.split("-");

      if (NomeDia[0] === "segunda") {
        this.dadosTela["ZTY_DIASEM"] = `1=Segunda`;
      }
      if (NomeDia[0] === "terça") {
        this.dadosTela["ZTY_DIASEM"] = `2=Terça`;
      }
      if (NomeDia[0] === "quarta") {
        this.dadosTela["ZTY_DIASEM"] = `3=Quarta`;
      }
      if (NomeDia[0] === "quinta") {
        this.dadosTela["ZTY_DIASEM"] = `4=Quinta`;
      }
      if (NomeDia[0] === "sexta") {
        this.dadosTela["ZTY_DIASEM"] = `5=Sexta`;
      }

      if (!this.user.AdmVisitador) {
        this.dadosTela.ZTY_PROSPE = this.user.cod_vendedor;
      } else {
        this.dadosTela.ZTY_PROSPE = this.assessorAtual;
      }
    },
    ...mapMutations(["setOrigem"]),
    LimpaFiltro() {
      this.stringFiltro = null;
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.itemsAssessor = this.top500SA3;
    },
    selecionaTemplate(item) {
      this.templateSelecionado = item;
      this.snackbarText = `Item selecionado: ${item.title}`;
      this.showSnackbar = true;
    },
    excluiTemplate(item) {
      this.textDialogConfirmaExclusaoVisita = `Tem certeza que deseja excluir a visita ao médico ${item.title.trim()}?`;
      this.dialogConfirmaExclusaoVisita = true;
    },
    async confirmaExclusao(item) {
      this.txtDialogAwaitExcluiVisita = `Excluindo visita ID ${item.ZTY_ID}...`;
      this.dialogAwaitExcluiVisita = true;

      let today = new Date();
      let todayString = `${this.getYearString(today)}${this.getMonthString(
        today,
      )}${this.getDayString(today)}`;

      let ZTY = {
        ZTY_ID: item.ZTY_ID,
        ZTY_PROSPE: item.ZTY_PROSPE,
        ZTY_MEDICO: item.ZTY_MEDICO,
        ZTY_DTEXCL: todayString,
        ZTY_USREXC: this.user.usr_protheus,
      };

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZTY,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/delVisitaTemplate`;
      await axios.put(url, dados).then((/*res*/) => {
        this.snackbarText = `Visita excluída com sucesso.`;
        this.showSnackbar = true;
      });

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: " ZTY_ID ",
        WHERE: ` ZTY_ID = '${item.ZTY_ID}' `,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/getTemplateVisitas`;

      await axios.post(url, dados).then(res => {
        if (res.data.length > 0) {
          this.snackbarText = `Houve um problema ao excluir esta visita.`;
          this.showSnackbar = true;
        } else {
          this.snackbarText = `Visita excluída com sucesso.`;
          this.showSnackbar = true;
        }
      });

      let codigo_vendedor = this.user.AdmVisitador
        ? this.assessorAtual
        : this.user.cod_vendedor;

      this.templateSelecionado = null;

      this.buscaTemplateVisitas(codigo_vendedor);

      this.dialogConfirmaExclusaoVisita = false;
      this.dialogAwaitExcluiVisita = false;
    },
    async viewDay({ date }) {
      this.focus = date;

      if (this.templateSelecionado !== null) {
        let horaInicial = this.templateSelecionado.ZTY_HRINI;
        let horaFinal = this.templateSelecionado.ZTY_HRFIN;

        const min = new Date(`${date}T${horaInicial}:00`);
        const max = new Date(`${date}T${horaFinal}:00`);

        const allDay = this.rnd(0, 3) === 0;

        let dataSplit = min
          .toISOString()
          .split("T")[0]
          .split("-");

        let ZTT = {
          ZTT_IDTEMP: this.templateSelecionado.ZTY_ID,
          ZTT_PROSPE: this.templateSelecionado.ZTY_PROSPE,
          ZTT_MEDICO: this.templateSelecionado.ZTY_MEDICO,
          ZTT_VISITA: this.templateSelecionado.ZTY_VISITA,
          ZTT_DATA: dataSplit[0] + dataSplit[1] + dataSplit[2],
          ZTT_HRINI: horaInicial,
          ZTT_HRFIM: horaFinal,
        };

        let exists = false;

        // verifica se o item atual já foi pre-agendado
        this.events.forEach(function(evento) {
          if (evento.id == ZTT.ZTT_IDTEMP) {
            dataSplit = evento.start
              .toISOString()
              .split("T")[0]
              .split("-");
            exists = true;
          }
        });

        if (exists) {
          this.snackbarText = `O template selecionado já foi pré-agendado para ${
            dataSplit[2]
          }/${dataSplit[1] - 1}/${dataSplit[0]}.`;
          this.showSnackbar = true;
        } else {
          this.dialogAwaitPreAgenda = true;

          const dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            ZTT,
          };

          const url = `${process.env.VUE_APP_BASE_API_URL}/svVisitaManual`;
          await axios.post(url, dados).then(res => {
            if (res.data.STATUS === "true") {
              this.events.push({
                id: this.templateSelecionado.id,
                name: this.templateSelecionado.title,
                start: min,
                end: max,
                color: this.getColor(ZTT.ZTT_VISITA.trim()),
                timed: !allDay,
                details: `${this.templateSelecionado.ZTY_HRINI} - ${this.templateSelecionado.ZTY_HRFIN}`,
                ZTY: this.templateSelecionado,
              });

              this.dialogAwaitSaveVisita = false;
              this.progress = 100;
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({
                  text: `${e}`,
                  icon: "mdi-send",
                });
              });
              this.dialogAwaitSaveVisita = false;
              this.dialogErrosVisita = true;
              this.progress = 100;
              return;
            }
          });

          this.dialogAwaitPreAgenda = false;
        }
      } else {
        this.snackbarText =
          "Nenhum template foi selecionado. Selecione um template para efetivar no calendário.";
        this.showSnackbar = true;
      }

      this.templateSelecionado = null;
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.events = [];
      this.$refs.calendar.prev();
    },
    next() {
      this.events = [];
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async buscaPreAgenda() {
      let MD021 = [];
      let ano = this.$refs.calendar.lastStart.year.toString();
      let mes = ("00" + this.$refs.calendar.lastStart.month.toString()).slice(
        -2,
      );
      let where = `ZTT_PROSPE = '${this.assessorAtual}' AND ZTT_DATA LIKE '${ano}${mes}^'`;

      this.dialogAwaitBuscaPreAgendas = true;
      this.stringCampos = "";

      MD021 = this.metadados.filter(
        e =>
          e.ZZP_MODELO.trim() === "MD021" &&
          (e.ZZP_TABELA.trim() === "ZTT010" ||
            e.ZZP_TABELA.trim() === "ZTY010"),
      );

      MD021.map(e => {
        this.stringCampos += `${e.ZZP_CAMPO.trim()},`;
      });

      if (this.stringCampos.substr(-1) === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: this.stringCampos,
        WHERE: where,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/getTemplateTemporario`;

      // const min = new Date(`${start.date}T00:00:00`)
      // const max = new Date(`${end.date}T23:59:59`)
      // const days = (max.getTime() - min.getTime()) / 86400000
      // const eventCount = this.rnd(days, days + 20)

      await axios.post(url, dados).then(res => {
        res.data.map(e => {
          let year = this.getYearInt(e.ZTT_DATA);
          let month = this.getMonthInt(e.ZTT_DATA);
          let day = this.getDayInt(e.ZTT_DATA);
          let allDay = this.rnd(0, 3) === 0;
          let first = new Date(year, month, day);
          let secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 1000;
          let second = new Date(first.getTime() + secondTimestamp);

          let color = this.getColor(e.ZTT_VISITA.trim());

          let ZTY = {
            ZTT_ID: e.ZTT_ID,
            ZTT_IDTEMP: e.ZTT_IDTEMP,
            ZTY_SEMANA: e.ZTY_SEMANA,
            ZTY_DIASEM: e.ZTY_DIASEM,
            ZTY_HRINI: e.ZTY_HRINI,
            ZTY_HRFIN: e.ZTY_HRFIN,
            ZTY_PROSPE: e.ZTY_PROSPE,
            ZTY_MEDICO: e.ZTY_MEDICO,
            ZTY_VISITA: e.ZTY_VISITA,
          };

          this.events.push({
            id: e.ZTT_IDTEMP,
            name: e.MEDICO,
            start: first,
            end: second,
            color: color,
            timed: !allDay,
            details: `${ZTY.ZTY_HRINI} - ${ZTY.ZTY_HRFIN}`,
            ZTY: ZTY,
          });
        });
      });

      this.dialogAwaitBuscaPreAgendas = false;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getColor(tipoVisita) {
      switch (tipoVisita) {
        case "P":
          return "green";
        case "R":
          return "blue";
        case "N":
          return "red";
        default:
          return "black";
      }
    },
    async filtraRegistro(campo, stringFiltro) {
      if (!campo || !stringFiltro) return;

      let WHERE = ` (A3_ZPROSPE = '${this.user.cod_vendedor}' OR A3_ZECTC = '${this.user.cod_vendedor}') AND `;

      if (campo === "Código") {
        WHERE += `A3_COD LIKE '^${stringFiltro}^'`;
      }

      if (campo === "Nome") {
        WHERE += `A3_NOME LIKE '^${stringFiltro.toUpperCase()}^'`;
      }

      this.loading = true;
      this.top500SA3 = this.itemsAssessor;
      this.ItemOS = [];

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: this.stringCampos,
        WHERE: WHERE,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/assessor`;

      await axios.post(url, dados).then(res => {
        this.itemsAssessor = res.data;
        this.NrRegFiltro = this.itemsAssessor.length;
      });

      this.loading = false;
    },
    async blockUpdated(id, status) {
      this.dialogAwaitAtualizaBloco = true;

      let diaSemana = "0";

      if (status == "segunda-feira") {
        diaSemana = "1";
      } else if (status == "terça-feira") {
        diaSemana = "2";
      } else if (status == "quarta-feira") {
        diaSemana = "3";
      } else if (status == "quinta-feira") {
        diaSemana = "4";
      } else if (status == "sexta-feira") {
        diaSemana = "5";
      }

      let ZTY = {
        ZTY_ID: id,
        ZTY_DIASEM: diaSemana,
      };

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZTY,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/svTemplateVisita`;
      await axios.put(url, dados).then(res => {
        if (res.data.STATUS) {
          this.snackbarText = `ID ${res.data.CODIGO} atualizado para ${status}.`;
          this.showSnackbar = true;
        }
      });

      this.dialogAwaitAtualizaBloco = false;
    },
    async listaAssessoresProspector() {
      this.altura = window.screen.availHeight - 300;
      this.loading = true;

      let MD020 = [];
      MD020 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD020");

      MD020.map(e => {
        this.stringCampos += `${e.ZZP_CAMPO.trim()},`;
      });

      if (this.stringCampos.substr(-1) === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      this.headersAssessor.push({
        text: "Actions",
        value: "actions",
        align: "left",
        width: 40,
        caption: "title",
      });

      await MD020.map(e => {
        let width = 0;

        if (e.ZZP_CAMPO.trim() == "A3_COD") {
          width = 30;
        } else {
          width = 250;
        }

        this.headersAssessor.push({
          text: e.ZZP_TITULO.trim(),
          value: e.ZZP_CAMPO.trim(),
          align: "start",
          width: width,
          caption: "title",
        });
      });

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: this.stringCampos,
        WHERE: ` A3_ZTIPO IN ('000017','000021','100007','100008') ORDER BY A3_NOME`,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/assessor`;

      this.itemsAssessor = [];

      await axios.post(url, dados).then(res => {
        this.itemsAssessor = res.data;
      });

      this.loading = false;
    },
    async buscaTemplateVisitas(codAssessor) {
      let MD019 = [];
      let where = `ZTY_PROSPE = '${codAssessor}'`;

      this.assessorAtual = codAssessor;

      this.visitasSemana1 = [];
      this.visitasSemana2 = [];
      this.visitasSemana3 = [];
      this.visitasSemana4 = [];
      this.dialogAwaitLoadTemplate = true;
      this.stringCampos = "";

      MD019 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD019");

      MD019.map(e => {
        this.stringCampos += `${e.ZZP_CAMPO.trim()},`;
      });

      if (this.stringCampos.substr(-1) === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: this.stringCampos,
        WHERE: where,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/getTemplateVisitas`;

      await axios.post(url, dados).then(res => {
        res.data.map(e => {
          switch (e.ZTY_DIASEM) {
            case "1":
              e.status = "segunda-feira";
              break;
            case "2":
              e.status = "terça-feira";
              break;
            case "3":
              e.status = "quarta-feira";
              break;
            case "4":
              e.status = "quinta-feira";
              break;
            case "5":
              e.status = "sexta-feira";
              break;
          }

          switch (e.ZTY_SEMANA) {
            case "1":
              this.visitasSemana1.push({
                id: e.ZTY_ID.trim(),
                status: e.status,
                title: e.NOME_MEDICO ? e.NOME_MEDICO.trim() : "",
                detail: `${e.ZTY_HRINI} ~ ${e.ZTY_HRFIN}`,
                ZTY_ID: e.ZTY_ID,
                ZTY_MEDICO: e.ZTY_MEDICO,
                ZTY_PROSPE: e.ZTY_PROSPE,
                ZTY_SEMANA: e.ZTY_SEMANA,
                ZTY_VISITA: e.ZTY_VISITA,
                ZTY_HRINI: e.ZTY_HRINI,
                ZTY_HRFIN: e.ZTY_HRFIN,
                new: false,
              });
              break;
            case "2":
              this.visitasSemana2.push({
                id: e.ZTY_ID.trim(),
                status: e.status,
                title: e.NOME_MEDICO.trim(),
                detail: `${e.ZTY_HRINI} ~ ${e.ZTY_HRFIN}`,
                ZTY_ID: e.ZTY_ID,
                ZTY_MEDICO: e.ZTY_MEDICO,
                ZTY_PROSPE: e.ZTY_PROSPE,
                ZTY_SEMANA: e.ZTY_SEMANA,
                ZTY_VISITA: e.ZTY_VISITA,
                ZTY_HRINI: e.ZTY_HRINI,
                ZTY_HRFIN: e.ZTY_HRFIN,
                new: false,
              });
              break;
            case "3":
              this.visitasSemana3.push({
                id: e.ZTY_ID.trim(),
                status: e.status,
                title: e.NOME_MEDICO.trim(),
                detail: `${e.ZTY_HRINI} ~ ${e.ZTY_HRFIN}`,
                ZTY_ID: e.ZTY_ID,
                ZTY_MEDICO: e.ZTY_MEDICO,
                ZTY_PROSPE: e.ZTY_PROSPE,
                ZTY_SEMANA: e.ZTY_SEMANA,
                ZTY_VISITA: e.ZTY_VISITA,
                ZTY_HRINI: e.ZTY_HRINI,
                ZTY_HRFIN: e.ZTY_HRFIN,
                new: false,
              });
              break;
            case "4":
              this.visitasSemana4.push({
                id: e.ZTY_ID.trim(),
                status: e.status,
                title: e.NOME_MEDICO.trim(),
                detail: `${e.ZTY_HRINI} ~ ${e.ZTY_HRFIN}`,
                ZTY_ID: e.ZTY_ID,
                ZTY_MEDICO: e.ZTY_MEDICO,
                ZTY_PROSPE: e.ZTY_PROSPE,
                ZTY_SEMANA: e.ZTY_SEMANA,
                ZTY_VISITA: e.ZTY_VISITA,
                ZTY_HRINI: e.ZTY_HRINI,
                ZTY_HRFIN: e.ZTY_HRFIN,
                new: false,
              });
              break;
          }
        });
      });

      this.dialogAwaitLoadTemplate = false;
    },

    obrigat(value, obrigatorio) {
      if (!value && obrigatorio === "1") {
        return ["Campo obrigatório!"];
      }
    },
    queryAndIndeterminate() {
      this.progress = 0;

      this.interval = setInterval(() => {
        if (this.progress === 100) {
          clearInterval(this.interval);
          this.progress = 0;
          return;
          //return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.progress += 5;
      }, 1000);
    },
    getYearString(myDate) {
      return myDate.getFullYear().toString();
    },
    getMonthString(myDate) {
      let month = myDate.getMonth() + 1;
      let monthString = ("00" + month).slice(-2);
      return monthString;
    },
    getDayString(myDate) {
      let day = myDate.getDate();
      let dayString = ("00" + day).slice(-2);
      return dayString;
    },
    getYearInt(stringData /*AAAAMMDD*/) {
      let year = stringData.substr(0, 4);
      return parseInt(year);
    },
    getMonthInt(stringData /*AAAAMMDD*/) {
      let month = stringData.substr(4, 2);
      return parseInt(month) - 1;
    },
    getDayInt(stringData /*AAAAMMDD*/) {
      let day = stringData.substr(6, 2);
      return parseInt(day);
    },
    async deletaAgendamentos() {
      this.dialogAwaitExcluiPreAgenda = true;
      let user_protheus = this.user.usr_protheus;

      // this.events.forEach(async function(evento) {

      for (const evento of this.events) {
        let dataSplit = evento.start
          .toISOString()
          .split("T")[0]
          .split("-");

        let today = new Date();
        let todayString = `${this.getYearString(today)}${this.getMonthString(
          today,
        )}${this.getDayString(today)}`;

        let ZTT = {
          ZTT_ID: evento.ZTY.ZTT_ID,
          ZTT_IDTEMP: evento.ZTY.ZTT_IDTEMP,
          ZTT_PROSPE: evento.ZTY.ZTY_PROSPE,
          ZTT_MEDICO: evento.ZTY.ZTY_MEDICO,
          ZTT_VISITA: evento.ZTY.ZTY_VISITA,
          ZTT_DATA: dataSplit[0] + dataSplit[1] + dataSplit[2],
          ZTT_DTEXCL: todayString,
          ZTT_USREXC: user_protheus,
        };

        let dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZTT,
        };

        let url = `${process.env.VUE_APP_BASE_API_URL}/delAgendaTemporaria`;
        await axios.put(url, dados).then(() => {
          this.txtDialogAwaitGeraPreAgenda = `Excluindo ID ${evento.ZTY.ZTT_ID}...`;
          // this.events.splice(index);
        });
      }
      // });preAgendaAutomatica

      this.events = []; // reseto os eventos da pre-agenda
      this.dialogAwaitExcluiPreAgenda = false;
    },
    async preAgendaAutomatica() {
      this.knowledge = 0;
      this.dialogConfirmaPreAgenda = false;
      this.dialogAwaitGeraPreAgenda = true;
      await this.deletaAgendamentos();

      let MD019 = [];
      let where = `ZTY_PROSPE = '${this.assessorAtual}' ORDER BY ZTY_SEMANA, ZTY_DIASEM`;
      // let date = new Date();

      this.txtDialogAwaitGeraPreAgenda = `Buscando o template atual de visitas...`;
      this.stringCampos = "";

      MD019 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD019");

      MD019.map(e => {
        this.stringCampos += `${e.ZZP_CAMPO.trim()},`;
      });

      if (this.stringCampos.substr(-1) === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: this.stringCampos,
        WHERE: where,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/getTemplateVisitas`;

      let year = "";
      let month = "";

      if (!this.$refs.calendar.value) {
        year = this.$refs.calendar.start.split("-")[0];
        month = this.$refs.calendar.start.split("-")[1];
      } else {
        year = this.$refs.calendar.value.split("-")[0];
        month = this.$refs.calendar.value.split("-")[1];
      }

      await axios.post(url, dados).then(res => {
        res.data.map(e => {
          let allDay = this.rnd(0, 3) === 0;

          let primeiroDia = new Date(year, parseInt(month) - 1, 1);
          let daysSum = 0;

          e.ZTT_ID = "";

          // percorre por 5 dias na semana
          for (let i = 1; i <= 5; i++) {
            if (e.ZTY_DIASEM == i) {
              if (e.ZTY_SEMANA == "1") {
                daysSum = 7;
              } else if (e.ZTY_SEMANA == "2") {
                daysSum = 14;
              } else if (e.ZTY_SEMANA == "3") {
                daysSum = 21;
              } else if (e.ZTY_SEMANA == "4") {
                daysSum = 28;
              }

              if (e.ZTY_DIASEM < primeiroDia.getDay()) {
                this.events.push({
                  id: e.ZTY_ID,
                  name: e.NOME_MEDICO,
                  start: new Date(
                    year,
                    parseInt(month) - 1,
                    primeiroDia.getDate() +
                      daysSum -
                      (primeiroDia.getDay() - parseInt(e.ZTY_DIASEM)),
                  ),
                  end: new Date(
                    year,
                    parseInt(month) - 1,
                    primeiroDia.getDate() +
                      daysSum -
                      (primeiroDia.getDay() - parseInt(e.ZTY_DIASEM)),
                  ), // somo primeiro dia + 7, depois subtraio pela diferença no dia da semana
                  color: this.getColor(e.ZTY_VISITA.trim()),
                  timed: !allDay,
                  details: `${e.ZTY_HRINI} - ${e.ZTY_HRFIN}`,
                  ZTY: e,
                });
              } else {
                this.events.push({
                  id: e.ZTY_ID,
                  name: e.NOME_MEDICO,
                  start: new Date(
                    year,
                    parseInt(month) - 1,
                    primeiroDia.getDate() +
                      (daysSum - 7) +
                      (i - primeiroDia.getDay()),
                  ),
                  end: new Date(
                    year,
                    parseInt(month) - 1,
                    primeiroDia.getDate() +
                      (daysSum - 7) +
                      (i - primeiroDia.getDay()),
                  ), // somo primeiro dia + 7, depois subtraio pela diferença no dia da semana
                  color: this.getColor(e.ZTY_VISITA.trim()),
                  timed: !allDay,
                  details: `${e.ZTY_HRINI} - ${e.ZTY_HRFIN}`,
                  ZTY: e,
                });
              }
            }
          }
        });
      });

      await this.gravaPreAgenda();
    },
    async gravaPreAgenda() {
      const totalRegistros =
        this.visitasSemana1.length +
        this.visitasSemana2.length +
        this.visitasSemana3.length +
        this.visitasSemana4.length;

      let contador = 0;

      for (const evento of this.events) {
        let dataSplit = evento.start
          .toISOString()
          .split("T")[0]
          .split("-");

        let ZTT = {
          ZTT_ID: evento.ZTY.ZTT_ID,
          ZTT_IDTEMP: evento.ZTY.ZTY_ID,
          ZTT_PROSPE: evento.ZTY.ZTY_PROSPE,
          ZTT_MEDICO: evento.ZTY.ZTY_MEDICO,
          ZTT_VISITA: evento.ZTY.ZTY_VISITA,
          ZTT_HRINI: evento.ZTY.ZTY_HRINI,
          ZTT_HRFIM: evento.ZTY.ZTY_HRFIN,
          ZTT_DATA: `${dataSplit[0]}${dataSplit[1]}${dataSplit[2]}`,
        };

        let dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZTT,
        };

        let url = `${process.env.VUE_APP_BASE_API_URL}/svVisitaManual`;
        await axios.post(url, dados).then(() => {
          this.txtDialogAwaitGeraPreAgenda = `Médico/Hospital: ${evento.name} - Data: ${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`;
          contador++;
          this.knowledge = (contador * 100) / totalRegistros;
        });
      }

      this.dialogAwaitGeraPreAgenda = false;
    },
    async efetivaAgenda() {
      this.dialogAwaitEfetivaAgenda = true;
      this.txtDialogAwaitEfetivaAgenda = "Aguarde...";

      for (const evento of this.events) {
        let dataSplit = evento.start
          .toISOString()
          .split("T")[0]
          .split("-");

        let ZZY = {
          ZZY_PROSPE: evento.ZTY.ZTY_PROSPE,
          ZZY_MEDICO: evento.ZTY.ZTY_MEDICO,
          ZZY_VISITA: evento.ZTY.ZTY_VISITA,
          ZZY_DATA: dataSplit[0] + dataSplit[1] + dataSplit[2],
          ZZY_HRINI: evento.ZTY.ZTY_HRINI,
          ZZY_HRFIM: evento.ZTY.ZTY_HRFIN,
        };

        let dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZZY,
        };

        let url = `${process.env.VUE_APP_BASE_API_URL}/saveAgendaApp`;
        await axios.post(url, dados).then(() => {
          this.txtDialogAwaitEfetivaAgenda = `Incluído=> Médico/Hospital: ${evento.name} - Data: ${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`;
        });
      }

      this.dialogAwaitEfetivaAgenda = false;
      this.dialogConfirmaAgenda = false;

      await this.deletaAgendamentos();

      this.snackbarText = `Agenda efetivada com sucesso!`;
      this.showSnackbar = true;
    },
    //Função para remover caracteres especiais na Notas
    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");
      return text.toUpperCase();
    },
    async salva(id) {
      const keysObrigat = [];

      this.dialogAwaitSaveVisita = true;
      this.dialogSuccessSaveVisita = false;

      this.camposTela.filter(e => {
        if (e.ZZP_OBRIGA === "1") {
          const campo = e.ZZP_CAMPO.trim();
          keysObrigat.push({
            campo,
          });
        }
      });

      let verifica = true;

      this.cloneDadosTela = this.dadosTela;

      const keys = Object.keys(this.cloneDadosTela);

      keysObrigat.forEach(e => {
        if (keys.indexOf(e.campo) < 0) {
          verifica = false;
        }
      });

      if (!verifica) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      keys.forEach(e => {
        this.cloneDadosTela[e] = this.cloneDadosTela[e].toUpperCase();
      });

      let dia = ``;
      let medico = ``;

      this.camposTela.forEach(e => {
        let value = null;
        if (e.ZZP_TIPO.trim() === "A") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("-");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
          if (e.ZZP_CAMPO.trim() === "ZTY_MEDICO") {
            medico = value[1].trim();
          }
        }
        if (e.ZZP_TIPO.trim() === "S") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("=");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
          if (e.ZZP_CAMPO.trim() === "ZTY_DIASEM") {
            dia = `${value[1].toLowerCase()}-feira`;
          }
        }
        if (e.ZZP_TIPO.trim() === "D") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("/");
          this.cloneDadosTela[
            e.ZZP_CAMPO.trim()
          ] = `${value[2]}${value[1]}${value[0]}`;
        }
        if (
          e.ZZP_TIPO.trim() === "T" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          value = this.removeAcento(this.cloneDadosTela[e.ZZP_CAMPO.trim()]);
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value;
        }
      });

      this.queryAndIndeterminate();
      if (id) {
        const codigoCli = id.split("-");

        let ZTY = { ...this.cloneDadosTela };

        ZTY.A1_COD = codigoCli[0];
        ZTY.A1_LOJA = codigoCli[1];

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZTY,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/svTemplateVisita`;
        await axios
          .put(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = `Template de visita salvo com o código: ${res.data.CODIGO}`;
              this.dialogAwaitSaveVisita = false;
              this.dialogSuccessSaveVisita = true;
              this.progress = 100;
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({
                  text: `${e}`,
                  icon: "mdi-send",
                });
              });
              this.dialogAwaitSaveVisita = false;
              this.dialogErrosVisita = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => {
            this.aError = [];
            this.aError.push({ text: `${err}`, icon: "mdi-send" });

            this.dialogAwaitSaveVisita = false;
            this.dialogErrosVisita = true;
            this.progress = 100;
            return;
          });
      } else {
        let ZTY = { ...this.cloneDadosTela };

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZTY,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/svTemplateVisita`;
        await axios.post(url, dados).then(res => {
          if (res.data.STATUS) {
            // let codigo_vendedor = this.user.AdmVisitador
            //   ? this.assessorAtual
            //   : this.user.cod_vendedor;

            this.idSalvo = res.data.CODIGO;
            this.mensagem = `Template de visita salvo com o código: ${res.data.CODIGO}`;
            this.dialogAwaitSaveVisita = false;
            this.dialogSuccessSaveVisita = true;
            this.DialogDados = false;
            this.progress = 100;

            if (dados.ZTY.ZTY_SEMANA === "1") {
              this.visitasSemana1.push({
                id: res.data.CODIGO,
                status: dia,
                title: medico,
                detail: `${dados.ZTY.ZTY_HRINI} ~ ${dados.ZTY.ZTY_HRFIN}`,
                ZTY_ID: res.data.CODIGO,
                ZTY_MEDICO: dados.ZTY.ZTY_MEDICO,
                ZTY_PROSPE: dados.ZTY.ZTY_PROSPE,
                ZTY_SEMANA: dados.ZTY.ZTY_SEMANA,
                ZTY_VISITA: dados.ZTY.ZTY_VISITA,
                ZTY_HRINI: dados.ZTY.ZTY_HRINI,
                ZTY_HRFIN: dados.ZTY.ZTY_HRFIN,
                new: true,
              });
            }
            if (dados.ZTY.ZTY_SEMANA === "2") {
              this.visitasSemana2.push({
                id: res.data.CODIGO,
                status: dia,
                title: medico,
                detail: `${dados.ZTY.ZTY_HRINI} ~ ${dados.ZTY.ZTY_HRFIN}`,
                ZTY_ID: res.data.CODIGO,
                ZTY_MEDICO: dados.ZTY.ZTY_MEDICO,
                ZTY_PROSPE: dados.ZTY.ZTY_PROSPE,
                ZTY_SEMANA: dados.ZTY.ZTY_SEMANA,
                ZTY_VISITA: dados.ZTY.ZTY_VISITA,
                ZTY_HRINI: dados.ZTY.ZTY_HRINI,
                ZTY_HRFIN: dados.ZTY.ZTY_HRFIN,
                new: true,
              });
            }
            if (dados.ZTY.ZTY_SEMANA === "3") {
              this.visitasSemana3.push({
                id: res.data.CODIGO,
                status: dia,
                title: medico,
                detail: `${dados.ZTY.ZTY_HRINI} ~ ${dados.ZTY.ZTY_HRFIN}`,
                ZTY_ID: res.data.CODIGO,
                ZTY_MEDICO: dados.ZTY.ZTY_MEDICO,
                ZTY_PROSPE: dados.ZTY.ZTY_PROSPE,
                ZTY_SEMANA: dados.ZTY.ZTY_SEMANA,
                ZTY_VISITA: dados.ZTY.ZTY_VISITA,
                ZTY_HRINI: dados.ZTY.ZTY_HRINI,
                ZTY_HRFIN: dados.ZTY.ZTY_HRFIN,
                new: true,
              });
            }
            if (dados.ZTY.ZTY_SEMANA === "4") {
              this.visitasSemana4.push({
                id: res.data.CODIGO,
                status: dia,
                title: medico,
                detail: `${dados.ZTY.ZTY_HRINI} ~ ${dados.ZTY.ZTY_HRFIN}`,
                ZTY_ID: res.data.CODIGO,
                ZTY_MEDICO: dados.ZTY.ZTY_MEDICO,
                ZTY_PROSPE: dados.ZTY.ZTY_PROSPE,
                ZTY_SEMANA: dados.ZTY.ZTY_SEMANA,
                ZTY_VISITA: dados.ZTY.ZTY_VISITA,
                ZTY_HRINI: dados.ZTY.ZTY_HRINI,
                ZTY_HRFIN: dados.ZTY.ZTY_HRFIN,
                new: true,
              });
            }
          } else {
            this.aError = [];
            const err = res.data.ERRO.split("\n");
            err.forEach(e => {
              this.aError.push({
                text: `${e}`,
                icon: "mdi-send",
              });
            });
            this.dialogAwaitSaveVisita = false;
            this.dialogErrosVisita = true;
            this.progress = 100;
            return;
          }
        });
      }

      this.camposTela.forEach(e => {
        let value = null;
        if (e.ZZP_TIPO.trim() === "D") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()];
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = `${value.substr(
            6,
            2,
          )}/${value.substr(4, 2)}/${value.substr(0, 4)}`;
        }
      });
      this.progress = 100;
    },
    limpaVariaveis() {
      this.DialogDados = false;
      this.dialogSuccessSaveVisita = false;
      this.setOrigem("");
    },
    async carregaDados() {
      this.boilerplate = false;

      let ZTY = this.metadados.filter(
        e =>
          e.ZZP_MODELO.trim() === "MD019" &&
          e.ZZP_USADO === "1" &&
          e.ZZP_GRAVA.trim() !== "N",
      );

      ZTY.map(e => {
        if (e.ZZP_CBOX.trim().length > 0) {
          const dados = e.ZZP_CBOX.split(";");
          e.ITEMS = dados.map(i => {
            return `${i.trim()}`;
          });
        }
        if (e.ZZP_TIPO.trim() === "D") {
          e.MASK = "##/##/####";
        }
        if (e.ZZP_OBRIGA === "1") {
          this.countObrigatorio++;
        }

        if (e.ZZP_CAMPO.trim() === "ZTY_MEDICO") {
          e.ZZP_WHERE = `${e.ZZP_WHERE.trim()} AND A3_ZPROSPE = '${
            this.assessorAtual
          }' OR A3_ZECTC = '${this.assessorAtual}'`;
        }
      });

      this.camposTela = ZTY;

      this.camposTela.map(e => (e.detail = null));

      this.dadosTela["ZTY_VISITA"] = "P=Presencial";

      this.boilerplate = true;
    },
    async reload(item) {
      item.ITEMS = this.chamaConsulta(item);

      this.camposTela.map(e => {
        if (e.ZZP_CAMPO.trim() === item.ZZP_CAMPO.trim()) {
          e.ITEMS = item.ITEMS.trim();
        }
      });

      return this.camposTela;
    },
    chamaConsulta(item) {
      let dados = {};
      let items = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: ``,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      return items;
    },
    async montaBrowser() {
      this.filtroEscolhido = null;
      this.stringFiltro = null;
      this.NrRegFiltro = null;
      this.altura = window.screen.availHeight - 300;
      this.loading = true;
    },
    verDados(item) {
      this.mostraDados = true;
      this.buscaTemplateVisitas(item.A3_COD);
    },
    editItem(item) {
      this.carregaDados();
      this.titleDialog = `Editando o cliente: ${item.A1_COD}-${
        item.A1_LOJA
      } ${item.A1_NOME.trim()}`;
      this.id = `${item.A1_COD}-${item.A1_LOJA}`;

      this.isNew = false;

      this.$store.commit("SetLead_id", `${item.A1_COD}-${item.A1_LOJA}`);
      this.$store.commit("SetLead_nome", item.A1_NOME.trim());
      this.setOrigem("Visita");

      this.carregaDados();

      this.isEditing = true;
      this.dadosTela = item;
      this.isReading = false;
      this.DialogDados = true;
    },
    async carregaItems(item) {
      this.loadingText = "Buscando dados...";
      const campos = item.ZZP_F3CAMP.split(";");
      const where = item.ZZP_WHERE.trim();
      let dados = {};
      let items = [];
      this.itemsTipo = [];
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB.trim()}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: where,
          TABELA: `${item.ZZP_F3TAB.trim()}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }

            return dados;
          });

          if (Object.keys(items).length < 1) {
            this.loadingText = "Nenhum médico encontrado para este prospector.";
          }
          this.itemsTipo = items;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    novaVisitaTemplate() {
      this.dadosTela = [];
      this.isReading = false;
      this.titleDialog = `Nova visita`;
      this.isNew = true;
      this.carregaDados();
      this.DialogDados = true;

      if (!this.user.AdmVisitador) {
        this.dadosTela.ZTY_PROSPE = this.user.cod_vendedor;
      } else {
        this.dadosTela.ZTY_PROSPE = this.assessorAtual;
      }
    },
    Custonfiltro(indice) {
      const search = this.search.toString().toLowerCase();

      if (indice === "N") {
        return this.ItemVisitas.filter(i => {
          i["A1_NOME"] === search;
        });
      }
      if (indice === "C") {
        return this.ItemVisitas.filter(i => {
          i["A1_COD"] === search;
        });
      }
      if (indice === "F") {
        return this.ItemVisitas.filter(i => {
          i["A1_CGC"] === search;
        });
      }
    },
  },
  mounted() {
    this.mostraDados = !this.user.AdmVisitador;
    this.events = [];

    if (this.mostraDados) {
      this.assessorAtual = this.user.cod_vendedor;
      this.buscaTemplateVisitas(this.user.cod_vendedor);
    } else {
      this.listaAssessoresProspector();
    }
  },
};
</script>

<style lang="scss">
@import "./src/assets/kanban.scss";
</style>

<style>
.v-label {
  font-size: 0.7rem;
}
input {
  font-size: 0.8rem;
}
.text-center {
  font-size: 0.7rem !important;
}
.text-start {
  font-size: 0.7rem !important;
}
tbody tr:nth-of-type(odd) {
  background-color: #d8f6fa;
}
.v-select__selection {
  font-size: 0.8rem !important;
}
</style>
