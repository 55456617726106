import Agenda from "@/components/Agenda/Agenda";
import AgendaC from "@/components/Agenda/AgendaC";
import Home from "@/components/Home/Home";
import Vue from "vue";
import VueRouter from "vue-router";
// import Auth from '@/components/auth/Auth'
import TrataAlcada from "@/components/Alcadas/trataAlcada";
import BaseInstalada from "@/components/BaseInstalada/BaseInstalada";
import Leads1 from "@/components/Central_CRM/Leads/Leads1";
import Clientes from "@/components/Clientes/Clientes";
// import Clube from "@/components/Clube/Clube";
import Administra from "@/components/Configuracoes/Administrar";
import TClientes from "@/components/Configuracoes/TelasConfiguracao/Tclientes";
import TelasSistema from "@/components/Configuracoes/TelasSistema";
import Metas from "@/components/Metas/Metas";
import MetasProdutos from "@/components/Metas/metasProdutos";
import OrdemServico from "@/components/OrdemServico/OrdemServico";
import NewAuth from "@/components/auth/newAuth";

Vue.use(VueRouter);

const routes = [
  {
    name: "Auth",
    path: "/auth",
    component: NewAuth,
  },
  {
    name: "home",
    path: "/home",
    component: Home,
  },
  {
    name: "Agenda",
    path: "/agenda",
    component: Agenda,
    canReuse: false,
  },
  {
    name: "Telas Sistema",
    path: "/telasSistema",
    component: TelasSistema,
    canReuse: false,
  },
  {
    name: "Administracao",
    path: "/administra",
    component: Administra,
    canReuse: false,
  },
  {
    name: "Tela Clientes",
    path: "/Tclientes",
    component: TClientes,
    canReuse: false,
  },
  {
    name: "Clientes",
    path: "/clientes",
    component: Clientes,
    canReuse: false,
  },
  {
    name: "Metas",
    path: "/metas",
    component: Metas,
    canReuse: false,
  },
  {
    name: "OrdemServico",
    path: "/ordem-de-servico",
    component: OrdemServico,
    canReuse: false,
  },
  {
    name: "AgendaCir",
    path: "/agendaC",
    component: AgendaC,
    canReuse: false,
  },
  {
    name: "BaseInstalada",
    path: "/base-instalada",
    component: BaseInstalada,
    canReuse: false,
  },
  {
    name: "Leads",
    path: "/leads",
    component: Leads1,
    canReuse: false,
  },
  // {
  //   name: "Clube",
  //   path: "/clube",
  //   component: Clube,
  //   canReuse: false,
  // },
  {
    name: "MetasProdutos",
    path: "/metas-produtos",
    component: MetasProdutos,
    canReuse: false,
  },
  {
    name: "Comissao",
    path: "/comissoes",
    component: () => import("../components/Comissao/Comissao"),
    canReuse: false,
  },
  {
    name: "Alcadas",
    path: "/trataAlcada",
    component: TrataAlcada,
    canReuse: false,
  },
];

export default new VueRouter({
  //mode: 'history',
  routes,
});
