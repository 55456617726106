<template>
  <v-card class="mx-auto mt-3" max-width="400" tile>
    <v-list dense>
      <v-subheader>Opções</v-subheader>
      <v-list-item-group v-model="itensAdministracao" color="primary">
        <v-list-item
          v-for="(item, i) in itensAdministracao"
          :key="i"
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.Item"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "Administra",
  data: () => ({
    itensAdministracao: [
      { Item: "Telas", link: "/telasSistema", icon: "mdi-monitor", flex: 1 },
    ],
  }),
};
</script>

<style></style>
