<template>
  <v-row class="d-flex flex-1 flex-column justify-center">
    <div>
      <header class="d-flex justify-space-between">
        <div
          class="d-flex ml-5 align-space-between justify-space-between"
          style="padding-left: 4vw; padding-top: 20px; width: 30vw;"
        >
          <div>
            <v-btn
              icon
              href="http://www.universidadeopimed.com.br"
              target="_blank"
            >
              <v-icon size="64" class="pa-2" color="black">mdi-school</v-icon>
            </v-btn>
            <span class="ma-5">Universidade Opimed</span>
          </div>
          <div>
            <v-btn
              icon
              href="https://opimed.sistemapeoplenet.com.br/rh/App"
              target="_blank"
            >
              <v-icon size="64" class="pa-2" color="black"
                >mdi-badge-account</v-icon
              >
            </v-btn>
            <span class="ma-5">People Net Rh</span>
          </div>
        </div>
        <div class="event" v-if="natal">
          <v-img
            max-height="90px"
            max-width="90px"
            src="../../assets/natal1.jpg"
            contain
            class="imgVert rounded-lg"
          >
          </v-img>
        </div>
        <div class="event" v-if="anoNovo">
          <v-avatar size="90">
            <img
              max-height="90px"
              max-width="90px"
              src="../../assets/anoNovo.png"
              contain
              class="rounded-lg"
            />
          </v-avatar>
        </div>
        <div class="event" v-if="copa">
          <v-img
            max-width="100px"
            src="../../assets/taca-copa.webp"
            contain
            class="rounded-lg"
          />
        </div>
      </header>
      <main class="center">
        <v-img
          v-if="copa"
          src="../../assets/Logomarca_Opimed_copa.png"
          max-height="500px"
          max-width="500px"
          contain
        >
        </v-img>
        <v-img
          v-else
          src="../../assets/Logomarca_Opimed.png"
          max-height="500px"
          max-width="500px"
          contain
        >
        </v-img>
      </main>
    </div>
  </v-row>
</template>

<script>
import confetti from "canvas-confetti";
import { getDate, getMonth, isToday } from "date-fns";
import { mapMutations } from "vuex";

export default {
  name: "paginaInicial",
  data() {
    return {
      natal: false,
      anoNovo: false,
      copa: false,
    };
  },
  methods: mapMutations(["setMenuColor"]),
  mounted() {
    const hoje = new Date();
    const mes = getMonth(hoje) + 1;
    const dia = getDate(hoje);

    const jogosCopa = [
      // new Date(2024, 25, 3), //grupos
      // new Date(2022, 10, 28), //grupos
      // new Date(2022, 11, 2), //grupos
      // new Date(2022, 11, 5), //oitavas
      // new Date(2022, 11, 9), //quartas
      // new Date(2022, 11, 13), //semi
      // new Date(2022, 11, 18), //final
    ];

    if (jogosCopa.some(d => isToday(d))) {
      this.copa = true;
      this.setMenuColor("#06aa48");
      setTimeout(() => {
        confetti({
          particleCount: 300,
          spread: 80,
          origin: { y: 0.6 },
          disableForReducedMotion: true,
        });
      }, 1000);
    } else {
      this.setMenuColor("#0FCBE1");
      if (mes === 12 && dia <= 25) {
        this.natal = true;
      } else if (mes === 12) {
        this.anoNovo = true;
      }
    }
  },
};
</script>

<style>
.imgVert {
  transform: rotate(50deg);
  -webkit-transform: rotate(50deg);
  -ms-transform: rotate(50deg);
}
.rounded {
  border-radius: 50%;
}

.event {
  position: absolute;
  top: 10px;
  right: 10px;
}
.center {
  height: 75vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
