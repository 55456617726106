import { minutesToMilliseconds, hoursToMilliseconds } from "date-fns";

export const fusoInMili = new Date().getTimezoneOffset() * 60000;

/**
 * @param {Date} Data
 * @return {object} JSON com todos campos da data
 */
export function jsonClock(time) {
  return {
    ano: `${time.getFullYear()}`,
    mes: `${time.getMonth() + 1}`.padStart(2, "0"),
    dia: `${time.getDate()}`.padStart(2, "0"),
    hora: `${time.getHours()}`.padStart(2, "0"),
    min: `${time.getMinutes()}`.padStart(2, "0"),
    sec: `${time.getSeconds()}`.padStart(2, "0"),
  };
}

/**
 * @param {Date} Date
 * @return {string} AAAAMMDD
 */
export function dateToAnoMesDia(date) {
  const ano = `${date.getFullYear()}`;
  const mes = `${date.getMonth() + 1}`.padStart(2, "0");
  const dia = `${date.getDate()}`.padStart(2, "0");

  return `${ano}${mes}${dia}`;
}

/**
 * @param {string}  AAAAMMDD
 * @return {Date} number
 */
export function anoMesDiaToMilliseconds(string) {
  const ano = parseInt(string.substring(0, 4));
  const mes = parseInt(string.substring(4, 6));
  const dia = parseInt(string.substring(6, 8));

  return Date.parse(new Date(ano, mes - 1, dia));
}

/**
 * @param {string}  AAAAMMDD
 * @return {Date} Date
 */
export function stringToDate(stringDate){
  if (!stringDate) {
    return null;
  }
  const ano = parseInt(stringDate.substring(0, 4));
  const mes = parseInt(stringDate.substring(4, 6));
  const dia = parseInt(stringDate.substring(6, 8));

  return new Date(ano, mes - 1, dia);
}

/**
 * @param {Date} Data
 * @return {string} DD/MM/AAAA
 */
export function dateToStringDate(time) {
  const ano = `${time.getFullYear()}`;
  const mes = `${time.getMonth() + 1}`.padStart(2, "0");
  const dia = `${time.getDate()}`.padStart(2, "0");
  return `${dia}/${mes}/${ano}`;
}

export function stringDatetoStringBanco(stringDate){
  if(!stringDate){
    return null
  }

  const dataDigitada = stringDate.split('/')

  const ano = dataDigitada[2]
  const mes = dataDigitada[1].padStart(2,"0")
  const dia = dataDigitada[0].padStart(2,"0")

  return `${ano}${mes}${dia}`
}

/**
 * @param {string}  AAAAMMDD
 * @return {string} DD/MM/AAAA
 */
export function formatDate(string) {
  if (string?.trim().length === 0) {
    return "  /  /    ";
  }

  const ano = string.substring(0, 4);
  const mes = string.substring(4, 6);
  const dia = string.substring(6, 8);

  return `${dia}/${mes}/${ano}`;
}

/**
 * @param {string} DD-MM-AAAA
 * @return {Date} Date
 */
export function calendarDateToDate(string) {
  const [ano, mes, dia] = string.split("-");
  return new Date(ano, mes - 1, dia);
}

/**
 * @param {string} HH:mm
 * @return {Date} Date
 */
export function hourParse(string) {
  const [h, m] = string.split(":");
  return hoursToMilliseconds(parseInt(h)) + minutesToMilliseconds(parseInt(m));
}
