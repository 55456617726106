<template>
  <div>
    <v-container fluid>
      <v-row dense justify="center">
        <v-col
          v-for="card in painelMedico.PESSOA"
          :key="card.SA3_NOME"
          cols="12"
          :class="`d-flex align-center justify-${card.justify} mb-6`"
        >
          <v-expansion-panels>
            <v-expansion-panel width="95%" class="cyan lighten-4">
              <v-expansion-panel-header>
                <v-spacer></v-spacer>
                <v-row>
                  <v-badge
                    class="mt-1"
                    color="primary"
                    :content="card.MEDICOS.length"
                    v-if="card.MEDICOS.length > 0"
                    bordered
                  >
                    {{ card.A3_NOME }}
                  </v-badge>
                  <span v-if="card.MEDICOS.length <= 0">{{
                    card.A3_NOME
                  }}</span>
                  <v-spacer></v-spacer>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content transition="scale-transition">
                <div>
                  <v-data-table
                    :headers="headersPainel"
                    fixed-header
                    dense
                    :items="card.MEDICOS"
                    class="elevation-1"
                    no-data-text="Sem dados..."
                    :items-per-page="card.MEDICOS.length"
                    hide-default-footer
                    :height="
                      card.MEDICOS.length <= 20 ? 250 : card.MEDICOS.length * 10
                    "
                  >
                    <template v-slot:top>
                      <v-toolbar flat color="white">
                        <v-row dense>
                          <v-spacer></v-spacer>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <vue-excel-xlsx
                                :data="card.MEDICOS"
                                :columns="headersRelatExcel"
                                :filename="
                                  `Medicos de ${card.A3_NOME.substring(0, 20)}`
                                "
                                :sheetname="
                                  `Medicos de ${card.A3_NOME.substring(0, 20)}`
                                "
                                class="ml-5 tbExcel"
                              >
                                <v-btn
                                  icon
                                  color="blue darken-2"
                                  class="mr-5 mt-5"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-microsoft-excel</v-icon>
                                </v-btn>
                              </vue-excel-xlsx>
                            </template>
                            <span>Exportar para excel</span>
                          </v-tooltip>
                        </v-row>
                      </v-toolbar>
                    </template>
                  </v-data-table>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogAwait" width="500" persistent>
      <v-card>
        <v-overlay :value="dialogAwait" absolute>
          <!-- <v-progress-circular indeterminate size="64"></v-progress-circular> -->
        </v-overlay>
        <v-card-title>Painel</v-card-title>
        <v-card-subtitle>
          <p style="font-size: 0.7rem">{{ dialogAwait_message }}</p>
          <v-progress-linear v-model="cont_tabs" height="25" color="green">
            <strong>{{ Math.ceil(cont_tabs) }}%</strong>
          </v-progress-linear>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "Painel",
  data: () => ({
    headersRelatExcel: [
      { label: "Codigo", field: "A3_COD" },
      { label: "Nome", field: "A3_NOME" },
      { label: "Especialidade", field: "ZZ1_DESC" },
      { label: "E-mail", field: "A3_EMAIL" },
      { label: "CPF", field: "A3_CGC" },
      { label: "Aniversário", field: "A3_ZANIV" },
      { label: "DDD", field: "A3_DDDTEL" },
      { label: "Telefone", field: "A3_TEL" },
      { label: "Celular", field: "A3_TEL2" },
      { label: "Sercetária", field: "A3_ZSECRET" },
      { label: "Tel. secretaria", field: "A3_ZTELSEC" },
      { label: "Aniv. sercretária", field: "A3_ZANIVIN" },
      { label: "Município", field: "A3_MUN" },
      { label: "Bairro", field: "A3_BAIRRO" },
      { label: "Cep", field: "A3_CEP" },
      { label: "Complemento", field: "A3_COMPLEM" },
      { label: "Endereço", field: "A3_END" },
      { label: "Clínica", field: "A3_NOMCLIN" },
      { label: "CRM", field: "A3_ZREGIST" },
      { label: "Observação", field: "A3_ZOBSERV" },
    ],
    busca: null,
    NrRegBusca: null,
    painel_medico: [],
    dialogAwait: false,
    dialogAwait_message: ``,
    cont_tabs: 0,
    headersPainel: [
      {
        text: "Código",
        value: "A3_COD",
        align: "start",
      },
      {
        text: "Nome",
        value: "A3_NOME",
        align: "start",
        width: 300,
      },
      { text: "Especialidade", value: "ZZ1_DESC" },
      { text: "E-mail", value: "A3_EMAIL" },
      { text: "CPF", value: "A3_CGC" },
      { text: "Aniversário", value: "A3_ZANIV" },
      { text: "DDD", value: "A3_DDDTEL" },
      { text: "Telefone", value: "A3_TEL" },
      { text: "Celular", value: "A3_TEL2" },
      { text: "Sercetária", value: "A3_ZSECRET" },
      { text: "Tel. secretaria", value: "A3_ZTELSEC" },
      { text: "Aniv. sercretária", value: "A3_ZANIVIN" },
      { text: "Município", value: "A3_MUN" },
      { text: "Bairro", value: "A3_BAIRRO" },
      { text: "Cep", value: "A3_CEP" },
      { text: "Complemento", value: "A3_COMPLEM" },
      { text: "Endereço", value: "A3_END", width: 250 },
      { text: "Clínica", value: "A3_NOMCLIN" },
      { text: "CRM", value: "A3_ZREGIST" },
      { text: "Observação", value: "A3_ZOBSERV", width: 350 },
    ],
  }),
  computed: {
    ...mapState(["user", "painelMedico"]),
  },
  methods: {
    async carregaPainel() {
      if (this.painelMedico.PESSOA) {
        return;
      }
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "A3_COD,A3_NOME,A3_MUN,A3_TEL,A3_CEL,A3_ZTIPO",
        WHERE: `A3_ZTIPO IN ('000017','000021','100016','100017') AND A3_MSBLQL='2' ORDER BY A3_NOME`,
        TABELA: "SA3",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => (this.painel_medico.PESSOA = res.data))
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      this.carregaMedicos();
    },
    async carregaMedicos() {
      this.cont_tabs = 0;
      let contador = 0;
      this.dialogAwait = true;
      const total_registros = this.painel_medico.PESSOA.length;
      for (const painel of this.painel_medico.PESSOA) {
        this.dialogAwait_message = `Populando médicos de: ${painel.A3_NOME.trim()}, aguarde...`;
        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `A3_COD,
            A3_NOME,
            ZZ1_DESC,
            A3_EMAIL,
            A3_CGC,
            A3_ZANIV,
            A3_TEL,
            A3_DDDTEL,
            A3_TEL2,
            A3_ZSECRET,
            A3_ZTELSEC,
            A3_ZANIVIN,
            A3_MUN,
            A3_BAIRRO,
            A3_CEP,
            A3_COMPLEM,
            A3_END,
            A3_NOMCLIN,
            A3_ZREGIST,
            CONVERT(VARCHAR(8000), A3_ZOBSERV) A3_ZOBSERV`,
          WHERE: `A3_MSBLQL='2' AND (A3_ZPROSPE='${painel.A3_COD}' OR A3_ZECTC='${painel.A3_COD}') ORDER BY A3_NOME`,
          LEFT: `LEFT JOIN ZZ1010 ZZ1 WITH(NOLOCK) ON ZZ1_COD=A3_ZZESPEC AND ZZ1.D_E_L_E_T_=' '`,
          TABELA: "SA3",
        };

        if (painel.A3_ZTIPO.trim() === "100016") {
          dados.WHERE = `A3_MSBLQL='2' AND A3_ZGERPRO='${painel.A3_COD}' ORDER BY A3_NOME`;
        }

        if (painel.A3_ZTIPO.trim() === "100017") {
          dados.WHERE = `A3_MSBLQL='2' AND A3_ZCOPROS='${painel.A3_COD}' ORDER BY A3_NOME`;
        }

        const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

        await axios
          .post(url, dados)
          .then(res => {
            painel.MEDICOS = res.data;
            contador++;
            this.cont_tabs = (contador * 100) / total_registros;
          })
          .catch(error => {
            console.log(error);
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });
      }

      this.$store.commit("setPainelMedico", this.painel_medico);
      this.dialogAwait = false;
    },
  },
  mounted() {
    this.carregaPainel();
  },
};
</script>

<style></style>
