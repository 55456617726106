<template>
  <v-snackbar
    v-model="snack"
    color="error"
    multi-line="multi-line"
    :timeout="-1"
    bottom="bottom"
  >
    <v-icon small color="#FFF" class="mr-3">mdi-alert-circle</v-icon>
    {{ error }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="snack = false">
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "snackBarError",
  props: ["error", "snack"],
};
</script>

<style></style>
