import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VToolbar,{attrs:{"dense":""}},[_c(VIcon,{staticClass:"mr-5"},[_vm._v("mdi-face-agent")]),_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('span',{staticClass:"body"},[_vm._v("Olá, "),_c('span',{staticStyle:{"color":"blue"}},[_vm._v(_vm._s(_vm.user.name)+".")])]),_c('span',{staticClass:"caption"},[_vm._v("Que bom te ver por aqui :)")])]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"green"},on:{"click":_vm.cardMeusLeads}},[_c(VIcon,[_vm._v("mdi-cached")])],1),_c(VMenu,{attrs:{"offset-y":"","transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAppBarNavIcon,_vm._g(_vm._b({staticClass:"mr-3"},'v-app-bar-nav-icon',attrs,false),on))]}}])},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.botoes),function(item,index){return _c(VListItem,{key:index,attrs:{"disabled":!item.tela},on:{"click":function($event){return _vm.setaTitle(item)}}},[_c(VListItemAction,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1),_c('div',{staticClass:"mt-3 ml-3 d-flex"},_vm._l((_vm.botoes),function(item,i){return _c(VCard,{key:i,staticClass:"pa-3 mr-3 clickPoint",attrs:{"max-width":"400","color":item.color},on:{"click":function($event){return _vm.setaTitle(item)}}},[_c(VCardTitle,{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(item.title))])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }