<template>
  <div class="" style="height: 50vh">
    <v-dialog v-model="dialogAwait" width="500">
      <v-card>
        <v-overlay :value="dialogAwait" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title class="headline grey lighten-2">Email</v-card-title>
        <v-card-subtitle>
          <p class="body-1">Enviando o email...</p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEsqueciSenha" width="30vw">
      <v-card>
        <v-card-title>Esqueci a senha.</v-card-title>
        <v-card-subtitle>Digite o seu e-mail!</v-card-subtitle>
        <v-card-text>
          <div class="d-flex justify-center align-center">
            <v-text-field
              v-model="emailEsqueci"
              label="Digite seu email!"
              placeholder="Digite seu email!"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="error"
            x-small
            @click="
              dialogEsqueciSenha = false;
              emailEsqueci = null;
            "
            >fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="primary" x-small @click="validaEmailBase"
            >solicitar código de verificação</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCodigo" width="30vw" persistent>
      <v-card>
        <v-card-title>Digite o código de verificação.</v-card-title>
        <v-card-subtitle
          >Digite o código de verificação recebido no e-mail!</v-card-subtitle
        >
        <v-card-text>
          <div class="d-flex flex-column justify-center align-center">
            <p>Olá {{ usuario.ZS1_NOME }}</p>
            <span
              >Digite abaixo o código recebido no email:
              {{ usuario.ZS1_EMAIL }}.</span
            >
            <div
              class="d-flex justify-space-between align-center ma-2"
              style="width: 28vw;"
            >
              <v-text-field
                v-model="cod1"
                outlined
                class="mr-2 passCodigo"
                maxlength="6"
                @input="splitCodigo"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="cod2"
                outlined
                class="mr-2 passCodigo"
                maxlength="1"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="cod3"
                outlined
                class="mr-2 passCodigo"
                maxlength="1"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="cod4"
                outlined
                class="mr-2 passCodigo"
                maxlength="1"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="cod5"
                outlined
                class="mr-2 passCodigo"
                maxlength="1"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="cod6"
                outlined
                class="passCodigo"
                maxlength="1"
                hide-details
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex justify-center align-center">
            <span style="font-size: .7rem;"
              >O e-mail não chegou? Lembre-se de olhar sua caixa de span e
              também se há alguma regra cadastrada para o email!</span
            >
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="error"
            x-small
            @click="
              dialogCodigo = false;
              cod1 = null;
              cod2 = null;
              cod3 = null;
              cod4 = null;
              cod5 = null;
              cod6 = null;
            "
            >fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="primary" x-small @click="validaCodigoEmail"
            >Validar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <aside class="login ma-0 pa-4">
      <div class="d-flex flex-row justify-space-between align-center">
        <v-img
          max-height="200px"
          max-width="200px"
          src="@/assets/salesMove.png"
        />

        <v-img max-height="200px" max-width="200px" src="@/assets/opimed.png" />
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-row>
          <!-- <v-col cols="12" md="12">
            <v-text-field
              v-model="user.ZS1_COD"
              v-show="showSignup"
              label="Nome"
              :rules="[rules.required]"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="user.ZZC_SNOME"
              v-show="showSignup"
              label="Sobrenome"
              :rules="[rules.required]"
              dense
            ></v-text-field>
          </v-col> -->
          <v-col cols="12" md="12">
            <v-text-field
              v-model="user.ZS1_COD"
              label="Código"
              :rules="[rules.required]"
              dense
              messages="* Somente números"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="user.ZS1_PASS"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="show1 ? 'text' : 'password'"
              label="Senha"
              v-on:keyup.enter="validaNewUser"
              @click:append="show1 = !show1"
              dense
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" md="12">
            <v-text-field
              v-model="usuarioTela"
              label="Código ou e-mail"
              dense
              color="grey lighten-5"
              class="usuarioemail"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="senhaTela"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              label="Senha"
              @click:append="show1 = !show1"
              dense
              color="grey lighten-5"
              class="usuarioemail"
              hide-details
            ></v-text-field>
          </v-col> -->
        </v-row>
        <div class="d-flex" style="width: 95%;margin-top:-20px;">
          <span
            class="caption blue--text link"
            @click="dialogEsqueciSenha = true"
            >Esqueci a senha</span
          >
        </div>
        <div class="d-flex align-center mt-5" style="width: 95%">
          <v-spacer></v-spacer>
          <v-btn
            class="purple darken-2 text-center"
            dark
            large
            rounded
            width="180"
            height="48"
            @click="validaNewUser"
            v-show="!showSignup"
          >
            entrar
          </v-btn>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <!-- <footer class="authFooter">
        <span class="caption">
          Powered by Sansix &copy; {{ new Date().getFullYear() }}
        </span>
      </footer> -->
      <p class="text-right" style="font-size: 0.7rem">
        Sansix &copy; versão: {{ versao }}
      </p>
    </aside>
    <div class="d-flex justify-center align-center" style="height: 100vh">
      <v-img
        src="@/assets/opimed.png"
        max-width="40vw"
        style="margin-left: 25vw"
      ></v-img>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialogNewUser" persistent max-width="600">
        <v-card class="mx-auto" max-width="800">
          <v-img
            class="center"
            max-height="150px"
            max-width="300px"
            src="../../assets/opimed.png"
          ></v-img>
          <v-card-title style="font-size: 1rem; color: indigo"
            >Olá este é seu primeiro acesso, confirme a senha para liberar seu
            login.</v-card-title
          >
          <v-card-text class="text--primary">
            <v-col cols="12" md="12">
              <v-text-field
                v-model="user.ZS1_COD"
                label="Código"
                :rules="[rules.required]"
                dense
                messages="* Somente números"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="user.ZS1_PASS"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show1 ? 'text' : 'password'"
                label="Senha"
                @click:append="show1 = !show1"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="user.confirmPassword"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show1 ? 'text' : 'password'"
                label="Confirme a senha"
                @click:append="show1 = !show1"
                dense
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn color="green" class="ml-2" text @click="saveUser"
              >Criar acesso</v-btn
            >
          </v-card-text>

          <v-card-actions></v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="dialogNovaSenha" persistent width="30vw">
      <v-card>
        <v-card-title>Digite sua nova senha!</v-card-title>
        <v-card-text>
          <div class="d-flex flex-column justify-center align-center">
            <v-text-field
              v-model="novaSenha"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              label="Nova Senha"
              :type="show3 ? 'text' : 'password'"
              @click:append="show3 = !show3"
              placeholder="digite sua nova senha..."
              maxlength="6"
              counter="6"
            ></v-text-field>
            <v-text-field
              v-model="confNovaSenha"
              :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
              label="confirme a nova Senha"
              :type="show4 ? 'text' : 'password'"
              @click:append="show4 = !show4"
              placeholder="digite novamente sua nova senha..."
              maxlength="6"
              counter="6"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" x-small @click="salvaSenhaNova"
            >Salvar sua nova senha.</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-dialog v-model="dialogSucesso" persistent max-width="600">
        <v-card class="mx-auto">
          <v-img
            class="center"
            max-height="150px"
            max-width="300px"
            src="../../assets/opimed.png"
          ></v-img>
          <v-card-title style="font-size: 1rem; color: indigo"
            >Seu acesso foi criado com sucesso! Feche a tela e faça o
            Login.</v-card-title
          >
          <v-card-actions>
            <v-btn
              color="green"
              class="ml-2"
              text
              @click="dialogSucesso = false"
              >Fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :color="color"
      multi-line="multi-line"
      :bottom="posicao === 'bottom'"
      :top="posicao === 'top'"
    >
      <v-icon small dark class="mr-3">{{ icone }}</v-icon>
      {{ mensagem }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { version } from "../../../package.json";
import { api } from "../../services/api";
import { mapState } from "vuex";

String.prototype.replaceAll =
  String.prototype.replaceAll ||
  function(needle, replacement) {
    return this.split(needle).join(replacement);
  };
export default {
  name: "Auth-page",
  data: function() {
    return {
      show3: false,
      show4: false,
      dialogNovaSenha: false,
      novaSenha: null,
      confNovaSenha: null,
      dialogAwait: false,
      cod1: null,
      cod2: null,
      cod3: null,
      cod4: null,
      cod5: null,
      cod6: null,
      dialogCodigo: false,
      codigo: null,
      usuario: {},
      emailEsqueci: null,
      dialogEsqueciSenha: false,
      versao: version,
      dialogSucesso: false,
      dialogNewUser: false,
      showSignup: false,
      mensagem: "",
      color: "",
      posicao: "",
      icone: "",
      user: { ZS1_COD: null },
      dialog: true,
      show1: false,
      show2: false,
      rules: {
        required: value => !!value || "Obrigatório.",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "E-mail inválido!";
        },
      },
      snackbar: false,
    };
  },
  computed: {
    ...mapState({
      trataAlcada: state => state.menu.trataAlcada,
    }),
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    splitCodigo() {
      const codigo = this.cod1;

      this.cod1 = null;
      this.cod2 = null;
      this.cod3 = null;
      this.cod4 = null;
      this.cod5 = null;
      this.cod6 = null;

      for (let n = 1; n <= codigo.length; n++) {
        if (n == 1) {
          this.cod1 = codigo.slice(n - 1, n);
        }
        if (n == 2) {
          this.cod2 = codigo.slice(n - 1, n);
        }
        if (n == 3) {
          this.cod3 = codigo.slice(n - 1, n);
        }
        if (n == 4) {
          this.cod4 = codigo.slice(n - 1, n);
        }
        if (n == 5) {
          this.cod5 = codigo.slice(n - 1, n);
        }
        if (n == 6) {
          this.cod6 = codigo.slice(n - 1, n);
        }
      }
    },
    async validaCodigoEmail() {
      const codDigitado = `${this.cod1}${this.cod2}${this.cod3}${this.cod4}${this.cod5}${this.cod6}`;

      console.log(this.codigo);
      console.log(codDigitado);

      if (this.codigo !== codDigitado) {
        this.$toast.error(
          "O código digitado não confere com o enviado ao email! Tente novamente.",
          {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          },
        );
        this.dialogAwait = false;
        return;
      }

      this.dialogCodigo = false;
      this.codigo = null;
      this.dialogNovaSenha = true;
    },
    async validaEmailBase() {
      this.usuario = null;
      this.codigo = null;
      this.dialogCodigo = false;
      if (!this.emailEsqueci) return;

      this.dialogAwait = true;

      let url = `${process.env.VUE_APP_BASE_API_URL}/validaEmail`;

      let error = null;

      const resposta = await axios
        .post(url, { email: this.emailEsqueci })
        .catch(err => (error = err.response.data));

      if (error) {
        this.$toast.error(error, {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.dialogAwait = false;
        return;
      }

      this.dialogAwait = false;

      if (!resposta) return;

      this.dialogAwait = true;

      this.codigo = resposta.data.codigoSenha;

      let dados = {
        ZS1_STEMP: this.codigo,
        ZS1_COD: resposta.data.ZS1_COD,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/senhaTemporaria`;

      this.usuario = await axios.post(url, dados);

      if (!this.usuario) {
        this.$toast.error("Aconteceu algum erro!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.dialogAwait = false;
        return;
      }

      this.usuario = resposta.data;

      dados = {
        codigo: this.codigo,
        nome: resposta.data.ZS1_NOME.trim(),
        email: resposta.data.ZS1_EMAIL,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/enviaCodigo`;

      await axios.post(url, dados).then(() => {
        this.$toast.success("E-mail enviado com sucesso!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.dialogAwait = false;
        this.dialogEsqueciSenha = false;
        this.emailEsqueci = null;
        this.dialogCodigo = true;
      });
    },
    removeAcento(text) {
      if (!text) return;
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "A");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "E");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "I");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "O");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "U");
      text = text.replace(new RegExp("[Ç]", "gi"), "C");
      return text.toUpperCase();
    },
    async validaNewUser() {
      const url = `${process.env.VUE_APP_BASE_API_URL}/validaNewUser`;

      await axios
        .post(url, this.user)
        .then(res => {
          //troca de senha
          if (res.data.Valido && res.data.userNew) {
            this.dialogNewUser = true;
          }
          //acesso
          if (res.data.Valido && !res.data.userNew) {
            this.signin();
          }
          //usuario sem cadastro
          if (!res.data.Valido) {
            this.posicao = "bottom";
            this.color = "error";
            this.icone = "mdi-alert-circle";
            this.mensagem = "Código de usuário não encontrado!";
            this.snackbar = true;
          }
        })
        .catch(err => {
          console.log({ err });
          this.posicao = "bottom";
          this.color = "error";
          this.icone = "mdi-alert-circle-outline";
          this.mensagem = err.response?.data ?? err.message;
          this.snackbar = true;
        });
    },
    async salvaSenhaNova() {
      if (!this.novaSenha) return;
      if (!this.confNovaSenha) return;

      if (this.novaSenha !== this.confNovaSenha) {
        this.$toast.error("As senhas não conferem!!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      await api
        .post("/updatePass", {
          ZS1_COD: this.usuario.ZS1_COD,
          ZS1_PASS: this.novaSenha,
        })
        .then(() => {
          this.dialogNovaSenha = false;
          this.dialogSucesso = true;
          this.novaSenha = null;
          this.confNovaSenha = null;
        })
        .catch(error => {
          this.posicao = "bottom";
          this.color = "error";
          this.icone = "mdi-alert-circle-outline";
          this.mensagem = error.message;
          this.snackbar = true;
          return;
        });
    },
    async saveUser() {
      if (
        this.user.ZS1_PASS !== this.user.confirmPassword ||
        !this.user.ZS1_PASS
      ) {
        this.posicao = "bottom";
        this.color = "error";
        this.icone = "mdi-alert-circle-outline";
        this.mensagem = "As senhas não conferem!";
        this.snackbar = true;
        return;
      }

      await api
        .post("/user", {
          ZS1_COD: this.user.ZS1_COD,
        })
        .then(response => {
          console.log(response.status, "usuário válido.");
        })
        .catch(error => {
          this.posicao = "bottom";
          this.color = "error";
          this.icone = "mdi-alert-circle-outline";
          this.mensagem = error.response.data.message;
          this.snackbar = true;
          return;
        });

      await api
        .post("/updatePass", {
          ZS1_COD: this.user.ZS1_COD,
          ZS1_PASS: this.user.ZS1_PASS,
        })
        .then(() => {
          this.dialogNewUser = false;
          this.dialogSucesso = true;
        })
        .catch(error => {
          this.posicao = "bottom";
          this.color = "error";
          this.icone = "mdi-alert-circle-outline";
          this.mensagem = error.message;
          this.snackbar = true;
          return;
        });
    },
    async signin() {
      if (!this.user.ZS1_COD || !this.user.ZS1_PASS) {
        this.posicao = "bottom";
        this.color = "error";
        this.icone = "mdi-alert-circle-outline";
        this.mensagem = "Preencha o Código e a Senha!";
        this.snackbar = true;
        return;
      }

      await axios
        .post(`${process.env.VUE_APP_BASE_API_URL}/signin`, this.user)
        .then(res => {
          this.dialog = false;
          console.log("alçada", this.trataAlcada);
          if (!this.trataAlcada) {
            this.$store.commit("SetTitle", "Home");
            this.$store.commit("SetUser", res.data);
            this.$store.commit("SetLimpaTabs", []);
            this.$store.commit("setTrataAlcada", false);
          } else {
            this.$store.commit("SetTitle", "Home");
            this.$store.commit("SetUser", res.data);
            this.$store.commit("SetLimpaTabs", []);
            this.$store.commit("SetTitle", "Tratamento de alçada");
            this.$store.commit("SetTabs", {
              name: `Alcadas`,
              content: `TrataAlcada`,
            });
            this.$store.commit("SetTitle", "Tratamento de Alçada");
          }
          localStorage.setItem(
            process.env.VUE_APP_USER_KEY,
            JSON.stringify(res.data),
          );
          sessionStorage.setItem(
            process.env.VUE_APP_USER_KEY,
            JSON.stringify(res.data),
          );
          this.validaVersao();
        })
        .catch(err => {
          this.posicao = "bottom";
          this.color = "error";
          this.icone = "mdi-alert-circle";
          this.mensagem = err.response.data;
          this.snackbar = true;
        });
    },

    async validaVersao() {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZB7_VERSAO",
        WHERE: `ZB7_EXPIRA='N' ORDER BY R_E_C_N_O_ DESC`,
        TABELA: "ZB7010",
      };

      const validationVersion = await axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/node/consultas`,
        dados,
      );

      if (!validationVersion.data[0]) return;

      if (validationVersion.data[0].ZB7_VERSAO.trim() > this.versao) {
        document.location.reload();
      }
    },
  },
};
</script>

<style>
#auth img {
  object-fit: cover;
  height: 100vh;
}

#auth .appSnackBar {
  margin: 0px;
}

.background {
  position: absolute;
  top: 0px;
  right: 0px;
  max-width: calc(100vw - 30rem);
  object-fit: cover;
}

.login {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 30rem;
  height: 100vh;
  background-color: white;
  z-index: 100;

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: "center";
  justify-content: "space-between";
}

.authFooter {
  align-self: flex-end;
}

.link:hover {
  cursor: pointer;
}

.v-text-field.passCodigo input {
  font-size: 1.8em;

  text-align: center;
  width: 55px;
  max-height: 25px;
}
</style>
