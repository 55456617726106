<template>
  <div class="pa-4">
    <div class="tableCli">
      <v-data-table
        :headers="HeadersClientes"
        dense
        :items="ItemClientes"
        class="elevation-1"
        no-data-text="Sem dados..."
        fixed-header
        :height="altura"
        :loading="loading"
        :search="search"
        :items-per-page="20"
        :footer-props="{
          disableItemsPerPage: true,
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-row dense>
              <v-col cols="2">
                <v-btn
                  class="mx-5 mt-2"
                  fab
                  x-small
                  dark
                  color="indigo"
                  @click="novo()"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" v-if="NrRegFiltro !== null">
                <span style="font-size: 0.7rem"
                  >Filtro aplicado no <strong>{{ filtroEscolhido }}</strong
                  >, {{ NrRegFiltro }} registro(s) encontrado(s).</span
                >
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  fab
                  color="error"
                  @click="LimpaFiltro()"
                >
                  <v-icon dark class="ml-1">mdi-eraser</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2">
                <v-select
                  v-model="filtroEscolhido"
                  :items="itemsFiltro"
                  label="Escolha o campo"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="digite aqui.."
                  v-model="stringFiltro"
                  :disabled="!filtroEscolhido"
                  v-on:keyup.enter="
                    filtraRegistro(filtroEscolhido, stringFiltro)
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  color="indigo"
                  @click="filtraRegistro(filtroEscolhido, stringFiltro)"
                >
                  <v-icon dark class="ml-1">mdi-account-search</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <!-- <template v-slot:[`body.prepend`]>
          <tr>
            <td v-for="item in HeadersClientes" :key="item.text">
              <v-text-field
                dense
                v-if="item.text === 'Codigo'"
                v-model="A1_COD"
                type="text"
                :placeholder="item.text"
              ></v-text-field>
              <v-text-field
                dense
                v-if="item.text === 'Nome'"
                v-model="A1_NOME"
                type="text"
                :placeholder="item.text"
              ></v-text-field>
              <v-text-field
                dense
                v-if="item.text === 'CNPJ / CPF'"
                v-model="A1_CGC"
                type="text"
                :placeholder="item.text"
              ></v-text-field>
            </td>
          </tr>
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left v-if="item.MSBLQL === 'Liberado'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar cadastro</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="verDados(item)"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                >mdi-eye</v-icon
              >
            </template>
            <span>Visualizar cadastro</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="VerDetalhes(item)"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                >mdi-monitor-dashboard</v-icon
              >
            </template>
            <span>Detalhes</span>
          </v-tooltip>
          <v-tooltip right v-if="item.CLUBE === 'Sim'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-show="item.CLUBE"
                v-bind="attrs"
                v-on="on"
                >mdi-ticket-percent</v-icon
              >
            </template>
            <span>Tem assinatura do clube</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.MSBLQL`]="{ item }">
          <v-chip
            class="ma-2"
            v-if="item.MSBLQL === 'Bloqueado'"
            x-small
            dark
            color="red darken-1"
            >Bloqueado
          </v-chip>
          <v-chip
            class="ma-2"
            v-if="item.MSBLQL === 'Liberado'"
            x-small
            dark
            color="blue"
            >Liberado
          </v-chip>
          <v-chip
            class="ma-2"
            v-if="item.MSBLQL === ' '"
            x-small
            dark
            color="grey darken-1"
            >Vazio
          </v-chip>
        </template>
        <template v-slot:[`item.A1_ZAPAC`]="{ item }">
          <v-chip
            class="ma-2"
            v-if="item.A1_ZAPAC === 'S'"
            x-small
            dark
            color="blue darken-3"
            >APAC
          </v-chip>
        </template>
      </v-data-table>
    </div>
    <div class="dadosCli text-center">
      <v-dialog v-model="DialogDados" width="90%" persistent>
        <v-card v-if="boilerplate" ref="form">
          <v-card-title
            class="headline grey lighten-2"
            v-text="titleDialog"
          ></v-card-title>
          <v-card-text v-if="DialogDados">
            <v-row dense class="mt-5">
              <v-col
                v-for="campo in camposTela"
                :key="campo.ZZP_CAMPO"
                :cols="campo.ZZP_COLS"
              >
                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO]"
                  :label="campo.ZZP_TITULO"
                  v-if="
                    campo.ZZP_TIPO === 'T' &&
                      campo.ZZP_CAMPO.trim() !== 'A1_CEP' &&
                      !camposTelefone.includes(campo.ZZP_CAMPO)
                  "
                  :rules="obrigat(dadosTela[campo.ZZP_CAMPO], campo.ZZP_OBRIGA)"
                  class="text-uppercase"
                  dense
                  :readonly="isEditing && campo.ZZP_VISUAL === '1'"
                ></v-text-field>

                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO]"
                  :label="campo.ZZP_TITULO"
                  v-if="
                    campo.ZZP_TIPO === 'T' &&
                      campo.ZZP_CAMPO.trim() === 'A1_CEP'
                  "
                  v-mask="'#####-###'"
                  :rules="obrigat(dadosTela[campo.ZZP_CAMPO], campo.ZZP_OBRIGA)"
                  @blur="consultaCep(campo)"
                  class="text-uppercase"
                  dense
                  :readonly="isEditing && campo.ZZP_VISUAL === '1'"
                ></v-text-field>

                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO]"
                  :label="campo.ZZP_TITULO"
                  v-if="campo.ZZP_CAMPO === 'A1_TEL'"
                  :rules="obrigat(dadosTela[campo.ZZP_CAMPO], campo.ZZP_OBRIGA)"
                  v-mask="dadosTela['A1_TEL_MASK']"
                  @input="verificaMascara('A1_TEL')"
                  @blur="consultaCep(campo)"
                  class="text-uppercase"
                  dense
                  :readonly="isReading"
                ></v-text-field>

                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO]"
                  :label="campo.ZZP_TITULO"
                  v-if="
                    camposTelefone.includes(campo.ZZP_CAMPO) &&
                      campo.ZZP_CAMPO !== 'A1_TEL'
                  "
                  :rules="obrigat(dadosTela[campo.ZZP_CAMPO], campo.ZZP_OBRIGA)"
                  v-mask="dadosTela[`${campo.ZZP_CAMPO}_MASK`]"
                  @input="verificaMascara(campo.ZZP_CAMPO)"
                  @blur="consultaCep(campo)"
                  class="text-uppercase"
                  dense
                  :readonly="isReading"
                ></v-text-field>

                <v-text-field
                  v-model="dadosTela[campo.ZZP_CAMPO]"
                  :label="campo.ZZP_TITULO"
                  v-if="campo.ZZP_TIPO === 'D'"
                  :rules="obrigat(dadosTela[campo.ZZP_CAMPO], campo.ZZP_OBRIGA)"
                  v-mask="'##/##/####'"
                  @blur="consultaCep(campo)"
                  class="text-uppercase"
                  dense
                  :readonly="isReading"
                ></v-text-field>
                <v-select
                  v-model="dadosTela[campo.ZZP_CAMPO]"
                  :placeholder="dadosTela[campo.ZZP_CAMPO]"
                  :items="campo.ITEMS"
                  :label="campo.ZZP_TITULO"
                  :rules="obrigat(dadosTela[campo.ZZP_CAMPO], campo.ZZP_OBRIGA)"
                  v-if="campo.ZZP_TIPO === 'S'"
                  class="text-uppercase"
                  dense
                  :readonly="
                    isReading || (isEditing && campo.ZZP_VISUAL === '1')
                  "
                ></v-select>
                <v-autocomplete
                  v-model="dadosTela[campo.ZZP_CAMPO]"
                  :placeholder="dadosTela[campo.ZZP_CAMPO]"
                  style="margin-top: -22px"
                  dense
                  :items="itemsTipo"
                  @click="carregaItems(campo)"
                  @focus="carregaItems(campo)"
                  no-data-text="Buscando dados..."
                  :rules="obrigat(dadosTela[campo.ZZP_CAMPO], campo.ZZP_OBRIGA)"
                  filled
                  :label="campo.ZZP_TITULO"
                  background-color="white"
                  v-if="campo.ZZP_TIPO === 'A'"
                  class="text-uppercase"
                  :readonly="
                    isReading || (isEditing && campo.ZZP_VISUAL === '1')
                  "
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-col cols="5">
              <v-progress-linear
                v-model="progress"
                v-if="progress > 0"
                color="blue-grey"
                height="25"
              >
                <template v-slot="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              v-if="!isReading && !isEditing"
              text
              :readonly="desativaBotao"
              @click="salva()"
              >Salvar Novo</v-btn
            >
            <v-btn
              color="primary"
              v-if="!isReading && isEditing"
              text
              :readonly="desativaBotao"
              @click="salva(id)"
              >Salvar Alterações</v-btn
            >
          </v-card-actions>
          <v-divider></v-divider>
          <FollowUp ref="follow" v-if="!isReading && !isNew" />
          <div class="px-3">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="limpaVariaveis">Fechar</v-btn>
          </div>
        </v-card>
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="boilerplate"
          type="table"
          class="mx-auto"
          v-if="!boilerplate"
        ></v-skeleton-loader>
      </v-dialog>
    </div>
    <v-bottom-sheet v-model="sheetMessage">
      <v-sheet class="text-center" :color="colorMessage" height="130px">
        <div class="py-3">
          <v-btn class="mx-2" text fab>
            <v-icon v-text="iconMessage" @click="sheetMessage = false"></v-icon>
          </v-btn>
        </div>
        <div class="py-3" style="font-size: 15px" v-text="message"></div>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-model="dialogSuccessSaveCliente" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">Cliente</v-card-title>
        <v-card-text class="mt-5 title">{{ mensagem }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="limpaVariaveis">fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAwaitSaveCliente" width="500" persistent>
      <v-card>
        <v-overlay :value="dialogAwaitSaveCliente" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title class="headline grey lighten-2">Cliente</v-card-title>
        <v-card-subtitle>
          <p class="body-1">Salvando o Cliente...</p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogErrosCliente" width="900">
      <v-card max-width="900" class="mx-auto">
        <v-card-title class="headline red lighten-2" style="color: white"
          >Erros ao salvar</v-card-title
        >

        <v-card-text>
          <v-list disabled>
            <v-subheader>Erros</v-subheader>
            <v-list-item-group v-model="aError" color="primary">
              <v-list-item v-for="(item, i) in aError" :key="i">
                <v-list-item-icon>
                  <v-icon color="red" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 0.8rem"
                    v-text="item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogErrosCliente = false"
            >fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <Detalhes />
    </v-container>
  </div>
</template>

<script>
import FollowUp from "@/components/Central_CRM/Leads/FollowUp";
import Detalhes from "@/components/Clientes/Detalhes_Cliente";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { mapMutations, mapState } from "vuex";
import { stringToBrDate } from "../../utils/stringParse";
//import { component } from 'vue/types/umd';
export default {
  name: "Clientes-page",
  data: () => {
    return {
      isNew: false,
      NrRegFiltro: null,
      codCli: null,
      lojaCli: null,
      dialogDetalhes: false,
      progress: 0,
      desativaBotao: false,
      indFiltro: "C",
      filtroEscolhido: null,
      id: null,
      aError: [],
      itemsFiltro: ["Código", "Nome", "CPF/CGC"],
      stringFiltro: null,
      dialogErrosCliente: false,
      dialogAwaitSaveCliente: false,
      dialogSuccessSaveCliente: false,
      mensagem: null,
      errosTE: false,
      sheetMessage: false,
      colorMessage: "",
      iconMessage: "",
      titleDialog: "",
      message: "",
      DialogDados: false,
      isReading: false,
      isEditing: false,
      search: null,
      loading: true,
      altura: 0,
      perPage: 0,
      HeadersClientes: [],
      ItemClientes: [],
      itemsTipo: [],
      camposTela: [],
      dadosTela: [],
      cliente: {},
      boilerplate: false,
      dadosCep: [],
      DtInicial: new Date().toISOString().substr(0, 10),
      countObrigatorio: 0,
      cloneDadosTela: null,
      interval: 0,
      A1_COD: "",
      A1_NOME: "",
      A1_CGC: "",
      stringCampos: "",
      top500SA1: [],
      camposTelefone: [
        "A1_TEL",
        "A1_TEL2",
        "A1_CELULAR",
        "A1_ZWHATSA",
        "A1_ZTELCOM",
        "A1_ZTELCEL",
      ],
    };
  },
  components: { Detalhes, FollowUp },
  computed: {
    ...mapState(["metadados", "codLojaCliente", "origem", "user"]),
    DataFormatadaInicial() {
      return moment(this.DtInicial).format("DD/MM/YYYY");
    },
  },
  methods: {
    ...mapMutations(["setOrigem"]),
    LimpaFiltro() {
      this.stringFiltro = null;
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.ItemClientes = cloneDeep(this.top500SA1);
    },
    async filtraRegistro(campo, stringFiltro) {
      if (!campo || !stringFiltro) return;

      let WHERE = "";

      if (campo === "Código") {
        WHERE = `A1_COD LIKE '^${stringFiltro}^'`;
      }

      if (campo === "Nome") {
        WHERE = `A1_NOME LIKE '^${stringFiltro.toUpperCase()}^'`;
      }

      if (campo === "CPF/CGC") {
        WHERE = `A1_CGC LIKE '^${stringFiltro}^'`;
      }

      this.loading = true;
      this.ItemClientes = [];

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: WHERE,
        TABELA: "V_CLIENTES_CLUBE",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.A1_NOME = e.A1_NOME.trim();

            if (e.A1_DTNASC) {
              e.A1_DTNASC = stringToBrDate(e.A1_DTNASC);
            } else {
              e.A1_DTNASC = "  /  /    ";
            }

            this.camposTelefone.forEach(tel => {
              e[tel] = (e[tel] || " ").trim();
              e[`${tel}_MASK`] =
                e[tel].length < 11 ? "(##) ####-####X" : "(##) #####-####";
            });
            e.A1_DDD = (e.A1_DDD || " ").trim();
            e.A1_TEL = `${e.A1_DDD}${e.A1_TEL}`;
            e.A1_ZWHATSA = `${e.A1_DDD}${e.A1_ZWHATSA}`;
          });
          this.ItemClientes = res.data;
          this.NrRegFiltro = this.ItemClientes.length;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      this.loading = false;
    },
    VerDetalhes(item) {
      this.codCli = item.A1_COD;
      this.lojaCli = item.A1_LOJA;
      const componenteFilho = this.$children.find(
        component => component.$options.name === "DetCli",
      );
      componenteFilho.buscaDados(item.A1_COD, item.A1_LOJA, item.A1_NOME);
    },
    verificaMascara(campo) {
      if (this.dadosTela[campo].length === 15) {
        this.dadosTela[`${campo}_MASK`] = "(##) #####-####";
      } else {
        this.dadosTela[`${campo}_MASK`] = "(##) ####-####X";
      }
    },
    obrigat(value, obrigatorio) {
      if (!value && obrigatorio === "1") {
        return ["Campo obrigatório!"];
      }
    },
    queryAndIndeterminate() {
      this.progress = 0;

      this.interval = setInterval(() => {
        if (this.progress === 100) {
          clearInterval(this.interval);
          this.progress = 0;
          return;
          //return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.progress += 5;
      }, 1000);
    },
    //Função para remover caracteres especiais na Notas
    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");
      return text.toUpperCase();
    },
    async consultaCep(dados) {
      if (dados.ZZP_CAMPO.trim() !== "A1_CEP") return;
      const cep = this.dadosTela.A1_CEP;

      if (cep !== "") {
        const validaCep = /^[0-9]{5}-[0-9]{3}$/;

        if (validaCep.test(cep)) {
          delete axios.defaults.headers.common["Authorization"];
          const url = `http://viacep.com.br/ws/${cep}/json`;

          await axios.get(url).then(res => {
            if (res.data.erro) {
              this.dadosCep = [];
            } else {
              this.dadosCep = cloneDeep(res.data);
              this.dadosTela.A1_CEP = null;
              this.dadosTela.A1_END = this.dadosCep.logradouro
                .replaceAll("`", " ")
                .replaceAll("´", " ")
                .replaceAll('"', " ")
                .replaceAll("'", " ")
                .toUpperCase();
              this.dadosTela.A1_BAIRRO = this.dadosCep.bairro
                .replaceAll("`", " ")
                .replaceAll("´", " ")
                .replaceAll('"', " ")
                .replaceAll("'", " ")
                .toUpperCase();
              this.dadosTela.A1_MUN = this.dadosCep.localidade
                .replaceAll("`", " ")
                .replaceAll("´", " ")
                .replaceAll('"', " ")
                .replaceAll("'", " ")
                .toUpperCase();
              this.dadosTela.A1_COD_MUN = this.dadosCep.ibge.substring(2, 7);
              this.dadosTela.A1_EST = this.dadosCep.uf;

              this.dadosTela.A1_CEP = cep;
            }
          });
        }

        const json = localStorage.getItem(process.env.VUE_APP_USER_KEY);
        const userData = JSON.parse(json);
        axios.defaults.headers.common[
          "Authorization"
        ] = `bearer ${userData.token}`;
      }
    },
    async salva(id) {
      this.desativaBotao = true;
      const keysObrigat = [];
      this.camposTela.filter(e => {
        if (e.ZZP_OBRIGA === "1") {
          const campo = e.ZZP_CAMPO.trim();
          keysObrigat.push({
            campo,
          });
        }
      });

      let verifica = true;

      /* inicializadores */
      this.dadosTela["A1_ZAPAC"] = this.dadosTela["A1_ZAPAC"] ?? "N=Nao";

      /* inicio das validacoes */
      this.cloneDadosTela = { ...this.dadosTela };
      const keys = Object.keys(this.dadosTela);

      keysObrigat.forEach(e => {
        if (keys.indexOf(e.campo) < 0) {
          verifica = false;
        }
      });

      if (!verifica) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      this.dialogAwaitSaveCliente = true;

      keys.forEach(e => {
        if (e.trim() !== "") {
          this.cloneDadosTela[e] = this.cloneDadosTela[e]
            ? this.cloneDadosTela[e].toUpperCase()
            : "";
        }
      });

      this.camposTela.forEach(e => {
        let value = null;
        if (e.ZZP_TIPO.trim() === "A") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("-");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
        }
        if (e.ZZP_TIPO.trim() === "S") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("=");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
        }
        if (e.ZZP_TIPO.trim() === "D") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("/");
          this.cloneDadosTela[
            e.ZZP_CAMPO.trim()
          ] = `${value[2]}${value[1]}${value[0]}`;
        }
        if (
          e.ZZP_TIPO.trim() === "T" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          value = this.removeAcento(this.cloneDadosTela[e.ZZP_CAMPO.trim()]);
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value;
        }

        if (e.ZZP_CAMPO.trim() === "A1_TEL") {
          let [ddd, tel] = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split(" ");

          this.cloneDadosTela["A1_DDD"] = ddd.replace("(", "").replace(")", "");
          this.cloneDadosTela["A1_TEL"] = tel.replace("-", "");
        }
        if (e.ZZP_CAMPO.trim() === "A1_ZWHATSA") {
          let [, tel] = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split(" ");

          // this.cloneDadosTela["A1_DDD"] = ddd.replace("(", "").replace(")", "");
          this.cloneDadosTela["A1_ZWHATSA"] = tel.replace("-", "");
        }

        if (e.ZZP_CAMPO.trim() === "A1_CEP") {
          this.cloneDadosTela["A1_CEP"] = this.cloneDadosTela["A1_CEP"].replace(
            "-",
            "",
          );
        }

        if (e.ZZP_CAMPO.trim() === "A1_COD_MUN") {
          this.cloneDadosTela["A1_COD_MUN"] = this.cloneDadosTela[
            "A1_COD_MUN"
          ].split("-")[0];
        }

        if (e.ZZP_CAMPO.trim() === "A1_EST") {
          this.cloneDadosTela["A1_EST"] = this.cloneDadosTela["A1_EST"]
            .split("-")[0]
            .trim();
        }
      });

      this.cloneDadosTela["A1_NOME"] = this.cloneDadosTela["A1_NOME"]?.trim();

      this.cloneDadosTela["A1_END"] = this.cloneDadosTela["A1_END"]?.trim();

      this.cloneDadosTela["A1_COMPLEM"] = this.cloneDadosTela[
        "A1_COMPLEM"
      ]?.trim();

      this.cloneDadosTela["A1_BAIRRO"] = this.cloneDadosTela[
        "A1_BAIRRO"
      ]?.trim();

      this.cloneDadosTela["A1_CONTATO"] = this.cloneDadosTela[
        "A1_CONTATO"
      ]?.trim();

      this.cloneDadosTela["A1_EMAIL"] = this.cloneDadosTela["A1_EMAIL"]?.trim();

      if (this.cloneDadosTela["A1_CEP"].length < 8) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CEP inválido!`;
        this.sheetMessage = true;
        return;
      }

      const data = new Date();

      this.queryAndIndeterminate();

      if (id) {
        //alteracao
        const codigoCli = id.split("-");
        let SA1 = cloneDeep(this.cloneDadosTela);

        SA1.A1_COD = codigoCli[0];
        SA1.A1_LOJA = codigoCli[1];
        SA1.A1_ZZLOG = `${this.user.name.trim()} - ${data
          .toString()
          .substr(0, 25)}`;

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          SA1,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/svCliente`;
        await axios
          .put(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = `Cliente salvo com o código: ${res.data.CODIGO}`;
              this.dialogAwaitSaveCliente = false;
              this.dialogSuccessSaveCliente = true;
              this.progress = 100;
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({ text: `${e}`, icon: "mdi-send" });
              });
              this.dialogAwaitSaveCliente = false;
              this.dialogErrosCliente = true;
              this.progress = 100;
              this.desativaBotao = false;
              return;
            }
          })
          .catch(err => {
            this.aError = [];
            this.aError.push({ text: `${err}`, icon: "mdi-send" });

            this.dialogAwaitSaveCliente = false;
            this.dialogErrosCliente = true;
            this.progress = 100;
            this.desativaBotao = false;
            return;
          });
        this.desativaBotao = false;
      } else {
        this.desativaBotao = true;

        //novo
        let SA1 = cloneDeep(this.cloneDadosTela);
        SA1.USERPOR = this.user.name;

        SA1.A1_ZZLOG = `${this.user.name.trim()} - ${data
          .toString()
          .substr(0, 25)}`;

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          SA1,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/svCliente`;
        await axios
          .post(url, dados)
          .then(res => {
            console.log({ data: res.data });
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = `Cliente salvo com o código: ${res.data.CODIGO ||
                "123456"}`;
              if (SA1.A1_PESSOA === "J") {
                this.mensagem +=
                  " .Todo cadastro de pessoa jurídica deve passar por aprovação da área fiscal e financeira,\n o e-mail foi enviado para os responsáveis. \nAguarde liberação para utilizar o cliente cadastrado.!";
              }
              this.dialogAwaitSaveCliente = false;
              this.dialogSuccessSaveCliente = true;
              this.progress = 100;
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({ text: `${e}`, icon: "mdi-send" });
              });
              this.dialogAwaitSaveCliente = false;
              this.dialogErrosCliente = true;
              this.progress = 100;
              this.desativaBotao = false;
              return;
            }
          })
          .catch(err => {
            console.log(
              `erro no post ${process.env.VUE_APP_BASE_API_URL}/svCliente`,
            );
            console.log(err);
            // this.dialogAwaitSaveCliente = false;
          });
        this.desativaBotao = false;
      }

      this.camposTela.forEach(e => {
        let value = null;
        if (e.ZZP_TIPO.trim() === "D") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()];
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = `${value.substr(
            6,
            2,
          )}/${value.substr(4, 2)}/${value.substr(0, 4)}`;
        }
      });

      this.progress = 100;
      this.montaBrowser();
    },
    limpaVariaveis() {
      this.DialogDados = false;
      this.dialogSuccessSaveCliente = false;
      this.isReading = false;
      this.isEditing = false;

      this.setOrigem("");
    },
    async carregaDados() {
      this.boilerplate = false;
      // let campos = [];
      // let dados = {
      //   EMPRESA: "01",
      //   FILIAL: "0101",
      //   WHERE: "ZZP_MODELO='MD1' AND ZZP_USADO='1' ORDER BY ZZP_ORDEM",
      //   FIELDS: "",
      // };

      // let url = `${process.env.VUE_APP_BASE_API_URL}/modelos`;

      // this.countObrigatorio = null;

      // await axios
      //   .post(url, dados)
      //   .then((res) => {
      //     res.data.forEach((e) => {
      //       if (e.ZZP_CBOX.trim().length > 0) {
      //         const dados = e.ZZP_CBOX.split(";");
      //         e.ITEMS = dados.map((i) => {
      //           return `${i}`;
      //         });
      //       }
      // if (e.ZZP_F3TAB.trim().length > 0) {
      //   this.chamaConsulta(e).then((resp) => {
      //     e.ZZP_ITEMS = resp;
      //   });
      // } else {
      //   e.ZZP_ITEMS = [];
      // }
      //     if (e.ZZP_TIPO.trim() === "D") {
      //       e.MASK = "##/##/####";
      //     }
      //     if (e.ZZP_OBRIGA === "1") {
      //       this.countObrigatorio++;
      //     }
      //   });

      //   campos = res.data;
      // })
      // .catch((err) => console.log(err));

      let SA1 = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === "MD1" && e.ZZP_USADO === "1",
      );

      SA1.forEach(e => {
        e.ZZP_CAMPO = e.ZZP_CAMPO.trim();

        if (e.ZZP_CBOX.trim().length > 0) {
          e.ITEMS = e.ZZP_CBOX.split(";");
        }
        if (e.ZZP_TIPO.trim() === "D") {
          e.MASK = "##/##/####";
        }
        if (e.ZZP_OBRIGA === "1") {
          this.countObrigatorio++;
        }
      });

      if (this.isNew) {
        this.camposTelefone.forEach(tel => {
          this.dadosTela[`${tel}_MASK`] = "(##) #####-####";
        });
      }

      this.camposTela = cloneDeep(SA1);

      this.boilerplate = true;
    },
    async reload(item) {
      item.ITEMS = await this.chamaConsulta(item);

      this.camposTela.map(e => {
        if (e.ZZP_CAMPO.trim() === item.ZZP_CAMPO.trim()) {
          e.ITEMS = item.ITEMS;
        }
      });

      return this.camposTela;
    },
    chamaConsulta(item) {
      let dados = {};
      let items = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: ``,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n]?.trim()]}`;
              } else {
                dados += `${i[campos[n]?.trim()]}-`;
              }
            }
            return dados;
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      return items;
    },
    async montaBrowser() {
      this.filtroEscolhido = null;
      this.stringFiltro = null;
      this.NrRegFiltro = null;
      this.altura = window.screen.availHeight - 300;
      this.loading = true;
      let ZZP = [];
      let dados = {};
      let url = ``;
      //let HeadersClientes = [];
      this.HeadersClientes.push({
        text: "Ações",
        value: "actions",
        align: "center",
        width: 200,
        caption: "title",
      });

      console.log(this.metadados);

      ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD1");

      ZZP.map(e => {
        let width = 0;
        if (e.ZZP_COLS <= 2) {
          width = 90;
        } else if (e.ZZP_COLS <= 3) {
          width = 200;
        } else {
          width = 250;
        }
        const name = `${e.ZZP_CAMPO.trim()}`;
        if (name === "A1_COD") {
          this.HeadersClientes.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: width,
            caption: "title",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["A1_COD"].toLowerCase());
            },
          });
        }
        if (name === "A1_NOME") {
          this.HeadersClientes.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: width,
            caption: "title",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["A1_NOME"].toLowerCase());
            },
          });
        }
        if (name === "A1_CGC") {
          this.HeadersClientes.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: width,
            caption: "title",
            filter: f => {
              return (f + "")
                .toLowerCase()
                .includes(this["A1_CGC"].toLowerCase());
            },
          });
        }
        if (name !== "A1_COD" && name !== "A1_NOME" && name !== "A1_CGC") {
          this.HeadersClientes.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "center",
            width: width,
            caption: "title",
          });
        }
      });

      // let dados = {
      //   EMPRESA: "01",
      //   FILIAL: "0101",
      //   FIELDS: ``,
      //   WHERE: `ZZP_MODELO='MD1' ORDER BY ZZP_ORDEM`,
      //   TABELA: `ZZP`,
      // };

      // let url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      // this.HeadersClientes = [];

      // await axios
      //   .post(url, dados)
      //   .then((res) => {
      //     this.HeadersClientes.push({
      //       text: "Ações",
      //       value: "actions",
      //       align: "center",
      //       width: 200,
      //       caption: "title",
      //     });
      //     res.data.map((e) => {
      //       let width = 0;
      //       if (e.ZZP_COLS <= 2) {
      //         width = 90;
      //       } else if (e.ZZP_COLS <= 3) {
      //         width = 200;
      //       } else {
      //         width = 250;
      //       }
      //       const name = `${e.ZZP_CAMPO.trim()}`;
      //       if (name === "A1_COD") {
      //         this.HeadersClientes.push({
      //           text: e.ZZP_TITULO.trim(),
      //           value: e.ZZP_CAMPO.trim(),
      //           align: "center",
      //           width: width,
      //           caption: "title",
      //           filter: (f) => {
      //             return (f + "")
      //               .toLowerCase()
      //               .includes(this["A1_COD"].toLowerCase());
      //           },
      //         });
      //       }
      //       if (name === "A1_NOME") {
      //         this.HeadersClientes.push({
      //           text: e.ZZP_TITULO.trim(),
      //           value: e.ZZP_CAMPO.trim(),
      //           align: "center",
      //           width: width,
      //           caption: "title",
      //           filter: (f) => {
      //             return (f + "")
      //               .toLowerCase()
      //               .includes(this["A1_NOME"].toLowerCase());
      //           },
      //         });
      //       }
      //       if (name === "A1_CGC") {
      //         this.HeadersClientes.push({
      //           text: e.ZZP_TITULO.trim(),
      //           value: e.ZZP_CAMPO.trim(),
      //           align: "center",
      //           width: width,
      //           caption: "title",
      //           filter: (f) => {
      //             return (f + "")
      //               .toLowerCase()
      //               .includes(this["A1_CGC"].toLowerCase());
      //           },
      //         });
      //       }
      //       if (name !== "A1_COD" && name !== "A1_NOME" && name !== "A1_CGC") {
      //         this.HeadersClientes.push({
      //           text: e.ZZP_TITULO.trim(),
      //           value: e.ZZP_CAMPO.trim(),
      //           align: "center",
      //           width: width,
      //           caption: "title",
      //         });
      //       }
      //     });
      //     ZZP = res.data;
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     if (error.response.status === 401) {
      //       this.$store.commit("SetUser", null);
      //       return;
      //     }
      //   });

      let WHERE = [...new Set(ZZP.map(e => e.ZZP_WHERE.trim()))]
        .filter(where => {
          if (where.trim().length > 0) {
            return where.trim();
          }
        })
        .join(" AND ");

      if (WHERE === "") {
        WHERE = "1=1 ORDER BY A1_NOME";
      }

      // const tabelas = [...new Set(ZZP.map((item) => item.ZZP_TABELA))].join(',');
      this.stringCampos = ZZP.map(meta => `${meta.ZZP_CAMPO.trim()}`).join(",");

      if (this.codLojaCliente) {
        const dadosCli = this.codLojaCliente.split("-");
        const codigoCliente = dadosCli[0];
        const lojaCliente = dadosCli[1];

        WHERE = ` A1_COD = '${codigoCliente}' AND A1_LOJA='${lojaCliente}' ORDER BY A1_NOME`;
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `TOP 500 ${this.stringCampos}`,
        WHERE: `${WHERE}`,
        TABELA: "V_CLIENTES_CLUBE",
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      this.ItemClientes = [];

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.A1_NOME = e.A1_NOME.trim();

            if (e.A1_DTNASC) {
              e.A1_DTNASC = stringToBrDate(e.A1_DTNASC);
            } else {
              e.A1_DTNASC = "  /  /    ";
            }

            this.camposTelefone.forEach(tel => {
              e[tel] = (e[tel] || " ").trim();
              e[`${tel}_MASK`] =
                e[tel].length < 11 ? "(##) ####-####X" : "(##) #####-####";
            });
            e.A1_DDD = (e.A1_DDD || " ").trim();
            e.A1_TEL = `${e.A1_DDD}${e.A1_TEL}`;
            e.A1_ZWHATSA = `${e.A1_DDD}${e.A1_ZWHATSA}`;
          });

          this.ItemClientes = cloneDeep(res.data);
          this.top500SA1 = cloneDeep(res.data);
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      this.perPage = this.ItemClientes.length;
      this.loading = false;

      this.$store.commit("SetCodLojaCliente", null);
    },
    async verDados(item) {
      console.log(item);
      await this.carregaDados();
      this.titleDialog = `Visualizando o cliente: ${item.A1_COD}-${
        item.A1_LOJA
      } ${item.A1_NOME.trim()}`;

      this.dadosTela = cloneDeep(item);
      this.isReading = true;
      this.isEditing = false;
      this.DialogDados = true;
    },
    async editItem(item) {
      this.isNew = false;
      this.id = `${item.A1_COD}-${item.A1_LOJA}`;
      this.titleDialog = `Editando o cliente: ${item.A1_COD}-${
        item.A1_LOJA
      } ${item.A1_NOME.trim()}`;

      this.$store.commit("SetLead_id", `${item.A1_COD}-${item.A1_LOJA}`);
      this.$store.commit("SetLead_nome", item.A1_NOME.trim());
      this.setOrigem("Cliente");

      await this.carregaDados();

      this.dadosTela = cloneDeep(item);
      this.isEditing = true;
      this.isReading = false;
      this.DialogDados = true;

      if (!this.isNew) {
        setTimeout(() => {
          this.fFollowUp();
        }, 300);
      }
    },
    fFollowUp() {
      this.$store.commit("SetAlias", "SA1");
      this.$refs.follow.montaBrowser();
      //this.$store.commit("SetDialogFollowUp", true);
    },
    async carregaItems(item) {
      let dados = {};
      let items = [];
      this.itemsTipo = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: ``,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n]?.trim()]}`;
              } else {
                dados += `${i[campos[n]?.trim()]}-`;
              }
            }
            return dados;
          });
          this.itemsTipo = items;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    async novo() {
      this.dadosTela = [];
      this.isReading = false;
      this.titleDialog = `Cliente novo`;
      this.isNew = true;
      await this.carregaDados();

      this.DialogDados = true;
    },
    Custonfiltro(indice) {
      const search = this.search.toString().toLowerCase();

      if (indice === "N") {
        return this.ItemClientes.filter(i => {
          i["A1_NOME"] === search;
        });
      }
      if (indice === "C") {
        return this.ItemClientes.filter(i => {
          i["A1_COD"] === search;
        });
      }
      if (indice === "F") {
        return this.ItemClientes.filter(i => {
          i["A1_CGC"] === search;
        });
      }
    },
  },
  mounted() {
    this.montaBrowser();
  },
};
</script>

<style>
.v-label {
  font-size: 0.7rem;
}
input {
  font-size: 0.8rem;
}
.text-center {
  font-size: 0.7rem !important;
}
.text-start {
  font-size: 0.7rem !important;
}
tbody tr:nth-of-type(odd) {
  background-color: #d8f6fa;
}
.v-select__selection {
  font-size: 0.8rem !important;
}
</style>
