<template>
  <div class="pa-4">
    <div class="tableCli">
      <v-data-table
        class="elevation-1"
        dense
        fixed-header
        no-data-text="Sem dados..."
        :headers="HeadersMetas"
        :items="ItemMetas"
        :height="altura"
        :loading="loading"
        :search="search"
        :items-per-page="20"
        :footer-props="{
          disableItemsPerPage: true,
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-row dense>
              <v-col cols="2">
                <v-btn
                  class="mx-5 mt-2"
                  fab
                  x-small
                  dark
                  color="indigo"
                  @click="novo()"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" v-if="NrRegFiltro !== null">
                <span style="font-size: 0.7rem"
                  >Filtro aplicado no <strong>{{ filtroEscolhido }}</strong
                  >, {{ NrRegFiltro }} registro(s) encontrado(s).</span
                >
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  fab
                  color="error"
                  @click="LimpaFiltro()"
                >
                  <v-icon dark class="ml-1">mdi-eraser</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2">
                <v-select
                  v-model="filtroEscolhido"
                  :items="itemsFiltro"
                  label="Escolha o campo"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="digite aqui.."
                  v-model="stringFiltro"
                  :disabled="!filtroEscolhido"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn
                  class="mx-5 mt-2"
                  x-small
                  dark
                  color="indigo"
                  @click="filtraRegistro(filtroEscolhido, stringFiltro)"
                >
                  <v-icon dark class="ml-1">mdi-account-search</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar cadastro</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="verDados(item)"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                >mdi-eye</v-icon
              >
            </template>
            <span>Visualizar cadastro</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="dadosCli text-center">
      <v-dialog v-model="DialogDados" width="90%" persistent>
        <v-card v-if="boilerplate" ref="form">
          <v-card-title
            class="headline grey lighten-2"
            v-text="titleDialog"
          ></v-card-title>
          <v-card-text>
            <v-row dense class="mt-5">
              <v-col
                v-for="campo in camposTela"
                :key="campo.ZZP_CAMPO"
                :cols="campo.ZZP_COLS"
              >
                <v-text-field
                  class="text-uppercase"
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  v-if="campo.ZZP_TIPO === 'T'"
                  dense
                  :label="campo.ZZP_TITULO"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  :readonly="isEditing && campo.ZZP_VISUAL === '1'"
                ></v-text-field>

                <v-select
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :items="campo.ITEMS"
                  :label="campo.ZZP_TITULO"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  v-if="campo.ZZP_TIPO === 'S'"
                  class="text-uppercase"
                  dense
                  :readonly="isReading"
                ></v-select>

                <v-autocomplete
                  class="text-uppercase"
                  v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                  v-if="campo.ZZP_TIPO === 'A'"
                  style="margin-top: -22px"
                  background-color="white"
                  dense
                  filled
                  no-data-text="Buscando dados..."
                  @focus="carregaItems(campo)"
                  :items="itemsTipo"
                  :label="campo.ZZP_TITULO"
                  :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                  :value="dadosTela[campo.ZZP_CAMPO]"
                  :rules="
                    obrigat(dadosTela[campo.ZZP_CAMPO.trim()], campo.ZZP_OBRIGA)
                  "
                  :readonly="isReading"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="error" text @click="limpaVariaveis()">Fechar</v-btn>
            <v-spacer></v-spacer>
            <v-col cols="5">
              <v-progress-linear
                v-model="progress"
                v-if="progress > 0"
                color="blue-grey"
                height="25"
              >
                <template v-slot="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              v-if="!isReading && !isEditing"
              text
              @click="salva()"
              >Salvar Novo</v-btn
            >
            <v-btn
              color="primary"
              v-if="!isReading && isEditing"
              text
              @click="salva(id)"
              >Salvar Alterações</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="boilerplate"
          type="table"
          class="mx-auto"
          v-if="!boilerplate"
        ></v-skeleton-loader>
      </v-dialog>
    </div>
    <v-bottom-sheet v-model="sheetMessage">
      <v-sheet class="text-center" :color="colorMessage" height="130px">
        <div class="py-3">
          <v-btn class="mx-2" text fab>
            <v-icon v-text="iconMessage" @click="sheetMessage = false"></v-icon>
          </v-btn>
        </div>
        <div class="py-3" style="font-size: 15px" v-text="message"></div>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-model="dialogSuccessSaveMeta" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">Meta</v-card-title>
        <v-card-text class="mt-5 title">{{ mensagem }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="limpaVariaveis">fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAwaitSaveMeta" width="500">
      <v-card>
        <v-overlay :value="dialogAwaitSaveMeta" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title class="headline grey lighten-2">Meta</v-card-title>
        <v-card-subtitle>
          <p class="body-1">Salvando o Meta...</p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogErrosMeta" width="900">
      <v-card max-width="900" class="mx-auto">
        <v-card-title class="headline red lighten-2" style="color: white"
          >Erros ao salvar</v-card-title
        >

        <v-card-text>
          <v-list disabled>
            <v-subheader>Erros</v-subheader>
            <v-list-item-group v-model="aError" color="primary">
              <v-list-item v-for="(item, i) in aError" :key="i">
                <v-list-item-icon>
                  <v-icon color="red" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 0.8rem"
                    v-text="item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogErrosMeta = false"
            >fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <Detalhes />
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import moment from "moment";
import Detalhes from "@/components/Clientes/Detalhes_Cliente";

export default {
  name: "Metas",
  data: () => {
    return {
      // controle da pagina
      loading: true,
      DtInicial: new Date().toISOString().substr(0, 10),
      stringCampos: "",
      top500ZML: [],

      // tabela de dados
      HeadersMetas: [],
      ItemMetas: [],
      altura: 0,
      search: null,

      // filtro
      itemsFiltro: ["Nome", "AnoMes"],
      filtroEscolhido: null,
      NrRegFiltro: null,
      stringFiltro: null,

      // conteudo da tabela
      camposTela: [],
      DialogDados: false,
      boilerplate: false, //dialog
      titleDialog: "",
      isEditing: false,
      isReading: false,
      dadosTela: [],
      itemsTipo: [],

      // modal
      camposModal: [],
      codVEditado: "",
      progress: 0,
      isNew: false,
      sheetMessage: false,
      colorMessage: "",
      iconMessage: "",
      message: "",
      dialogSuccessSaveMeta: false,
      mensagem: null,
      dialogErrosMeta: false,
      dialogAwaitSaveMeta: false,
      aError: [],
      countObrigatorio: 0,
      cloneDadosTela: null,
      id: null,

      // queryAndIndeterminate
      interval: 0,
    };
  },
  components: { Detalhes },
  computed: {
    ...mapState(["metadados", "codLojaCliente", "origem", "user"]),
    DataFormatadaInicial() {
      return moment(this.DtInicial).format("DD/MM/YYYY");
    },
  },
  methods: {
    ...mapMutations(["setOrigem"]),

    queryAndIndeterminate() {
      this.progress = 0;

      this.interval = setInterval(() => {
        if (this.progress === 100) {
          clearInterval(this.interval);
          this.progress = 0;
          return;
          //return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.progress += 5;
      }, 1000);
    },
    LimpaFiltro() {
      this.stringFiltro = null;
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.ItemMetas = this.top500ZML;
    },
    obrigat(value, obrigatorio) {
      if (!value && obrigatorio === "1") {
        return ["Campo obrigatório!"];
      }
    },
    limpaVariaveis() {
      this.DialogDados = false;
      this.dialogSuccessSaveMeta = false;
      this.isReading = false;
      this.isEditing = false;
      this.setOrigem("");
    },
    verDados(item) {
      this.carregaDados();
      this.titleDialog = `Visualizando meta: ${item.ZML_ANOMES.substring(
        4,
        6,
      )}/${item.ZML_ANOMES.substring(0, 4)} - ${item.ZML_NOMEV.trim()}`;

      this.dadosTela = item;
      this.isReading = true;
      this.isEditing = false;
      this.DialogDados = true;
    },
    editItem(item) {
      this.carregaDados();
      this.titleDialog = `Editando meta: ${
        item.ZML_ID
      } ${item.ZML_ANOMES.substring(4, 6)}/${item.ZML_ANOMES.substring(
        0,
        4,
      )} - ${item.ZML_NOMEV.trim()}`;
      this.id = `${item.ZML_ID}`;
      this.isNew = false;

      this.carregaDados();
      this.dadosTela = { ...item };
      this.codVEditado = item.ZML_CVEND;
      this.isReading = false;
      this.isEditing = true;
      this.DialogDados = true;
    },
    novo() {
      this.dadosTela = [];
      this.isReading = false;
      this.titleDialog = `Nova meta`;
      this.isNew = true;
      this.carregaDados();
      this.DialogDados = true;
    },
    async chamaConsulta(item) {
      let dados = {};
      let items = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos.trim()}`,
        WHERE: ``,
        TABELA: `${item.ZZP_F3TAB}`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      return items;
    },
    async filtraRegistro(campo, stringFiltro) {
      if (!campo || !stringFiltro) return;

      let where = "";

      if (campo === "Nome") {
        where = `ZML_NOMEV LIKE '^${stringFiltro}^'`;
      }

      if (campo === "AnoMes") {
        where = `ZML_ANOMES LIKE '^${stringFiltro}^'`;
      }

      this.loading = true;
      this.ItemMetas = [];

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: where,
        TABELA: "ZML",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      try {
        const res = await axios.post(url, dados);
        this.ItemMetas = res.data.reverse();
        this.NrRegFiltro = this.ItemMetas.length;
      } catch (error) {
        if (error.response.status === 401) {
          this.$store.commit("SetUser", null);
          return;
        }
      }
      this.loading = false;
    },
    async salva(id) {
      const keysObrigat = [];
      let ok = true;

      this.camposTela.forEach(e => {
        if (e.ZZP_OBRIGA === "1") {
          const campo = e.ZZP_CAMPO.trim();
          keysObrigat.push({
            campo,
          });
          ok = ok && !!this.dadosTela[campo];
        }
      });

      this.cloneDadosTela = { ...this.dadosTela };

      if (!ok) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      // keys.forEach((e) => {
      //   this.cloneDadosTela[e] = this.cloneDadosTela[e].toUpperCase();
      // });

      this.camposTela.forEach(e => {
        let value = null;
        const zzpCampo = e.ZZP_CAMPO.trim();

        if (e.ZZP_TIPO.trim() === "A") {
          if (
            this.cloneDadosTela[zzpCampo].includes("-") &&
            zzpCampo === "ZML_NOMEV"
          ) {
            value = this.cloneDadosTela[zzpCampo].split("-");
            this.cloneDadosTela["ZML_CVEND"] = value[0];
            this.cloneDadosTela["ZML_NOMEV"] = value[1];
          }
        }

        if (
          e.ZZP_TIPO.trim() === "S" &&
          zzpCampo === "ZML_TIPOAV" &&
          this.cloneDadosTela[zzpCampo]
        ) {
          value = this.cloneDadosTela[zzpCampo].split("=");
          this.cloneDadosTela[zzpCampo] = value[0];
        }

        if (e.ZZP_TIPO.trim() === "T" && this.cloneDadosTela[zzpCampo]) {
          // value = this.removeAcento(this.cloneDadosTela[zzpCampo]);
          // this.cloneDadosTela[zzpCampo] = value;
        }

        /* campos numéricos */
        if (zzpCampo === "ZML_NOTAAV" || zzpCampo === "ZML_QTDIND") {
          this.cloneDadosTela[zzpCampo] = Number(this.cloneDadosTela[zzpCampo]);
        }

        if (zzpCampo === "ZML_VALOR") {
          const campo = this.cloneDadosTela[zzpCampo];
          this.cloneDadosTela[zzpCampo] = parseFloat(
            campo.replaceAll(".", "").replace(",", "."),
          );
        }
      });

      this.queryAndIndeterminate();

      if (id) {
        let ZML = { ...this.cloneDadosTela };

        ZML.ZML_METAS = parseFloat(ZML.ZML_METAS);

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZML,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/atualizaMeta`;
        await axios
          .put(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = `Meta atualizada com o código: ${res.data.CODIGO}`;
              this.dialogAwaitSaveMeta = false;
              this.dialogSuccessSaveMeta = true;
              this.progress = 100;
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({ text: `${e}`, icon: "mdi-send" });
              });
              this.dialogAwaitSaveMeta = false;
              this.dialogErrosMeta = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => {
            this.aError = [];
            this.aError.push({ text: `${err}`, icon: "mdi-send" });

            this.dialogAwaitSaveMeta = false;
            this.dialogErrosMeta = true;
            this.progress = 100;
            return;
          });

        this.ItemMetas.splice(
          this.ItemMetas.findIndex(e => e.ZML_ID === id),
          1,
          { ...ZML },
        );

        this.top500ZML = { ...this.ItemMetas };
      } else {
        let ZML = { ...this.cloneDadosTela };

        ZML.ZML_METAS = parseFloat(ZML.ZML_METAS);

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZML,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/svMeta`;
        await axios
          .post(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = `Meta salva com o código: ${res.data.CODIGO}`;
              this.dialogAwaitSaveMeta = false;
              this.dialogSuccessSaveMeta = true;
              this.progress = 100;
            } else {
              this.aError = [];
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({ text: `${e}`, icon: "mdi-send" });
              });
              this.dialogAwaitSaveMeta = false;
              this.dialogErrosMeta = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => console.log(err));

        this.ItemMetas.unshift({
          ZML_ID: this.idSalvo,
          ...ZML,
        });
      }

      // formata campo de datas (##/##/##)
      this.camposTela.forEach(e => {
        let value = null;
        if (e.ZZP_TIPO.trim() === "D") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()];
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = `${value.substr(
            6,
            2,
          )}/${value.substr(4, 2)}/${value.substr(0, 4)}`;
        }
      });
      this.progress = 100;
    },
    async carregaDados() {
      this.boilerplate = false;

      const usados = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === "MD023" && e.ZZP_USADO === "1",
      );
      const obrigatorios = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === "MD023" && e.ZZP_OBRIGA === "1",
      );

      usados.forEach(e => {
        if (e.ZZP_CBOX.trim().length > 0) {
          const dados = e.ZZP_CBOX.split(";");
          e.ITEMS = dados.map(i => {
            return `${i}`;
          });
        }
        if (e.ZZP_OBRIGA === "1") {
          this.countObrigatorio++;
        }
      });
      this.camposTela = usados;
      this.camposModal = obrigatorios;
      this.boilerplate = true;
    },
    async reload(item) {
      item.ITEMS = await this.chamaConsulta(item);

      this.camposTela.map(e => {
        if (e.ZZP_CAMPO.trim() === item.ZZP_CAMPO.trim()) {
          e.ITEMS = item.ITEMS;
        }
      });
      return this.camposTela;
    },
    async carregaItems(item) {
      let dados = {};
      let items = [];
      this.itemsTipo = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos.trim()}`,
        WHERE: `${item.ZZP_WHERE}`,
        TABELA: `${item.ZZP_F3TAB}`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
          this.itemsTipo = items;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    async montaBrowser() {
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.stringFiltro = null;
      this.altura = window.screen.availHeight - 300;
      this.loading = true;
      this.HeadersMetas.push({
        text: "Ações",
        value: "actions",
        align: "center",
        width: 200,
        caption: "title",
      });

      let ZZP = [];
      let dados = {};
      let url = ``;

      ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD023");

      ZZP.map(e => {
        let width = 0;
        if (e.ZZP_COLS <= 2) {
          width = 90;
        } else if (e.ZZP_COLS <= 3) {
          width = 200;
        } else {
          width = 250;
        }
        this.HeadersMetas.push({
          text: e.ZZP_TITULO.trim(),
          value: e.ZZP_CAMPO.trim(),
          align: "center",
          width: width,
          caption: "title",
        });
      });
      this.HeadersMetas.push({
        text: "Valor da Metas",
        value: "VALOR_META",
        align: "right",
        width: 200,
      });

      const tabelaAux = [...new Set(ZZP.map(item => item.ZZP_TABELA))];
      this.stringCampos = "TOP 500 ";
      let tabela = tabelaAux[0];
      let cont = 0;
      ZZP.forEach(e => {
        if (e.ZZP_TABELA === tabela) {
          cont++;
        }
      });

      for (let i = 0; i < cont; i++) {
        if (ZZP[i].ZZP_TABELA === tabela) {
          if (i + 1 >= cont) {
            this.stringCampos += `${ZZP[i].ZZP_CAMPO.trim()}`;
          } else {
            this.stringCampos += `${ZZP[i].ZZP_CAMPO.trim()},`;
          }
        }
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: "",
        TABELA: `${tabela.substr(0, 3)}`,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      this.ItemMetas = [];
      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(
            e =>
              (e.VALOR_META = e.ZML_VALOR.toLocaleString("pt-BR", {
                currency: "BRL",
                style: "currency",
              })),
          );
          this.ItemMetas = res.data.reverse();
          this.top500ZML = { ...this.ItemMetas };
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      this.loading = false;
      this.$store.commit("SetCodLojaCliente", null);
    },
  },
  mounted() {
    this.montaBrowser();
  },
};
</script>

<style>
.v-label {
  font-size: 0.7rem;
}
input {
  font-size: 0.8rem;
}
.text-center {
  font-size: 0.7rem !important;
}
.text-start {
  font-size: 0.7rem !important;
}
tbody tr:nth-of-type(odd) {
  background-color: #d8f6fa;
}
.v-select__selection {
  font-size: 0.8rem !important;
}
</style>
