<template>
  <div>
    <v-toolbar dense>
      <v-icon class="mr-5">mdi-face-agent</v-icon>
      <div class="d-flex flex-column justify-center">
        <span class="body"
          >Olá, <span style="color: blue">{{ user.name }}.</span></span
        >
        <span class="caption">Que bom te ver por aqui :)</span>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon color="green" @click="cardMeusLeads">
        <v-icon>mdi-cached</v-icon>
      </v-btn>
      <v-menu offset-y transition="slide-x-transition" bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            v-bind="attrs"
            v-on="on"
            class="mr-3"
          ></v-app-bar-nav-icon>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(item, index) in botoes"
            :key="index"
            @click="setaTitle(item)"
            :disabled="!item.tela"
          >
            <v-list-item-action>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <div>
      <v-row class="pa-2" style="margin-left: -5px">
        <v-col
          v-for="(card, i) in cards"
          :key="i"
          cols="12"
          :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
        >
          <v-card :height="320">
            <div class="d-flex">
              <v-icon class="ml-3">{{ card.icon }}</v-icon>
              <v-card-title class="body-1" v-text="card.title"></v-card-title>
            </div>
            <v-divider
              color="blue"
              class="mr-2 ml-2"
              style="padding-top: 1px"
            ></v-divider>
            <v-card-text class="d-flex justify-space-between">
              <div style="font-variant-caps: all-petite-caps">
                <p
                  v-for="(content, i) in card.content"
                  :key="i"
                  class="linkP"
                  @click="aplicaFiltroTela(card.title, i, card.origem)"
                >
                  <span
                    v-if="!content.danger"
                    class="body-1 ml-2"
                    style="font-weight: bold"
                    >{{ content.count }}</span
                  >
                  <span v-if="!content.danger" class="ml-1">{{
                    content.conteudo
                  }}</span>
                  <span
                    v-if="content.danger"
                    class="title ml-2"
                    style="font-weight: bold"
                    >{{ content.count }}</span
                  >
                  <span
                    v-if="content.danger"
                    class="title ml-1"
                    style="color: red"
                    >{{ content.conteudo }}</span
                  >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="blue"
                        class="ml-1"
                        style="margin-top: -5px"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span>{{ content.help }}</span>
                  </v-tooltip>
                </p>
              </div>
              <div
                class="
                  d-flex
                  align-center
                  flex-column
                  mb-6
                  pa-3
                  elevation-3
                  mr-1
                "
                style="width: 100px; height: 100px; border-radius: 5px"
              >
                <span
                  class="mb-auto display-3"
                  v-if="card.incHoje && card.incHoje.length <= 2"
                  >{{ card.incHoje }}</span
                >
                <span
                  class="mb-auto display-2"
                  v-if="card.incHoje && card.incHoje.length === 3"
                  >{{ card.incHoje }}</span
                >
                <span
                  class="mb-auto display-1"
                  v-if="card.incHoje && card.incHoje.length > 3"
                  >{{ card.incHoje }}</span
                >
                <span style="font-weight: bold">{{ card.label }}</span>
              </div>
              <div
                v-if="card.label1"
                class="
                  d-flex
                  align-center
                  flex-column
                  mb-6
                  pa-3
                  elevation-3
                  mr-1
                "
                style="width: 100px; height: 100px; border-radius: 5px"
              >
                <span
                  class="mb-auto display-3"
                  v-if="card.incHoje1 && card.incHoje1.length <= 2"
                  >{{ card.incHoje1 }}</span
                >
                <span
                  class="mb-auto display-2"
                  v-if="card.incHoje1 && card.incHoje1.length === 3"
                  >{{ card.incHoje1 }}</span
                >
                <span
                  class="mb-auto display-1"
                  v-if="card.incHoje1 && card.incHoje1.length > 3"
                  >{{ card.incHoje1 }}</span
                >
                <span style="font-weight: bold">{{ card.label1 }}</span>
              </div>
            </v-card-text>
            <v-divider
              color="blue"
              class="mr-2 ml-2"
              style="padding-top: 1px"
            ></v-divider>
            <v-card-text class="d-flex justify-space-between align-center">
              <div style="margin-top: -7px">
                <p
                  v-for="(info, i) in card.info"
                  :key="i"
                  class="subtitle-1"
                  style="font-weight: bold"
                >
                  <span class="body-1 ml-2">{{ info.count }}</span>
                  {{ info.conteudo }}
                </p>
              </div>
            </v-card-text>
            <v-overlay :value="card.loading" absolute>
              <v-progress-circular
                indeterminate
                absolute
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <v-tabs v-model="tab_charts" class="pa-2">
      <v-tab> Leads </v-tab>
      <v-tab> Atividades </v-tab>
      <v-tabs-items v-model="tab_charts">
        <v-tab-item>
          <div class="ml-5" v-if="admin">
            <v-radio-group v-model="typeDataChart" row dense>
              <v-radio
                label="Meus dados"
                value="M"
                @click="carregaCharts()"
              ></v-radio>
              <v-radio
                label="Dados da minha equipe"
                value="E"
                @click="carregaCharts()"
              ></v-radio>
            </v-radio-group>
          </div>

          <v-row class="pa-2" style="margin-left: -5px">
            <v-col cols="12" :md="size === 2 ? 6 : size === 3 ? 4 : undefined">
              <v-card :height="400">
                <div class="d-flex">
                  <v-card-title
                    class="caption"
                    v-text="`Leads com responsável e aberto por origem`"
                  ></v-card-title>
                </div>
                <v-divider
                  color="blue"
                  class="mr-2 ml-2"
                  style="padding-top: 1px"
                ></v-divider>
                <v-card-text class="d-flex justify-center align-center">
                  <pie-chart
                    v-if="mostraCharts && semDadosChartOrigem"
                    :chartData="chartDataOrigem"
                    :options="optionsOrigem"
                  />
                  <span v-if="!semDadosChartOrigem && mostraCharts"
                    >Sem dados no momento</span
                  >
                </v-card-text>
                <v-overlay :value="!mostraCharts" absolute>
                  <v-progress-circular
                    indeterminate
                    absolute
                    size="64"
                  ></v-progress-circular>
                </v-overlay>
              </v-card>
            </v-col>
            <v-col cols="12" :md="size === 2 ? 6 : size === 3 ? 4 : undefined">
              <v-card :height="400">
                <div class="d-flex">
                  <v-card-title
                    class="caption"
                    v-text="
                      `Leads com responsável em aberto no dia Vs encerrado no dia`
                    "
                  ></v-card-title>
                </div>
                <v-divider
                  color="blue"
                  class="mr-2 ml-2"
                  style="padding-top: 1px"
                ></v-divider>
                <v-card-text class="d-flex justify-center align-center">
                  <div class="chartBar">
                    <bar-chart
                      v-if="mostraCharts && semDadosChartVs"
                      :chartData="chartDataAbVsFech"
                      :options="optionsAbVsFech"
                    />
                    <span v-if="!semDadosChartVs && mostraCharts"
                      >Sem dados no momento</span
                    >
                  </div>
                </v-card-text>
                <v-overlay :value="!mostraCharts" absolute>
                  <v-progress-circular
                    indeterminate
                    absolute
                    size="64"
                  ></v-progress-circular>
                </v-overlay>
              </v-card>
            </v-col>
            <v-col cols="12" :md="size === 2 ? 6 : size === 3 ? 4 : undefined">
              <v-card :height="400">
                <div class="d-flex">
                  <v-card-title
                    class="caption"
                    v-text="`Leads com responsável e aberto por status`"
                  ></v-card-title>
                </div>
                <v-divider
                  color="blue"
                  class="mr-2 ml-2"
                  style="padding-top: 1px"
                ></v-divider>
                <v-card-text class="d-flex justify-center align-center">
                  <doughnut-chart
                    v-if="mostraCharts && semDadosChartStatus"
                    :chartData="chartDataStatus"
                    :options="optionsOrigem"
                  />
                  <span v-if="!semDadosChartStatus && mostraCharts"
                    >Sem dados no momento</span
                  >
                </v-card-text>
                <v-overlay :value="!mostraCharts" absolute>
                  <v-progress-circular
                    indeterminate
                    absolute
                    size="64"
                  ></v-progress-circular>
                </v-overlay>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <div class="ml-5" v-if="admin">
            <v-radio-group v-model="typeDataChart" row dense>
              <v-radio
                label="Meus dados"
                value="M"
                @click="carregaChartsAtiv()"
              ></v-radio>
              <v-radio
                label="Dados da minha equipe"
                value="E"
                @click="carregaChartsAtiv()"
              ></v-radio>
            </v-radio-group>
          </div>
          <div
            class="pa-2 d-flex justify-space-between"
            style="margin-left: -5px"
          >
            <v-card :height="400" style="width: 33%">
              <div class="d-flex">
                <v-card-title
                  class="caption"
                  v-text="`Atividade(s) por localização`"
                ></v-card-title>
              </div>
              <v-divider
                color="blue"
                class="mr-2 ml-2"
                style="padding-top: 1px"
              ></v-divider>
              <v-card-text class="d-flex justify-center align-center">
                <pie-chart
                  v-if="mostraChartsAtiv && semDadosChartLocaliza"
                  :chartData="chartDataLocaliza"
                  :options="optionsChatsAtiv"
                />
                <span v-if="!semDadosChartLocaliza && mostraChartsAtiv"
                  >Sem dados no momento</span
                >
              </v-card-text>
              <v-overlay :value="!mostraChartsAtiv" absolute>
                <v-progress-circular
                  indeterminate
                  absolute
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-card>
            <v-card :height="400" style="width: 33%">
              <div class="d-flex">
                <v-card-title
                  class="caption"
                  v-text="`Efetividade ação`"
                ></v-card-title>
              </div>
              <v-divider
                color="blue"
                class="mr-2 ml-2"
                style="padding-top: 1px"
              ></v-divider>
              <v-card-text class="d-flex justify-center align-center">
                <pie-chart
                  v-if="mostraChartsAtiv && semDadosChartEfet"
                  :chartData="chartDataEfet"
                  :options="optionsChatsAtiv"
                />
                <span v-if="!semDadosChartEfet && mostraChartsAtiv"
                  >Sem dados no momento</span
                >
              </v-card-text>
              <v-overlay :value="!mostraChartsAtiv" absolute>
                <v-progress-circular
                  indeterminate
                  absolute
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-card>

            <v-card :height="400" style="width: 33%">
              <div class="d-flex">
                <v-card-title
                  class="caption"
                  v-text="`Atividade por responsável`"
                ></v-card-title>
              </div>
              <v-divider
                color="blue"
                class="mr-2 ml-2"
                style="padding-top: 1px"
              ></v-divider>
              <v-card-text class="d-flex justify-center align-center">
                <doughnut-chart
                  v-if="mostraChartsAtiv && semDadosChartPessoa"
                  :chartData="chartDataPessoa"
                  :options="optionsOrigem"
                />
                <span v-if="!semDadosChartPessoa && mostraChartsAtiv"
                  >Sem dados no momento</span
                >
              </v-card-text>
              <v-overlay :value="!mostraChartsAtiv" absolute>
                <v-progress-circular
                  indeterminate
                  absolute
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <div class="d-flex justify-space-around ma-3" v-if="true">
      <!-- <GChart
        v-if="chartDataOrigem.length > 0"
        class="ma-3"
        type="PieChart"
        :data="chartDataOrigem"
        :options="chartOptions.chart1"
      />
      <GChart
        v-if="chartDataAbVsFech.length > 0"
        class="ma-3"
        type="BarChart"
        :data="chartDataAbVsFech"
        :options="chartOptions.chart2"
      />
      <GChart
        v-if="chartDataStatus.length > 0"
        class="ma-3"
        type="PieChart"
        :data="chartDataStatus"
        :options="chartOptions.chart3"
      /> -->
      <!--<GChart
        class="ma-3"
        type="BarChart"
        :data="chartData"
        :options="chartOptions.chart"
      /> -->
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/Central_CRM/template/Charts/Bar";
import DoughnutChart from "@/components/Central_CRM/template/Charts/Doughnut";
import PieChart from "@/components/Central_CRM/template/Charts/Pie";
import axios from "axios";
import { mapState } from "vuex";
//import { GChart } from "vue-google-charts";
//import Chart from 'chart.js'
//import moment from 'moment'
export default {
  name: "CentralCrm",
  data: () => ({
    tab_charts: null,
    typeDataChart: "M",
    admin: false,
    chartDataOrigem: null,
    optionsOrigem: [],
    chartDataAbVsFech: null,
    optionsAbVsFech: null,
    chartDataStatus: null,
    mostraCharts: false,
    mostraChartsAtiv: false,
    semDadosChartOrigem: true,
    semDadosChartVs: true,
    semDadosChartStatus: true,
    semDadosChartLocaliza: true,
    chartDataLocaliza: null,
    optionsChatsAtiv: [],
    chartDataEfet: [],
    dadosAtividades: [],
    oldDadosAtiv: [],
    semDadosChartEfet: true,
    chartDataPessoa: [],
    semDadosChartPessoa: true,
    // chartDataOrigem: [
    //   // ["Year", "Sales", "Expenses", "Profit"],
    //   // ["2014", 1000, 400, 200],
    //   // ["2015", 1170, 460, 250],
    //   // ["2016", 660, 1120, 300],
    //   // ["2017", 1030, 540, 350],
    // ],
    chartOptions: {
      chart1: {
        title: "Leads em aberto por origem",
      },
      chart2: {
        title: "Leads abertos no dia versus encerrados no dia",
      },
      chart3: {
        title: "Leads abertos por Status",
      },
    },
    meusLeads: true,
    size: 3,
    page: 1,
    panel: [0, 1, 2, 3, 4],
    countLeads: null,
    cards: [
      {
        title: "Meus Leads",
        icon: "mdi-account-convert",
        origem: "Leads",
        content: [
          {
            conteudo: "leads",
            count: "",
            help:
              "Leads que estão sob minha responsabilidade e que ainda não foram encerrados!",
          },
          {
            conteudo: "retorno até hoje",
            count: "",
            help:
              "Leads que estão com Stauts = Retornar e que a data seja igual ou inferior a data de hoje",
          },
          {
            conteudo: "retorno nos próximos 7 dias",
            count: "",
            help: "Leads que estão com Stauts = Retornar nos próximos 7 dias.",
          },
          {
            conteudo: "cliente vinculado",
            count: "",
            help:
              "Leads que estão com o cliente vinculado e que ainda não estão encerrados",
          },
        ],
        info: [{ conteudo: "", count: "" }],
        incHoje: null,
        label: "Inc. Hoje",
        loading: true,
      },
      {
        title: "Leads da minha Equipe",
        icon: "mdi-account-reactivate",
        origem: "Leads",
        content: [
          {
            conteudo: "leads sem responsável",
            count: "",
            help: "Leads que estão sem responsável",
          },
          {
            conteudo: "leads em aberto com vínculo de cliente",
            count: "",
            help:
              "Leads que estão com cliente vínculado e com status diferente de encerrado",
          },
          {
            conteudo: "leads qualificados este mês",
            count: "",
            help: "Leads com status = Encerrado e qualificado = Sim no mês",
          },
        ],
        info: [{ conteudo: "", count: "" }],
        incHoje: null,
        incHoje1: null,
        label: "Abertos",
        label1: "Novos",
        loading: true,
      },
      {
        title: "Minhas Atividades",
        origem: "Atividades",
        icon: "mdi-ticket",
        content: [
          {
            conteudo: "Atividades vencendo hoje",
            count: "",
            help: "Atividades que vencem hoje.",
          },
          {
            conteudo: "Atividades vencidas",
            count: "",
            help: "Atividades que estão vencidas.",
            danger: false,
          },
        ],
        info: [{ conteudo: "", count: "" }],
        incHoje: null,
        label: "Total Geral",
        loading: true,
      },
    ],
    atividades: [],
    botoes: [],
    myLeads: [],
    equipLeads: [],
    chartEquipLeads: [],
  }),
  components: { PieChart, DoughnutChart, BarChart },
  computed: {
    ...mapState([
      "metadados",
      "user",
      "dialogFollowUp",
      "isReload",
      "tabs",
      "menuCtCrm",
      "drawer",
      "totalGeralLeads",
    ]),
    CoreDrawer: () => import("@/components/Central_CRM/template/Drawer"),
    CoreAppBar: () => import("@/components/Central_CRM/template/AppBar"),
  },
  methods: {
    async carregaChartsAtiv() {
      this.chartDataEfet = [];

      this.chartDataLocaliza = [];

      this.chartDataPessoa = [];
      this.mostraChartsAtiv = false;
      this.semDadosChartEfet = true;
      this.semDadosChartLocaliza = true;
      this.semDadosChartPessoa = true;

      const colors = [
        "#00ACC1",
        "#388E3C",
        "#D32F2F",
        "#C2185B",
        "#7B1FA2",
        "#512DA8",
        "#303F9F",
        "#006064",
        "#004D40",
        "#8C9EFF",
        "#00ACC1",
        "#64FFDA",
      ];
      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "",
        WHERE: "",
        TABELA: "ZW5",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      this.dadosAtividades = [];
      this.oldDadosAtiv = [];

      await axios
        .post(url, dados)
        .then(res => {
          this.dadosAtividades = res.data;
          this.oldDadosAtiv = res.data;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      this.optionsChatsAtiv = {
        legend: {
          display: true,
          position: "right",
          labels: { fontSize: 9, boxWidth: 20 },
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let percentage;
              let datasets = ctx.chart.data.datasets;
              if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                percentage = Math.round((value / sum) * 100) + "%";
                return percentage;
              } else {
                return percentage;
              }
            },
            color: "#fff",
          },
        },
      };

      this.dadosAtividades = this.oldDadosAtiv;

      //GERANDO O CHART DE LOCALIZAÇÃO ################################################################################
      let dadosFiltro = [];
      if (this.typeDataChart === "E") {
        dadosFiltro = [
          ...new Set(this.dadosAtividades.map(item => item.ZW5_NIVEL1.trim())),
        ];
      } else {
        this.dadosAtividades = this.dadosAtividades.filter(
          e => e.ZW5_RESP === this.user.id,
        );
        dadosFiltro = [
          ...new Set(this.dadosAtividades.map(item => item.ZW5_NIVEL1.trim())),
        ];
      }

      let array = [];

      dadosFiltro.forEach(e => {
        array.push(
          this.dadosAtividades.filter(m => m.ZW5_NIVEL1.trim() === e).length,
        );
      });

      let contador = array.length;

      if (contador <= 0) {
        this.semDadosChartLocaliza = false;
      }

      this.chartDataLocaliza = {
        labels: ["Sem localização", "Não localizado", "Localizado"],
        datasets: [
          {
            label: "# of teste",
            backgroundColor: colors,
            data: array,
            borderWidth: 1,
          },
        ],
      };
      //############################################################################################################

      this.dadosAtividades = [];
      this.dadosAtividades = this.oldDadosAtiv;

      //GERANDO O CHART DE EFETIVIDADE #############################################################################
      dadosFiltro = [];
      //let labels = []

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "",
        WHERE: "",
        TABELA: "ZW7",
      };

      console.log("pesquisando efetiva");
      await axios.post(url, dados).then(res => {
        res.data.forEach(e => {
          this.dadosAtividades.map(a => {
            if (a.ZW5_NIVEL2 === e.ZW7_CODIGO) {
              a.ZW5_NIVEL2 = `${e.ZW7_DESCRI.trim()}`;
            }
          });
        });
      });

      if (this.typeDataChart === "M") {
        this.dadosAtividades = this.dadosAtividades.filter(
          e => e.ZW5_RESP === this.user.id,
        );
      }

      dadosFiltro = [
        ...new Set(this.dadosAtividades.map(item => item.ZW5_NIVEL2.trim())),
      ];

      //dadosFiltro.unshift('Sem efetivação')

      array = [];

      dadosFiltro.forEach(e => {
        array.push(
          this.dadosAtividades.filter(m => m.ZW5_NIVEL2.trim() === e).length,
        );
      });

      contador = array.length;

      if (contador <= 0) {
        this.semDadosChartEfet = false;
      }

      this.chartDataEfet = {
        labels: dadosFiltro,
        datasets: [
          {
            label: "# of teste",
            backgroundColor: colors,
            data: array,
            borderWidth: 1,
          },
        ],
      };

      //############################################################################################################

      this.dadosAtividades = [];
      this.dadosAtividades = this.oldDadosAtiv;
      //GERANDO O CHART DE PESSOAS##################################################################################
      dadosFiltro = [];

      if (this.typeDataChart === "M") {
        console.log("meus dados");
        this.dadosAtividades = this.dadosAtividades.filter(
          e => e.ZW5_RESP === this.user.id,
        );
      }

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "",
        WHERE: "",
        TABELA: "ZS1",
      };

      console.log("pesquisando nomes");
      await axios.post(url, dados).then(res => {
        res.data.forEach(e => {
          this.dadosAtividades.map(a => {
            if (a.ZW5_RESP === e.ZS1_COD) {
              a.ZW5_RESP = `${e.ZS1_NOME.trim()}`;
            }
          });
        });
      });

      dadosFiltro = [
        ...new Set(this.dadosAtividades.map(item => item.ZW5_RESP.trim())),
      ];

      array = [];
      dadosFiltro.forEach(e => {
        array.push(
          this.dadosAtividades.filter(m => m.ZW5_RESP.trim() === e).length,
        );
      });

      contador = array.length;

      if (contador <= 0) {
        this.semDadosChartPessoa = false;
      }

      this.chartDataPessoa = {
        labels: dadosFiltro,
        datasets: [
          {
            label: "# of teste",
            backgroundColor: colors,
            data: array,
            borderWidth: 1,
          },
        ],
      };

      //############################################################################################################
      this.dadosAtividades = this.oldDadosAtiv;
      this.mostraChartsAtiv = true;
    },
    aplicaFiltroTela(card, item, origem) {
      this.$store.commit("removeTabName", "Leads");

      this.$store.commit("setFiltroTela", [{ card, item }]);

      setTimeout(() => {
        this.$store.commit("SetTitle", origem);
        this.$store.commit("SetTabs", {
          name: `${origem}`,
          content: `${origem}`,
        });
        // this.$store.commit("SetTabActive", this.tabs.length - 1);
      }, 200);
    },
    setaTitle(item) {
      this.$store.commit("SetTitle", item.title);
      this.$store.commit("SetTabs", {
        name: `${item.title}`,
        content: `${item.content}`,
      });
      // this.$store.commit("SetTabActive", this.tabs.length - 1);
      this.$store.commit("SetTitle", item.title);

      //this.$router.push({ name: `${item.name}` }).catch(() => {});
    },
    async cardMeusLeads() {
      this.chartDataStatus = [];
      this.chartDataOrigem = [];
      this.chartDataAbVsFech = [];
      this.mostraCharts = false;
      this.cards[0].loading = true;
      this.cards[1].loading = true;
      this.cards[2].loading = true;
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS:
          "ZZQ_DTRET,ZZQ_RESP,ZZQ_CLIENT,ZZQ_STATUS,ZZQ_DTINC,A1_COD,ZZQ_SITUAC,ZZQ_QUALIF,ZZQ_PAG,ZZR_DESCRI,ZZQ_DTENC",
        WHERE: `ZZQ_RESP='${this.user.id}' AND ZZQ_SITUAC<> 'E'`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/leads`;

      let meusLeads = null;
      let meusRetornos = null;
      let meusClientes = null;
      let proximosSeteDias = null;
      let incHoje = null;
      let leads;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            let origem = ``;
            if (e.ZZQ_PAG.trim() === "LIGACAO") {
              origem = `LIGACAO`;
            } else if (e.ZZQ_PAG.trim() === "FACEBOOK") {
              origem = `FACEBOOK`;
            } else {
              origem = `SITE`;
            }
            e.ZZQ_PAG = origem;
            e.ZZQ_DTRET.trim() !== ""
              ? (e.ZZQ_DTRET = `${e.ZZQ_DTRET.substr(
                  6,
                  2,
                )}/${e.ZZQ_DTRET.substr(4, 2)}/${e.ZZQ_DTRET.substr(0, 4)}`)
              : null;
            if (e.ZZR_DESCRI) e.ZZQ_STATUS = `${e.ZZR_DESCRI.toUpperCase()}`;
            if (!e.ZZR_DESCRI) e.ZZQ_STATUS = `SEM STATUS`;
          });
          leads = res.data;
          this.myLeads = res.data;
        })
        .catch(err => console.log(err));

      meusLeads = leads.filter(e => e.ZZQ_RESP === this.user.id);
      meusClientes = leads.filter(e => e.A1_COD && e.A1_COD.trim() !== "");
      meusRetornos = leads.filter(
        e => e.ZZQ_STATUS.trim() === "000007" && this.verdata(e.ZZQ_DTRET),
      );

      proximosSeteDias = leads.filter(e => this.prox7dias(e.ZZQ_DTRET));

      const data = new Date();
      const ano = data.getFullYear().toString();
      let mes = (data.getMonth() + 1).toString();
      let dia = data.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      incHoje = leads.filter(e => e.ZZQ_DTINC === `${ano}${mes}${dia}`);

      this.cards[0].content[0].count = meusLeads.length;
      this.cards[0].content[1].count = meusRetornos.length;
      this.cards[0].content[2].count = proximosSeteDias.length;
      this.cards[0].content[3].count = meusClientes.length;

      this.cards[0].incHoje = incHoje.length.toString();

      this.cards[0].loading = false;

      dados.WHERE = ``;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            let origem = ``;
            if (e.ZZQ_PAG.trim() === "LIGACAO") {
              origem = `LIGACAO`;
            } else if (e.ZZQ_PAG.trim() === "FACEBOOK") {
              origem = `FACEBOOK`;
            } else {
              origem = `SITE`;
            }
            e.ZZQ_PAG = origem;
            e.ZZQ_DTINC.trim() !== ""
              ? (e.ZZQ_DTINC = `${e.ZZQ_DTINC.substr(
                  6,
                  2,
                )}/${e.ZZQ_DTINC.substr(4, 2)}/${e.ZZQ_DTINC.substr(0, 4)}`)
              : null;
            if (e.ZZR_DESCRI) e.ZZQ_STATUS = `${e.ZZR_DESCRI.toUpperCase()}`;
            if (!e.ZZR_DESCRI) e.ZZQ_STATUS = `SEM STATUS`;
          });
          leads = res.data;
          this.equipLeads = res.data;
          this.countLeads = res.data.length;
        })
        .catch(err => console.log(err));

      this.cards[1].content[0].count = leads.filter(
        e => e.ZZQ_SITUAC === "N" && e.ZZQ_SITUAC !== "E",
      ).length;
      this.cards[1].content[1].count = leads.filter(
        e => e.A1_COD && e.A1_COD.trim() !== "" && e.ZZQ_SITUAC !== "E",
      ).length;
      this.cards[1].content[2].count = leads.filter(
        e =>
          e.ZZQ_QUALIF === "1" &&
          this.between(e.ZZQ_DTINC) &&
          e.ZZQ_SITUAC === "E",
      ).length;

      this.cards[1].incHoje = leads
        .filter(e => e.ZZQ_SITUAC === "A")
        .length.toString();

      this.cards[1].incHoje1 = leads
        .filter(e => e.ZZQ_SITUAC === "N")
        .length.toString();

      this.cards[1].loading = false;

      this.meusLeads = false;

      dados.WHERE = `ZZQ_SITUAC = 'A'`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            let origem = ``;
            if (e.ZZQ_PAG.trim() === "LIGACAO") {
              origem = `LIGACAO`;
            } else if (e.ZZQ_PAG.trim() === "FACEBOOK") {
              origem = `FACEBOOK`;
            } else {
              origem = `SITE`;
            }
            e.ZZQ_PAG = origem;
            e.ZZQ_DTRET.trim() !== ""
              ? (e.ZZQ_DTRET = `${e.ZZQ_DTRET.substr(
                  6,
                  2,
                )}/${e.ZZQ_DTRET.substr(4, 2)}/${e.ZZQ_DTRET.substr(0, 4)}`)
              : null;
            if (e.ZZR_DESCRI) e.ZZQ_STATUS = `${e.ZZR_DESCRI.toUpperCase()}`;
            if (!e.ZZR_DESCRI) e.ZZQ_STATUS = `SEM STATUS`;
          });
          this.chartEquipLeads = res.data;
        })
        .catch(err => console.log(err));

      this.Montaatividades();

      this.carregaCharts();

      this.carregaChartsAtiv();
      //setInterval(this.verificaLeads, 60000);
    },
    async Montaatividades() {
      this.atividades = [];
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZW5_ID,ZW5_RESP,ZW5_STATUS,ZW5_DTINI,ZW5_DTFIM,ZW5.R_E_C_N_O_",
        WHERE: `ZW5_STATUS NOT IN ('E') AND ZW5_RESP = '${this.user.id}'`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/campanha`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.ZW5_DTFIM = `${e.ZW5_DTFIM.substr(6, 2)}/${e.ZW5_DTFIM.substr(
              4,
              2,
            )}/${e.ZW5_DTFIM.substr(0, 4)}`;
            e.ZW5_DTINI = `${e.ZW5_DTINI.substr(6, 2)}/${e.ZW5_DTINI.substr(
              4,
              2,
            )}/${e.ZW5_DTINI.substr(0, 4)}`;
          });
          this.atividades = res.data;
        })
        .catch(error => {
          console.log(error);
        });

      this.cards[2].incHoje = this.atividades.length.toString();

      this.cards[2].content[0].count = this.atividades.filter(a =>
        this.verdata1(a.ZW5_DTFIM, "="),
      ).length;

      this.cards[2].content[1].count = this.atividades.filter(a =>
        this.verdata1(a.ZW5_DTFIM, "<"),
      ).length;

      if (this.cards[2].content[1].count > 0) {
        this.cards[2].content[1].danger = true;
      }

      this.cards[2].loading = false;
    },
    verificaLeads() {
      if (this.countLeads < this.totalGeralLeads) {
        this.countLeads = this.totalGeralLeads;
        this.cards[0].loading = true;
        this.cards[1].loading = true;
        this.cardMeusLeads();
      }
    },
    prox7dias(dtRet) {
      const data = new Date();

      if (dtRet) {
        const dataRetorno = dtRet.split("/");
        dtRet = Date.parse(
          `${dataRetorno[2]}-${dataRetorno[1]}-${
            dataRetorno[0]
          } ${data.getHours()}:${data.getMinutes()}:${data.getSeconds()}`,
        );

        const hoje = Date.parse(data);

        let hojeS = new Date();

        const SeteDias = hojeS.setDate(hojeS.getDate() + 7);

        if (dtRet >= hoje && dtRet <= SeteDias) {
          return true;
        }
      } else {
        return false;
      }
    },
    between(data) {
      let dataFormat = data.split("/");
      let dataItem = `${dataFormat[2]}-${dataFormat[1]}-${dataFormat[0]}`;
      let dataParse = Date.parse(dataItem);

      const dataCorrente = new Date();

      let primeiroDia = new Date(
        dataCorrente.getFullYear(),
        dataCorrente.getMonth(),
        1,
      );
      let ultimoDia = new Date(
        dataCorrente.getFullYear(),
        dataCorrente.getMonth() + 1,
        0,
      );

      primeiroDia = Date.parse(primeiroDia);
      ultimoDia = Date.parse(ultimoDia);

      if (dataParse >= primeiroDia && dataParse <= ultimoDia) {
        return true;
      }
    },
    verdata1(data, operador) {
      let dataFormat = data.split("/");
      let dataItem = `${dataFormat[2]}-${dataFormat[1]}-${dataFormat[0]}`;
      let dataParse = Date.parse(dataItem);
      const dataCor = new Date();
      const ano = dataCor.getFullYear().toString();
      let mes = (dataCor.getMonth() + 1).toString();
      let dia = dataCor.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      const hoje = Date.parse(`${ano}-${mes}-${dia}`);

      if (operador === "=") {
        if (dataParse === hoje) {
          return true;
        } else {
          return false;
        }
      }

      if (operador === "<") {
        if (dataParse < hoje) {
          return true;
        } else {
          return false;
        }
      }

      if (operador === "<=") {
        if (dataParse <= hoje) {
          return true;
        } else {
          return false;
        }
      }

      if (operador === ">") {
        if (dataParse > hoje) {
          return true;
        } else {
          return false;
        }
      }

      if (operador === ">=") {
        if (dataParse >= hoje) {
          return true;
        } else {
          return false;
        }
      }
    },
    verdata(data) {
      let dataFormat = data.split("/");
      let dataItem = `${dataFormat[2]}-${dataFormat[1]}-${dataFormat[0]}`;
      let dataParse = Date.parse(dataItem);
      const dataCor = new Date();
      const ano = dataCor.getFullYear().toString();
      let mes = (dataCor.getMonth() + 1).toString();
      let dia = dataCor.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      const hoje = Date.parse(`${ano}-${mes}-${dia}`);
      if (dataParse <= hoje) {
        return true;
      } else {
        return false;
      }
    },
    async carregaCharts() {
      this.chartDataOrigem = [];
      this.optionsOrigem = [];
      this.chartDataAbVsFech = [];
      this.optionsAbVsFech = [];
      this.chartDataStatus = [];
      this.mostraCharts = false;
      this.semDadosChartOrigem = true;
      this.semDadosChartVs = true;
      this.semDadosChartStatus = true;

      const colors = [
        "#00ACC1",
        "#388E3C",
        "#D32F2F",
        "#C2185B",
        "#7B1FA2",
        "#512DA8",
        "#303F9F",
        "#006064",
        "#004D40",
      ];
      let contador = 0;
      if (this.typeDataChart === "M") {
        //Construindo o chart de origens

        const origens = [
          ...new Set(this.myLeads.map(item => item.ZZQ_PAG.trim())),
        ];
        let array = [];

        origens.forEach(e => {
          array.push(
            this.myLeads.filter(
              m => m.ZZQ_PAG.trim() === e && m.ZZQ_SITUAC === "A",
            ).length,
          );
        });

        // this.optionsOrigem = {
        //
        // };
        this.optionsOrigem = {
          legend: {
            display: true,
            position: "right",
            labels: { fontSize: 9, boxWidth: 20 },
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                let percentage;
                let datasets = ctx.chart.data.datasets;
                if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                  let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                  percentage = Math.round((value / sum) * 100) + "%";
                  return percentage;
                } else {
                  return percentage;
                }
              },
              color: "#fff",
            },
          },
        };

        contador = 0;
        array.forEach(e => {
          contador += e;
        });

        if (contador <= 0) {
          this.semDadosChartOrigem = false;
        }

        this.chartDataOrigem = {
          labels: [...origens],
          datasets: [
            {
              label: "# of teste",
              backgroundColor: colors,
              data: array,
              borderWidth: 1,
            },
          ],
        };

        const dataNow = new Date();

        const ano = dataNow.getFullYear().toString();
        let mes = (dataNow.getMonth() + 1).toString();
        let dia = dataNow.getDate().toString();

        if (mes.length < 2) mes = "0" + mes;

        if (dia.length < 2) dia = "0" + dia;

        let Now = `${ano}${mes}${dia}`;
        let Now1 = `${dia}/${mes}/${ano}`;

        const labels = ["A", "E"];

        array = [];

        labels.forEach(e => {
          if (e === "A") {
            array.push(
              this.equipLeads.filter(
                o =>
                  o.ZZQ_RESP === this.user.id &&
                  o.ZZQ_DTINC === Now1 &&
                  o.ZZQ_SITUAC === e,
              ).length,
            );
          } else {
            array.push(
              this.equipLeads.filter(
                o =>
                  o.ZZQ_RESP === this.user.id &&
                  o.ZZQ_DTINC === Now1 &&
                  o.ZZQ_DTENC === Now &&
                  o.ZZQ_SITUAC === e,
              ).length,
            );
          }
        });

        contador = 0;
        array.forEach(e => {
          contador += e;
        });

        if (contador <= 0) {
          this.semDadosChartVs = false;
        }

        this.chartDataAbVsFech = {
          labels: ["EM ABERTO", "ENCERRADO"],
          datasets: [
            {
              barPercentage: 0.5,
              minBarLength: 2,
              label: Now1,
              backgroundColor: colors,
              data: array,
            },
          ],
        };

        this.optionsAbVsFech = {
          responsive: false,
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                let percentage;
                let datasets = ctx.chart.data.datasets;
                if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                  let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                  percentage = Math.round((value / sum) * 100) + "%";
                  return percentage;
                } else {
                  return percentage;
                }
              },
              color: "#fff",
              font: {
                size: 12,
              },
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        };

        //construindo o chart de Status
        const status = [...new Set(this.myLeads.map(item => item.ZZQ_STATUS))];

        console.log(status);

        array = [];

        status.forEach(e => {
          array.push(
            this.myLeads.filter(m => m.ZZQ_STATUS === e && m.ZZQ_SITUAC === "A")
              .length,
          );
        });

        contador = 0;
        array.forEach(e => {
          contador += e;
        });

        if (contador <= 0) {
          this.semDadosChartStatus = false;
        }

        this.chartDataStatus = {
          labels: [...status],
          datasets: [
            {
              backgroundColor: colors,
              data: array,
              borderWidth: 1,
            },
          ],
        };
      }

      if (this.typeDataChart === "E") {
        const origens = [
          ...new Set(this.chartEquipLeads.map(item => item.ZZQ_PAG.trim())),
        ];
        let array = [];

        origens.forEach(e => {
          array.push(
            this.chartEquipLeads.filter(
              m => m.ZZQ_PAG.trim() === e && m.ZZQ_SITUAC === "A",
            ).length,
          );
        });

        contador = 0;
        array.forEach(e => {
          contador += e;
        });

        if (contador <= 0) {
          this.semDadosChartOrigem = false;
        }

        this.chartDataOrigem = {
          labels: [...origens],
          datasets: [
            {
              label: "# of teste",
              backgroundColor: colors,
              data: array,
              borderWidth: 1,
            },
          ],
        };

        const dataNow = new Date();

        const ano = dataNow.getFullYear().toString();
        let mes = (dataNow.getMonth() + 1).toString();
        let dia = dataNow.getDate().toString();

        if (mes.length < 2) mes = "0" + mes;

        if (dia.length < 2) dia = "0" + dia;

        let Now = `${ano}${mes}${dia}`;
        let Now1 = `${dia}/${mes}/${ano}`;

        const labels = ["A", "E"];

        array = [];

        labels.forEach(e => {
          if (e === "A") {
            array.push(
              this.equipLeads.filter(
                o => o.ZZQ_DTINC === Now1 && o.ZZQ_SITUAC === e,
              ).length,
            );
          } else {
            array.push(
              this.equipLeads.filter(
                o =>
                  o.ZZQ_DTINC === Now1 &&
                  o.ZZQ_DTENC === Now &&
                  o.ZZQ_SITUAC === e,
              ).length,
            );
          }
        });

        contador = 0;
        array.forEach(e => {
          contador += e;
        });

        if (contador <= 0) {
          this.semDadosChartVs = false;
        }

        this.chartDataAbVsFech = {
          labels: ["EM ABERTO", "ENCERRADO"],
          datasets: [
            {
              barPercentage: 0.5,
              minBarLength: 2,
              label: Now1,
              backgroundColor: colors,
              data: array,
            },
          ],
        };

        this.optionsOrigem = {
          legend: {
            display: true,
            position: "right",
            labels: { fontSize: 9, boxWidth: 20 },
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                let percentage;
                let datasets = ctx.chart.data.datasets;
                if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                  let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                  percentage = Math.round((value / sum) * 100) + "%";
                  return percentage;
                } else {
                  return percentage;
                }
              },
              color: "#fff",
            },
          },
        };

        this.optionsAbVsFech = {
          responsive: false,
          scales: {
            xAxes: [
              {
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        };

        //construindo o chart de Status
        let status = [
          ...new Set(this.chartEquipLeads.map(item => item.ZZQ_STATUS.trim())),
        ];

        array = [];

        status.forEach(e => {
          array.push(
            this.chartEquipLeads.filter(
              m => m.ZZQ_STATUS.trim() === e && m.ZZQ_SITUAC === "A",
            ).length,
          );
        });

        contador = 0;
        array.forEach(e => {
          contador += e;
        });

        if (contador <= 0) {
          this.semDadosChartStatus = false;
        }

        this.chartDataStatus = {
          labels: [...status],
          datasets: [
            {
              backgroundColor: colors,
              data: array,
              borderWidth: 1,
            },
          ],
        };
      }

      this.mostraCharts = true;
    },
  },
  async mounted() {
    if (
      this.user.isAdmin &&
      this.user.NivelAdmin.map(e => e.trim()).includes("1")
    ) {
      this.admin = true;
    }

    // if(this.user.Leads){
    //   this.telaLeads = true
    // }
    // if(this.user.Campanhas){
    //   this.telaCampanha = true
    // }
    // if(this.user.InvestimentoCRM){
    //   this.telaInvestimento = true
    // }
    // if(this.user.RelatoriosCRM){
    //   this.telaRelCRM = true
    // }

    this.botoes.push(
      {
        title: "Leads administração",
        content: "LeadsAdm",
        tela: this.user.Leads,
      },
      { title: "Leads atendimento", content: "Leads", tela: this.user.Leads },
      { title: "Atividades", content: "Atividades", tela: true },
      {
        title: "Investimento CRM",
        content: "Investimento",
        tela: this.user.InvestimentoCRM,
      },
      {
        title: "Relatórios CRM",
        content: "ReportCampanhaAnuncioMeta",
        tela: this.user.RelatoriosCRM,
      },
      { title: "Campanhas", content: "Campanhas", tela: this.user.Campanhas },
      { title: "WhatsApp", content: "WhatsApp", tela: true },
      { title: "Rel. Agendamento", content: "RelAgendamentos", tela: true },
    );

    this.typeDataChart = "M";
    await this.cardMeusLeads();
  },
};
</script>

<style>
.linkP:hover {
  cursor: pointer;
}
.display-1,
.display-2,
.display-3 {
  font-weight: 400 !important;
}
#pie-chart {
  margin-top: -35px;
}
#bar-chart {
  width: 300px !important;
  height: 300px !important;
}
#doughnut-chart {
  margin-top: -35px;
}
#horizontalbar-chart {
  margin-top: -80px;
}
</style>
