<template>
  <div style="height: 96vh; background-color: #ECEFF1;">
    <v-dialog v-model="editaLead" width="70vw">
      <v-card>
        <v-card-title>Edição de dados do Lead</v-card-title>
        <v-card-subtitle>{{
          leadEditando[0]
            ? `${leadEditando[0].ZZQ_ID} - ${leadEditando[0].ZZQ_NOME}`
            : ""
        }}</v-card-subtitle>
        <v-card-text v-if="leadEditando[0]">
          <div class="d-flex justify-space-around align-center">
            <v-col cols="3">
              <v-text-field
                v-model="leadEditando[0].ZZQ_NOME"
                label="Nome"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-autocomplete
                v-model="leadEditando[0].ZZQ_QUALIF"
                :items="itemsQualificado"
                item-text="text"
                item-value="value"
                label="Qualificado ?"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="leadEditando[0].ZZQ_STATUS"
                :items="itemsStatus"
                item-text="text"
                item-value="value"
                label="Status ?"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="leadEditando[0].ZZQ_EMAIL"
                label="Email"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-text-field
                v-model="leadEditando[0].ZZQ_DDD"
                label="DDD"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="leadEditando[0].ZZQ_TEL"
                label="Telefone"
                @blur="
                  leadEditando[0].ZZQ_TEL1 = leadEditando[0].ZZQ_TEL;
                  leadEditando[0].ZZQ_WHATS = leadEditando[0].ZZQ_TEL;
                "
              ></v-text-field>
            </v-col>
          </div>
          <div class="d-flex justify-space-around align-center">
            <v-col cols="2">
              <v-text-field
                v-model="leadEditando[0].ZZQ_TEL1"
                label="Telefone secundário"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="leadEditando[0].ZZQ_WHATS"
                label="Whats-App"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="leadEditando[0].ZZQ_PESTRA"
                :items="itemsPessoaTrat"
                item-text="text"
                item-value="value"
                label="Pessoa Tratamento ?"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="leadEditando[0].ZZQ_PSTRAT"
                label="Nome pessoa tratamento"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="leadEditando[0].ZZQ_LAUDOM"
                :items="itemsQualificado"
                item-text="text"
                item-value="value"
                label="Laudo médico ?"
              ></v-autocomplete>
            </v-col>
          </div>
          <div class="d-flex justify-space-around align-center">
            <v-col cols="2">
              <v-autocomplete
                v-model="leadEditando[0].ZZQ_APUSER"
                :items="itemsQualificado"
                item-text="text"
                item-value="value"
                label="Já usa aparelho ?"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="leadEditando[0].ZZQ_EST"
                :items="itemsEstado"
                item-text="text"
                item-value="value"
                label="Estado"
                @blur="buscaCidades"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="leadEditando[0].ZZQ_CIDADE"
                :items="itemsCidade"
                item-text="text"
                item-value="value"
                label="Cidade"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="leadEditando[0].ZZQ_DEFEIT"
                label="Desc. do defeito"
              ></v-text-field>
            </v-col>
          </div>
          <v-divider></v-divider>
          <v-expansion-panels class="mt-2">
            <v-expansion-panel class="primary">
              <v-expansion-panel-header class="white--text">
                Follow-Up
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <FollowUp ref="follow" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-btn x-small class="error" @click="editaLead = !editaLead"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn x-small class="primary" @click="salvaLeads">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" persistent width="10vw">
      <div
        class="pa-3 d-flex justify-space-between align-center"
        style="background-color: white"
      >
        <span>Aguarde...</span
        ><v-progress-circular
          indeterminate
          :size="18"
          color="cyan darken-4"
        ></v-progress-circular>
      </div>
    </v-dialog>

    <v-dialog v-model="dialogRetornar" persistent width="20vw">
      <v-card>
        <v-card-title>Digite a data de retorno</v-card-title>
        <v-card-subtitle>{{
          indexEditado > -1 ? blocks[indexEditado].ZZQ_NOME : ""
        }}</v-card-subtitle>
        <v-card-text>
          <div class="d-flex justify-center align-center">
            <v-col cols="6">
              <v-text-field
                v-model="dataRetorno"
                label="Data de retorno"
                dense
                hide-details
                v-mask="'##/##/####'"
              ></v-text-field>
            </v-col>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn x-small class="error" @click="dialogRetornar = !dialogRetornar"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn x-small class="primary" @click="salvaDataRetorno"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAgendar" persistent width="60vw">
      <v-card color="grey lighten-3">
        <v-card-title>Agendar lead</v-card-title>
        <v-card-subtitle>{{
          indexEditado > -1 ? blocks[indexEditado].ZZQ_NOME : ""
        }}</v-card-subtitle>
        <v-card-text>
          <div class="d-flex justify-space-around align-center">
            {{ profissional }}
            <v-col cols="5">
              <v-autocomplete
                v-model="profissional"
                :items="itemsProfissional"
                :loading="loadingProfissional"
                dense
                hide-details
                item-text="text"
                item-value="value"
                label="Escolha o profissional"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="dataAgenda"
                label="Data"
                dense
                v-mask="'##/##/#####'"
                hide-details
              ></v-text-field>
            </v-col>
            <v-btn class="primary" x-small dark @click="buscarHora"
              >Verificar horários disponíveis</v-btn
            >
          </div>
          <v-scale-transition>
            <div
              v-if="bucaHoraDisponivel"
              class="d-flex justify-space-between align-center"
            >
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                interval-minutes="60"
                interval-height="40"
                first-interval="7"
                short-intervals
                interval-count="12"
                type="day"
                locale="pt-BR"
                style="height: 40vh; width: 20vw"
              ></v-calendar>
              <div
                class="d-flex flex-column justify-start align-start ma-5"
                style="width: 40vw"
              >
                <span v-if="!trabalha" class="red--text text--darken-3"
                  >Sem expediente</span
                >
                <span v-else class="blue--text text--darken-3"
                  >Horário de trabalho: {{ horaInicioTrabalho }} até
                  {{ horaFinalTrabalho }}</span
                >
                <v-col cols="12">
                  <v-autocomplete
                    v-model="tituloAgenda"
                    :items="itemsTitulo"
                    dense
                    hide-details
                    item-text="text"
                    item-value="value"
                    label="Título"
                  ></v-autocomplete>
                </v-col>
                <div
                  class="d-flex justify-center align-center"
                  style="width: 35vw"
                >
                  <v-col cols="4">
                    <v-text-field
                      v-model="horaInicioAgenda"
                      label="Hora inicial"
                      v-mask="'##:##'"
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="horaFimAgenda"
                      label="Hora final"
                      v-mask="'##:##'"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </div>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="medicoAgenda"
                    :items="itemsMedicosAgenda"
                    dense
                    hide-details
                    item-text="text"
                    item-value="value"
                    label="Médico"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="notaAgenda"
                    label="Notas"
                    hide-details
                  ></v-text-field>
                </v-col>
              </div>
            </div>
          </v-scale-transition>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="error"
            x-small
            dark
            @click="dialogAgendar = !dialogAgendar"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="primary"
            x-small
            dark
            :disabled="!trabalha"
            @click="salvaAgenda"
            >Salvar agenda</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVinculaCliente" persistent width="50vw">
      <v-card>
        <v-card-title>Vincular cliente da base ao Lead</v-card-title>
        <v-card-subtitle>{{
          indexEditado > -1 ? blocks[indexEditado].ZZQ_NOME : ""
        }}</v-card-subtitle>
        <v-card-text>
          <div class="d-flex justify-center align-center">
            <v-col cols="8">
              <v-autocomplete
                v-model="clienteVinculado"
                :items="itemsClientes"
                :loading="loadingClientes"
                dense
                hide-details
                item-text="text"
                item-value="value"
                label="Escolha o cliente da base"
                v-if="!visualizaCliente"
              ></v-autocomplete>
              <v-text-field
                v-model="visualClienteVinc"
                readonly
                dense
                hide-details
                v-else
              ></v-text-field>
            </v-col>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            x-small
            class="error"
            @click="dialogVinculaCliente = !dialogVinculaCliente"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn x-small class="primary" @click="salvaClienteVinculado"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex justify-space-between align-center py-2 px-6">
      <span class="h4 blue--text text--darken-3">
        <v-badge
          color="blue darken-3"
          :content="blocks.filter(e => e.status !== 'Concluido').length"
        >
          Leads - {{ this.user.name }}
        </v-badge>
      </span>
      <!-- <h4>
        Leads - Evandro Alves de Souza
        <v-badge
          color="black"
          :content="blocks.filter(e => e.status !== 'Concluido').length"
          ><h2 class="white--text">
            {{ stage }}
          </h2></v-badge
        >
      </h4> -->
      <v-col cols="6">
        <v-text-field
          v-model="pesquisa"
          label="Filtar"
          placeholder="digite aqui...."
          hide-details
          dense
          append-outer-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          clearable
          @click:append-outer="pesquisaDados"
        ></v-text-field>
      </v-col>
      <v-btn class="primary" fab small @click="buscaDados"
        ><v-icon>mdi-reload</v-icon></v-btn
      >
    </div>
    <kanban-board
      :stages="statuses"
      :blocks="blocks"
      :state-machine-config="stateMachineConfig"
      @update-block="updateLeadKanban"
    >
      <div
        v-for="stage in statuses"
        :slot="stage"
        :key="stage"
        class="d-flex justify-space-between align-center"
      >
        <v-badge
          v-if="stage === 'Novo'"
          class="mr-3 mt-3"
          color="black"
          :content="blocks.filter(e => e.status === 'Novo').length"
          ><h2 class="white--text">
            {{ stage }}
          </h2></v-badge
        >
        <v-badge
          v-if="stage === 'Em-tratamento'"
          class="mr-3 mt-3"
          color="black"
          :content="blocks.filter(e => e.status === 'Em-tratamento').length"
          ><h2 class="white--text">
            {{ stage }}
          </h2></v-badge
        >
        <v-badge
          v-if="stage === 'Retornar'"
          class="mr-3 mt-3"
          color="black"
          :content="blocks.filter(e => e.status === 'Retornar').length"
          ><h2 class="white--text">
            {{ stage }}
          </h2></v-badge
        >
        <v-badge
          v-if="stage === 'Agendado'"
          class="mr-3 mt-3"
          color="black"
          :content="blocks.filter(e => e.status === 'Agendado').length"
          ><h2 class="white--text">
            {{ stage }}
          </h2></v-badge
        >
        <v-badge
          v-if="stage === 'Concluido'"
          class="mr-3 mt-3"
          color="black"
          :content="blocks.filter(e => e.status === 'Concluido').length"
          ><h2 class="white--text">
            {{ stage }}
          </h2></v-badge
        >
      </div>
      <div v-for="item in blocks" :slot="item.id" :key="item.id">
        <div class="d-flex flex-column">
          <header class="card-header">
            <span class="grid-title font-weight-bold">{{ item.title }} </span>
            <div class="grid-health">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    icon
                    :disabled="item.saude < 92"
                    color="green darken-3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-star</v-icon>
                  </v-btn>
                </template>
                <span>Parabéns!! Dados do lead completo.</span>
              </v-tooltip>
            </div>
            <div class="grid-origin">
              <v-icon v-if="item.origem === 'ZENVIA'">mdi-phone</v-icon>
              <v-icon v-if="item.origem === 'FACEBOOK'">mdi-facebook</v-icon>
              <v-icon v-if="item.origem === 'PRE-AGENDA'">mdi-calendar</v-icon>
              <v-icon v-if="item.origem === 'SITE'">mdi-web</v-icon>
              <span class="text-xs">{{ item.origem }}</span>
            </div>

            <section class="grid-data">
              <span class="text-sm">
                Inclusão: {{ item.ZZQ_DTINC }} - {{ item.ZZQ_HRINC }}
              </span>
              <span class="text-sm"
                >Telefone: {{ `(${item.ZZQ_DDD}) ${item.ZZQ_TEL}` }}</span
              >
            </section>

            <!-- Agenda -->
            <section class="grid-schedule">
              <span
                v-if="item.status === 'Retornar'"
                rounded
                :class="
                  `d-block font-weight-bold ${
                    item.afterReturn ? 'error rounded px-1 white--text' : ''
                  }`
                "
              >
                Retornar: {{ item.ZZQ_DTRET }}
              </span>
              <span
                v-if="item.status === 'Agendado'"
                class="d-block font-weight-bold"
              >
                Agendado: {{ item.dataAgendado }} - {{ item.horaAgendado }}
              </span>
              <div
                v-if="item.status === 'Agendado'"
                class="d-flex flex-column align-center"
              >
                <span
                  v-if="item.statusAgenda === 'A'"
                  class="primary px-1 rounded white--text font-weight-bold"
                >
                  Agendado
                </span>
                <span
                  v-if="item.statusAgenda === 'C'"
                  class="success px-1 rounded white--text font-weight-bold"
                >
                  Compareceu
                </span>
                <span
                  v-if="item.statusAgenda === 'N'"
                  class="error px-1 rounded white--text font-weight-bold"
                >
                  Não compareceu
                </span>
                <span class="py-1 text-xs">{{ item.profissional }}</span>
              </div>
            </section>

            <section class="grid-actions">
              <v-tooltip
                v-if="item.status === 'Agendado'"
                left
                color="green darken-4"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    icon
                    @click="handleEnviarTemplateAgenda(item)"
                    color="green darken-4"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                </template>
                <span>Enviar template</span>
              </v-tooltip>
            </section>
          </header>
          <main class="d-flex flex-column">
            <section class="d-flex justify-space-around align-center">
              <v-tooltip top color="green darken-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    icon
                    :disabled="item.origem !== 'ZENVIA'"
                    :href="item.ZZQ_URLGRV.toLowerCase()"
                    target="_blank"
                    color="red lighten-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-account-voice</v-icon>
                  </v-btn>
                </template>
                <span>Ouvir ligação</span>
              </v-tooltip>
              <v-tooltip top color="green darken-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    icon
                    :disabled="item.status === 'Novo'"
                    @click="abreTelaEdicao(item)"
                    color="red lighten-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar informações</span>
              </v-tooltip>
              <v-tooltip top color="green darken-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    icon
                    color="red lighten-2"
                    :disabled="item.status !== 'Agendado'"
                    @click="verificaAgendamento(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-calendar-account</v-icon>
                  </v-btn>
                </template>
                <span>Inclui agendamento</span>
              </v-tooltip>
              <v-tooltip top color="green darken-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    icon
                    color="red lighten-2"
                    @click="VerDetLeads(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-monitor-dashboard</v-icon>
                  </v-btn>
                </template>
                <span>Detalhamento</span>
              </v-tooltip>
              <v-tooltip top color="green darken-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    icon
                    v-if="item.ZZQ_CLIENT.trim() === ''"
                    @click="abreDialogVinculaCliente(item)"
                    color="red lighten-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-account-arrow-right</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    icon
                    v-else
                    color="green darken-2"
                    @click="abreVinculoCliente(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-account-check</v-icon>
                  </v-btn>
                </template>
                <span>Vincular/visualizar cliente</span>
              </v-tooltip>
            </section>

            <!-- Saúde -->
            <section>
              <v-tooltip top color="green darken-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-progress-linear
                    v-model="item.saude"
                    :color="validaSaude(item)"
                    height="5"
                    v-bind="attrs"
                    v-on="on"
                  ></v-progress-linear>
                </template>
                <span>{{ `Saúde dos dados em ${item.saude}%` }}</span>
              </v-tooltip>
            </section>

            <!-- Interesse -->
            <section
              class="d-flex flex-column align-center justify-space-between pa-1 ma-2"
              style="border: 1px solid #BDBDBD; border-radius: 5px;"
            >
              <span style="font-weight: bold; font-size: .7rem;"
                >Interesse para futuras campanhas!</span
              >
              <v-radio-group
                v-model="item.ZZQ_INTERO"
                row
                dense
                hide-details
                style="margin-top: -05px;"
              >
                <v-radio label="Baixo" value="B" color="red darken-1"></v-radio>
                <v-radio
                  label="Médio"
                  value="M"
                  color="orange darken-1"
                ></v-radio>
                <v-radio
                  label="Alto"
                  value="A"
                  color="green darken-1"
                ></v-radio>
              </v-radio-group>

              <!-- <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small v-bind="attrs" v-on="on" color="red darken-3" dark :v-if="item.ZZQ_INTERO !== 'B'">Baixo</v-btn>
                  </template>
                  <span>Nível de interesse da Opimed no Lead para futuras campanhas!</span>
                </v-tooltip> -->
              <!-- <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small v-bind="attrs" v-on="on" color="orange darken-3" dark :v-if="item.ZZQ_INTERO === 'M'">Médio</v-btn>
                  </template>
                  <span>Nível de interesse da Opimed no Lead para futuras campanhas!</span>
                </v-tooltip> -->
              <!-- <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small v-bind="attrs" v-on="on" color="green darken-3" dark :v-if="item.ZZQ_INTERO !== 'A'">Alto</v-btn>
                  </template>
                  <span>Nível de interesse da Opimed no Lead para futuras campanhas!</span>
                </v-tooltip> -->
            </section>
          </main>
        </div>
      </div>
    </kanban-board>
    <Detalhes />
    <v-snackbar
      v-model="snackbar"
      :color="colorSnackbar"
      :timeout="timeSnackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Detalhes from "@/components/Central_CRM/Leads/Detalhes_Leads";
import FollowUp from "@/components/Central_CRM/Leads/FollowUp";
import { stringDatetoStringBanco, stringToDate } from "@/utils/dateParse";
import axios from "axios";
import { compareDesc, format, getISODay } from "date-fns";
import { mapState } from "vuex";
// import { deepClone } from 'lodash'

export default {
  name: "app",
  components: { FollowUp, Detalhes },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      pesquisa: null,
      visualClienteVinc: null,
      visualizaCliente: false,
      dialogVinculaCliente: false,
      clienteVinculado: null,
      itemsClientes: [],
      loadingClientes: false,
      bucaHoraDisponivel: false,
      horaInicioAgenda: null,
      horaFimAgenda: null,
      medicoAgenda: null,
      itemsMedicosAgenda: [],
      notaAgenda: null,
      tituloAgenda: null,
      itemsTitulo: [],
      events: [],
      focus: "",
      horaInicioTrabalho: null,
      horaFinalTrabalho: null,
      dialogAgendar: false,
      loadingProfissional: false,
      dataAgenda: null,
      profissional: null,
      localAgenda: null,
      itemsProfissional: [],
      trabalha: false,
      dialogRetornar: false,
      dataRetorno: null,
      snackbar: false,
      colorSnackbar: null,
      timeSnackbar: null,
      text: null,
      itemsQualificado: [
        { text: "Sim", value: "1" },
        { text: "Não", value: "2" },
      ],
      itemsStatus: [
        { value: "000000", text: "Outras informações" },
        { value: "000001", text: "Reagendar" },
        { value: "000002", text: "Atendimento realizado" },
        { value: "000003", text: "Perdido" },
        { value: "000004", text: "Lojas Opimed" },
        { value: "000005", text: "Outras marcas" },
        { value: "000006", text: "Ligou errado" },
        { value: "000007", text: "Retornar" },
        { value: "000008", text: "Outras informacoes" },
        { value: "000009", text: "Teste" },
        { value: "000010", text: "Agendado" },
        { value: "000011", text: "Agendamento online" },
        { value: "000012", text: "Não responde contato" },
        { value: "000013", text: "Número incorreto" },
      ],
      itemsPessoaTrat: [
        { text: "Familiar", value: "F" },
        { text: "O mesmo", value: "M" },
        { text: "Amigo", value: "A" },
      ],
      itemsCidade: [],
      itemsEstado: [],
      leadEditando: [],
      editaLead: false,
      loading: false,
      indexEditado: -1,
      statuses: ["Novo", "Em-tratamento", "Retornar", "Agendado", "Concluido"],
      blocks: [],
      blocksOld: [],
      stateMachineConfig: {
        id: "kanban",
        initial: "Novo",
        states: {
          Novo: {
            on: {
              PICK_UP: "Em-tratamento",
            },
          },
          "Em-tratamento": {
            on: {
              RELINQUISH_TASK: "Novo",
              PUSH_TO_QA: "Retornar",
              PASS_QA: "Agendado",
              PICK_UP: "Concluido",
            },
          },
          Retornar: {
            on: {
              REQUEST_CHANGE: "Em-tratamento",
              PASS_QA: "Agendado",
              PICK_UP: "Concluido",
            },
          },
          Agendado: {
            on: {
              REQUEST_CHANGE: "Retornar",
              PASS_QA: "Concluido",
            },
          },
          Concluido: {
            on: {
              // REQUEST_CHANGE: "Agendado",
            },
          },
          approved: {
            type: "final",
          },
        },
      },
      camposSaude: [
        "ZZQ_QUALIF",
        "ZZQ_STATUS",
        "ZZQ_EMAIL",
        "ZZQ_TEL",
        "ZZQ_TEL1",
        "ZZQ_WHATS",
        "ZZQ_PESTRA",
        "ZZQ_PSTRAT",
        "ZZQ_LAUDOM",
        "ZZQ_APUSER",
        "ZZQ_EST",
        "ZZQ_CIDADE",
        "ZZQ_DEFEIT",
        "ZZQ_DDD",
      ],
    };
  },
  async mounted() {
    this.buscaDados();
  },
  methods: {
    pesquisaDados() {
      this.loading = true;
      this.indexEditado = -1;

      this.blocks = this.blocksOld;

      if (this.pesquisa && this.pesquisa.trim().length > 0) {
        this.blocks = this.blocksOld.filter(e => {
          return Object.keys(e)
            .map(k => {
              return `${e[k]}`.includes(this.pesquisa.toUpperCase());
            })
            .reduce((b, t) => t || b);
        });
      }
      this.loading = false;
    },

    async buscaDados() {
      let user = this.user.id;
      if (user === "000000") {
        user = "000004";
      }

      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const status = await axios.post(url, {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZZR_COD, RTRIM(ZZR_DESCRI) ZZR_DESCRI",
        TABELA: "ZZR",
      });

      this.itemsStatus = status.data.map(s => {
        return {
          text: s.ZZR_DESCRI,
          value: s.ZZR_COD,
        };
      });

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ZZQ_ID,ZZQ_HRINC,ZZQ_DTINC,ZZQ_DTRET,ZZQ_PAG,ZZQ_URLGRV,ZZQ_NOME,ZZQ_QUALIF,ZZQ_STATUS,ZZQ_EMAIL,
                 ZZQ_TEL,ZZQ_TEL1,ZZQ_WHATS,ZZQ_PESTRA,ZZQ_PSTRAT,ZZQ_KANBAN,ZZQ_CLIENT,ZZQ_DTAGEN,ZZQ_HRAGEN,
                 ZZQ_LAUDOM,ZZQ_APUSER,ZZQ_EST,ZZQ_CIDADE,ZZQ_DDD,ZZQ_INTERO,
                 CONVERT(VARCHAR(MAX), ZZQ_DEFEIT) ZZQ_DEFEIT `,
        WHERE: `ZZQ_RESP='${user}' AND ZZQ_SITUAC='A'
                AND (ZZQ_DTENC >= CONVERT(VARCHAR(8), getDate(),112) OR ZZQ_DTENC='')
                ORDER BY ZZQ_DTINC`,
        TABELA: "ZZQ",
      };

      const leads = await axios.post(url, dados);

      this.blocks = leads.data.map(e => {
        /* formatações */
        e.ZZQ_DTINC =
          e.ZZQ_DTINC.trim() !== ""
            ? format(stringToDate(e.ZZQ_DTINC), "dd/MM/yyyy")
            : "";

        let origem;
        switch (e.ZZQ_PAG.trim()) {
          case "FACEBOOK":
            origem = "FACEBOOK";
            break;
          case "LIGACAO":
            origem = "FACEBOOK";
            break;
          case "PRE-AGENDA":
            origem = "PRE-AGENDA";
            break;
          default:
            origem = "SITE";
            break;
        }

        let status;
        switch (e.ZZQ_KANBAN) {
          case "N":
            status = "Novo";
            break;
          case "T":
            status = "Em-tratamento";
            break;
          case "R":
            status = "Retornar";
            break;
          case "A":
            status = "Agendado";
            break;
          case "C":
            status = "Concluido";
            break;
          default:
            status = "Novo";
            break;
        }

        if (e.ZZQ_CIDADE && e.ZZQ_CIDADE.trim() !== "")
          e.ZZQ_CIDADE = e.ZZQ_CIDADE.split("-")[0];

        let afterReturn = false;

        if (e.ZZQ_DTRET.trim() !== "") {
          const dataRetorno = stringToDate(e.ZZQ_DTRET);
          const dataAtual = new Date();
          if (compareDesc(dataAtual, dataRetorno) <= 0) afterReturn = true;
        }

        e.ZZQ_DTRET =
          e.ZZQ_DTRET.trim() !== ""
            ? format(stringToDate(e.ZZQ_DTRET), "dd/MM/yyyy")
            : "";

        return {
          ...e,
          id: e.ZZQ_ID,
          status,
          title: e.ZZQ_NOME,
          origem,
          afterReturn,
        };
      });

      await Promise.all(
        this.blocks.map(async item => {
          if (item.ZZQ_DTAGEN.trim() !== "") {
            const dados = {
              EMPRESA: "01",
              FILIAL: "0101",
              FIELDS:
                "TOP 1 ZZL_STATUS STATUS_AGENDA,ZZK_NOME, ZZL_ID ID_AGENDA, ZZL_LOCAL",
              INNER: `INNER JOIN ZZK010 ZZK WITH(NOLOCK) ON ZZK.D_E_L_E_T_=' ' AND ZZK_COD=ZZL_PESSOA `,
              WHERE: `ZZL.D_E_L_E_T_=' ' AND ZZL_DTINI='${item.ZZQ_DTAGEN}' AND ZZL_IDLEAD='${item.ZZQ_ID}' ORDER BY ZZL.R_E_C_N_O_ DESC`,
              TABELA: "ZZL",
            };

            const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;
            const { data } = await axios.post(url, dados);

            if (data[0]) {
              item.statusAgenda = data[0].STATUS_AGENDA;
              item.profissional = data[0].ZZK_NOME.trim();
              item.idAgenda = data[0].ID_AGENDA;
              item.localAgenda = data[0].ZZL_LOCAL;

              item.dataAgendado =
                item.ZZQ_DTAGEN.trim() !== ""
                  ? format(stringToDate(item.ZZQ_DTAGEN), "dd/MM/yyyy")
                  : "";
              item.horaAgendado = item.ZZQ_HRAGEN;
            }
          }
        }),
      );

      this.analisaSaudeDadosLeads();
    },

    validaSaude(item) {
      if (!item.saude) return "white";

      if (item.saude >= 0 && item.saude <= 25) {
        return "yellow darken-1";
      }
      if (item.saude > 25 && item.saude <= 50) {
        return "amber darken-1";
      }
      if (item.saude > 50 && item.saude <= 75) {
        return "blue darken-1";
      }
      if (item.saude > 75) {
        return "green darken-1";
      }
    },

    analisaSaudeDadosLeads() {
      const peso = 100 / this.camposSaude.length;

      this.blocks.map(e => {
        let contador = 0;
        this.camposSaude.forEach(c => {
          if (e[c] && e[c].toString().trim() !== "") {
            contador++;
          }
        });
        e.saude = parseInt(contador * peso);
      });

      this.blocks = this.trimStringOnObject(this.blocks);

      this.blocksOld = this.trimStringOnObject(this.blocks);

      this.loading = false;
    },

    async buscaStatus(leadID, dataAgenda) {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "MAX(ZZL_STATUS)",
        WHERE: `D_E_L_E_T_=' ' AND ZZL_DTINI='${dataAgenda}' AND ZZL_IDLEAD='${leadID}'`,
        TABELA: "ZZL",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const statusAgenda = await axios.post(url, dados);

      return statusAgenda.data[0].ZZL_STATUS;
    },

    async buscaCidades() {
      if (!this.leadEditando[0].ZZQ_EST) return;
      this.loading = true;

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "CC2_CODMUN,CC2_MUN",
        WHERE: `D_E_L_E_T_=' ' AND CC2_EST='${this.leadEditando[0].ZZQ_EST}'`,
        TABELA: "CC2",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.itemsCidade = res.data.map(e => {
            return {
              text: e.CC2_MUN,
              value: e.CC2_CODMUN.trim(),
            };
          });
        })
        .catch(error => console.log(error));

      this.loading = false;
    },

    VerDetLeads(item) {
      console.log(this.$children.find(e => console.log(e.$options.name)));
      this.snackbar_error = false;
      const componenteFilho = this.$children.find(
        component => component.$options.name === "DetLeads",
      );
      componenteFilho.buscaDados(item.ZZQ_ID, item.ZZQ_NOME);
    },

    updateLeadKanban(id, status) {
      this.indexEditado = this.blocks.findIndex(e => e.id === id);
      const lead = this.blocks[this.indexEditado];
      if (status === "Novo") {
        this.salvaStatus(id, "N");
      }
      if (status === "Em-tratamento") {
        this.salvaStatus(id, "T");
        this.abreTelaEdicao(lead);
      }
      if (status === "Retornar") {
        this.salvaStatus(id, "R");
        this.dialogRetornar = true;
      }

      if (status === "Agendado") {
        this.salvaStatus(id, "A");
        this.verificaAgendamento(this.blocks[this.indexEditado]);
        this.buscaProfissional();
      }

      if (status === "Concluido") {
        this.salvaStatus(id, "C");
        this.concluiLead();
        console.log("Concluido");
      }

      if (this.indexEditado > -1) {
        Object.assign(this.blocks[this.indexEditado], { status });
      }
    },

    async salvaStatus(id, status) {
      this.loading = true;

      const dados = {
        ZZQ_ID: id,
        ZZQ_KANBAN: status,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/updateStatusKanban`;

      await axios.post(url, dados);

      this.loading = false;
    },

    async salvaLeads() {
      this.loading = true;
      this.leadEditando = this.trimStringOnObject(this.leadEditando);

      const url = `${process.env.VUE_APP_BASE_API_URL}/updateLeadKanban`;

      await axios
        .post(url, this.leadEditando[0])
        .then(res => {
          console.log(res.data);
          this.$toast.success("Salvo com sucesso!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        })
        .catch(err => {
          this.$toast.error(err.response.data, {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });

      this.indexEditado = this.blocks.findIndex(
        e => e.id === this.leadEditando[0].ZZQ_ID,
      );

      const peso = 100 / this.camposSaude.length;
      this.leadEditando.map(e => {
        let contador = 0;
        this.camposSaude.forEach(c => {
          if (e[c] && e[c].toString().trim() !== "") {
            contador++;
          }
        });
        e.saude = parseInt(contador * peso);
      });

      if (this.indexEditado > -1) {
        Object.assign(this.blocks[this.indexEditado], {
          ...this.leadEditando[0],
        });
      }

      this.loading = false;
      this.leadEditando = [];
      this.indexEditado = -1;
      this.editaLead = false;
    },

    trimStringOnObject(target) {
      Object.keys(target).forEach(key => {
        if (target[key]) {
          if (typeof target[key] === "string") {
            target[key] = target[key].trim();
          }
          if (typeof target[key] === "object" && !Array.isArray(target[key])) {
            target[key] = this.trimStringOnObject(target[key]);
          }
        }
      });
      return target;
    },

    async abreTelaEdicao(lead) {
      this.loading = true;

      this.leadEditando = [
        {
          ZZQ_ID: lead.ZZQ_ID,
          ZZQ_NOME: lead.ZZQ_NOME,
          ZZQ_QUALIF: lead.ZZQ_QUALIF,
          ZZQ_STATUS: lead.ZZQ_STATUS,
          ZZQ_EMAIL: lead.ZZQ_EMAIL,
          ZZQ_TEL: lead.ZZQ_TEL,
          ZZQ_TEL1: lead.ZZQ_TEL1,
          ZZQ_WHATS: lead.ZZQ_WHATS,
          ZZQ_PESTRA: lead.ZZQ_PESTRA,
          ZZQ_PSTRAT: lead.ZZQ_PSTRAT,
          ZZQ_LAUDOM: lead.ZZQ_LAUDOM,
          ZZQ_APUSER: lead.ZZQ_APUSER,
          ZZQ_EST: lead.ZZQ_EST,
          ZZQ_CIDADE: lead.ZZQ_CIDADE,
          ZZQ_DEFEIT: lead.ZZQ_DEFEIT,
          ZZQ_DDD: lead.ZZQ_DDD,
        },
      ];

      if (this.itemsEstado.length <= 0) {
        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `X5_CHAVE,X5_DESCRI`,
          WHERE: `X5_TABELA='12'`,
          TABELA: `SX5`,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

        await axios
          .post(url, dados)
          .then(res => {
            this.itemsEstado = res.data.map(e => {
              return {
                text: e.X5_DESCRI,
                value: e.X5_CHAVE.trim(),
              };
            });
          })
          .catch(err => console.log(err));
      }

      if (this.leadEditando[0].ZZQ_CIDADE.trim() !== "") {
        await this.buscaCidades();
      }

      this.loading = false;
      this.editaLead = true;

      this.$store.commit("SetLead_id", lead.ZZQ_ID);
      this.$store.commit("SetLead_nome", lead.ZZQ_NOME);
      this.$store.commit("setOrigem", "Leads");

      this.$store.commit("SetAlias", "ZZQ");
      this.$store.commit("SetDialogFollowUp", true);
    },

    async salvaDataRetorno() {
      if (!this.dataRetorno) {
        this.$toast.error("Digite a data de retorno!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      const dataDigitada = stringDatetoStringBanco(this.dataRetorno);

      const dataAtual = new Date();

      const dataDigit = this.dataRetorno.split("/");

      const ano = dataDigit[2];
      const mes = dataDigit[1].padStart(2, "0");
      const dia = dataDigit[0].padStart(2, "0");

      const dataDigitToDate = stringToDate(`${ano}${mes}${dia}`);

      console.log("DTGGD ==> ", compareDesc(dataAtual, dataDigitToDate));

      if (compareDesc(dataAtual, dataDigitToDate) < 0) {
        this.$toast.error("Data de retorno tem que ser maior que a atual!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      const dados = {
        ZZQ_ID: this.blocks[this.indexEditado].ZZQ_ID,
        ZZQ_DTRET: dataDigitada,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/salvaDataRetorno`;

      await axios
        .post(url, dados)
        .then(() => {
          this.$toast.success("Salvo com sucesso!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        })
        .catch(err => {
          this.$toast.error(err.response.data, {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });

      if (this.indexEditado > -1) {
        Object.assign(this.blocks[this.indexEditado], {
          ZZQ_DTRET: this.dataRetorno,
        });
      }

      this.indexEditado = -1;
      this.dataRetorno = "";

      this.dialogRetornar = false;
    },

    async buscaProfissional() {
      if (this.itemsProfissional.length <= 0) {
        this.loadingProfissional = true;
        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "*",
          INNER: `INNER JOIN ZZJ010 ZZJ WITH(NOLOCK) ON ZZJ.D_E_L_E_T_=' ' AND ZZJ_PROFIS=ZZK_COD `,
          WHERE: `ZZK.D_E_L_E_T_=' ' AND ZZK_TIPO='F' AND ZZK_MSBLQL='2' ORDER BY ZZK_NOME`,
          TABELA: "ZZK",
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

        await axios
          .post(url, dados)
          .then(res => {
            this.itemsProfissional = res.data.map(e => {
              return {
                text: e.ZZK_NOME.trim(),
                value: e.ZZK_COD,
                ...e,
              };
            });
          })
          .catch(err => {
            this.colorSnackbar = "red darken-2";
            this.timeSnackbar = 8000;
            this.text = err.response.data;
            this.snackbar = true;
          });

        this.loadingProfissional = false;
      }
    },

    async buscarHora() {
      this.horaInicioTrabalho = null;
      this.horaFinalTrabalho = null;
      this.trabalha = false;
      this.bucaHoraDisponivel = false;
      this.events = [];
      this.focus = null;
      if (!this.profissional || !this.dataAgenda) {
        this.colorSnackbar = "red darken-2";
        this.timeSnackbar = 8000;
        this.text = "Escolha o profissional e data!";
        this.snackbar = true;
        return;
      }
      console.log(this.profissional);

      const dataDigitada = stringDatetoStringBanco(this.dataAgenda);

      const dataAtual = new Date();

      const dataDigit = this.dataAgenda.split("/");

      const ano = dataDigit[2];
      const mes = dataDigit[1].padStart(2, "0");
      const dia = dataDigit[0].padStart(2, "0");

      const dataDigitToDate = stringToDate(`${ano}${mes}${dia}`);

      const diaSemana = getISODay(dataDigitToDate);

      const profissional = this.itemsProfissional.filter(
        e => e.ZZK_COD === this.profissional,
      )[0];

      this.localAgenda = profissional.ZZJ_LOCAL;
      console.log(profissional.ZZJ_NMPROF?.trim());

      let horaAlmocoI = profissional.ZZK_HRALMI;
      let horaAlmocoF = profissional.ZZK_HRALMF;

      //SEGUNDA-FERIRA
      if (diaSemana === 1) {
        this.horaInicioTrabalho = profissional.ZZK_HRSEGI;
        this.horaFinalTrabalho = profissional.ZZK_HRSEGF;
        this.trabalha = profissional.ZZK_HSEG === "S" ? true : false;
      }

      //TERÇA-FEIRA
      if (diaSemana === 2) {
        this.horaInicioTrabalho = profissional.ZZK_HRTERI;
        this.horaFinalTrabalho = profissional.ZZK_HRTERF;
        this.trabalha = profissional.ZZK_HTER === "S" ? true : false;
      }

      //TERÇA-FEIRA
      if (diaSemana === 3) {
        this.horaInicioTrabalho = profissional.ZZK_HRQUAI;
        this.horaFinalTrabalho = profissional.ZZK_HRQUAF;
        this.trabalha = profissional.ZZK_HQUA === "S" ? true : false;
      }

      //QUARTA-FEIRA
      if (diaSemana === 4) {
        this.horaInicioTrabalho = profissional.ZZK_HRQUII;
        this.horaFinalTrabalho = profissional.ZZK_HRQUIF;
        this.trabalha = profissional.ZZK_HQUI === "S" ? true : false;
      }

      //SEXTA-FEIRA
      if (diaSemana === 5) {
        this.horaInicioTrabalho = profissional.ZZK_HRSEXI;
        this.horaFinalTrabalho = profissional.ZZK_HRSEXF;
        this.trabalha = profissional.ZZK_HSEX === "S" ? true : false;
      }

      //SÁBADO
      if (diaSemana === 6) {
        this.horaInicioTrabalho = profissional.ZZK_HRSABI;
        this.horaFinalTrabalho = profissional.ZZK_HRSABF;
        this.trabalha = profissional.ZZK_HSAB === "S" ? true : false;
      }

      //DOMINGO
      if (diaSemana === 7) {
        this.horaInicioTrabalho = profissional.ZZK_HRDOMI;
        this.horaFinalTrabalho = profissional.ZZK_HRDOMF;
        this.trabalha = profissional.ZZK_HDOM === "S" ? true : false;
      }

      if (compareDesc(dataAtual, dataDigitToDate) < 0) {
        this.colorSnackbar = "red darken-2";
        this.timeSnackbar = 8000;
        this.text = "Data da agenda tem que ser maior ou igual que a atual!";
        this.snackbar = true;
        return;
      }

      this.loading = true;

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "A3_COD,A3_NOME",
        WHERE: `D_E_L_E_T_=' ' AND A3_ZTIPO='000013' AND A3_MSBLQL='2' ORDER BY A3_NOME`,
        TABELA: "SA3",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.itemsMedicosAgenda = res.data.map(e => {
            return {
              text: e.A3_NOME.trim(),
              value: e.A3_COD,
              e,
            };
          });
        })
        .catch(err => {
          this.colorSnackbar = "red darken-2";
          this.timeSnackbar = 8000;
          this.text = err.response.data;
          this.snackbar = true;
        });

      this.itemsMedicosAgenda.push({
        text: "Leads digitais / Internet",
        value: "001845",
      });

      dados.FIELDS = "ZZT_COD,ZZT_DESCRI";
      dados.WHERE = `D_E_L_E_T_=' ' AND ZZT_FILIAL = '    ' ORDER BY ZZT_COD`;
      dados.TABELA = `ZZT`;

      await axios
        .post(url, dados)
        .then(res => {
          this.itemsTitulo = res.data.map(e => {
            return {
              text: e.ZZT_DESCRI.trim(),
              value: e.ZZT_DESCRI,
              ...e,
            };
          });
        })
        .catch(err => {
          this.colorSnackbar = "red darken-2";
          this.timeSnackbar = 8000;
          this.text = err.response.data;
          this.snackbar = true;
        });

      dados.FIELDS = "TOP 10 ZZL_HRINI,ZZL_HRFIN";
      dados.WHERE = `D_E_L_E_T_=' ' AND ZZL_PESSOA='${this.profissional}' AND ZZL_DTINI='${dataDigitada}'  ORDER BY ZZL_HRINI`;
      dados.TABELA = `ZZL`;

      this.focus = `${ano}-${mes}-${dia}`;

      this.events = [
        {
          start: `${ano}-${mes}-${dia} ${horaAlmocoI}`,
          name: "Almoço",
          end: `${ano}-${mes}-${dia} ${horaAlmocoF}`,
          color: "blue lighten-3",
        },
      ];

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            this.events.push({
              start: `${ano}-${mes}-${dia} ${e.ZZL_HRINI}`,
              end: `${ano}-${mes}-${dia} ${e.ZZL_HRFIN}`,
              name: `Ocupado`,
              color: "grey darken-3",
            });
          });
        })
        .catch(err => {
          this.colorSnackbar = "red darken-2";
          this.timeSnackbar = 8000;
          this.text = err.response.data;
          this.snackbar = true;
        });

      this.loading = false;
      this.bucaHoraDisponivel = true;
    },

    async salvaAgenda() {
      if (!this.tituloAgenda) {
        this.$toast.error("Digite o título da agenda", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

        return;
      }
      if (!this.horaInicioAgenda) {
        this.$toast.error("Digite a hora inicial", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (!this.horaFimAgenda) {
        this.$toast.error("Digite a hora final", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }
      if (!this.medicoAgenda) {
        this.$toast.error("Digite o médico da agenda", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }
      if (!this.notaAgenda) {
        this.$toast.error("Digite a nota da agenda", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      const dataDigit = this.dataAgenda.split("/");

      const ano = dataDigit[2];
      const mes = dataDigit[1].padStart(2, "0");
      const dia = dataDigit[0].padStart(2, "0");

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZZL_ID",
        WHERE: `D_E_L_E_T_=' ' AND ZZL_PESSOA='${this.profissional}'
                  AND ZZL_DTINI='${ano}${mes}${dia}'
                  AND CONVERT(TIME,'${this.horaInicioAgenda}',114) BETWEEN CONVERT(TIME,ZZL_HRINI, 114) AND  CONVERT(TIME,ZZL_HRFIN,114)`,
        TABELA: "ZZL",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const ocupado = await axios.post(url, dados);

      if (ocupado.data.length >= 1) {
        this.$toast.error("Horário escolhido encontra-se ocupado!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      const horaInicio = parseInt(this.horaInicioAgenda.split(":")[0]);
      const horaFinal = parseInt(this.horaFimAgenda.split(":")[0]);

      const horaInicioTrab = parseInt(this.horaInicioTrabalho.split(":")[0]);
      const horaFinalTrab = parseInt(this.horaFinalTrabalho.split(":")[0]);

      if (horaInicio < horaInicioTrab) {
        this.$toast.error("Hora inicial menor que hora inicial de trabalho!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      if (horaFinal > horaFinalTrab) {
        this.$toast.error("Hora final maior que hora final de trabalho!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      const dadosAgenda = {
        ZZL_TITULO: this.tituloAgenda,
        ZZL_HRINI: this.horaInicioAgenda,
        ZZL_HRFIN: this.horaFimAgenda,
        ZZL_DTINI: `${ano}${mes}${dia}`,
        ZZL_DTFIN: `${ano}${mes}${dia}`,
        ZZL_NOTAS: this.notaAgenda,
        ZZL_PESSOA: this.profissional,
        ZZL_LOCAL: this.localAgenda,
        ZZL_STATUS: "A",
        ZZL_CANAL: "000002",
        ZZL_VEND1: this.medicoAgenda,
        ZZL_IDLEAD: this.blocks[this.indexEditado].ZZQ_ID,
        ZZL_DDD: this.blocks[this.indexEditado].ZZQ_DDD,
        ZZL_TEL1: this.blocks[this.indexEditado].ZZQ_TEL,
        ZZL_NOMECL: this.blocks[this.indexEditado].ZZQ_NOME,
        ZZL_WHATS: "N",
        // ZZL_ERWHAT: 'N',
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/salvaAgendaLeads`;

      let error = false;

      await axios
        .post(url, dadosAgenda)
        .then(() => {
          this.$toast.success("Salvo com sucesso!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        })
        .catch(err => {
          error = true;
          this.$toast.error(err.response.data, {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });

      if (!error) {
        const dadosUp = {
          ZZQ_ID: this.blocks[this.indexEditado].ZZQ_ID,
          ZZQ_DTAGEN: `${ano}${mes}${dia}`,
          ZZQ_HRAGEN: this.horaInicioAgenda,
        };

        url = `${process.env.VUE_APP_BASE_API_URL}/salvaDataRetorno`;

        await axios.post(url, dadosUp);

        if (this.indexEditado > -1) {
          Object.assign(this.blocks[this.indexEditado], {
            ZZQ_DTAGEN: `${dia}/${mes}/${ano}`,
            ZZQ_HRAGEN: this.horaInicioAgenda,
            statusAgenda: "A",
          });
        }

        this.profissional = null;
        this.dataAgenda = null;
        this.tituloAgenda = null;
        this.horaInicioAgenda = null;
        this.horaFimAgenda = null;
        this.medicoAgenda = null;
        this.notaAgenda = null;
        this.trabalha = false;
        this.bucaHoraDisponivel = false;
        this.events = [];
        this.dialogAgendar = false;
        this.indexEditado = -1;
      }

      // this.dialogRetornar = false
    },

    async verificaAgendamento(lead) {
      if (lead.ZZQ_DTAGEN.trim() !== "") {
        this.colorSnackbar = "red darken-2";
        this.timeSnackbar = 8000;
        this.text =
          "Já existe agendamento, para remover ou incluir um novo va á rotina de agenda auditiva!";
        this.snackbar = true;
        return;
      }
      this.indexEditado = this.blocks.findIndex(e => e.id === lead.ZZQ_ID);
      this.dialogAgendar = true;
      this.buscaProfissional();
    },

    async abreDialogVinculaCliente(lead) {
      this.visualizaCliente = false;
      this.indexEditado = this.blocks.findIndex(e => e.ZZQ_ID === lead.ZZQ_ID);

      this.dialogVinculaCliente = true;
      if (this.itemsClientes.length <= 0) {
        this.loadingClientes = true;

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "A1_COD,A1_LOJA,A1_NOME",
          WHERE: `D_E_L_E_T_=' ' AND A1_MSBLQL='2' ORDER BY A1_NOME`,
          TABELA: "SA1",
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

        await axios
          .post(url, dados)
          .then(res => {
            this.itemsClientes = res.data.map(e => {
              return {
                text: e.A1_NOME.trim(),
                value: `${e.A1_COD}-${e.A1_LOJA}`,
                ...e,
              };
            });
          })
          .catch(err => {
            this.colorSnackbar = "red darken-2";
            this.timeSnackbar = 8000;
            this.text = err.response.data;
            this.snackbar = true;
          });

        this.loadingClientes = false;
      }
    },

    async salvaClienteVinculado() {
      if (!this.clienteVinculado) {
        this.colorSnackbar = "red darken-2";
        this.timeSnackbar = 8000;
        this.text = "Escolha o cliente para vincular!";
        this.snackbar = true;
        return;
      }

      const dadosCliente = this.clienteVinculado.split("-");

      const dados = {
        ZZQ_CLIENT: dadosCliente[0],
        ZZQ_LOJA: dadosCliente[1],
        ZZQ_ID: this.blocks[this.indexEditado].ZZQ_ID,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/salvaDataRetorno`;

      await axios
        .post(url, dados)
        .then(() => {
          this.$toast.success("Salvo com sucesso!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        })
        .catch(err => {
          this.$toast.success(err.response.data, {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });

      if (this.indexEditado > -1) {
        Object.assign(this.blocks[this.indexEditado], {
          ZZQ_CLIENT: dadosCliente[0],
        });
      }

      this.clienteVinculado = null;
      this.dialogVinculaCliente = false;
    },

    async abreVinculoCliente(lead) {
      this.visualizaCliente = true;
      this.loading = true;
      if (this.itemsClientes.length <= 0) {
        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "A1_COD,A1_LOJA,A1_NOME",
          WHERE: `D_E_L_E_T_=' ' AND A1_MSBLQL='2' ORDER BY A1_NOME`,
          TABELA: "SA1",
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

        await axios.post(url, dados).then(res => {
          this.itemsClientes = res.data.map(e => {
            return {
              text: e.A1_NOME.trim(),
              value: `${e.A1_COD}-${e.A1_LOJA}`,
              ...e,
            };
          });
        });
      }

      const cliente = this.itemsClientes.filter(
        e => e.A1_COD === lead.ZZQ_CLIENT,
      );

      this.visualClienteVinc = `${cliente[0].A1_COD} - ${cliente[0].A1_NOME}`;

      this.loading = false;

      this.dialogVinculaCliente = true;
    },

    async handleEnviarTemplateAgenda(lead) {
      console.log(lead.idAgenda);
      this.loading = true;

      const url = `${process.env.VUE_APP_BASE_API_URL}/notificaAgenda`;

      axios
        .post(url, {
          idAgenda: lead.idAgenda,
        })
        .then(({ data }) => {
          if (data.status) {
            this.$toast.success(data.message, {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else {
            this.$toast.warning(data.message, {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        })
        .catch(error => {
          console.log(error);
          this.$toast.error(error?.response.data.message ?? error.message, {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async concluiLead() {
      this.loading = true;

      const dataAtual = new Date();

      const ano = dataAtual.getFullYear();
      const mes = (dataAtual.getMonth() + 1).toString().padStart(2, "0");
      const dia = dataAtual
        .getDate()
        .toString()
        .padStart(2, "0");
      const dados = {
        ZZQ_ID: this.blocks[this.indexEditado].ZZQ_ID,
        ZZQ_DTENC: `${ano}${mes}${dia}`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/salvaDataRetorno`;

      await axios.post(url, dados).then(() => {
        this.$toast("Lead concluído com sucesso!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      });

      this.loading = false;
    },

    getEventColor(event) {
      return event.color;
    },
  },
};
</script>

<style lang="scss">
$Novo: #fb7d44;
$Em-tratamento: #2a92bf;
$Retornar: #f9a825;
$Agendado: #0d47a1;
$Concluido: #00b963;

* {
  box-sizing: border-box;
}

.drag-column {
  background: red;

  .drag-column-header > div {
    width: 100%;

    h2 > a {
      float: right;
    }
  }

  &-Novo {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: $Novo;
    }
  }

  &-Em-tratamento {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: $Em-tratamento;
    }
  }

  &-Retornar {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: $Retornar;
    }
  }

  &-Agendado {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: $Agendado;
    }
  }

  &-Concluido {
    .drag-column-header,
    .is-moved,
    .drag-options {
      background: $Concluido;
    }
  }
}

.fixed-titulo {
  position: fixed;
  width: 18vw;
  border-radius: 5px;
  top: 0;
}

.drag-inner-list {
  height: 82vh;
  overflow-y: auto;
}

.section {
  padding: 20px;
  text-align: center;

  a {
    color: red;
    text-decoration: none;
    font-weight: 300;
  }

  h4 {
    font-weight: 400;

    a {
      font-weight: 600;
    }
  }

  .v-input--radio-group__input {
    justify-content: space-between !important;
  }
}

.text-sm {
  font-size: 0.85rem;
}
.text-xs {
  font-size: 0.7rem;
  line-height: 1;
}

.card-header {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "title health origin"
    "data actions actions"
    "data actions actions"
    "schedule schedule schedule";
  align-items: start;
}

.grid-title {
  grid-area: title;
  display: block;
}

.grid-health {
  grid-area: health;
  display: flex;
}
.grid-actions {
  grid-area: actions;
  align-items: end;
  justify-self: end;
}

.grid-origin {
  grid-area: origin;
  min-width: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-data {
  grid-area: data;
  display: flex;
  flex-direction: column;
}

.grid-schedule {
  grid-area: schedule;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 3fr 2fr;
  place-items: start;
}
</style>
