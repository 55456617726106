import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto mt-3",attrs:{"max-width":"400","tile":""}},[_c(VList,{attrs:{"dense":""}},[_c(VSubheader,[_vm._v("Opções")]),_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.itensAdministracao),callback:function ($$v) {_vm.itensAdministracao=$$v},expression:"itensAdministracao"}},_vm._l((_vm.itensAdministracao),function(item,i){return _c(VListItem,{key:i,attrs:{"to":item.link}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.Item)}})],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }