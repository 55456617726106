import VueMask from "v-mask";
import Vue from "vue";
import VueExcelSlsx from "vue-excel-xlsx";
import VueHtmlToPaper from "vue-html-to-paper";
import VueKanban from "vue-kanban";
import App from "./App.vue";
import router from "./config/router";
import store from "./config/store";
import vuetify from "./plugins/vuetify";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});
Vue.use(VueMask);
Vue.use(VueKanban);

Vue.config.productionTip = false;

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
};

Vue.use(VueHtmlToPaper, options);
Vue.use(VueExcelSlsx);

export const useToast = () => Vue.$toast;

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App),
}).$mount("#app");
