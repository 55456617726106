<template>
  <div class="text-center">
    <v-dialog
      v-model="dialogError"
      width="700"
      persistent
    >
      <v-card>
        <v-card-title class="title red lighten-2" v-text="`Aconteceu algum erro, entre em contato com o administrador!`">
        </v-card-title>

        <v-card-text class="body-1 mt-3" v-text="error">
         
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="fechaDialog"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: "erros",
    props: ['error'],
    computed: mapState(["dialogError"]),
    methods:{
        fechaDialog(){
            this.$store.commit('setDialogError', false)
        }
    }
}
</script>

<style>

</style>