<template>
  <div style="width: 100%;">
    <main class="main" v-if="tabs.length > 0">
      <v-tabs
        :value="tabActive"
        background-color="black"
        color="white"
        style="margin-top: -10px;"
      >
        <v-tab
          v-for="(item, i) in tabs"
          :key="i"
          @click="teste(item.name, i)"
          class="mt-1"
          style="color: white;font-size: .6rem;"
        >
          {{ item.name
          }}<v-btn
            icon
            color="white"
            x-small
            class="ml-3"
            @click="removeTab(i)"
          >
            <v-icon x-small>mdi-close</v-icon>
          </v-btn>
        </v-tab>
        <v-spacer></v-spacer>
        <img
          src="../../assets/logoMini.png"
          style="max-height: 40px; padding-top: 15px;"
        />
        <div class="pa-2 mr-2">
          <!-- <v-btn icon dark>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon dark>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon dark>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->

          <v-avatar size="28" class="mt-1" dark>
            <Gravatar :email="user.email" alt="email" />
          </v-avatar>
        </div>
      </v-tabs>
      <v-tabs-items v-model="tabActive">
        <v-tab-item v-for="item in tabs" :key="item.name">
          <!--<v-card flat>
            <v-card-text>-->
          <component v-bind:is="item.content"></component>
          <!--</v-card-text>
          </v-card>-->
        </v-tab-item>
      </v-tabs-items>
    </main>
    <PagInicial v-if="tabs.length <= 0" />
  </div>
</template>

<script>
import Agenda from "@/components/Agenda/Agenda";
import AgendaC from "@/components/Agenda/AgendaC";
import TrataAlcada from "@/components/Alcadas/trataAlcada";
import BaseInstalada from "@/components/BaseInstalada/BaseInstalada";
import Atividades from "@/components/Central_CRM/Atividades/Atividades";
import Campanhas from "@/components/Central_CRM/Campanhas/Campanhas";
import Investimento from "@/components/Central_CRM/Investimento/invest";
import relatorioInvest from "@/components/Central_CRM/Investimento/relatorio";
// import Leads from "@/components/Central_CRM/Leads/Leads1";
import Leads from "@/components/Central_CRM/Leads/Kleads";
import LeadsAdm from "@/components/Central_CRM/Leads/Leads1";
import ReportCampanhaAnuncioMeta from "@/components/Central_CRM/Relatorios/ReportCampanhaAnuncioMeta";
import CentralCrm from "@/components/Central_CRM/template/Home_central";
import Clientes from "@/components/Clientes/Clientes";
import Clube from "@/components/Clube/Clube";
import Metas from "@/components/Metas/Metas";
import MetasAreas from "@/components/Metas/metasAreas";
import MetasProdutos from "@/components/Metas/metasProdutos";
import OrdemServico from "@/components/OrdemServico/OrdemServico";
import CentralVisitador from "@/components/Visitas/CentralVisitador";
import Ocorrencias from "@/components/Visitas/Ocorrencias";
import Painel from "@/components/Visitas/Painel";
import Visitas from "@/components/Visitas/Visitas";
import VisitasNew from "@/components/Visitas/VisitasNew";
import PagInicial from "@/components/templates/PagInicial";

import Gravatar from "vue-gravatar";
import { mapState } from "vuex";
export default {
  components: {
    Agenda,
    AgendaC,
    TrataAlcada,
    BaseInstalada,
    Atividades,
    Campanhas,
    Investimento,
    relatorioInvest,
    Leads,
    LeadsAdm,
    ReportCampanhaAnuncioMeta,
    CentralCrm,
    Clientes,
    Clube,
    Metas,
    MetasProdutos,
    MetasAreas,
    OrdemServico,
    CentralVisitador,
    Ocorrencias,
    Painel,
    Visitas,
    VisitasNew,
    PagInicial,
    Gravatar,
    Comissao: () => import("../Comissao/Comissao"),
    MetasOpimed: () => import("../Faturamento_Metas/Faturamento.vue"),
    WhatsApp: () => import("../Central_WhatsApp/Home_WhatsApp.vue"),
    ControlOS: () => import("../OrdemServico/ControleOS"),
    FilaAtendimento: () => import("../OrdemServico/FilaAtendimento.vue"),
    metasVendedores: () => import("../Metas/metasVendedoresNew.vue"),
    centralAuditiva: () => import("../Central_Auditiva/centralAuditiva.vue"),
    estoque: () => import("../Central_Auditiva/estoque.vue"),
    RelAgendamentos: () =>
      import("../Central_CRM/Relatorios/RelLeadsAgenda.vue"),
    RelAgendaIndica: () => import("../Visitas/RelAgendamento.vue"),
    PainelVisitador: () => import("../Visitas/PainelVisitador.vue"),
    FonosRepre: () => import("../Comissao/ComissaoFonosRepre.vue"),
    CustoPrevOp: ()=> import("../Financeiro/cpo.vue"),
  },
  computed: { ...mapState(["tabs", "tabActive", "titlePag", "user"]) },
  data() {
    return {
      items: [
        { tab: "Agenda", content: "ComponentA" },
        { tab: "CLientes", content: "ComponentB" },
      ],
    };
  },
  methods: {
    teste(title, i) {
      this.$store.commit("SetTabActive", i);
    },
    removeTab(i) {
      this.$store.commit("removeTab", i);
      this.$store.commit("SetTabActive", i - 1);
    },
    LimpaTabs() {
      this.$store.commit("SetLimpaTabs", []);
    },
  },
};
</script>

<style>
.main {
  margin-left: 56px;
}
</style>
