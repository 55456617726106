<template>
  <div id="app">
    <v-dialog v-model="awaitFormsFace" width="30%" persistent>
      <v-card>
        <v-card-title> Leads Facebook </v-card-title>
        <v-card-text>
          <div class="d-flex flex-column">
            <span class="title">Leads incluídos na base com sucesso.</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="awaitFormsFace = false" color="red darken-2">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="formsFacebook" width="80%" persistent>
      <v-card>
        <v-card-title>Campanhas Facebook em sua página</v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selectFormsFace"
            :headers="headersFormsFace"
            :items="itemsFormsFace"
            dense
            :items-per-page="itemsFormsFace ? itemsFormsFace.length : 0"
            hide-default-footer
            :loading="loadingFormsFace"
            no-data-text="aguarde..."
          >
            <template v-slot:[`item.status`]="{ item }">
              <div class="d-flex justify-center">
                <v-chip
                  small
                  v-if="item.status !== 'ACTIVE'"
                  dark
                  color="red darken-4"
                  >{{ item.status }}</v-chip
                >
                <v-chip small v-else dark color="green darken-4">{{
                  item.status
                }}</v-chip>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="d-flex justify-space-between mt-3">
          <v-btn text color="error" @click="formsFacebook = false">
            Fechar
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="verificaLFace()"
            :disabled="!isAdmin"
          >
            Incluir lead's na base
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="LeadForm" width="60%" persistent>
      <v-card>
        <v-card-title>Leads recuperados do furmulário</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersLeadForm"
            :items="itemsLeadForm"
            dense
            :items-per-page="itemsLeadForm ? itemsLeadForm.length : 0"
            hide-default-footer
            :key="id"
            :loading="loadingLeadForm"
          ></v-data-table>
        </v-card-text>
        <div class="d-flex justify-space-between mt-3">
          <v-btn text color="error" @click="LeadForm = false"> Fechar </v-btn>
          <v-btn text color="primary"> Incluir leads na base </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-card class="d-flex">
      <v-card>
        <v-navigation-drawer
          v-if="drawer"
          v-model="drawer"
          :mini-variant.sync="mini"
          permanent
        >
          <v-list dense>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              link
              @click="paineis(item.function)"
            >
              <v-list-item-content>
                <v-tooltip top v-if="item.title.length > 40">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 0.7rem"
                    >
                      {{ item.title }}
                    </v-list-item-title>
                  </template>
                  <span>{{ item.title }}</span>
                </v-tooltip>
                <v-list-item-title
                  style="font-size: 0.7rem"
                  v-if="item.title.length < 40"
                >
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <v-card>
        <div class="ml-2">
          <v-data-table
            v-model="selectLeads"
            :headers="HeadresLeads"
            :items="itemLeads"
            show-select
            item-key="RECNO"
            :loading="loading"
            :search="search"
            :footer-props="{
              disableItemsPerPage: true,
            }"
            :items-per-page="25"
            dense
            fixed-header
            height="80vh"
            class="elevation-1 tbLeads"
            no-data-text="Sem dados..."
            @click:row="marcaItem"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <div v-if="user.NivelAdmin[0].trim() !== '0'">
                  <v-btn
                    color="indigo"
                    style="color: white"
                    @click.stop="abrePainel"
                    class="mt-1"
                    :disabled="itemLeads.length <= 0"
                  >
                    <v-icon v-if="!drawer">mdi-chevron-right</v-icon>
                    <v-icon v-if="drawer">mdi-chevron-left</v-icon>
                    Painéis
                  </v-btn>
                  <v-btn
                    class="mx-5 mt-1"
                    fab
                    x-small
                    dark
                    color="indigo"
                    @click="novo()"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div v-if="NrRegFiltro !== null">
                  <span style="font-size: 0.7rem">
                    Filtro aplicado no
                    <strong>{{ filtroEscolhido }}</strong>
                    , {{ NrRegFiltro }} registro(s) encontrado(s).
                  </span>
                  <v-btn
                    class="mx-5 mt-2"
                    x-small
                    dark
                    fab
                    color="error"
                    @click="LimpaFiltro()"
                  >
                    <v-icon dark class="ml-1">mdi-eraser</v-icon>
                  </v-btn>
                </div>
                <v-col cols="2">
                  <v-select
                    class="mt-5"
                    v-model="filtroEscolhido"
                    :items="itemsFiltro"
                    @change="verTipoFiltro"
                    label="Escolha o campo"
                  ></v-select>
                </v-col>
                <v-col cols="2" v-if="itensFiltroEsc.length <= 0">
                  <v-text-field
                    class="mt-5"
                    placeholder="digite aqui.."
                    v-model="stringFiltro"
                    :disabled="!filtroEscolhido"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" v-if="itensFiltroEsc.length > 0">
                  <v-select
                    class="mt-5"
                    v-model="stringFiltro"
                    :items="itensFiltroEsc"
                    label="Escolha..."
                  ></v-select>
                </v-col>
                <v-col cols="1" v-if="isAdmin">
                  <v-text-field
                    class="mt-4 text-uppercase"
                    label="Data Inicial"
                    v-model="dtInicial"
                    v-mask="'##/##/####'"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="1" v-if="isAdmin">
                  <v-text-field
                    class="mt-4 text-uppercase"
                    label="Data Final"
                    v-model="dtFinal"
                    v-mask="'##/##/####'"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    class="mx-5"
                    x-small
                    dark
                    color="indigo"
                    @click="filtraRegistro(filtroEscolhido, stringFiltro)"
                  >
                    <v-icon dark class="ml-1">mdi-account-search</v-icon>
                  </v-btn>
                </v-col>
                <div v-if="isAdmin">
                  <v-tooltip bottom>
                    <v-spacer></v-spacer>
                    <template v-slot:activator="{ on, attrs }">
                      <vue-excel-xlsx
                        :data="itemsRelatExcel"
                        :columns="headersRelatExcel"
                        :filename="NOME_RELATORIO"
                        :sheetname="``"
                        class="ml-5 mb-5 tbExcel"
                      >
                        <v-btn
                          icon
                          color="blue darken-2"
                          class="mr-5 mt-5"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                      </vue-excel-xlsx>
                    </template>
                    <span>Exportar para excel</span>
                  </v-tooltip>
                </div>
                <v-btn icon @click="montaBrowser">
                  <v-icon small class="pa-1" color="blue">mdi-reload</v-icon>
                </v-btn>
              </v-toolbar>
              <section dense v-if="painelAplicado" class="ml-3">
                <span
                  style="font-size: 18px; color: #424242; font-weight: bolder"
                >
                  {{ TituloPainel }}
                </span>
                <p style="font-size: 12px; color: #757575">
                  {{ DetalhesPainel }}
                  <v-btn
                    class="ml-2"
                    x-small
                    dark
                    fab
                    color="error"
                    @click="LimpaFiltroPainel()"
                  >
                    <v-icon dark small>mdi-eraser</v-icon>
                  </v-btn>
                </p>
              </section>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-row class="start">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="user.NivelAdmin[0].trim() === '0'"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Alterar cadastro</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click="verDados(item)"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>Visualizar cadastro</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click="VerDetLeads(item)"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-monitor-dashboard
                    </v-icon>
                  </template>
                  <span>Detalhes</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.ZZL_ID"
                    >
                      mdi-calendar-month-outline
                    </v-icon>
                  </template>
                  <span>Possui agendamento</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteLead(item)"
                      :disabled="!isAdmin"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </template>
                  <span>Excluir Lead</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="vercliente(item)"
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.A1_COD"
                    >
                      mdi-account-check
                    </v-icon>
                  </template>
                  <span>
                    Cliente: {{ item.A1_COD }}/{{ item.A1_LOJA }} -
                    {{ item.A1_NOME }}, clique para ir em clientes.
                  </span>
                </v-tooltip>
              </v-row>
              <!-- <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="FollowUp(item)"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                >mdi-account-tie-voice</v-icon
              >
            </template>
            <span>Follow-Up</span>
          </v-tooltip> -->
            </template>

            <template v-slot:[`item.ZZQ_URLGRV`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    class="mr-3"
                    v-bind="attrs"
                    v-on="on"
                    :href="item.ZZQ_URLGRV.toLowerCase()"
                    target="_blank"
                    v-show="item.ZZQ_URLGRV.trim() !== ''"
                    :disabled="item.ZZQ_LIGDUR === '00:00:00'"
                  >
                    <v-icon small color="orange darken-2">
                      mdi-account-voice
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ouvir</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.ZZL_ID`]="{ item }">
              <span v-if="item.ZZL_ID">Sim</span>
            </template>
          </v-data-table>
          <div height="10vh" class="d-flex">
            <p class="ma-1">Último lead alterado: {{ ultimo_lead_editado }}</p>
          </div>
        </div>
        <div class="dadosCli text-center">
          <v-dialog v-model="DialogDados" width="90%" persistent>
            <v-card v-if="boilerplate" ref="form">
              <v-card-title
                class="headline grey lighten-2 justify-center"
                :v-text="titleDialog"
                style="height: 60px"
              ></v-card-title>
              <v-card-text>
                <v-row dense class="mt-5">
                  <v-col
                    v-for="campo in camposTela"
                    :key="campo.ZZP_CAMPO"
                    :cols="campo.ZZP_COLS"
                  >
                    <v-text-field
                      v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :label="campo.ZZP_TITULO"
                      v-if="campo.ZZP_TIPO === 'T'"
                      :rules="
                        obrigat(
                          dadosTela[campo.ZZP_CAMPO.trim()],
                          campo.ZZP_OBRIGA,
                        )
                      "
                      class="text-uppercase"
                      dense
                      :readonly="isReading"
                      :disabled="campo.ZZP_VISUAL === '2'"
                    ></v-text-field>
                    <v-text-field
                      v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :label="campo.ZZP_TITULO"
                      v-if="campo.ZZP_TIPO === 'D'"
                      :rules="
                        obrigat(
                          dadosTela[campo.ZZP_CAMPO.trim()],
                          campo.ZZP_OBRIGA,
                        )
                      "
                      v-mask="'##/##/####'"
                      class="text-uppercase"
                      dense
                      :readonly="isReading"
                      :disabled="campo.ZZP_VISUAL === '2'"
                    ></v-text-field>
                    <v-select
                      v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :items="campo.ITEMS"
                      :label="campo.ZZP_TITULO"
                      :rules="
                        obrigat(
                          dadosTela[campo.ZZP_CAMPO.trim()],
                          campo.ZZP_OBRIGA,
                        )
                      "
                      v-if="campo.ZZP_TIPO === 'S'"
                      class="text-uppercase"
                      dense
                      :readonly="isReading"
                      :disabled="campo.ZZP_VISUAL === '2'"
                    ></v-select>
                    <v-autocomplete
                      v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                      style="margin-top: -22px"
                      dense
                      :items="itemsTipo"
                      @click="carregaItems(campo)"
                      @focus="carregaItems(campo)"
                      @blur="buscaCliente(campo.ZZP_CAMPO.trim())"
                      no-data-text="Buscando dados..."
                      :rules="
                        obrigat(
                          dadosTela[campo.ZZP_CAMPO.trim()],
                          campo.ZZP_OBRIGA,
                        )
                      "
                      filled
                      :label="campo.ZZP_TITULO"
                      background-color="white"
                      v-if="campo.ZZP_TIPO === 'A'"
                      class="text-uppercase"
                      :readonly="isReading"
                      :disabled="campo.ZZP_VISUAL === '2'"
                    ></v-autocomplete>
                    <v-textarea
                      style="margin-top: -14px; font-size: 0.7rem"
                      v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :label="campo.ZZP_TITULO"
                      class="mx-2"
                      rows="1"
                      v-if="campo.ZZP_TIPO === 'Y'"
                      :readonly="isReading"
                      :disabled="campo.ZZP_VISUAL === '2'"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-col cols="5">
                  <v-progress-linear
                    v-model="progress"
                    v-if="progress > 0"
                    color="blue-grey"
                    height="25"
                  >
                    <template v-slot="{ value }">
                      <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  v-if="!isReading && !isEditing"
                  text
                  @click="salva()"
                >
                  Salvar
                </v-btn>
                <v-btn
                  color="primary"
                  v-if="!isReading && isEditing"
                  text
                  @click="salva(id)"
                >
                  Salvar Alteração
                </v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <FollowUp ref="followUpp" />
              <div class="px-3">
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="limpaVariaveis()">
                  Fechar
                </v-btn>
              </div>
            </v-card>
            <v-skeleton-loader
              ref="skeleton"
              :boilerplate="boilerplate"
              type="table"
              class="mx-auto"
              v-if="!boilerplate"
            ></v-skeleton-loader>
          </v-dialog>
        </div>
        <v-bottom-sheet v-model="sheetMessage">
          <v-sheet class="text-center" :color="colorMessage" height="130px">
            <div class="py-3">
              <v-btn class="mx-2" text fab>
                <v-icon
                  :v-text="iconMessage"
                  @click="sheetMessage = false"
                ></v-icon>
              </v-btn>
            </div>
            <div class="py-3" style="font-size: 15px" v-text="message"></div>
          </v-sheet>
        </v-bottom-sheet>
        <v-dialog v-model="dialogSuccessSaveCliente" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2">Lead</v-card-title>
            <v-card-text class="mt-5 title">{{ mensagem }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="reloadSave()">fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="progressDel" persistent width="300">
          <v-card>
            <v-card-title>Excluindo Lead, aguarde...</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" v-if="progressDel">
                  <v-progress-linear
                    color="deep-purple accent-4"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogExcluiLead" width="700" persistent>
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Excluir Lead: {{ nomeExclui }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="leadRedundante"
                    :items="itemsLeadsExclui"
                    dense
                    filled
                    label="Escolha um Lead redundante"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="red" text @click="fechaDialogExclui()">
                fechar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="green" text @click="concluiExcluLead()">
                Continuar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAwaitSaveCliente" width="500" persistent>
          <v-card>
            <v-overlay :value="dialogAwaitSaveCliente" absolute>
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-card-title class="headline grey lighten-2">Lead</v-card-title>
            <v-card-subtitle>
              <p class="body-1">Salvando o Lead...</p>
            </v-card-subtitle>
            <v-card-text v-if="nameofleadface" class="d-flex flex-column">
              <span class="title">
                Foram encontrado(s) {{ totalaIncluir }} lead(s) à incluir
              </span>
              <span class="title">
                Incluídos até o momento {{ totincnabase }}
              </span>
              <span class="title">Incluindo o lead {{ nameofleadface }}</span>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAwaitSaveLead" width="500">
          <v-card>
            <v-overlay :value="dialogAwaitSaveLead" absolute>
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-card-title class="headline grey lighten-2">Leads</v-card-title>
            <v-card-subtitle>
              <p class="body-1">Aguarde verificando novos Leads...</p>
            </v-card-subtitle>
            <v-card-text v-if="newLeads">
              <span class="title">
                Foram encontrados {{ newLeads }} Leads novos
              </span>
              <v-spacer></v-spacer>
              <span>
                Incluíndo lead...
                <span style="font-weight: bolder">{{ nrIncLead }}</span>
              </span>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogTimeLine" width="20%">
          <div style="background: white" class="d-flex">
            <span class="title ml-2">Histórico</span>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialogTimeLine = false">
              Fechar
            </v-btn>
          </div>
          <v-card height="600" class="scroll" v-if="itensTimeLine.length > 0">
            <v-card-text class="py-0">
              <v-timeline align-top dense style="margin-left: -40px">
                <v-timeline-item
                  v-for="(item, i) in itensTimeLine"
                  :key="i"
                  :color="item.color"
                  small
                  :icon="item.icon"
                  fill-dot
                >
                  <v-row>
                    <v-col>
                      <strong>{{ item.operacao }}</strong>
                      <div class="caption">{{ item.caption1 }}</div>
                      <div class="caption">{{ item.caption2 }}</div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogErrosCliente" width="900">
          <v-card max-width="900" class="mx-auto">
            <v-card-title class="headline red lighten-2" style="color: white">
              Erros ao salvar
            </v-card-title>
            <v-card-text>
              <v-list disabled>
                <v-subheader>Erros</v-subheader>
                <v-list-item-group v-model="aError" color="primary">
                  <v-list-item v-for="(item, i) in aError" :key="i">
                    <v-list-item-icon>
                      <v-icon color="red" size="64">mdi-close</v-icon>
                    </v-list-item-icon>
                    {{ item }}
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogErrosCliente = false">
                fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="text-center">
          <v-dialog v-model="dialogPaineis" width="500" persistent>
            <v-card>
              <v-overlay :value="dialogPaineis" absolute>
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
              <v-card-title class="headline grey lighten-2">
                Paineis
              </v-card-title>
              <v-card-text> {{ messagePainel }} </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <div class="text-center">
          <v-dialog v-model="dialogTransfLead" width="500" persistent>
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Transferência de Lead
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="RespTransf"
                      :items="itemsResponsaveis"
                      dense
                      filled
                      label="Escolha o novo responsável"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="error" text @click="fechaDialogTransf">
                  Fechar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="efetivaTransferencia">
                  Transferir
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <Opss />
      </v-card>
    </v-card>
    <Detalhes />
    <snackBar :snack="snackbar_error" :error="error" />
  </div>
</template>

<script>
import Detalhes from "@/components/Central_CRM/Leads/Detalhes_Leads";
import FollowUp from "@/components/Central_CRM/Leads/FollowUp";
import Opss from "@/components/templates/Opss";
import snackBar from "@/components/templates/snakBar_error";
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
//import { component } from 'vue/types/umd';

export default {
  name: "Leads-1",
  data: () => {
    return {
      headersLeadForm: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "E-mail", value: "e-mail" },
        { text: "Data cadastro", value: "data" },
        { text: "Hora", value: "hora" },
        { text: "WhatsApp", value: "whatsapp" },
        { text: "Telefone", value: "telefone" },
      ],
      headersRelatExcel: [
        { label: "Origem", field: "ZZQ_PAG" },
        { label: "Responsável", field: "ZS1_NOME" },
        { label: "Gravação", field: "ZZQ_URLGRV" },
        { label: "Campanha Facebook", field: "ZZQ_CAMPF" },
        { label: "Data Inclusão", field: "ZZQ_DTINC" },
        { label: "Hora Inclusão", field: "ZZQ_HRINC" },
        { label: "Situação", field: "ZZQ_SITUAC" },
        { label: "Qualificado", field: "ZZQ_QUALIF" },
        { label: "Status", field: "ZZR_DESCRI" },
        { label: "Data Retorno", field: "ZZQ_DTRET" },
        { label: "ID", field: "ZZQ_ID" },
        { label: "E-mail", field: "ZZQ_EMAIL" },
        { label: "Nome", field: "ZZQ_NOME" },
        { label: "Telefone", field: "ZZQ_TEL" },
        { label: "WhatsApp", field: "ZZQ_WHATS" },
        { label: "Telefone Secundário", field: "ZZQ_TEL1" },
        { label: "Telefone Destino", field: "ZZQ_TELDES" },
        { label: "Status Ligação", field: "ZZQ_STLIGA" },
        { label: "Duração ligação", field: "ZZQ_LIGDUR" },
        { label: "Duração falada", field: "ZZQ_LIGFAL" },
        { label: "Nome pessoa p/ tratamento", field: "ZZQ_PSTRAT" },
        { label: "Pessoa p/ tratamento", field: "ZZQ_PESTRA" },
        { label: "Laudo Médico", field: "ZZQ_LAUDOM" },
        { label: "Já usa aparelho", field: "ZZQ_APUSER" },
        { label: "É cliente", field: "ZZQ_ISCLI" },
        { label: "CPF", field: "ZZQ_CPF" },
        { label: "Cod Cliente", field: "A1_COD" },
        { label: "Estado", field: "ZZQ_EST" },
        { label: "Cidade", field: "ZZQ_CIDADE" },
      ],
      nomeExclui: "",
      progressDel: false,
      snackbar_error: false,
      countForms: null,
      nameForm: ``,
      error: ``,
      LeadForm: false,
      itemsLeadForm: [],
      loadingLeadForm: false,
      NOME_RELATORIO: `Leads`,
      itemsRelatExcel: [],
      dialogTimeLine: false,
      dialogTransfLead: false,
      RespTransf: null,
      itemsResponsaveis: [],
      dialogPaineis: false,
      messagePainel: null,
      drawer: null,
      items: [],
      painelAplicado: false,
      TituloPainel: null,
      DetalhesPainel: null,
      mini: true,
      NrRegFiltro: null,
      codCli: null,
      lojaCli: null,
      dialogDetalhes: false,
      progress: 0,
      indFiltro: "C",
      filtroEscolhido: null,
      id: null,
      aError: [],
      itemsFiltro: [],
      stringFiltro: null,
      dialogErrosCliente: false,
      dialogAwaitSaveCliente: false,
      dialogAwaitSaveLead: true,
      dialogSuccessSaveCliente: false,
      mensagem: null,
      ultimo_lead_editado: ``,
      errosTE: false,
      sheetMessage: false,
      colorMessage: "",
      iconMessage: "",
      titleDialog: "",
      selectLeads: [],
      message: "",
      DialogDados: false,
      isReading: false,
      isEditing: false,
      search: null,
      loading: true,
      altura: 0,
      perPage: 0,
      itensTimeLine: [],
      HeadresLeads: [],
      itemLeads: [],
      itemsTipo: [],
      camposTela: [],
      dadosTela: [],
      cliente: {},
      boilerplate: false,
      dadosCep: [],
      DtInicial: new Date().toISOString().substring(0, 10),
      countObrigatorio: 0,
      cloneDadosTela: null,
      interval: 0,
      ZS1_NOME: "",
      ZZQ_NOME: "",
      ZZQ_EMAIL: "",
      ZZQ_TEL: "",
      ZZQ_STATUS: "",
      ZZQ_QUALIF: "",
      ZZQ_SITUAC: "",
      itensFiltroEsc: [],
      stringCampos: "",
      top500Leads: [],
      isNew: false,
      isAdmin: false,
      dtInicial: "",
      dtFinal: "",
      newLeads: null,
      nrIncLead: 0,
      countNLeads: 0,
      totalLeads: 0,
      totincnabase: 0,
      totalaIncluir: 0,
      leadsCompleto: [],
      contIncLead: 0,
      dialogExcluiLead: false,
      itemsLeadsExclui: [],
      leadRedundante: "",
      leadAEscluir: null,
      headersFormsFace: [
        { text: "ID", value: "id" },
        { text: "Localidade", value: "locale" },
        { text: "Nome", value: "name" },
        { text: "Status", value: "status" },
        {
          text: "Data última recuperação",
          value: "DTULTRECUP",
          align: "center",
        },
        { text: "Hora", value: "HORA", align: "center" },
        { text: "Leads na Campanha", value: "countLeads", align: "center" },
        { text: "Novos à incluir", value: "newLeads", align: "center" },
      ],
      selectFormsFace: [],
      nameofleadface: null,
      btnFecharFace: false,
      novosLeadsFace: [],
      countGeral: 0,
      itemsFormsFace: [],
      leadsfacebook: [],
      leadsFaceBase: [],
      countNformsFace: 0,
      formsFacebook: false,
      loadingFormsFace: false,
      awaitFormsFace: false,
    };
  },
  components: { Opss, FollowUp, Detalhes, snackBar },
  computed: {
    ...mapState([
      "metadados",
      "user",
      "dialogFollowUp",
      "isReload",
      "tabs",
      "filtroTela",
      "totalGeralLeads",
      "FormsFacebook",
    ]),
    DataFormatadaInicial() {
      return moment(this.DtInicial).format("DD/MM/YYYY");
    },
  },
  methods: {
    vercliente(item) {
      this.$store.commit("SetCodLojaCliente", `${item.A1_COD}-${item.A1_LOJA}`);
      this.$store.commit("SetTitle", "Clientes");
      this.$store.commit("SetTabs", {
        name: `${"Clientes"}`,
        content: `Clientes`,
      });
      // this.$store.commit("SetTabActive", this.tabs.length - 1);
      this.$store.commit("SetTitle", "Clientes");
    },
    reloadSave() {
      const id_atual = this.dadosTela.ZZQ_ID.trim();
      const dadosTemp = this.dadosTela;

      // this.montaBrowser();
      this.dialogSuccessSaveCliente = false;

      this.itemLeads.forEach(function(item) {
        if (item.ZZQ_ID.trim() === id_atual) {
          item = dadosTemp;
        }
      });
    },
    fFollowUp() {
      this.$store.commit("SetAlias", "ZZQ");
      this.$refs.followUpp.montaBrowser();
      this.$store.commit("SetDialogFollowUp", true);
    },
    paineis(chamada) {
      if (chamada === "meusLeads") this.meusLeads();
      if (chamada === "atribuirLeads") this.atribuirLeads();
      if (chamada === "transfereLead") this.transfereLead();
      if (chamada === "histLead") this.histLead();
      if (chamada === "leadsRetorno") this.leadsRetorno();
      if (chamada === "leadsCliAberto") this.leadsCliAberto();
      if (chamada === "formsFace") this.verificaFormsFace();
      if (chamada === "leadsEncerra") this.leadsEncerra();
    },
    async leadsCliAberto() {
      const filtro = this.top500Leads.filter(
        e =>
          e.ZZQ_RESP === this.user.id &&
          e.ZZQ_SITUAC.trim() === "Aberto" &&
          e.A1_COD,
      );

      if (filtro.length > 0) {
        this.painelAplicado = true;
        this.TituloPainel = "Meus lead(s) com cliente vinculado em aberto";
        this.DetalhesPainel = `Total de ${filtro.length} registro(s)`;
        this.itemLeads = filtro;
        this.itemsRelatExcel = filtro;
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit("SetMensagemDialogOps", "Sem resultado no momento.");
        this.$store.commit("SetdialogNull", true);
      }
    },
    async leadsRetorno() {
      const filtro = this.top500Leads.filter(
        e =>
          e.ZZQ_RESP === this.user.id &&
          e.ZZQ_STATUS.trim() === "000007-Retornar" &&
          this.verdata(e.ZZQ_DTRET),
      );

      if (filtro.length > 0) {
        this.painelAplicado = true;
        this.TituloPainel = "Meus lead(s) com retorno até hoje";
        this.DetalhesPainel = `Total de ${filtro.length} registro(s)`;
        this.itemLeads = filtro;
        this.itemsRelatExcel = filtro;
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit("SetMensagemDialogOps", "Sem resultado no momento.");
        this.$store.commit("SetdialogNull", true);
      }
    },
    verdata(data) {
      let dataFormat = data.split("/");
      let dataItem = `${dataFormat[2]}-${dataFormat[1]}-${dataFormat[0]}`;
      let dataParse = Date.parse(dataItem);
      let hoje = new Date();
      hoje = Date.parse(hoje);
      if (dataParse <= hoje) {
        return true;
      } else {
        return false;
      }
    },
    async histLead() {
      this.itensTimeLine = [];

      if (this.selectLeads.length <= 0 || this.selectLeads.length > 1) {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit(
          "SetMensagemDialogOps",
          "Selecione apenas um Lead para ver o histórico.",
        );
        this.$store.commit("SetdialogNull", true);
      } else {
        this.dialogPaineis = true;
        this.messagePainel = `Buscando logs, aguarde...`;
        const id = this.selectLeads[0].ZZQ_ID;

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS:
            "ZZS_DATA,ZZS_HORA,ZZS_OPER,ZS1.ZS1_NOME US,ZSS1.ZS1_NOME USER_RESP",
          WHERE: `ZZS_ID='${id}' AND ZZS_ORIGEM='LEAD' ORDER BY ZZS.R_E_C_N_O_ DESC`,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/timeLine`;

        await axios
          .post(url, dados)
          .then(res => {
            res.data.forEach(e => {
              if (e.ZZS_OPER.trim() === "ATRIBUICAO") {
                this.itensTimeLine.push({
                  color: "blue darken-3",
                  icon: "mdi-account-multiple-plus",
                  operacao: e.ZZS_OPER.trim(),
                  caption1: e.US.substring(0, 13),
                  caption2: `${e.ZZS_DATA.substring(6, 8)}/${e.ZZS_DATA.substring(
                    4,
                    6,
                  )}/${e.ZZS_DATA.substring(0, 4)} ${e.ZZS_HORA}`,
                });
              }
              if (e.ZZS_OPER.trim() === "ALTERACAO") {
                this.itensTimeLine.push({
                  color: "green darken-3",
                  icon: "mdi-account-edit",
                  operacao: e.ZZS_OPER.trim(),
                  caption1: e.US.substring(0, 13),
                  caption2: `${e.ZZS_DATA.substring(6, 8)}/${e.ZZS_DATA.substring(
                    4,
                    6,
                  )}/${e.ZZS_DATA.substring(0, 4)} ${e.ZZS_HORA}`,
                });
              }
              if (e.ZZS_OPER.trim() === "TRANSFERENCIA") {
                this.itensTimeLine.push({
                  color: "yellow darken-3",
                  icon: "mdi-transfer",
                  operacao: e.ZZS_OPER.trim(),
                  caption1: ` => ${e.US.substring(0, 13)}`,
                  caption2: `${e.ZZS_DATA.substring(6, 8)}/${e.ZZS_DATA.substring(
                    4,
                    6,
                  )}/${e.ZZS_DATA.substring(0, 4)} ${e.ZZS_HORA}`,
                });
              }
            });
            if (res.data.length <= 0) {
              this.itensTimeLine.push({
                color: "red darken-3",
                icon: "mdi-close",
                operacao: "SEM DADOS",
                caption1: `Sem histórico no momento`,
                caption2: ``,
              });
            }
            this.dialogPaineis = false;
            this.dialogTimeLine = true;
            this.messagePainel = null;
          })
          .catch(err => {
            console.log(err);
            this.dialogPaineis = false;
            this.messagePainel = null;
          });

        //
      }
    },
    fechaDialogTransf() {
      this.RespTransf = null;
      this.dialogTransfLead = false;
    },
    transfereLead() {
      console.log(this.selectLeads);
      if (this.selectLeads.length > 0) {
        if (!this.isAdmin) {
          const meus = this.selectLeads.filter(
            e => e.ZZQ_RESP === this.user.id,
          );

          if (meus.length !== this.selectLeads.length) {
            this.painelAplicado = false;
            this.TituloPainel = null;
            this.DetalhesPainel = null;
            this.$store.commit(
              "SetMensagemDialogOps",
              "Foi marcado lead(s) que ainda não esta sobre sua responsabilidade, para transferencia marque somente Leads seus.",
            );
            this.$store.commit("SetdialogNull", true);
          } else {
            this.dialogTransfLead = true;
          }
        } else {
          this.dialogTransfLead = true;
        }
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit(
          "SetMensagemDialogOps",
          "Marque seu(s) registro(s) para transferir!",
        );
        this.$store.commit("SetdialogNull", true);
      }
    },
    async efetivaTransferencia() {
      this.dialogPaineis = true;
      this.messagePainel = `Transferindo lead(s), aguarde...`;
      let dados = {
        CABECALHO: {
          EMPRESA: "01",
          FILIAL: "0101",
          OPER: "T",
        },
      };
      dados.ZZQ = [];
      this.selectLeads.forEach(e => {
        dados.ZZQ.push({
          ZZQ_ID: e.ZZQ_ID,
          ZZQ_SITUAC: "A",
          ZZQ_RESP: this.RespTransf.substring(0, 6),
        });
      });

      const url = `${process.env.VUE_APP_BASE_API_URL}/atribuiLead`;

      await axios
        .put(url, dados)
        .then(res => {
          console.log(res.data);
          this.dialogPaineis = false;
          this.messagePainel = null;
          this.selectLeads = [];
          this.RespTransf = null;
          this.dialogTransfLead = false;
          this.montaBrowser();
        })
        .catch(err => {
          console.log(err);
          this.dialogPaineis = false;
          this.messagePainel = null;
        });
    },
    meusLeads() {
      const filtro = this.top500Leads.filter(e => e.ZZQ_RESP === this.user.id);

      if (filtro.length > 0) {
        this.painelAplicado = true;
        this.TituloPainel = "Meus leads";
        this.DetalhesPainel = `Total de ${filtro.length} registro(s)`;
        this.itemLeads = filtro;
        this.itemsRelatExcel = filtro;
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit("SetMensagemDialogOps", "Sem resultado no momento.");
        this.$store.commit("SetdialogNull", true);
      }
    },
    marcaItem(item) {
      const marcado = this.selectLeads.filter(e => e.ZZQ_ID === item.ZZQ_ID);

      if (marcado.length <= 0) {
        this.selectLeads.push(item);
      } else {
        this.selectLeads = this.selectLeads.filter(
          e => e.ZZQ_ID !== item.ZZQ_ID,
        );
      }
    },
    async atribuirLeads() {
      if (this.selectLeads.length > 0) {
        const meus = this.selectLeads.filter(e => e.ZZQ_SITUAC === "Novo");

        if (meus.length !== this.selectLeads.length) {
          this.painelAplicado = false;
          this.TituloPainel = null;
          this.DetalhesPainel = null;
          this.$store.commit(
            "SetMensagemDialogOps",
            "Foi marcado lead(s) que já esta atribuído a um responsável, marque somente aqueles que não possuem responsável.",
          );
          this.$store.commit("SetdialogNull", true);
        } else {
          this.dialogPaineis = true;
          this.messagePainel = `Atribuindo leads, aguarde...`;
          let dados = {
            CABECALHO: {
              EMPRESA: "01",
              FILIAL: "0101",
              OPER: "A",
            },
          };
          dados.ZZQ = [];
          this.selectLeads.forEach(e => {
            dados.ZZQ.push({
              ZZQ_ID: e.ZZQ_ID,
              ZZQ_SITUAC: "A",
              ZZQ_RESP: this.user.id,
            });
          });

          const url = `${process.env.VUE_APP_BASE_API_URL}/atribuiLead`;

          await axios
            .put(url, dados)
            .then(res => {
              console.log(res.data);
              this.dialogPaineis = false;
              this.messagePainel = null;
              this.selectLeads = [];
              this.montaBrowser();
            })
            .catch(err => {
              console.log(err);
              this.dialogPaineis = false;
              this.messagePainel = null;
            });
        }
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit(
          "SetMensagemDialogOps",
          "Sem registro(s) marcado(s).",
        );
        this.$store.commit("SetdialogNull", true);
      }
    },
    abrePainel() {
      this.drawer = !this.drawer;
      this.mini = false;
    },
    async verTipoFiltro() {
      this.itensFiltroEsc = [];

      if (this.filtroEscolhido === "Responsável") {
        const dados = {
          FIELDS: "DISTINCT ZS1_NOME, ZS1_COD",
          WHERE: "ZS1_MSBLQ<>'2'",
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/leads`;

        const leads = await axios
          .post(url, dados)
          .then(res => res.data)
          .catch(err => console.log(err));

        leads.forEach(lead => {
          if (lead.ZS1_NOME !== null) {
            this.itensFiltroEsc.push({
              text: lead.ZS1_NOME.trim(),
              value: lead.ZS1_COD,
            });
          }
        });
      }

      if (this.filtroEscolhido === "Status") {
        this.itensFiltroEsc = [
          { text: "Reagendar", value: "000001" },
          { text: "Atendimento realizado", value: "000002" },
          { text: "Perdido", value: "000003" },
          { text: "Lojas Opimed", value: "000004" },
          { text: "Outras marcas", value: "000005" },
          { text: "Ligou errado", value: "000006" },
          { text: "Retornar", value: "000007" },
          { text: "Teste", value: "000008" },
          { text: "Agendado", value: "000009" },
          { text: "Outras informações", value: "999999" },
        ];
      }

      if (this.filtroEscolhido === "Origem") {
        this.itensFiltroEsc = [
          { text: "FACEBOOK", value: "FACEBOOK" },
          { text: "LIGAÇÃO", value: "LIGACAO" },
          { text: "SITE", value: "DIF" },
        ];
      }

      if (this.filtroEscolhido === "Qualificado") {
        this.itensFiltroEsc = [
          { text: "Sim", value: "1" },
          { text: "Não", value: "2" },
        ];
      }

      if (this.filtroEscolhido === "Situação") {
        this.itensFiltroEsc = [
          { text: "Novo", value: "N" },
          { text: "Aberto", value: "A" },
          { text: "Encerrado", value: "E" },
          { text: "Sem contato", value: "S" },
        ];
      }
    },
    LimpaFiltroPainel() {
      this.stringFiltro = null;
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.itensFiltroEsc = [];
      this.painelAplicado = false;
      this.TituloPainel = null;
      this.DetalhesPainel = null;
      this.itemLeads = this.top500Leads;
      this.itemsRelatExcel = this.top500Leads;
    },
    LimpaFiltro() {
      this.stringFiltro = null;
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.itensFiltroEsc = [];
      this.dtInicial = "";
      this.dtFinal = "";
      if (!this.painelAplicado) {
        this.itemLeads = this.top500Leads;
        this.itemsRelatExcel = this.top500Leads;
      } else {
        this.meusLeads();
      }
    },
    async filtraRegistro(campo, stringFiltro) {
      let WHERE = "";

      if (!campo && this.dtInicial && this.dtFinal) {
        if (!this.isAdmin) {
          if (this.painelAplicado) {
            WHERE += ` AND ZZQ_RESP='${this.user.id}'`;
          }
        } else {
          const dtIni = this.dtInicial.split("/");
          const dtFim = this.dtFinal.split("/");

          WHERE += `ZZQ_DTINC BETWEEN '${dtIni[2]}${dtIni[1]}${dtIni[0]}' AND '${dtFim[2]}${dtFim[1]}${dtFim[0]}'`;
        }
      } else {
        if (!campo || !stringFiltro) return;

        if (campo === "ID") {
          WHERE = `ZZQ_ID LIKE '%${stringFiltro.toUpperCase()}%'`;

          // this.itemLeads = this.itemLeads.filter((e) =>
          //   e.ZZQ_ID.includes(this.removeAcento(stringFiltro))
          // );
          // this.NrRegFiltro = this.itemLeads.length;
          // return;
        }

        if (campo === "Nome da pessoa p/ tratamento") {
          WHERE = `ZZQ_PSTRAT LIKE '%${stringFiltro.toUpperCase()}%'`;

          // this.itemLeads = this.itemLeads.filter((e) =>
          //   e.ZZQ_PSTRAT.includes(stringFiltro)
          // );
          // this.NrRegFiltro = this.itemLeads.length;
          // return;
        }

        if (campo === "Nome") {
          WHERE = `ZZQ_NOME LIKE '%${stringFiltro.toUpperCase()}%'`;
        }

        if (campo === "E-mail") {
          WHERE = `ZZQ_EMAIL LIKE '%${stringFiltro.toLowerCase()}%'`;
        }

        if (campo === "Telefone") {
          WHERE = `ZZQ_TEL LIKE '%${stringFiltro}%'`;
        }

        if (campo === "Status") {
          WHERE = `ZZQ_STATUS = '${stringFiltro}'`;
        }

        if (campo === "Qualificado") {
          WHERE = `ZZQ_QUALIF = '${stringFiltro}'`;
        }

        if (campo === "Situação") {
          WHERE = `ZZQ_SITUAC = '${stringFiltro}'`;
        }

        if (campo === "Responsável") {
          const codResponsavel = stringFiltro.split("-");

          WHERE = `ZZQ_RESP = '${codResponsavel[0]}'`;
        }

        if (campo === "Origem") {
          if (stringFiltro.trim() === "DIF") {
            WHERE = `ZZQ_PAG NOT IN ('FACEBOOK','LIGACAO') `;
          } else {
            WHERE = `ZZQ_PAG = '${stringFiltro}'`;
          }
        }

        if (!this.isAdmin) {
          if (this.painelAplicado) {
            WHERE += ` AND ZZQ_RESP='${this.user.id}'`;
          }
        } else {
          const dtIni = this.dtInicial.split("/");
          const dtFim = this.dtFinal.split("/");

          WHERE += ` AND ZZQ_DTINC BETWEEN '${dtIni[2]}${dtIni[1]}${dtIni[0]}' AND '${dtFim[2]}${dtFim[1]}${dtFim[0]}'`;
        }
      }

      WHERE +=
        " GROUP BY ZZQ_PAG,A1_NOME,ZZQ_LOJA,ZZR_DESCRI,ZS1_NOME,A1_COD,A1_LOJA,ZZQ_URLGRV,ZZQ_CAMPF,ZZQ_DTINC,ZZQ_HRINC,ZZQ_SITUAC,ZZQ_RESP,ZZQ_QUALIF,ZZQ_STATUS,ZZQ_DTRET,ZZQ_ID,ZZQ_EMAIL,ZZQ_NOME,ZZQ_TEL,ZZQ_TEL1,ZZQ_WHATS,ZZQ_TELDES,ZZQ_STLIGA,ZZQ_LIGDUR,ZZQ_LIGFAL,ZZQ_PSTRAT,ZZQ_PESTRA,ZZQ_LAUDOM,ZZQ_APUSER,ZZQ_ISCLI,ZZQ_CPF,ZZQ_CLIENT,ZZQ_EST,ZZQ_CIDADE,ZZQ_DEFEIT,ZZQ_DEFEI1,ZZQ_IDFORM,ZZQ_IDLFAC,ZZQ_DTAGEN,ZZQ_HRAGEN,ZZQ_CDATEN,ZZQ_CLIADS,ZZQ_DTNASC,ZZQ010.R_E_C_N_O_ ";
      WHERE += " ORDER BY ZZQ_DTINC DESC";

      this.loading = true;
      this.itemLeads = [];
      this.itemsRelatExcel = [];

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: WHERE,
        TABELA: "ZZQ",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/leads`; // Consulta ZZQ com filtros

      // pesquisa com filtro
      // console.log("busca na base dados: ", {dados, url})

      await axios
        .post(url, dados)
        .then(res => {
          console.log("Lead na pesquisa ==>", res.data);
          res.data.map(e => {
            if (e.ZZQ_DTINC) {
              e.ZZQ_DTINC = `${e.ZZQ_DTINC.substring(6, 8)}/${e.ZZQ_DTINC.substring(
                4,
                6,
              )}/${e.ZZQ_DTINC.substring(0, 4)}`;
            }
            if (e.ZZR_DESCRI) {
              e.ZZQ_STATUS = `${e.ZZQ_STATUS}-${e.ZZR_DESCRI}`;
            }
            if (e.ZZQ_SITUAC === "N") e.ZZQ_SITUAC = "Novo";
            if (e.ZZQ_SITUAC === "A") e.ZZQ_SITUAC = "Aberto";
            if (e.ZZQ_SITUAC === "E") e.ZZQ_SITUAC = "Encerrado";
            if (e.ZZQ_SITUAC === "S") e.ZZQ_SITUAC = "Sem contato";
            if (e.ZZQ_QUALIF === "1") e.ZZQ_QUALIF = "Sim";
            if (e.ZZQ_QUALIF === "2") e.ZZQ_QUALIF = "Não";

            if (e.ZZQ_PESTRA && e.ZZQ_PESTRA === "M") e.ZZQ_PESTRA = "Min";
            if (e.ZZQ_PESTRA && e.ZZQ_PESTRA === "F") e.ZZQ_PESTRA = "Familiar";
            if (e.ZZQ_PESTRA && e.ZZQ_PESTRA === "A") e.ZZQ_PESTRA = "Amigo";

            if (e.ZZQ_ISCLI === "1") e.ZZQ_ISCLI = "Sim";
            if (e.ZZQ_ISCLI === "2") e.ZZQ_ISCLI = "Não";

            if (e.ZZQ_LAUDOM === "1") e.ZZQ_LAUDOM = "Sim";
            if (e.ZZQ_LAUDOM === "2") e.ZZQ_LAUDOM = "Não";

            if (e.ZZQ_APUSER === "1") e.ZZQ_APUSER = "Sim";
            if (e.ZZQ_APUSER === "2") e.ZZQ_APUSER = "Não";

            if (e.ZZQ_STLIGA === "A") e.ZZQ_STLIGA = "A=Atendida";
            e.ZZQ_MARQ = false;
            const hora = e.ZZQ_HRINC ? e.ZZQ_HRINC.split(":") : null;

            if (e.ZZQ_RESP.trim() !== "") {
              e.ZS1_NOME = `${e.ZZQ_RESP}-${e.ZS1_NOME}`;
            }

            if (hora[0].length <= 1) {
              e.ZZQ_HRINC = `0${e.ZZQ_HRINC}`;
            }
          });
          this.itemLeads = res.data;
          this.itemsRelatExcel = res.data;
          this.NrRegFiltro = this.itemLeads.length;
        })
        .catch(err => console.log(err));

      this.loading = false;
    },
    VerDetLeads(item) {
      console.log(this.$children.find(e => console.log(e.$options.name)));
      this.snackbar_error = false;
      const componenteFilho = this.$children.find(
        component => component.$options.name === "DetLeads",
      );
      componenteFilho.buscaDados(item.ZZQ_ID, item.ZZQ_NOME);
    },
    // VerDetalhes(item) {
    //   this.codCli = item.A1_COD;
    //   this.lojaCli = item.A1_LOJA;
    //   const componenteFilho = this.$children.find(
    //     (component) => component.$options.name === "DetCli"
    //   );
    //   componenteFilho.buscaDados(item.A1_COD, item.A1_LOJA, item.A1_NOME);
    // },
    obrigat(value, obrigatorio) {
      if (!value && obrigatorio === "1") {
        return ["Campo obrigatório!"];
      }
    },
    queryAndIndeterminate() {
      this.progress = 0;

      this.interval = setInterval(() => {
        if (this.progress === 100) {
          clearInterval(this.interval);
          this.progress = 0;
          return;
          //return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.progress += 5;
      }, 1000);
    },
    fechaDialogExclui() {
      this.dialogExcluiLead = false;
      this.itemsLeadsExclui = [];
      this.leadRedundante = "";
      this.leadAEscluir = null;
    },
    async concluiExcluLead() {
      if (!this.leadAEscluir) return;
      if (!this.leadRedundante) return;

      this.snackbar_error = false;

      const [recnoParaExcluir, idLeadRedundante] = this.leadRedundante.split(
        "-",
      );

      // const date = new Date().toString();

      this.progressDel = true;
      this.loading = true;

      const ZZQ = {
        R_E_C_N_O_: recnoParaExcluir,
        ZZQ_FILIAL: "    ",
        ZZQ_ID: this.leadAEscluir.ZZQ_ID,
        // ZZQ_USRDEL: this.user.name.trim(),
        // ZZQ_LOGDEL: date,
        ZZQ_LBASE: idLeadRedundante,
        ZZQ_RESP: this.user.id,
      };
      // console.log(ZZQ);

      const url = `${process.env.VUE_APP_BASE_API_URL}/delLead`;

      await axios
        .delete(url, {
          data: {
            EMPRESA: "01",
            FILIAL: "0101",
            ZZQ,
          },
        })
        .then(() => {
          this.progressDel = false;
          this.snackbar_error = true;
        })
        .catch(err => console.log(err));

      this.itemLeads = this.itemLeads.filter(e => e.RECNO !== recnoParaExcluir);
      this.top500Leads = this.top500Leads.filter(
        e => e.RECNO !== recnoParaExcluir,
      );
      this.itemsRelatExcel = this.itemsRelatExcel.filter(
        e => e.RECNO !== recnoParaExcluir,
      );

      this.error = `Lead ID: ${this.leadRedundante}, excluído com sucesso!`;
      this.itemsLeadsExclui = [];
      this.leadRedundante = "";
      this.leadAEscluir = null;
      this.dialogExcluiLead = false;
      // this.snackbar_error = false;
      this.loading = false;
    },
    //Função para remover caracteres especiais na Notas
    removeAcento(text) {
      if (text.trim() !== "") {
        text = text.toLowerCase();
        text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
        text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
        text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
        text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
        text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
        text = text.replace(new RegExp("[Ç]", "gi"), "c");
        return text.toUpperCase();
      } else {
        return "";
      }
    },
    async salva(id) {
      const keysObrigat = [];
      this.camposTela.filter(e => {
        if (e.ZZP_OBRIGA === "1") {
          const campo = e.ZZP_CAMPO.trim();
          keysObrigat.push({
            campo,
          });
        }
      });

      let verifica = true;

      this.cloneDadosTela = this.dadosTela;

      const keys = Object.keys(this.cloneDadosTela);

      keysObrigat.forEach(e => {
        if (keys.indexOf(e.campo) < 0) {
          verifica = false;
        }
      });

      if (!verifica) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      if (
        this.cloneDadosTela.ZZQ_STATUS &&
        this.cloneDadosTela.ZZQ_STATUS.trim() === "000007-Retornar" &&
        !this.cloneDadosTela.ZZQ_DTRET
      ) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Quando o Status for igual a retornar, é obrigatório o preenchimento da data de retorno!`;
        this.sheetMessage = true;
        return;
      }

      const data = new Date();
      const ano = data.getFullYear().toString();
      let mes = (data.getMonth() + 1).toString();
      let dia = data.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      const hoje = Date.parse(`${ano}-${mes}-${dia}`);

      if (this.cloneDadosTela.ZZQ_DTRET) {
        let dataFormat = this.cloneDadosTela.ZZQ_DTRET.split("/");
        let dataItem = `${dataFormat[2]}-${dataFormat[1]}-${dataFormat[0]}`;
        let dataParse = Date.parse(dataItem);

        if (dataParse < hoje) {
          this.iconMessage = "mdi-close";
          this.colorMessage = "red lighten-4";
          this.message = `Data de retorno tem que ser maior ou igual a data atual.`;
          this.sheetMessage = true;
          return;
        }
      }
      this.camposTela.forEach(e => {
        let value = null;
        if (
          e.ZZP_TIPO.trim() === "A" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          console.log(e.ZZP_CAMPO);
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("-");
          if (e.ZZP_CAMPO.trim() === "ZZQ_CIDADE") {
            this.cloneDadosTela[e.ZZP_CAMPO.trim()] = `${value[0]}-${value[1]}`;
          } else {
            this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0].trim();
          }
        }
        if (
          e.ZZP_TIPO.trim() === "S" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("=");
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
        }
        if (
          e.ZZP_TIPO.trim() === "D" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()]
        ) {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("/");
          this.cloneDadosTela[
            e.ZZP_CAMPO.trim()
          ] = `${value[2]}${value[1]}${value[0]}`;
        }
        if (
          e.ZZP_TIPO.trim() === "T" &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] &&
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] !== "ZZQ_EMAIL"
        ) {
          value = this.removeAcento(this.cloneDadosTela[e.ZZP_CAMPO.trim()]);
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value.trim();
        }
        if (
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] &&
          e.ZZP_TIPO.trim() === "Y"
        ) {
          value = this.removeAcento(this.cloneDadosTela[e.ZZP_CAMPO.trim()]);
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value.trim();
        }
      });

      let campoEmail = ``;
      if (this.cloneDadosTela.ZZQ_EMAIL)
        campoEmail = this.cloneDadosTela.ZZQ_EMAIL.toLowerCase();

      this.cloneDadosTela.ZZQ_EMAIL = campoEmail;

      const hora = data.getHours();
      let minuto = data.getMinutes();
      if (minuto < 10) {
        minuto = `0${minuto}`;
      }

      this.queryAndIndeterminate();
      if (id) {
        let ZZQ = { ...this.cloneDadosTela };

        ZZQ.ZZQ_ID = id;
        //ZZQ.ZZQ_DTINC = `${ano}${mes}${dia}`;
        //ZZQ.ZZQ_HRINC = `${hora}:${minuto}`;
        if (!ZZQ.ZZQ_SITUAC) {
          ZZQ.ZZQ_SITUAC = "N";
        }

        if (ZZQ.ZZQ_SITUAC === "E") {
          const dataNow = new Date();

          const ano = dataNow.getFullYear().toString();
          let mes = (dataNow.getMonth() + 1).toString();
          let dia = dataNow.getDate().toString();

          if (mes.length < 2) mes = "0" + mes;

          if (dia.length < 2) dia = "0" + dia;

          ZZQ.ZZQ_DTENC = `${ano}${mes}${dia}`;
        } else {
          ZZQ.ZZQ_DTENC = "";
        }

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZZQ,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/saveLeads`;
        this.ultimo_lead_editado = ``;
        await axios
          .put(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.mensagem = `Lead salvo com o código: ${res.data.CODIGO}`;
              this.$store.commit("SetReload", true);
              this.dialogAwaitSaveCliente = false;
              this.dialogSuccessSaveCliente = true;
              this.progress = 100;
              this.cloneDadosTela.ZZQ_RESP = `${this.cloneDadosTela.ZS1_NOME}`;
              this.cloneDadosTela.ZZQ_STATUS = `${this.cloneDadosTela.ZZQ_STATUS}-${this.cloneDadosTela.ZZR_DESCRI}`;
              if (this.cloneDadosTela.ZZQ_SITUAC === "A")
                this.cloneDadosTela.ZZQ_SITUAC = `${this.cloneDadosTela.ZZQ_SITUAC}=Aberto`;
              if (this.cloneDadosTela.ZZQ_SITUAC === "N")
                this.cloneDadosTela.ZZQ_SITUAC = `${this.cloneDadosTela.ZZQ_SITUAC}=Novo`;
              if (this.cloneDadosTela.ZZQ_SITUAC === "E")
                this.cloneDadosTela.ZZQ_SITUAC = `${this.cloneDadosTela.ZZQ_SITUAC}=Encerrado`;
              if (this.cloneDadosTela.ZZQ_SITUAC === "S")
                this.cloneDadosTela.ZZQ_SITUAC = `${this.cloneDadosTela.ZZQ_SITUAC}=Sem contato`;

              this.ultimo_lead_editado = ` ID: ${ZZQ.ZZQ_ID} / NOME: ${ZZQ.ZZQ_NOME} / ORIGEM: ${ZZQ.ZZQ_PAG} / E-MAIL: ${ZZQ.ZZQ_EMAIL}`;
            } else {
              console.log(res.data);
              this.aError = [];
              const erroTela = res.data.ERRO.split("\\");
              erroTela.map(e => (e = e.trim()));
              this.aError = erroTela.filter(e => e.includes("nErro"));
              // console.log(erroTela)
              // const err = res.data.ERRO.split("\n");
              // err.forEach(e => {
              //   this.aError.push({ text: `${e}`, icon: "mdi-send" });
              // });
              this.dialogAwaitSaveCliente = false;
              this.dialogErrosCliente = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => {
            console.log(err);
            this.aError = [];
            this.aError.push({ text: `${err}`, icon: "mdi-send" });

            this.dialogAwaitSaveCliente = false;
            this.dialogErrosCliente = true;
            this.progress = 100;
            return;
          });
      } else {
        let ZZQ = { ...this.cloneDadosTela };

        // ZZQ.ZZQ_PAG = "Inc. Manual";
        ZZQ.ZZQ_DTINC = `${ano}${mes}${dia}`;
        ZZQ.ZZQ_HRINC = `${hora}:${minuto}`;

        if (!ZZQ.ZZQ_SITUAC) {
          ZZQ.ZZQ_SITUAC = "N";
        }

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZZQ,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/saveLeads`;
        await axios
          .post(url, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.idSalvo = res.data.CODIGO;
              this.dadosTela.ZZQ_ID = this.idSalvo;
              this.mensagem = `Lead salvo com o código: ${res.data.CODIGO}`;
              this.$store.commit("SetReload", true);
              this.dialogAwaitSaveCliente = false;
              this.dialogSuccessSaveCliente = true;
              this.progress = 100;
            } else {
              this.aError = [];
              const erroTela = res.data.ERRO.split("\\");
              erroTela.map(e => (e = e.trim()));
              this.aError = erroTela.filter(e => e.includes("nErro"));
              this.dialogAwaitSaveCliente = false;
              this.dialogErrosCliente = true;
              this.progress = 100;
              return;
            }
          })
          .catch(err => console.log(err));
      }

      this.camposTela.forEach(e => {
        let value = null;
        if (e.ZZP_TIPO.trim() === "D") {
          value = this.cloneDadosTela[e.ZZP_CAMPO.trim()];
          this.cloneDadosTela[e.ZZP_CAMPO.trim()] = `${value.substring(
            6,
            8,
          )}/${value.substring(4, 6)}/${value.substring(0, 4)}`;
        }
      });
      this.progress = 100;
    },
    deleteLead(item) {
      this.snackbar_error = false;
      this.leadAEscluir = item;

      if (item.ZZL_ID) {
        this.snackbar_error = true;
        this.error = `O Lead ${
          item.ZZQ_ID
        }-${item.ZZQ_NOME?.trim()}, possui agendamento, não pode ser excluído!`;
        this.leadAEscluir = null;
        return;
      }

      this.dialogExcluiLead = true;
      this.nomeExclui = item.ZZQ_NOME?.trim();

      this.itemLeads.forEach(e => {
        if (e.RECNO !== item.RECNO) {
          this.itemsLeadsExclui.push({
            text: `${e.ZZQ_ID}-${e.ZZQ_NOME}`,
            value: `${item.RECNO}-${e.ZZQ_ID}-${e.ZZQ_NOME}`,
          });
        }
      });
    },
    limpaVariaveis() {
      this.DialogDados = false;
      this.dialogSuccessSaveCliente = false;

      if (this.dadosTela.ZZQ_SITUAC === "N=Novo")
        this.dadosTela.ZZQ_SITUAC = "Novo";
      if (this.dadosTela.ZZQ_SITUAC === "A=Aberto")
        this.dadosTela.ZZQ_SITUAC = "Aberto";
      if (this.dadosTela.ZZQ_SITUAC === "E=Encerrado")
        this.dadosTela.ZZQ_SITUAC = "Encerrado";
      if (this.dadosTela.ZZQ_SITUAC === "S=Sem contato")
        this.dadosTela.ZZQ_SITUAC = "Sem contato";
      if (this.dadosTela.ZZQ_QUALIF === "1=Sim")
        this.dadosTela.ZZQ_QUALIF = "Sim";
      if (this.dadosTela.ZZQ_QUALIF === "2=Não")
        this.dadosTela.ZZQ_QUALIF = "Não";

      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "M=Min")
        this.dadosTela.ZZQ_PESTRA = "Min";
      if (
        this.dadosTela.ZZQ_PESTRA &&
        this.dadosTela.ZZQ_PESTRA === "F=Familiar"
      )
        this.dadosTela.ZZQ_PESTRA = "Familiar";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "A=Amigo")
        this.dadosTela.ZZQ_PESTRA = "Amigo";

      if (this.dadosTela.ZZQ_ISCLI === "1=Sim")
        this.dadosTela.ZZQ_ISCLI = "Sim";
      if (this.dadosTela.ZZQ_ISCLI === "2=Não")
        this.dadosTela.ZZQ_ISCLI = "Não";

      if (this.dadosTela.ZZQ_LAUDOM === "1=Sim")
        this.dadosTela.ZZQ_LAUDOM = "Sim";
      if (this.dadosTela.ZZQ_LAUDOM === "2=Não")
        this.dadosTela.ZZQ_LAUDOM = "Não";

      if (this.dadosTela.ZZQ_APUSER === "1=Sim")
        this.dadosTela.ZZQ_APUSER = "Sim";
      if (this.dadosTela.ZZQ_APUSER === "2=Não")
        this.dadosTela.ZZQ_APUSER = "Não";

      const resp = this.dadosTela.ZZQ_RESP.split("-");

      this.dadosTela.ZZQ_RESP = resp[0];

      if (this.isReload && !this.painelAplicado) {
        this.montaBrowser();
      }
      if (this.painelAplicado) {
        this.meusLeads();
      }
      this.isEditing = false;
      this.isNew = false;
      this.isReading = false;
      this.$store.commit("setOrigem", "");
    },
    async carregaDados() {
      this.boilerplate = false;
      let campos = [];

      this.countObrigatorio = null;

      let ZZP = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === "MD8" && e.ZZP_USADO === "1",
      );

      ZZP.map(e => {
        if (e.ZZP_CBOX.trim().length > 0) {
          const dados = e.ZZP_CBOX.split(";");
          e.ITEMS = dados.map(i => {
            return `${i}`;
          });
        }

        if (e.ZZP_TIPO.trim() === "D") {
          e.MASK = "##/##/####";
        }
        if (e.ZZP_OBRIGA === "1") {
          this.countObrigatorio++;
        }
      });

      campos = ZZP;

      if (this.isNew) {
        this.dadosTela.ZZQ_RESP1 = `${this.user.id}-${this.user.name}`;
        this.dadosTela.ZZQ_SITUAC = `N=Novo`;
      }

      this.camposTela = campos;

      this.boilerplate = true;
    },
    async reload(item) {
      item.ITEMS = await this.chamaConsulta(item);

      this.camposTela.map(e => {
        if (e.ZZP_CAMPO.trim() === item.ZZP_CAMPO.trim()) {
          e.ITEMS = item.ITEMS;
        }
      });

      return this.camposTela;
    },
    chamaConsulta(item) {
      let dados = {};
      let items = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: ``,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      return items;
    },
    async montaBrowser() {
      this.filtroEscolhido = null;
      this.stringFiltro = null;
      this.NrRegFiltro = null;
      this.altura = window.screen.availHeight - 300;
      this.loading = true;
      let ZZP = [];
      let dados = {};
      let url = ``;
      this.HeadresLeads = [];
      this.HeadresLeads.push({
        text: "Ações",
        value: "actions",
        align: "start",
        width: 180,
        caption: "title",
      });

      ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD8");

      ZZP.map(e => {
        let width = 0;
        if (e.ZZP_COLS <= 2) {
          width = 130;
        } else if (e.ZZP_COLS <= 3) {
          width = 200;
        } else {
          width = 250;
        }
        const name = `${e.ZZP_CAMPO.trim()}`;
        if (e.ZZP_BROWSE === "1") {
          if (name === "ZZQ_ID") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
          }
          if (name === "ZZQ_PSTRAT") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
          }
          if (this.isAdmin) {
            if (name === "ZS1_NOME") {
              this.itemsFiltro.push(e.ZZP_TITULO.trim());
              this.HeadresLeads.push({
                text: e.ZZP_TITULO.trim(),
                value: e.ZZP_CAMPO.trim(),
                align: "center",
                width: width,
                caption: "title",
                filter: f => {
                  return (f + "")
                    .toLowerCase()
                    .includes(this["ZS1_NOME"].toLowerCase());
                },
              });
            }
          }
          this.itemsFiltro.push("Origem");
          if (name === "ZZQ_NOME") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresLeads.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
              filter: f => {
                return (f + "")
                  .toLowerCase()
                  .includes(this["ZZQ_NOME"].toLowerCase());
              },
            });
          }
          if (name === "ZZQ_EMAIL") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresLeads.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
              filter: f => {
                return (f + "")
                  .toLowerCase()
                  .includes(this["ZZQ_EMAIL"].toLowerCase());
              },
            });
          }
          if (name === "ZZQ_TEL") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresLeads.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
              filter: f => {
                return (f + "")
                  .toLowerCase()
                  .includes(this["ZZQ_TEL"].toLowerCase());
              },
            });
          }

          if (name === "ZZQ_STATUS") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresLeads.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
              filter: f => {
                return (f + "")
                  .toLowerCase()
                  .includes(this["ZZQ_STATUS"].toLowerCase());
              },
            });
          }

          if (name === "ZZQ_QUALIF") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresLeads.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
              filter: f => {
                return (f + "")
                  .toLowerCase()
                  .includes(this["ZZQ_QUALIF"].toLowerCase());
              },
            });
          }

          if (name === "ZZQ_SITUAC") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresLeads.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
              filter: f => {
                return (f + "")
                  .toLowerCase()
                  .includes(this["ZZQ_SITUAC"].toLowerCase());
              },
            });
          }

          if (this.isAdmin) {
            if (
              name !== "ZZQ_NOME" &&
              name !== "ZZQ_TEL" &&
              name !== "ZZQ_EMAIL" &&
              name !== "ZZQ_STATUS" &&
              name !== "ZZQ_QUALIF" &&
              name !== "ZZQ_SITUAC" &&
              name !== "ZS1_NOME"
            ) {
              this.HeadresLeads.push({
                text: e.ZZP_TITULO.trim(),
                value: e.ZZP_CAMPO.trim(),
                align: "center",
                width: width,
                caption: "title",
              });
            }
          } else {
            if (
              name !== "ZZQ_NOME" &&
              name !== "ZZQ_TEL" &&
              name !== "ZZQ_EMAIL" &&
              name !== "ZZQ_STATUS" &&
              name !== "ZZQ_QUALIF" &&
              name !== "ZZQ_SITUAC"
            ) {
              this.HeadresLeads.push({
                text: e.ZZP_TITULO.trim(),
                value: e.ZZP_CAMPO.trim(),
                align: "center",
                width: width,
                caption: "title",
              });
            }
          }
        }
      });

      const tabelaAux = [...new Set(ZZP.map(item => item.ZZP_TABELA))];

      this.stringCampos = `ZZQ010.R_E_C_N_O_ RECNO,`;

      let tabela = tabelaAux[0];

      ZZP.forEach(e => {
        if (e.ZZP_TABELA.trim() === tabela.trim()) {
          if (e.ZZP_TIPO === "Y") {
            this.stringCampos += `CONVERT(VARCHAR(2000), ${e.ZZP_CAMPO.trim()}) ${e.ZZP_CAMPO.trim()},`;
          } else if (e.ZZP_CAMPO.trim() === "ZZL_ID") {
            this.stringCampos += `MAX(${e.ZZP_CAMPO.trim()}) ${e.ZZP_CAMPO.trim()},`;
          } else {
            this.stringCampos += `${e.ZZP_CAMPO.trim()},`;
          }
        }
      });

      if (this.stringCampos.slice(-1)[0] === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      let WHERE = `ZZQ_ID <> ' '`;

      const dataRetro = "19900101";

      const dataNow = new Date();

      const ano = dataNow.getFullYear().toString();
      let mes = (dataNow.getMonth() + 1).toString();
      let dia = dataNow.getDate().toString();
      if (mes.length < 2) mes = "0" + mes;
      if (dia.length < 2) dia = "0" + dia;

      const Now = `${ano}${mes}${dia}`;

      if (this.filtroTela.length > 0) {
        if (this.filtroTela[0].card === "Meus Leads") {
          if (this.filtroTela[0].item === 0)
            WHERE = `ZZQ_RESP='${this.user.id}' AND ZZQ_SITUAC<>'E'`;
          if (this.filtroTela[0].item === 1)
            WHERE = `ZZQ_RESP='${this.user.id}' AND ZZQ_SITUAC<>'E' AND ZZQ_DTRET BETWEEN '${dataRetro}' AND '${Now}'`;
          if (this.filtroTela[0].item === 2)
            WHERE = `ZZQ_RESP='${this.user.id}' AND ZZQ_SITUAC<>'E' AND ZZQ_DTRET BETWEEN '${Now}' AND DATEADD(day, 7, getDate())`;
          if (this.filtroTela[0].item === 3)
            WHERE = `ZZQ_RESP='${this.user.id}' AND ZZQ_SITUAC<>'E' AND A1_COD<> ' '`;
        }
        if (this.filtroTela[0].card === "Leads da minha Equipe") {
          if (this.filtroTela[0].item === 0)
            WHERE = `ZZQ_RESP=' ' AND ZZQ_SITUAC<>'E'`;
          if (this.filtroTela[0].item === 1)
            WHERE = `ZZQ_SITUAC<>'E' AND A1_COD<>''`;
          if (this.filtroTela[0].item === 2)
            WHERE = `ZZQ_SITUAC='E' AND ZZQ_QUALIF='1' AND SUBSTRING(ZZQ_DTINC,0,7) = '${ano}${mes}'`;
        }
      }

      WHERE +=
        " GROUP BY ZZQ_PAG,A1_NOME,ZZQ_LOJA,ZZR_DESCRI,ZS1_NOME,A1_COD,A1_LOJA,ZZQ_URLGRV,ZZQ_CAMPF,ZZQ_DTINC,ZZQ_HRINC,ZZQ_SITUAC,ZZQ_RESP,ZZQ_QUALIF,ZZQ_STATUS,ZZQ_DTRET,ZZQ_ID,ZZQ_EMAIL,ZZQ_NOME,ZZQ_TEL,ZZQ_TEL1,ZZQ_WHATS,ZZQ_TELDES,ZZQ_STLIGA,ZZQ_LIGDUR,ZZQ_LIGFAL,ZZQ_PSTRAT,ZZQ_PESTRA,ZZQ_LAUDOM,ZZQ_APUSER,ZZQ_ISCLI,ZZQ_CPF,ZZQ_CLIENT,ZZQ_EST,ZZQ_CIDADE,ZZQ_DEFEIT,ZZQ_DEFEI1,ZZQ_IDFORM,ZZQ_IDLFAC,ZZQ_DTAGEN,ZZQ_HRAGEN,ZZQ_CDATEN,ZZQ_CLIADS,ZZQ_DTNASC,ZZQ010.R_E_C_N_O_ ";
      WHERE += " ORDER BY ZZQ_DTINC DESC";

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        // FIELDS: `ZZQ_PAG,A1_NOME,ZZQ_LOJA,ZZR_DESCRI,ZS1_NOME,A1_COD,A1_LOJA,ZZQ_URLGRV,ZZQ_CAMPF,ZZQ_DTINC,ZZQ_HRINC,ZZQ_SITUAC,ZZQ_RESP,ZZQ_QUALIF,ZZQ_STATUS,ZZQ_DTRET,ZZQ_ID,ZZQ_EMAIL,ZZQ_NOME,ZZQ_TEL,ZZQ_TEL1,ZZQ_WHATS,ZZQ_TELDES,ZZQ_STLIGA,ZZQ_LIGDUR,ZZQ_LIGFAL,ZZQ_PSTRAT,ZZQ_PESTRA,ZZQ_LAUDOM,ZZQ_APUSER,ZZQ_ISCLI,ZZQ_CPF,ZZQ_CLIENT,ZZQ_EST,ZZQ_CIDADE,ZZQ_DEFEIT,ZZQ_DEFEI1,ZZQ_IDFORM,ZZQ_IDLFAC,ZZQ_DTAGEN,ZZQ_HRAGEN,ZZQ_CDATEN,ZZQ_CLIADS`,
        WHERE: WHERE,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/leads`; // Consulta ZZQ completa

      this.itemLeads = [];
      this.itemsRelatExcel = [];

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.ZZQ_DTINC = `${e.ZZQ_DTINC.substring(6, 8)}/${e.ZZQ_DTINC.substring(
              4,
              6,
            )}/${e.ZZQ_DTINC.substring(0, 4)}`;
            e.ZZQ_DTRET.trim() !== ""
              ? (e.ZZQ_DTRET = `${e.ZZQ_DTRET.substring(
                  6,
                  8,
                )}/${e.ZZQ_DTRET.substring(4, 6)}/${e.ZZQ_DTRET.substring(0, 4)}`)
              : null;
            e.ZZQ_DTAGEN.trim() !== ""
              ? (e.ZZQ_DTAGEN = `${e.ZZQ_DTAGEN.substring(
                  6,
                  8,
                )}/${e.ZZQ_DTAGEN.substring(4, 6)}/${e.ZZQ_DTAGEN.substring(0, 4)}`)
              : null;
            if (e.ZZR_DESCRI) e.ZZQ_STATUS = `${e.ZZQ_STATUS}-${e.ZZR_DESCRI}`;
            if (e.ZZQ_SITUAC === "N") e.ZZQ_SITUAC = "Novo";
            if (e.ZZQ_SITUAC === "A") e.ZZQ_SITUAC = "Aberto";
            if (e.ZZQ_SITUAC === "E") e.ZZQ_SITUAC = "Encerrado";
            if (e.ZZQ_SITUAC === "S") e.ZZQ_SITUAC = "Sem contato";
            if (e.ZZQ_QUALIF === "1") e.ZZQ_QUALIF = "Sim";
            if (e.ZZQ_QUALIF === "2") e.ZZQ_QUALIF = "Não";

            if (e.ZZQ_PESTRA && e.ZZQ_PESTRA === "M") e.ZZQ_PESTRA = "Min";
            if (e.ZZQ_PESTRA && e.ZZQ_PESTRA === "F") e.ZZQ_PESTRA = "Familiar";
            if (e.ZZQ_PESTRA && e.ZZQ_PESTRA === "A") e.ZZQ_PESTRA = "Amigo";

            if (e.ZZQ_ISCLI === "1") e.ZZQ_ISCLI = "Sim";
            if (e.ZZQ_ISCLI === "2") e.ZZQ_ISCLI = "Não";

            if (e.ZZQ_LAUDOM === "1") e.ZZQ_LAUDOM = "Sim";
            if (e.ZZQ_LAUDOM === "2") e.ZZQ_LAUDOM = "Não";

            if (e.ZZQ_APUSER === "1") e.ZZQ_APUSER = "Sim";
            if (e.ZZQ_APUSER === "2") e.ZZQ_APUSER = "Não";

            if (e.ZZQ_STLIGA === "A") e.ZZQ_STLIGA = "A=Atendida";

            if (e.A1_COD) {
              e.ZZQ_ISCLI = "Sim";
              e.ZZQ_CLIENT = e.A1_COD;
              e.ZZQ_LOJA = e.A1_LOJA;
            }

            if (e.ZZQ_RESP.trim() !== "")
              e.ZS1_NOME = `${e.ZZQ_RESP}-${e.ZS1_NOME}`;

            const hora = e.ZZQ_HRINC.split(":");

            if (hora[0].length <= 1) {
              e.ZZQ_HRINC = `0${e.ZZQ_HRINC}`;
            }
          });
          this.leadsCompleto = res.data;
          this.itemLeads = [];
          this.itemsRelatExcel = [];
          this.top500Leads = [];
          this.itemLeads = res.data.filter(
            e => e.ZZQ_SITUAC !== "Encerrado" && e.ZZQ_SITUAC !== "Sem contato",
          );
          this.itemsRelatExcel = this.itemLeads;
          this.itemsRelatExcel = this.itemLeads;
          this.top500Leads = this.itemLeads;
          if (this.itemsResponsaveis.length <= 0) {
            this.montaResponsaveis();
          }
        })
        .catch(err => console.log(err));

      this.perPage = this.itemLeads.length;
      this.loading = false;
      this.$store.commit("setFiltroTela", []);
    },
    async montaResponsaveis() {
      let where = `ZS1_LEADS='1' AND ZS1_COD NOT IN ('${this.user.id}') ORDER BY ZS1_NOME`;
      if (this.isAdmin) {
        where = `ZS1_LEADS='1' ORDER BY ZS1_NOME`;
      }
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZS1_COD,ZS1_NOME",
        WHERE: where,
        TABELA: "ZS1",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.forEach(e => {
            this.itemsResponsaveis.push(`${e.ZS1_COD}-${e.ZS1_NOME}`);
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    verDados(item) {
      //this.carregaDados();
      this.titleDialog = `Visualizando o Lead: ${item.ZZQ_ID}-${item.ZZQ_NOME}`;
      this.dadosTela = item;

      if (this.dadosTela.ZZQ_SITUAC === "Novo")
        this.dadosTela.ZZQ_SITUAC = "N=Novo";
      if (this.dadosTela.ZZQ_SITUAC === "Aberto")
        this.dadosTela.ZZQ_SITUAC = "A=Aberto";
      if (this.dadosTela.ZZQ_SITUAC === "Eencerrado")
        this.dadosTela.ZZQ_SITUAC = "E=Encerrado";
      if (this.dadosTela.ZZQ_SITUAC === "Sem contato")
        this.dadosTela.ZZQ_SITUAC = "S=Sem contato";
      if (this.dadosTela.ZZQ_QUALIF === "Sim")
        this.dadosTela.ZZQ_QUALIF = "1=Sim";
      if (this.dadosTela.ZZQ_QUALIF === "Não")
        this.dadosTela.ZZQ_QUALIF = "2=Não";

      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Min")
        this.dadosTela.ZZQ_PESTRA = "M=Min";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Familiar")
        this.dadosTela.ZZQ_PESTRA = "F=Familiar";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Amigo")
        this.dadosTela.ZZQ_PESTRA = "A=Amigo";

      if (this.dadosTela.ZZQ_ISCLI === "Sim")
        this.dadosTela.ZZQ_ISCLI = "1=Sim";
      if (this.dadosTela.ZZQ_ISCLI === "Não")
        this.dadosTela.ZZQ_ISCLI = "2=Não";

      if (this.dadosTela.ZZQ_LAUDOM === "Sim")
        this.dadosTela.ZZQ_LAUDOM = "1=Sim";
      if (this.dadosTela.ZZQ_LAUDOM === "Não")
        this.dadosTela.ZZQ_LAUDOM = "2=Não";

      if (this.dadosTela.ZZQ_APUSER === "Sim")
        this.dadosTela.ZZQ_APUSER = "1=Sim";
      if (this.dadosTela.ZZQ_APUSER === "Não")
        this.dadosTela.ZZQ_APUSER = "2=Não";
      this.isReading = true;
      this.isEditing = false;
      this.isNew = false;
      this.DialogDados = true;

      this.$store.commit("SetLead_id", item.ZZQ_ID);
      this.$store.commit("SetLead_nome", item.ZZQ_NOME);
      this.$store.commit("setOrigem", "Leads");
      this.carregaDados();

      setTimeout(() => {
        this.fFollowUp();
      }, 300);
    },
    editItem(item) {
      this.snackbar_error = false;
      if (
        item.ZZQ_SITUAC.trim() === "Aberto" &&
        item.ZZQ_RESP.trim() !== this.user.id &&
        !this.isAdmin
      ) {
        this.$store.commit(
          "SetMensagemDialogOps",
          "Voce não pode alterar Lead que não é de sua responsabilidade.",
        );
        this.$store.commit("SetdialogNull", true);
        return;
      }

      this.$store.commit("SetLead_id", item.ZZQ_ID);
      this.$store.commit("SetLead_nome", item.ZZQ_NOME);
      this.$store.commit("setOrigem", "Leads");
      this.carregaDados();
      this.isNew = false;
      this.titleDialog = `Alterando o Lead: ${item.ZZQ_ID}-${item.ZZQ_NOME}`;
      this.id = `${item.ZZQ_ID}`;

      this.isEditing = true;
      this.dadosTela = item;

      this.ultimo_lead_editado = ``;

      console.log("LEAD: ", item);

      if (item.ZZQ_RESP && item.ZS1_NOME) {
        this.dadosTela.ZZQ_RESP = `${item.ZS1_NOME}`;
      } else {
        this.dadosTela.ZZQ_RESP = ``;
      }

      if (this.dadosTela.ZZQ_SITUAC === "Novo")
        this.dadosTela.ZZQ_SITUAC = "N=Novo";
      if (this.dadosTela.ZZQ_SITUAC === "Aberto")
        this.dadosTela.ZZQ_SITUAC = "A=Aberto";
      if (this.dadosTela.ZZQ_SITUAC === "Eencerrado")
        this.dadosTela.ZZQ_SITUAC = "E=Encerrado";
      if (this.dadosTela.ZZQ_SITUAC === "Sem contato")
        this.dadosTela.ZZQ_SITUAC = "S=Sem contato";
      if (this.dadosTela.ZZQ_QUALIF === "Sim")
        this.dadosTela.ZZQ_QUALIF = "1=Sim";
      if (this.dadosTela.ZZQ_QUALIF === "Não")
        this.dadosTela.ZZQ_QUALIF = "2=Não";

      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Min")
        this.dadosTela.ZZQ_PESTRA = "M=Min";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Familiar")
        this.dadosTela.ZZQ_PESTRA = "F=Familiar";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Amigo")
        this.dadosTela.ZZQ_PESTRA = "A=Amigo";

      if (this.dadosTela.ZZQ_ISCLI === "Sim")
        this.dadosTela.ZZQ_ISCLI = "1=Sim";
      if (this.dadosTela.ZZQ_ISCLI === "Não")
        this.dadosTela.ZZQ_ISCLI = "2=Não";

      if (this.dadosTela.ZZQ_LAUDOM === "Sim")
        this.dadosTela.ZZQ_LAUDOM = "1=Sim";
      if (this.dadosTela.ZZQ_LAUDOM === "Não")
        this.dadosTela.ZZQ_LAUDOM = "2=Não";

      if (this.dadosTela.ZZQ_APUSER === "Sim")
        this.dadosTela.ZZQ_APUSER = "1=Sim";
      if (this.dadosTela.ZZQ_APUSER === "Não")
        this.dadosTela.ZZQ_APUSER = "2=Não";

      this.isReading = false;
      this.DialogDados = true;

      console.log(this.dadosTela);

      if (this.isEditing) {
        setTimeout(() => {
          this.fFollowUp();
        }, 300);
      }
    },

    async carregaItems(item) {
      let dados = {};
      let items = [];
      this.itemsTipo = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";
      let where = ``;

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        if (
          item.ZZP_CAMPO.trim() === "ZZQ_CIDADE" &&
          this.dadosTela["ZZQ_EST"].trim() !== ""
        ) {
          const estado = this.dadosTela["ZZQ_EST"].split("-");
          where = `CC2_EST = '${estado[0].trim()}' ORDER BY CC2_MUN`;
        }

        if (item.ZZP_CAMPO.trim() === "ZZQ_STATUS") {
          where = `ZZR_COD <> '' ORDER BY ZZR_COD`;
        }
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: where,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
          this.itemsTipo = items;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    novo() {
      this.dadosTela = [];
      this.snackbar_error = false;
      this.isReading = false;
      this.isNew = true;
      this.titleDialog = `Incluindo Lead`;
      this.carregaDados();
      this.DialogDados = true;
    },
    Custonfiltro(indice) {
      const search = this.search.toString().toLowerCase();

      if (indice === "N") {
        return this.itemLeads.filter(i => {
          i["A1_NOME"] === search;
        });
      }
      if (indice === "C") {
        return this.itemLeads.filter(i => {
          i["A1_COD"] === search;
        });
      }
      if (indice === "F") {
        return this.itemLeads.filter(i => {
          i["A1_CGC"] === search;
        });
      }
    },
    async verificaLeads() {
      this.dialogAwaitSaveLead = true;
      let leads = [];

      let url = `${process.env.VUE_APP_BASE_API_URL}/tb_aux_lead`; //consulta ZW2

      await axios.get(url).then(res => {
        console.log(res.data);
        if (res.data.length <= 0) {
          this.dialogAwaitSaveLead = false;
          this.montaBrowser();
        }
        this.newLeads = res.data.length;
        leads = res.data;
      });

      let contador = 0;

      for await (const aux of leads) {
        const ZZQ = [];

        contador++;

        ZZQ.push({
          ZZQ_EMAIL: aux.ZW2_EMAIL,
          ZZQ_TPATEN: aux.ZW2_TPATEN,
          ZZQ_TEL: aux.ZW2_TEL,
          ZZQ_DTINC: aux.ZW2_DTINC,
          ZZQ_HRINC: aux.ZW2_HRINC,
          ZZQ_PAG: aux.ZW2_PAG,
          ZZQ_TEL1: aux.ZW2_TEL1,
          ZZQ_PESTRA: aux.ZW2_PESTRA,
          ZZQ_LAUDOM: aux.ZW2_LAUDOM,
          ZZQ_APUSER: aux.ZW2_APUSER,
          ZZQ_ISCLI: aux.ZW2_ISCLI,
          ZZQ_CLIENT: aux.ZW2_CLIENT,
          ZZQ_LOJA: aux.ZW2_LOJA,
          ZZQ_CIDADE: this.removeAcento(aux.ZW2_CIDADE),
          ZZQ_EST: aux.ZW2_EST,
          ZZQ_DEFEI1: this.removeAcento(aux.ZW2_DEFEI1),
          ZZQ_ACNEWL: aux.ZW2_ACNEWL,
          ZZQ_CPF: aux.ZW2_CPF,
          ZZQ_SITUAC: "N",
          ZZQ_QUALIF: aux.ZW2_QUALIF,
          ZZQ_CONVET: aux.ZW2_CONVET,
          ZZQ_STATUS: aux.ZW2_STATUS,
          ZZQ_DSTATU: aux.ZW2_DSTATU,
          ZZQ_RESP: aux.ZW2_RESP,
          ZZQ_NOME: this.removeAcento(aux.ZW2_NOME),
          ZZQ_DTRET: aux.ZW2_DTRET,
          ZZQ_PSTRAT: aux.ZW2_PSTRAT,
          ZZQ_TELDES: aux.ZW2_TELDES,
          ZZQ_STLIGA: aux.ZW2_STLIGA,
          ZZQ_LIGDUR: aux.ZW2_LIGDUR,
          ZZQ_LIGFAL: aux.ZW2_LIGFAL,
          ZZQ_URLGRV: aux.ZW2_URLGRV,
          ZZQ_IDLIG: aux.ZW2_IDLIG,
          ////////////////////////////////
          ZZQ_CAMPF: aux.ZW2_CAMPF,  
          ZZQ_DTENC: aux.ZW2_DTENC,  
          ZZQ_WHATS: aux.ZW2_WHATS,
          ZZQ_KANBAN: aux.ZW2_KANBAN,
          ZZQ_DDD: aux.ZW2_DDD,
          ZZQ_INTERO: aux.ZW2_INTERO,
          RECNOZW2: aux.R_E_C_N_O_,
        });
        await this.gravaLead(ZZQ, aux).then(
          console.log(`Incluindo o lead ${contador}`),
        );
      }

      // leads.map(async (e) => {
      //   const ZZQ = [];

      //   ZZQ.push({
      //     ZZQ_EMAIL: e.ZW2_EMAIL,
      //     ZZQ_TPATEN: e.ZW2_TPATEN,
      //     ZZQ_TEL: e.ZW2_TEL,
      //     ZZQ_DTINC: e.ZW2_DTINC,
      //     ZZQ_HRINC: e.ZW2_HRINC,
      //     ZZQ_PAG: e.ZW2_PAG,
      //     ZZQ_TEL1: e.ZW2_TEL1,
      //     ZZQ_PESTRA: e.ZW2_PESTRA,
      //     ZZQ_LAUDOM: e.ZW2_LAUDOM,
      //     ZZQ_APUSER: e.ZW2_APUSER,
      //     ZZQ_ISCLI: e.ZW2_ISCLI,
      //     ZZQ_CLIENT: e.ZW2_CLIENT,
      //     ZZQ_LOJA: e.ZW2_LOJA,
      //     ZZQ_CIDADE: this.removeAcento(e.ZW2_CIDADE),
      //     ZZQ_EST: e.ZW2_EST,
      //     ZZQ_DEFEI1: this.removeAcento(e.ZW2_DEFEI1),
      //     ZZQ_ACNEWL: e.ZW2_ACNEWL,
      //     ZZQ_CPF: e.ZW2_CPF,
      //     ZZQ_SITUAC: "N",
      //     ZZQ_QUALIF: e.ZW2_QUALIF,
      //     ZZQ_CONVET: e.ZW2_CONVET,
      //     ZZQ_STATUS: e.ZW2_STATUS,
      //     ZZQ_DSTATU: e.ZW2_DSTATU,
      //     ZZQ_RESP: e.ZW2_RESP,
      //     ZZQ_NOME: this.removeAcento(e.ZW2_NOME),
      //     ZZQ_DTRET: e.ZW2_DTRET,
      //     ZZQ_PSTRAT: e.ZW2_PSTRAT,
      //     ZZQ_TELDES: e.ZW2_TELDES,
      //     ZZQ_STLIGA: e.ZW2_STLIGA,
      //     ZZQ_LIGDUR: e.ZW2_LIGDUR,
      //     ZZQ_LIGFAL: e.ZW2_LIGFAL,
      //     ZZQ_URLGRV: e.ZW2_URLGRV,
      //   });

      //   await this.gravaLead(ZZQ, { ...e });
      // });
    },

    buscaCliente(campo) {
      if (campo === "ZZQ_CLIENT" && this.dadosTela[campo].trim().length > 0) {
        const cliente = this.dadosTela[campo].split("-");

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `A1_CGC`,
          WHERE: `A1_COD='${cliente[0]}' AND A1_LOJA='${cliente[1]}' `,
          TABELA: "SA1",
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

        axios
          .post(url, dados)
          .then(res => {
            this.dadosTela["ZZQ_CPF"] = res.data[0].A1_CGC;
          })
          .catch(error => {
            console.log(error);
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });
      }
    },
    leadsEncerra() {
      // console.log(this.itemLeads.length);
      // console.log(this.leadsCompleto.length);

      if (this.isAdmin) {
        this.itemLeads = this.leadsCompleto.filter(
          e => e.ZZQ_SITUAC === "Encerrado",
        );
      } else {
        this.itemLeads = this.leadsCompleto.filter(
          e => e.ZZQ_SITUAC === "Encerrado" && e.ZZQ_RESP === this.user.id,
        );
      }

      this.painelAplicado = true;
      this.TituloPainel = "Leads encerrados";
      this.DetalhesPainel = `Total de ${this.itemLeads.length} registro(s)`;
      this.itemsRelatExcel = this.itemLeads;
    },
    leadsScontato() {
      if (this.isAdmin) {
        this.itemLeads = this.leadsCompleto.filter(
          e => e.ZZQ_SITUAC === "Sem contato",
        );
      } else {
        this.itemLeads = this.leadsCompleto.filter(
          e => e.ZZQ_SITUAC === "Sem contato" && e.ZZQ_RESP === this.user.id,
        );
      }

      this.painelAplicado = true;
      this.TituloPainel = "Leads encerrados";
      this.DetalhesPainel = `Total de ${this.itemLeads.length} registro(s)`;
      this.itemsRelatExcel = this.itemLeads;
    },
    async countLeads() {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        WHERE: "",
        FIELDS: "COUNT(*) COUNT",
        TABELA: "ZZQ",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => this.$store.commit("setTotalLeads", res.data[0].COUNT))
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    async gravaLead(ZZQ, ZW2) {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZZQ: ZZQ[0],
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/saveLeads`; //salva na ZZQ
      await axios
        .post(url, dados)
        .then(res => {
          console.log(`Código do lead ${res.data.CODIGO}`);
          if (res.data.STATUS === "true") {
            const recno = ZW2.R_E_C_N_O_;
            ZW2 = {
              ZW2_SITUAC: "X",
              ZW2_ID: res.data.CODIGO,
              R_E_C_N_O_: recno,
            };

            url = `${process.env.VUE_APP_BASE_API_URL}/alterTbAuxLead`; // salva na ZW2

            axios.post(url, ZW2);
          }
          this.nrIncLead++;
        })
        .catch(err => console.log(err));

      if (this.nrIncLead === this.newLeads) {
        this.dialogAwaitSaveLead = false;
        this.newLeads = null;
        this.nrIncLead = 0;
        this.montaBrowser();
      }
    },

    //// FORM FACE GRAPH API FACEBOOK
    async verificaFormsFace() {
      this.formsFacebook = true;
      this.loadingFormsFace = true;
      //this.btnFecharFace = false
      const dados = {
        PAGE_ID: process.env.VUE_APP_ID_PAGE,
        TOKEN: process.env.VUE_APP_TOKEN_PAGE_FACEBOOK,
      };

      const url = `${process.env.VUE_APP_BASE_FACEBOOK_URL}/getForms`;
      // const url = `${process.env.VUE_APP_BASE_FACEBOOK_URL}/forms`;
      // console.log({url})

      await axios
        .post(url, dados)
        .then(res => {
          this.verbaseForms(res.data.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    async verbaseForms(forms) {
      this.countNformsFace = 0;
      forms.map(e => {
        this.FormsFacebook.forEach(i => {
          if (e.id.trim() === i.ZT1_ID.trim()) {
            e.DTULTRECUP = `${i.ZT1_DTULTC.substring(
              6,
              8,
            )}/${i.ZT1_DTULTC.substring(4, 6)}/${i.ZT1_DTULTC.substring(0, 4)}`;
            e.HORA = i.ZT1_HORA;
          }
        });
      });

      let formsActives = forms;

      this.countForms = formsActives.length;

      await this.countLeadsForm(formsActives);

      this.countNformsFace = formsActives.length;
    },
    async countLeadsForm(formsActives) {
      this.itemsFormsFace = [];
      this.leadsfacebook = [];
      this.novosLeadsFace = [];
      await formsActives.map(async form => {
        let dados = {
          FORM_ID: form.id,
          TOKEN: process.env.VUE_APP_TOKEN_FORM_FACEBOOK,
        };

        let url = `${process.env.VUE_APP_BASE_FACEBOOK_URL}/getLeads`;
        // let url = `${process.env.VUE_APP_BASE_FACEBOOK_URL}/leadsfacebook`;

        await axios
          .post(url, dados)
          .then(async res => {
            this.leadsfacebook = [];
            await res.data.data.forEach(s => {
              s.field_data.forEach(r => {
                if (
                  r.name === "nome" &&
                  r.values[0] !== "<test lead: dummy data for nome>"
                ) {
                  this.leadsfacebook.push({
                    data: s,
                    idform: form.id,
                    campanha: form.name,
                  });
                }
              });
            });

            form.countLeads = this.leadsfacebook.length;
            this.leadsFaceBase = [];
            this.leadsFaceBase = this.leadsCompleto.filter(
              f =>
                f.ZZQ_PAG.trim() === "FACEBOOK" &&
                f.ZZQ_IDFORM.trim() === form.id,
            );

            // const LeadBase = this.leadsfacebook.filter((j) =>
            //   this.verLeadBase(j.data.id)
            // );

            const LeadForaBase = this.leadsfacebook.filter(j =>
              this.verLeadForaBase(j.data.id),
            );

            LeadForaBase.forEach(y => this.novosLeadsFace.push(y));
            form.newLeads = LeadForaBase.length;
            this.itemsFormsFace.push(form);
          })
          .catch(error => {
            console.log(error);
          });
      }),
        (this.loadingFormsFace = false);
      console.log("Forms Face:", this.itemsFormsFace);
    },
    verLeadForaBase(leadId) {
      const lead = this.leadsFaceBase.filter(
        e => e.ZZQ_IDLFAC.trim() === leadId,
      );

      if (lead.length <= 0) {
        return true;
      } else {
        return false;
      }
    },
    verLeadBase(leadId) {
      const lead = this.leadsFaceBase.filter(
        e => e.ZZQ_IDLFAC.trim() === leadId,
      );

      if (lead.length <= 0) {
        return false;
      } else {
        return true;
      }
    },
    async capturaFormsFace() {
      const data = new Date();
      const ano = data.getFullYear().toString();
      let mes = (data.getMonth() + 1).toString();
      let dia = data.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      const hora = data.getHours();
      let minuto = data.getMinutes();
      if (minuto < 10) {
        minuto = `0${minuto}`;
      }

      let ZT2 = [];
      console.log(this.itemsFormsFace);
      this.$store.commit("setFormsFace", []);
      this.itemsFormsFace.forEach(async f => {
        const NewForm = this.FormsFacebook.filter(
          e => e.ZT1_ID.trim() === f.id.trim(),
        );
        const ZT1 = {
          ZT1_ID: f.id,
          ZT1_DTULTC: `${ano}${mes}${dia}`,
          ZT1_HORA: `${hora}:${minuto}`,
        };

        if (NewForm.length <= 0) {
          const dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            ZT1,
            ZT2,
          };

          const url = `${process.env.VUE_APP_BASE_FACEBOOK_URL}/addForms`;
          // const url = `${process.env.VUE_APP_BASE_FACEBOOK_URL}/incForms`;

          await axios
            .post(url, dados)
            .then(() => {
              this.itemsFormsFace.map(e => {
                if (e.id.trim() === f.id.trim()) {
                  e.DTULTRECUP = `${dia}/${mes}/${ano}`;
                  e.HORA = `${hora}:${minuto}`;
                }
              });

              this.$store.commit("setPushFormFace", ZT1);
            })
            .catch(error => console.log(error));
        } else {
          this.itemsFormsFace.map(e => {
            if (e.id.trim() === f.id.trim()) {
              e.DTULTRECUP = `${dia}/${mes}/${ano}`;
              e.HORA = `${hora}:${minuto}`;
            }
          });
        }

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          id: f.id,
        };

        const url = `${process.env.VUE_APP_BASE_FACEBOOK_URL}/editForms`;

        await axios
          .post(url, dados)
          .then(() => this.$store.commit("setPushFormFace", ZT1));
      });
    },
    async verificaLFace() {
      console.log(this.novosLeadsFace);

      if (this.novosLeadsFace.length <= 0) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Sem leads para incluir no momento!`;
        this.sheetMessage = true;
        this.formsFacebook = false;
        this.itemsFormsFace = [];
        return;
      }

      this.dialogAwaitSaveCliente = true;
      this.countGeral = 0;
      this.totalaIncluir = this.novosLeadsFace.length;

      /* Salva novos leads do FACE na base de leads (ZZQ) */
      this.novosLeadsFace.forEach(async e => {
        let valores = e.data.field_data;
        let whatsapp = ``;
        let email = ``;
        let nome = ``;
        let telefone = ``;
        let cidade = ``;
        valores.forEach(j => {
          if (j.name === "whatsapp") {
            whatsapp = j.values[0];
          }
          if (j.name === "e-mail") {
            email = j.values[0];
          }
          if (j.name === "email") {
            email = j.values[0];
          }
          if (j.name === "telefone") {
            telefone = j.values[0];
          }
          if (j.name === "cidade") {
            cidade = j.values[0];
          }
          if (j.name === "nome") {
            nome = j.values[0];
          }
        });

        let ZZQ = {};

        let dataFormat = e.data.created_time.substring(0, 10).split("-");
        let hora = e.data.created_time.substring(11, 16);
        let dataBanco = `${dataFormat[0]}${dataFormat[1]}${dataFormat[2]}`;
        ZZQ = {
          ZZQ_NOME: this.removeAcento(nome),
          ZZQ_CAMPF: this.removeAcento(e.campanha),
          ZZQ_EMAIL: email,
          ZZQ_TEL: telefone,
          ZZQ_DTINC: dataBanco,
          ZZQ_HRINC: hora,
          ZZQ_PAG: "FACEBOOK",
          ZZQ_SITUAC: "N",
          ZZQ_IDFORM: e.idform,
          ZZQ_CIDADE: this.removeAcento(cidade),
          ZZQ_WHATS: whatsapp,
          ZZQ_IDLFAC: e.data.id,
        };

        // console.log(ZZQ);

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZZQ,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/saveLeads`; // salva na ZZQ

        await axios
          .post(url, dados)
          .then(() => {
            this.totincnabase++;
            this.nameofleadface = `${ZZQ.ZZQ_NOME}`;

            if (this.totincnabase >= this.totalaIncluir) {
              this.dialogAwaitSaveCliente = false;
              this.formsFacebook = false;
              this.awaitFormsFace = true;
              this.nameofleadface = null;
              this.totincnabase = 0;
              this.totalaIncluir = 0;
              this.montaBrowser();
              this.capturaFormsFace();
            }
          })
          .catch(error => {
            this.totincnabase++;
            console.log(error);
          });
      });

      // this.dialogAwaitSaveCliente = false;

      // this.totalaIncluir = 0;
      // this.totincnabase = 0;
      // this.itemsFormsFace.forEach((e) => {
      //   this.totalaIncluir += e.newLeads;
      // });

      // if (this.totalaIncluir <= 0) {
      //   this.iconMessage = "mdi-close";
      //   this.colorMessage = "red lighten-4";
      //   this.message = `Sem leads para incluir no momento!`;
      //   this.sheetMessage = true;
      //   this.formsFacebook = false;
      //   this.itemsFormsFace = [];
      //   return;
      // }
      // this.dialogAwaitSaveCliente = true;
      // this.countGeral = 0;

      // this.itemsFormsFace.forEach(async (e) => {
      //   if (e.newLeads > 0) {
      //     this.totincnabase = 0;

      //     this.leadsfacebook.forEach(async (l) => {
      //       const valores = l.data.field_data;

      //       let whatsapp = ``;
      //       let email = ``;
      //       let nome = ``;
      //       let telefone = ``;
      //       let cidade = ``;
      //       valores.forEach((j) => {
      //         if (je      //           whatsapp = j.values[0];
      //         }
      //         if (j.name === "e-mail") {
      //           email = j.values[0];
      //         }
      //         if (j.name === "email") {
      //           email = j.values[0];
      //         }
      //         if (j.name === "telefone") {
      //           telefone = j.values[0];
      //         }
      //         if (j.name === "cidade") {
      //           cidade = j.values[0];
      //         }
      //         if (j.name === "nome") {
      //           nome = j.values[0];
      //         }
      //       });

      //       let ZZQ = {};

      //       const leadNaBase = this.itemLeads.filter(
      //         (y) =>
      //           y.ZZQ_IDLFAC.trim() === l.data.id.trim() &&
      //           y.ZZQ_IDFORM.trim() === l.idform.trim()
      //       ).length;

      //       if (leadNaBase <= 0 && l.idform === e.id) {
      //         let dataFormat = l.data.created_time.substring(0, 10).split("-");
      //         let hora = l.data.created_time.substring(11, 16);
      //         let dataBanco = `${dataFormat[0]}${dataFormat[1]}${dataFormat[2]}`;
      //         ZZQ = {
      //           ZZQ_EMAIL: email,
      //           ZZQ_TEL: telefone,
      //           ZZQ_DTINC: dataBanco,
      //           ZZQ_HRINC: hora,
      //           ZZQ_PAG: "FACEBOOK",
      //           ZZQ_SITUAC: 'N',
      //           ZZQ_IDFORM: l.idform,
      //           ZZQ_NOME: this.removeAcento(nome),
      //           ZZQ_CIDADE: this.removeAcento(cidade),
      //           ZZQ_WHATS: whatsapp,
      //           ZZQ_IDLFAC: l.data.id,
      //           ZZQ_CAMPF: this.removeAcento(e.name),
      //         };

      //         const dados = {
      //           EMPRESA: "01",
      //           FILIAL: "0101",
      //           ZZQ,
      //         };

      //         const url = `${process.env.VUE_APP_BASE_API_URL}/saveLeads`;

      //         await axios
      //           .post(url, dados)
      //           .then(() => {
      //             this.totincnabase++;
      //             this.nameofleadface = `${ZZQ.ZZQ_NOME}`;

      //             if (this.totincnabase >= this.totalaIncluir) {
      //               this.dialogAwaitSaveCliente = false;
      //               this.formsFacebook = false;
      //               this.awaitFormsFace = true;
      //               this.nameofleadface = null;
      //               this.totincnabase = 0;
      //               this.totalaIncluir = 0;
      //               this.montaBrowser();
      //               this.capturaFormsFace();
      //             }
      //           })
      //           .catch((error) => {
      //             this.totincnabase++;
      //             console.log(error);
      //           });
      //       }
      //     });
      //   }
      // });
    },
  },
  async mounted() {
    if (this.user.AdmLeads) {
      this.isAdmin = true;
      this.items = [
        { title: "Meus lead(s)", icon: "mdi-home-city", function: "meusLeads" },
        {
          title: "Meus lead(s) com retorno até hoje",
          icon: "mdi-account",
          function: "leadsRetorno",
        },
        {
          title: "Meus Lead(s) com cliente vinculado em aberto",
          icon: "mdi-account",
          function: "leadsCliAberto",
        },
        {
          title: "Atribuir lead(s)",
          icon: "mdi-account-group-outline",
          function: "atribuirLeads",
        },
        {
          title: "Transferir Lead(s)",
          icon: "mdi-account-group-outline",
          function: "transfereLead",
        },
        { title: "Histórico do Lead", function: "histLead" },
        // { title: "Incluir leads Facebook", function: "formsFace" },
        { title: "Leads encerrados", function: "leadsEncerra" },
      ];
    } else {
      this.items = [
        { title: "Meus lead(s)", icon: "mdi-home-city", function: "meusLeads" },
        {
          title: "Meus lead(s) com retorno até hoje",
          icon: "mdi-account",
          function: "leadsRetorno",
        },
        {
          title: "Meus Lead(s) com cliente vinculado em aberto",
          icon: "mdi-account",
          function: "leadsCliAberto",
        },
        {
          title: "Atribuir lead(s)",
          icon: "mdi-account-group-outline",
          function: "atribuirLeads",
        },
        {
          title: "Transferir meu(s) Lead(s)",
          icon: "mdi-account-group-outline",
          function: "transfereLead",
        },
        { title: "Histórico do Lead", function: "histLead" },
        // { title: "Incluir leads Facebook", function: "formsFace" },
        { title: "Leads encerrados", function: "leadsEncerra" },
      ];
      this.isAdmin = false;
    }

    if (this.filtroTela.length <= 0) {
      //await this.verificaLeads();
      //await this.verificaLFace()
      this.dialogAwaitSaveLead = false;
      this.countLeads();

      this.montaBrowser();
    } else {
      this.dialogAwaitSaveLead = false;

      this.montaBrowser();
    }
  },
};
</script>

<style>
.v-label {
  font-size: 0.7rem;
}
input {
  font-size: 0.8rem;
}
.text-center {
  font-size: 0.7rem !important;
}
.text-start {
  font-size: 0.7rem !important;
}
tbody tr:nth-of-type(odd) {
  background-color: #d8f6fa;
}
.v-select__selection {
  font-size: 0.8rem !important;
}
.scroll {
  overflow-y: scroll;
}
.painel {
  overflow-x: scroll;
}

.theme--light.v-data-table.tbLeads tbody tr.v-data-table__selected {
  background: #0fcbe1 !important;
}

.theme--light.v-data-table.tbLeads tbody tr.v-data-table__selected {
  background: #0fcbe1 !important;
}

.v-data-table.tbLeads tbody tr :hover {
  cursor: pointer;
}
</style>
