import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialogNull),callback:function ($$v) {_vm.dialogNull=$$v},expression:"dialogNull"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline red lighten-4"},[_c('span',{staticStyle:{"color":"#B71C1C"}},[_vm._v("OPS!!!")])]),_c(VCardText,{staticClass:"mt-3"},[_c('span',{staticStyle:{"font-size":"1rem","color":"black"}},[_vm._v(_vm._s(_vm.mensagemDialogOps))])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.fechaDialog}},[_vm._v(" Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }