/**
 * @param {string}  DD/MM/AAAA
 * @return {string} AAAAMMDD
 */
export function brDateToString(stringDate, cb) {
  if (!stringDate) {
    return cb(null, null);
  }

  const [dia, mes, ano] = stringDate.split("/");

  if (dia.length === 8) {
    cb(null, dia);
    return;
  }

  if (parseInt(dia) > 31 || parseInt(mes) > 12 || ano.length > 4) {
    cb("Data inválida", stringDate);
  } else {
    cb(null, `${ano}${mes}${dia}`);
  }
  return;
}

/**
 * @param {string} AAAAMMDD
 * @return {string} DD/MM/AAAA
 */
export function stringToBrDate(string) {
  const ano = string.substring(0, 4);
  const mes = string.substring(4, 6);
  const dia = string.substring(6, 8);

  return `${dia}/${mes}/${ano}`;
}

/**
 * @param: string _h:mm
 * @return: string hh:mm
 */
export function stringToBrClock(string) {
  let [hora, minuto] = string.split(":");

  hora = hora.length <= 1 ? `0${hora}` : hora;
  minuto = minuto.length <= 1 ? `0${minuto}` : minuto;

  return `${hora}:${minuto}`;
}

/**
 * @param {string}  00.00
 * @return {string} R$ 00,00
 */
export function stringToBrMoney(value) {
  if(!value) return 
  if (typeof value === "string") {
    value = parseFloat(value).toFixed(2);
  }

  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

/**
 * @param {number}  00.00
 * @return {string} R$ 00,00
 */
export function floatToMoney(number) {
  if (typeof number === "string") {
    return stringToFloat(number).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  if (typeof number === "number") {
    return number.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return number;
  }
}

/**
 * @param {string}  00.000,00
 * @return {string} 000000.00
 */
export function stringToFloat(value) {
  if (typeof value === "string") {
    const aux = value
      .replace(new RegExp("[.]", "g"), "")
      .replace(new RegExp("[,]", "g"), ".");

     console.log(aux)
    return parseFloat(aux || "0");
  } else {
    return value;
  }
}

/**
 * @param {object} [{X3_CBOX: aaaa=1;bbbb=2}]
 * @return {array} [{aaaa:1}, {bbbb:2}]
 */
export function stringToSelectOptions(linha) {
  if (linha.X3_CBOX.length > 0) {
    return linha.X3_CBOX.split(";").map(e => {
      const [chave, valor] = e.split("=");
      return {
        text: `${chave}=${valor}`,
        value: chave,
      };
    });
  }
}
