import axios from "axios";

// import {stringToBrMoney} from '@/utils/stringParse'

// import {sub, add} from 'date-fns'

const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

export default {
  state: {
    faturamentoIndividual: {},
    faturamentoOpGrade: [],
    FatuamentoEquipe: [],
    dadosCalculoIndividual: [],
  },
  mutations: {
    async setFaturamentoIndividual(state, payload) {
      let anomes = "";
      if (payload.trimestre.split("-")[0].substr(0, 2) == "01") {
        anomes = `'${payload.ano}01','${payload.ano}02','${payload.ano}03'`;
      }
      if (payload.trimestre.split("-")[0].substr(0, 2) == "04") {
        anomes = `'${payload.ano}04','${payload.ano}05','${payload.ano}06'`;
      }
      if (payload.trimestre.split("-")[0].substr(0, 2) == "07") {
        anomes = `'${payload.ano}07','${payload.ano}08','${payload.ano}09'`;
      }
      if (payload.trimestre.split("-")[0].substr(0, 2) == "10") {
        anomes = `'${payload.ano}10','${payload.ano}11','${payload.ano}12'`;
      }

      const dados = {
        FIELDS: `CASE WHEN SUM(FATURAMENTO_LIQUIDO) IS NULL THEN 0 ELSE SUM(FATURAMENTO_LIQUIDO) END VALOR`,
        WHERE: `PLANO_SAUDE<>'1'
                        AND APAC<>'S'
                        AND ANOMES IN (${anomes})
                        AND RELATORIO_BI<>'2'
                        AND LEFT(VENDEDOR,6) = '${payload.vendedor}' `,
        TABELA: "DB_V_RECEITAS_BI",
      };

      const faturamento = await axios.post(url, dados);

      state.faturamentoIndividual.valor = Number(
        faturamento.data[0].VALOR.toFixed(2),
      );
      state.faturamentoIndividual.detalhes = [];
      state.faturamentoIndividual.dadosVendedor = payload;

      if (payload.detalhes) {
        dados.FIELDS = "*";

        const detalhes = await axios.post(url, dados);

        state.faturamentoIndividual.detalhes = detalhes.data;
      }

      this.commit("calculoIndividual");
    },
    async calculoIndividual(state) {
      let dadosComissao = [];
      state.dadosCalculoIndividual = [];

      let dados = {
        FIELDS: `ZA3_INDINI,ZA3_INDFIM,ZA3_TIPO,ZA3_PERC,ZA2_LIMITE,ZA2_META1,ZA2_META2,ZA2_META3,ZA2_META4,ZA2_TIPO,ZA3_PBASE`,
        INNER: ` INNER JOIN ZA3010 ZA3 WITH(NOLOCK) ON (ZA3_IDREG=ZA2_ID AND ZA3.D_E_L_E_T_=' ')`,
        WHERE: `ZA2_CODVEN='${state.faturamentoIndividual.dadosVendedor.vendedor}' AND ZA2_ANO='${state.faturamentoIndividual.dadosVendedor.ano}' AND ZA2_TIPO='I'`,
        TABELA: `ZA2`,
      };

      await axios.post(url, dados).then(res => (dadosComissao = res.data));

      if (dadosComissao.length <= 0) return;

      dadosComissao.forEach((e, index) => {
        let valorInicial = 0;
        let valorFinal = 0;
        if (index <= 0) {
          valorInicial =
            (state.faturamentoIndividual.dadosVendedor.meta *
              dadosComissao[index]["ZA3_INDINI"]) /
            100;
          valorInicial = Math.trunc(valorInicial);
          valorInicial = 50 - (valorInicial % 50) + valorInicial;

          state.faturamentoIndividual.metaInicial = (
            valorInicial - 0.01
          ).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });

          valorFinal =
            (state.faturamentoIndividual.dadosVendedor.meta *
              dadosComissao[index]["ZA3_INDFIM"]) /
            100;
          valorFinal = Math.trunc(valorFinal);
          valorFinal = 50 - (valorFinal % 50) + valorFinal;
        } else {
          valorInicial =
            (state.faturamentoIndividual.dadosVendedor.meta *
              dadosComissao[index - 1]["ZA3_INDFIM"]) /
            100;
          valorInicial = Math.trunc(valorInicial);
          valorInicial = 50 - (valorInicial % 50) + valorInicial;
          valorInicial = valorInicial + 0.01;

          valorFinal =
            (state.faturamentoIndividual.dadosVendedor.meta *
              dadosComissao[index]["ZA3_INDFIM"]) /
            100;
          valorFinal = Math.trunc(valorFinal);
          valorFinal = 50 - (valorFinal % 50) + valorFinal;
        }

        let valorMaxPorc =
          (state.faturamentoIndividual.valor * 100) /
          state.faturamentoIndividual.dadosVendedor.meta;

        let tagMax = "";
        if (valorMaxPorc > e.ZA2_LIMITE) {
          tagMax = "Excedeu o limite de 120%";
        }

        if (
          state.faturamentoIndividual.valor <= valorFinal &&
          state.faturamentoIndividual.valor >= valorInicial
        ) {
          let valorBase =
            (state.faturamentoIndividual.dadosVendedor.meta * e.ZA3_PBASE) /
            100;

          let valorPremio = (valorBase * e.ZA3_PERC) / 100;

          valorPremio = Math.trunc(valorPremio);
          valorPremio = 50 - (valorPremio % 50) + valorPremio;

          state.dadosCalculoIndividual.push({
            vl_vendido: state.faturamentoIndividual.valor.toLocaleString(
              "pt-BR",
              {
                style: "currency",
                currency: "BRl",
              },
            ),
            fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
            vl_final: valorFinal.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
            vl_premio: valorPremio.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
          });
        } else {
          state.dadosCalculoIndividual.push({
            vl_vendido: state.faturamentoIndividual.valor.toLocaleString(
              "pt-BR",
              {
                style: "currency",
                currency: "BRl",
              },
            ),
            fx_inicial: `${e.ZA3_INDINI}%`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
            vl_final: valorFinal.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRl",
            }),
            vl_premio: "-",
          });
        }
      });
    },
  },
  acttion: {},
};
