<template>
  <div id="app">
    <v-card class="d-flex">
      <v-card>
        <v-navigation-drawer
          v-if="drawer"
          v-model="drawer"
          :mini-variant.sync="mini"
          permanent
        >
          <v-list dense>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              link
              @click="paineis(item.function)"
            >
              <v-list-item-content>
                <v-tooltip top v-if="item.title.length > 40">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 0.7rem"
                      >{{ item.title }}</v-list-item-title
                    >
                  </template>
                  <span>{{ item.title }}</span>
                </v-tooltip>
                <v-list-item-title
                  style="font-size: 0.7rem"
                  v-if="item.title.length < 40"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <v-card>
        <div class="ml-2">
          <v-data-table
            v-model="selectAtividades"
            :headers="HeadresAtividades"
            dense
            show-select
            :items="itemAtividades"
            class="elevation-1 tbAtividades"
            no-data-text="Sem dados..."
            fixed-header
            item-key="indice"
            :height="altura"
            :loading="loading"
            :search="search"
            :items-per-page="20"
            @click:row="marcaItem"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <div v-if="user.NivelAdmin[0].trim() !== '0'">
                  <v-btn
                    color="indigo"
                    dark
                    @click.stop="abrePainel"
                    class="mt-1"
                  >
                    <v-icon v-if="!drawer">mdi-chevron-right</v-icon
                    ><v-icon v-if="drawer">mdi-chevron-left</v-icon>Painéis
                  </v-btn>
                  <v-btn
                    class="mx-5 mt-1"
                    fab
                    x-small
                    dark
                    color="indigo"
                    @click="novo()"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div v-if="NrRegFiltro !== null">
                  <span style="font-size: 0.7rem"
                    >Filtro aplicado no <strong>{{ filtroEscolhido }}</strong
                    >, {{ NrRegFiltro }} registro(s) encontrado(s).</span
                  >
                  <v-btn
                    class="mx-5 mt-2"
                    x-small
                    dark
                    fab
                    color="error"
                    @click="LimpaFiltro()"
                  >
                    <v-icon dark class="ml-1">mdi-eraser</v-icon>
                  </v-btn>
                </div>
                <v-col cols="2">
                  <v-select
                    class="mt-5"
                    v-model="filtroEscolhido"
                    :items="itemsFiltro"
                    @change="verTipoFiltro"
                    label="Escolha o campo"
                  ></v-select>
                </v-col>
                <v-col cols="2" v-if="itensFiltroEsc.length <= 0">
                  <v-text-field
                    class="mt-5"
                    placeholder="digite aqui.."
                    v-model="stringFiltro"
                    :disabled="!filtroEscolhido"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" v-if="itensFiltroEsc.length > 0">
                  <v-select
                    class="mt-5"
                    v-model="stringFiltro"
                    :items="itensFiltroEsc"
                    label="Escolha..."
                  ></v-select>
                </v-col>
                <v-col cols="1" v-if="isAdmin">
                  <v-text-field
                    class="mt-4 text-uppercase"
                    label="Data Inicial"
                    v-model="dtInicial"
                    v-mask="'##/##/####'"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="1" v-if="isAdmin">
                  <v-text-field
                    class="mt-4 text-uppercase"
                    label="Data Final"
                    v-model="dtFinal"
                    v-mask="'##/##/####'"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    class="mx-5"
                    x-small
                    dark
                    color="indigo"
                    @click="filtraRegistro(filtroEscolhido, stringFiltro)"
                  >
                    <v-icon dark class="ml-1">mdi-account-search</v-icon>
                  </v-btn>
                </v-col>
                <div v-if="isAdmin">
                  <v-tooltip bottom>
                    <v-spacer></v-spacer>
                    <template v-slot:activator="{ on, attrs }">
                      <vue-excel-xlsx
                        :data="itemsRelatExcel"
                        :columns="headersRelatExcel"
                        :filename="NOME_RELATORIO"
                        :sheetname="``"
                        class="ml-5 mb-5 tbExcel"
                      >
                        <v-btn
                          icon
                          color="blue darken-2"
                          class="mr-5 mt-5"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                      </vue-excel-xlsx>
                    </template>
                    <span>Exportar para excel</span>
                  </v-tooltip>
                </div>
                <div>
                  <v-btn icon @click="montaBrowser">
                    <v-icon small class="pa-1" color="blue">mdi-reload</v-icon>
                  </v-btn>
                </div>
              </v-toolbar>
              <section dense v-if="painelAplicado" class="ml-3">
                <span
                  style="font-size: 18px; color: #424242; font-weight: bolder"
                  >{{ TituloPainel }}</span
                >
                <p style="font-size: 12px; color: #757575">
                  {{ DetalhesPainel }}
                  <v-btn
                    class="ml-2"
                    x-small
                    dark
                    fab
                    color="error"
                    @click="LimpaFiltroPainel()"
                  >
                    <v-icon dark small>mdi-eraser</v-icon>
                  </v-btn>
                </p>
              </section>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    @click="interagir(item)"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="
                      user.NivelAdmin[0].trim() === '0' ||
                        item.ZW5_STATUS === 'E=ENCERRADO'
                    "
                    color="blue darken-3"
                    >mdi-access-point</v-icon
                  >
                </template>
                <span>Interagir</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="VerDetalhes(item)"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-monitor-dashboard</v-icon
                  >
                </template>
                <span>Detalhes</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.ZW4_TIPO`]="{ item }">
              <v-chip
                class="ma-2"
                x-small
                color="cyan darken-2"
                dark
                v-if="item.ZW4_TIPO === 'INDIVIDUAL'"
              >
                {{ item.ZW4_TIPO }}
              </v-chip>
              <v-chip
                class="ma-2"
                x-small
                color="indigo darken-2"
                dark
                v-if="item.ZW4_TIPO === 'CAMPANHA'"
              >
                {{ item.ZW4_TIPO }}
              </v-chip>
              <v-chip
                class="ma-2"
                x-small
                color="purple darken-2"
                dark
                v-if="item.ZW4_TIPO === 'O'"
              >
                {{ `OPIMED+ ${item.ZW5_SEQ}` }}
              </v-chip>
            </template>

            <template v-slot:[`item.ZW5_STATUS`]="{ item }">
              <v-chip
                class="ma-2"
                x-small
                color="blue darken-2"
                dark
                v-if="item.ZW5_STATUS === 'A=ABERTO'"
              >
                {{ item.ZW5_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                x-small
                color="green darken-2"
                dark
                v-if="item.ZW5_STATUS === 'E=ENCERRADO'"
              >
                {{ item.ZW5_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                x-small
                color="orange darken-2"
                dark
                v-if="item.ZW5_STATUS === 'R=ATRIBUÍDO'"
              >
                {{ item.ZW5_STATUS }}
              </v-chip>
              <v-chip
                class="ma-2"
                x-small
                color="green darken-2"
                dark
                v-if="item.ZW5_STATUS === 'S=SEM CONTATO'"
              >
                {{ item.ZW5_STATUS }}
              </v-chip>
            </template>
          </v-data-table>
          <div
            class="pa-1 d-flex justify-space-around align-center"
            style="background-color: #0d47a1"
          >
            <div>
              <span style="color: white"
                >Total selecionado: {{ selectAtividades.length }}</span
              >
              <v-btn
                x-small
                color="green darken-3"
                dark
                class="ml-3"
                @click="selectAtividades = []"
                v-if="selectAtividades.length > 0"
                >Limpar seleção</v-btn
              >
            </div>
            <div
              class="pa-1 d-flex align-center"
              style="background-color: #0d47a1"
            >
              <span class="ml-5 center" dark style="color: white">
                Última atividade alterada
                <v-icon color="white" x-small>mdi-arrow-right-bold</v-icon
                >{{ ultima_atividade_editada }}</span
              >
            </div>
          </div>
        </div>

        <v-dialog v-model="novaOpMais" width="60%" persistent>
          <v-card>
            <v-card-title>Sugestão OPIMED+ nova sequência</v-card-title>
            <v-card-text>
              <p>
                Você acabou de encerrar uma sequência do OPIMED+, deseja incluir
                a nova sequência abaixo:
              </p>
              <v-data-table
                :headers="HeadersNovaOpMais"
                dense
                :items="itensNovaOpMais"
                class="elevation-1 tbAtividades"
                hide-default-footer
              ></v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" text @click="dialogAtOpMais = true"
                >Fechar</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="gravaNovaOpMais()"
                >Incluir nova sequência</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAtOpMais" width="500" persistent>
          <v-card>
            <v-card-title>ATENÇÂO</v-card-title>
            <v-card-text>
              <p>Se você fechar a tela a rotina automática vai ser ignorada.</p>
              <p>
                Para lançar uma nova sequência, tera que ser feita manualmente.
              </p>
              <p>Deseja realmente ignorar a rotina.</p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="error"
                text
                @click="
                  dialogAtOpMais = false;
                  novaOpMais = false;
                "
                >Ignorar</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogAtOpMais = false"
                >Voltar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="dadosCli text-center">
          <v-dialog v-model="DialogDados" width="90%">
            <v-card v-if="boilerplate" ref="form">
              <v-card-title
                class="headline grey lighten-2 justify-center"
                v-text="titleDialog"
                style="height: 60px; font-size: 0.9rem !important"
              ></v-card-title>
              <v-card-text>
                <v-row dense class="mt-5">
                  <v-col
                    v-for="campo in camposTela"
                    :key="campo.ZZP_CAMPO"
                    :cols="campo.ZZP_COLS"
                  >
                    <v-text-field
                      v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :label="campo.ZZP_TITULO"
                      v-if="campo.ZZP_TIPO === 'T'"
                      :rules="
                        obrigat(
                          dadosTela[campo.ZZP_CAMPO.trim()],
                          campo.ZZP_OBRIGA,
                        )
                      "
                      class="text-uppercase"
                      dense
                      :readonly="isReading"
                      :disabled="campo.ZZP_VISUAL === '2'"
                    ></v-text-field>
                    <v-text-field
                      v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :label="campo.ZZP_TITULO"
                      v-if="campo.ZZP_TIPO === 'D'"
                      :rules="
                        obrigat(
                          dadosTela[campo.ZZP_CAMPO.trim()],
                          campo.ZZP_OBRIGA,
                        )
                      "
                      v-mask="'##/##/####'"
                      class="text-uppercase"
                      dense
                      :readonly="isReading"
                      :disabled="campo.ZZP_VISUAL === '2'"
                    ></v-text-field>
                    <v-select
                      v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :items="campo.ITEMS"
                      :label="campo.ZZP_TITULO"
                      :rules="
                        obrigat(
                          dadosTela[campo.ZZP_CAMPO.trim()],
                          campo.ZZP_OBRIGA,
                        )
                      "
                      v-if="campo.ZZP_TIPO === 'S'"
                      class="text-uppercase"
                      dense
                      :readonly="isReading"
                      :disabled="campo.ZZP_VISUAL === '2'"
                    ></v-select>
                    <v-autocomplete
                      v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                      style="margin-top: -22px"
                      dense
                      :items="itemsTipo"
                      @click="carregaItems(campo)"
                      @focus="carregaItems(campo)"
                      no-data-text="Buscando dados..."
                      :rules="
                        obrigat(
                          dadosTela[campo.ZZP_CAMPO.trim()],
                          campo.ZZP_OBRIGA,
                        )
                      "
                      filled
                      :label="campo.ZZP_TITULO"
                      background-color="white"
                      v-if="campo.ZZP_TIPO === 'A'"
                      class="text-uppercase"
                      :readonly="isReading"
                      :disabled="campo.ZZP_VISUAL === '2'"
                    ></v-autocomplete>
                    <v-textarea
                      style="margin-top: -14px; font-size: 0.7rem"
                      v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                      :label="campo.ZZP_TITULO"
                      class="mx-2"
                      rows="1"
                      v-if="campo.ZZP_TIPO === 'Y'"
                      :readonly="isReading"
                      :disabled="campo.ZZP_VISUAL === '2'"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-col cols="5">
                  <v-progress-linear
                    v-model="progress"
                    v-if="progress > 0"
                    color="blue-grey"
                    height="25"
                  >
                    <template v-slot="{ value }">
                      <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  v-if="!isReading && !isEditing"
                  text
                  @click="salva()"
                  >Salvar</v-btn
                >
                <v-btn
                  color="primary"
                  v-if="!isReading && isEditing"
                  text
                  @click="salva(id)"
                  >Salvar Alteração</v-btn
                >
              </v-card-actions>
              <v-divider></v-divider>
              <FollowUp ref="follow" v-if="!isNew" />
              <div class="px-3">
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="limpaVariaveis()"
                  >Fechar</v-btn
                >
              </div>
            </v-card>

            <v-skeleton-loader
              ref="skeleton"
              :boilerplate="boilerplate"
              type="table"
              class="mx-auto"
              v-if="!boilerplate"
            ></v-skeleton-loader>
          </v-dialog>
        </div>
        <v-bottom-sheet v-model="sheetMessage">
          <v-sheet class="text-center" :color="colorMessage" height="130px">
            <div class="py-3">
              <v-btn class="mx-2" text fab>
                <v-icon
                  v-text="iconMessage"
                  @click="sheetMessage = false"
                ></v-icon>
              </v-btn>
            </div>
            <div class="py-3" style="font-size: 15px" v-text="message"></div>
          </v-sheet>
        </v-bottom-sheet>
        <v-dialog v-model="dSaveAtividade" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2"
              >Atividades</v-card-title
            >
            <v-card-text class="mt-5 title"
              >Atividade salva com sucesso!</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dSaveAtividade = false"
                >fechar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dAwaitSaveAtividade" width="500" persistent>
          <v-card>
            <v-overlay :value="dAwaitSaveAtividade" absolute>
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-card-title class="headline grey lighten-2"
              >Atividade</v-card-title
            >
            <v-card-subtitle>
              <p class="body-1">Salvando a atividade, aguarde...</p>
            </v-card-subtitle>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dAwaitOpMaisSeq" width="500" persistent>
          <v-card>
            <v-overlay :value="dAwaitOpMaisSeq" absolute>
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-card-title class="headline grey lighten-2">Opimed+</v-card-title>
            <v-card-subtitle>
              <p class="body-1">Buscando nova sequencia, aguarde...</p>
            </v-card-subtitle>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogOpimedMais" width="500" persistent>
          <v-card>
            <v-overlay :value="dialogOpimedMais" absolute>
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-card-title class="headline grey lighten-2"
              >Atividades</v-card-title
            >
            <v-card-subtitle
              ><p>Aguarde veirificando novas atividades....</p></v-card-subtitle
            >
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAwaitSaveLead" width="500">
          <v-card>
            <v-overlay :value="dialogAwaitSaveLead" absolute>
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-card-title class="headline grey lighten-2"
              >Atividades</v-card-title
            >
            <v-card-subtitle>
              <p class="body-1">Aguarde verificando novas Atividades...</p>
            </v-card-subtitle>
            <v-card-text v-if="newAtividades">
              <span class="title"
                >Foram encontrados {{ newAtividades }} Atividades novos</span
              >
              <v-spacer></v-spacer>
              <span
                >Incluíndo lead...
                <span style="font-weight: bolder">{{ nrIncLead }}</span>
              </span>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogTimeLine" width="20%">
          <div style="background: white" class="d-flex">
            <span class="title ml-2">Histórico</span>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialogTimeLine = false">
              Fechar
            </v-btn>
          </div>
          <v-card height="600" class="scroll" v-if="itensTimeLine.length > 0">
            <v-card-text class="py-0">
              <v-timeline align-top dense style="margin-left: -40px">
                <v-timeline-item
                  v-for="(item, i) in itensTimeLine"
                  :key="i"
                  :color="item.color"
                  small
                  :icon="item.icon"
                  fill-dot
                >
                  <v-row>
                    <v-col>
                      <strong>{{ item.operacao }}</strong>
                      <div class="caption">
                        {{ item.caption1 }}
                      </div>
                      <div class="caption">
                        {{ item.caption2 }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogErrosCliente" width="900">
          <v-card max-width="900" class="mx-auto">
            <v-card-title class="headline red lighten-2" style="color: white"
              >Erros ao salvar</v-card-title
            >

            <v-card-text>
              <v-list disabled>
                <v-subheader>Erros</v-subheader>
                <v-list-item-group v-model="aError" color="primary">
                  <v-list-item v-for="(item, i) in aError" :key="i">
                    <v-list-item-icon>
                      <v-icon color="red" v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: 0.8rem"
                        v-text="item.text"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogErrosCliente = false"
                >fechar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="text-center">
          <v-dialog v-model="dialogPaineis" width="500" persistent>
            <v-card>
              <v-overlay :value="dialogPaineis" absolute>
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
              <v-card-title class="headline grey lighten-2">
                Paineis
              </v-card-title>

              <v-card-text>
                {{ messagePainel }}
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <div class="text-center">
          <v-dialog v-model="dialogTransfLead" width="500" persistent>
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Transferência de Atividade
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="RespTransf"
                      :items="itemsResponsaveis"
                      dense
                      filled
                      label="Escolha o novo responsável"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="error" text @click="fechaDialogTransf">
                  Fechar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="efetivaTransferencia">
                  Transferir
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogPaineis" width="500" persistent>
            <v-card>
              <v-overlay :value="dialogPaineis" absolute>
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
              <v-card-title class="headline grey lighten-2">
                Paineis
              </v-card-title>

              <v-card-text>
                {{ messagePainel }}
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <div class="text-center">
          <v-dialog v-model="dialogAtribuiAl" width="500" persistent>
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Atribuição aleatória
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="RespTransf"
                      :items="itemsResponsaveis"
                      dense
                      filled
                      label="Escolha o novo responsável"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="maxAtribui"
                      label="Digite o número de atividades a atribuir"
                      number
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="error" text @click="dialogAtribuiAl = false">
                  Fechar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="efetivaAtribuiAl">
                  Atribuir
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <Opss />
      </v-card>
    </v-card>
    <v-container fluid>
      <Detalhes />
    </v-container>
  </div>
</template>

<script>
import FollowUp from "@/components/Central_CRM/Leads/FollowUp";
import Detalhes from "@/components/Clientes/Detalhes_Cliente";
import Opss from "@/components/templates/Opss";
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "Atividades",
  data: () => {
    return {
      dAwaitOpMaisSeq: false,
      dialogTimeLine: false,
      dialogTransfLead: false,
      RespTransf: null,
      itemsResponsaveis: [],
      dialogPaineis: false,
      messagePainel: null,
      drawer: null,
      dialogOpimedMais: false,
      items: [],
      painelAplicado: false,
      TituloPainel: null,
      DetalhesPainel: null,
      mini: true,
      NrRegFiltro: null,
      codCli: null,
      lojaCli: null,
      dialogDetalhes: false,
      progress: 0,
      indFiltro: "C",
      filtroEscolhido: null,
      id: null,
      aError: [],
      itemsFiltro: [],
      stringFiltro: null,
      dialogErrosCliente: false,
      dialogAwaitSaveCliente: false,
      dialogAwaitSaveLead: false,
      dAwaitSaveAtividade: false,
      dSaveAtividade: false,
      mensagem: null,
      errosTE: false,
      sheetMessage: false,
      colorMessage: "",
      iconMessage: "",
      titleDialog: "",
      selectAtividades: [],
      message: "",
      DialogDados: false,
      isReading: false,
      isEditing: false,
      search: null,
      loading: true,
      altura: 0,
      perPage: 0,
      dialogAtribuiAl: false,
      maxAtribui: 10,
      itensTimeLine: [],
      HeadresAtividades: [],
      itemAtividades: [],
      itemsTipo: [],
      camposTela: [],
      dadosTela: [],
      cliente: {},
      boilerplate: false,
      dadosCep: [],
      DtInicial: new Date().toISOString().substr(0, 10),
      countObrigatorio: 0,
      cloneDadosTela: null,
      interval: 0,
      novaOpMais: false,
      itemsRelatExcel: [],
      headersRelatExcel: [
        { label: "Tipo", field: "ZW4_TIPO" },
        { label: "Status", field: "ZW5_STATUS" },
        { label: "Responsável", field: "ZW5_RESP" },
        { label: "Nome", field: "ZW4_NOME" },
        { label: "Início", field: "ZW5_DTINI" },
        { label: "Fim", field: "ZW5_DTFIM" },
        { label: "Cliente", field: "A1_NOME" },
        { label: "Inclusão", field: "ZW4_DTINC" },
        { label: "Hora", field: "ZW4_HORA" },
        { label: "Usuário de criação", field: "ZW4_USER" },
      ],
      HeadersNovaOpMais: [
        { text: "Sequência", value: "SEQUENCIA" },
        { text: "Documento", value: "DOCUMENTO" },
        { text: "Serie", value: "SERIE" },
        { text: "Início", value: "START" },
        { text: "Fim", value: "START" },
      ],
      dialogAtOpMais: false,
      itensNovaOpMais: [],
      NOME_RELATORIO: "Atividades",
      ZW4_ID: "",
      ZW5_STATUS: "",
      ZW5_RESP: "",
      ZZQ_TEL: "",
      ZZQ_STATUS: "",
      ZZQ_QUALIF: "",
      ZZQ_SITUAC: "",
      itensFiltroEsc: [],
      stringCampos: "",
      top500Atividades: [],
      isNew: false,
      isAdmin: false,
      dtInicial: "",
      dtFinal: "",
      newAtividades: null,
      nrIncLead: 0,
      ultima_atividade_editada: null,
    };
  },
  components: { Opss, FollowUp, Detalhes },
  computed: {
    ...mapState([
      "metadados",
      "user",
      "dialogFollowUp",
      "isReload",
      "tabs",
      "filtroTela",
      "totalGeralAtividades",
    ]),
    DataFormatadaInicial() {
      return moment(this.DtInicial).format("DD/MM/YYYY");
    },
  },
  methods: {
    async efetivaAtribuiAl() {
      if (this.maxAtribui <= 0) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Escolha o número da atividades maior que zero`;
        this.sheetMessage = true;
        return;
      }

      let arrayLivre = this.itemAtividades.filter(
        e => e.ZW5_STATUS === "A=ABERTO",
      );
      let arraySelecionado = [];

      if (this.maxAtribui > arrayLivre.length) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Número de atividades escolhido maior do que aberta(${arrayLivre.length})`;
        this.sheetMessage = true;
        return;
      }

      if (!this.RespTransf) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Escolha um responsável`;
        this.sheetMessage = true;
        return;
      }

      for (let i = 1; i <= this.maxAtribui; i++) {
        let sugestao = Math.ceil(Math.random() * arrayLivre.length);
        while (arraySelecionado.indexOf(sugestao) >= 0) {
          sugestao = Math.ceil(Math.random() * arrayLivre.length);
        }
        arraySelecionado.push(arrayLivre[sugestao]);
      }

      this.selectAtividades = arraySelecionado;

      this.dialogPaineis = true;
      this.messagePainel = `Atribuindo Atividades, aguarde...`;

      const ZW5 = [];

      this.selectAtividades.forEach(e => {
        ZW5.push({
          ZW5_STATUS: "R",
          ZW5_RESP: this.RespTransf.substr(0, 6),
          RECNO: e.RECNO,
        });
      });

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        OPER: "A",
        ZW5,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/atribuiAtividade`;

      await axios
        .put(url, dados)
        .then(res => {
          console.log(res.data);
          this.dialogPaineis = false;
          this.messagePainel = null;
          this.selectAtividades = [];
          this.dialogAtribuiAl = false;
          // console.log(ZW5)
          ZW5.forEach(e => {
            this.itemAtividades.map(a => {
              if (a.RECNO === e.RECNO) {
                a.ZW5_STATUS = "R=ATRIBUÍDO";
                a.ZW5_RESP = this.RespTransf;
              }
            });
          });
          this.selectAtividades = [];
          this.top500Atividades = this.itemAtividades;
        })
        .catch(err => {
          console.log(err);
          this.dialogPaineis = false;
          this.messagePainel = null;
        });
    },
    vercliente(item) {
      this.$store.commit("SetCodLojaCliente", `${item.A1_COD}-${item.A1_LOJA}`);
      this.$store.commit("SetTitle", "Clientes");
      this.$store.commit("SetTabs", {
        name: `${"Clientes"}`,
        content: `Clientes`,
      });
      // this.$store.commit("SetTabActive", this.tabs.length - 1);
      this.$store.commit("SetTitle", "Clientes");
    },
    reloadSave() {
      this.montaBrowser();
      this.dialogSuccessSaveCliente = false;
    },
    fFollowUp() {
      this.$store.commit("SetAlias", "ZW5");
      this.$refs.follow.montaBrowser();
      this.$store.commit("SetDialogFollowUp", true);
    },
    paineis(chamada) {
      if (chamada === "minhasAtividades") this.minhasAtividades();
      //if (chamada === "AtividadesRetorno") this.AtividadesRetorno();
      if (chamada === "AtividadesVencendo") this.AtividadesVencendo();
      if (chamada === "AtividadesVencidas") this.AtividadesVencidas();
      if (chamada === "atribuirAtividades") this.atribuirAtividades();
      if (chamada === "atribuirAtividadesAL") this.atribuirAtividadesAL();
      if (chamada === "transfereAtividade") this.transfereAtividade();
      if (chamada === "histAtividade") this.histAtividade();
    },
    atribuirAtividadesAL() {
      this.dialogAtribuiAl = true;
    },
    async AtividadesVencidas() {
      const filtro = this.top500Atividades.filter(
        e => e.ZW5_STATUS !== "E=ENCERRADO" && this.verdata1(e.ZW5_DTFIM, "<"),
      );

      if (filtro.length > 0) {
        this.painelAplicado = true;
        this.TituloPainel = "Atividade(s) vencidas";
        this.DetalhesPainel = `Total de ${filtro.length} registro(s)`;
        this.itemAtividades = filtro;
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit("SetMensagemDialogOps", "Sem resultado no momento.");
        this.$store.commit("SetdialogNull", true);
      }
    },
    async AtividadesVencendo() {
      const filtro = this.top500Atividades.filter(
        e => e.ZW5_STATUS !== "E=ENCERRADO" && this.verdata1(e.ZW5_DTFIM, "="),
      );

      if (filtro.length > 0) {
        this.painelAplicado = true;
        this.TituloPainel = "Atividade(s) com vencimento hoje";
        this.DetalhesPainel = `Total de ${filtro.length} registro(s)`;
        this.itemAtividades = filtro;
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit("SetMensagemDialogOps", "Sem resultado no momento.");
        this.$store.commit("SetdialogNull", true);
      }
    },
    async AtividadesCliAberto() {
      const filtro = this.top500Atividades.filter(
        e =>
          e.ZZQ_RESP === this.user.id &&
          e.ZZQ_SITUAC.trim() === "Aberto" &&
          e.A1_COD,
      );

      if (filtro.length > 0) {
        this.painelAplicado = true;
        this.TituloPainel = "Meus lead(s) com cliente vinculado em aberto";
        this.DetalhesPainel = `Total de ${filtro.length} registro(s)`;
        this.itemAtividades = filtro;
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit("SetMensagemDialogOps", "Sem resultado no momento.");
        this.$store.commit("SetdialogNull", true);
      }
    },
    randomArr(max) {
      let arrayLivre = this.itemAtividades.filter(
        e => e.ZW5_STATUS === "A=ABERTO",
      );
      let arraySelecionado = [];

      if (max > arrayLivre.length) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Número de atividades escolhido maior do que aberta(${arrayLivre.length})`;
        this.sheetMessage = true;
        return;
      }

      for (let i = 1; i <= max; i++) {
        let sugestao = Math.ceil(Math.random() * arrayLivre.length);
        while (arraySelecionado.indexOf(sugestao) >= 0) {
          sugestao = Math.ceil(Math.random() * arrayLivre.length);
        }
        arraySelecionado.push(arrayLivre[sugestao]);
      }

      this.selectAtividades = arraySelecionado;
    },
    betweenDatas(data1, data2) {
      let dataFormat1 = data1.split("/");
      let dataFormat2 = data2.split("/");

      let dataItem1 = `${dataFormat1[2]}-${dataFormat1[1]}-${dataFormat1[0]}`;
      let dataParse1 = Date.parse(dataItem1);

      let dataItem2 = `${dataFormat2[2]}-${dataFormat2[1]}-${dataFormat2[0]}`;
      let dataParse2 = Date.parse(dataItem2);

      let dataFormat3 = this.dtInicial.split("/");
      let dataFormat4 = this.dtFinal.split("/");

      let dataItem3 = `${dataFormat3[2]}-${dataFormat3[1]}-${dataFormat3[0]}`;
      let dataParse3 = Date.parse(dataItem3);

      let dataItem4 = `${dataFormat4[2]}-${dataFormat4[1]}-${dataFormat4[0]}`;
      let dataParse4 = Date.parse(dataItem4);

      if (dataParse1 >= dataParse3 && dataParse2 <= dataParse4) {
        return true;
      } else {
        return false;
      }
    },
    VerDetAtividade(item) {
      const componenteFilho = this.$children.find(
        component => component.$options.name === "DetAtividades",
      );

      componenteFilho.buscaDados(
        item.ZW4_ID,
        item.A1_NOME,
        item.A1_COD,
        item.A1_LOJA,
      );
    },
    verdata1(data, operador) {
      let dataFormat = data.split("/");
      let dataItem = `${dataFormat[2]}-${dataFormat[1]}-${dataFormat[0]}`;
      let dataParse = Date.parse(dataItem);
      const dataCor = new Date();
      const ano = dataCor.getFullYear().toString();
      let mes = (dataCor.getMonth() + 1).toString();
      let dia = dataCor.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      const hoje = Date.parse(`${ano}-${mes}-${dia}`);

      if (operador === "=") {
        if (dataParse === hoje) {
          return true;
        } else {
          return false;
        }
      }

      if (operador === "<") {
        if (dataParse < hoje) {
          return true;
        } else {
          return false;
        }
      }

      if (operador === "<=") {
        if (dataParse <= hoje) {
          return true;
        } else {
          return false;
        }
      }

      if (operador === ">") {
        if (dataParse > hoje) {
          return true;
        } else {
          return false;
        }
      }

      if (operador === ">=") {
        if (dataParse >= hoje) {
          return true;
        } else {
          return false;
        }
      }
    },
    async AtividadesRetorno() {
      const filtro = this.top500Atividades.filter(
        e =>
          e.ZW5_STATUS === "A=ABERTO" &&
          e.ZW5_RESP &&
          e.ZW5_RESP.substr(0, 6) === this.user.id &&
          this.verdata1(e.ZW5_DTFIM, "<="),
      );

      if (filtro.length > 0) {
        this.painelAplicado = true;
        this.TituloPainel = "Minha(s) atividade(s) com vencimento até hoje";
        this.DetalhesPainel = `Total de ${filtro.length} registro(s)`;
        this.itemAtividades = filtro;
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit("SetMensagemDialogOps", "Sem resultado no momento.");
        this.$store.commit("SetdialogNull", true);
      }
    },
    verdata(data) {
      let dataFormat = data.split("/");
      let dataItem = `${dataFormat[2]}-${dataFormat[1]}-${dataFormat[0]}`;
      let dataParse = Date.parse(dataItem);
      let hoje = new Date();
      hoje = Date.parse(hoje);
      if (dataParse <= hoje) {
        return true;
      } else {
        return false;
      }
    },
    async histAtividade() {
      this.itensTimeLine = [];

      if (
        this.selectAtividades.length <= 0 ||
        this.selectAtividades.length > 1
      ) {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit(
          "SetMensagemDialogOps",
          "Selecione apenas uma Atividade para ver o histórico.",
        );
        this.$store.commit("SetdialogNull", true);
      } else {
        this.dialogPaineis = true;
        this.messagePainel = `Buscando logs, aguarde...`;
        const id = this.selectAtividades[0].RECNO;

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS:
            "ZZS_DATA,ZZS_HORA,ZZS_OPER,ZS1.ZS1_NOME US,ZSS1.ZS1_NOME USER_RESP",
          WHERE: `ZZS_ID='${id}' AND ZZS_ORIGEM='Atividade' ORDER BY ZZS.R_E_C_N_O_ DESC`,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/timeLine`;

        await axios
          .post(url, dados)
          .then(res => {
            res.data.forEach(e => {
              if (e.ZZS_OPER.trim() === "ATRIBUICAO") {
                this.itensTimeLine.push({
                  color: "blue darken-3",
                  icon: "mdi-account-multiple-plus",
                  operacao: e.ZZS_OPER.trim(),
                  caption1: e.US.substr(0, 13),
                  caption2: `${e.ZZS_DATA.substr(6, 2)}/${e.ZZS_DATA.substr(
                    4,
                    2,
                  )}/${e.ZZS_DATA.substr(0, 4)} ${e.ZZS_HORA}`,
                });
              }
              if (e.ZZS_OPER.trim() === "E") {
                e.ZZS_OPER = "ALTERAÇÃO";
                this.itensTimeLine.push({
                  color: "green darken-3",
                  icon: "mdi-account-edit",
                  operacao: e.ZZS_OPER.trim(),
                  caption1: e.US.substr(0, 13),
                  caption2: `${e.ZZS_DATA.substr(6, 2)}/${e.ZZS_DATA.substr(
                    4,
                    2,
                  )}/${e.ZZS_DATA.substr(0, 4)} ${e.ZZS_HORA}`,
                });
              }
              if (e.ZZS_OPER.trim() === "TRANSFERENCIA") {
                this.itensTimeLine.push({
                  color: "yellow darken-3",
                  icon: "mdi-transfer",
                  operacao: e.ZZS_OPER.trim(),
                  caption1: ` => ${e.US.substr(0, 13)}`,
                  caption2: `${e.ZZS_DATA.substr(6, 2)}/${e.ZZS_DATA.substr(
                    4,
                    2,
                  )}/${e.ZZS_DATA.substr(0, 4)} ${e.ZZS_HORA}`,
                });
              }
            });
            if (res.data.length <= 0) {
              this.itensTimeLine.push({
                color: "red darken-3",
                icon: "mdi-close",
                operacao: "SEM DADOS",
                caption1: `Sem histórico no momento`,
                caption2: ``,
              });
            }
            this.dialogPaineis = false;
            this.dialogTimeLine = true;
            this.messagePainel = null;
          })
          .catch(err => {
            console.log(err);
            this.dialogPaineis = false;
            this.messagePainel = null;
          });

        //
      }
    },
    fechaDialogTransf() {
      this.RespTransf = null;
      this.dialogTransfLead = false;
    },
    transfereAtividade() {
      if (this.selectAtividades.length > 0) {
        if (!this.isAdmin) {
          const meus = this.selectAtividades.filter(
            e => e.ZW5_RESP && e.ZW5_RESP.substr(0, 6) === this.user.id,
          );

          if (meus.length !== this.selectAtividades.length) {
            this.painelAplicado = false;
            this.TituloPainel = null;
            this.DetalhesPainel = null;
            this.$store.commit(
              "SetMensagemDialogOps",
              "Foi marcado Atividade(s) que ainda não esta sobre sua responsabilidade, para transferencia marque somente Atividades suas.",
            );
            this.$store.commit("SetdialogNull", true);
          } else {
            this.dialogTransfLead = true;
          }
        } else {
          this.dialogTransfLead = true;
        }
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit(
          "SetMensagemDialogOps",
          "Marque seu(s) registro(s) para transferir!",
        );
        this.$store.commit("SetdialogNull", true);
      }
    },
    marcaItem(item) {
      const marcado = this.selectAtividades.filter(
        e => e.indice === item.indice,
      );

      if (marcado.length <= 0) {
        this.selectAtividades.push(item);
      } else {
        this.selectAtividades = this.selectAtividades.filter(
          e => e.indice !== item.indice,
        );
      }
    },
    async efetivaTransferencia() {
      this.dialogPaineis = true;
      this.messagePainel = `Transferindo Atividades(s), aguarde...`;

      const ZW5 = [];
      this.selectAtividades.forEach(e => {
        ZW5.push({
          RECNO: e.R_E_C_N_O_,
          ZW5_STATUS: "R",
          ZW5_RESP: this.RespTransf.substr(0, 6),
        });
      });

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        OPER: "T",
        ZW5,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/atribuiAtividade`;

      await axios
        .put(url, dados)
        .then(res => {
          console.log(res.data);
          this.dialogPaineis = false;
          this.messagePainel = null;
          this.selectAtividades = [];

          this.dialogTransfLead = false;

          ZW5.forEach(e => {
            this.itemAtividades
              .filter(i => i.R_E_C_N_O_ === e.RECNO)
              .map(f => {
                f.ZW5_RESP = this.RespTransf;
                f.ZW5_STATUS = "R=ATRIBUÍDO";
              });
          });

          // this.itemAtividades.map((e) => {
          //   if (e.R_E_C_N_O_ === ZW5[0].RECNO) {
          //     e.ZW5_RESP = this.RespTransf;
          //   }
          // });
          this.RespTransf = null;
        })
        .catch(err => {
          console.log(err);
          this.dialogPaineis = false;
          this.messagePainel = null;
        });
    },
    minhasAtividades() {
      const filtro = this.top500Atividades.filter(
        e =>
          e.ZW5_STATUS === "R=ATRIBUÍDO" &&
          e.ZW5_RESP.substr(0, 6) === this.user.id,
      );

      if (filtro.length > 0) {
        this.painelAplicado = true;
        this.TituloPainel = "Minha(s) Atividade(s) em aberto";
        this.DetalhesPainel = `Total de ${filtro.length} registro(s)`;
        this.itemAtividades = filtro;
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit("SetMensagemDialogOps", "Sem resultado no momento.");
        this.$store.commit("SetdialogNull", true);
      }
    },
    async atribuirAtividades() {
      if (this.selectAtividades.length > 0) {
        const meus = this.selectAtividades.filter(
          e => e.ZW5_RESP.trim() === "",
        );

        if (meus.length !== this.selectAtividades.length) {
          this.painelAplicado = false;
          this.TituloPainel = null;
          this.DetalhesPainel = null;
          this.$store.commit(
            "SetMensagemDialogOps",
            "Foi marcado lead(s) que já esta atribuído a um responsável, marque somente aqueles que não possuem responsável.",
          );
          this.$store.commit("SetdialogNull", true);
        } else {
          this.dialogPaineis = true;
          this.messagePainel = `Atribuindo Atividades, aguarde...`;

          const ZW5 = [];

          this.selectAtividades.forEach(e => {
            ZW5.push({
              ZW5_STATUS: "R",
              ZW5_RESP: this.user.id,
              RECNO: e.RECNO,
            });
          });

          let dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            OPER: "A",
            ZW5,
          };

          const url = `${process.env.VUE_APP_BASE_API_URL}/atribuiAtividade`;

          await axios
            .put(url, dados)
            .then(() => {
              this.dialogPaineis = false;
              this.messagePainel = null;

              this.selectAtividades.forEach(e => {
                this.itemAtividades.map(a => {
                  if (a.RECNO === e.RECNO) {
                    a.ZW5_STATUS = "R=ATRIBUÍDO";
                    a.ZW5_RESP = `${this.user.id}-${this.user.name}`;
                  }
                });
                this.top500Atividades = this.itemAtividades;
                this.selectAtividades = [];
              });
            })
            .catch(err => {
              console.log(err);
              this.dialogPaineis = false;
              this.messagePainel = null;
            });
        }
      } else {
        this.painelAplicado = false;
        this.TituloPainel = null;
        this.DetalhesPainel = null;
        this.$store.commit(
          "SetMensagemDialogOps",
          "Sem registro(s) marcado(s).",
        );
        this.$store.commit("SetdialogNull", true);
      }
    },
    abrePainel() {
      this.drawer = !this.drawer;
      this.mini = false;
    },
    verTipoFiltro() {
      this.itensFiltroEsc = [];

      if (this.filtroEscolhido === "Responsável") {
        const nomes = [
          ...new Set(this.top500Atividades.map(item => item.ZW5_RESP)),
        ];
        nomes.forEach(e => {
          if (e.trim() !== "" && e !== null) {
            this.itensFiltroEsc.push({
              text: e,
              value: e,
            });
          }
        });
      }

      if (this.filtroEscolhido === "Nome") {
        const nomes = [...new Set(this.itemAtividades.map(e => e.ZW4_NOME))];
        nomes.forEach(e => {
          this.itensFiltroEsc.push({
            text: e,
            value: e,
          });
        });
      }

      if (this.filtroEscolhido === "Status") {
        this.itensFiltroEsc = [
          { text: "Aberto", value: "A=ABERTO" },
          { text: "Atribuído", value: "R=ATRIBUÍDO" },
          { text: "Encerrado", value: "E=ENCERRADO" },
          { text: "Sem contato", value: "S=SEM CONTATO" },
        ];
      }

      if (this.filtroEscolhido === "Nivel 1") {
        this.itensFiltroEsc = [
          { text: "LOCALIZADO", value: "L=LOCALIZADO" },
          { text: "NAO LOCALIZADO", value: "N=NAO LOCALIZADO" },
        ];
      }

      if (this.filtroEscolhido === "Nivel 2") {
        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "",
          WHERE: "",
          TABELA: "ZW7",
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

        axios
          .post(url, dados)
          .then(res => {
            res.data.forEach(e => {
              this.itensFiltroEsc.push({
                text: e.ZW7_DESCRI,
                value: `${e.ZW7_CODIGO}=${e.ZW7_DESCRI}`,
              });
            });
          })
          .catch(error => {
            console.log(error);
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });
      }
    },
    LimpaFiltroPainel() {
      // this.stringFiltro = null;
      // this.filtroEscolhido = null;
      // this.dtInicial = null
      // this.dtFinal = null
      this.NrRegFiltro = null;
      //this.itensFiltroEsc = [];
      this.painelAplicado = false;
      this.TituloPainel = null;
      this.DetalhesPainel = null;
      this.itemAtividades = this.top500Atividades;
      this.itemsRelatExcel = this.top500Atividades;
    },
    LimpaFiltro() {
      //this.stringFiltro = null;
      //this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      //this.itensFiltroEsc = [];
      //this.dtInicial = "";
      //this.dtFinal = "";
      if (!this.painelAplicado) {
        this.itemAtividades = this.top500Atividades;
        this.itemsRelatExcel = this.top500Atividades;
      } else {
        this.meusAtividades();
      }
    },
    async filtraRegistro(campo, stringFiltro) {
      this.NrRegFiltro = null;
      this.painelAplicado = false;
      this.TituloPainel = null;
      this.DetalhesPainel = null;
      this.itemAtividades = this.top500Atividades;
      this.itemsRelatExcel = this.top500Atividades;

      if (campo && stringFiltro && this.dtInicial && this.dtFinal) {
        if (campo === "Status") {
          this.itemAtividades = this.itemAtividades.filter(
            e =>
              e.ZW5_STATUS === stringFiltro &&
              this.betweenDatas(e.ZW5_DTINI, e.ZW5_DTFIM),
          );
        }
        if (campo === "Responsável") {
          this.itemAtividades = this.itemAtividades.filter(
            e =>
              e.ZW5_RESP === stringFiltro &&
              this.betweenDatas(e.ZW5_DTINI, e.ZW5_DTFIM),
          );
        }
        if (campo === "Nome") {
          this.itemAtividades = this.itemAtividades.filter(
            e =>
              e.ZW4_NOME === stringFiltro &&
              this.betweenDatas(e.ZW5_DTINI, e.ZW5_DTFIM),
          );
        }
        if (campo === "Nivel 1") {
          this.itemAtividades = this.itemAtividades.filter(
            e =>
              e.ZW5_NIVEL1 === stringFiltro &&
              this.betweenDatas(e.ZW5_DTINI, e.ZW5_DTFIM),
          );
        }
        if (campo === "Nivel 2") {
          this.itemAtividades = this.itemAtividades.filter(
            e =>
              e.ZW5_NIVEL2 === stringFiltro.trim() &&
              this.betweenDatas(e.ZW5_DTINI, e.ZW5_DTFIM),
          );
        }
      }

      if (!campo && !stringFiltro && this.dtInicial && this.dtFinal) {
        this.itemAtividades = this.itemAtividades.filter(e =>
          this.betweenDatas(e.ZW5_DTINI, e.ZW5_DTFIM),
        );
      }

      if (campo && stringFiltro && !this.dtInicial && !this.dtFinal) {
        if (campo === "Status") {
          this.itemAtividades = this.itemAtividades.filter(
            e => e.ZW5_STATUS === stringFiltro,
          );
        }
        if (campo === "Responsável") {
          this.itemAtividades = this.itemAtividades.filter(
            e => e.ZW5_RESP === stringFiltro,
          );
        }
        if (campo === "Nome") {
          this.itemAtividades = this.itemAtividades.filter(
            e => e.ZW4_NOME === stringFiltro,
          );
        }
        if (campo === "Nivel 1") {
          this.itemAtividades = this.itemAtividades.filter(
            e => e.ZW5_NIVEL1 === stringFiltro,
          );
        }
        if (campo === "Nivel 2") {
          this.itemAtividades = this.itemAtividades.filter(
            e => e.ZW5_NIVEL2 === stringFiltro.trim(),
          );
        }
      }

      this.painelAplicado = true;
      if (!campo) {
        this.TituloPainel = `Filtrado por data inicial e final`;
      } else {
        this.TituloPainel = `Filtrado por ${campo}`;
      }
      this.DetalhesPainel = `Total de ${this.itemAtividades.length} registro(s)`;

      this.itemsRelatExcel = this.itemAtividades;
    },
    VerDetalhes(item) {
      this.codCli = item.A1_COD;
      this.lojaCli = item.A1_LOJA;
      const componenteFilho = this.$children.find(
        component => component.$options.name === "DetCli",
      );
      componenteFilho.buscaDados(item.A1_COD, item.A1_LOJA, item.A1_NOME);
    },
    obrigat(value, obrigatorio) {
      if (!value && obrigatorio === "1") {
        return ["Campo obrigatório!"];
      }
    },
    queryAndIndeterminate() {
      this.progress = 0;

      this.interval = setInterval(() => {
        if (this.progress === 100) {
          clearInterval(this.interval);
          this.progress = 0;
          return;
          //return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.progress += 5;
      }, 1000);
    },
    //Função para remover caracteres especiais na Notas
    removeAcento(text) {
      if (text.trim() !== "") {
        text = text.toLowerCase();
        text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
        text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
        text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
        text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
        text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
        text = text.replace(new RegExp("[Ç]", "gi"), "c");
        return text.toUpperCase();
      } else {
        return "";
      }
    },
    async salva(id) {
      const keysObrigat = [];
      let cloneDadosTela = [];

      cloneDadosTela.push({ ...this.dadosTela });

      this.camposTela.filter(e => {
        if (cloneDadosTela[0].ZW4_TIPO.trim() !== "O=OPIMED+") {
          if (e.ZZP_OBRIGA === "1") {
            const campo = e.ZZP_CAMPO.trim();
            keysObrigat.push({
              campo,
            });
          }
        } else {
          if (
            e.ZZP_OBRIGA === "1" &&
            e.ZZP_CAMPO.trim() !== "ZW5_DTINI" &&
            e.ZZP_CAMPO.trim() !== "ZW5_DTFIM"
          ) {
            const campo = e.ZZP_CAMPO.trim();
            keysObrigat.push({
              campo,
            });
          }
        }
      });

      let verifica = true;

      const keys = Object.keys(cloneDadosTela[0]);

      keysObrigat.forEach(e => {
        if (keys.indexOf(e.campo) < 0) {
          verifica = false;
        }
      });

      if (!verifica) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      const data = new Date();
      const ano = data.getFullYear().toString();
      let mes = (data.getMonth() + 1).toString();
      let dia = data.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      //const hoje = Date.parse(`${ano}-${mes}-${dia}`);

      let loja;
      let nomecliente;
      //let resp

      this.camposTela.forEach(e => {
        let value = null;
        if (
          e.ZZP_TIPO.trim() === "A" &&
          cloneDadosTela[0][e.ZZP_CAMPO.trim()]
        ) {
          value = cloneDadosTela[0][e.ZZP_CAMPO.trim()].split("-");
          if (
            e.ZZP_CAMPO.trim() === "ZW5_NIVEL2" ||
            e.ZZP_CAMPO.trim() === "ZW5_SEQ"
          ) {
            cloneDadosTela[0][e.ZZP_CAMPO.trim()] = `${value[0]}`;
          } else {
            cloneDadosTela[0][e.ZZP_CAMPO.trim()] = value[0].trim();
            loja = value[1].trim();
            nomecliente = value[2].trim();
          }
        }
        if (
          e.ZZP_TIPO.trim() === "S" &&
          cloneDadosTela[0][e.ZZP_CAMPO.trim()]
        ) {
          value = cloneDadosTela[0][e.ZZP_CAMPO.trim()].split("=");
          cloneDadosTela[0][e.ZZP_CAMPO.trim()] = value[0];
        }
        if (
          e.ZZP_TIPO.trim() === "D" &&
          cloneDadosTela[0][e.ZZP_CAMPO.trim()]
        ) {
          value = cloneDadosTela[0][e.ZZP_CAMPO.trim()].split("/");
          cloneDadosTela[0][
            e.ZZP_CAMPO.trim()
          ] = `${value[2]}${value[1]}${value[0]}`;
        }
        if (
          e.ZZP_TIPO.trim() === "T" &&
          cloneDadosTela[0][e.ZZP_CAMPO.trim()] &&
          cloneDadosTela[0][e.ZZP_CAMPO.trim()] !== "ZZQ_EMAIL"
        ) {
          value = this.removeAcento(cloneDadosTela[0][e.ZZP_CAMPO.trim()]);
          cloneDadosTela[0][e.ZZP_CAMPO.trim()] = value.trim();
        }
        if (
          cloneDadosTela[0][e.ZZP_CAMPO.trim()] &&
          e.ZZP_TIPO.trim() === "Y"
        ) {
          value = this.removeAcento(cloneDadosTela[0][e.ZZP_CAMPO.trim()]);
          cloneDadosTela[0][e.ZZP_CAMPO.trim()] = value.trim();
        }
      });

      let continua = false;
      let ProxOpimedMais = [];

      this.queryAndIndeterminate();

      this.dAwaitSaveAtividade = true;

      this.progress = 0;

      if (cloneDadosTela[0].ZW4_TIPO === "O" && !id) {
        let dados = {
          DOCUMENTO: cloneDadosTela[0].ZW5_DOC,
          SERIE: cloneDadosTela[0].ZW5_SERIE,
          CLIENTE: cloneDadosTela[0].ZW5_CLIENT,
          LOJA: loja,
        };

        let url = `${process.env.VUE_APP_BASE_API_URL}/opimedMaisManual`;

        const verDocBase = await axios.post(url, dados).then(res => res.data);

        if (verDocBase.length <= 0) {
          this.iconMessage = "mdi-close";
          this.colorMessage = "red lighten-4";
          this.message = `Não achei o documento, série ou cliente para gerar o Opimed+, revise seus dados digitados!`;
          this.sheetMessage = true;
          this.dAwaitSaveAtividade = false;
          this.progress = 100;
          return;
        }

        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `MAX(ZW5_STATUS) STATUS`,
          WHERE: `ZW5_TIPO='O' AND ZW5_CLIENT='${cloneDadosTela[0].ZW5_CLIENT}' AND ZW5_LOJA='${loja}' AND ZW5_DOC='${cloneDadosTela[0].ZW5_DOC}' AND ZW5_SERIE='${cloneDadosTela[0].ZW5_SERIE}'`,
          TABELA: "ZW5",
        };

        url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

        const Status = await axios
          .post(url, dados)
          .then(res => res.data[0].STATUS)
          .catch(error => {
            console.log(error);
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });

        if (Status !== "E" && Status !== "S") {
          this.iconMessage = "mdi-close";
          this.colorMessage = "red lighten-4";
          this.message = `Não pode incluir uma nova sequência sem a anterior estar encerrada ou sem contato, revise a sequência anterior!`;
          this.sheetMessage = true;
          this.dAwaitSaveAtividade = false;
          this.progress = 100;
          return;
        }

        const sequenciaEsc = parseFloat(cloneDadosTela[0].ZW5_SEQ);

        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "MAX(ZW5_SEQ) SEQUENCIA",
          WHERE: `ZW5_TIPO='O' AND ZW5_CLIENT='${cloneDadosTela[0].ZW5_CLIENT}' AND ZW5_LOJA='${loja}' AND ZW5_DOC='${cloneDadosTela[0].ZW5_DOC}' AND ZW5_SERIE='${cloneDadosTela[0].ZW5_SERIE}'`,
          TABELA: "ZW5",
        };

        url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

        let sequenciaBanco = await axios
          .post(url, dados)
          .then(res => res.data[0].SEQUENCIA)
          .catch(error => {
            console.log(error);
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });

        sequenciaBanco = parseFloat(sequenciaBanco);

        if (sequenciaEsc < sequenciaBanco) {
          this.iconMessage = "mdi-close";
          this.colorMessage = "red lighten-4";
          this.message = `A sequência escolhida é menor do que a última sequência no banco, revise seus dados!`;
          this.sheetMessage = true;
          this.dAwaitSaveAtividade = false;
          this.progress = 100;
          return;
        }

        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "",
          WHERE: `ZW5_CLIENT='${cloneDadosTela[0].ZW5_CLIENT}' AND ZW5_LOJA='${loja}' AND ZW5_DOC='${cloneDadosTela[0].ZW5_DOC}' AND ZW5_SERIE='${cloneDadosTela[0].ZW5_SERIE}' AND ZW5_SEQ='${cloneDadosTela[0].ZW5_SEQ}' AND ZW5_STATUS IN ('A','R')`,
          TABELA: "ZW5",
        };

        url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

        await axios
          .post(url, dados)
          .then(res => {
            if (res.data.length <= 0) {
              continua = true;
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });

        if (continua) {
          dados = {
            EMRPESA: "01",
            FILIAL: "0101",
            FIELDS: "ZW9_START",
            WHERE: `ZW9_SEQ='${cloneDadosTela[0].ZW5_SEQ}' AND ZW9_MSBLQ='2'`,
            TABELA: "ZW9",
          };

          const dias = await axios
            .post(url, dados)
            .then(res => res.data[0].ZW9_START);

          dados = {
            DIAS: dias,
            DOCUMENTO: cloneDadosTela[0].ZW5_DOC,
            SERIE: cloneDadosTela[0].ZW5_SERIE,
            CLIENTE: cloneDadosTela[0].ZW5_CLIENT,
            LOJA: loja,
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/proxOpimedMais`;

          ProxOpimedMais = await axios.post(url, dados).then(res => res.data);
        }
      } else {
        continua = true;
      }

      if (continua) {
        const hora = data.getHours();
        let minuto = data.getMinutes();
        if (minuto < 10) {
          minuto = `0${minuto}`;
        }

        let ZW4 = {};

        let ZW5 = [];

        // const recno = this.itemAtividades[this.itemAtividades.length - 1].R_E_C_N_O_;

        let cliente = ``;

        cloneDadosTela.forEach(e => {
          const status = e.ZW5_STATUS.split("=");
          if (status[0] === "A") {
            status[0] = "R";
          }
          if (id) {
            cliente = e.A1_NOME;
            ZW4 = {
              ZW4_ID: id,
            };

            ZW5.push({
              ZW5_ID: id,
              ZW5_STATUS: status[0],
              ZW5_RESP: this.user.id,
              ZW5_DTFIM: e.ZW5_DTFIM,
              ZW5_NIVEL1: e.ZW5_NIVEL1,
              ZW5_NIVEL2: e.ZW5_NIVEL2,
              RECNO: e.R_E_C_N_O_,
            });
          } else {
            if (cloneDadosTela[0].ZW4_TIPO !== "O") {
              ZW4 = {
                ZW4_NOME: this.removeAcento(e.ZW4_NOME),
                ZW4_DTINI: e.ZW5_DTINI,
                ZW4_DTFIM: e.ZW5_DTFIM,
                ZW4_TIPO: e.ZW4_TIPO,
                ZW4_USER: this.user.id,
                ZW4_MODEL: "PORTAL",
                ZW4_NREGIS: 1,
                ZW4_NREGTO: 1,
                ZW4_FILTRO: "",
                ZW4_DTINC: `${ano}${mes}${dia}`,
                ZW4_HORA: `${hora}:${minuto}`,
                ZW4_FINALI: this.removeAcento(e.ZW4_FINALI),
              };

              ZW5.push({
                ZW5_CLIENT: e.ZW5_CLIENT,
                ZW5_LOJA: loja,
                ZW5_STATUS: "R",
                ZW5_RESP: this.user.id,
                ZW5_DTINI: e.ZW5_DTINI,
                ZW5_DTFIM: e.ZW5_DTFIM,
                ZW5_TIPO: e.ZW4_TIPO,
              });
            } else {
              ZW4 = {
                ZW4_NOME: this.removeAcento(e.ZW4_NOME),
                ZW4_DTINI: ProxOpimedMais[0].START,
                ZW4_DTFIM: ProxOpimedMais[0].START,
                ZW4_TIPO: e.ZW4_TIPO,
                ZW4_USER: this.user.id,
                ZW4_MODEL: "PORTAL",
                ZW4_NREGIS: 1,
                ZW4_NREGTO: 1,
                ZW4_FILTRO: "",
                ZW4_DTINC: `${ano}${mes}${dia}`,
                ZW4_HORA: `${hora}:${minuto}`,
                ZW4_FINALI: this.removeAcento(e.ZW4_FINALI),
              };
              ZW5.push({
                ZW5_CLIENT: e.ZW5_CLIENT,
                ZW5_LOJA: loja,
                ZW5_STATUS: "R",
                ZW5_RESP: this.user.id,
                ZW5_DTINI: ProxOpimedMais[0].START,
                ZW5_DTFIM: ProxOpimedMais[0].START,
                ZW5_TIPO: e.ZW4_TIPO,
                ZW5_LOJAOP: ProxOpimedMais[0].FILIAL,
                ZW5_DLJOPI: ProxOpimedMais[0].LOJA_OPIMED,
                ZW5_DOC: ProxOpimedMais[0].DOCUMENTO,
                ZW5_SERIE: ProxOpimedMais[0].SERIE,
                ZW5_SEQ: cloneDadosTela[0].ZW5_SEQ,
              });
            }
          }
        });

        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZW4,
          ZW5,
        };

        const url = `${process.env.VUE_APP_BASE_API_URL}/campanhas`;

        //this.queryAndIndeterminate();

        //this.dAwaitSaveAtividade = true

        //this.progress = 0;

        await axios
          .post(url, dados)
          .then(res => {
            console.log(res.data);
            if (res.data.STATUS === "true") {
              this.ultima_atividade_editada = cliente;
              this.dAwaitSaveAtividade = false;
              this.progress = 100;
              this.DialogDados = false;
              this.dSaveAtividade = true;
              if (id) {
                if (ZW5[0].ZW5_STATUS === "R")
                  ZW5[0].ZW5_STATUS = "R=ATRIBUÍDO";
                if (ZW5[0].ZW5_STATUS === "E")
                  ZW5[0].ZW5_STATUS = "E=ENCERRADO";
                if (ZW5[0].ZW5_STATUS === "S")
                  ZW5[0].ZW5_STATUS = "S=SEM CONTATO";
                this.itemAtividades.map(e => {
                  if (e.R_E_C_N_O_ === ZW5[0].RECNO) {
                    e.ZW5_STATUS = ZW5[0].ZW5_STATUS;
                    e.ZW5_DTFIM = `${ZW5[0].ZW5_DTFIM.substr(
                      6,
                      2,
                    )}/${ZW5[0].ZW5_DTFIM.substr(
                      4,
                      2,
                    )}/${ZW5[0].ZW5_DTFIM.substr(0, 4)}`;
                    e.ZW5_RESP = `${ZW5[0].ZW5_RESP}-${this.user.name}`;
                  }
                });
                this.id = null;
              } else {
                //this.montaBrowser();

                console.log("nova atividade");
                let newDados = {};
                if (cloneDadosTela[0].ZW4_TIPO === "O") {
                  newDados = {
                    A1_COD: ZW5[0].ZW5_CLIENT,
                    A1_LOJA: ZW5[0].ZW5_LOJA,
                    A1_NOME: nomecliente,
                    RECNO: "",
                    ZS1_NOME: this.user.name,
                    ZW5_DTFIM: `${ProxOpimedMais[0].START.substr(
                      6,
                      2,
                    )}/${ProxOpimedMais[0].START.substr(
                      4,
                      2,
                    )}/${ProxOpimedMais[0].START.substr(0, 4)}`,
                    ZW5_DTINI: `${ProxOpimedMais[0].START.substr(
                      6,
                      2,
                    )}/${ProxOpimedMais[0].START.substr(
                      4,
                      2,
                    )}/${ProxOpimedMais[0].START.substr(0, 4)}`,

                    ZW4_DTINC: `${dia}/${mes}/${ano}`,
                    ZW4_HORA: ZW4.ZW4_HORA,
                    ZW4_ID: res.data.CODIGO,
                    ZW4_NOME: ZW4.ZW4_NOME,
                    ZW4_TIPO: "O",
                    ZW4_USER: `${this.user.id}-${this.user.name}`,
                    ZW5_RESP: `${this.user.id}-${this.user.name}`,
                    ZW5_STATUS: "R=ATRIBUÍDO",
                    indice: `${ZW5[0].ZW5_CLIENT}${ZW5[0].ZW5_LOJA}${res.data.CODIGO}`,
                    ZW5_SEQ: ZW5[0].ZW5_SEQ,
                    ZW5_LOJAOP: ZW5[0].ZW5_LOJAOP,
                    ZW5_DLJOPI: ZW5[0].ZW5_DLJOPI,
                    ZW5_DOC: ZW5[0].ZW5_DOC,
                    ZW5_SERIE: ZW5[0].ZW5_SERIE,
                  };
                } else {
                  newDados = {
                    A1_COD: ZW5[0].ZW5_CLIENT,
                    A1_LOJA: ZW5[0].ZW5_LOJA,
                    A1_NOME: nomecliente,
                    RECNO: "",
                    ZS1_NOME: this.user.name,
                    ZW5_DTFIM: `${ZW5[0].ZW5_DTFIM.substr(
                      6,
                      2,
                    )}/${ZW5[0].ZW5_DTFIM.substr(
                      4,
                      2,
                    )}/${ZW5[0].ZW5_DTFIM.substr(0, 4)}`,
                    ZW5_DTINI: `${ZW5[0].ZW5_DTINI.substr(
                      6,
                      2,
                    )}/${ZW5[0].ZW5_DTINI.substr(
                      4,
                      2,
                    )}/${ZW5[0].ZW5_DTINI.substr(0, 4)}`,
                    ZW4_DTINC: `${ano}${mes}${dia}`,
                    ZW4_HORA: ZW4.ZW4_HORA,
                    ZW4_ID: res.data.CODIGO,
                    ZW4_NOME: ZW4.ZW4_NOME,
                    ZW4_TIPO: "INDIVIDUAL",
                    ZW4_USER: `${this.user.id}-${this.user.name}`,
                    ZW5_RESP: `${this.user.id}-${this.user.name}`,
                    ZW5_STATUS: "R=ATRIBUÍDO",
                    indice: `${ZW5[0].ZW5_CLIENT}${ZW5[0].ZW5_LOJA}${res.data.CODIGO}`,
                  };
                }
                this.itemAtividades.unshift(newDados);
                this.top500Atividades = this.itemAtividades;
              }
              if (
                cloneDadosTela[0].ZW4_TIPO === "O" &&
                id &&
                cloneDadosTela[0].ZW5_STATUS === "E"
              ) {
                this.dSaveAtividade = false;
                this.dAwaitOpMaisSeq = true;
                this.sugerirNovaOpMais(cloneDadosTela[0]);
              }
            }
          })
          .catch(error => {
            this.dialogAwaitSaveCamp = false;
            this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
            this.erro += `Chamada: ${url} =====>`;
            this.erro += `${error}`;
            this.$store.commit("setDialogError", true);
          });
        this.progress = 100;
      } else {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `Já existe atividade para esta sequencia Opimed+`;
        this.sheetMessage = true;
        this.dAwaitSaveAtividade = false;
        this.progress = 100;
      }
    },
    async sugerirNovaOpMais(dados) {
      this.itensNovaOpMais = [];

      if (dados.ZW4_TIPO === "O" && dados.ZW5_STATUS === "E") {
        let dadosC = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `MIN(ZW9_SEQ) SEQUENCIA`,
          WHERE: `ZW9_SEQ > '${dados.ZW5_SEQ}' AND ZW9_MSBLQ='2'`,
          TABELA: "ZW9",
        };

        let url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

        const novaSequencia = await axios
          .post(url, dadosC)
          .then(res => res.data)
          .catch(error => {
            console.log(error);
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });

        if (novaSequencia.length > 0) {
          dadosC = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: "",
            WHERE: `ZW9_SEQ='${novaSequencia[0].SEQUENCIA}' AND ZW9_MSBLQ='2'`,
            TABELA: "ZW9",
          };

          const dadosNovaSeq = await axios
            .post(url, dadosC)
            .then(res => res.data);

          dadosC = {
            DIAS: dadosNovaSeq[0].ZW9_START,
            DOCUMENTO: dados.ZW5_DOC,
            SERIE: dados.ZW5_SERIE,
            CLIENTE: dados.ZW5_CLIENT,
            LOJA: dados.A1_LOJA,
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/proxOpimedMais`;

          const ProxOpimedMais = await axios
            .post(url, dadosC)
            .then(res => res.data);

          this.itensNovaOpMais.push({
            DOCUMENTO: dados.ZW5_DOC,
            SEQUENCIA: dadosNovaSeq[0].ZW9_DESCRI,
            SERIE: dados.ZW5_SERIE,
            START: `${ProxOpimedMais[0].START.substr(
              6,
              2,
            )}/${ProxOpimedMais[0].START.substr(
              4,
              2,
            )}/${ProxOpimedMais[0].START.substr(0, 4)}`,
            DATABANCO: ProxOpimedMais[0].START,
            DADOSTELA: dados,
            ZW5_SEQ: novaSequencia[0].SEQUENCIA,
          });

          this.novaOpMais = true;
          this.dAwaitOpMaisSeq = false;
        }
      }
    },
    async gravaNovaOpMais() {
      this.progress = 0;
      let dadosOpMais = this.itensNovaOpMais[0].DADOSTELA;

      const data = new Date();
      const ano = data.getFullYear().toString();
      let mes = (data.getMonth() + 1).toString();
      let dia = data.getDate().toString();

      const hora = data.getHours();
      let minuto = data.getMinutes();
      if (minuto < 10) {
        minuto = `0${minuto}`;
      }

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      let ZW5 = [];

      let ZW4 = {
        ZW4_NOME: `OPIMED MAIS`,
        ZW4_DTINI: this.itensNovaOpMais[0].DATABANCO,
        ZW4_DTFIM: this.itensNovaOpMais[0].DATABANCO,
        ZW4_TIPO: "O",
        ZW4_USER: this.user.id,
        ZW4_MODEL: "PORTAL",
        ZW4_NREGIS: 1,
        ZW4_NREGTO: 1,
        ZW4_FILTRO: "",
        ZW4_DTINC: `${ano}${mes}${dia}`,
        ZW4_HORA: `${hora}:${minuto}`,
        ZW4_FINALI: "CONFIRMAR AGENDA COM O PACIENTE",
      };

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZM0_NOME",
        WHERE: `ZM0_CODFIL='${dadosOpMais.ZW5_LOJAOP}'`,
        TABELA: "ZM0",
      };

      this.dAwaitSaveAtividade = true;

      let url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const loja_Opimed = await axios
        .post(url, dados)
        .then(res => res.data[0].ZM0_NOME.trim())
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      ZW5.push({
        ZW5_CLIENT: dadosOpMais.ZW5_CLIENT,
        ZW5_LOJA: dadosOpMais.A1_LOJA,
        ZW5_STATUS: "R",
        ZW5_RESP: this.user.id,
        ZW5_DTINI: this.itensNovaOpMais[0].DATABANCO,
        ZW5_DTFIM: this.itensNovaOpMais[0].DATABANCO,
        ZW5_TIPO: "O",
        ZW5_LOJAOP: dadosOpMais.ZW5_LOJAOP,
        ZW5_DLJOPI: loja_Opimed,
        ZW5_DOC: dadosOpMais.ZW5_DOC,
        ZW5_SERIE: dadosOpMais.ZW5_SERIE,
        ZW5_SEQ: this.itensNovaOpMais[0].ZW5_SEQ,
      });

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZW4,
        ZW5,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/campanhas`;

      await axios
        .post(url, dados)
        .then(res => {
          console.log(res.data);
          if (res.data.STATUS === "true") {
            this.dAwaitSaveAtividade = false;
            this.progress = 100;
            this.DialogDados = false;
            this.dSaveAtividade = true;
            this.novaOpMais = false;

            //this.montaBrowser();

            console.log("nova atividade");
            let newDados = {};

            newDados = {
              A1_COD: ZW5[0].ZW5_CLIENT,
              A1_LOJA: ZW5[0].ZW5_LOJA,
              A1_NOME: ZW4.ZW4_NOME,
              RECNO: "",
              ZS1_NOME: this.user.name,
              ZW5_DTFIM: `${ZW5[0].ZW5_DTFIM.substr(
                6,
                2,
              )}/${ZW5[0].ZW5_DTFIM.substr(4, 2)}/${ZW5[0].ZW5_DTFIM.substr(
                0,
                4,
              )}`,
              ZW5_DTINI: `${ZW5[0].ZW5_DTINI.substr(
                6,
                2,
              )}/${ZW5[0].ZW5_DTINI.substr(4, 2)}/${ZW5[0].ZW5_DTINI.substr(
                0,
                4,
              )}`,

              ZW4_DTINC: `${dia}/${mes}/${ano}`,
              ZW4_HORA: ZW4.ZW4_HORA,
              ZW4_ID: res.data.CODIGO,
              ZW4_NOME: `OPIMED+ ${this.itensNovaOpMais[0].SEQUENCIA}`,
              ZW4_TIPO: "O",
              ZW4_USER: `${this.user.id}-${this.user.name}`,
              ZW5_RESP: `${this.user.id}-${this.user.name}`,
              ZW5_STATUS: "R=ATRIBUÍDO",
              indice: `${ZW5[0].ZW5_CLIENT}${ZW5[0].ZW5_LOJA}${res.data.CODIGO}`,
              ZW5_SEQ: ZW5[0].ZW5_SEQ,
              ZW5_LOJAOP: ZW5[0].ZW5_LOJAOP,
              ZW5_DLJOPI: ZW5[0].ZW5_DLJOPI,
              ZW5_DOC: ZW5[0].ZW5_DOC,
              ZW5_SERIE: ZW5[0].ZW5_SERIE,
            };

            this.itemAtividades.unshift(newDados);
            this.top500Atividades = this.itemAtividades;
          }
        })
        .catch(error => {
          this.dialogAwaitSaveCamp = false;
          this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
          this.erro += `Chamada: ${url} =====>`;
          this.erro += `${error}`;
          this.$store.commit("setDialogError", true);
        });
      this.progress = 0;
    },
    limpaVariaveis() {
      this.DialogDados = false;
      this.dialogSuccessSaveCliente = false;

      if (this.dadosTela.ZZQ_SITUAC === "N=Novo")
        this.dadosTela.ZZQ_SITUAC = "Novo";
      if (this.dadosTela.ZZQ_SITUAC === "A=Aberto")
        this.dadosTela.ZZQ_SITUAC = "Aberto";
      if (this.dadosTela.ZZQ_SITUAC === "E=Encerrado")
        this.dadosTela.ZZQ_SITUAC = "Encerrado";
      if (this.dadosTela.ZZQ_SITUAC === "S=Sem contato")
        this.dadosTela.ZZQ_SITUAC = "Sem contato";
      if (this.dadosTela.ZZQ_QUALIF === "1=Sim")
        this.dadosTela.ZZQ_QUALIF = "Sim";
      if (this.dadosTela.ZZQ_QUALIF === "2=Não")
        this.dadosTela.ZZQ_QUALIF = "Não";

      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "M=Min")
        this.dadosTela.ZZQ_PESTRA = "Min";
      if (
        this.dadosTela.ZZQ_PESTRA &&
        this.dadosTela.ZZQ_PESTRA === "F=Familiar"
      )
        this.dadosTela.ZZQ_PESTRA = "Familiar";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "A=Amigo")
        this.dadosTela.ZZQ_PESTRA = "Amigo";

      if (this.dadosTela.ZZQ_ISCLI === "1=Sim")
        this.dadosTela.ZZQ_ISCLI = "Sim";
      if (this.dadosTela.ZZQ_ISCLI === "2=Não")
        this.dadosTela.ZZQ_ISCLI = "Não";

      if (this.dadosTela.ZZQ_LAUDOM === "1=Sim")
        this.dadosTela.ZZQ_LAUDOM = "Sim";
      if (this.dadosTela.ZZQ_LAUDOM === "2=Não")
        this.dadosTela.ZZQ_LAUDOM = "Não";

      if (this.dadosTela.ZZQ_APUSER === "1=Sim")
        this.dadosTela.ZZQ_APUSER = "Sim";
      if (this.dadosTela.ZZQ_APUSER === "2=Não")
        this.dadosTela.ZZQ_APUSER = "Não";

      const resp = this.dadosTela.ZZQ_RESP
        ? this.dadosTela.ZZQ_RESP.split("-")
        : null;

      if (resp) this.dadosTela.ZZQ_RESP = resp[0];

      if (this.isReload && !this.painelAplicado) {
        this.montaBrowser();
      }
      if (this.painelAplicado) {
        this.meusAtividades();
      }
      this.isEditing = false;
      this.isNew = false;
      this.isReading = false;
      this.$store.commit("setOrigem", "");
    },
    async carregaDados() {
      this.boilerplate = false;
      let campos = [];

      this.countObrigatorio = null;

      let ZZP = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === "MD016" && e.ZZP_USADO === "1",
      );

      ZZP.map(e => {
        if (e.ZZP_CBOX.trim().length > 0) {
          const dados = e.ZZP_CBOX.split(";");
          e.ITEMS = dados.map(i => {
            return `${i}`;
          });
        }

        if (e.ZZP_TIPO.trim() === "D") {
          e.MASK = "##/##/####";
        }
        if (e.ZZP_OBRIGA === "1") {
          this.countObrigatorio++;
        }
      });

      campos = ZZP;

      if (this.isNew) {
        this.dadosTela.ZZQ_RESP1 = `${this.user.id}-${this.user.name}`;
        this.dadosTela.ZZQ_SITUAC = `N=Novo`;
      }

      this.camposTela = campos;

      this.boilerplate = true;
    },
    async reload(item) {
      item.ITEMS = await this.chamaConsulta(item);

      this.camposTela.map(e => {
        if (e.ZZP_CAMPO.trim() === item.ZZP_CAMPO.trim()) {
          e.ITEMS = item.ITEMS;
        }
      });

      return this.camposTela;
    },
    chamaConsulta(item) {
      let dados = {};
      let items = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: ``,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      return items;
    },
    async montaBrowser() {
      this.filtroEscolhido = null;
      this.stringFiltro = null;
      this.dtInicial = null;
      this.dtFinal = null;
      this.NrRegFiltro = null;
      this.altura = window.screen.availHeight - 300;
      this.loading = true;
      let ZZP = [];
      let dados = {};
      let url = ``;
      this.HeadresAtividades = [];
      this.HeadresAtividades.push({
        text: "Ações",
        value: "actions",
        align: "start",
        width: 100,
        caption: "title",
      });

      ZZP = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD015");

      ZZP.map(e => {
        let width = 0;
        if (e.ZZP_COLS <= 2) {
          width = 160;
        } else if (e.ZZP_COLS <= 3) {
          width = 350;
        } else {
          width = 300;
        }
        const name = `${e.ZZP_CAMPO.trim()}`;
        if (e.ZZP_BROWSE === "1") {
          // if (this.isAdmin) {
          if (name === "ZW5_RESP") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresAtividades.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
              filter: f => {
                return (f + "")
                  .toLowerCase()
                  .includes(this["ZW5_RESP"].toLowerCase());
              },
            });
          }
          // }
          if (name === "ZW4_NOME") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresAtividades.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
            });
          }
          if (name === "ZW4_ID") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresAtividades.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
              filter: f => {
                return (f + "")
                  .toLowerCase()
                  .includes(this["ZW4_ID"].toLowerCase());
              },
            });
          }
          if (name === "ZW5_NIVEL1") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresAtividades.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
            });
          }
          if (name === "ZW5_NIVEL2") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresAtividades.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
            });
          }
          if (name === "ZW5_STATUS") {
            this.itemsFiltro.push(e.ZZP_TITULO.trim());
            this.HeadresAtividades.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
              filter: f => {
                return (f + "")
                  .toLowerCase()
                  .includes(this["ZW5_STATUS"].toLowerCase());
              },
            });
          }

          if (
            name !== "ZW5_STATUS" &&
            name !== "ZW5_RESP" &&
            name !== "ZW5_NIVEL1" &&
            name !== "ZW5_NIVEL2" &&
            name !== "ZW4_NOME"
          ) {
            this.HeadresAtividades.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "center",
              width: width,
              caption: "title",
            });
          }
        }
      });

      const tabelaAux = [...new Set(ZZP.map(item => item.ZZP_TABELA))];

      this.stringCampos = "";

      let tabela = tabelaAux[0];

      ZZP.forEach(e => {
        if (e.ZZP_TABELA.trim() === tabela.trim()) {
          if (e.ZZP_TIPO === "Y") {
            this.stringCampos += `CONVERT(VARCHAR(2000), ${e.ZZP_CAMPO.trim()}) ${e.ZZP_CAMPO.trim()},`;
          } else {
            this.stringCampos += `${e.ZZP_CAMPO.trim()},`;
          }
        }
      });

      if (this.stringCampos.substr(-1) === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      let WHERE = `ZW4_ID <> '' ORDER BY ZW4_ID DESC`;

      const dataNow = new Date();

      let mes = (dataNow.getMonth() + 1).toString();
      let dia = dataNow.getDate().toString();

      if (mes.length < 2) mes = "0" + mes;

      if (dia.length < 2) dia = "0" + dia;

      this.stringCampos += `,ZW5_LOJAOP,ZW5_DOC,ZW5_SERIE,ZW9_DESCRI,ZW5_SEQ`;

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `${this.stringCampos}`,
        WHERE: WHERE,
        TABELA: tabela.substr(0, 3),
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/campanha`;

      this.itemAtividades = [];

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.indice = `${e.ZW4_ID}${e.A1_COD}${e.A1_LOJA}${e.ZW5_LOJAOP}${e.ZW5_DOC}${e.ZW5_SERIE}`;
            e.ZW5_DTINI = `${e.ZW5_DTINI.substr(6, 2)}/${e.ZW5_DTINI.substr(
              4,
              2,
            )}/${e.ZW5_DTINI.substr(0, 4)}`;
            e.ZW5_DTFIM.trim() !== ""
              ? (e.ZW5_DTFIM = `${e.ZW5_DTFIM.substr(
                  6,
                  2,
                )}/${e.ZW5_DTFIM.substr(4, 2)}/${e.ZW5_DTFIM.substr(0, 4)}`)
              : null;
            e.ZW4_DTINC.trim() !== ""
              ? (e.ZW4_DTINC = `${e.ZW4_DTINC.substr(
                  6,
                  2,
                )}/${e.ZW4_DTINC.substr(4, 2)}/${e.ZW4_DTINC.substr(0, 4)}`)
              : null;

            if (e.ZW4_TIPO === "I") e.ZW4_TIPO = "INDIVIDUAL";
            if (e.ZW4_TIPO === "C") e.ZW4_TIPO = "CAMPANHA";
            if (e.ZW4_TIPO === "O") {
              e.ZW4_NOME += `${e.ZW9_DESCRI}`;
            }

            if (e.ZW5_STATUS === "A") e.ZW5_STATUS = "A=ABERTO";
            if (e.ZW5_STATUS === "E") e.ZW5_STATUS = "E=ENCERRADO";
            if (e.ZW5_STATUS === "R") e.ZW5_STATUS = "R=ATRIBUÍDO";
            if (e.ZW5_STATUS === "S") e.ZW5_STATUS = "S=SEM CONTATO";

            if (e.ZW5_NIVEL1 === "L") e.ZW5_NIVEL1 = "L=LOCALIZADO";
            if (e.ZW5_NIVEL1 === "N") e.ZW5_NIVEL1 = "N=NAO LOCALIZADO";

            if (e.ZW5_NIVEL2 === "000001") e.ZW5_NIVEL2 = "000001=AGENDADO";
            if (e.ZW5_NIVEL2 === "000002") e.ZW5_NIVEL2 = "000002=RETORNAR";
            if (e.ZW5_NIVEL2 === "000003") e.ZW5_NIVEL2 = "000003=RECUSOU";
            if (e.ZW5_NIVEL2 === "000004") e.ZW5_NIVEL2 = "000004=FALECEU";
            if (e.ZW5_NIVEL2 === "000005")
              e.ZW5_NIVEL2 = "000005=CLIENTE MARCARA";
            if (e.ZW5_NIVEL2 === "000006")
              e.ZW5_NIVEL2 = "000006=NAO ESTA USANDO";
            if (e.ZW5_NIVEL2 === "000007")
              e.ZW5_NIVEL2 = "000007=COMPRA RECENTE";
            if (e.ZW5_NIVEL2 === "000008")
              e.ZW5_NIVEL2 = "000008=AASI ASSISTENCIA";
            if (e.ZW5_NIVEL2 === "000009") e.ZW5_NIVEL2 = "000009=PCTE MUDOU";
            if (e.ZW5_NIVEL2 === "000010") e.ZW5_NIVEL2 = "000010=PCTE DOENTE";
            if (e.ZW5_NIVEL2 === "000011")
              e.ZW5_NIVEL2 = "000010=JA COMPARECEU";

            const hora = e.ZW4_HORA.split(":");

            if (hora[0].length <= 1) {
              e.ZW4_HORA = `0${e.ZW4_HORA}`;
            }

            if (e.ZS1_NOME && e.ZS1_NOME.length >= 2) {
              if (e.ZW5_RESP.trim() !== "" && e.ZS1_NOME[1]) {
                e.ZW5_RESP = `${e.ZW5_RESP}-${e.ZS1_NOME[1]}`;
              }

              if (e.ZW4_USER.trim() !== "" && e.ZS1_NOME[0]) {
                e.ZW4_USER = `${e.ZW4_USER}-${e.ZS1_NOME[0]}`;
              }
            } else {
              e.ZW5_RESP = "";
              e.ZW4_USER = "";
            }
          });
          this.itemAtividades = [];
          this.top500Atividades = [];
          this.itemAtividades = res.data;
          this.itemsRelatExcel = res.data;
          this.top500Atividades = res.data;
          if (this.itemsResponsaveis.length <= 0) {
            this.montaResponsaveis();
          }
        })
        .catch(err => console.log(err));

      this.perPage = this.itemAtividades.length;
      this.loading = false;

      if (this.filtroTela.length > 0) {
        console.log(this.filtroTela);
        if (this.filtroTela[0].card === "Minhas Atividades") {
          if (this.filtroTela[0].item === 0) this.AtividadesVencendo();
          if (this.filtroTela[0].item === 1) this.AtividadesVencidas();
        }
      }

      this.$store.commit("setFiltroTela", []);
    },
    async montaResponsaveis() {
      let where = `ZS1_LEADS='1' AND ZS1_COD NOT IN ('${this.user.id}') ORDER BY ZS1_NOME`;
      if (this.isAdmin) {
        where = `ZS1_LEADS='1' ORDER BY ZS1_NOME`;
      }
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZS1_COD,ZS1_NOME",
        WHERE: where,
        TABELA: "ZS1",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.forEach(e => {
            this.itemsResponsaveis.push(`${e.ZS1_COD}-${e.ZS1_NOME}`);
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    verDados(item) {
      //this.carregaDados();
      this.titleDialog = `Visualizando o Lead: ${item.ZZQ_ID}-${item.ZZQ_NOME}`;
      this.dadosTela = item;

      if (this.dadosTela.ZZQ_SITUAC === "Novo")
        this.dadosTela.ZZQ_SITUAC = "N=Novo";
      if (this.dadosTela.ZZQ_SITUAC === "Aberto")
        this.dadosTela.ZZQ_SITUAC = "A=Aberto";
      if (this.dadosTela.ZZQ_SITUAC === "Eencerrado")
        this.dadosTela.ZZQ_SITUAC = "E=Encerrado";
      if (this.dadosTela.ZZQ_SITUAC === "Sem contato")
        this.dadosTela.ZZQ_SITUAC = "S=Sem contato";
      if (this.dadosTela.ZZQ_QUALIF === "Sim")
        this.dadosTela.ZZQ_QUALIF = "1=Sim";
      if (this.dadosTela.ZZQ_QUALIF === "Não")
        this.dadosTela.ZZQ_QUALIF = "2=Não";

      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Min")
        this.dadosTela.ZZQ_PESTRA = "M=Min";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Familiar")
        this.dadosTela.ZZQ_PESTRA = "F=Familiar";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Amigo")
        this.dadosTela.ZZQ_PESTRA = "A=Amigo";

      if (this.dadosTela.ZZQ_ISCLI === "Sim")
        this.dadosTela.ZZQ_ISCLI = "1=Sim";
      if (this.dadosTela.ZZQ_ISCLI === "Não")
        this.dadosTela.ZZQ_ISCLI = "2=Não";

      if (this.dadosTela.ZZQ_LAUDOM === "Sim")
        this.dadosTela.ZZQ_LAUDOM = "1=Sim";
      if (this.dadosTela.ZZQ_LAUDOM === "Não")
        this.dadosTela.ZZQ_LAUDOM = "2=Não";

      if (this.dadosTela.ZZQ_APUSER === "Sim")
        this.dadosTela.ZZQ_APUSER = "1=Sim";
      if (this.dadosTela.ZZQ_APUSER === "Não")
        this.dadosTela.ZZQ_APUSER = "2=Não";
      this.isReading = true;
      this.isEditing = false;
      this.isNew = false;
      this.DialogDados = true;

      this.$store.commit("SetLead_id", item.ZZQ_ID);
      this.$store.commit("SetLead_nome", item.ZZQ_NOME);

      this.$store.commit("setOrigem", "Atividades");
      this.carregaDados();

      setTimeout(() => {
        this.fFollowUp();
      }, 300);
    },
    interagir(item) {
      // if (!this.isAdmin && item.ZW5_RESP !== this.user.id) { // comentado pra inserir a linha abaixo, em 09/04/2021 - solicitação Evandro
      if (!this.isAdmin && item.ZW5_RESP.substr(0, 6) !== this.user.id.trim()) {
        this.$store.commit(
          "SetMensagemDialogOps",
          "Voce não pode interagir Atividade que não é de sua responsabilidade.",
        );
        this.$store.commit("SetdialogNull", true);
        return;
      } else {
        this.isNew = false;
        this.titleDialog = `Interagindo na atividade: ${item.ZW4_NOME}-Cliente: ${item.A1_NOME}`;
        this.id = `${item.ZW4_ID}`;

        this.$store.commit("SetLead_id", item.ZW4_ID);
        this.$store.commit("SetLead_nome", item.A1_NOME);
        this.$store.commit("setOrigem", "Atividade");

        this.carregaDados();

        this.isEditing = true;
        this.dadosTela = item;
        this.dadosTela.ZW5_CLIENT = `${item.A1_COD}-${item.A1_LOJA}-${item.A1_NOME}`;
        // this.dadosTela.ZW5_DTINI = item.ZW4_DTINI
        // this.dadosTela.ZW5_DTFIM = item.ZW4_DTFIM

        this.DialogDados = true;
        this.boilerplate = true;

        const dadosCliente = {
          CODIGO: item.A1_COD,
          LOJA: item.A1_LOJA,
        };

        this.$store.commit("setCliente", dadosCliente);

        if (this.isEditing) {
          setTimeout(() => {
            this.fFollowUp();
          }, 300);
        }
      }
    },
    editItem(item) {
      if (
        item.ZZQ_SITUAC.trim() === "Aberto" &&
        item.ZZQ_RESP.trim() !== this.user.id
      ) {
        this.$store.commit(
          "SetMensagemDialogOps",
          "Voce não pode alterar Lead que não é de sua responsabilidade.",
        );
        this.$store.commit("SetdialogNull", true);
        return;
      }

      this.$store.commit("SetLead_id", item.ZZQ_ID);
      this.$store.commit("SetLead_nome", item.ZZQ_NOME);
      this.$store.commit("setOrigem", "Atividades");
      this.carregaDados();
      this.isNew = false;
      this.titleDialog = `Alterando o Lead: ${item.ZZQ_ID}-${item.ZZQ_NOME}`;
      this.id = `${item.ZZQ_ID}`;

      this.isEditing = true;
      this.dadosTela = item;

      this.dadosTela.ZZQ_RESP = `${item.ZZQ_RESP}-${item.ZS1_NOME}`;

      if (this.dadosTela.ZZQ_SITUAC === "Novo")
        this.dadosTela.ZZQ_SITUAC = "N=Novo";
      if (this.dadosTela.ZZQ_SITUAC === "Aberto")
        this.dadosTela.ZZQ_SITUAC = "A=Aberto";
      if (this.dadosTela.ZZQ_SITUAC === "Eencerrado")
        this.dadosTela.ZZQ_SITUAC = "E=Encerrado";
      if (this.dadosTela.ZZQ_SITUAC === "Sem contato")
        this.dadosTela.ZZQ_SITUAC = "S=Sem contato";
      if (this.dadosTela.ZZQ_QUALIF === "Sim")
        this.dadosTela.ZZQ_QUALIF = "1=Sim";
      if (this.dadosTela.ZZQ_QUALIF === "Não")
        this.dadosTela.ZZQ_QUALIF = "2=Não";

      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Min")
        this.dadosTela.ZZQ_PESTRA = "M=Min";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Familiar")
        this.dadosTela.ZZQ_PESTRA = "F=Familiar";
      if (this.dadosTela.ZZQ_PESTRA && this.dadosTela.ZZQ_PESTRA === "Amigo")
        this.dadosTela.ZZQ_PESTRA = "A=Amigo";

      if (this.dadosTela.ZZQ_ISCLI === "Sim")
        this.dadosTela.ZZQ_ISCLI = "1=Sim";
      if (this.dadosTela.ZZQ_ISCLI === "Não")
        this.dadosTela.ZZQ_ISCLI = "2=Não";

      if (this.dadosTela.ZZQ_LAUDOM === "Sim")
        this.dadosTela.ZZQ_LAUDOM = "1=Sim";
      if (this.dadosTela.ZZQ_LAUDOM === "Não")
        this.dadosTela.ZZQ_LAUDOM = "2=Não";

      if (this.dadosTela.ZZQ_APUSER === "Sim")
        this.dadosTela.ZZQ_APUSER = "1=Sim";
      if (this.dadosTela.ZZQ_APUSER === "Não")
        this.dadosTela.ZZQ_APUSER = "2=Não";

      this.isReading = false;
      this.DialogDados = true;

      if (this.isEditing) {
        setTimeout(() => {
          this.fFollowUp();
        }, 300);
      }
    },
    async carregaItems(item) {
      let dados = {};
      let items = [];
      this.itemsTipo = [];
      const campos = item.ZZP_F3CAMP.split(";");
      this.stringCampos = "";
      let where = ``;

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          this.stringCampos += `${campos[i]}`;
        } else {
          this.stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        if (
          item.ZZP_CAMPO.trim() === "ZZQ_CIDADE" &&
          this.dadosTela["ZZQ_EST"].trim() !== ""
        ) {
          const estado = this.dadosTela["ZZQ_EST"].split("-");
          where = `CC2_EST = '${estado[0].trim()}' ORDER BY CC2_MUN`;
        }
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${this.stringCampos.trim()}`,
          WHERE: where,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          items = res.data.map(i => {
            let dados = ``;
            for (let n = 0; n < campos.length; n++) {
              if (n + 1 >= campos.length) {
                dados += `${i[campos[n].trim()]}`;
              } else {
                dados += `${i[campos[n].trim()]}-`;
              }
            }
            return dados;
          });
          this.itemsTipo = items;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    novo() {
      this.dadosTela = [];
      this.isReading = false;
      this.isNew = true;
      this.titleDialog = `Incluindo Atividade`;
      this.carregaDados();
      this.DialogDados = true;
    },
    Custonfiltro(indice) {
      const search = this.search.toString().toLowerCase();

      if (indice === "N") {
        return this.itemAtividades.filter(i => {
          i["A1_NOME"] === search;
        });
      }
      if (indice === "C") {
        return this.itemAtividades.filter(i => {
          i["A1_COD"] === search;
        });
      }
      if (indice === "F") {
        return this.itemAtividades.filter(i => {
          i["A1_CGC"] === search;
        });
      }
    },
    async verificaAtividades() {
      this.dialogAwaitSaveLead = true;
      let Atividades = [];

      let url = `${process.env.VUE_APP_BASE_API_URL}/tb_aux_lead`;

      await axios.get(url).then(res => {
        if (res.data.length <= 0) {
          this.dialogAwaitSaveLead = false;
          this.montaBrowser();
        }
        this.newAtividades = res.data.length;
        Atividades = res.data;
      });

      await Atividades.map(async e => {
        const ZZQ = [];

        ZZQ.push({
          ZZQ_EMAIL: e.ZW2_EMAIL,
          ZZQ_TPATEN: e.ZW2_TPATEN,
          ZZQ_TEL: e.ZW2_TEL,
          ZZQ_DTINC: e.ZW2_DTINC,
          ZZQ_HRINC: e.ZW2_HRINC,
          ZZQ_PAG: e.ZW2_PAG,
          ZZQ_TEL1: e.ZW2_TEL1,
          ZZQ_PESTRA: e.ZW2_PESTRA,
          ZZQ_LAUDOM: e.ZW2_LAUDOM,
          ZZQ_APUSER: e.ZW2_APUSER,
          ZZQ_ISCLI: e.ZW2_ISCLI,
          ZZQ_CLIENT: e.ZW2_CLIENT,
          ZZQ_LOJA: e.ZW2_LOJA,
          ZZQ_CIDADE: this.removeAcento(e.ZW2_CIDADE),
          ZZQ_EST: e.ZW2_EST,
          ZZQ_DEFEI1: this.removeAcento(e.ZW2_DEFEI1),
          ZZQ_ACNEWL: e.ZW2_ACNEWL,
          ZZQ_CPF: e.ZW2_CPF,
          ZZQ_SITUAC: "N",
          ZZQ_QUALIF: e.ZW2_QUALIF,
          ZZQ_CONVET: e.ZW2_CONVET,
          ZZQ_STATUS: e.ZW2_STATUS,
          ZZQ_DSTATU: e.ZW2_DSTATU,
          ZZQ_RESP: e.ZW2_RESP,
          ZZQ_NOME: this.removeAcento(e.ZW2_NOME),
          ZZQ_DTRET: e.ZW2_DTRET,
          ZZQ_PSTRAT: e.ZW2_PSTRAT,
          ZZQ_TELDES: e.ZW2_TELDES,
          ZZQ_STLIGA: e.ZW2_STLIGA,
          ZZQ_LIGDUR: e.ZW2_LIGDUR,
          ZZQ_LIGFAL: e.ZW2_LIGFAL,
          ZZQ_URLGRV: e.ZW2_URLGRV,
        });

        await this.gravaLead(ZZQ, { ...e });
      });
    },
    async countAtividades() {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        WHERE: "",
        FIELDS: "COUNT(*) COUNT",
        TABELA: "ZZQ",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      await axios
        .post(url, dados)
        .then(res =>
          this.$store.commit("setTotalAtividades", res.data[0].COUNT),
        )
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      console.log(this.totalGeralAtividades);
    },
    async gravaLead(ZZQ, ZW2) {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        ZZQ: ZZQ[0],
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/saveAtividades`;
      await axios
        .post(url, dados)
        .then(res => {
          if (res.data.STATUS === "true") {
            const recno = ZW2.R_E_C_N_O_;
            ZW2 = {
              ZW2_SITUAC: "X",
              ZW2_ID: res.data.CODIGO,
              R_E_C_N_O_: recno,
            };

            url = `${process.env.VUE_APP_BASE_API_URL}/alterTbAuxLead`;

            axios.post(url, ZW2);
          }
          this.nrIncLead++;
        })
        .catch(err => console.log(err));

      if (this.nrIncLead === this.newAtividades) {
        this.dialogAwaitSaveLead = false;
        this.newAtividades = null;
        this.nrIncLead = 0;
        this.montaBrowser();
      }
    },
    async verificaOpimedMais() {
      this.dialogOpimedMais = true;
      const dataHoje = new Date();

      const ano = dataHoje.getFullYear().toString();

      let mes = (dataHoje.getMonth() + 1).toString();

      let dia = dataHoje.getDate().toString();

      if (mes.length <= 1) {
        mes = `0${mes}`;
      }

      if (dia.length <= 1) {
        dia = `0${dia}`;
      }

      const hora = dataHoje.getHours();
      let minuto = dataHoje.getMinutes();
      if (minuto < 10) {
        minuto = `0${minuto}`;
      }

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "",
        WHERE: `ZW8_DATA='${ano}${mes}${dia}'`,
        TABELA: "ZW8",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      let aCheckRotina = [];

      await axios
        .post(url, dados)
        .then(res => {
          aCheckRotina = res.data;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
          this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
          this.erro += `Chamada: ${url} =====>`;
          this.erro += `${error}`;
          this.$store.commit("setDialogError", true);
        });

      let continua = true;

      if (aCheckRotina.length > 0) {
        aCheckRotina.forEach(e => {
          if (e.ZW8_STATUS === "E" || e.ZW8_STATUS === "C") {
            continua = false;
          }
        });
      } else {
        const ZW8 = {
          ZW8_DATA: `${ano}${mes}${dia}`,
          ZW8_HORA: `${hora}:${minuto}`,
          ZW8_USER: this.user.id,
          ZW8_STATUS: "E",
        };

        url = `${process.env.VUE_APP_BASE_API_URL}/saveCheck`;

        await axios
          .post(url, ZW8)
          .then(res => {
            console.log(res.data);
          })
          .catch(error => {
            this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
            this.erro += `Chamada: ${url} =====>`;
            this.erro += `${error}`;
            this.$store.commit("setDialogError", true);
          });
      }

      if (continua) {
        url = `${process.env.VUE_APP_BASE_API_URL}/opimedMais`;

        let aOpimedMais = [];

        await axios
          .get(url)
          .then(res => {
            aOpimedMais = res.data;
          })
          .catch(error => {
            this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
            this.erro += `Chamada: ${url} =====>`;
            this.erro += `${error}`;
            this.$store.commit("setDialogError", true);
          });

        console.log(aOpimedMais);

        let aAtividadesOpimed = [];

        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `ZW5_CLIENT,ZW5_LOJA,ZW5_LOJAOP,ZW5_DOC,ZW5_SERIE`,
          WHERE: `ZW5_TIPO='O'`,
          TABELA: "ZW5",
        };

        url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

        await axios
          .post(url, dados)
          .then(res => {
            aAtividadesOpimed = res.data;
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
            this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
            this.erro += `Chamada: ${url} =====>`;
            this.erro += `${error}`;
            this.$store.commit("setDialogError", true);
          });

        console.log(aAtividadesOpimed);

        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "TOP 1 *",
          WHERE: `ZW9_MSBLQ='2' ORDER BY ZW9_SEQ`,
          TABELA: "ZW9",
        };

        url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;
        let ZW9 = {};
        await axios
          .post(url, dados)
          .then(res => {
            ZW9 = { ...res.data[0] };
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
            this.erro = `Dados: ${JSON.stringify(dados)} =====>`;
            this.erro += `Chamada: ${url} =====>`;
            this.erro += `${error}`;
            this.$store.commit("setDialogError", true);
          });

        let ZW5 = [];

        aOpimedMais.forEach(e => {
          const isExists = aAtividadesOpimed.filter(
            i =>
              i.ZW5_CLIENT === e.CLIENTE &&
              i.ZW5_LOJA === e.LOJA &&
              i.ZW5_LOJAOP === e.FILIAL &&
              i.ZW5_DOC === e.DOCUMENTO &&
              i.ZW5_SERIE === e.SERIE,
          );

          if (
            isExists.length <= 0 &&
            e.DEVOLUCAO !== "X" &&
            e.CPF.trim().length <= 11
          ) {
            ZW5.push({
              ZW5_CLIENT: e.CLIENTE,
              ZW5_LOJA: e.LOJA,
              ZW5_STATUS: "A",
              ZW5_RESP: "",
              ZW5_DTINI: `${e.START}`,
              ZW5_DTFIM: `${e.START}`,
              ZW5_TIPO: "O",
              ZW5_LOJAOP: e.FILIAL,
              ZW5_DLJOPI: this.removeAcento(e.LOJA_OPIMED),
              ZW5_DOC: e.DOCUMENTO,
              ZW5_SERIE: e.SERIE,
              ZW5_SEQ: ZW9.ZW9_SEQ,
            });
          }
        });

        console.log("Novos Opimed +");
        console.log(ZW5.length);

        if (ZW5.length > 0) {
          const ZW4 = {
            ZW4_NOME: "OPIMED +",
            ZW4_DTINI: `${ano}${mes}${dia}`,
            ZW4_DTFIM: `${ano}${mes}${dia}`,
            ZW4_TIPO: "O",
            ZW4_USER: this.user.id,
            ZW4_MODEL: "PORTAL",
            ZW4_NREGIS: ZW5.length,
            ZW4_NREGTO: ZW5.length,
            ZW4_FILTRO: "",
            ZW4_DTINC: `${ano}${mes}${dia}`,
            ZW4_HORA: `${hora}:${minuto}`,
            ZW4_FINALI: "CONFIRMAR AGENDA PARA O RETORNO",
          };

          dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            ZW4,
            ZW5,
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/campanhas`;

          axios.post(url, dados).then(res => {
            console.log(res.data);
            if (res.data.STATUS === "true") {
              const ZW8 = {
                ZW8_DATA: `${ano}${mes}${dia}`,
                ZW8_HORA: `${hora}:${minuto}`,
                ZW8_USER: this.user.id,
                ZW8_STATUS: "U",
              };

              url = `${process.env.VUE_APP_BASE_API_URL}/saveCheck`;

              axios
                .post(url, ZW8)
                .then(res => {
                  console.log(res.data);
                })
                .catch(error => console.log(error));
              this.montaBrowser();
              this.dialogOpimedMais = false;
            } else {
              const ZW8 = {
                ZW8_DATA: `${ano}${mes}${dia}`,
                ZW8_HORA: `${hora}:${minuto}`,
                ZW8_USER: this.user.id,
                ZW8_STATUS: "Z",
              };

              url = `${process.env.VUE_APP_BASE_API_URL}/saveCheck`;

              axios
                .post(url, ZW8)
                .then(res => {
                  console.log(res.data);
                })
                .catch(error => console.log(error));
              this.montaBrowser();
              this.dialogOpimedMais = false;
            }
          });
        } else {
          this.montaBrowser();
          this.dialogOpimedMais = false;
        }
      } else {
        this.montaBrowser();
        this.dialogOpimedMais = false;
      }
    },
  },
  async mounted() {
    if (
      this.user.isAdmin &&
      this.user.NivelAdmin.map(e => e.trim()).includes("1")
    ) {
      this.isAdmin = true;
      this.items = [
        {
          title: "Minha(s) atividade(s) em aberto",
          icon: "mdi-home-city",
          function: "minhasAtividades",
        },
        // {
        //   title: "Minha(s) atividade(s) com vencimento até hoje",
        //   icon: "mdi-account",
        //   function: "AtividadesRetorno",
        // },
        {
          title: "Atividade(s) vencendo hoje",
          icon: "mdi-account",
          function: "AtividadesVencendo",
        },
        {
          title: "Atividade(s) vencidas",
          icon: "mdi-account",
          function: "AtividadesVencidas",
        },
        {
          title: "Atribuir atividade(s)",
          icon: "mdi-account-group-outline",
          function: "atribuirAtividades",
        },
        {
          title: "Atribuir atividade(s) aleatória(s)",
          icon: "mdi-account-group-outline",
          function: "atribuirAtividadesAL",
        },
        {
          title: "Transferir atividade(s)",
          icon: "mdi-account-group-outline",
          function: "transfereAtividade",
        },
        { title: "Histórico da atividade", function: "histAtividade" },
      ];
    } else {
      this.items = [
        {
          title: "Minha(s) atividade(s) em aberto",
          icon: "mdi-home-city",
          function: "minhasAtividades",
        },
        // {
        //   title: "Minha(s) atividade(s) com vencimento até hoje",
        //   icon: "mdi-account",
        //   function: "AtividadesRetorno",
        // },
        {
          title: "Atividade(s) vencendo hoje",
          icon: "mdi-account",
          function: "AtividadesVencendo",
        },
        {
          title: "Atividade(s) vencidas",
          icon: "mdi-account",
          function: "AtividadesVencidas",
        },
        {
          title: "Atribuir atividade(s)",
          icon: "mdi-account-group-outline",
          function: "atribuirAtividades",
        },
        {
          title: "Transferir atividade(s)",
          icon: "mdi-account-group-outline",
          function: "transfereAtividade",
        },
        { title: "Histórico da Atividade", function: "histAtividade" },
      ];
      this.isAdmin = false;
    }

    if (this.filtroTela.length <= 0) {
      await this.verificaOpimedMais();
      this.montaBrowser();
    } else {
      this.dialogAwaitSaveLead = false;
      this.montaBrowser();
    }
  },
};
</script>

<style>
.v-label {
  font-size: 0.7rem;
}
input {
  font-size: 0.8rem;
}
.text-center {
  font-size: 0.7rem !important;
}
.text-start {
  font-size: 0.7rem !important;
}
tbody tr:nth-of-type(odd) {
  background-color: #d8f6fa;
}
.v-select__selection {
  font-size: 0.8rem !important;
}
.scroll {
  overflow-y: scroll;
}
.painel {
  overflow-x: scroll;
}

.theme--light.v-data-table.tbAtividades tbody tr.v-data-table__selected {
  background: #0fcbe1 !important;
}

.theme--light.v-data-table.tbAtividades tbody tr.v-data-table__selected {
  background: #0fcbe1 !important;
}

.v-data-table.tbAtividades tbody tr :hover {
  cursor: pointer;
}
</style>
