<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    :mini-variant.sync="mini"
    :color="menuColor"
    permanent
    v-if="true"
  >
    <v-list-item class="px-2">
      <v-list-item-title>{{ user.name }}</v-list-item-title>

      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item
        v-for="item in optionsMenu"
        :key="item.title"
        @click.stop="validaVersao(item)"
        link
      >
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-icon color="black">{{ item.icon }}</v-icon>
            </v-list-item-icon>
          </template>
          <span v-text="item.title"></span>
        </v-tooltip>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <!-- <div class="px-2">
            <v-btn fab x-small class="mb-3" to="/administra" dark  @click.stop="$store.commit('SetTitle', 'Configurações administrativas')">
              <v-icon>mdi-cogs</v-icon>
            </v-btn>
        </div> -->
      <div class="px-2">
        <span v-if="!mini" class="sx-version">
          Sansix &copy; {{ new Date().getFullYear() }} v.{{ versao }}
        </span>
        <br />
        <v-btn fab x-small class="mb-3" @click="logout" color="black">
          <v-icon color="white">mdi-logout</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { version } from "../../../package.json";
export default {
  name: "menu-bar",
  computed: {
    ...mapState({
      user: state => state.user,
      tabs: state => state.tabs,
      tabActive: state => state.tabActive,
      menuColor: state => state.menuColor,
      optionsMenu: state => state.menu.optionsMenu,
    }),
  },
  data() {
    return {
      versao: version,
      drawer: true,
      items: [],
      mini: true,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem(process.env.VUE_APP_USER_KEY);
      localStorage.removeItem(process.env.VUE_APP_METADADOS_KEY);
      this.$store.commit("SetUser", null);
      this.$store.commit("SetMetadados", null);
      // this.$store.commit("setDadosPdf", {});
      this.$store.commit("setOrdemServico", null);
    },
    async validaVersao(item) {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZB7_VERSAO",
        WHERE: `ZB7_EXPIRA='N' ORDER BY R_E_C_N_O_ DESC`,
        TABELA: "ZB7010",
      };

      const validationVersion = await axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/node/consultas`,
        dados,
      );

      if (!validationVersion.data[0]) return;

      if (validationVersion.data[0].ZB7_VERSAO.trim() > this.versao) {
        document.location.reload();
      } else {
        this.setaTitle(item);
      }
    },
    setaTitle(item) {
      if (item.name === "UniOpimed") {
        window.open(item.linkPag, "_blank");
        return;
      }

      this.$store.commit("SetTitle", item.title);
      this.$store.commit("SetTabs", {
        name: `${item.title}`,
        content: `${item.content}`,
      });
      // this.$store.commit("SetTabActive", this.tabs.length - 1)
      this.$store.commit("SetTitle", item.title);

      //this.$router.push({ name: `${item.name}` }).catch(() => {});
    },
  },
  mounted() {
    const optionsMenu = [];

    optionsMenu.push({
      id: 0,
      title: "Universidade Opimed",
      icon: "mdi-school",
      name: "UniOpimed",
      link: "",
      content: "UniOpimed",
      linkPag: "http://www.universidadeopimed.com.br",
      target: "_blank",
    });

    optionsMenu.push({
      id: 1,
      title: "Peoplenet Rh",
      icon: "mdi-badge-account",
      name: "UniOpimed",
      link: "",
      content: "PeopleNet",
      linkPag: "https://opimed.sistemapeoplenet.com.br/rh/App",
      target: "_blank",
    });

    if (this.user.AgCirurgica) {
      optionsMenu.push({
        id: 2,
        title: "Gestão Agenda Cirúrgica",
        name: "AgendaCir",
        icon: "mdi-face-mask-outline",
        link: "/agendaC",
        content: "AgendaC",
      });
    }

    if (this.user.AgAuditiva) {
      optionsMenu.push({
        id: 3,
        title: "Central Auditiva",
        name: "Central Auditiva",
        // icon: "mdi-calendar-month-outline",
        icon: "mdi-ear-hearing",
        link: "/centralAuditiva",
        content: "centralAuditiva",
      });
    }

    if (this.user.Clientes) {
      optionsMenu.push({
        id: 4,
        title: "Clientes",
        name: "Clientes",
        icon: "mdi-account-group",
        link: "/clientes",
        content: "Clientes",
      });
    }

    if (this.user.Os) {
      optionsMenu.push({
        id: 5,
        title: "Ordem de Serviço",
        icon: "mdi-hammer-wrench",
        name: "OrdemServico",
        link: "/ordem-de-servico",
        content: "OrdemServico",
      });
    }

    if (this.user.Central_crm) {
      optionsMenu.push({
        id: 6,
        title: "Central CRM",
        icon: "mdi-face-agent",
        name: "CentralCrm",
        link: "/",
        content: "CentralCrm",
      });
    }

    if (this.user.Visitador && this.user.AdmVisitador) {
      optionsMenu.push({
        id: 7,
        title: "Central Visitadores",
        icon: "mdi-wallet-travel",
        name: "CentralVisitador",
        link: "/centralVisitador",
        content: "CentralVisitador",
      });
    }

    if (this.user.Visitador) {
      optionsMenu.push({
        id: 8,
        title: "Visitas",
        icon: "mdi-train-car",
        name: "Visitas",
        link: "/visitas",
        content: "Visitas",
      });
    }

    // if (this.user.filialVendedor.trim()) {
    //   optionsMenu.push({
    //     id: 13,
    //     title: "Clube Opimed",
    //     icon: "mdi-ticket-percent-outline",
    //     name: "Clube",
    //     link: "/clube",
    //     content: "Clube",
    //   });
    // }

    //aqui tem que vir o If da comissão criar campo no cadastro de usuários do portal para comissão
    if (this.user.comissao) {
      optionsMenu.push({
        id: 10,
        title: "Prêmio",
        icon: "mdi-account-cash-outline",
        name: "Comissao",
        link: "/comissoes",
        content: "Comissao",
      });
    }

    //aqui tem que vir o If da comissão criar campo no cadastro de usuários do portal para comissão
    if (this.user.metas) {
      optionsMenu.push({
        id: 11,
        title: "Metas Opimed",
        icon: "mdi-finance",
        name: "FaturamentoMetas",
        link: "/metasOpimed",
        content: "MetasOpimed",
      });
    }

    if (this.user.Os) {
      optionsMenu.push({
        id: 12,
        title: "Controle OS",
        icon: "mdi-account-wrench ",
        name: "ControlOS",
        link: "/controlOS",
        content: "ControlOS",
      });
    }

    if (this.user.metasPessoal) {
      optionsMenu.push({
        id: 13,
        title: "Metas Pessoais",
        icon: "mdi-account-details",
        name: "MetasVendedores",
        link: "/metasVendedores",
        content: "metasVendedores",
      });
    }

    if (this.user.metasProdutos) {
      optionsMenu.push({
        id: 14,
        title: "Metas Produtos",
        icon: "mdi-archive-check-outline",
        name: "MetasProdutos",
        link: "/metas-produtos",
        content: "MetasProdutos",
      });
    }

    console.log("this.user.metasArea", this.user.metasArea);

    if (this.user.metasArea) {
      optionsMenu.push({
        id: 14,
        title: "Metas por área",
        icon: "mdi-cash-check",
        name: "MetasAreas",
        link: "/metas-areas",
        content: "MetasAreas",
      });
    }

    if (this.user.metasA4) {
      optionsMenu.push({
        id: 14,
        title: "Fonos Representantes",
        icon: "mdi-account-cash",
        name: "FonosRepre",
        link: "/fonos-represent",
        content: "FonosRepre",
      });
    }

    if (this.user.PCO) {
      optionsMenu.push({
        id: 14,
        title: "PCO",
        icon: "mdi-cash-register",
        name: "CustoPrevOp",
        link: "/CustoPrevOp",
        content: "CustoPrevOp",
      });
    }

    this.$store.commit("setOptionsMenu", optionsMenu);
  },
};
</script>

<style>
/* .v-navigation-drawer {
    display: block;
  }
  .v-navigation-drawer--mini-variant .sx-version {
    display: none;
  } */
.sx-version {
  font-size: 0.8rem;
}
</style>
