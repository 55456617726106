

export default {
  state: {
    blocks: []
  },
  mutations: {
    setBlocks: (state, payload) => (state.blocks = payload),
    setAddBlocks(state, payload) {
      state.blocks.push(payload)
    },
    setBlocksEditStatus(state, payload) {
      console.log(payload)
      state.blocks.filter(e => e.id === payload.id).map(e => {
        e.status = payload.status
        e.style = payload.styles
      })
    }
  },
  actions: {
    editStatusBlock({ commit }, payload) {
      commit('setBlocksEditStatus', payload)
    },
    newBlocks({ commit }, payload) {
      commit('setBlocks', payload)
    }
  }
};